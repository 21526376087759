export class DisplayUtil {
  public static getDialogWidth(): number {
    return 400;
  }

  public static getDialogLargeWidth(): number {
    return 900;
  }

  public static getDialogWidthPx(): string {
    return DisplayUtil.getDialogWidth() + 'px';
  }

  public static getDialogWidthLargePx(): string {
    return DisplayUtil.getDialogLargeWidth() + 'px';
  }
}
