import { Pipe, PipeTransform } from '@angular/core';
import { EnumService, IEnumData } from '../../shared/services/enum.service';
import { Logger, LoggerService } from '../../shared/services/logger.service';
import { CommonUtil } from '../../shared/utils/common.util';
import { isNullOrUndefined } from '../../shared/utils/isNullOrUndefined';

@Pipe({ name: 'enumText' })
export class EnumTextPipe implements PipeTransform {
  private logger: Logger;

  constructor(private enumService: EnumService, loggerService: LoggerService) {
    this.logger = loggerService.create(EnumTextPipe.name);
  }

  transform(ordinal: any, enumType: any): string {
    const enumData: IEnumData = this.enumService.fromValue(enumType, ordinal);
    if (!isNullOrUndefined(enumData)) {
      return enumData.display;
    }
    this.logger.error(`EnumTextPipe: Unknown ordinal="${ordinal}" enumType="${enumType}"`);
    return CommonUtil.getNotAvailable();
  }
}
