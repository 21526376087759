/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PostSingleContactAddressRequest } from './postSingleContactAddressRequest';


export interface PostLeadRequest { 
    MandatorId: number;
    Origin: string;
    TradeTypeCode: string;
    Language: PostLeadRequest.LanguageEnum;
    DirectoryContactId?: number;
    SingleContact?: PostSingleContactAddressRequest;
    ComplexityType?: string;
}
export namespace PostLeadRequest {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
}
