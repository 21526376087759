/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PatchEmobilityVariantRequest { 
    /**
     * The goal margin
     */
    GoalMargin?: number;
    /**
     * The date until when the variant is valid
     */
    ValidationDate?: Date;
    SalesType?: PatchEmobilityVariantRequest.SalesTypeEnum;
    /**
     * The minimal margin
     */
    MinimalMargin?: number;
    /**
     * The municipal subsidies
     */
    MunicipalSubsidies?: number;
    /**
     * The cantonal subsidies
     */
    CantonalSubsidies?: number;
    /**
     * The federal subsidies
     */
    FederalSubsidies?: number;
    /**
     * The other subsidies
     */
    OtherSubsidies?: number;
    FinancingType?: PatchEmobilityVariantRequest.FinancingTypeEnum;
    /**
     * The car manufacturer subsidies
     */
    CarManufacturerSubsidy?: number;
}
export namespace PatchEmobilityVariantRequest {
    export type SalesTypeEnum = 'Classic' | 'Digital';
    export const SalesTypeEnum = {
        Classic: 'Classic' as SalesTypeEnum,
        Digital: 'Digital' as SalesTypeEnum
    };
    export type FinancingTypeEnum = 'None' | 'SubsidyPrefinancing' | 'Installments';
    export const FinancingTypeEnum = {
        None: 'None' as FinancingTypeEnum,
        SubsidyPrefinancing: 'SubsidyPrefinancing' as FinancingTypeEnum,
        Installments: 'Installments' as FinancingTypeEnum
    };
}
