import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import {
  MasterDataMandatorTradeTypeViewModel,
  MasterDataMandatorViewModel,
  MasterDataOrganisationUnitViewModel,
  MasterDataTradeTypeViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { MasterDataUtil } from '../../../shared/utils/master-data.util';
import { IPrincipal } from '../../../shared/interfaces/principal.interface';
import * as RootReducer from '../../../shared/states';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import orderBy from 'lodash/orderBy';
import { TradeTypeEnum, TradeTypeUtils } from '../../../shared/utils/trade-type.util';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { DIALOG_DATA, DialogRef } from '@sitewerk/theia-ui-lib';

export interface ISelectedLeadData {
  mandant: MasterDataMandatorViewModel;
  orgUnit: MasterDataOrganisationUnitViewModel;
  tradeType: MasterDataMandatorTradeTypeViewModel;
  origin: string;
  complexityType: string;
}

export interface INewLeadDataDialogInputData {
  tradeType?: string;
  mandantId: number;
  isCopyLead: boolean;
}

@Component({
  selector: 'pc-new-lead-data-dialog',
  templateUrl: './new-lead-data-dialog.component.html',
  styleUrls: ['./new-lead-data-dialog.component.scss'],
})
export class NewLeadDataDialogComponent implements OnInit, OnDestroy {
  protected origins: string[] = [];
  protected selectedOrigin: string;

  protected allComplexityTypes: string[] = [];
  protected complexityTypes: string[] = [];
  protected selectedComplexityType: string;

  protected tradeTypes: MasterDataTradeTypeViewModel[];
  protected selectedTradeCode: string;
  protected selectedTradeType: MasterDataMandatorTradeTypeViewModel;

  protected mandants: MasterDataMandatorViewModel[];
  protected selectedMandant: MasterDataMandatorViewModel;
  protected selectedMandantId: number;

  protected orgUnits: MasterDataOrganisationUnitViewModel[];
  protected selectedOrgUnit: MasterDataMandatorViewModel;

  protected isMandatorSelectorEnabled: boolean = false;
  protected isComplexityTypeSelectorEnabled: boolean = false;
  protected isOriginSelectorEnabled: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: INewLeadDataDialogInputData,
    private store: Store<RootReducer.IState>
  ) {}

  public ngOnInit(): void {
    const principal$: Observable<IPrincipal> = this.store.select<IPrincipal>(
      RootReducer.getAuthPrincipal
    );
    const masterData$: Observable<MasterDataViewModel> = this.store.select<MasterDataViewModel>(
      RootReducer.getGlobalMasterData
    );

    if (isNullOrUndefined(this.data) || isNullOrUndefined(this.data.mandantId)) {
      this.isMandatorSelectorEnabled = true;
      this.isComplexityTypeSelectorEnabled = true;
      this.isOriginSelectorEnabled = true;
    }

    this.subscription.add(
      combineLatest([principal$, masterData$]).subscribe(
        ([principal, masterData]: [IPrincipal, MasterDataViewModel]) => {
          this.allComplexityTypes = masterData.LeadComplexityTypes;

          this.mandants = masterData.Mandators;
          this.mandants = orderBy(this.mandants, ['Name'], ['asc']);

          const mandant = this.isMandatorSelectorEnabled
            ? new MasterDataUtil(masterData, principal.getMandantId()).getMandant()
            : this.mandants.find((m: MasterDataMandatorViewModel) => m.Id === this.data.mandantId);

          this.selectedMandantId = mandant.Id;
          this.selectedMandant = mandant;
          this.setMandantDependencies(mandant);
        }
      )
    );

    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.mandantId)) {
      this.selectedTradeCode = this.tradeTypes.find((tradeType: MasterDataTradeTypeViewModel) => {
        return tradeType.Code === this.data.tradeType;
      })?.Code;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onOk(): void {
    const mandant = this.mandants.find(
      (m: MasterDataMandatorViewModel) => m.Id === this.selectedMandantId
    );
    const tradeType = mandant.TradeTypes.find(tt => tt.Code === this.selectedTradeCode);

    const data: ISelectedLeadData = {
      mandant: mandant,
      orgUnit: this.selectedOrgUnit,
      tradeType: tradeType,
      origin: this.selectedOrigin,
      complexityType: this.selectedComplexityType,
    };

    this.dialogRef.close(data);
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

  public onMandantChange(id: number): void {
    this.selectedOrgUnit = undefined;
    this.selectedTradeCode = undefined;
    this.selectedTradeType = undefined;
    this.selectedOrigin = undefined;
    this.selectedComplexityType = undefined;
    this.selectedMandantId = +id;
    this.selectedMandant = this.mandants.find(m => m.Id === +id);

    this.setMandantDependencies(this.selectedMandant);
  }

  public onTradeTypeChange(code: string): void {
    this.selectedComplexityType = undefined;
    this.selectedTradeCode = code;

    const tradeType = this.selectedMandant.TradeTypes.find(
      (tt: MasterDataMandatorTradeTypeViewModel) => tt.Code === code
    );
    this.selectedTradeType = tradeType;
    this.setTradeTypeDependencies(tradeType);
  }

  public isButtonOkEnabled(): boolean {
    if (this.isMandatorSelectorEnabled && this.selectedMandantId === undefined) {
      return false;
    }
    if (this.isComplexityTypeSelectorEnabled && this.selectedComplexityType === undefined) {
      return false;
    }
    if (this.isOriginSelectorEnabled && this.selectedOrigin === undefined) {
      return false;
    }

    return this.selectedTradeCode !== undefined;
  }

  private setMandantDependencies(mandant: MasterDataMandatorViewModel): void {
    this.orgUnits = mandant.OrganisationUnits;
    this.origins = mandant.ConfiguredOrigins ?? [];
    this.tradeTypes = mandant.TradeTypes.filter(
      (x: MasterDataMandatorTradeTypeViewModel) =>
        x.Code.toLowerCase() !== TradeTypeUtils.toTradeTypeCode(TradeTypeEnum.PVX).toLowerCase()
    );

    this.setTradeTypeDependencies(undefined);
  }

  private setTradeTypeDependencies(tradeType: MasterDataMandatorTradeTypeViewModel): void {
    this.complexityTypes = tradeType?.ComplexityTypes ?? this.allComplexityTypes;

    this.selectedComplexityType =
      this.complexityTypes && this.complexityTypes.length === 1
        ? this.complexityTypes[0]
        : undefined;
  }
}
