import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NGX_QUILL_CUSTOM_OPTIONS,
  NGX_QUILL_MODULES,
  resizeBase64,
} from 'app/shared/utils/ngx-quill-editor.settings';
import * as quill from 'ngx-quill';
import Quill from 'quill';
import { sampleTime, skip } from 'rxjs/operators';
import { AutosaveService, AutosaveStatusEnum } from './autosave.service';

const AUTOSAVE_INTERVAL = 5000;

@Component({
  selector: 'pc-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
})
export class QuillEditorComponent implements AfterViewInit, OnChanges {
  @Input() public placeholder: string;
  @Input() public cyAttr: string = '';
  @Input() public needFocus: boolean = false;

  @Input() public text: string;
  @Output() public autoSave: EventEmitter<string> = new EventEmitter();
  @Output() public textChange: EventEmitter<string> = new EventEmitter();
  @Output() public editorCreated: EventEmitter<Quill> = new EventEmitter();

  @ViewChild(quill.QuillEditorComponent) public quillEditor: quill.QuillEditorComponent;

  public customOptions: quill.CustomOption[] = NGX_QUILL_CUSTOM_OPTIONS;
  public modules: quill.QuillModules = NGX_QUILL_MODULES;
  public AutosaveStatusEnum = AutosaveStatusEnum;
  public id: string;

  private isTextInitialized: boolean = false;

  constructor(public autosaveService: AutosaveService) {
    this.id = crypto.randomUUID();
    // https://github.com/KillerCodeMonkey/ngx-quill/issues/362#issuecomment-667376166
    // Load the Quill Editor toolbar icons for Undo and Redo
    const icons = Quill.import('ui/icons');
    icons['undo'] =
      '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
      '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
    icons['redo'] =
      '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
      '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // needed to set initial value if it was loaded from server after view init
    if (!this.isTextInitialized && changes.text.currentValue && this.quillEditor) {
      this.quillEditor.editorElem.querySelector('.ql-editor').innerHTML = changes.text.currentValue;
      this.isTextInitialized = true;
    }
  }

  public updateText(): void {
    this.quillEditor.editorElem.querySelector('.ql-editor').innerHTML = this.text;
  }

  public ngAfterViewInit(): void {
    // need to wait until quill editor rendered and initialized
    setTimeout(() => {
      this.quillEditor.editorElem.querySelector('.ql-editor').innerHTML = this.text;
      this.focusEditor();

      this.textChange.pipe(skip(1), sampleTime(AUTOSAVE_INTERVAL)).subscribe(() => {
        this.text && this.autoSave.emit(this.id);
      });
    }, 200);
  }

  public async onContentChanged(): Promise<void> {
    this.isTextInitialized = true;
    const images = Array.from(
      this.quillEditor.editorElem.querySelectorAll('img[src^="data:"]:not(.loading)')
    ) as HTMLImageElement[];
    for (const image of images) {
      const imageSrc = image.getAttribute('src');
      const resizedImage = await resizeBase64(imageSrc, 400, 400);
      image.setAttribute('src', resizedImage);
    }

    const tagsToPatch = this.quillEditor.editorElem.querySelectorAll(
      'p, li'
    ) as NodeListOf<HTMLElement>;
    const tagsToPatchWithTabs = Array.from(tagsToPatch).filter(tagToPatch =>
      tagToPatch.innerText.includes('\t')
    );
    tagsToPatchWithTabs.forEach(tag => this.applySpacingCorrectionStyles(tag));

    const editorValue = this.quillEditor.editorElem.querySelector('.ql-editor').innerHTML;
    this.text = editorValue;
    this.textChange.emit(editorValue);
  }

  public setInitialValue(quillComponent: Quill): void {
    quillComponent.clipboard.dangerouslyPasteHTML(this.text);
    this.editorCreated.emit(quillComponent);
    this.focusEditor();
  }

  public focusEditor(): void {
    if (this.needFocus) {
      this.quillEditor?.quillEditor?.blur(); // since setTimeout is needed for setSelection to work https://stackoverflow.com/a/70734172 we have to blur first

      setTimeout(() => {
        this.quillEditor?.quillEditor?.setSelection(this.quillEditor?.quillEditor?.getLength(), 0);
        this.quillEditor?.quillEditor?.focus();
      }, 0);
    }
  }

  private applySpacingCorrectionStyles(node: HTMLElement): void {
    node.style.whiteSpace = 'pre-wrap';
    node.style.wordWrap = 'break-word';
  }
}
