/* eslint-disable max-classes-per-file */
import { ActionSendBackAction } from './../../../shared/states/actions/actions.action';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import {
  LeadActionViewModel,
  LeadCompositeViewModel,
  LeadStageViewModel,
  MasterDataUserViewModel,
  MasterDataViewModel,
  TaskViewModel,
  UserInfo,
} from '../../../shared/apis/advis';
import { RightsService } from '../../../shared/services/rights.service';
import { Logger, LoggerService } from '../../../shared/services/logger.service';
import { ConfigService } from '../../../shared/services/config.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as RootReducer from '../../../shared/states/index';
import { TasksLoadAction } from '../../../shared/states/task/task.action';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { map, take } from 'rxjs/operators';
import { NotificationCountColor } from '../../../common-components/notification-count/notification-count.component';
import { LeadUtil } from '../../../shared/utils/lead.util';
import { IPrincipal } from '../../../shared/interfaces/principal.interface';
import { ActionsUtil } from '../../../shared/utils/actions.util';
import ActionTypeEnum = LeadActionViewModel.ActionTypeEnum;
import { StageUtil } from '../../../shared/utils/stage.util';
import { PermissionService } from '../../../shared/services/permission.service';
import { ActionTransferAction } from '../../../shared/states/actions/actions.action';
import { DetailLoadSuccessAction, UnlockLeadAction } from '../../../shared/states/lead/lead.action';
import { StageE } from '../../../shared/models/enum/stage.enum';
import { UrlService } from '../../../shared/services/url.service';
import { routesNames } from '../../../app.component';
import { MainDrawerContentComponent } from '../../../common-components/main-drawer-content/main-drawer-content.component';
import { DialogService, SnackbarService } from '@sitewerk/theia-ui-lib';
import { ErrorTypeE, IErrorInfo } from 'app/shared/states/global/global.reducer';
import { ErrorClearAction } from 'app/shared/states/global/global.action';
import { LeadNotificationService } from 'app/singalR/lead-notification.service';
import { GetAllNotesSuccessAction } from 'app/shared/states/lead-notes/lead-notes.action';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';

export enum DebugClass {
  LOCAL = <any>'local',
  DEVELOPMENT = <any>'development',
  TESTING = <any>'testing',
  PRODUCTION = <any>'production',
}

export class DebugInfo {
  public readonly debugText: string;
  public readonly debugClass: DebugClass;
  public readonly isDisplayed: boolean;

  constructor(text: string, clazz: DebugClass, display: boolean) {
    this.debugText = text;
    this.debugClass = clazz;
    this.isDisplayed = display;
  }
}

@Component({
  selector: 'pc-lead-main',
  templateUrl: './lead-main.component.html',
  styleUrls: ['./lead-main.component.scss'],
})
export class LeadMainComponent implements OnInit, AfterViewInit, OnDestroy {
  public drawerContentComponent: Type<MainDrawerContentComponent> = MainDrawerContentComponent;
  public readonly notificationColorEnum: typeof NotificationCountColor = NotificationCountColor;

  public debugInfo: DebugInfo;

  public isMandantSelected: boolean = true;
  public tasks$: Observable<TaskViewModel[]>;
  public owner: MasterDataUserViewModel = undefined;
  public delegate: MasterDataUserViewModel = undefined;
  public stages: LeadStageViewModel[];
  public email: string = undefined;
  public isReadOnly: boolean;
  public isResponsible: boolean;
  public isOwner: boolean;
  public isDelegate: boolean;
  public isReadOnlyLabelShow: boolean;
  public readOnlyLabel: string;
  public showAssignToMeButton: boolean;
  public showProjectAssignButton: boolean;
  public leadId: number;
  public pagePath: string;
  public leadStage: string;

  protected readonly MainDrawerContentComponent = MainDrawerContentComponent;

  private timerId: number;
  private logger: Logger;
  private subscription: Subscription = new Subscription();
  private mandantSelectedTmr: number;
  private errorDialogVisible: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private store: Store<RootReducer.IState>,
    private router: Router,
    public environment: EnvironmentService,
    private config: ConfigService,
    private loggerService: LoggerService,
    public rights: RightsService,
    private permissions: PermissionService,
    private urlService: UrlService,
    private zone: NgZone,
    private dialogService: DialogService,
    private leadNotificationService: LeadNotificationService,
    private loadingModalService: LoadingModalService,
    private snackbarService: SnackbarService
  ) {
    this.logger = this.loggerService.create(LeadMainComponent.name);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.leadNotificationService.onLeadUpdated().subscribe(lead => {
        this.store
          .select(RootReducer.getLeadDetailEntity)
          .pipe(take(1))
          .subscribe(currentLead => {
            if (currentLead.Lead.Id !== lead.Lead.Id) {
              return;
            }

            this.showLeadUpdatedSnackBar();
            this.store.dispatch(new DetailLoadSuccessAction(lead));
          });
      })
    );

    this.subscription.add(
      this.leadNotificationService.onLeadNotesUpdated().subscribe(notes => {
        this.store
          .select(RootReducer.getLeadDetailEntity)
          .pipe(take(1))
          .subscribe(currentLead => {
            if (currentLead.Lead.Id !== notes.LeadId) {
              return;
            }

            this.showLeadUpdatedSnackBar();
            this.store.dispatch(
              new GetAllNotesSuccessAction({
                leadId: notes.LeadId,
                notes: notes.Notes,
              })
            );
          });
      })
    );

    this.createDebugInfo();

    this.tasks$ = this.store.select(RootReducer.getTasks).pipe(
      map((tasksVm: TaskViewModel[]) => {
        const tasks: TaskViewModel[] = tasksVm || [];
        return tasks.filter((task: TaskViewModel) => {
          return moment(new Date(task.DueDate)).isSameOrBefore(moment.now(), 'date');
        });
      })
    );

    this.subscription.add(
      this.store
        .select((state: RootReducer.IState) => state.auth.loggedIn)
        .subscribe((loggedIn: boolean) => {
          if (loggedIn === true) {
            this.logger.info(
              `Start fetching own open tasks - interval set to="${this.config.apiPollOwnOpenTasksIntervalMs}ms"`
            );
            this.store.dispatch(new TasksLoadAction());

            this.timerId = window.setInterval(() => {
              this.store.dispatch(new TasksLoadAction());
            }, this.config.apiPollOwnOpenTasksIntervalMs);
          } else {
            this.logger.info('Stop fetching own open tasks');
            clearInterval(this.timerId);
          }
        })
    );

    this.subscription.add(
      this.store
        .select<Set<number>>(RootReducer.getGlobalSelectedMandants)
        .subscribe((selectedMandants: Set<number>) => {
          if (selectedMandants && selectedMandants.size > 0) {
            clearTimeout(this.mandantSelectedTmr);
            this.isMandantSelected = true;
          } else {
            this.mandantSelectedTmr = window.setTimeout(() => {
              this.isMandantSelected = false;
            }, 500);
          }
        })
    );

    this.subscription.add(
      this.store.select(RootReducer.getGlobalErrors).subscribe((errors: IErrorInfo[]) => {
        if (errors.length > 0) {
          this.showErrorDialog(errors);
        }
      })
    );

    const loggedInState$: Observable<boolean> = this.store.select(RootReducer.getAuthLoggedIn);
    const userInfo$: Observable<UserInfo> = this.store.select(RootReducer.getUserInfo);
    const leadDetail$: Observable<LeadCompositeViewModel> = this.store.select(
      RootReducer.getLeadDetailEntity
    );
    const principal$: Observable<IPrincipal> = this.store.select<IPrincipal>(
      RootReducer.getAuthPrincipal
    );
    const mapMasterData$: Observable<Map<string, MasterDataUserViewModel>> = this.store.select<
      Map<string, MasterDataUserViewModel>
    >(RootReducer.getGlobalUserMap);
    const masterData$: Observable<MasterDataViewModel> = this.store.select<MasterDataViewModel>(
      RootReducer.getGlobalMasterData
    );
    const currentUrl$: Observable<string> = this.urlService.currentUrl$;

    this.subscription.add(
      combineLatest([
        loggedInState$,
        userInfo$,
        leadDetail$,
        principal$,
        mapMasterData$,
        masterData$,
        currentUrl$,
      ]).subscribe(
        ([isLoggedIn, userInfo, lead, principal, mapMasterData, masterData, currentUrl]: [
          boolean,
          UserInfo,
          LeadCompositeViewModel,
          IPrincipal,
          Map<string, MasterDataUserViewModel>,
          MasterDataViewModel,
          string
        ]) => {
          if (!principal) {
            return;
          }

          this.pagePath = window.location.pathname;
          this.stages = masterData.Stages;

          if (!isLoggedIn) {
            return;
          }

          if (!userInfo) {
            return;
          }

          this.email = userInfo.User;

          if (!currentUrl && !this.pagePath) {
            return;
          }

          if (currentUrl) {
            this.pagePath = currentUrl;
          }

          if (!userInfo.Roles) {
            return;
          }

          if (!lead.Lead) {
            return;
          }
          this.isReadOnly =
            (this.rights.getLeadRights().isReadOnly(lead.Lead) &&
              !StageUtil.isStageInArr(lead.Lead.Stage, [
                StageE.ON_HOLD,
                StageE.PROJECT_ARCHIVED,
              ])) ||
            lead.Lead.IsLocked;
          this.leadId = lead.Lead.Id;
          this.leadStage = lead.Lead.Stage;

          this.isResponsible = LeadUtil.isResponsible(lead.Lead, principal);
          this.isOwner = LeadUtil.isOwner(lead.Lead, principal);
          this.isDelegate = LeadUtil.isDelegate(lead.Lead, principal);

          if (!mapMasterData) {
            return;
          }

          this.owner = mapMasterData.get(lead.Lead.OwnerId);

          if (lead.Lead.DelegateId) {
            this.delegate = mapMasterData.get(lead.Lead.DelegateId);
          }

          const allowedLeadActions: LeadActionViewModel[] = StageUtil.getAllowedActionsForStage(
            lead.Lead.Stage,
            this.stages
          );

          this.showProjectAssignButton =
            ActionsUtil.isAllowed(ActionTypeEnum.AssignProject, allowedLeadActions) &&
            this.permissions.userPermissions.CanAssignProject;
          this.showAssignToMeButton =
            this.rights.getLeadRights().canAssignMe(lead.Lead, this.stages) &&
            this.permissions.userPermissions.CanAssignMe;

          this.updateReadOnlyLabelVisibility();
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    // force a new change detection cycle since change detections
    // have finished when `ngAfterViewInit` is executed
    this.updateReadOnlyLabelVisibility();
    this.cdr.detectChanges();
  }

  public toggleSideNav(manageList: any): void {
    manageList.opened = !manageList.opened;
  }

  public onTask(task: TaskViewModel): void {
    this.loadingModalService.openModal();
    this.router.navigate(['./lead/detail', task.LeadId]);
  }

  public createDebugInfo(): void {
    switch (this.environment.getEnvironmentType()) {
      case this.environment.getLocal():
        this.debugInfo = new DebugInfo(
          this.environment.getLocal().toString(),
          DebugClass.LOCAL,
          true
        );
        break;
      case this.environment.getDev():
        this.debugInfo = new DebugInfo(
          this.environment.getDev().toString(),
          DebugClass.DEVELOPMENT,
          true
        );
        break;
      case this.environment.getTest():
        this.debugInfo = new DebugInfo(
          this.environment.getTest().toString(),
          DebugClass.TESTING,
          true
        );
        break;
      case this.environment.getProd():
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
      default:
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
    }
  }

  public onLeadAssignToMe(): void {
    this.store.dispatch(new ActionTransferAction({ leadId: this.leadId }));
  }

  public onUnlockLead(): void {
    this.store.dispatch(new UnlockLeadAction(this.leadId));
  }

  public onSendBackLead(): void {
    this.store.dispatch(new ActionSendBackAction({ leadId: this.leadId }));
  }

  public navigateToHome(): void {
    this.router.navigate(['/']);
  }

  private updateReadOnlyLabelVisibility(): void {
    this.isReadOnlyLabelShow =
      this.isReadOnly &&
      (this.pagePath.includes('lead/detail/') ||
        this.pagePath.includes('leadaddr/') ||
        this.pagePath.includes(routesNames.LEAD_EDIT_OBJECT + '/') ||
        this.pagePath.includes(routesNames.LEAD_EDIT_DEBITOR + '/')) &&
      this.leadStage !== StageE.WON.toString() &&
      this.leadStage !== StageE.ABORT.toString();
    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        if (this.isReadOnlyLabelShow) {
          if (this.isReadOnly && !this.isResponsible) {
            if (this.delegate) {
              const delegate: string = `${this.delegate.FirstName} ${this.delegate.LastName}`;
              this.readOnlyLabel = this.translate.instant(
                'LEAD_DETAIL.READ_ONLY_LABEL.LEAD_DELEGATED_TO_OTHER_USER',
                { userName: delegate }
              );
            } else {
              const owner: string = this.owner
                ? `${this.owner.FirstName} ${this.owner.LastName}`
                : '_';
              this.readOnlyLabel = this.translate.instant(
                'LEAD_DETAIL.READ_ONLY_LABEL.LEAD_ASSIGN_TO_OTHER_USER',
                { userName: owner }
              );
            }
          } else if (this.isReadOnly && this.isResponsible) {
            this.readOnlyLabel = this.translate.instant(
              'LEAD_DETAIL.READ_ONLY_LABEL.LEAD_LOCK_BY_OFFLINE'
            );
          }
        }
      });
    });
  }

  private showLeadUpdatedSnackBar(): void {
    this.snackbarService.onShowSnackbar({
      color: 'success',
      message: this.translate.instant('LEAD.NOTIFICATION.LEAD_UPDATED'),
    });
  }

  private showErrorDialog(errors: IErrorInfo[]): void {
    let message: string = '';
    if (errors.length === 1) {
      const error: IErrorInfo = errors[0];
      switch (error.type) {
        case ErrorTypeE.SAVE:
          message = this.translate.instant('ERROR.SAVE_FAILED');
          break;

        case ErrorTypeE.LOAD:
          message = this.translate.instant('ERROR.LOAD_FAILED');
          break;

        case ErrorTypeE.PERMISSION:
          message = this.translate.instant('ERROR.PERMISSION');
          break;

        case ErrorTypeE.SESSION_TIMEOUT:
          message = this.translate.instant('ERROR.SESSION_TIMEOUT');
          break;

        case ErrorTypeE.UNDEF:
        default:
          message = this.translate.instant('ERROR.GENERAL_ERRORS');
          break;
      }
    } else if (errors.length > 1) {
      message = this.translate.instant('ERROR.GENERAL_ERRORS');
    }

    if (!this.errorDialogVisible) {
      this.errorDialogVisible = true;
      this.subscription.add(
        this.dialogService
          .openAlert(message)
          .afterClosed()
          .subscribe((accept: boolean) => {
            this.errorDialogVisible = false;
            if (accept) {
              // empty
            } else {
              this.store.dispatch(new ErrorClearAction());
            }
          })
      );
    } else {
      this.logger.warn(`Skipped error message "${message}"`);
    }
  }
}
