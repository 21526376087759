/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProjectFinanceTrafficLightViewModel { 
    Key?: string;
    TrafficLight?: ProjectFinanceTrafficLightViewModel.TrafficLightEnum;
    MinRatioValue?: number;
    MaxRatioValue?: number;
}
export namespace ProjectFinanceTrafficLightViewModel {
    export type TrafficLightEnum = 'None' | 'Light' | 'Strong' | 'Neutral';
    export const TrafficLightEnum = {
        None: 'None' as TrafficLightEnum,
        Light: 'Light' as TrafficLightEnum,
        Strong: 'Strong' as TrafficLightEnum,
        Neutral: 'Neutral' as TrafficLightEnum
    };
}
