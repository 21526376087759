/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PvObjectDataTemplateViewModel } from './pvObjectDataTemplateViewModel';


export interface PvProductlineViewModel { 
    /**
     * Identification of the productline
     */
    Id?: number;
    /**
     * Identification of the productline
     */
    MandatorId?: number;
    /**
     * Name of the productline
     */
    Name?: string;
    /**
     * Description of the productline
     */
    Description?: string;
    ProductLineType?: PvProductlineViewModel.ProductLineTypeEnum;
    /**
     * ObjectData Templates for that productline
     */
    ObjectDataTemplates?: Array<PvObjectDataTemplateViewModel>;
    IsActive?: boolean;
}
export namespace PvProductlineViewModel {
    export type ProductLineTypeEnum = 'Basic' | 'Advanced' | 'HighEnd' | 'SingleArticle' | 'LargeScale';
    export const ProductLineTypeEnum = {
        Basic: 'Basic' as ProductLineTypeEnum,
        Advanced: 'Advanced' as ProductLineTypeEnum,
        HighEnd: 'HighEnd' as ProductLineTypeEnum,
        SingleArticle: 'SingleArticle' as ProductLineTypeEnum,
        LargeScale: 'LargeScale' as ProductLineTypeEnum
    };
}
