/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PersonInfo } from './personInfo';


export interface SendEmailRequest { 
    /**
     * Email template identifier.
     */
    EmailTemplateId: number;
    /**
     * Custom email body.
     */
    CustomBody?: string;
    /**
     * Gets or sets the receivers emails.
     */
    ReceiversEmails: Array<string>;
    CustomEmails?: Array<string>;
    ReceiversInfos: Array<PersonInfo>;
    BccEmail?: string;
    /**
     * if true then configured email address on the template else own email as sender
     */
    UseTemplateEmailAsSender?: boolean;
    /**
     * Required if LeadId is not set
     */
    VariantId?: number;
    /**
     * Required if VariantId is not set
     */
    LeadId?: number;
    /**
     * Required if AttachmentIds are set
     */
    DocumentTemplateIds?: Array<number>;
    AttachmentIds?: Array<number>;
    AttachmentFormat?: SendEmailRequest.AttachmentFormatEnum;
    readonly SenderEmail?: string;
}
export namespace SendEmailRequest {
    export type AttachmentFormatEnum = 'Pdf' | 'Word';
    export const AttachmentFormatEnum = {
        Pdf: 'Pdf' as AttachmentFormatEnum,
        Word: 'Word' as AttachmentFormatEnum
    };
}
