import { animationFrameScheduler, fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { filter, map, mapTo, repeat, withLatestFrom } from 'rxjs/operators';
import { last } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DragScrollerService {
  private isDesktop: boolean = true;

  constructor(private dragulaService: DragulaService, deviceService: DeviceDetectorService) {
    this.isDesktop = deviceService.isDesktop();
  }

  public subscribeToScrollableContainerDrag(selector: string): Subscription {
    const drag$: Observable<boolean> = this.dragulaService.drag().pipe(mapTo(true));
    const dragEnd$: Observable<boolean> = this.dragulaService.dragend().pipe(mapTo(false));
    const dragging$: Observable<boolean> = merge(drag$, dragEnd$);
    const move$ = fromEvent(document, 'mousemove');

    const animate$: Observable<any> = timer(0, animationFrameScheduler).pipe(
      repeat(),
      withLatestFrom(dragging$),
      map((values: any[]) => last(values)),
      filter((dragging: boolean) => dragging),
      withLatestFrom(move$),
      map((values: any[]) => last(values))
    );

    return animate$.subscribe(event => this.onDragging(event, selector));
  }

  private onDragging(event: any, scrollContainerSelector: string): void {
    const drawer: Element = document.querySelector(scrollContainerSelector);
    if (!drawer) {
      return;
    }

    let yPos: number;
    if (this.isDesktop) {
      yPos = event.clientY;
    } else {
      event.preventDefault();
      yPos = event.touches[0].clientY;
    }

    const rect: ClientRect = drawer.getBoundingClientRect();
    const height: number = rect.height;
    const relativeMouseY: number = yPos - rect.top;
    const scrollRange: number = 40;
    const scrollBy: number = 20;

    if (relativeMouseY < scrollRange) {
      drawer.scrollBy(0, -scrollBy);
    } else if (relativeMouseY + scrollRange > height) {
      drawer.scrollBy(0, scrollBy);
    }
  }
}
