/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SsoAccountService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * External user login redirect callback
     * 
     * @param account 
     * @param tempUserId 
     * @param redirectUrl 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssoAccountCompleteLoginExternal(account?: string, tempUserId?: string, redirectUrl?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ssoAccountCompleteLoginExternal(account?: string, tempUserId?: string, redirectUrl?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ssoAccountCompleteLoginExternal(account?: string, tempUserId?: string, redirectUrl?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ssoAccountCompleteLoginExternal(account?: string, tempUserId?: string, redirectUrl?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account !== undefined && account !== null) {
            queryParameters = queryParameters.set('account', <any>account);
        }
        if (tempUserId !== undefined && tempUserId !== null) {
            queryParameters = queryParameters.set('tempUserId', <any>tempUserId);
        }
        if (redirectUrl !== undefined && redirectUrl !== null) {
            queryParameters = queryParameters.set('redirectUrl', <any>redirectUrl);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/account/complete-login-external`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * External user login redirect callback
     * 
     * @param account 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssoAccountCompleteLoginExternalJwt(account?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ssoAccountCompleteLoginExternalJwt(account?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ssoAccountCompleteLoginExternalJwt(account?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ssoAccountCompleteLoginExternalJwt(account?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (account !== undefined && account !== null) {
            queryParameters = queryParameters.set('account', <any>account);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/account/complete-login-external-jwt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssoAccountExchangeOpenIdToken(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ssoAccountExchangeOpenIdToken(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ssoAccountExchangeOpenIdToken(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ssoAccountExchangeOpenIdToken(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/account/exchange-openid-token`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param redirectUrl 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssoAccountSigninMicrosoft(redirectUrl?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ssoAccountSigninMicrosoft(redirectUrl?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ssoAccountSigninMicrosoft(redirectUrl?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ssoAccountSigninMicrosoft(redirectUrl?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (redirectUrl !== undefined && redirectUrl !== null) {
            queryParameters = queryParameters.set('redirectUrl', <any>redirectUrl);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/account/signin-microsoft`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ssoAccountSigninMicrosoftCallback(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ssoAccountSigninMicrosoftCallback(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ssoAccountSigninMicrosoftCallback(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ssoAccountSigninMicrosoftCallback(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/account/signin-microsoft-callback`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
