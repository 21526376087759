import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { ViewSettingItem, ViewSettingService } from '../../services/view-setting.service';
import * as ViewSettingsAction from './view-setting.action';
import { ViewSettingLoadAction, ViewSettingSaveAction } from './view-setting.action';
import { ViewSettingModel } from '../../models/view-setting/view-setting.model';
import { ConfigService } from '../../services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ViewSettingEffects {
  load$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewSettingsAction.LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ViewSettingLoadAction) => action.payload),
      switchMap(() => {
        return this.viewSettingService.load().pipe(
          map(
            (overviewSettings: ViewSettingModel) =>
              new ViewSettingsAction.ViewSettingLoadSuccessAction(overviewSettings)
          ),
          catchError((e: any) => of(new ViewSettingsAction.ViewSettingLoadFailureAction(e)))
        );
      })
    )
  );

  save$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewSettingsAction.SAVE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ViewSettingSaveAction<any>) => action.payload),
      switchMap((viewSettingsItem: ViewSettingItem<any>) => {
        return this.viewSettingService.save(viewSettingsItem).pipe(
          map(
            (vsItem: ViewSettingItem<any>) =>
              new ViewSettingsAction.ViewSettingSaveSuccessAction(vsItem)
          ),
          catchError((e: any) => of(new ViewSettingsAction.ViewSettingLoadFailureAction(e)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private viewSettingService: ViewSettingService,
    private config: ConfigService
  ) {}
}
