/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProjectChecklistStepViewModel { 
    Id?: string;
    IsSubStep?: boolean;
    ParentId?: string;
    Index?: string;
    Title?: string;
    CustomerTitle?: string;
    Link?: string;
    ModifiedById?: string;
    ModifiedDate?: Date;
    Deadline?: Date;
    IsTimeWarning?: boolean;
    IsLockedByRequiredSteps?: boolean;
    RequiredStepIndexes?: string;
    IsVisibleToCustomer?: boolean;
    IsOptional?: boolean;
    CheckedState?: ProjectChecklistStepViewModel.CheckedStateEnum;
    LinkedTaskId?: number;
    DocumentTemplateId?: string;
}
export namespace ProjectChecklistStepViewModel {
    export type CheckedStateEnum = 'Unchecked' | 'NotRequired' | 'Checked';
    export const CheckedStateEnum = {
        Unchecked: 'Unchecked' as CheckedStateEnum,
        NotRequired: 'NotRequired' as CheckedStateEnum,
        Checked: 'Checked' as CheckedStateEnum
    };
}
