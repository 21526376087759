import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as HeatPumpAction from './heating.action';
import { HeatingMasterDataViewModel, MasterDataService } from '../../apis/advis';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class HeatingEffects {
  getMasterData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HeatPumpAction.GET_MASTER_DATA),
      switchMap(() => {
        return this.masterData.pCApiMasterdataGetHeatingMasterData().pipe(
          map((res: HeatingMasterDataViewModel) => {
            return new HeatPumpAction.GetMasterDataSuccessAction(res);
          }),
          catchError((error: any) => of(new HeatPumpAction.GetMasterDataFailedAction(error)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private masterData: MasterDataService) {}
}
