<section class="dialog">
  <h2 class="dialog-title">
    {{ 'LEADS.CHOSE' | translate }}
  </h2>

  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <helion-ui-select
      *ngIf="!data?.isCopyLead"
      placeholder="{{ 'LEADS.CHOSE_MANDANT' | translate }}"
      data-cy="mandatorSelector"
      [ngModel]="selectedMandantId"
      (ngModelChange)="onMandantChange($event)">
      <helion-ui-option
        *ngFor="let mandant of mandants"
        [value]="mandant.Id"
        [label]="mandant.Name"
        data-cy="mandatorSelection">
      </helion-ui-option>
    </helion-ui-select>

    <helion-ui-select
      *ngIf="tradeTypes"
      placeholder="{{ 'LEADS.CHOSE' | translate }}"
      data-cy="tradeSelector"
      [ngModel]="selectedTradeCode"
      (ngModelChange)="onTradeTypeChange($event)">
      <helion-ui-option
        *ngFor="let tradeType of tradeTypes"
        [value]="tradeType.Code"
        [label]="tradeType.Name"
        data-cy="mandatorSelection">
      </helion-ui-option>
    </helion-ui-select>

    <pc-lead-complexity-type-selector
      *ngIf="isComplexityTypeSelectorEnabled"
      [tradeType]="selectedTradeType"
      [selectedComplexityType]="selectedComplexityType"
      (selectedComplexityTypeChange)="
        selectedComplexityType = $event
      "></pc-lead-complexity-type-selector>

    <helion-ui-select
      *ngIf="origins && isOriginSelectorEnabled"
      placeholder="{{ 'LEAD_PERSON.ORIGIN_PLACEHOLDER' | translate }}"
      name="origin"
      [ngModel]="selectedOrigin"
      (ngModelChange)="selectedOrigin = $event">
      <helion-ui-option *ngFor="let item of origins" [value]="item" [label]="item">
      </helion-ui-option>
    </helion-ui-select>
  </div>

  <div class="dialog-actions">
    <button (click)="cancel()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button
      [attr.fill-type]="'contained'"
      (click)="onOk()"
      [disabled]="!isButtonOkEnabled()"
      data-cy="tradeOKButton">
      {{ 'COMMON.BTN_OK' | translate }}
    </button>
  </div>
</section>
