import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { cloneDeep } from 'lodash';

import * as LeadAction from './lead.action';
import {
  AllLoadFailureAction,
  CopyVariantFailedAction,
  DetailAddAppointmentFailureAction,
  DetailAddTaskFailureAction,
  DetailCancelVariantFailureAction,
  DetailLoadEmLeadFailureAction,
  DetailLoadFailureAction,
  DetailLoadHtLeadFailureAction,
  DetailRemoveAppointmentFailureAction,
  DetailRemoveTaskFailureAction,
  DetailUpdateAppointmentFailureAction,
  DetailUpdateTaskFailureAction,
  IAddFinalInvoicesActionPayload,
  IApproveSolarTarifPayload,
  ICreateBillVariantActionPayload,
  IDetailLoad,
  IDialogAddTaskActionPayload,
  IPatchEmLead,
  IPatchHtLead,
  IPatchPvLead,
  IRequestDunningRunBlockerPayload,
  ISynchronizeLeadPayload,
  ITakeoverActionPayload,
  IUpdateInvoiceStatusPayload,
  IUpdateLeadComplexityTypeActionPayload,
  IUpdateLeadTagActionPayload,
  IUpdateVariantActionPayload,
  IUpdateVariantSalesProbabilityActionPayload,
  LoadProjectPropertiesAction,
  LoadProjectPropertiesFailureAction,
  LoadProjectPropertiesSuccessAction,
  OnlinepoolLoadFailureAction,
  OwnLoadFailureAction,
  PatchEmLeadFailedAction,
  PatchHtLeadFailedAction,
  PatchPvLeadAction,
  TriageLoadFailureAction,
  UpdateInvoicesFailureAction,
  UpdateLeadFailureAction,
  UpdateLeadSuccessAction,
  UpdateVariantSalesProbabilityFailureAction,
} from './lead.action';
import * as LeadDocument from './../lead-document/lead-document.action';
import {
  AddressViewModel,
  AppointmentViewModel,
  EmobilityLeadViewModel,
  ExternalSystem,
  HeatingLeadViewModel,
  InvoiceService,
  LeadActionsService,
  LeadCompositeViewModel,
  LeadRequest,
  LeadService,
  LeadSiblingsViewModel,
  LeadViewModel,
  MeierToblerService,
  MtLeadListViewModelPageResultViewModel,
  PageResultViewModelLeadListViewModel,
  PatchVariantInvoiceRequest,
  PvLeadViewModel,
  TaskListViewModel,
  TaskViewModel,
  VariantListCompositionViewModel,
  VariantSearchResultViewModel,
} from '../../apis/advis';
import { ErrorAddAction } from '../global/global.action';
import { ErrorTypeE } from '../global/global.reducer';
import { ConfigService } from '../../services/config.service';
import {
  initDefaultProjectDescription,
  IProjectDescriptionViewModel,
} from '../../models/project-description-view-model';
import { LeadDynamicPropertyViewModel } from 'app/shared/apis/advis/model/leadDynamicPropertyViewModel';
import { NewLeadDataDialogComponent } from '../../../lead/dialog/new-lead-data-dialog/new-lead-data-dialog.component';
import { Router } from '@angular/router';
import { IGenericVariantActionPayload } from '../variant/variant.action';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  debounceTime,
  finalize,
  flatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { LoadingModalService } from '../../services/loading-modal.service';
import * as RootReducer from '../index';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { MtLeadRequest } from '../../models/MtLeadRequest';
import { NotificationService, TypeE } from '../../services/notification.service';
import { AddressValidationMessageBuilderService } from '../../../lead/dialog/theia-import-tool-dialog/address-validation-message-builder.service';
import { StageE } from 'app/shared/models/enum/stage.enum';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Injectable()
export class LeadEffects {
  leadInStore: LeadCompositeViewModel;

  takeover: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.TAKEOVER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.TakeoverAction) => action.payload),
      switchMap((ownTakeoverAction: ITakeoverActionPayload) => {
        return this.leadActionService
          .pCApiLeadActionsTransferMultipleLeads(ownTakeoverAction.leadId, ownTakeoverAction.userId)
          .pipe(
            map(() => {
              // No response from API - take the payload
              return new LeadAction.TakeoverSuccessAction(ownTakeoverAction);
            }),
            catchError((e: any) => of(new LeadAction.TakeoverFailureAction(e)))
          );
      })
    )
  );

  ownMtCrmLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.OWN_MT_CRM_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.OwnLoadAction) => action.payload),
      switchMap((filter: MtLeadRequest) => {
        return this.meierToblerService
          .meierToblerCrmGetCrmLeadList(
            filter.Date,
            filter.MT_CrmVkNr,
            filter.CustomerName,
            filter.PageIndex,
            filter.PageSize,
            filter.SortField,
            filter.SortOrder
          )
          .pipe(
            map((viewModel: MtLeadListViewModelPageResultViewModel) => {
              return new LeadAction.OwnMtCrmLoadSuccessAction(viewModel.Entries);
            }),
            catchError((e: any) => of(new LeadAction.OwnMtCrmLoadFailureAction(e)))
          );
      })
    )
  );

  searchMtCrmLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.SEARCH_MT_CRM_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.SearchMtCrmLoadAction) => action.payload),
      switchMap((filter: MtLeadRequest) => {
        return this.meierToblerService
          .meierToblerCrmGetCrmLeadList(
            filter.Date,
            filter.CustomerName,
            filter.MT_CrmVkNr,
            filter.PageIndex,
            filter.PageSize,
            filter.SortField,
            filter.SortOrder
          )
          .pipe(
            map((viewModel: MtLeadListViewModelPageResultViewModel) => {
              return new LeadAction.SearchMtCrmLoadSuccessAction(viewModel.Entries);
            }),
            catchError((e: any) => of(new LeadAction.SearchMtCrmLoadFailureAction(e)))
          );
      })
    )
  );

  ownLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.OWN_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.OwnLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.OwnLoadSuccessAction(viewModel.Entries);
          }),
          catchError((e: any) => of(new LeadAction.OwnLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  wonLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.WON_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.WonLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.WonLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.WonLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  newLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.NEW_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.NewLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.NewLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.NewLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  onHoldLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ONHOLD_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.OnHoldLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.OnHoldLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.OnHoldLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  followupLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.FOLLOWUP_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.FollowUpLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.FollowUpLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.FollowUpLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  orderEntryLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ORDER_ENTRY_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.FollowUpLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.OrderEntryLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.OrderEntryLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  notAssignedLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.NOT_ASSIGNED_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.NotAssignedLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.NotAssignedLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.NotAssignedLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  projectsInProgressLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PROJECTS_IN_PROGRESS_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.ProjectsInProgressLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.ProjectsInProgressLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.ProjectsInProgressLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  projectOverviewLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PROJECT_OVERVIEW_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.ProjectOverviewLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.ProjectOverviewLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.ProjectOverviewLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  projectOverviewOwnLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PROJECT_OVERVIEW_OWN_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.ProjectOverviewOwnLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.ProjectOverviewOwnLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.ProjectOverviewOwnLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  allLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ALL_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.AllLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((pageResult: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.AllLoadSuccessAction(pageResult);
          }),
          catchError((e: any) => of(new LeadAction.AllLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  energySolutionsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ENERGY_SOLUTIONS_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.EnergySolutionLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((pageResult: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.EnergySolutionLoadSuccessAction(pageResult);
          }),
          catchError((e: any) => of(new LeadAction.EnergySolutionLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  triageLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.TRIAGE_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.TriageLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        // tslint:disable-next-line:max-line-length
        return this.leadService.leadGetLeads(filter).pipe(
          map((pageResult: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.TriageLoadSuccessAction(pageResult);
          }),
          catchError((e: any) => of(new LeadAction.TriageLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  onlinepoolLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ONLINEPOOL_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.OnlinepoolLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        const filterClone = cloneDeep(filter);
        filterClone.Status = ['COMPLETE'];
        return this.leadService.leadGetLeads(filterClone).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.OnlinepoolLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.OnlinepoolLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  callpoolLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.CALLPOOL_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.CallpoolLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        const filterClone = cloneDeep(filter);
        filterClone.Status = ['NEW', 'CALLBACK'];
        return this.leadService.leadGetLeads(filterClone).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.CallpoolLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.CallpoolLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  downloadpoolLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DOWNLOADPOOL_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.DownloadpoolLoadAction) => action.payload),
      switchMap((filter: LeadRequest) => {
        const filterClone = cloneDeep(filter);
        filterClone.Status = ['OFFERDOWNLOAD'];
        return this.leadService.leadGetLeads(filterClone).pipe(
          map((viewModel: PageResultViewModelLeadListViewModel) => {
            return new LeadAction.DownloadLoadSuccessAction(viewModel);
          }),
          catchError((e: any) => of(new LeadAction.DownloadpoolLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  taskListOwnLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.TASKS_LOAD_OWN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.TasksLoadOwnAction) => action.payload),
      switchMap(() => {
        return this.leadService.pCApiLeadControllerGetTaskListForCurrentUser().pipe(
          map((taskList: TaskListViewModel[]) => {
            return new LeadAction.TasksLoadOwnSuccessAction(taskList);
          }),
          catchError((e: any) => of(new LeadAction.TasksLoadOwnFailureAction(e)))
        );
      })
    )
  );

  taskListDelegatedLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.TASKS_LOAD_DELEGATED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.TasksLoadDelegatedAction) => action.payload),
      switchMap(() => {
        return this.leadService.pCApiLeadControllerGetDelegatedTaskListForCurrentUser().pipe(
          map((taskList: TaskListViewModel[]) => {
            return new LeadAction.TasksLoadDelegatedSuccessAction(taskList);
          }),
          catchError((e: any) => of(new LeadAction.TasksLoadDelegatedFailureAction(e)))
        );
      })
    )
  );

  detailLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailLoadAction) => action.payload),
      switchMap((payload: IDetailLoad) => {
        let leadSiblingsInStore: LeadSiblingsViewModel = {};
        this.store
          .select((storeState: RootReducer.IState) => storeState.lead.leadsDetail)
          .pipe(take(1))
          .subscribe((lead: LeadCompositeViewModel) => {
            this.leadInStore = lead;
          });

        this.store
          .select((storeState: RootReducer.IState) => storeState.lead.leadSiblings)
          .pipe(take(1))
          .subscribe((siblings: LeadSiblingsViewModel) => {
            leadSiblingsInStore = siblings;
          });
        if (
          !isEmpty(this.leadInStore) &&
          payload.leadId === this.leadInStore.Lead.Id &&
          !isNotNullOrUndefined(payload.useForceDetailLoad) &&
          !payload.useForceDetailLoad
        ) {
          return [
            new LeadAction.SiblingsLoadSuccessAction(leadSiblingsInStore),
            new LeadAction.DetailLoadSuccessAction(this.leadInStore),
          ];
        }
        return this.leadService.pCApiLeadGetLead(payload.leadId).pipe(
          flatMap((lead: LeadCompositeViewModel) => [
            new LeadAction.LoadProjectPropertiesAction(lead.Lead.Id),
            new LeadDocument.GetFilesAction({ leadId: lead.Lead.Id }),
            new LeadAction.SiblingsLoadAction(lead.Lead.Id),
            new LeadAction.DetailLoadSuccessAction(lead),
          ]),
          catchError((e: any) => of(new LeadAction.DetailLoadFailureAction(e)))
        );
      })
    )
  );

  siblingsLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.SIBLINGS_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      tap(this.doRegisterLoading()),
      map((action: LeadAction.SiblingsLoadAction) => action.payload),
      switchMap((payload: number) => {
        return this.leadService.pCApiLeadGetLeadSiblings(payload).pipe(
          map(
            (siblings: LeadSiblingsViewModel) => new LeadAction.SiblingsLoadSuccessAction(siblings)
          ),
          catchError((e: any) => of(new LeadAction.SiblingsLoadFailureAction(e))),
          finalize(this.doResolveLoading())
        );
      })
    )
  );

  detailLoadEmLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_LOAD_EM_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailLoadEmLeadAction) => action.payload),
      switchMap((id: number) => {
        return this.leadService.emobilityLeadGetEmobilityLead(id).pipe(
          map((eMobilityLead: EmobilityLeadViewModel) => {
            return new LeadAction.DetailLoadEmLeadSuccessAction(eMobilityLead);
          }),
          catchError((e: any) => of(new LeadAction.DetailLoadEmLeadFailureAction(e)))
        );
      })
    )
  );

  patchEmLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PATCH_EM_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.PatchEmLeadAction) => action.payload),
      switchMap((data: IPatchEmLead) => {
        return this.leadService.emobilityLeadPatchEmobilityLead(data.leadId, data.request).pipe(
          map((eMobilityLead: EmobilityLeadViewModel) => {
            return new LeadAction.PatchEmLeadSuccessAction(eMobilityLead);
          }),
          catchError((e: any) => of(new LeadAction.PatchEmLeadFailedAction(e)))
        );
      })
    )
  );

  detailLoadHtLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_LOAD_HT_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailLoadHtLeadAction) => action.payload),
      switchMap((id: number) => {
        return this.leadService.heatingLeadGetHeatingLead(id).pipe(
          map((heatingLead: HeatingLeadViewModel) => {
            return new LeadAction.DetailLoadHtLeadSuccessAction(heatingLead);
          }),
          catchError((e: any) => of(new LeadAction.DetailLoadHtLeadFailureAction(e)))
        );
      })
    )
  );

  patchHtLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PATCH_HT_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.PatchHtLeadAction) => action.payload),
      switchMap((data: IPatchHtLead) => {
        return this.leadService.heatingLeadPatchHeatingLead(data.leadId, data.request).pipe(
          map((heatingLead: HeatingLeadViewModel) => {
            return new LeadAction.PatchHtLeadSuccessAction(heatingLead);
          }),
          catchError((e: any) => of(new LeadAction.PatchHtLeadFailedAction(e)))
        );
      })
    )
  );

  detailLoadPvLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_LOAD_PV_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailLoadPvLeadAction) => action.payload),
      switchMap((id: number) => {
        return this.leadService.pvLeadGetPvLead(id).pipe(
          map((pvLead: PvLeadViewModel) => {
            return new LeadAction.DetailLoadPvLeadSuccessAction(pvLead);
          }),
          catchError((e: any) => of(new LeadAction.DetailLoadPvLeadFailureAction(e)))
        );
      })
    )
  );

  patchPvLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.PATCH_PV_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.PatchPvLeadAction) => action.payload),
      switchMap((data: IPatchPvLead) => {
        return this.leadService.pvLeadPatchPvLead(data.leadId, data.request).pipe(
          map((pvLead: PvLeadViewModel) => {
            return new LeadAction.PatchPvLeadSuccessAction(pvLead);
          }),
          catchError((e: any) => of(new LeadAction.PatchPvLeadFailedAction(e)))
        );
      })
    )
  );

  addTask: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ADD_TASK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.AddTaskAction) => action.payload),
      switchMap((task: TaskViewModel) => {
        return this.leadService.leadAddTask(task.LeadId, { TaskToAdd: task }).pipe(
          map((lead: LeadCompositeViewModel) => new LeadAction.AddTaskSuccessAction(lead)),
          catchError((e: any) => of(new LeadAction.AddTaskFailureAction(e)))
        );
      })
    )
  );

  detailAddTask: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_ADD_TASK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailAddTaskAction) => action.payload),
      switchMap((task: TaskViewModel) => {
        return this.leadService.leadAddTask(task.LeadId, { TaskToAdd: task }).pipe(
          map((lead: LeadCompositeViewModel) => new LeadAction.DetailAddTaskSuccessAction(lead)),
          catchError((e: any) => of(new LeadAction.DetailAddTaskFailureAction(e)))
        );
      })
    )
  );

  dialogAddTask: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DIALOG_ADD_TASK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DialogAddTaskAction) => action.payload),
      switchMap((payload: IDialogAddTaskActionPayload) => {
        return this.leadService
          .leadAddTask(payload.newTask.LeadId, {
            TaskToAdd: payload.newTask,
            PredecessorTaskIdToClose: payload.predecessorTaskIdToClose,
          })
          .pipe(
            map((lead: LeadCompositeViewModel) => new LeadAction.DialogAddTaskSuccessAction(lead)),
            catchError((e: any) => of(new LeadAction.DetailAddTaskFailureAction(e)))
          );
      })
    )
  );

  detailUpdateTask: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_UPDATE_TASK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailUpdateTaskAction) => action.payload),
      switchMap((task: TaskViewModel) => {
        return this.leadService.leadUpdateTask(task.LeadId, task.Id, task).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.DetailUpdateTaskSuccessAction(lead);
          }),
          catchError((e: any) => of(new LeadAction.DetailUpdateTaskFailureAction(e)))
        );
      })
    )
  );

  detailRemoveTask: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_REMOVE_TASK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailRemoveTaskAction) => action.payload),
      switchMap((task: TaskViewModel) => {
        return this.leadService.leadRemoveTask(task.LeadId, task.Id).pipe(
          map((leadComposite: LeadCompositeViewModel) => {
            return new LeadAction.DetailRemoveTaskSuccessAction(leadComposite);
          }),
          catchError((e: any) => of(new LeadAction.DetailRemoveTaskFailureAction(e)))
        );
      })
    )
  );

  addAppointment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ADD_APPOINTMENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.AddAppointmentAction) => action.payload),
      switchMap((appointment: AppointmentViewModel) => {
        return this.leadService.leadAddAppointment(appointment.LeadId, appointment).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.AddAppointmentSuccessAction(lead);
          }),
          catchError((e: any) => of(new LeadAction.AddAppointmentFailureAction(e)))
        );
      })
    )
  );

  detailAddAppointment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_ADD_APPOINTMENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailAddAppointmentAction) => action.payload),
      switchMap((appointment: AppointmentViewModel) => {
        return this.leadService.leadAddAppointment(appointment.LeadId, appointment).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.DetailAddAppointmentSuccessAction(lead);
          }),
          catchError((e: any) => of(new LeadAction.DetailAddAppointmentFailureAction(e)))
        );
      })
    )
  );

  detailUpdateAppointment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_UPDATE_APPOINTMENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailUpdateAppointmentAction) => action.payload),
      switchMap((appointment: AppointmentViewModel) => {
        return this.leadService
          .leadUpdateAppointment(appointment.LeadId, appointment.Id, appointment)
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              return new LeadAction.DetailUpdateAppointmentSuccessAction(lead);
            }),
            catchError((e: any) => of(new LeadAction.DetailUpdateAppointmentFailureAction(e)))
          );
      })
    )
  );

  detailRemoveAppointment: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_REMOVE_APPOINTMENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailRemoveAppointmentAction) => action.payload),
      switchMap((appointment: AppointmentViewModel) => {
        return this.leadService.leadRemoveAppointment(appointment.LeadId, appointment.Id).pipe(
          map((leadComposite: LeadCompositeViewModel) => {
            return new LeadAction.DetailRemoveAppointmentSuccessAction(leadComposite);
          }),
          catchError((e: any) => of(new LeadAction.DetailRemoveAppointmentFailureAction(e)))
        );
      })
    )
  );

  detailAddFinalInvoices: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.ADD_FINAL_INVOICES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.AddFinalInvoicesAction) => action.payload),
      switchMap((finalinvoiceRequest: IAddFinalInvoicesActionPayload) => {
        return this.leadService
          .pCApiLeadPostLeadVariantFinalInvoices(
            finalinvoiceRequest.variantId,
            finalinvoiceRequest.request
          )
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              return new LeadAction.AddFinalInvoicesSuccessAction(lead);
            }),
            catchError((e: any) => of(new LeadAction.AddFinalInvoicesFailureAction(e)))
          );
      })
    )
  );

  detailUpdateInvoices: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_INVOICES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateInvoicesAction) => action.payload),
      switchMap((invoice: PatchVariantInvoiceRequest[]) => {
        return this.leadService.pCApiLeadPatchLeadVariantInvoices(invoice).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.UpdateInvoicesSuccessAction(lead);
          }),
          catchError((e: any) => of(new LeadAction.UpdateInvoicesFailureAction(e)))
        );
      })
    )
  );

  updateInvoiceStatus: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_INVOICE_STATUS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateInvoicesStatusAction) => action.payload),
      switchMap((payload: IUpdateInvoiceStatusPayload) => {
        return (
          payload.isFinal
            ? this.leadService.pCApiLeadPatchLeadVariantFinalInvoiceStatus(
                payload.variantId,
                payload.invoiceId,
                payload.body
              )
            : this.leadService.pCApiLeadPatchLeadVariantPartialInvoiceStatus(
                payload.variantId,
                payload.invoiceId,
                payload.body
              )
        ).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.UpdateInvoicesStatusSuccessAction(lead);
          }),
          catchError((e: any) => of(new LeadAction.UpdateInvoicesStatusFailureAction(e)))
        );
      })
    )
  );

  requestDunningRunBlocker: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.REQUEST_DUNNING_RUN_BLOCKER),
      tap(this.doRegisterLoading()),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.RequestDunningRunBlockerAction) => action.payload),
      switchMap((payload: IRequestDunningRunBlockerPayload) => {
        const body = `"${payload.note}"`;
        return this.invoiceService
          .invoiceSetDunningRunBlocker(payload.leadId, payload.invoiceId, body)
          .pipe(
            map(
              (lead: LeadCompositeViewModel) =>
                new LeadAction.RequestDunningRunBlockerSuccessAction(lead)
            ),
            catchError(e => of(new LeadAction.RequestDunningRunBlockerFailureAction(e))),
            finalize(this.doResolveLoading())
          );
      })
    )
  );

  cancelVariant: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_CANCEL_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailCancelVariantAction) => action.payload),
      switchMap((variant: VariantListCompositionViewModel) => {
        return this.leadService.pCApiLeadDeleteLeadVariant(variant.LeadVariant.Id).pipe(
          map(
            (leadComposite: LeadCompositeViewModel) =>
              new LeadAction.DetailCancelVariantSuccessAction(leadComposite)
          ),
          catchError((e: any) => of(new LeadAction.DetailCancelVariantFailureAction(e)))
        );
      })
    )
  );

  wonVariant: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.WON_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.WonVariantAction) => action.payload),
      switchMap((variant: VariantListCompositionViewModel) => {
        return this.leadService.pCApiLeadProjectOfferVariantToWon(variant.LeadVariant.Id).pipe(
          map(
            (leadComposite: LeadCompositeViewModel) =>
              new LeadAction.DetailCancelVariantSuccessAction(leadComposite)
          ),
          catchError((e: any) => of(new LeadAction.DetailCancelVariantFailureAction(e)))
        );
      })
    )
  );

  // TODO FIX? pCApiLeadCloneVariant

  detailCloneVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.DETAIL_CLONE_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.DetailCloneVariantAction) => action.payload),
      switchMap((payload: IGenericVariantActionPayload) => {
        return this.leadService.pCApiLeadCopyVariant(payload.variantId).pipe(
          map(
            (leadComposite: LeadCompositeViewModel) =>
              new LeadAction.DetailCloneVariantSuccessAction(leadComposite)
          ),
          catchError((e: any) => of(new LeadAction.DetailCloneVariantFailedAction(e)))
        );
      })
    )
  );

  copyVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.COPY_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.CopyVariantAction) => action.payload),
      switchMap((payload: any) => {
        return this.leadService.pCApiLeadCopyVariant(payload.variantId, payload.leadId).pipe(
          map(
            (leadComposite: LeadCompositeViewModel) =>
              new LeadAction.CopyVariantSuccessAction(leadComposite)
          ),
          catchError((e: any) => of(new LeadAction.CopyVariantFailedAction(e)))
        );
      })
    )
  );

  createBillVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.BILL_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.BillVariantAction) => action.payload),
      switchMap((payload: ICreateBillVariantActionPayload) => {
        return this.leadService
          .pCApiLeadCreateFreeBillVariant(payload.leadId, payload.freeBillType)
          .pipe(
            map(
              (leadComposite: LeadCompositeViewModel) =>
                new LeadAction.CopyVariantSuccessAction(leadComposite)
            ),
            catchError((e: any) => of(new LeadAction.CopyVariantFailedAction(e)))
          );
      })
    )
  );

  approveSolarTarif$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.APPROVE_SOLAR_TARIF),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.ApproveSolarTarifAction) => action.payload),
      switchMap((payload: IApproveSolarTarifPayload) => {
        return this.leadActionService
          .pCApiLeadActionsApproveSolarTarif(payload.leadId, payload.approved)
          .pipe(
            map(
              (leadComposite: LeadCompositeViewModel) =>
                new LeadAction.ApproveSolarTarifSuccessAction(leadComposite)
            ),
            catchError((e: any) => of(new LeadAction.ApproveSolarTarifFailedAction(e)))
          );
      })
    )
  );

  searchVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.SEARCH_VARIANT),
      map((action: LeadAction.SearchVariantAction) => action.payload),
      switchMap((payload: any) => {
        return this.leadService
          .pCApiLeadGetLeadVariants(payload.searchRequest, payload.tradeType, payload.mandatorId)
          .pipe(
            map((variantsSearchListViewModel: VariantSearchResultViewModel) => {
              if (
                variantsSearchListViewModel.IsLeadFromWrongMandator ||
                variantsSearchListViewModel.IsLeadFromWrongTradeType
              ) {
                this.dialogService.openAlert(
                  this.translate.instant('LEADS.DIALOG_LEAD_FROM_WRONG_MANDATOR_OR_TRADE')
                );
              }
              return new LeadAction.SearchVariantSuccessAction(
                variantsSearchListViewModel.Variants
              );
            }),
            catchError((error: any) => of(new LeadAction.SearchVariantFailedAction(error)))
          );
      })
    )
  );

  updateLeadComplexityType$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadAction.UPDATE_LEAD_COMPLEXITY_TYPE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateLeadComplexityTypeAction) => action.payload),
      switchMap((payload: IUpdateLeadComplexityTypeActionPayload) => {
        const body: string = `"${payload.complexityType}"`;
        return this.leadService.pCApiLeadPatchLeadComplexityType(payload.leadId, body).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new LeadAction.UpdateLeadComplexityTypeSuccessAction({
                leadId: lead.Lead.Id,
                complexityType: lead.Lead.ComplexityType,
              })
          ),
          catchError((e: any) => of(new LeadAction.UpdateLeadComplexityTypeFailureAction(e)))
        );
      })
    );
  });

  updateLeadTag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_LEAD_TAG),
      tap(this.doRegisterLoading()),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateLeadTagAction) => action.payload),
      switchMap((payload: IUpdateLeadTagActionPayload) => {
        return this.leadService.pCApiLeadPatchLeadTags(payload.leadId, payload.tag).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new LeadAction.UpdateLeadTagSuccessAction({
                leadId: lead.Lead.Id,
                tag: lead.Lead.Tag,
              } as IUpdateLeadTagActionPayload)
          ),
          tap(this.doResolveLoading()),
          catchError((e: any) => of(new LeadAction.UpdateLeadTagFailureAction(e)))
        );
      })
    )
  );

  updateLead$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_LEAD),
      tap(this.doRegisterLoading()),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateLeadAction) => action.payload),
      switchMap((payload: LeadAction.IUpdateLeadActionPayload) => {
        return this.leadService.pCApiLeadPatchLead(payload.leadId, payload.request).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new UpdateLeadSuccessAction({
                leadId: lead.Lead.Id,
                lead: lead.Lead,
              } as LeadViewModel)
          ),
          tap(this.doResolveLoading()),
          catchError((e: any) => of(new LeadAction.UpdateLeadFailureAction(e)))
        );
      })
    )
  );

  updateVariantSalesProbability$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_VARIANT_SALES_PROBABILITY),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateVariantSalesProbabilityAction) => action.payload),
      switchMap((payload: IUpdateVariantSalesProbabilityActionPayload) => {
        return this.leadService
          .pCApiLeadPatchLeadVariant(payload.variantId, {
            SalesProbability: payload.salesProbability,
          })
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              if (lead.Lead.Stage === StageE.FOLLOWUP_BO.toString()) {
                this.store.dispatch(
                  new LeadAction.SynchronizeLeadAction({
                    leadId: lead.Lead.Id,
                    leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                  })
                );
              }

              return new LeadAction.UpdateVariantSalesProbabilitySuccessAction({
                leadId: lead.Lead.Id,
                variantId: payload.variantId,
                salesProbability: payload.salesProbability,
              });
            }),
            catchError((e: any) => of(new LeadAction.UpdateVariantSalesProbabilityFailureAction(e)))
          );
      })
    )
  );

  updateVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UPDATE_VARIANT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UpdateVariantAction) => action.payload),
      switchMap((payload: IUpdateVariantActionPayload) => {
        return this.leadService.pCApiLeadPatchLeadVariant(payload.variantId, payload.body).pipe(
          map((lead: LeadCompositeViewModel) => {
            if (lead.Lead.Stage === StageE.FOLLOWUP_BO.toString()) {
              this.store.dispatch(
                new LeadAction.SynchronizeLeadAction({
                  leadId: lead.Lead.Id,
                  leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                })
              );
            }

            return new LeadAction.UpdateVariantSuccessAction({
              leadId: lead.Lead.Id,
              variantId: payload.variantId,
              body: payload.body,
            });
          }),
          catchError((e: any) => of(new LeadAction.UpdateVariantFailedAction(e)))
        );
      })
    )
  );

  createLeadFromExisting$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.CREATE_LEAD_FROM_EXISTING),
      mergeMap((a: LeadAction.CreateLeadFromExistingAction) => {
        if (!a.payload.leadId) {
          const error: string = 'Either (origin and personId) or leadId is mandatory';
          console.error(error);
          throw new Error(error);
        }

        this.doRegisterLoading();
        return this.leadService.pCApiLeadGetLead(a.payload.leadId).pipe(
          tap((lead: LeadCompositeViewModel) => {
            this.doResolveLoading();

            if (
              !lead.Lead.ContactAddress.MainContact.Emails?.length &&
              !lead.Lead.ContactAddress.Person.Emails?.length &&
              !lead.Lead.ContactAddress.Company.Emails?.length
            ) {
              this.dialogService.openAlert(
                this.translate.instant('ADDRESS.DIALOGS.MISSING_EMAIL.MESSAGE'),
                this.translate.instant('ADDRESS.DIALOGS.MISSING_EMAIL.TITLE')
              );
              throw new Error('Missing email address');
            }

            return of(lead);
          }),
          catchError((error: any) => {
            this.doResolveLoading();
            return of(error);
          }),
          map((lead: LeadCompositeViewModel) => {
            return new LeadAction.CreateLeadFromExistingAction({
              origin: lead.Lead.Origin,
              personId: lead.Lead.ContactAddress.Id,
              mandantId: lead.Lead.MandantId,
              leadId: lead.Lead.Id,
            });
          })
        );
      }),
      flatMap((a: LeadAction.CreateLeadFromExistingAction) =>
        this.dialogService
          .open(NewLeadDataDialogComponent, {
            data: {
              mandantId: a.payload.mandantId,
              tradeType: a.payload.tradeType,
              isCopyLead: true,
              leadId: a.payload.leadId,
            },
          })
          .afterClosed()
          .pipe(
            mergeMap((data: any) => {
              if (!data) {
                return of(new LeadAction.CreateLeadFromExistingCancelledAction());
              }

              this.doRegisterLoading();

              return this.leadService
                .pCApiLeadCreateFromExistingLead(a.payload.leadId, data.tradeType.Code)
                .pipe(
                  tap(() => this.doResolveLoading()),
                  map(
                    (leadComposite: LeadCompositeViewModel) =>
                      new LeadAction.CreateLeadFromExistingSuccessAction(leadComposite)
                  )
                );
            }),
            catchError((error: any) => {
              this.doResolveLoading();
              console.error('Failed to create lead from existing lead', error);
              return of(new LeadAction.CreateLeadFromExistingFailureAction(error));
            })
          )
      )
    )
  );

  createLeadFromExistingSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LeadAction.CREATE_LEAD_FROM_EXISTING_SUCCESS),
        flatMap((action: LeadAction.CreateLeadFromExistingSuccessAction) => {
          this.router.navigate(['lead/detail', action.payload.Lead.Id]);
          return of({ type: 'NOOP' });
        })
      ),
    { dispatch: false }
  );

  // Failure handling

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LeadAction.DETAIL_LOAD_FAILURE,
        LeadAction.ALL_LOAD_FAILURE,
        LeadAction.ENERGY_SOLUTIONS_LOAD_FAILURE,
        LeadAction.OWN_LOAD_FAILURE,
        LeadAction.ONLINEPOOL_LOAD_FAILURE,
        LeadAction.TRIAGE_LOAD_FAILURE,
        LeadAction.DETAIL_LOAD_EM_LEAD_FAILURE,
        LeadAction.DETAIL_LOAD_HT_LEAD_FAILURE
      ),
      debounceTime(this.config.apiDebounceTimeMs),
      map(
        (
          action:
            | DetailLoadFailureAction
            | AllLoadFailureAction
            | OwnLoadFailureAction
            | OnlinepoolLoadFailureAction
            | TriageLoadFailureAction
            | DetailLoadEmLeadFailureAction
            | DetailLoadHtLeadFailureAction
        ) => {
          return new ErrorAddAction({ type: ErrorTypeE.LOAD, data: action.payload });
        }
      )
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LeadAction.DETAIL_CANCEL_VARIANT_FAILURE,
        LeadAction.DETAIL_CLONE_VARIANT_FAILURE,
        LeadAction.DETAIL_ADD_TASK_FAILURE,
        LeadAction.DETAIL_UPDATE_TASK_FAILURE,
        LeadAction.DETAIL_REMOVE_TASK_FAILURE,
        LeadAction.DETAIL_REMOVE_APPOINTMENT_FAILURE,
        LeadAction.UPDATE_VARIANT_SALES_PROBABILITY_FAILURE,
        LeadAction.UPDATE_LEAD_FAILURE,
        LeadAction.CREATE_LEAD_FROM_EXISTING_FAILURE,
        LeadAction.COPY_VARIANT_FAILURE,
        LeadAction.DETAIL_UPDATE_APPOINTMENT_FAILURE,
        LeadAction.DETAIL_ADD_APPOINTMENT_FAILURE,
        LeadAction.PATCH_EM_LEAD_FAILURE,
        LeadAction.PATCH_HT_LEAD_FAILURE,
        LeadAction.PATCH_PV_LEAD_FAILURE,
        LeadAction.UPDATE_INVOICES_FAILURE
      ),
      debounceTime(this.config.apiDebounceTimeMs),
      map(
        (
          action:
            | DetailCancelVariantFailureAction
            | DetailAddTaskFailureAction
            | DetailUpdateTaskFailureAction
            | DetailRemoveTaskFailureAction
            | DetailUpdateAppointmentFailureAction
            | UpdateVariantSalesProbabilityFailureAction
            | UpdateLeadFailureAction
            | CopyVariantFailedAction
            | DetailRemoveAppointmentFailureAction
            | DetailAddAppointmentFailureAction
            | PatchEmLeadFailedAction
            | PatchHtLeadFailedAction
            | PatchPvLeadAction
            | UpdateInvoicesFailureAction
        ) => {
          return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
        }
      )
    )
  );

  loadProjectProperties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.LOAD_PROJECT_PROPERTIES),
      tap(this.doRegisterLoading()),
      map((a: LoadProjectPropertiesAction) => a.payload),
      mergeMap((leadId: number) =>
        this.leadService.leadDynamicPropertiesGetDynamicPropertiesForLead(leadId)
      ),
      map((properties: LeadDynamicPropertyViewModel[]) => {
        const property: LeadDynamicPropertyViewModel = properties.find(
          (p: any) => p.PropertyName === 'projectDescription'
        );
        return !property || !property.PropertyValue || property.PropertyValue === ''
          ? undefined
          : { ...JSON.parse(property.PropertyValue), isDefault: false };
      }),
      map(
        (properties: any = initDefaultProjectDescription()) =>
          new LoadProjectPropertiesSuccessAction(<IProjectDescriptionViewModel>properties)
      ),
      tap(this.doResolveLoading()),
      catchError((error: any) => {
        this.doResolveLoading();
        console.error('Failed to load properties: ', error);
        return of(new LoadProjectPropertiesFailureAction(error));
      })
    )
  );

  storeProjectProperties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.STORE_PROJECT_PROPERTIES),
      tap(this.doRegisterLoading()),
      map((a: LeadAction.StoreProjectPropertiesAction) => a.payload),
      mergeMap((payload: LeadAction.IStoreProjectPropertiesPayload) =>
        this.leadService
          .leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty(
            payload.leadId,
            'projectDescription',
            JSON.stringify(payload.properties)
          )
          .pipe(map(() => new LeadAction.StoreProjectPropertiesSuccessAction(payload.properties)))
      ),
      tap(this.doResolveLoading()),
      catchError((error: any) => {
        this.doResolveLoading();
        return of(new LeadAction.StoreProjectPropertiesFailureAction(error));
      })
    )
  );

  lockLead: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.LOCK_LEAD),
      tap(this.doRegisterLoading()),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.LockLeadAction) => action.payload),
      switchMap((payload: number) => {
        return this.leadService.leadLockLead(payload).pipe(
          map(
            (lead: LeadCompositeViewModel) => new LeadAction.LockLeadSuccessAction(lead),
            catchError((e: any) => of(new LeadAction.LockLeadFailureAction(e)))
          )
        );
      }),
      tap(this.doResolveLoading())
    )
  );

  unlockLead: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.UNLOCK_LEAD),
      tap(this.doRegisterLoading()),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.UnlockLeadAction) => action.payload),
      switchMap((payload: number) => {
        return this.leadService.leadUnlockLead(payload).pipe(
          map(
            (lead: LeadCompositeViewModel) => new LeadAction.UnlockLeadSuccessAction(lead),
            catchError((e: any) => of(new LeadAction.UnlockLeadFailureAction(e)))
          )
        );
      }),
      tap(this.doResolveLoading())
    )
  );

  synchronizeLead: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadAction.SYNCHRONIZE_LEAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadAction.SynchronizeLeadAction) => action.payload),
      switchMap((payload: ISynchronizeLeadPayload) => {
        const externalSystems = Array.isArray(payload.leadSynchronizationToExternalSystem)
          ? payload.leadSynchronizationToExternalSystem
          : [payload.leadSynchronizationToExternalSystem];

        const apiCalls$ = combineLatest(
          externalSystems.map(externalSystem =>
            this.leadService.leadLeadSynchronizeWithTheiaBridge(payload.leadId, externalSystem)
          )
        );

        return apiCalls$.pipe(
          map(() => {
            this.notificationService.notifySimple(
              this.translate.instant('COMMON.LEAD_SYNCHRONIZED'),
              TypeE.PRIMARY
            );
            return new LeadAction.SynchronizeLeadSuccessAction();
          }),
          catchError((e: any) => {
            this.doResolveLoading();
            this.notificationService.notifySimple(
              this.translate.instant('COMMON.LEAD_SYNCHRONIZATION_FAILED'),
              TypeE.ALERT
            );
            if (e.status === 412) {
              const invalidAddresses = JSON.parse(e.error) as AddressViewModel[];
              if (Array.isArray(invalidAddresses) && invalidAddresses.length > 0) {
                const message =
                  this.addressValidationMessageBuilderService.getInvalidLeadAddressesWarningMessage(
                    invalidAddresses,
                    payload.leadId
                  );
                this.dialogService.openAlert(
                  message,
                  this.translate.instant('DIALOG.TITLE_WARN'),
                  this.translate.instant('COMMON.BTN_OK'),
                  true
                );
              }
            }
            return of(new LeadAction.SynchronizeLeadFailureAction(e));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private leadService: LeadService,
    private meierToblerService: MeierToblerService,
    private leadActionService: LeadActionsService,
    private config: ConfigService,
    private loadingModalService: LoadingModalService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private store: Store<RootReducer.IState>,
    private notificationService: NotificationService,
    private router: Router,
    private addressValidationMessageBuilderService: AddressValidationMessageBuilderService,
    private invoiceService: InvoiceService
  ) {
    // empty
  }

  private doRegisterLoading(): any {
    return () => this.loadingModalService.openModal();
  }

  private doResolveLoading(): any {
    return () => this.loadingModalService.closeModal();
  }
}
