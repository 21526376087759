import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewSettingService } from '../../shared/services/view-setting.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-privacy-mode-switcher',
  templateUrl: './privacy-mode-switcher.component.html',
  styleUrls: ['./privacy-mode-switcher.component.scss'],
})
export class PrivacyModeSwitcherComponent implements OnInit, OnDestroy {
  public isPrivacyMode: boolean;
  private subscription: Subscription = new Subscription();
  constructor(private vsService: ViewSettingService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.vsService.$IsPrivacyMode.subscribe(value => (this.isPrivacyMode = value))
    );
  }

  onChangeMode(change: boolean): void {
    this.vsService.setPrivacyMode(change);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
