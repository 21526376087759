import { Action } from '@ngrx/store';
import {
  AppointmentViewModel,
  AutoOfferResult,
  EmobilityLeadViewModel,
  ExternalSystem,
  HeatingLeadViewModel,
  LeadCompositeViewModel,
  LeadListViewModel,
  LeadRequest,
  LeadSiblingsViewModel,
  LeadViewModel,
  MtLeadListViewModel,
  PageResultViewModelLeadListViewModel,
  PatchLeadVariantRequest,
  PatchLeadViewModel,
  PatchVariantInvoiceRequest,
  PatchVariantInvoiceStatusRequest,
  PostFinalInvoiceRequest,
  PvAutoOfferRequest,
  PvLeadViewModel,
  TaskListViewModel,
  TaskViewModel,
  VariantListCompositionViewModel,
  VariantListViewModel,
} from '../../apis/advis';
import { IProjectDescriptionViewModel } from '../../models/project-description-view-model';
import { IGenericVariantActionPayload } from '../variant/variant.action';
import { MtLeadRequest } from '../../models/MtLeadRequest';

export const TAKEOVER: string = '[Lead] Takeover';
export const TAKEOVER_SUCCESS: string = '[Lead] Takeover Success';
export const TAKEOVER_FAILURE: string = '[Lead] Takeover Failure';

export const OWN_LOAD: string = '[Lead] Own - Load';
export const OWN_LOAD_SUCCESS: string = '[Lead] Own - Load Success';
export const OWN_LOAD_FAILURE: string = '[Lead] Own - Load Failure';

export const FOLLOWUP_LOAD: string = '[Lead] Follow Up - Load';
export const FOLLOWUP_LOAD_SUCCESS: string = '[Lead] Follow Up - Load Success';
export const FOLLOWUP_LOAD_FAILURE: string = '[Lead] Follow Up - Load Failure';

export const ORDER_ENTRY_LOAD: string = '[Lead] Order entry - Load';
export const ORDER_ENTRY_LOAD_SUCCESS: string = '[Lead] Order entry - Load Success';
export const ORDER_ENTRY_LOAD_FAILURE: string = '[Lead] Order entry - Load Failure';

export const ONHOLD_LOAD: string = '[Lead] On Hold - Load';
export const ONHOLD_LOAD_SUCCESS: string = '[Lead] On Hold - Load Success';
export const ONHOLD_LOAD_FAILURE: string = '[Lead] On Hold - Load Failure';

export const WON_LOAD: string = '[Lead] Won - Load';
export const WON_LOAD_SUCCESS: string = '[Lead] Won - Load Success';
export const WON_LOAD_FAILURE: string = '[Lead] Won - Load Failure';

export const NEW_LOAD: string = '[Lead] New - Load';
export const NEW_LOAD_SUCCESS: string = '[Lead] New - Load Success';
export const NEW_LOAD_FAILURE: string = '[Lead] New - Load Failure';

export const NOT_ASSIGNED_LOAD: string = '[Lead] Not Assigned - Load';
export const NOT_ASSIGNED_LOAD_SUCCESS: string = '[Lead] Not Assigned - Load Success';
export const NOT_ASSIGNED_LOAD_FAILURE: string = '[Lead] Not Assigned - Load Failure';

export const PROJECTS_IN_PROGRESS_LOAD: string = '[Lead] Projects In Progress - Load';
export const PROJECTS_IN_PROGRESS_LOAD_SUCCESS: string =
  '[Lead] Projects In Progress - Load Success';
export const PROJECTS_IN_PROGRESS_LOAD_FAILURE: string =
  '[Lead] Projects In Progress - Load Failure';

export const PROJECT_OVERVIEW_LOAD: string = '[Lead] Project Overview - Load';
export const PROJECT_OVERVIEW_LOAD_SUCCESS: string = '[Lead] Project Overview - Load Success';
export const PROJECT_OVERVIEW_LOAD_FAILURE: string = '[Lead] Project Overview - Load Failure';

export const PROJECT_OVERVIEW_OWN_LOAD: string = '[Lead] Project Overview Own - Load';
export const PROJECT_OVERVIEW_OWN_LOAD_SUCCESS: string =
  '[Lead] Project Overview Own - Load Success';
export const PROJECT_OVERVIEW_OWN_LOAD_FAILURE: string =
  '[Lead] Project Overview Own - Load Failure';

export const OWN_MT_CRM_LOAD: string = '[Meier Tobler Crm Lead] Own - Load';
export const OWN_MT_CRM_LOAD_SUCCESS: string = '[Meier Tobler Crm Lead] Own - Load Success';
export const OWN_MT_CRM_LOAD_FAILURE: string = '[Meier Tobler Crm Lead] Own - Load Failure';

export const SEARCH_MT_CRM_LOAD: string = '[Meier Tobler Crm Lead] Search - Load';
export const SEARCH_MT_CRM_LOAD_SUCCESS: string = '[Meier Tobler Crm Lead] Search - Load Success';
export const SEARCH_MT_CRM_LOAD_FAILURE: string = '[Meier Tobler Crm Lead] Search - Load Failure';

export const ONLINEPOOL_LOAD: string = '[Lead] Onlinepool - Load';
export const ONLINEPOOL_LOAD_SUCCESS: string = '[Lead] Onlinepool - Load Success';
export const ONLINEPOOL_LOAD_FAILURE: string = '[Lead] Onlinepool - Load Failure';

export const CALLPOOL_LOAD: string = '[Lead] Callepool - Load';
export const CALLPOOL_LOAD_SUCCESS: string = '[Lead] Callpool - Load Success';
export const CALLPOOL_LOAD_FAILURE: string = '[Lead] Callpool - Load Failure';

export const DOWNLOADPOOL_LOAD: string = '[Lead] Downloadpool - Load';
export const DOWNLOADPOOL_LOAD_SUCCESS: string = '[Lead] Downloadpool - Load Success';
export const DOWNLOADPOOL_LOAD_FAILURE: string = '[Lead] Downloadpool - Load Failure';

export const TASKS_LOAD_OWN: string = '[Tasks] Load Own - Load';
export const TASKS_LOAD_OWN_SUCCESS: string = '[Tasks] Load Own - Load Success';
export const TASKS_LOAD_OWN_FAILURE: string = '[Tasks] Load Own - Load Failure';

export const TASKS_LOAD_DELEGATED: string = '[Tasks] Load Delegated - Load';
export const TASKS_LOAD_DELEGATED_SUCCESS: string = '[Tasks] Load - Delegated Succes ';
export const TASKS_LOAD_DELEGATED_FAILURE: string = '[Tasks] Load - Delegated Failure';

export const ALL_CLEAR: string = '[Lead] All - Clear';

export const ALL_LOAD: string = '[Lead] All - Load';
export const ALL_LOAD_SUCCESS: string = '[Lead] All - Load Success';
export const ALL_LOAD_FAILURE: string = '[Lead] All - Load Failure';

export const ENERGY_SOLUTIONS_LOAD: string = '[Lead] EnergySolutions - Load';
export const ENERGY_SOLUTIONS_LOAD_SUCCESS: string = '[Lead] EnergySolutions - Load Success';
export const ENERGY_SOLUTIONS_LOAD_FAILURE: string = '[Lead] EnergySolutions - Load Failure';

export const TRIAGE_CLEAR: string = '[Lead] Triage - Clear';
export const TRIAGE_LOAD: string = '[Lead] Triage - Load';
export const TRIAGE_LOAD_SUCCESS: string = '[Lead] Triage - Load Success';
export const TRIAGE_LOAD_FAILURE: string = '[Lead] Triage - Load Failure';

export const DETAIL_LOAD: string = '[Lead] Detail - Load';
export const DETAIL_LOAD_SUCCESS: string = '[Lead] Detail - Load Success';
export const DETAIL_LOAD_FAILURE: string = '[Lead] Detail - Load Failure';

export const SIBLINGS_LOAD: string = '[Lead] Siblings - Load';
export const SIBLINGS_LOAD_SUCCESS: string = '[Lead] Siblings - Load Success';
export const SIBLINGS_LOAD_FAILURE: string = '[Lead] Siblings - Load Failure';

export const DETAIL_LOAD_EM_LEAD: string = '[Lead] Detail - Load EM lead';
export const DETAIL_LOAD_EM_LEAD_SUCCESS: string = '[Lead] Detail - Load Success EM lead';
export const DETAIL_LOAD_EM_LEAD_FAILURE: string = '[Lead] Detail - Load Failure EM lead';

export const PATCH_EM_LEAD: string = '[Lead] Detail - Patch EM lead';
export const PATCH_EM_LEAD_SUCCESS: string = '[Lead] Detail - Patch Success EM lead';
export const PATCH_EM_LEAD_FAILURE: string = '[Lead] Detail - Patch Failure EM lead';

export const DETAIL_LOAD_HT_LEAD: string = '[Lead] Detail - Load HT lead';
export const DETAIL_LOAD_HT_LEAD_SUCCESS: string = '[Lead] Detail - Load Success HT lead';
export const DETAIL_LOAD_HT_LEAD_FAILURE: string = '[Lead] Detail - Load Failure HT lead';

export const PATCH_HT_LEAD: string = '[Lead] Detail - Patch HT lead';
export const PATCH_HT_LEAD_SUCCESS: string = '[Lead] Detail - Patch Success HT lead';
export const PATCH_HT_LEAD_FAILURE: string = '[Lead] Detail - Patch Failure HT lead';

export const DETAIL_LOAD_PV_LEAD: string = '[Lead] Detail - Load PV lead';
export const DETAIL_LOAD_PV_LEAD_SUCCESS: string = '[Lead] Detail - Load Success PV lead';
export const DETAIL_LOAD_PV_LEAD_FAILURE: string = '[Lead] Detail - Load Failure PV lead';

export const PATCH_PV_LEAD: string = '[Lead] Detail - Patch PV lead';
export const PATCH_PV_LEAD_SUCCESS: string = '[Lead] Detail - Patch Success PV lead';
export const PATCH_PV_LEAD_FAILURE: string = '[Lead] Detail - Patch Failure PV lead';

export const DETAIL_ADD_TASK: string = '[Lead] Detail - Add Task';
export const DETAIL_ADD_TASK_SUCCESS: string = '[Lead] Detail - Add Task Success';
export const DETAIL_ADD_TASK_FAILURE: string = '[Lead] Detail - Add Task Failure';

export const DIALOG_ADD_TASK: string = '[Lead] Dialog - Add Task';
export const DIALOG_ADD_TASK_SUCCESS: string = '[Lead] Dialog - Add Task Success';
export const DIALOG_ADD_TASK_SUCCESS_RESET: string = '[Lead] Dialog - Add Task Success Reset';

export const DETAIL_UPDATE_TASK: string = '[Lead] Detail - Update Task';
export const DETAIL_UPDATE_TASK_SUCCESS: string = '[Lead] Detail - Update Task Success';
export const DETAIL_UPDATE_TASK_FAILURE: string = '[Lead] Detail - Update Task Failure';

export const DETAIL_REMOVE_TASK: string = '[Lead] Detail - Remove Task';
export const DETAIL_REMOVE_TASK_SUCCESS: string = '[Lead] Detail - Remove Task Success';
export const DETAIL_REMOVE_TASK_FAILURE: string = '[Lead] Detail - Remove Task Failure';

export const DETAIL_UPDATE_APPOINTMENT: string = '[Lead] Detail - Update Appointment';
export const DETAIL_UPDATE_APPOINTMENT_SUCCESS: string =
  '[Lead] Detail - Update Appointment Success';
export const DETAIL_UPDATE_APPOINTMENT_FAILURE: string =
  '[Lead] Detail - Update Appointment Failure';

export const DETAIL_ADD_APPOINTMENT: string = '[Lead] Detail - Add Appointment';
export const DETAIL_ADD_APPOINTMENT_SUCCESS: string = '[Lead] Detail - Add Appointment Success';
export const DETAIL_ADD_APPOINTMENT_FAILURE: string = '[Lead] Detail - Add Appointment Failure';

export const DETAIL_REMOVE_APPOINTMENT: string = '[Lead] Detail - Remove Appointment';
export const DETAIL_REMOVE_APPOINTMENT_SUCCESS: string =
  '[Lead] Detail - Remove Appointment Success';
export const DETAIL_REMOVE_APPOINTMENT_FAILURE: string =
  '[Lead] Detail - Remove Appointment Failure';

export const ADD_FINAL_INVOICES: string = '[Lead] Detail - Add final Invoices';
export const ADD_FINAL_INVOICES_SUCCESS: string = '[Lead] Detail - Add final Invoices Success';
export const ADD_FINAL_INVOICES_FAILURE: string = '[Lead] Detail - Add final Invoices Failure';
export const UPDATE_INVOICES: string = '[Lead] Detail - Update Invoices';
export const UPDATE_INVOICES_SUCCESS: string = '[Lead] Detail - Update Invoices Success';
export const UPDATE_INVOICES_FAILURE: string = '[Lead] Detail - Update Invoices Failure';

export const DELETE_INVOICE: string = '[Lead] Detail - Delete Invoice';
export const DELETE_INVOICE_SUCCESS: string = '[Lead] Detail - Delete Invoice Success';
export const DELETE_INVOICE_FAILURE: string = '[Lead] Detail - Delete Invoice Failure';

export const INVOICE_PDF_DOWNLOAD: string = '[Lead] Detail - Invoice PDF Download';
export const INVOICE_PDF_DOWNLOAD_SUCCESS: string = '[Lead] Detail - Invoice PDF Download Success';
export const INVOICE_PDF_DOWNLOAD_FAILURE: string = '[Lead] Detail - Invoice PDF Download Failure';

export const UPDATE_INVOICE_STATUS: string = '[Lead] Detail - Update Invoice Status';
export const UPDATE_INVOICE_STATUS_SUCCESS: string =
  '[Lead] Detail - Update Invoice Status Success';
export const UPDATE_INVOICE_STATUS_FAILURE: string =
  '[Lead] Detail - Update Invoice Status Failure';

export const REQUEST_DUNNING_RUN_BLOCKER: string = '[Lead] Detail - Request Dunning Run Blocker';
export const REQUEST_DUNNING_RUN_BLOCKER_SUCCESS: string =
  '[Lead] Detail - Request Dunning Run Blocker Success';
export const REQUEST_DUNNING_RUN_BLOCKER_FAILURE: string =
  '[Lead] Detail - Request Dunning Run Blocker Failure';

export const GET_INVOICES: string = '[Lead] Detail - Get Invoices';
export const GET_INVOICES_SUCCESS: string = '[Lead] Detail - Get Invoices Success';
export const GET_INVOICES_FAILURE: string = '[Lead] Detail - Get Invoices Failure';

export const DETAIL_CLONE_VARIANT: string = '[Variant] Clone Variant';
export const DETAIL_CLONE_VARIANT_SUCCESS: string = '[Variant] Clone Variant success';
export const DETAIL_CLONE_VARIANT_FAILURE: string = '[Variant] Clone Variant Failure';

export const COPY_VARIANT: string = '[Variant] Copy Variant';
export const COPY_VARIANT_SUCCESS: string = '[Variant] Copy Variant success';
export const COPY_VARIANT_FAILURE: string = '[Variant] Copy Variant Failure';

export const BILL_VARIANT: string = '[Variant] Bill Variant';
export const BILL_VARIANT_SUCCESS: string = '[Variant] Bill Variant success';
export const BILL_VARIANT_FAILURE: string = '[Variant] Bill Variant Failure';

export const SEARCH_VARIANT: string = '[Variant] Search variant';
export const SEARCH_VARIANT_SUCCESS: string = '[Variant] Search variant success';
export const SEARCH_VARIANT_FAILURE: string = '[Variant] Search variant failed';

export const DETAIL_CANCEL_VARIANT: string = '[Lead] Detail - Cancel Variant';
export const DETAIL_CANCEL_VARIANT_SUCCESS: string = '[Lead] Detail - Cancel Variant Success';
export const DETAIL_CANCEL_VARIANT_FAILURE: string = '[Lead] Detail - Cancel Variant Failure';

export const WON_VARIANT: string = '[Lead] Detail - Won Variant';
export const WON_VARIANT_SUCCESS: string = '[Lead] Detail - Won Variant Success';
export const WON_VARIANT_FAILURE: string = '[Lead] Detail - Won Variant Failure';

export const UPDATE_VARIANT_SALES_PROBABILITY: string = '[Lead] Update Variant Rating';
export const UPDATE_VARIANT_SALES_PROBABILITY_SUCCESS: string =
  '[Lead] Update Variant Rating Success';
export const UPDATE_VARIANT_SALES_PROBABILITY_FAILURE: string =
  '[Lead] Update Variant Rating Failure';

export const UPDATE_VARIANT: string = '[Lead] Update Variant';
export const UPDATE_VARIANT_SUCCESS: string = '[Lead] Update Variant Success';
export const UPDATE_VARIANT_FAILURE: string = '[Lead] Update Variant Failure';

export const UPDATE_LEAD_COMPLEXITY_TYPE: string = '[Lead] Update Lead Complexity Type';
export const UPDATE_LEAD_COMPLEXITY_TYPE_SUCCESS: string =
  '[Lead] Update Lead Complexity Type Success';
export const UPDATE_LEAD_COMPLEXITY_TYPE_FAILURE: string =
  '[Lead] Update Lead Complexity Type Failure';

export const UPDATE_LEAD_TAG: string = '[Lead] Update Lead Tag';
export const UPDATE_LEAD_TAG_SUCCESS: string = '[Lead] Update Lead Tag Success';
export const UPDATE_LEAD_TAG_FAILURE: string = '[Lead] Update Lead Tag Failure';

export const UPDATE_LEAD: string = '[Lead] Update Lead';
export const UPDATE_LEAD_SUCCESS: string = '[Lead] Update Lead Success';
export const UPDATE_LEAD_FAILURE: string = '[Lead] Update Lead Failure';

export const LOAD_PROJECT_PROPERTIES: string = '[Lead] Load Project Properties';
export const LOAD_PROJECT_PROPERTIES_SUCCESS: string = '[Lead] Load Project Properties Success';
export const LOAD_PROJECT_PROPERTIES_FAILURE: string = '[Lead] Load Project Properties Failure';

export const STORE_PROJECT_PROPERTIES: string = '[Lead] Store Project Properties';
export const STORE_PROJECT_PROPERTIES_SUCCESS: string = '[Lead] Store Project Properties Success';
export const STORE_PROJECT_PROPERTIES_FAILURE: string = '[Lead] Store Project Properties Failure';

export const CREATE_LEAD_FROM_EXISTING: string = '[Lead] Create Lead From Existing';
export const CREATE_LEAD_FROM_EXISTING_CANCELLED: string =
  '[Lead] Create Lead From Existing Cancelled';
export const CREATE_LEAD_FROM_EXISTING_SUCCESS: string = '[Lead] Create Lead From Existing Success';
export const CREATE_LEAD_FROM_EXISTING_FAILURE: string = '[Lead] Create Lead From Existing Failure';

export const ADD_APPOINTMENT: string = '[Lead] Create Appointment';
export const ADD_APPOINTMENT_SUCCESS: string = '[Lead] Create Appointment Success';
export const ADD_APPOINTMENT_FAILURE: string = '[Lead] Create Appointment Failure';

export const ADD_TASK: string = '[Lead] Create Task';
export const ADD_TASK_SUCCESS: string = '[Lead] Create Task Success';
export const ADD_TASK_FAILURE: string = '[Lead] Create Task Failure';

export const SET_NEXT_LEAD_LIST: string = '[Lead] Set Next Lead List';

export const LOCK_LEAD: string = '[Lead] Lock Lead';
export const LOCK_LEAD_SUCCESS: string = '[Lead] Lock Lead Success';
export const LOCK_LEAD_FAILURE: string = '[Lead] Lock Lead Failure';

export const UNLOCK_LEAD: string = '[Lead] Unlock Lead';
export const UNLOCK_LEAD_SUCCESS: string = '[Lead] Unlock Lead Success';
export const UNLOCK_LEAD_FAILURE: string = '[Lead] Unlock Lead Failure';

export const SYNCHRONIZE_LEAD: string = '[Lead] Synchronize Lead';
export const SYNCHRONIZE_LEAD_SUCCESS: string = '[Lead] Synchronize Lead Success';
export const SYNCHRONIZE_LEAD_FAILURE: string = '[Lead] Synchronize Lead Failure';

export const APPROVE_SOLAR_TARIF: string = '[Lead] Approve Solar Tariff';
export const APPROVE_SOLAR_TARIF_SUCCESS: string = '[Lead] Approve Solar Tariff Success';
export const APPROVE_SOLAR_TARIF_FAILURE: string = '[Lead] Approve Solar Tariff Failure';

export const CREATE_PV_AUTO_OFFER: string = '[Lead] Create PV Auto Offer';
export const CREATE_PV_AUTO_OFFER_SUCCESS: string = '[Lead] Create PV Auto Offer Success';
export const CREATE_PV_AUTO_OFFER_FAILURE: string = '[Lead] Create PV Auto Offer Failure';

export interface ISynchronizeLeadPayload {
  leadId: number;
  leadSynchronizationToExternalSystem: ExternalSystem | ExternalSystem[];
}

export interface IUpdateInvoiceStatusPayload {
  variantId: number;
  invoiceId: number;
  body: PatchVariantInvoiceStatusRequest;
  isFinal: boolean;
}

export interface IRequestDunningRunBlockerPayload {
  leadId: number;
  invoiceId: number;
  note: string;
}

export class SynchronizeLeadAction implements Action {
  readonly type: string = SYNCHRONIZE_LEAD;

  constructor(public payload: ISynchronizeLeadPayload) {}
}

export class SynchronizeLeadSuccessAction implements Action {
  readonly type: string = SYNCHRONIZE_LEAD_SUCCESS;
}

export class SynchronizeLeadFailureAction implements Action {
  readonly type: string = SYNCHRONIZE_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export class OwnLoadAction implements Action {
  readonly type: string = OWN_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class OwnLoadSuccessAction implements Action {
  readonly type: string = OWN_LOAD_SUCCESS;

  constructor(public payload: LeadListViewModel[]) {}
}

export class OwnLoadFailureAction implements Action {
  readonly type: string = OWN_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class FollowUpLoadAction implements Action {
  readonly type: string = FOLLOWUP_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class FollowUpLoadSuccessAction implements Action {
  readonly type: string = FOLLOWUP_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class FollowUpLoadFailureAction implements Action {
  readonly type: string = FOLLOWUP_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class OrderEntryLoadAction implements Action {
  readonly type: string = ORDER_ENTRY_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class OrderEntryLoadSuccessAction implements Action {
  readonly type: string = ORDER_ENTRY_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class OrderEntryLoadFailureAction implements Action {
  readonly type: string = ORDER_ENTRY_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class OnHoldLoadAction implements Action {
  readonly type: string = ONHOLD_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class OnHoldLoadSuccessAction implements Action {
  readonly type: string = ONHOLD_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class OnHoldLoadFailureAction implements Action {
  readonly type: string = ONHOLD_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class WonLoadAction implements Action {
  readonly type: string = WON_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class WonLoadSuccessAction implements Action {
  readonly type: string = WON_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class WonLoadFailureAction implements Action {
  readonly type: string = WON_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class NewLoadAction implements Action {
  readonly type: string = NEW_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class NewLoadSuccessAction implements Action {
  readonly type: string = NEW_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class NewLoadFailureAction implements Action {
  readonly type: string = NEW_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class OwnMtCrmLoadAction implements Action {
  readonly type: string = OWN_MT_CRM_LOAD;

  constructor(public payload: MtLeadRequest) {}
}

export class OwnMtCrmLoadSuccessAction implements Action {
  readonly type: string = OWN_MT_CRM_LOAD_SUCCESS;

  constructor(public payload: MtLeadListViewModel[]) {}
}

export class OwnMtCrmLoadFailureAction implements Action {
  readonly type: string = OWN_MT_CRM_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class SearchMtCrmLoadAction implements Action {
  readonly type: string = SEARCH_MT_CRM_LOAD;

  constructor(public payload: MtLeadRequest) {}
}

export class SearchMtCrmLoadSuccessAction implements Action {
  readonly type: string = SEARCH_MT_CRM_LOAD_SUCCESS;

  constructor(public payload: MtLeadListViewModel[]) {}
}

export class SearchMtCrmLoadFailureAction implements Action {
  readonly type: string = SEARCH_MT_CRM_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class NotAssignedLoadAction implements Action {
  readonly type: string = NOT_ASSIGNED_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class NotAssignedLoadSuccessAction implements Action {
  readonly type: string = NOT_ASSIGNED_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class NotAssignedLoadFailureAction implements Action {
  readonly type: string = NOT_ASSIGNED_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class ProjectsInProgressLoadAction implements Action {
  readonly type: string = PROJECTS_IN_PROGRESS_LOAD;

  constructor(public payload: any) {}
}

export class ProjectsInProgressLoadSuccessAction implements Action {
  readonly type: string = PROJECTS_IN_PROGRESS_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class ProjectsInProgressLoadFailureAction implements Action {
  readonly type: string = PROJECTS_IN_PROGRESS_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class ProjectOverviewLoadAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_LOAD;

  constructor(public payload: any) {}
}

export class ProjectOverviewLoadSuccessAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class ProjectOverviewLoadFailureAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class ProjectOverviewOwnLoadAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_OWN_LOAD;

  constructor(public payload: any) {}
}

export class ProjectOverviewOwnLoadSuccessAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_OWN_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class ProjectOverviewOwnLoadFailureAction implements Action {
  readonly type: string = PROJECT_OVERVIEW_OWN_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export interface ITakeoverActionPayload {
  userId: string;
  leadId: string;
}

export class TakeoverAction implements Action {
  readonly type: string = TAKEOVER;

  constructor(public payload: ITakeoverActionPayload) {
    // UserId
  }
}

export class TakeoverSuccessAction implements Action {
  readonly type: string = TAKEOVER_SUCCESS;

  constructor(public payload: ITakeoverActionPayload) {}
}

export class TakeoverFailureAction implements Action {
  readonly type: string = TAKEOVER_FAILURE;

  constructor(public payload: any) {}
}

// Lead-Onlinepool
export class OnlinepoolLoadAction implements Action {
  readonly type: string = ONLINEPOOL_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class OnlinepoolLoadSuccessAction implements Action {
  readonly type: string = ONLINEPOOL_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class OnlinepoolLoadFailureAction implements Action {
  readonly type: string = ONLINEPOOL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

// Lead-Callpool
export class CallpoolLoadAction implements Action {
  readonly type: string = CALLPOOL_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class CallpoolLoadSuccessAction implements Action {
  readonly type: string = CALLPOOL_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class CallpoolLoadFailureAction implements Action {
  readonly type: string = CALLPOOL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

// Lead-Cownloadpool
export class DownloadpoolLoadAction implements Action {
  readonly type: string = DOWNLOADPOOL_LOAD;

  constructor(public payload: LeadRequest) {}
}

export class DownloadLoadSuccessAction implements Action {
  readonly type: string = DOWNLOADPOOL_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class DownloadpoolLoadFailureAction implements Action {
  readonly type: string = DOWNLOADPOOL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

// Lead-Task
export class TasksLoadOwnAction implements Action {
  readonly type: string = TASKS_LOAD_OWN;

  constructor(public payload: any = undefined) {}
}

export class TasksLoadOwnSuccessAction implements Action {
  readonly type: string = TASKS_LOAD_OWN_SUCCESS;

  constructor(public payload: TaskListViewModel[]) {}
}

export class TasksLoadOwnFailureAction implements Action {
  readonly type: string = TASKS_LOAD_OWN_FAILURE;

  constructor(public payload: any) {}
}

export class TasksLoadDelegatedAction implements Action {
  readonly type: string = TASKS_LOAD_DELEGATED;

  constructor(public payload: any = undefined) {}
}

export class TasksLoadDelegatedSuccessAction implements Action {
  readonly type: string = TASKS_LOAD_DELEGATED_SUCCESS;

  constructor(public payload: TaskListViewModel[]) {}
}

export class TasksLoadDelegatedFailureAction implements Action {
  readonly type: string = TASKS_LOAD_DELEGATED_FAILURE;

  constructor(public payload: any) {}
}

// Lead-All
export class AllLoadAction implements Action {
  readonly type: string = ALL_LOAD;

  constructor(public payload: LeadRequest = {}) {}
}

export class AllClearAction implements Action {
  readonly type: string = ALL_CLEAR;

  constructor(public payload: LeadRequest = {}) {}
}

export class AllLoadSuccessAction implements Action {
  readonly type: string = ALL_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class AllLoadFailureAction implements Action {
  readonly type: string = ALL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class EnergySolutionLoadAction implements Action {
  readonly type: string = ENERGY_SOLUTIONS_LOAD;

  constructor(public payload: LeadRequest = {}) {}
}

export class EnergySolutionLoadSuccessAction implements Action {
  readonly type: string = ENERGY_SOLUTIONS_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class EnergySolutionLoadFailureAction implements Action {
  readonly type: string = ENERGY_SOLUTIONS_LOAD_FAILURE;

  constructor(public payload: any) {}
}

// Lead-All
export class TriageLoadAction implements Action {
  readonly type: string = TRIAGE_LOAD;

  constructor(public payload: LeadRequest = {}) {}
}

export class TriageClearAction implements Action {
  readonly type: string = TRIAGE_CLEAR;

  constructor(public payload: LeadRequest = {}) {}
}

export class TriageLoadSuccessAction implements Action {
  readonly type: string = TRIAGE_LOAD_SUCCESS;

  constructor(public payload: PageResultViewModelLeadListViewModel) {}
}

export class TriageLoadFailureAction implements Action {
  readonly type: string = TRIAGE_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export interface IDetailLoad {
  leadId: number;
  useForceDetailLoad?: boolean;
}

// Lead-Detail
export class DetailLoadAction implements Action {
  readonly type: string = DETAIL_LOAD;

  constructor(public payload: IDetailLoad) {}
}

export class DetailLoadSuccessAction implements Action {
  readonly type: string = DETAIL_LOAD_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailLoadFailureAction implements Action {
  readonly type: string = DETAIL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class SiblingsLoadAction implements Action {
  readonly type: string = SIBLINGS_LOAD;

  constructor(public payload: number) {}
}

export class SiblingsLoadSuccessAction implements Action {
  readonly type: string = SIBLINGS_LOAD_SUCCESS;

  constructor(public payload: LeadSiblingsViewModel) {}
}

export class SiblingsLoadFailureAction implements Action {
  readonly type: string = SIBLINGS_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class DetailLoadEmLeadAction implements Action {
  readonly type: string = DETAIL_LOAD_EM_LEAD;

  constructor(public payload: number) {}
}

export class DetailLoadEmLeadSuccessAction implements Action {
  readonly type: string = DETAIL_LOAD_EM_LEAD_SUCCESS;

  constructor(public payload: EmobilityLeadViewModel) {}
}

export class DetailLoadEmLeadFailureAction implements Action {
  readonly type: string = DETAIL_LOAD_EM_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export interface IPatchEmLead {
  leadId: number;
  request: EmobilityLeadViewModel;
  Id?: number;
}

export interface IInvoicePdfDownloadActionPayload {
  leadId: number;
  thirdPartyReferenceDocumentNumber: string;
}

export class PatchEmLeadAction implements Action {
  readonly type: string = PATCH_EM_LEAD;

  constructor(public payload: IPatchEmLead) {}
}

export class PatchEmLeadSuccessAction implements Action {
  readonly type: string = PATCH_EM_LEAD_SUCCESS;

  constructor(public payload: EmobilityLeadViewModel) {}
}

export class PatchEmLeadFailedAction implements Action {
  readonly type: string = PATCH_EM_LEAD_FAILURE;

  constructor(public payload: any) {}
}

// Ht actions
export class DetailLoadHtLeadAction implements Action {
  readonly type: string = DETAIL_LOAD_HT_LEAD;

  constructor(public payload: number) {}
}

export class DetailLoadHtLeadSuccessAction implements Action {
  readonly type: string = DETAIL_LOAD_HT_LEAD_SUCCESS;

  constructor(public payload: HeatingLeadViewModel) {}
}

export class DetailLoadHtLeadFailureAction implements Action {
  readonly type: string = DETAIL_LOAD_HT_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export interface IPatchHtLead {
  leadId: number;
  request: HeatingLeadViewModel;
}

export interface IPatchPvLead {
  leadId: number;
  request: PvLeadViewModel;
}

export class PatchHtLeadAction implements Action {
  readonly type: string = PATCH_HT_LEAD;

  constructor(public payload: IPatchHtLead) {}
}

export class PatchHtLeadSuccessAction implements Action {
  readonly type: string = PATCH_HT_LEAD_SUCCESS;

  constructor(public payload: HeatingLeadViewModel) {}
}

export class PatchHtLeadFailedAction implements Action {
  readonly type: string = PATCH_HT_LEAD_FAILURE;

  constructor(public payload: any) {}
}

// Pv actions
export class DetailLoadPvLeadAction implements Action {
  readonly type: string = DETAIL_LOAD_PV_LEAD;

  constructor(public payload: number) {}
}

export class DetailLoadPvLeadSuccessAction implements Action {
  readonly type: string = DETAIL_LOAD_PV_LEAD_SUCCESS;

  constructor(public payload: PvLeadViewModel) {}
}

export class DetailLoadPvLeadFailureAction implements Action {
  readonly type: string = DETAIL_LOAD_PV_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export interface IPatchPvLead {
  leadId: number;
  request: PvLeadViewModel;
}

export class PatchPvLeadAction implements Action {
  readonly type: string = PATCH_PV_LEAD;

  constructor(public payload: IPatchPvLead) {}
}

export class PatchPvLeadSuccessAction implements Action {
  readonly type: string = PATCH_PV_LEAD_SUCCESS;

  constructor(public payload: PvLeadViewModel) {}
}

export class PatchPvLeadFailedAction implements Action {
  readonly type: string = PATCH_PV_LEAD_FAILURE;

  constructor(public payload: any) {}
}

// Add Task
export class DetailAddTaskAction implements Action {
  readonly type: string = DETAIL_ADD_TASK;

  constructor(public payload: TaskViewModel) {}
}

export class DetailAddTaskSuccessAction implements Action {
  readonly type: string = DETAIL_ADD_TASK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailAddTaskFailureAction implements Action {
  readonly type: string = DETAIL_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export interface IDialogAddTaskActionPayload {
  newTask: TaskViewModel;
  predecessorTaskIdToClose?: number;
}

export class DialogAddTaskAction implements Action {
  readonly type: string = DIALOG_ADD_TASK;

  constructor(public payload: IDialogAddTaskActionPayload) {}
}

export class DialogAddTaskSuccessAction implements Action {
  readonly type: string = DIALOG_ADD_TASK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DialogAddTaskSuccessResetAction implements Action {
  readonly type: string = DIALOG_ADD_TASK_SUCCESS_RESET;

  constructor(public payload: any) {}
}

// Update Task
export class DetailUpdateTaskAction implements Action {
  readonly type: string = DETAIL_UPDATE_TASK;

  constructor(public payload: TaskViewModel) {}
}

export class DetailUpdateTaskSuccessAction implements Action {
  readonly type: string = DETAIL_UPDATE_TASK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailUpdateTaskFailureAction implements Action {
  readonly type: string = DETAIL_UPDATE_TASK_FAILURE;

  constructor(public payload: any) {}
}

// Delete Task
export class DetailRemoveTaskAction implements Action {
  readonly type: string = DETAIL_REMOVE_TASK;

  constructor(public payload: TaskViewModel) {}
}

export class DetailRemoveTaskSuccessAction implements Action {
  readonly type: string = DETAIL_REMOVE_TASK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailRemoveTaskFailureAction implements Action {
  readonly type: string = DETAIL_REMOVE_TASK_FAILURE;

  constructor(public payload: any) {}
}

// Update appointment
export class DetailUpdateAppointmentAction implements Action {
  readonly type: string = DETAIL_UPDATE_APPOINTMENT;

  constructor(public payload: AppointmentViewModel) {}
}

export class DetailUpdateAppointmentSuccessAction implements Action {
  readonly type: string = DETAIL_UPDATE_APPOINTMENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailUpdateAppointmentFailureAction implements Action {
  readonly type: string = DETAIL_UPDATE_APPOINTMENT_FAILURE;

  constructor(public payload: any) {}
}

export class DetailAddAppointmentAction implements Action {
  readonly type: string = DETAIL_ADD_APPOINTMENT;

  constructor(public payload: AppointmentViewModel) {}
}

export class DetailAddAppointmentSuccessAction implements Action {
  readonly type: string = DETAIL_ADD_APPOINTMENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailAddAppointmentFailureAction implements Action {
  readonly type: string = DETAIL_ADD_APPOINTMENT_FAILURE;

  constructor(public payload: any) {}
}

// Delete Appointment
export class DetailRemoveAppointmentAction implements Action {
  readonly type: string = DETAIL_REMOVE_APPOINTMENT;

  constructor(public payload: AppointmentViewModel) {}
}

export class DetailRemoveAppointmentSuccessAction implements Action {
  readonly type: string = DETAIL_REMOVE_APPOINTMENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailRemoveAppointmentFailureAction implements Action {
  readonly type: string = DETAIL_REMOVE_APPOINTMENT_FAILURE;

  constructor(public payload: any) {}
}

// Invoices
export interface IAddFinalInvoicesActionPayload {
  variantId: number;
  request: PostFinalInvoiceRequest;
}

export class AddFinalInvoicesAction implements Action {
  readonly type: string = ADD_FINAL_INVOICES;

  constructor(public payload: IAddFinalInvoicesActionPayload) {}
}

export class AddFinalInvoicesSuccessAction implements Action {
  readonly type: string = ADD_FINAL_INVOICES_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class AddFinalInvoicesFailureAction implements Action {
  readonly type: string = ADD_FINAL_INVOICES_FAILURE;

  constructor(public payload: any) {}
}

export class DeleteInvoiceAction implements Action {
  readonly type: string = DELETE_INVOICE;

  constructor(public payload: { invoiceId: number; leadId: number }) {}
}

export class DeleteInvoiceSuccessAction implements Action {
  readonly type: string = DELETE_INVOICE_SUCCESS;

  constructor() {}
}

export class DeleteInvoiceFailureAction implements Action {
  readonly type: string = DELETE_INVOICE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateInvoicesAction implements Action {
  readonly type: string = UPDATE_INVOICES;

  constructor(public payload: PatchVariantInvoiceRequest[]) {}
}

export class UpdateInvoicesSuccessAction implements Action {
  readonly type: string = UPDATE_INVOICES_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class UpdateInvoicesFailureAction implements Action {
  readonly type: string = UPDATE_INVOICES_FAILURE;

  constructor(public payload: any) {}
}

export class InvoicePdfDownloadAction implements Action {
  readonly type: string = INVOICE_PDF_DOWNLOAD;

  constructor(public payload: IInvoicePdfDownloadActionPayload) {}
}

export class InvoicePdfDownloadSuccessAction implements Action {
  readonly type: string = INVOICE_PDF_DOWNLOAD_SUCCESS;

  constructor() {}
}

export class InvoicePdfDownloadFailureAction implements Action {
  readonly type: string = INVOICE_PDF_DOWNLOAD_FAILURE;

  constructor() {}
}

export class UpdateInvoicesStatusAction implements Action {
  readonly type: string = UPDATE_INVOICE_STATUS;

  constructor(public payload: IUpdateInvoiceStatusPayload) {}
}

export class UpdateInvoicesStatusSuccessAction implements Action {
  readonly type: string = UPDATE_INVOICE_STATUS_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateInvoicesStatusFailureAction implements Action {
  readonly type: string = UPDATE_INVOICE_STATUS_FAILURE;

  constructor(public payload: any) {}
}

export class RequestDunningRunBlockerAction implements Action {
  readonly type: string = REQUEST_DUNNING_RUN_BLOCKER;

  constructor(public payload: IRequestDunningRunBlockerPayload) {}
}

export class RequestDunningRunBlockerSuccessAction implements Action {
  readonly type: string = REQUEST_DUNNING_RUN_BLOCKER_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestDunningRunBlockerFailureAction implements Action {
  readonly type: string = REQUEST_DUNNING_RUN_BLOCKER_FAILURE;

  constructor(public payload: any) {}
}

export class GetInvoicesAction implements Action {
  readonly type: string = GET_INVOICES;

  constructor(public payload: number) {}
}

export class GetInvoicesSuccessAction implements Action {
  readonly type: string = GET_INVOICES_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class GetInvoicesFailureAction implements Action {
  readonly type: string = GET_INVOICES_FAILURE;

  constructor(public payload: any) {}
}

// Delete Variant
export class DetailCancelVariantAction implements Action {
  readonly type: string = DETAIL_CANCEL_VARIANT;

  constructor(public payload: VariantListCompositionViewModel) {}
}

export class DetailCancelVariantSuccessAction implements Action {
  readonly type: string = DETAIL_CANCEL_VARIANT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailCancelVariantFailureAction implements Action {
  readonly type: string = DETAIL_CANCEL_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

// Delete Variant
export class WonVariantAction implements Action {
  readonly type: string = WON_VARIANT;

  constructor(public payload: VariantListCompositionViewModel) {}
}

export class WonVariantSuccessAction implements Action {
  readonly type: string = WON_VARIANT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class WonVariantFailureAction implements Action {
  readonly type: string = WON_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export interface IUpdateVariantSalesProbabilityActionPayload {
  leadId: number;
  variantId: number;
  salesProbability: number;
}

export interface IUpdateVariantActionPayload {
  leadId: number;
  variantId: number;
  body: PatchLeadVariantRequest;
}

export class UpdateVariantAction implements Action {
  readonly type: string = UPDATE_VARIANT;

  constructor(public payload: IUpdateVariantActionPayload) {}
}

export class UpdateVariantSuccessAction implements Action {
  readonly type: string = UPDATE_VARIANT_SUCCESS;

  constructor(public payload: IUpdateVariantActionPayload) {}
}

export class UpdateVariantFailedAction implements Action {
  readonly type: string = UPDATE_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateVariantSalesProbabilityAction implements Action {
  readonly type: string = UPDATE_VARIANT_SALES_PROBABILITY;

  constructor(public payload: IUpdateVariantSalesProbabilityActionPayload) {}
}

export class UpdateVariantSalesProbabilitySuccessAction implements Action {
  readonly type: string = UPDATE_VARIANT_SALES_PROBABILITY_SUCCESS;

  constructor(public payload: IUpdateVariantSalesProbabilityActionPayload) {}
}

export class UpdateVariantSalesProbabilityFailureAction implements Action {
  readonly type: string = UPDATE_VARIANT_SALES_PROBABILITY_FAILURE;

  constructor(public payload: any) {}
}

export class DetailCloneVariantAction implements Action {
  readonly type: string = DETAIL_CLONE_VARIANT;

  constructor(public payload: IGenericVariantActionPayload) {}
}

export class DetailCloneVariantSuccessAction implements Action {
  readonly type: string = DETAIL_CLONE_VARIANT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class DetailCloneVariantFailedAction implements Action {
  readonly type: string = DETAIL_CLONE_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export class CopyVariantAction implements Action {
  readonly type: string = COPY_VARIANT;

  constructor(public payload: any) {}
}

export class CopyVariantSuccessAction implements Action {
  readonly type: string = COPY_VARIANT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class CopyVariantFailedAction implements Action {
  readonly type: string = COPY_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export interface ICreateBillVariantActionPayload {
  leadId: number;
  freeBillType: 'Expense' | 'Supplementary' | 'PartList';
}

export class BillVariantAction implements Action {
  readonly type: string = BILL_VARIANT;

  constructor(public payload: ICreateBillVariantActionPayload) {}
}

export class BillVariantSuccessAction implements Action {
  readonly type: string = BILL_VARIANT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class BillyVariantFailedAction implements Action {
  readonly type: string = BILL_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export class SearchVariantAction implements Action {
  readonly type: string = SEARCH_VARIANT;

  constructor(public payload: any) {}
}

export class SearchVariantSuccessAction implements Action {
  readonly type: string = SEARCH_VARIANT_SUCCESS;

  constructor(public payload: VariantListViewModel[]) {}
}

export class SearchVariantFailedAction implements Action {
  readonly type: string = SEARCH_VARIANT_FAILURE;

  constructor(public payload: any) {}
}

export interface IUpdateLeadComplexityTypeActionPayload {
  leadId: number;
  complexityType: string;
}

export interface IUpdateLeadTagActionPayload {
  leadId: number;
  tag: string;
}

export interface IUpdateLeadActionPayload {
  leadId: number;
  request: PatchLeadViewModel;
}

export class UpdateLeadComplexityTypeAction implements Action {
  readonly type: string = UPDATE_LEAD_COMPLEXITY_TYPE;

  constructor(public payload: IUpdateLeadComplexityTypeActionPayload) {}
}

export class UpdateLeadComplexityTypeSuccessAction implements Action {
  readonly type: string = UPDATE_LEAD_COMPLEXITY_TYPE_SUCCESS;

  constructor(public payload: IUpdateLeadComplexityTypeActionPayload) {}
}

export class UpdateLeadComplexityTypeFailureAction implements Action {
  readonly type: string = UPDATE_LEAD_COMPLEXITY_TYPE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateLeadTagAction implements Action {
  readonly type: string = UPDATE_LEAD_TAG;

  constructor(public payload: IUpdateLeadTagActionPayload) {}
}

export class UpdateLeadTagSuccessAction implements Action {
  readonly type: string = UPDATE_LEAD_TAG_SUCCESS;

  constructor(public payload: IUpdateLeadTagActionPayload) {}
}

export class UpdateLeadTagFailureAction implements Action {
  readonly type: string = UPDATE_LEAD_TAG_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateLeadAction implements Action {
  readonly type: string = UPDATE_LEAD;

  constructor(public payload: IUpdateLeadActionPayload) {}
}

export class UpdateLeadSuccessAction implements Action {
  readonly type: string = UPDATE_LEAD_SUCCESS;

  constructor(public payload: LeadViewModel) {}
}

export class UpdateLeadFailureAction implements Action {
  readonly type: string = UPDATE_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export class LoadProjectPropertiesAction implements Action {
  readonly type: string = LOAD_PROJECT_PROPERTIES;

  constructor(public payload: number) {}
}

export class LoadProjectPropertiesSuccessAction implements Action {
  readonly type: string = LOAD_PROJECT_PROPERTIES_SUCCESS;

  constructor(public payload: IProjectDescriptionViewModel) {}
}

export class LoadProjectPropertiesFailureAction implements Action {
  readonly type: string = LOAD_PROJECT_PROPERTIES_FAILURE;

  constructor(public payload: any) {}
}

export interface IStoreProjectPropertiesPayload {
  leadId: number;
  properties: IProjectDescriptionViewModel;
}

export class StoreProjectPropertiesAction implements Action {
  readonly type: string = STORE_PROJECT_PROPERTIES;

  constructor(public payload: IStoreProjectPropertiesPayload) {}
}

export class StoreProjectPropertiesSuccessAction implements Action {
  readonly type: string = STORE_PROJECT_PROPERTIES_SUCCESS;

  constructor(public payload: IProjectDescriptionViewModel) {}
}

export class StoreProjectPropertiesFailureAction implements Action {
  readonly type: string = STORE_PROJECT_PROPERTIES_FAILURE;

  constructor(public payload: any) {}
}

export class CreateLeadFromExistingAction implements Action {
  readonly type: string = CREATE_LEAD_FROM_EXISTING;

  constructor(
    public payload: {
      personId?: number;
      leadId?: number;
      origin?: string;
      tradeType?: string;
      mandantId: number;
    }
  ) {}
}

export class CreateLeadFromExistingCancelledAction implements Action {
  readonly type: string = CREATE_LEAD_FROM_EXISTING_CANCELLED;

  constructor(public payload?: any) {}
}

export class CreateLeadFromExistingSuccessAction implements Action {
  readonly type: string = CREATE_LEAD_FROM_EXISTING_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class CreateLeadFromExistingFailureAction implements Action {
  readonly type: string = CREATE_LEAD_FROM_EXISTING_FAILURE;

  constructor(public payload: any) {}
}

// Add Appointment
export class AddAppointmentAction implements Action {
  readonly type: string = ADD_APPOINTMENT;

  constructor(public payload: AppointmentViewModel) {}
}

export class AddAppointmentSuccessAction implements Action {
  readonly type: string = ADD_APPOINTMENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class AddAppointmentFailureAction implements Action {
  readonly type: string = ADD_APPOINTMENT_FAILURE;

  constructor(public payload: any) {}
}

// Add Task
export class AddTaskAction implements Action {
  readonly type: string = ADD_TASK;

  constructor(public payload: TaskViewModel) {}
}

export class AddTaskSuccessAction implements Action {
  readonly type: string = ADD_TASK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class AddTaskFailureAction implements Action {
  readonly type: string = ADD_TASK_FAILURE;

  constructor(public payload: any) {}
}

// Set Next Lead List
export class SetNextLeadListAction implements Action {
  readonly type: string = SET_NEXT_LEAD_LIST;

  constructor(public payload: number[]) {}
}

// Lock/Unlock Lead
export class LockLeadAction implements Action {
  readonly type: string = LOCK_LEAD;

  constructor(public payload: number) {}
}

export class LockLeadSuccessAction implements Action {
  readonly type: string = LOCK_LEAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LockLeadFailureAction implements Action {
  readonly type: string = LOCK_LEAD_FAILURE;

  constructor(public payload: any) {}
}

export class UnlockLeadAction implements Action {
  readonly type: string = UNLOCK_LEAD;

  constructor(public payload: number) {}
}

export class UnlockLeadSuccessAction implements Action {
  readonly type: string = UNLOCK_LEAD_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class UnlockLeadFailureAction implements Action {
  readonly type: string = UNLOCK_LEAD_FAILURE;

  constructor(public payload: any) {}
}

// Actions for Approve and Reject Solar Tariff
export interface IApproveSolarTarifPayload {
  leadId: number;
  approved: boolean;
}

export class ApproveSolarTarifAction implements Action {
  readonly type: string = APPROVE_SOLAR_TARIF;

  constructor(public payload: IApproveSolarTarifPayload) {}
}

export class ApproveSolarTarifSuccessAction implements Action {
  readonly type: string = APPROVE_SOLAR_TARIF_SUCCESS;

  constructor(public payload: any) {}
}

export class ApproveSolarTarifFailedAction implements Action {
  readonly type: string = APPROVE_SOLAR_TARIF_FAILURE;

  constructor(public payload: any) {}
}

export interface ICreatePvAutoOfferPayload {
  leadId: number;
  request: PvAutoOfferRequest;
}

export class CreatePvAutoOfferAction implements Action {
  readonly type: string = CREATE_PV_AUTO_OFFER;

  constructor(public payload: ICreatePvAutoOfferPayload) {}
}

export class CreatePvAutoOfferSuccessAction implements Action {
  readonly type: string = CREATE_PV_AUTO_OFFER_SUCCESS;

  constructor(public payload: AutoOfferResult) {}
}

export class CreatePvAutoOfferFailedAction implements Action {
  readonly type: string = CREATE_PV_AUTO_OFFER_FAILURE;

  constructor(public payload: any) {}
}

export type Actions =
  | OwnLoadAction
  | OwnLoadSuccessAction
  | OwnLoadFailureAction
  | OwnMtCrmLoadAction
  | OwnMtCrmLoadSuccessAction
  | OwnMtCrmLoadFailureAction
  | WonLoadAction
  | WonLoadSuccessAction
  | WonLoadFailureAction
  | TakeoverAction
  | TakeoverSuccessAction
  | TakeoverFailureAction
  | OnlinepoolLoadAction
  | OnlinepoolLoadSuccessAction
  | OnlinepoolLoadFailureAction
  | AllLoadAction
  | AllLoadSuccessAction
  | AllLoadFailureAction
  | TriageLoadAction
  | TriageLoadSuccessAction
  | TriageLoadFailureAction
  | DetailLoadAction
  | DetailLoadSuccessAction
  | DetailLoadFailureAction
  | DetailLoadEmLeadAction
  | DetailLoadEmLeadSuccessAction
  | DetailLoadEmLeadFailureAction
  | DetailLoadHtLeadAction
  | DetailLoadHtLeadSuccessAction
  | DetailLoadHtLeadFailureAction
  | PatchEmLeadAction
  | PatchEmLeadSuccessAction
  | PatchEmLeadFailedAction
  | PatchHtLeadAction
  | PatchHtLeadSuccessAction
  | PatchHtLeadFailedAction
  | DetailAddTaskAction
  | DialogAddTaskAction
  | DialogAddTaskSuccessAction
  | DialogAddTaskSuccessResetAction
  | DetailAddTaskSuccessAction
  | DetailAddTaskFailureAction
  | DetailUpdateTaskAction
  | DetailUpdateTaskSuccessAction
  | DetailUpdateTaskFailureAction
  | DetailRemoveTaskAction
  | DetailRemoveTaskSuccessAction
  | DetailRemoveTaskFailureAction
  | DetailUpdateAppointmentAction
  | DetailUpdateAppointmentSuccessAction
  | DetailUpdateAppointmentFailureAction
  | DetailRemoveAppointmentAction
  | DetailRemoveAppointmentSuccessAction
  | DetailRemoveAppointmentFailureAction
  | DetailAddAppointmentAction
  | DetailAddAppointmentSuccessAction
  | DetailAddAppointmentFailureAction
  | DetailCloneVariantFailedAction
  | UpdateVariantSalesProbabilityAction
  | UpdateVariantSalesProbabilitySuccessAction
  | UpdateVariantSalesProbabilityFailureAction
  | LoadProjectPropertiesAction
  | LoadProjectPropertiesSuccessAction
  | LoadProjectPropertiesFailureAction
  | StoreProjectPropertiesAction
  | StoreProjectPropertiesSuccessAction
  | StoreProjectPropertiesFailureAction
  | CreateLeadFromExistingAction
  | CreateLeadFromExistingSuccessAction
  | CreateLeadFromExistingFailureAction
  | CreateLeadFromExistingCancelledAction
  | AddAppointmentAction
  | AddAppointmentSuccessAction
  | AddAppointmentFailureAction
  | SetNextLeadListAction
  | LockLeadAction
  | LockLeadSuccessAction
  | LockLeadFailureAction
  | UnlockLeadAction
  | UnlockLeadSuccessAction
  | UnlockLeadFailureAction
  | BillVariantAction
  | BillVariantSuccessAction
  | BillyVariantFailedAction
  | ApproveSolarTarifAction
  | ApproveSolarTarifSuccessAction
  | ApproveSolarTarifFailedAction
  | UpdateVariantAction
  | UpdateVariantSuccessAction
  | UpdateVariantFailedAction
  | GetInvoicesAction
  | GetInvoicesSuccessAction
  | GetInvoicesFailureAction
  | InvoicePdfDownloadAction
  | CreatePvAutoOfferAction
  | CreatePvAutoOfferSuccessAction
  | CreatePvAutoOfferFailedAction;
