import { Component, Input } from '@angular/core';
import { DirectoryCompanyViewModel } from '../../../../shared/apis/advis';
import { IContactInfo } from '../contact-info/contact-info.component';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'pc-company-contact',
  templateUrl: './company-contact.component.html',
  styleUrls: ['./company-contact.component.scss'],
})
export class CompanyContactComponent {
  @Input() public isCompanyContactSelect: boolean;
  @Input() public company: DirectoryCompanyViewModel;

  public setCompanyInfo(): IContactInfo {
    if (!this.company) {
      return {
        contactLine1: '',
        contactLine2: '',
        email: '',
        phone: '',
        language: '',
      };
    }

    const strCompanyAdditionalName = isNotNullOrUndefined(this.company.AdditionalName)
      ? ', ' + this.company.AdditionalName
      : '';
    const strLine2: string[] = [this.company.ZipCode, this.company.City, this.company.Street];
    return {
      contactLine1: this.company.Name + strCompanyAdditionalName,
      contactLine2: strLine2.filter(x => isNotNullOrUndefined(x)).join(', '),
      email:
        this.company.Emails && this.company.Emails.length > 0 ? this.company.Emails[0].Email : '',
      phone:
        this.company.Phones && this.company.Phones.length > 0
          ? this.company.Phones[0].PhoneNumber
          : '',
      language: '',
    };
  }
}
