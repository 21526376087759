import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AdditionalAddressTypeViewModel,
  DirectoryContactAddressViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  LeadContactViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../../shared/utils/exter-managed-address-type.util';
import { IRegionalInformation } from '../models/regional-information';

@Component({
  selector: 'pc-contact [allExternalManagedAddressConfig]',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public title: string;

  @Input() public contact: LeadContactViewModel;

  @Input() public allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  @Input() public regionalInformation: IRegionalInformation;

  @Input() public masterData: MasterDataViewModel;

  @Input() public isReadOnly: boolean;

  @Input() public primary: boolean;

  @Input() public isAccordion: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onEdit: EventEmitter<void> = new EventEmitter<void>();

  public address: DirectoryContactAddressViewModel;
  public swissTopoLink: string;
  public searchLink: string;
  public additionalEmailTypesTranslations: { [key: string]: string } = {};
  public additionalPhoneTypesTranslations: { [key: string]: string } = {};
  public LeadContactViewModel: typeof LeadContactViewModel = LeadContactViewModel;
  public isExternalManaged: boolean;

  protected readonly DirectoryPersonViewModel = DirectoryPersonViewModel;

  constructor() {}

  private static convertTypesToTranslations(types: AdditionalAddressTypeViewModel[]): {
    [key: string]: string;
  } {
    return (types || []).reduce(
      (res: object, { Key, Translation }: AdditionalAddressTypeViewModel) => {
        res[Key] = Translation;
        return res;
      },
      {}
    );
  }

  public ngOnInit(): void {
    this.address = this.contact.MainContact;
    this.swissTopoLink = this.getSwissTopoLink();
    this.isExternalManaged = ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
      this.contact.AddressType,
      this.allExternalManagedAddressConfig
    );
  }

  public ngAfterViewInit(): void {
    this.searchLink =
      'https://map.search.ch/..' + this.address.City + ' ,' + this.address.Street + '.?mode=car';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.masterData !== 'undefined') {
      this.additionalEmailTypesTranslations = ContactComponent.convertTypesToTranslations(
        this.masterData.AdditionalEmailTypes
      );
      this.additionalPhoneTypesTranslations = ContactComponent.convertTypesToTranslations(
        this.masterData.AdditionalPhoneTypes
      );
    }
    if (changes.contact && this.contact) {
      this.address = this.contact.MainContact;
      this.swissTopoLink = this.getSwissTopoLink();
    }

    this.searchLink =
      'https://map.search.ch/..' + this.address.City + ' ,' + this.address.Street + '.?mode=car';
  }

  public getPhones(): DirectoryPhoneViewModel[] {
    if (this.address?.Phones) {
      return this.address.Phones;
    }
    return [];
  }

  public getMails(): DirectoryEmailViewModel[] {
    if (this.address?.Emails) {
      return this.address.Emails;
    }
    return [];
  }

  public getSwissTopoLink(): string {
    if (isNullOrUndefined(this.address) || isNullOrUndefined(this.address)) {
      return undefined;
    }
    const address: DirectoryContactAddressViewModel = this.address;
    const escapedAddress: string = encodeURI(
      `${address.Street} ${address.ZipCode} ${address.City}`
    );
    return `http://map.geo.admin.ch/?swisssearch=${escapedAddress}&zoom=12&bgLayer=ch.swisstopo.swissimage&lang=en&topic=ech&layers=ch.bfs.gebaeude_wohnungs_register,ch.are.bauzonen&layers_opacity=1,0.6&layers_visibility=true,false`;
  }

  public editAddress(): void {
    this.onEdit.emit();
  }
}
