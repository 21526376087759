import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CommonComponentsModule } from '../common-components/common-components.module';

import { CompanyContactFormComponent } from './lead-address/address-book-contact/company-contact/company-contact-form/company-contact-form.component';
import { PersonContactFormComponent } from './lead-address/address-book-contact/person-contact/person-contact-form/person-contact-form.component';
import { ContactSelectorComponent } from './lead-address/address-book-contact/contact-selector/contact-selector.component';
import { CompanyContactComponent } from './lead-address/address-book-contact/company-contact/company-contact.component';
import { PersonContactComponent } from './lead-address/address-book-contact/person-contact/person-contact.component';
import { AddressBookContactComponent } from './lead-address/address-book-contact/address-book-contact.component';
import { ContactInfoComponent } from './lead-address/address-book-contact/contact-info/contact-info.component';
import { AddressHeaderComponent } from './addresses-card/address-header/address-header.component';
import { SingleContactComponent } from './lead-address/single-contact/single-contact.component';
import { ContactComponent } from './addresses-card/contact/contact.component';
import { AddressComponent } from './addresses-card/address/address.component';
import { AddressesCardComponent } from './addresses-card/addresses-card.component';
import { EditCompanyContactDialogComponent } from './dialog/edit-company-contact-dialog/edit-company-contact-dialog.component';
import { MenuSaveToExternalManagedContactComponent } from './lead-address/external-managed-contact/menu-save-to-external-managed-contact/menu-save-to-external-managed-contact.component';
import { AddressDetailSearchDialogComponent } from './dialog/address-detail-search-dialog/address-detail-search-dialog.component';
import { AddressDialogComponent } from './dialog/address-dialog/address-dialog.component';
import { AddressCardContentComponent } from './addresses-card/address-card-content/address-card-content.component';
import { DuplicateContactDialogComponent } from './dialog/duplicate-contact-dialog/duplicate-contact-dialog.component';
import { EditPersonContactDialogComponent } from './dialog/edit-person-contact-dialog/edit-person-contact-dialog.component';
import { CreateEditAddressDialogComponent } from './dialog/create-edit-address-dialog/create-edit-address-dialog.component';
import { ContactSelectorDialogComponent } from './dialog/contact-selector-dialog/contact-selector-dialog.component';
import { SelectPersonDialogComponent } from './dialog/select-person-dialog/select-person-dialog.component';
import { CompanyNameTypeaheadComponent } from './company-name-typeahead/company-name-typeahead.component';
import { LibraryModule } from '@sitewerk/theia-ui-lib';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActionButtonsComponent } from './addresses-card/action-buttons/action-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxDatatableModule,
    CommonComponentsModule,
    LibraryModule,
    FlexLayoutModule,
  ],
  declarations: [
    AddressesCardComponent,
    MenuSaveToExternalManagedContactComponent,
    AddressDialogComponent,
    AddressCardContentComponent,
    SelectPersonDialogComponent,
    ContactSelectorDialogComponent,
    SingleContactComponent,
    ContactComponent,
    AddressComponent,
    AddressHeaderComponent,
    ContactInfoComponent,
    CompanyContactFormComponent,
    PersonContactFormComponent,
    CompanyNameTypeaheadComponent,
    ContactSelectorComponent,
    CompanyContactComponent,
    PersonContactComponent,
    AddressBookContactComponent,
    AddressDetailSearchDialogComponent,
    DuplicateContactDialogComponent,
    EditCompanyContactDialogComponent,
    EditPersonContactDialogComponent,
    CreateEditAddressDialogComponent,
    ActionButtonsComponent,
  ],
  exports: [
    MenuSaveToExternalManagedContactComponent,
    AddressesCardComponent,
    AddressDialogComponent,
    AddressCardContentComponent,
    SelectPersonDialogComponent,
    ContactSelectorDialogComponent,
    CreateEditAddressDialogComponent,
    EditCompanyContactDialogComponent,
    EditPersonContactDialogComponent,
    DuplicateContactDialogComponent,
    SingleContactComponent,
    ContactComponent,
    AddressComponent,
    AddressHeaderComponent,
    ContactInfoComponent,
    CompanyContactFormComponent,
    PersonContactFormComponent,
    CompanyNameTypeaheadComponent,
    ContactSelectorComponent,
    CompanyContactComponent,
    PersonContactComponent,
    AddressBookContactComponent,
    AddressDetailSearchDialogComponent,
  ],
})
export class AddressBookSharedModule {}
