import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressViewModel } from 'app/shared/apis/advis';

@Injectable({
  providedIn: 'root',
})
export class AddressValidationMessageBuilderService {
  constructor(private translate: TranslateService) {}

  public getInvalidLeadAddressesWarningMessage(
    addresses: AddressViewModel[],
    leadId: number
  ): string {
    const messages = addresses.map(
      address =>
        `<p>${this.translate.instant('ADDRESS.DIALOGS.ADDRESS_VALIDATION_TEXT1', {
          addressType: address.AddressType,
        })}` +
        `<br>${this.translate.instant(
          'ADDRESS.DIALOGS.ADDRESS_VALIDATION_TEXT2'
        )} here: <a href='${this.getAddressLinkByType(address, leadId)}'/>${
          address.AddressType
        }</a></p>`
    );
    const addressInfoRows = messages.reduce((acc, m) => (acc += `<p>${m}</p>`));
    return (
      addressInfoRows +
      `<br><p>${this.translate.instant('ADDRESS.DIALOGS.ADDRESS_VALIDATION_TEXT3')}</p>`
    );
  }

  private getAddressLinkByType(address: AddressViewModel, leadId: number): string {
    switch (address.AddressType) {
      case AddressViewModel.AddressTypeEnum.Contact:
        return `lead/person/${leadId}`;
      case AddressViewModel.AddressTypeEnum.Object:
        return `lead/objectaddr/${leadId}`;
      case AddressViewModel.AddressTypeEnum.Debitor:
        return `lead/debitoraddr/${leadId}`;
      default:
        return `lead/leadaddr/${leadId}?addressId=${address.Id}`;
    }
  }
}
