<section class="dialog">
  <h2 class="dialog-title">
    {{ 'LEADS.PROJECT_RANKING' | translate }}
  </h2>

  <div class="dialog-content">
    <helion-ui-spinner *ngIf="!isLoaded"></helion-ui-spinner>

    <div *ngIf="isLoaded">
      <div *ngIf="topScores.length === 0">
        {{ 'LEADS.PROJECT_RANKING_NO_ENTRY' | translate }}
      </div>
      <div *ngFor="let score of topScores">
        <b *ngIf="score.IsCurrentUser"
          ><ng-container
            [ngTemplateOutlet]="scoreRecord"
            [ngTemplateOutletContext]="{ score: score }"></ng-container
        ></b>
        <span *ngIf="!score.IsCurrentUser"
          ><ng-container
            [ngTemplateOutlet]="scoreRecord"
            [ngTemplateOutletContext]="{ score: score }"></ng-container
        ></span>
      </div>
      <div *ngIf="hasSpaceInBetween">...</div>
      <div *ngFor="let score of otherScores">
        <b *ngIf="score.IsCurrentUser"
          ><ng-container
            [ngTemplateOutlet]="scoreRecord"
            [ngTemplateOutletContext]="{ score: score }"></ng-container
        ></b>
        <span *ngIf="!score.IsCurrentUser"
          ><ng-container
            [ngTemplateOutlet]="scoreRecord"
            [ngTemplateOutletContext]="{ score: score }"></ng-container
        ></span>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <button [attr.fill-type]="'contained'" (click)="cancel()">
      {{ 'COMMON.BTN_OK' | translate }}
    </button>
  </div>

  <ng-template #scoreRecord let-score="score">
    <span fxLayout="row" fxLayoutAlign="start center">
      {{ score.Rank }}.
      <helion-ui-icon *ngIf="score.Score >= 30000" data-feather="award"></helion-ui-icon>
      {{ score.ProjectManager }} - {{ score.Score }}
    </span>
  </ng-template>
</section>