import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { forkJoin, merge, Observable, of, toArray } from 'rxjs';
import { Action, createSelector, MemoizedSelector, Selector, Store } from '@ngrx/store';
import * as LeadDocument from './lead-document.action';
import {
  CreateLeadFolderFailedAction,
  DeleteDocumentFailedAction,
  DeleteFileFailedAction,
  DeletePictureFailedAction,
  IDeleteDocumentActionPayload,
  IDeleteFileActionPayload,
  IDeletePictureActionPayload,
  IGetDocuments,
  IGetDocumentsSuccess,
  IGetPictures,
  IGetPicturesSuccess,
  ITestFolderConnectionPayload,
  IUploadDocumentActionPayload,
  IUploadFileActionPayload,
  IUploadPictureActionPayload,
  IUseFolderConnectionPayload,
  UploadDocumentFailedAction,
  UploadFileFailedAction,
} from './lead-document.action';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  DocumentService,
  DocumentTemplatesViewModel,
  FileLinkViewModel,
  LeadDocumentListViewModel,
  LeadDocumentViewModel,
  LeadFileService,
  LeadService,
  LeadViewModel,
  PictureListViewModel,
  PictureViewModel,
} from '../../apis/advis';
import { ErrorAddAction } from '../global/global.action';
import { ErrorTypeE } from '../global/global.reducer';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as RootReducer from '../index';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { NotificationService, TypeE } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

const MAX_LEAD_FILE_UPLOAD_PICTURE_SIZE: number = 30000000; // 30Mb

@Injectable()
export class LeadDocumentEffects {
  protected loadLeadDocumentTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.LOAD_LEAD_DOCUMENT_TEMPLATES),
      map((action: LeadDocument.LoadLeadDocumentTemplatesAction) => action.payload),
      switchMap((payload: { leadId: number; language: LeadViewModel.LanguageEnum }) =>
        this.documentService
          .pCApiLeadDocumentGetLeadDocumentTemplates(payload.leadId, payload.language)
          .pipe(
            map((result: DocumentTemplatesViewModel) => {
              return new LeadDocument.LoadLeadDocumentTemplatesSuccessAction(result);
            })
          )
      ),
      catchError((error: any) => of(new LeadDocument.LoadLeadDocumentTemplatesFailedAction(error)))
    )
  );

  protected getSyncDocumentAndPicture$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.SYNC_DOCUMENT_AND_PICTURE),
      map((action: LeadDocument.SyncDocumentAndPicturesAction) => action.payload),
      switchMap((payload: { leadId: number }) =>
        this.leadDocument.leadFileTriggerFileSync(payload.leadId).pipe(
          map(() => {
            return new LeadDocument.SyncDocumentAndPicturesSuccessAction(payload.leadId);
          })
        )
      ),
      catchError((error: any) => of(new LeadDocument.GetPicturesFailedAction(error)))
    )
  );

  protected testFolderConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.TEST_FOLDER_CONNECTION),
      map((action: LeadDocument.TestFolderConnectionAction) => action.payload),
      switchMap((payload: ITestFolderConnectionPayload) =>
        this.leadDocument
          .leadFileTestSharePointFolderConnection(payload.leadId, payload.relativeServerUrl)
          .pipe(
            map(data => {
              if (data) {
                return new LeadDocument.TestFolderConnectionSuccessAction({
                  folderName: data.FolderName,
                  relativeServerUrl: data.AbsoluteServerUrl,
                  uniqueId: data.UniqueFolderId,
                  leadId: payload.leadId,
                } as ITestFolderConnectionPayload);
              } else {
                return new LeadDocument.TestFolderConnectionFailedAction(null);
              }
            })
          )
      ),
      catchError((error: any) => of(new LeadDocument.TestFolderConnectionFailedAction(error)))
    )
  );

  protected useFolderConnection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.USE_FOLDER_CONNECTION),
      map((action: LeadDocument.UseFolderConnectionAction) => action.payload),
      switchMap((payload: IUseFolderConnectionPayload) =>
        this.leadDocument
          .leadFileUseSharePointFolderConnection(payload.leadId, payload.serverUrl)
          .pipe(
            map(data => {
              return new LeadDocument.UseFolderConnectionSuccessAction({
                folderName: data.FolderName,
                serverUrl: data.AbsoluteServerUrl,
                uniqueId: data.UniqueFolderId,
                leadId: payload.leadId,
              } as IUseFolderConnectionPayload);
            })
          )
      ),
      catchError((error: any) => of(new LeadDocument.UseFolderConnectionFailedAction(error)))
    )
  );

  protected getPictures$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.GET_PICTURES),
      map((action: LeadDocument.GetPicturesAction) => action.payload),
      switchMap((payload: IGetPictures) => {
        const selectPictures: Selector<RootReducer.IState, PictureListViewModel> = (
          storeState: RootReducer.IState
        ) => storeState.leadDocument.pictures;
        const selectLeadId: Selector<RootReducer.IState, number> = (
          storeState: RootReducer.IState
        ) => storeState.leadDocument.picturesLeadId;
        const combinedSelectors: MemoizedSelector<RootReducer.IState, any> = createSelector(
          selectPictures,
          selectLeadId,
          (pictures: PictureListViewModel, leadId: number) => ({ pictures, leadId })
        );
        return this.store.select(combinedSelectors).pipe(
          take(1),
          switchMap((selectResult: any) => {
            if (
              !isEmpty(selectResult.pictures) &&
              !isNotNullOrUndefined(payload.useForcePicturesLoad) &&
              !payload.useForcePicturesLoad
            ) {
              return [
                new LeadDocument.GetPicturesSuccessAction({
                  pictures: selectResult.pictures,
                  leadId: payload.leadId,
                } as IGetPicturesSuccess),
              ];
            }
            return this.leadDocument.leadFileGetLeadPictures(payload.leadId).pipe(
              map((pictures: PictureListViewModel) => {
                return new LeadDocument.GetPicturesSuccessAction({
                  pictures,
                  leadId: payload.leadId,
                } as IGetPicturesSuccess);
              }),
              catchError((error: any) => of(new LeadDocument.GetPicturesFailedAction(error)))
            );
          })
        );
      })
    )
  );

  protected uploadPicture$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.UPLOAD_PICTURE),
      map((action: LeadDocument.UploadPictureAction) => action.payload),
      switchMap((payload: IUploadPictureActionPayload) => {
        const filesToUpload: Blob[] = cloneDeep(payload.files);

        return merge(
          ...filesToUpload
            .reduce(
              (res, file: Blob) => {
                const index = res.findIndex(
                  ({ size }) => size + file.size < MAX_LEAD_FILE_UPLOAD_PICTURE_SIZE
                );
                if (index < 0) {
                  res.push({ files: [file], size: file.size });
                } else {
                  res[index].files.push(file);
                  res[index].size = res[index].size + file.size;
                }

                return res;
              },
              [{ files: [], size: 0 }]
            )
            .map(({ files }: { files: Blob[]; size: number }) =>
              this.leadDocument.leadFileUploadPicture(
                payload.leadId,
                payload.folder,
                payload.tags.join(','),
                files
              )
            ),
          1 // regulates how mane parallel uploads are allowed
        ).pipe(
          toArray(),
          mergeMap(() => {
            this.notificationService.notifySimple(
              this.translate.instant('PICTURE_UPLOAD.UPLOAD_SUCCESS') +
                ' "' +
                (payload.tags.length > 0 ? payload.tags.join(',') + '"' : ''),
              TypeE.PRIMARY
            );
            return [
              new LeadDocument.UploadPictureSuccessAction(undefined),
              new LeadDocument.GetPicturesAction({
                leadId: payload.leadId,
                useForcePicturesLoad: true,
              } as IGetPictures),
            ];
          })
        );
      }),
      catchError((error: any) => {
        this.notificationService.notifySimple(
          this.translate.instant('PICTURE_UPLOAD.UPLOAD_FAILED'),
          TypeE.ALERT
        );
        return of(new LeadDocument.UploadPictureFailedAction(error));
      })
    )
  );

  protected deletePicture$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.DELETE_PICTURE),
      map((action: LeadDocument.DeletePictureAction) => action.payload),
      switchMap((payload: IDeletePictureActionPayload) =>
        this.leadDocument
          .leadFileDeletePicture(payload.leadId, payload.pictureName, payload.folder)
          .pipe(
            map(() => {
              return new LeadDocument.DeletePictureSuccessAction({
                fileName: payload.pictureName,
                folder: payload.folder,
              });
            })
          )
      ),
      catchError((error: any) => of(new LeadDocument.DeletePictureFailedAction(error)))
    )
  );

  protected downloadPicture$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.DOWNLOAD_PICTURE),
      map((action: LeadDocument.DownloadPictureAction) => action.payload),
      switchMap((payload: PictureViewModel) => {
        const url: string = payload.AccessUri;
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = payload.FileName;
        downloadLink.click();
        return of(new LeadDocument.DownloadPictureSuccessAction(undefined));
      }),
      catchError((error: any) => of(new LeadDocument.DownloadPictureFailedAction(error)))
    )
  );

  protected getDocuments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.GET_DOCUMENTS),
      map((action: LeadDocument.GetDocumentsAction) => action.payload),
      switchMap((payload: IGetDocuments) => {
        const selectDocuments: Selector<RootReducer.IState, LeadDocumentListViewModel> = (
          storeState: RootReducer.IState
        ) => storeState.leadDocument.documents;
        const selectLeadId: Selector<RootReducer.IState, number> = (
          storeState: RootReducer.IState
        ) => storeState.leadDocument.documentsLeadId;
        const combinedSelectors: MemoizedSelector<RootReducer.IState, any> = createSelector(
          selectDocuments,
          selectLeadId,
          (documents: LeadDocumentListViewModel, leadId: number) => ({ documents, leadId })
        );
        return this.store.select(combinedSelectors).pipe(
          take(1),
          switchMap((selectResult: any) => {
            if (
              !isEmpty(selectResult.documents) &&
              payload.leadId === selectResult.leadId &&
              !isNotNullOrUndefined(payload.useForceDocumentsLoad) &&
              !payload.useForceDocumentsLoad
            ) {
              return [
                new LeadDocument.GetDocumentsSuccessAction({
                  documents: selectResult.documents,
                  leadId: payload.leadId,
                } as IGetDocumentsSuccess),
              ];
            }
            return this.leadDocument.leadFileGetLeadDocuments(payload.leadId).pipe(
              map((documents: LeadDocumentListViewModel) => {
                return new LeadDocument.GetDocumentsSuccessAction({
                  documents: documents,
                  leadId: payload.leadId,
                } as IGetDocumentsSuccess);
              }),
              catchError((error: any) => of(new LeadDocument.GetDocumentsFailedAction(error)))
            );
          })
        );
      })
    )
  );

  protected uploadDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.UPLOAD_DOCUMENT),
      map((action: LeadDocument.UploadDocumentAction) => action.payload),
      switchMap((payload: IUploadDocumentActionPayload) => {
        const filesToUpload: Blob[] = cloneDeep(payload.files);
        const firstFile: Blob = filesToUpload.shift();
        return this.leadDocument
          .leadFileUploadDocument(
            payload.leadId,
            payload.fileFolder,
            payload.tags.join(','),
            firstFile
          )
          .pipe(
            mergeMap(() => {
              if (filesToUpload.length === 0) {
                return [
                  new LeadDocument.UploadDocumentSuccessAction(undefined),
                  new LeadDocument.GetDocumentsAction({
                    leadId: payload.leadId,
                    useForceDocumentsLoad: true,
                  } as IGetPictures),
                ];
              }
              return forkJoin(
                filesToUpload.map((file: Blob) =>
                  this.leadDocument.leadFileUploadDocument(
                    payload.leadId,
                    payload.fileFolder,
                    payload.tags.join(','),
                    file
                  )
                )
              ).pipe(
                mergeMap(() => {
                  return [
                    new LeadDocument.UploadDocumentSuccessAction(undefined),
                    new LeadDocument.GetDocumentsAction({
                      leadId: payload.leadId,
                      useForceDocumentsLoad: true,
                    } as IGetPictures),
                  ];
                })
              );
            })
          );
      }),
      catchError((error: any) => of(new LeadDocument.UploadDocumentFailedAction(error)))
    )
  );

  protected deleteDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.DELETE_DOCUMENT),
      map((action: LeadDocument.DeleteDocumentAction) => action.payload),
      switchMap((payload: IDeleteDocumentActionPayload) =>
        this.leadDocument
          .leadFileDeleteDocument(payload.leadId, payload.documentName, payload.fileFolder)
          .pipe(
            map(() => {
              return new LeadDocument.DeleteDocumentSuccessAction({
                fileName: payload.documentName,
                fileFolder: payload.fileFolder,
              });
            })
          )
      ),
      catchError((error: any) => of(new LeadDocument.DeleteDocumentFailedAction(error)))
    )
  );

  protected downloadDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.DOWNLOAD_DOCUMENT),
      map((action: LeadDocument.DownloadDocumentAction) => action.payload),
      switchMap((payload: LeadDocumentViewModel) => {
        const url: string = payload.AccessUri;
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = payload.FileName;
        downloadLink.click();
        return of(new LeadDocument.DownloadDocumentSuccessAction(undefined));
      }),
      catchError((error: any) => of(new LeadDocument.DownloadDocumentFailedAction(error)))
    )
  );

  protected getFiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.GET_FILES),
      map((action: LeadDocument.GetFilesAction) => action.payload),
      switchMap((payload: { leadId: number }) =>
        this.leadDocument.leadFileGetLeadFiles(payload.leadId).pipe(
          map((files: FileLinkViewModel[]) => {
            return new LeadDocument.GetFilesSuccessAction(files);
          })
        )
      ),
      catchError((error: any) => of(new LeadDocument.GetFilesFailedAction(error)))
    )
  );

  protected getStencils$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.GET_LEAD_STENCILS),
      map((action: LeadDocument.GetLeadStencilsAction) => action.payload),
      switchMap(payload =>
        this.leadService
          .leadFileGetAvailableStencils(payload)
          .pipe(map(stencils => new LeadDocument.GetLeadStencilsSuccessAction(stencils)))
      ),
      catchError((error: any) => of(new LeadDocument.GetLeadStencilsFailedAction(error)))
    )
  );

  protected createFolder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.CREATE_LEAD_FOLDER),
      map((action: LeadDocument.CreateLeadFolderAction) => action.payload),
      switchMap(payload =>
        this.leadService
          .leadFileCreateLeadFolder(payload.leadId, payload.folderName)
          .pipe(map(documents => new LeadDocument.CreateLeadFolderSuccessAction(documents)))
      ),
      catchError((error: any) => of(new LeadDocument.CreateLeadFolderFailedAction(error)))
    )
  );

  protected uploadFile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.UPLOAD_FILE),
      map((action: LeadDocument.UploadFileAction) => action.payload),
      switchMap((payload: IUploadFileActionPayload) => {
        const filesToUpload: Blob[] = cloneDeep(payload.files);
        const firstFile: Blob = filesToUpload.shift();
        return this.leadDocument.leadFileUploadFile(payload.leadId, firstFile).pipe(
          mergeMap(() => {
            if (filesToUpload.length === 0) {
              return [
                new LeadDocument.UploadFileSuccessAction(undefined),
                new LeadDocument.GetFilesAction({ leadId: payload.leadId }),
              ];
            }
            return forkJoin(
              filesToUpload.map((file: Blob) =>
                this.leadDocument.leadFileUploadFile(payload.leadId, file)
              )
            ).pipe(
              mergeMap(() => {
                return [
                  new LeadDocument.UploadFileSuccessAction(undefined),
                  new LeadDocument.GetFilesAction({ leadId: payload.leadId }),
                ];
              })
            );
          })
        );
      }),
      catchError((error: any) => of(new LeadDocument.UploadFileFailedAction(error)))
    )
  );

  protected deleteFile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadDocument.DELETE_FILE),
      map((action: LeadDocument.DeleteFileAction) => action.payload),
      switchMap((payload: IDeleteFileActionPayload) =>
        this.leadDocument.leadFileDeleteFile(payload.leadId, payload.documentName).pipe(
          mergeMap(() => {
            return [
              new LeadDocument.DeleteFileSuccessAction({ fileName: payload.documentName }),
              new LeadDocument.GetFilesAction({ leadId: payload.leadId }),
            ];
          })
        )
      ),
      catchError((error: any) => of(new LeadDocument.DeleteFileFailedAction(error)))
    )
  );

  protected saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LeadDocument.UPLOAD_DOCUMENT_FAILED,
        LeadDocument.DELETE_DOCUMENT_FAILED,
        LeadDocument.DELETE_PICTURE_FAILED,
        LeadDocument.UPLOAD_FILE_FAILED,
        LeadDocument.DELETE_FILE_FAILED
      ),
      map(
        (
          action:
            | UploadDocumentFailedAction
            | DeleteDocumentFailedAction
            | DeletePictureFailedAction
            | UploadFileFailedAction
            | DeleteFileFailedAction
            | CreateLeadFolderFailedAction
        ) => {
          return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private leadDocument: LeadFileService,
    private documentService: DocumentService,
    private leadService: LeadService,
    private store: Store<RootReducer.IState>,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {
    // empty
  }
}
