import { PcUserPermissionsViewModel } from '../apis/advis';
import { Injectable } from '@angular/core';

@Injectable()
export class PermissionService {
  private _userPermissions: PcUserPermissionsViewModel;

  get userPermissions(): PcUserPermissionsViewModel {
    return this._userPermissions;
  }

  set userPermissions(value: PcUserPermissionsViewModel) {
    this._userPermissions = value;
    // note uncomment to have all permissions set to true
    // Object.keys(this._userPermissions).forEach((x: any) => this._userPermissions[x] = true);
  }
}
