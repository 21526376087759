import { Pipe, PipeTransform } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import {
  ContactAddressContactListViewModel,
  LeadCompositeViewModel,
  LeadContactViewModel,
  LeadListViewModel,
  LeadViewModel,
} from '../../shared/apis/advis';
import { isNullOrUndefined } from '../../shared/utils/isNullOrUndefined';
import { ContactViewModelUtil } from '../../shared/utils/contact-view-model.util';
import { TruncateLeadNamePipe } from 'app/common-components/truncate-lead-name.pipe';

@Pipe({ name: 'leadAddressCompoundForHeader' })
export class LeadAddressCompoundForHeaderPipe implements PipeTransform {
  constructor(private truncateNamePipe: TruncateLeadNamePipe) {}

  public transform(
    leadComposite: LeadCompositeViewModel | LeadListViewModel | LeadViewModel,
    truncateProjectName: boolean
  ): string {
    const lead: LeadViewModel | LeadListViewModel =
      'Lead' in leadComposite
        ? (leadComposite.Lead as LeadViewModel)
        : (leadComposite as LeadListViewModel);
    const leadId: number = lead.Id;
    const ProjectName: string =
      'Project' in lead ? lead.Project?.ProjectName : (lead as LeadListViewModel)?.ProjectName;
    const ContactAddress: LeadContactViewModel | ContactAddressContactListViewModel =
      'ContactAddress' in lead
        ? lead.ContactAddress
        : (lead as LeadListViewModel)?.ContactAddressContact;
    if (ProjectName) {
      return truncateProjectName ? this.truncateNamePipe.transform(ProjectName) : ProjectName;
    }

    const contactPerson: LeadContactViewModel = cloneDeep(ContactAddress);
    if (!isNullOrUndefined(contactPerson)) {
      let addressStr: string =
        ContactViewModelUtil.createNameOrCompanyNameContactAddressForHeader(contactPerson);

      if (contactPerson.Company) {
        addressStr +=
          (isNullOrUndefined(contactPerson.Company.ZipCode)
            ? ''
            : ', ' + contactPerson.Company.ZipCode) +
          (isNullOrUndefined(contactPerson.Company.City) ? '' : ' ' + contactPerson.Company.City);
      } else if (contactPerson.Person) {
        addressStr +=
          (isNullOrUndefined(contactPerson.Person.ZipCode)
            ? ''
            : ', ' + contactPerson.Person.ZipCode) +
          (isNullOrUndefined(contactPerson.Person.City) ? '' : ' ' + contactPerson.Person.City);
      }

      return addressStr;
    }

    console.warn('Unable to create compound address for leadId=' + leadId);
    return '';
  }
}
