/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FileLinkViewModel } from '../model/fileLinkViewModel';
import { LeadDocumentListViewModel } from '../model/leadDocumentListViewModel';
import { LeadFilesRequest } from '../model/leadFilesRequest';
import { PatchDocumentTagRequest } from '../model/patchDocumentTagRequest';
import { PatchPictureTagRequest } from '../model/patchPictureTagRequest';
import { PictureListViewModel } from '../model/pictureListViewModel';
import { PictureViewModel } from '../model/pictureViewModel';
import { TestSharepointFolderConnectionViewModel } from '../model/testSharepointFolderConnectionViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LeadFileService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Removes a document from a lead.
     * 
     * @param leadId the id of the lead
     * @param documentName the name of the document to delete
     * @param fileFolder the file folder. Default is 01_NBO_Offer_Contract_Communication_Timetable
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileDeleteDocument(leadId: number, documentName?: string, fileFolder?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileDeleteDocument(leadId: number, documentName?: string, fileFolder?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileDeleteDocument(leadId: number, documentName?: string, fileFolder?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileDeleteDocument(leadId: number, documentName?: string, fileFolder?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileDeleteDocument.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentName !== undefined && documentName !== null) {
            queryParameters = queryParameters.set('documentName', <any>documentName);
        }
        if (fileFolder !== undefined && fileFolder !== null) {
            queryParameters = queryParameters.set('fileFolder', <any>fileFolder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a file of a lead on Azure
     * 
     * @param leadId the id of the lead
     * @param fileName name of the file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileDeleteFile(leadId: number, fileName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileDeleteFile(leadId: number, fileName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileDeleteFile(leadId: number, fileName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileDeleteFile(leadId: number, fileName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileDeleteFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * removes a picture from a lead.
     * 
     * @param leadId the id of the lead
     * @param pictureName the name of the picture to delete
     * @param fileFolder the file folder. Default is 01_PreSale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileDeletePicture(leadId: number, pictureName?: string, fileFolder?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileDeletePicture(leadId: number, pictureName?: string, fileFolder?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileDeletePicture(leadId: number, pictureName?: string, fileFolder?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileDeletePicture(leadId: number, pictureName?: string, fileFolder?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileDeletePicture.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pictureName !== undefined && pictureName !== null) {
            queryParameters = queryParameters.set('pictureName', <any>pictureName);
        }
        if (fileFolder !== undefined && fileFolder !== null) {
            queryParameters = queryParameters.set('fileFolder', <any>fileFolder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/pictures`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the documents for a lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetLeadDocuments(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadDocumentListViewModel>;
    public leadFileGetLeadDocuments(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadDocumentListViewModel>>;
    public leadFileGetLeadDocuments(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadDocumentListViewModel>>;
    public leadFileGetLeadDocuments(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetLeadDocuments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<LeadDocumentListViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the files for a lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetLeadFiles(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FileLinkViewModel>>;
    public leadFileGetLeadFiles(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileLinkViewModel>>>;
    public leadFileGetLeadFiles(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileLinkViewModel>>>;
    public leadFileGetLeadFiles(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetLeadFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FileLinkViewModel>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the zip containing provided files for a lead
     * 
     * @param leadId the id of the lead
     * @param body lead files request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetLeadFilesAsZip(leadId: number, body?: LeadFilesRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public leadFileGetLeadFilesAsZip(leadId: number, body?: LeadFilesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public leadFileGetLeadFilesAsZip(leadId: number, body?: LeadFilesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public leadFileGetLeadFilesAsZip(leadId: number, body?: LeadFilesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetLeadFilesAsZip.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/zip-files`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the pictures for a lead including a base 64 thumbnail
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetLeadPictures(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<PictureListViewModel>;
    public leadFileGetLeadPictures(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PictureListViewModel>>;
    public leadFileGetLeadPictures(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PictureListViewModel>>;
    public leadFileGetLeadPictures(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetLeadPictures.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PictureListViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/pictures`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches document tags.
     * 
     * @param leadId the id of the lead
     * @param body the new values to patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFilePatchDocumentTag(leadId: number, body?: PatchDocumentTagRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFilePatchDocumentTag(leadId: number, body?: PatchDocumentTagRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFilePatchDocumentTag(leadId: number, body?: PatchDocumentTagRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFilePatchDocumentTag(leadId: number, body?: PatchDocumentTagRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFilePatchDocumentTag.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents/tags`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add tags to a picture for a lead.
     * 
     * @param leadId the id of the lead
     * @param body the new values to patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFilePatchPictureTag(leadId: number, body?: PatchPictureTagRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFilePatchPictureTag(leadId: number, body?: PatchPictureTagRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFilePatchPictureTag(leadId: number, body?: PatchPictureTagRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFilePatchPictureTag(leadId: number, body?: PatchPictureTagRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFilePatchPictureTag.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/pictures/tags`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rotate a picture for a lead.
     * 
     * @param leadId the id of the lead
     * @param pictureName the name of the image to rotate
     * @param rotateAngle the rotation of the image
     * @param fileFolder the file folder. Default is 01_PreSale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileRotatePicture(leadId: number, pictureName?: string, rotateAngle?: number, fileFolder?: string, observe?: 'body', reportProgress?: boolean): Observable<PictureViewModel>;
    public leadFileRotatePicture(leadId: number, pictureName?: string, rotateAngle?: number, fileFolder?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PictureViewModel>>;
    public leadFileRotatePicture(leadId: number, pictureName?: string, rotateAngle?: number, fileFolder?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PictureViewModel>>;
    public leadFileRotatePicture(leadId: number, pictureName?: string, rotateAngle?: number, fileFolder?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileRotatePicture.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pictureName !== undefined && pictureName !== null) {
            queryParameters = queryParameters.set('pictureName', <any>pictureName);
        }
        if (rotateAngle !== undefined && rotateAngle !== null) {
            queryParameters = queryParameters.set('rotateAngle', <any>rotateAngle);
        }
        if (fileFolder !== undefined && fileFolder !== null) {
            queryParameters = queryParameters.set('fileFolder', <any>fileFolder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.put<PictureViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/picture/rotate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param relativeServerPath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileTestSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'body', reportProgress?: boolean): Observable<TestSharepointFolderConnectionViewModel>;
    public leadFileTestSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TestSharepointFolderConnectionViewModel>>;
    public leadFileTestSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TestSharepointFolderConnectionViewModel>>;
    public leadFileTestSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileTestSharePointFolderConnection.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (relativeServerPath !== undefined && relativeServerPath !== null) {
            queryParameters = queryParameters.set('relativeServerPath', <any>relativeServerPath);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<TestSharepointFolderConnectionViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/test-folder-connection`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trigger the manual file sync for a lead folder
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileTriggerFileSync(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileTriggerFileSync(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileTriggerFileSync(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileTriggerFileSync(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileTriggerFileSync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/trigger-file-sync`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a document for a lead
     * 
     * @param leadId the id of the lead
     * @param fileFolder the file folder. Default is 01_NBO_Offer_Contract_Communication_Timetable
     * @param tags list of document tags split by commas
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileUploadDocument(leadId: number, fileFolder?: string, tags?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileUploadDocument(leadId: number, fileFolder?: string, tags?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileUploadDocument(leadId: number, fileFolder?: string, tags?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileUploadDocument(leadId: number, fileFolder?: string, tags?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileUploadDocument.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileFolder !== undefined && fileFolder !== null) {
            queryParameters = queryParameters.set('fileFolder', <any>fileFolder);
        }
        if (tags !== undefined && tags !== null) {
            queryParameters = queryParameters.set('tags', <any>tags);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload an existing file for a lead to azure.
     * 
     * @param leadId the id of the lead
     * @param sharePointLinkToken existing file link token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileUploadExistingFile(leadId: number, sharePointLinkToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileUploadExistingFile(leadId: number, sharePointLinkToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileUploadExistingFile(leadId: number, sharePointLinkToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileUploadExistingFile(leadId: number, sharePointLinkToken?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileUploadExistingFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sharePointLinkToken !== undefined && sharePointLinkToken !== null) {
            queryParameters = queryParameters.set('sharePointLinkToken', <any>sharePointLinkToken);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/existing-file`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a file for a lead to azure.
     * 
     * @param leadId the id of the lead
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileUploadFile(leadId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileUploadFile(leadId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileUploadFile(leadId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileUploadFile(leadId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileUploadFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/files`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a picture for a lead.
     * 
     * @param leadId the id of the lead
     * @param fileFolder the file folder. Default is 01_PreSale
     * @param tags picture tags. Comma separated
     * @param pictures 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileUploadPicture(leadId: number, fileFolder?: string, tags?: string, pictures?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileUploadPicture(leadId: number, fileFolder?: string, tags?: string, pictures?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileUploadPicture(leadId: number, fileFolder?: string, tags?: string, pictures?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileUploadPicture(leadId: number, fileFolder?: string, tags?: string, pictures?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileUploadPicture.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileFolder !== undefined && fileFolder !== null) {
            queryParameters = queryParameters.set('fileFolder', <any>fileFolder);
        }
        if (tags !== undefined && tags !== null) {
            queryParameters = queryParameters.set('tags', <any>tags);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (pictures) {
            formParams = canConsumeForm ? new FormData() : formParams;
            pictures.forEach((p: Blob, i: number) => { formParams = formParams.append('picture' + i, <any>p) || formParams; });
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/pictures`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param relativeServerPath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileUseSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'body', reportProgress?: boolean): Observable<TestSharepointFolderConnectionViewModel>;
    public leadFileUseSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TestSharepointFolderConnectionViewModel>>;
    public leadFileUseSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TestSharepointFolderConnectionViewModel>>;
    public leadFileUseSharePointFolderConnection(leadId: number, relativeServerPath?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileUseSharePointFolderConnection.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (relativeServerPath !== undefined && relativeServerPath !== null) {
            queryParameters = queryParameters.set('relativeServerPath', <any>relativeServerPath);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<TestSharepointFolderConnectionViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/use-folder-connection`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
