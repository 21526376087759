/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProjectChecklistStepViewModel } from './projectChecklistStepViewModel';
import { SopPhaseViewModel } from './sopPhaseViewModel';


export interface SopChecklistStepViewModel { 
    CheckedState?: SopChecklistStepViewModel.CheckedStateEnum;
    Id?: number;
    SopNumber?: number;
    Index?: string;
    Phase?: SopPhaseViewModel;
    Title?: string;
    ChecklistSteps?: Array<ProjectChecklistStepViewModel>;
}
export namespace SopChecklistStepViewModel {
    export type CheckedStateEnum = 'Unchecked' | 'PartiallyChecked' | 'Checked';
    export const CheckedStateEnum = {
        Unchecked: 'Unchecked' as CheckedStateEnum,
        PartiallyChecked: 'PartiallyChecked' as CheckedStateEnum,
        Checked: 'Checked' as CheckedStateEnum
    };
}
