/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MtCrmParameters } from '../model/mtCrmParameters';
import { MtLeadListViewModelPageResultViewModel } from '../model/mtLeadListViewModelPageResultViewModel';
import { MtLeadPatchtRequest } from '../model/mtLeadPatchtRequest';
import { MtLeadPostRequest } from '../model/mtLeadPostRequest';
import { MtLeadViewModel } from '../model/mtLeadViewModel';
import { MtSapRequest } from '../model/mtSapRequest';
import { VariantCompositeViewModel } from '../model/variantCompositeViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MeierToblerService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get a Meier Tobler crm lead
     * 
     * @param leadId the lead id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmGetCrmLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<MtLeadViewModel>;
    public meierToblerCrmGetCrmLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MtLeadViewModel>>;
    public meierToblerCrmGetCrmLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MtLeadViewModel>>;
    public meierToblerCrmGetCrmLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling meierToblerCrmGetCrmLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<MtLeadViewModel>(`${this.basePath}/api/pc/mt/crm/lead/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Filter a list with the Meier Tobler crm lead
     * 
     * @param Date only retrieve entries of this date (precision: one day)
     * @param CustomerName filter for entries with this customer name (includes first and last name in any order as well as company and additional company name)
     * @param MT_CrmVkNr only retrieve entries with a matching MT_CrmVkNr
     * @param PageIndex the index of the current page(starts at 1)
     * @param PageSize the amount of entries per page
     * @param SortField the field used to sort
     * @param SortOrder the direction of sorting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmGetCrmLeadList(Date?: Date, CustomerName?: string, MT_CrmVkNr?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'body', reportProgress?: boolean): Observable<MtLeadListViewModelPageResultViewModel>;
    public meierToblerCrmGetCrmLeadList(Date?: Date, CustomerName?: string, MT_CrmVkNr?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MtLeadListViewModelPageResultViewModel>>;
    public meierToblerCrmGetCrmLeadList(Date?: Date, CustomerName?: string, MT_CrmVkNr?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton', SortOrder?: 'None' | 'Asc' | 'Desc', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MtLeadListViewModelPageResultViewModel>>;
    public meierToblerCrmGetCrmLeadList(Date?: Date, CustomerName?: string, MT_CrmVkNr?: string, PageIndex?: number, PageSize?: number, SortField?: 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton', SortOrder?: 'None' | 'Asc' | 'Desc', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Date !== undefined && Date !== null) {
            queryParameters = queryParameters.set('Date', <any>Date.toISOString());
        }
        if (CustomerName !== undefined && CustomerName !== null) {
            queryParameters = queryParameters.set('CustomerName', <any>CustomerName);
        }
        if (MT_CrmVkNr !== undefined && MT_CrmVkNr !== null) {
            queryParameters = queryParameters.set('MT_CrmVkNr', <any>MT_CrmVkNr);
        }
        if (PageIndex !== undefined && PageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>PageIndex);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (SortField !== undefined && SortField !== null) {
            queryParameters = queryParameters.set('SortField', <any>SortField);
        }
        if (SortOrder !== undefined && SortOrder !== null) {
            queryParameters = queryParameters.set('SortOrder', <any>SortOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<MtLeadListViewModelPageResultViewModel>(`${this.basePath}/api/pc/mt/crm/lead/list`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update a crm lead parameters
     * 
     * @param leadId the lead id
     * @param body parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmPatchCrmParametersLead(leadId: number, body?: MtCrmParameters, observe?: 'body', reportProgress?: boolean): Observable<MtLeadViewModel>;
    public meierToblerCrmPatchCrmParametersLead(leadId: number, body?: MtCrmParameters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MtLeadViewModel>>;
    public meierToblerCrmPatchCrmParametersLead(leadId: number, body?: MtCrmParameters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MtLeadViewModel>>;
    public meierToblerCrmPatchCrmParametersLead(leadId: number, body?: MtCrmParameters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling meierToblerCrmPatchCrmParametersLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MtLeadViewModel>(`${this.basePath}/api/pc/mt/crm/lead/${encodeURIComponent(String(leadId))}/crm-parameters`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update a  crm lead
     * 
     * @param leadId the lead id
     * @param body the data for create a lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmPatchLead(leadId: number, body?: MtLeadPatchtRequest, observe?: 'body', reportProgress?: boolean): Observable<MtLeadViewModel>;
    public meierToblerCrmPatchLead(leadId: number, body?: MtLeadPatchtRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MtLeadViewModel>>;
    public meierToblerCrmPatchLead(leadId: number, body?: MtLeadPatchtRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MtLeadViewModel>>;
    public meierToblerCrmPatchLead(leadId: number, body?: MtLeadPatchtRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling meierToblerCrmPatchLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MtLeadViewModel>(`${this.basePath}/api/pc/mt/crm/lead/${encodeURIComponent(String(leadId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Forces the variant positions rebuild.
     * 
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmPostForceRebuild(variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public meierToblerCrmPostForceRebuild(variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public meierToblerCrmPostForceRebuild(variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public meierToblerCrmPostForceRebuild(variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling meierToblerCrmPostForceRebuild.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/mt/${encodeURIComponent(String(variantId))}/rebuild`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a crm lead
     * 
     * @param body the data for create a lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmPostLead(body?: MtLeadPostRequest, observe?: 'body', reportProgress?: boolean): Observable<MtLeadViewModel>;
    public meierToblerCrmPostLead(body?: MtLeadPostRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MtLeadViewModel>>;
    public meierToblerCrmPostLead(body?: MtLeadPostRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MtLeadViewModel>>;
    public meierToblerCrmPostLead(body?: MtLeadPostRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MtLeadViewModel>(`${this.basePath}/api/pc/mt/crm/lead`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends request to the MeierTobler SAP S/4 system.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmSendS4Data(body?: MtSapRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public meierToblerCrmSendS4Data(body?: MtSapRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public meierToblerCrmSendS4Data(body?: MtSapRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public meierToblerCrmSendS4Data(body?: MtSapRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/mt/crm/sendS4Data`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends request to the MeierTobler SAP system.
     * 
     * @param body The email request.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meierToblerCrmSendSapEmail(body?: MtSapRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public meierToblerCrmSendSapEmail(body?: MtSapRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public meierToblerCrmSendSapEmail(body?: MtSapRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public meierToblerCrmSendSapEmail(body?: MtSapRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/mt/crm/sendSapEmail`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
