import {
  AdditionalAddressTypeViewModel,
  ContactAddressContactListViewModel,
  ContactListViewModel,
  DirectoryCompanyViewModel,
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  LeadContactViewModel,
  LeadViewModel,
  PersonInfo,
  PostSingleContactAddressRequest,
  PutSingleContactAddressRequest,
} from '../apis/advis';
import { isNullOrUndefined } from './isNullOrUndefined';
import { ISendTo } from '../../lead-detail/send-email-dialog/send-email-dialog.component';
import cloneDeep from 'lodash/cloneDeep';
import { CommonUtil } from './common.util';
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import LanguageEnum = LeadViewModel.LanguageEnum;
import PreferredCommunicationTypeEnum = DirectoryPersonViewModel.PreferredCommunicationTypeEnum;

export class ContactViewModelUtil {
  public static createNameForPersonOrCompanyListVm(
    personListVm: ContactAddressContactListViewModel
  ): string {
    if (personListVm.Title === TitleEnum.Company) {
      if (personListVm.CompanyName === '') {
        return (
          (!isNullOrUndefined(personListVm.FirstName) ? personListVm.FirstName : '') +
          (!isNullOrUndefined(personListVm.LastName) ? ' ' + personListVm.LastName : '')
        );
      }
      return !isNullOrUndefined(personListVm.CompanyName) ? personListVm.CompanyName : '';
    } else {
      if (personListVm.FirstName === '' && personListVm.LastName === '') {
        return !isNullOrUndefined(personListVm.CompanyName) ? personListVm.CompanyName : '';
      }
      return (
        (!isNullOrUndefined(personListVm.FirstName) ? personListVm.FirstName : '') +
        (!isNullOrUndefined(personListVm.LastName) ? ' ' + personListVm.LastName : '')
      );
    }
  }

  public static createZipCityForPersonListVm(
    personListVm: ContactAddressContactListViewModel
  ): string {
    return (
      (!isNullOrUndefined(personListVm.ZipCode) ? personListVm.ZipCode : '') +
      (!isNullOrUndefined(personListVm.City) ? ' ' + personListVm.City : '')
    );
  }

  public static createNameOrCompanyNameContactAddressForHeader(
    personListModel: LeadContactViewModel
  ): string {
    if (
      !isNullOrUndefined(personListModel.Company?.Name) &&
      personListModel.Company.Name.length > 0
    ) {
      return !isNullOrUndefined(personListModel.Company.Name) ? personListModel.Company.Name : '';
    }
    return (
      (!isNullOrUndefined(personListModel.Person?.FirstName)
        ? personListModel.Person?.FirstName
        : '') +
      (!isNullOrUndefined(personListModel.Person?.LastName)
        ? ' ' + personListModel.Person?.LastName
        : '')
    );
  }

  public static createNameOrCompanyNameContactAddress(
    personListModel: LeadContactViewModel
  ): string {
    if (personListModel.Person?.FirstName === '' && personListModel.Person?.LastName === '') {
      return !isNullOrUndefined(personListModel.Company?.Name) ? personListModel.Company?.Name : '';
    }
    return (
      (!isNullOrUndefined(personListModel.Person?.FirstName)
        ? personListModel.Person?.FirstName
        : '') +
      (!isNullOrUndefined(personListModel.Person?.LastName)
        ? ' ' + personListModel.Person?.LastName
        : '')
    );
  }

  public static createObjectAddress(objectAddress: ContactListViewModel): string {
    objectAddress = objectAddress ? objectAddress : {};
    return (
      objectAddress.DisplayName +
      ', ' +
      objectAddress.Street +
      ', ' +
      objectAddress.ZipCode +
      ' ' +
      objectAddress.City
    );
  }

  public static createEmptySingleContact(): PostSingleContactAddressRequest {
    return {
      Email: '',
      Language: LanguageEnum.DE,
      PreferredCommunicationType: PreferredCommunicationTypeEnum.Email,
      AdditionalPhones: [],
      AdditionalEmails: [],
      Title: DirectoryPersonViewModel.TitleEnum.None,
      FirstName: '',
      LastName: '',
      ThirdPartyCustomerNumber: '',
      Street: '',
      ZipCode: '',
      City: '',
    };
  }

  public static createEmptyPerson(): DirectoryPersonViewModel {
    return {
      Title: DirectoryPersonViewModel.TitleEnum.None,
      FirstName: '',
      LastName: '',
      ThirdPartyCustomerNumber: '',
      Notes: '',
      Street: '',
      ZipCode: '',
      City: '',
      Emails: [{ EmailType: 'Primary', Email: '' }],
      Phones: [{ PhoneType: 'Primary', PhoneNumber: '' }],
      PreferredCommunicationType: PreferredCommunicationTypeEnum.Email,
    };
  }

  public static createEmptyPersonContact(
    addressType: LeadContactViewModel.AddressTypeEnum,
    isSingleContact: boolean
  ): LeadContactViewModel {
    return {
      AddressType: addressType,
      IsSingleContact: isSingleContact,
      DataState: LeadContactViewModel.DataStateEnum.Unqualified,
      Person: {
        Title: DirectoryPersonViewModel.TitleEnum.None,
        FirstName: '',
        LastName: '',
        ThirdPartyCustomerNumber: '',
        Notes: '',
        Street: '',
        ZipCode: '',
        City: '',
        Emails: [{ EmailType: 'Primary', Email: '' }],
        Phones: [{ PhoneType: 'Primary', PhoneNumber: '' }],
        PreferredCommunicationType: PreferredCommunicationTypeEnum.Email,
      },
    };
  }

  public static createEmptyCompany(): DirectoryCompanyViewModel {
    return {
      Name: '',
      AdditionalName: '',
      Notes: '',
      Street: '',
      ZipCode: '',
      City: '',
      Emails: [{ EmailType: 'Primary', Email: '' }],
      Phones: [{ PhoneType: 'Primary', PhoneNumber: '' }],
    };
  }

  public static createEmptyContact(
    addressType: LeadContactViewModel.AddressTypeEnum,
    isSingleContact: boolean
  ): LeadContactViewModel {
    return {
      Id: 0,
      AddressType: addressType,
      IsSingleContact: isSingleContact,
      Company: ContactViewModelUtil.createEmptyCompany(),
      Person: ContactViewModelUtil.createEmptyPerson(),
      DataState: LeadContactViewModel.DataStateEnum.Unqualified,
    };
  }

  public static createEmptyContactAddressbook(
    addressType?: LeadContactViewModel.AddressTypeEnum
  ): LeadContactViewModel {
    return {
      Id: 0,
      AddressType: addressType,
      IsSingleContact: false,
      Company: ContactViewModelUtil.createEmptyCompany(),
      Person: ContactViewModelUtil.createEmptyPerson(),
      DataState: LeadContactViewModel.DataStateEnum.Unqualified,
    };
  }

  public static addAdditionalAddress(
    list: ISendTo[],
    contact: LeadContactViewModel,
    translatedAddressType: string
  ): void {
    const emails: DirectoryEmailViewModel[] = ContactViewModelUtil.getAllEmails(contact);
    if (!isNullOrUndefined(contact) && emails.length > 0) {
      const personInfo: PersonInfo = {
        Title: contact.Person?.Title,
        FirstName: contact.Person?.FirstName,
        LastName: contact.Person?.LastName,
        AdditionalName: contact.Person?.AdditionalName,
        CompanyName: contact.Company?.Name,
        AdditionalCompanyName: contact.Company?.AdditionalName,
        Street: contact.MainContact.Street,
        ZipCode: contact.MainContact.ZipCode,
        City: contact.MainContact.City,
        PhoneNumber: ContactViewModelUtil.getMainPhone(contact).PhoneNumber,
        Email: ContactViewModelUtil.getMainEmail(contact).Email,
      };

      emails.forEach((a: DirectoryEmailViewModel) => {
        personInfo.Email = a.Email;
        list.push({
          key: list.length + 1,
          email: a.Email,
          display: translatedAddressType + ' (' + a.Email + ')',
          receiverInfo: personInfo,
        });
      });
    }
  }

  public static getTranslatedAddressType(
    addressType: string,
    additionalAddressTypes: AdditionalAddressTypeViewModel[]
  ): string {
    return additionalAddressTypes.find((x: AdditionalAddressTypeViewModel) => x.Key === addressType)
      .Translation;
  }

  public static mapLeadContactToContactViewModel(
    data: LeadContactViewModel
  ): PutSingleContactAddressRequest {
    return {
      AdditionalCompanyName: data.Company?.AdditionalName ?? '',
      AdditionalEmails: data.Person?.AdditionalEmails ?? [],
      AdditionalPhones: data.Person?.AdditionalPhones ?? [],
      City: data.Person?.City ?? '',
      CompanyName: data.Company?.Name ?? '',
      Email: data.Person?.PrimaryEmail.Email ?? '',
      FirstName: data.Person?.FirstName ?? '',
      Language: data.Person?.Language ?? LanguageEnum.DE,
      PreferredCommunicationType:
        data.Person?.PreferredCommunicationType ?? PreferredCommunicationTypeEnum.Email,
      LastName: data.Person?.LastName ?? '',
      PhoneNumber: data.Person?.PrimaryPhone.PhoneNumber ?? '',
      Street: data.Person?.Street ?? '',
      ThirdPartyCustomerNumber: data.Person?.ThirdPartyCustomerNumber ?? '',
      Title: data.Person?.Title ?? TitleEnum.None,
      UpdateDebitorAddress: false,
      UpdateObjectAddress: false,
      ZipCode: data.Person?.ZipCode ?? '',
      CareOfName: data.Person?.CareOfName ?? '',
      PostOfficeBox: data.Person?.PostOfficeBox ?? '',
    };
  }

  public static mapLeadContactToDirectoryContact(
    data: LeadContactViewModel
  ): DirectoryContactViewModel {
    return {
      Id: data.ContactId,
      Person: data.Person,
      Company: data.Company,
      ContactType: data.AddressType,
      DataState: data.DataState,
      IsSingleContact: data.IsSingleContact,
    } as DirectoryContactViewModel;
  }

  public static deepTrimStrings(
    person: PostSingleContactAddressRequest
  ): PostSingleContactAddressRequest {
    let clonedPerson: PostSingleContactAddressRequest = cloneDeep(person);

    clonedPerson = CommonUtil.trimAllStrings(clonedPerson);

    return clonedPerson;
  }

  public static mapLangStr(langStr: string): LanguageEnum {
    if (langStr) {
      switch (langStr.toLowerCase()) {
        case 'de':
          return LanguageEnum.DE;
        case 'fr':
          return LanguageEnum.FR;
        case 'it':
          return LanguageEnum.IT;
        default:
          // nothing
          break;
      }
    }
    const defaultLanguage: LanguageEnum = LanguageEnum.DE;
    console.warn(
      `No language mapping found for langStr "${langStr}", use default language "${defaultLanguage}"`
    );
    return defaultLanguage;
  }

  private static getMainPhone(contact: LeadContactViewModel): DirectoryPhoneViewModel {
    const phones: DirectoryPhoneViewModel[] = contact.MainContact.Phones;

    if (phones.length === 0) {
      return { PhoneType: 'Primary', PhoneNumber: '' } as DirectoryPhoneViewModel;
    }

    if (
      phones.findIndex(
        (x: DirectoryPhoneViewModel) =>
          x.PhoneType === DirectoryPhoneViewModel.PhoneTypeEnum.Primary
      ) > 0
    ) {
      return phones.find(
        (x: DirectoryPhoneViewModel) =>
          x.PhoneType === DirectoryPhoneViewModel.PhoneTypeEnum.Primary
      );
    } else if (
      phones.findIndex(
        (x: DirectoryPhoneViewModel) => x.PhoneType === DirectoryPhoneViewModel.PhoneTypeEnum.Direct
      ) > 0
    ) {
      return phones.find(
        (x: DirectoryPhoneViewModel) => x.PhoneType === DirectoryPhoneViewModel.PhoneTypeEnum.Direct
      );
    } else {
      return phones[0];
    }
  }

  private static getMainEmail(contact: LeadContactViewModel): DirectoryEmailViewModel {
    const emails: DirectoryEmailViewModel[] = contact.MainContact.Emails;

    if (emails.length === 0) {
      return { EmailType: 'Primary', Email: '' } as DirectoryEmailViewModel;
    }

    if (
      emails.findIndex(
        (x: DirectoryEmailViewModel) => x.Email === DirectoryEmailViewModel.EmailTypeEnum.Primary
      ) > 0
    ) {
      return emails.find(
        (x: DirectoryEmailViewModel) => x.Email === DirectoryEmailViewModel.EmailTypeEnum.Primary
      );
    } else if (
      emails.findIndex(
        (x: DirectoryEmailViewModel) => x.Email === DirectoryEmailViewModel.EmailTypeEnum.Mail
      ) > 0
    ) {
      return emails.find(
        (x: DirectoryEmailViewModel) => x.Email === DirectoryEmailViewModel.EmailTypeEnum.Mail
      );
    } else {
      return emails[0];
    }
  }

  private static getAllEmails(contact: LeadContactViewModel): DirectoryEmailViewModel[] {
    const emails: DirectoryEmailViewModel[] = [];

    if (contact.Person && contact.Person?.Emails) {
      emails.push(...contact.Person?.Emails);
    }

    if (contact.Company && contact.Company?.Emails) {
      emails.push(...contact.Company?.Emails);
    }

    return emails;
  }
}
