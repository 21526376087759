import { Injectable } from '@angular/core';
import { UploadPictureAction } from '../states/lead-document/lead-document.action';
import { Store } from '@ngrx/store';
import * as RootReducer from '../states';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import moment, { Moment } from 'moment';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModalService } from './loading-modal.service';

@Injectable({
  providedIn: 'root',
})
export class PictureUploadService {
  cancelPictureUpload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateService,
    private store: Store<RootReducer.IState>,
    private dialogService: DialogService,
    private deviceService: DeviceDetectorService,
    private loadingModalService: LoadingModalService
  ) {}

  fileUpload(
    filesToUpload: FileList | File | File[],
    accept: string,
    leadId: number,
    folder: string,
    tags: string[] = []
  ): void {
    let files: File[];
    if (filesToUpload instanceof FileList || filesToUpload instanceof File) {
      files = this.mapToFilesArray(filesToUpload);
    } else {
      files = filesToUpload;
    }
    const filesWithNormalizedNames: File[] = this.normalizeFileNames(files);
    const orderedFiles: File[] = filesWithNormalizedNames.sort(
      (a: File, b: File) => b.lastModified - a.lastModified
    );
    this.uploadPictures(orderedFiles, accept, leadId, folder, tags);
  }

  public mapToFilesArray(filesToUpload: FileList | File): File[] {
    let files: File[];
    if (filesToUpload instanceof FileList) {
      files = [];
      const filesLength: number = filesToUpload.length;
      for (let index: number = 0; index < filesLength; index++) {
        files.push(filesToUpload[index]);
      }
    } else {
      files = [filesToUpload];
    }

    return files;
  }

  public generateFileName(extension: string, index: number): string {
    const date: Moment = moment();
    return `${date.format('YYYYMMDDHHmmssms')}${index === 0 ? '' : '(' + index + ')'}.${extension}`;
  }

  public generateThumbnailName(name: string): string {
    const nameParts: string[] = name.split('.');
    const extension: string = nameParts.pop();

    return `${nameParts.join('')}__thumbnail__.${extension}`;
  }

  public generateNewFileName(file: File, index: number): string {
    const date: Moment = moment(file.lastModified);
    const extension: string = file.name.split('.').pop();
    return `${date.format('YYYYMMDDHHmmssms')}${index === 0 ? '' : '(' + index + ')'}.${extension}`;
  }

  public uploadPictures(
    files: File[],
    accept: string,
    leadId: number,
    folder: string,
    tags: string[] = []
  ): void {
    this.cancelPictureUpload.next(false);

    let filesToUpload: File[] = files.filter((file: File) => {
      if (!file) {
        return false;
      }

      const name: string = file.name.toLowerCase();
      return !!accept.split(',').find((ext: string) => name.endsWith(ext));
    });

    if (filesToUpload.length !== files.length) {
      this.dialogService.openAlert(
        this.translate.instant('LEAD_DETAIL.SUPPORTED_IMAGE_EXTENSIONS')
      );
      this.loadingModalService.closeModalAll();
      return;
    }

    this.dispatchUploadAction(filesToUpload, leadId, folder, tags);
  }

  private normalizeFileNames(files: File[]): File[] {
    const isDesktop: boolean = this.deviceService.isDesktop();
    if (isDesktop) {
      return files;
    }

    return files.map(
      (file: File, index: number) =>
        new File([file], this.generateNewFileName(file, index), {
          type: file.type,
        })
    );
  }

  private dispatchUploadAction(
    files: File[],
    leadId: number,
    folder: string,
    tags: string[] = []
  ): void {
    this.store.dispatch(
      new UploadPictureAction({
        leadId: leadId,
        files: files,
        folder: folder,
        tags: tags,
      })
    );

    this.cancelPictureUpload.next(true);
  }
}
