import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseContactFormDirective } from '../../../lead/common/base/BaseContactFormDirective';
import {
  DirectoryPersonViewModel,
  LeadContactViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { EnumService, IEnumData } from '../../../shared/services/enum.service';
import { AddressBookBackendService } from '../../../shared/services/address-book-backend.service';
import { NotificationService } from '../../../shared/services/notification.service';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { PersonContactFormComponent } from '../../lead-address/address-book-contact/person-contact/person-contact-form/person-contact-form.component';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../../shared/utils/exter-managed-address-type.util';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';

export interface IEditPersonData {
  dialogTitle?: string;
  isNew?: boolean;
  contactId: number;
  person: DirectoryPersonViewModel;
  addressType: LeadContactViewModel.AddressTypeEnum;
  titleItems: IEnumData[];
  mandatorId: number;
}

export interface IEditCompanyPersonData {
  dialogTitle?: string;
  isNew?: boolean;
  contactId: number;
  person: DirectoryPersonViewModel;
  addressType: LeadContactViewModel.AddressTypeEnum;
  titleItems: IEnumData[];
  mandatorId: number;
  companyId: number;
}

@Component({
  selector: 'pc-edit-person-contact-dialog',
  templateUrl: './edit-person-contact-dialog.component.html',
  styleUrls: ['./edit-person-contact-dialog.component.scss'],
})
export class EditPersonContactDialogComponent
  extends BaseContactFormDirective
  implements OnInit, OnDestroy
{
  @ViewChild('personForm', { static: true })
  public personContactFormComponent: PersonContactFormComponent;
  public dialogTitle: string;

  public person: DirectoryPersonViewModel;
  public addressType: AddressTypeEnum;
  public modalName: string = EditPersonContactDialogComponent.name;
  public isNew: boolean;
  public sharedAddressBookMandatorIds: number[];

  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  constructor(
    private dialogRef: DialogRef,
    private addressBookService: AddressBookBackendService,
    private store: Store<RootReducer.IState>,
    @Inject(DIALOG_DATA) private data: IEditPersonData | IEditCompanyPersonData,
    dialogService: DialogService,
    loadingModalService: LoadingModalService,
    notification: NotificationService,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(sanitizer, translate, dialogService, notification, loadingModalService, enumService);
    this.person = this.data.person;
    this.addressType = this.data.addressType;
    this.titleItems = this.data.titleItems;
    this.isNew = this.data.isNew;

    this.dialogTitle = this.data.dialogTitle
      ? this.data.dialogTitle
      : this.person.Id && this.person.Id > 0
      ? this.translate.instant('ADDRESS.EDIT.EDIT_PERSON')
      : this.translate.instant('ADDRESS.EDIT.ADD_PERSON');

    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select<MasterDataViewModel>(RootReducer.getGlobalMasterData)
        .subscribe(masterData => {
          this.phoneTypes = masterData.AdditionalPhoneTypes;
          this.emailTypes = masterData.AdditionalEmailTypes;
          this.titleItems = this.data.titleItems;
          this.sharedAddressBookMandatorIds = masterData.Mandators.find(
            x => x.Id === this.data.mandatorId
          ).SharedAddressBookMandatorIds;
        })
    );
    super.ngOnInit();
  }

  public onCloseClick(): void {
    this.dialogRef.close(undefined);
  }

  public saveDisabled(): boolean {
    return (
      this.person.FirstName.length === 0 ||
      this.person.LastName.length === 0 ||
      !this.personContactFormComponent?.ngForm?.valid
    );
  }

  protected saveEntry(): void {
    const isExternalManaged = ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
      this.addressType,
      this.allExternalManagedAddressConfig
    );
    this.loadingModalService.openModal(this.modalName);
    if (this.data.isNew) {
      if ((<IEditCompanyPersonData>this.data).companyId !== undefined) {
        this.subscriptions.add(
          this.subscriptions.add(
            this.addressBookService
              .addPersonToCompany(
                this.person,
                (<IEditCompanyPersonData>this.data).companyId,
                true,
                this.sharedAddressBookMandatorIds
              )
              .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
          )
        );
      } else if (!isExternalManaged) {
        this.subscriptions.add(
          this.subscriptions.add(
            this.addressBookService
              .addPerson(
                this.data.mandatorId,
                this.sharedAddressBookMandatorIds,
                this.person,
                false,
                this.addressType
              )
              .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
          )
        );
      } else {
        this.subscriptions.add(
          this.subscriptions.add(
            this.addressBookService
              .addPersonAsExternalManaged(
                this.data.mandatorId,
                this.sharedAddressBookMandatorIds,
                this.person,
                false,
                this.addressType
              )
              .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
          )
        );
      }
    } else {
      this.subscriptions.add(
        this.subscriptions.add(
          this.addressBookService
            .updatePerson(this.person.Id, this.person, this.data.contactId)
            .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
        )
      );
    }
  }
}
