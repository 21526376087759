import { IViewSettingBase } from './view-setting-base.model';

export class LeadOverviewTasksPanelVsModel {
  public panelExpanded: boolean = true;

  clone(): LeadOverviewTasksPanelVsModel {
    const panel: LeadOverviewTasksPanelVsModel = new LeadOverviewTasksPanelVsModel();
    panel.panelExpanded = this.panelExpanded;

    return panel;
  }
}

export class LeadOverviewTasksVsModel implements IViewSettingBase<LeadOverviewTasksVsModel> {
  public panel: LeadOverviewTasksPanelVsModel = new LeadOverviewTasksPanelVsModel();
  public tableSort: any[] = [];

  clone(): LeadOverviewTasksVsModel {
    const model: LeadOverviewTasksVsModel = new LeadOverviewTasksVsModel();
    model.panel = this.panel.clone();
    model.tableSort = this.tableSort;

    return model;
  }
}
