/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AppointmentViewModel } from './appointmentViewModel';
import { LeadContactViewModel } from './leadContactViewModel';
import { LeadDelegateChangeLogViewModel } from './leadDelegateChangeLogViewModel';
import { LeadExecutingOrganisationUnitChangeLogViewModel } from './leadExecutingOrganisationUnitChangeLogViewModel';
import { LeadGenericChangeLogViewModel } from './leadGenericChangeLogViewModel';
import { LeadOwnerChangeLogViewModel } from './leadOwnerChangeLogViewModel';
import { LeadProjectViewModel } from './leadProjectViewModel';
import { LeadSapNumberChangeLogViewModel } from './leadSapNumberChangeLogViewModel';
import { LeadStageHistoryViewModel } from './leadStageHistoryViewModel';
import { TaskViewModel } from './taskViewModel';


export interface LeadViewModel { 
    Id?: number;
    PublicId?: string;
    ContactAddress?: LeadContactViewModel;
    LeadCreatedFromId?: number;
    Language?: LeadViewModel.LanguageEnum;
    Phase?: LeadViewModel.PhaseEnum;
    Stage?: string;
    PreviousStage?: string;
    Status?: string;
    PreviousStatus?: string;
    PreviousOwnerId?: string;
    ContactDesired?: boolean;
    Date?: Date;
    ModiDate?: Date;
    Zugangsart?: string;
    OwnerId?: string;
    OwnerFirstName?: string;
    OwnerLastName?: string;
    DelegateId?: string;
    ResponsibleId?: string;
    MandantId?: number;
    OrganizationalUnitId?: number;
    Tasks?: Array<TaskViewModel>;
    Appointments?: Array<AppointmentViewModel>;
    OwnerChangeLogs?: Array<LeadOwnerChangeLogViewModel>;
    DelegateChangeLogs?: Array<LeadDelegateChangeLogViewModel>;
    SapNumberChangeLogs?: Array<LeadSapNumberChangeLogViewModel>;
    ExecutingOrganisationUnitChangeLogs?: Array<LeadExecutingOrganisationUnitChangeLogViewModel>;
    GenericChangeLogs?: Array<LeadGenericChangeLogViewModel>;
    ObjectAddress?: LeadContactViewModel;
    DebitorAddress?: LeadContactViewModel;
    AdditionalAddresses?: Array<LeadContactViewModel>;
    LeadStageHistories?: Array<LeadStageHistoryViewModel>;
    PlannedImplementationTime?: LeadViewModel.PlannedImplementationTimeEnum;
    IsCallbackTimeMorning?: boolean;
    IsCallbackTimeAfternoon?: boolean;
    IsCallbackTimeEvening?: boolean;
    IsCallbackTimeWeekend?: boolean;
    Rating?: number;
    Origin?: string;
    Tag?: string;
    Tracers?: string;
    TradeTypeCode?: string;
    ProviderLeadId?: string;
    ProviderTransactionId?: string;
    IsLocked?: boolean;
    SharePointLeadFolderUrl?: string;
    SharePointSiteUrl?: string;
    Project?: LeadProjectViewModel;
    GroupId?: string;
    LastSynchTimeStamp?: Date;
    SynchFailed?: boolean;
    IsProjectTransferDisabled?: boolean;
    SharepointUniqueFolderId?: string;
    ChecklistStepCompletedNotificationEnabled?: boolean;
    ComplexityType?: string;
}
export namespace LeadViewModel {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
    export type PhaseEnum = 'Nbo' | 'Bo' | 'Project' | 'Break';
    export const PhaseEnum = {
        Nbo: 'Nbo' as PhaseEnum,
        Bo: 'Bo' as PhaseEnum,
        Project: 'Project' as PhaseEnum,
        Break: 'Break' as PhaseEnum
    };
    export type PlannedImplementationTimeEnum = 'AsSoonAsPossible' | 'FourToTwelveMonths' | 'OneToTwoYears' | 'Unknown';
    export const PlannedImplementationTimeEnum = {
        AsSoonAsPossible: 'AsSoonAsPossible' as PlannedImplementationTimeEnum,
        FourToTwelveMonths: 'FourToTwelveMonths' as PlannedImplementationTimeEnum,
        OneToTwoYears: 'OneToTwoYears' as PlannedImplementationTimeEnum,
        Unknown: 'Unknown' as PlannedImplementationTimeEnum
    };
}
