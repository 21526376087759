import { Action } from '@ngrx/store';
import {
  AddPositionGroupRequest,
  AddRebateRequest,
  AddRegularPositionRequest,
  AddSpecialPositionRequest,
  CreateEmobilityVariantRequest,
  CreateHeatingVariantRequest,
  CreatePvVariantRequest,
  CreateSimpleTradeVariantRequest,
  PatchEmobilityVariantRequest,
  PatchHeatingVariantRequest,
  PatchPositionGroupRequest,
  PatchPositionRequest,
  PatchPvVariantRequest,
  PatchSimpleTradeVariantRequest,
  PatchVariantDynamicPropertyRequest,
  PatchVariantRequest,
  PutPositionOrderRequest,
  PvWizardViewModel,
  VariantCompositeViewModel,
  VariantPrecalculationViewModel,
  PastePositionsAndGroupsRequest,
  PatchVariantPositionRequest,
} from '../../apis/advis';
import { TradeTypeEnum } from '../../utils/trade-type.util';
import { IAccountAssignmentData } from '../../../common-components/account-assignment-list/account-assignment-list.component';

export const RESET_VARIANT: string = '[Variant] Reset';

export const CREATE_PRICE_TABLE_VARIANT: string = '[Variant] Create price table variant';
export const CREATE_PRICE_TABLE_VARIANT_SUCCESS: string =
  '[Variant] Create price table variant success';
export const CREATE_PRICE_TABLE_VARIANT_FAILED: string =
  '[Variant] Create price table variant failed';

export const CREATE_VARIANT: string = '[Variant] Create variant';
export const CREATE_VARIANT_SUCCESS: string = '[Variant] Create variant success';
export const CREATE_VARIANT_FAILED: string = '[Variant] Create variant failed';

export const SAVE_VARIANT: string = '[Variant] Save variant';
export const SAVE_VARIANT_SUCCESS: string = '[Variant] Save variant success';
export const SAVE_VARIANT_FAILED: string = '[Variant] Save variant failed';

export const SET_SELECTED_VARIANT: string = '[Variant] Set selected variant';

export const GET_VARIANT: string = '[Variant] Get variant';
export const GET_VARIANT_SUCCESS: string = '[Variant] Get variant success';
export const GET_VARIANT_FAILED: string = '[Variant] Get variant failed';

export const PATCH_VARIANT: string = '[Variant] Patch Variant';
export const PATCH_VARIANT_SUCCESS: string = '[Variant] Patch Variant success';
export const PATCH_VARIANT_FAILED: string = '[Variant] Patch Variant failed';

export const PATCH_TRATE_SPECIFIC_VARIANT_AND_DYNAMIC_PROPERTY: string =
  '[Variant] Patch trade specific Variant and dynamic properties';

export const PATCH_PV_NUMBER_OF_MODULES_VARIANT: string =
  '[Variant] Patch PV number of modules Variant';
export const PATCH_PV_SPECIFIC_VARIANT: string = '[Variant] Patch PV specific Variant';
export const PATCH_PV_SPECIFIC_VARIANT_SUCCESS: string =
  '[Variant] Patch PV specific Variant success';
export const PATCH_PV_SPECIFIC_VARIANT_FAILED: string =
  '[Variant] Patch PV specific Variant failed';

export const PATCH_PV_MODULE_WATT_PEAK_VARIANT: string = '[Variant] Patch PV module KwP Variant';

export const PATCH_HT_SPECIFIC_VARIANT: string = '[Variant] Patch HT specific Variant';
export const PATCH_HT_SPECIFIC_VARIANT_SUCCESS: string =
  '[Variant] Patch HT specific Variant success';
export const PATCH_HT_SPECIFIC_VARIANT_FAILED: string =
  '[Variant] Patch HT specific Variant failed';

export const PATCH_ST_SPECIFIC_VARIANT: string = '[Variant] Patch ST specific Variant';
export const PATCH_ST_SPECIFIC_VARIANT_SUCCESS: string =
  '[Variant] Patch ST specific Variant success';
export const PATCH_ST_SPECIFIC_VARIANT_FAILED: string =
  '[Variant] Patch ST specific Variant failed';

export const SELECT_POSITION_GROUP_FAILED: string = '[Variant] Select all position in a group';
export const SELECT_POSITION_GROUP_SUCCESS: string =
  '[Variant] Select all position in a group success';
export const SELECT_POSITION_GROUP: string = '[Variant] Select all position in a group failed';

export const DESELECT_POSITION_GROUP_FAILED: string = '[Variant] Deselect all position in a group';
export const DESELECT_POSITION_GROUP_SUCCESS: string =
  '[Variant] Deselect all position in a group success';
export const DESELECT_POSITION_GROUP: string = '[Variant] Deselect all position in a group failed';

export const PATCH_EM_SPECIFIC_VARIANT: string = '[Variant] Patch EM specific Variant';
export const PATCH_EM_SPECIFIC_VARIANT_SUCCESS: string =
  '[Variant] Patch EM specific Variant success';
export const PATCH_EM_SPECIFIC_VARIANT_FAILED: string =
  '[Variant] Patch EM specific Variant failed';

export const ADD_REGULAR_POSITIONS: string = '[Variant] Add regular positions';
export const ADD_REGULAR_POSITIONS_SUCCESS: string = '[Variant] Add regular positions success';
export const ADD_REGULAR_POSITIONS_FAILED: string = '[Variant] Add regular positions failed';

export const ADD_SPECIAL_POSITIONS: string = '[Variant] Add special positions';
export const ADD_SPECIAL_POSITIONS_SUCCESS: string = '[Variant] Add special positions success';
export const ADD_SPECIAL_POSITIONS_FAILED: string = '[Variant] Add special positions failed';

export const ADD_GROUP_POSITIONS: string = '[Variant] Add group positions';
export const ADD_GROUP_POSITIONS_SUCCESS: string = '[Variant] Add group positions success';
export const ADD_GROUP_POSITIONS_FAILED: string = '[Variant] Add group positions failed';

export const PASTE_GROUPS_AND_POSITIONS: string = '[Variant] Add groups and positions';
export const PASTE_GROUPS_AND_POSITIONS_SUCCESS: string =
  '[Variant] Add groups and positions success';
export const PASTE_GROUPS_AND_POSITIONS_FAILED: string =
  '[Variant] Add groups and positions failed';

export const PUT_POSITION_ORDER_AND_GROUP: string = '[Variant] Put position order and group';
export const PUT_POSITION_ORDER_AND_GROUP_SUCCESS: string =
  '[Variant] Put position order and group success';
export const PUT_POSITION_ORDER_AND_GROUP_FAILED: string =
  '[Variant] Put position order and group failed';

export const PATCH_POSITION_GROUP: string = '[Variant] Patch position group';
export const PATCH_POSITION_GROUP_SUCCESS: string = '[Variant] Patch position group success';
export const PATCH_POSITION_GROUP_FAILED: string = '[Variant] Patch position group failed';

export const DELETE_BOM_ITEMS: string = '[Variant] Delete group position';
export const DELETE_BOM_ITEMS_SUCCESS: string = '[Variant] Delete group position success';
export const DELETE_BOM_ITEMS_FAILED: string = '[Variant] Delete group position failed';

export const REPLACE_SUCCESSOR_POSITION: string = '[Variant] Replace successor position';
export const REPLACE_SUCCESSOR_POSITION_SUCCESS: string =
  '[Variant] Replace successor position success';
export const REPLACE_SUCCESSOR_POSITION_FAILED: string =
  '[Variant] Replace successor position failed';

export const REPLACE_ALL_SUCCESSOR_POSITIONS: string = '[Variant] Replace all successor positions';
export const REPLACE_ALL_SUCCESSOR_POSITIONS_SUCCESS: string =
  '[Variant] Replace all successor positions success';
export const REPLACE_ALL_SUCCESSOR_POSITIONS_FAILED: string =
  '[Variant] Replace all successor positions failed';

export const UPDATE_PRICES: string = '[Variant] Update prices';
export const UPDATE_PRICES_SUCCESS: string = '[Variant] Update prices success';
export const UPDATE_PRICES_FAILED: string = '[Variant] Update prices failed';

export const REBUILD_VARIANT: string = '[Variant] Rebuild variant';
export const REBUILD_VARIANT_MEIER_TOBLER: string = '[Variant] Rebuild variant Meier Tobler';
export const REBUILD_VARIANT_SUCCESS: string = '[Variant] Rebuild variant success';
export const REBUILD_VARIANT_FAILED: string = '[Variant] Rebuild variant failed';

export const ADD_VARIANT_REBATE: string = '[Variant] Add variant rebate';
export const ADD_VARIANT_REBATE_SUCCESS: string = '[Variant] Add variant rebate success';
export const ADD_VARIANT_REBATE_FAILED: string = '[Variant] Add variant rebate failed';

export const UPDATE_VARIANT_REBATE: string = '[Variant] Update variant rebate';
export const UPDATE_VARIANT_REBATE_SUCCESS: string = '[Variant] Update variant rebate success';
export const UPDATE_VARIANT_REBATE_FAILED: string = '[Variant] Update variant rebate failed';

export const REMOVE_VARIANT_REBATE: string = '[Variant] Remove variant rebate';
export const REMOVE_VARIANT_REBATE_SUCCESS: string = '[Variant] Remove variant rebate success';
export const REMOVE_VARIANT_REBATE_FAILED: string = '[Variant] Remove variant rebate failed';

export const PATCH_REGULAR_OR_SPECIAL_POSITION: string = '[Variant] Patch group';
export const PATCH_REGULAR_OR_SPECIAL_POSITIONS: string = '[Variant] Patch positions';
export const PATCH_REGULAR_OR_SPECIAL_POSITION_SUCCESS: string = '[Variant] Patch group success';
export const PATCH_REGULAR_OR_SPECIAL_VARIANT_POSITION_SUCCESS: string =
  '[Variant] Patch Variant group success';
export const PATCH_REGULAR_OR_SPECIAL_POSITION_FAILED: string = '[Variant] Patch group failure';

export const PATCH_DYNAMIC_PROPERTY: string = '[Variant] Patch Dynamic Property';
export const PATCH_DYNAMIC_PROPERTY_SUCCESS: string = '[Variant] Patch Dynamic Property success';
export const PATCH_DYNAMIC_PROPERTY_FAILED: string = '[Variant] Patch Dynamic Property failure';

export const DELETE_DYNAMIC_PROPERTY: string = '[Variant] Delete Dynamic Property';
export const DELETE_DYNAMIC_PROPERTY_SUCCESS: string = '[Variant] Delete Dynamic Property success';
export const DELETE_DYNAMIC_PROPERTY_FAILED: string = '[Variant] Delete Dynamic Property failure';

export const UPDATE_OBJECT_DATA: string = '[Variant] Update object data lock';
export const UPDATE_OBJECT_DATA_SUCCESS: string = '[Variant] Update object data lock success';
export const UPDATE_OBJECT_DATA_FAILED: string = '[Variant] Update object data lock failure';

export const GET_VARIANT_PRECALCULATION: string = '[Variant] Get variant precalculation';
export const GET_VARIANT_PRECALCULATION_SUCCESS: string =
  '[Variant] Get variant precalculation success';
export const GET_VARIANT_PRECALCULATION_FAILED: string =
  '[Variant] Get variant precalculation failed';

export const CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY: string =
  '[Variant] Convert solar panner dynamic property';
export const CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY_SUCCESS: string =
  '[Variant] Convert solar panner dynamic property success';
export const CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY_FAILED: string =
  '[Variant] Convert solar panner dynamic property failed';

export const SAVE_SOLAR_PLANNER_DATA: string = '[Variant] Save solar panner data';
export const SAVE_SOLAR_PLANNER_DATA_SUCCESS: string = '[Variant]  Save solar panner data success';
export const SAVE_SOLAR_PLANNER_DATA_FAILED: string = '[Variant] Save solar panner data failed';

export const DELETE_SOLAR_PLANNER_SCREENSHOT: string = '[Variant] Delete solar panner screenshot';
export const DELETE_SOLAR_PLANNER_SCREENSHOT_SUCCESS: string =
  '[Variant]  Delete solar panner screenshot success';
export const DELETE_SOLAR_PLANNER_SCREENSHOT_FAILED: string =
  '[Variant] Delete solar panner screenshot failed';

export const UPLOAD_VARIANT_SIGNATURE: string = '[Variant] Upload variant signature';
export const UPLOAD_VARIANT_SIGNATURE_SUCCESS: string =
  '[Variant] Upload variant signature success';
export const UPLOAD_VARIANT_SIGNATURE_FAILED: string = '[Variant] Upload variant signature failed';

export const GET_VARIANT_SIGNATURE: string = '[Variant] Get variant signature';
export const GET_VARIANT_SIGNATURE_SUCCESS: string = '[Variant] Get variant signature success';
export const GET_VARIANT_SIGNATURE_FAILED: string = '[Variant] Get variant signature failed';

export const GET_ACCOUNT_ASSIGNMENT_LIST: string = '[Variant] Get account assignment list';
export const GET_ACCOUNT_ASSIGNMENT_LIST_SUCCESS: string =
  '[Variant] Get account assignment list success';
export const GET_ACCOUNT_ASSIGNMENT_LIST_FAILED: string =
  '[Variant] Get account assignment list failed';

export class ResetVariantAction implements Action {
  readonly type: string = RESET_VARIANT;

  constructor(public payload?: undefined) {}
}

export interface ICreateVariantActionData {
  tradeType: string;
  leadId: number;
  createPvVariantRequest?: CreatePvVariantRequest;
  createHeatingVariantRequest?: CreateHeatingVariantRequest;
  createSimpleTradeVariantRequest?: CreateSimpleTradeVariantRequest;
  createEmVariantRequest?: CreateEmobilityVariantRequest;
}

export interface ICreatePriceTableVariantActionData {
  leadId: number;
  title: string;
  templateId: number;
  moduleCount: number;
}

export class CreatePriceTableVariantAction implements Action {
  readonly type: string = CREATE_PRICE_TABLE_VARIANT;

  constructor(public payload: ICreatePriceTableVariantActionData) {}
}

export class CreatePriceTableVariantSuccessAction implements Action {
  readonly type: string = CREATE_PRICE_TABLE_VARIANT_SUCCESS;

  constructor(public payload: { variantComposite: VariantCompositeViewModel }) {}
}

export class CreatePriceTableVariantFailedAction implements Action {
  readonly type: string = CREATE_PRICE_TABLE_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export class CreateVariantAction implements Action {
  readonly type: string = CREATE_VARIANT;

  constructor(public payload: ICreateVariantActionData) {}
}

export class CreateVariantSuccessAction implements Action {
  readonly type: string = CREATE_VARIANT_SUCCESS;

  constructor(public payload: { variantComposite: VariantCompositeViewModel }) {}
}

export class CreateVariantFailedAction implements Action {
  readonly type: string = CREATE_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface ISaveVariantActionPayload {
  tradeTypeCode: string;
  variantComposite: VariantCompositeViewModel;
}

export class SaveVariantAction implements Action {
  readonly type: string = SAVE_VARIANT;

  constructor(public payload: ISaveVariantActionPayload) {}
}

export class SaveVariantSuccessAction implements Action {
  readonly type: string = SAVE_VARIANT_SUCCESS;

  constructor(public payload: { variantComposite: VariantCompositeViewModel }) {}
}

export class SaveVariantFailedAction implements Action {
  readonly type: string = SAVE_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface IGenericVariantActionPayload {
  tradeType: string;
  variantId: number;
}

export class SetSelectedVariantAction implements Action {
  readonly type: string = SET_SELECTED_VARIANT;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class GetVariantAction implements Action {
  readonly type: string = GET_VARIANT;

  constructor(public payload: IGenericVariantActionPayload) {}
}

export class GetVariantSuccessAction implements Action {
  readonly type: string = GET_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class GetVariantFailedAction implements Action {
  readonly type: string = GET_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchVariantPayload {
  tradeTypeCode: string;
  variantId: number;
  patch: PatchVariantRequest;
}

export class PatchVariantAction implements Action {
  readonly type: string = PATCH_VARIANT;

  constructor(public payload: IPatchVariantPayload) {}
}

export class PatchVariantSuccessAction implements Action {
  readonly type: string = PATCH_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchVariantFailedAction implements Action {
  readonly type: string = PATCH_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchPvSpecificVariantPayload {
  variantId: number;
  patch: PatchPvVariantRequest;
}

export interface IPatchHtSpecificVariantPayload {
  variantId: number;
  patch: PatchHeatingVariantRequest;
}

export interface IPatchEmSpecificVariantPayload {
  variantId: number;
  patch: PatchEmobilityVariantRequest;
}

export interface IPatchPvNumberOfModulesVariantPayload {
  variantId: number;
  numberOfModules: number;
}

export class PatchPvNumberOfModulesVariantAction implements Action {
  readonly type: string = PATCH_PV_NUMBER_OF_MODULES_VARIANT;

  constructor(public payload: IPatchPvNumberOfModulesVariantPayload) {}
}

export interface IPatchPvModuleWattPeakVariantPayload {
  variantId: number;
  moduleWattPeak: number;
}

export class PatchPvModuleWattPeakVariantAction implements Action {
  readonly type: string = PATCH_PV_MODULE_WATT_PEAK_VARIANT;

  constructor(public payload: IPatchPvModuleWattPeakVariantPayload) {}
}

export interface IPatchStSpecificVariantPayload {
  variantId: number;
  tradeTypeCode: string;
  patch: PatchSimpleTradeVariantRequest;
}

export class PatchPvSpecificVariantAction implements Action {
  readonly type: string = PATCH_PV_SPECIFIC_VARIANT;

  constructor(public payload: IPatchPvSpecificVariantPayload) {}
}

export function isPvPatchPvVariant(action: Action): action is PatchPvSpecificVariantAction {
  return action.type === PATCH_PV_SPECIFIC_VARIANT;
}

export class PatchPvSpecificVariantSuccessAction implements Action {
  readonly type: string = PATCH_PV_SPECIFIC_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchPvSpecificVariantFailedAction implements Action {
  readonly type: string = PATCH_PV_SPECIFIC_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export class PatchHtSpecificVariantAction implements Action {
  readonly type: string = PATCH_HT_SPECIFIC_VARIANT;

  constructor(public payload: IPatchHtSpecificVariantPayload) {}
}

export class PatchHtSpecificVariantSuccessAction implements Action {
  readonly type: string = PATCH_HT_SPECIFIC_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchHtSpecificVariantFailedAction implements Action {
  readonly type: string = PATCH_HT_SPECIFIC_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchTradeSpecificVariantAndDynamicPropertyActionPayload {
  tradeType: TradeTypeEnum;
  stVariant: IPatchStSpecificVariantPayload;
  dynamicProperty: IPatchDynamicPropertyPayload;
}

export class PatchTradeSpecificVariantAndDynamicPropertyAction implements Action {
  readonly type: string = PATCH_TRATE_SPECIFIC_VARIANT_AND_DYNAMIC_PROPERTY;

  constructor(public payload: IPatchTradeSpecificVariantAndDynamicPropertyActionPayload) {}
}

export class PatchStSpecificVariantAction implements Action {
  readonly type: string = PATCH_ST_SPECIFIC_VARIANT;

  constructor(public payload: IPatchStSpecificVariantPayload) {}
}

export class PatchStSpecificVariantSuccessAction implements Action {
  readonly type: string = PATCH_ST_SPECIFIC_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchStSpecificVariantFailedAction implements Action {
  readonly type: string = PATCH_ST_SPECIFIC_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export class PatchEmSpecificVariantAction implements Action {
  readonly type: string = PATCH_EM_SPECIFIC_VARIANT;

  constructor(public payload: IPatchPvSpecificVariantPayload) {}
}

export class PatchEmSpecificVariantSuccessAction implements Action {
  readonly type: string = PATCH_EM_SPECIFIC_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchEmSpecificVariantFailedAction implements Action {
  readonly type: string = PATCH_EM_SPECIFIC_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export interface IAddRegularPositionsPayload {
  tradeTypeCode: string;
  variantId: number;
  addPositionRequests: AddRegularPositionRequest[];
}

export class AddRegularPositionsAction implements Action {
  readonly type: string = ADD_REGULAR_POSITIONS;

  constructor(public payload: IAddRegularPositionsPayload) {
    console.log(payload);
  }
}

export class AddRegularPositionsSuccessAction implements Action {
  readonly type: string = ADD_REGULAR_POSITIONS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class AddRegularPositionsFailedAction implements Action {
  readonly type: string = ADD_REGULAR_POSITIONS_FAILED;

  constructor(public payload: any) {}
}

export interface IAddSpecialPositionsPayload {
  tradeTypeCode: string;
  variantId: number;
  addPositionRequests: AddSpecialPositionRequest[];
}

export class AddSpecialPositionsAction implements Action {
  readonly type: string = ADD_SPECIAL_POSITIONS;

  constructor(public payload: IAddSpecialPositionsPayload) {}
}

export class AddSpecialPositionsSuccessAction implements Action {
  readonly type: string = ADD_SPECIAL_POSITIONS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class AddSpecialPositionsFailedAction implements Action {
  readonly type: string = ADD_SPECIAL_POSITIONS_FAILED;

  constructor(public payload: any) {}
}

export interface IAddGroupPayload {
  tradeTypeCode: string;
  variantId: number;
  addGroupsRequests: AddPositionGroupRequest;
}

export class AddGroupPositionsAction implements Action {
  readonly type: string = ADD_GROUP_POSITIONS;

  constructor(public payload: IAddGroupPayload) {}
}

export class AddGroupPositionsSuccessAction implements Action {
  readonly type: string = ADD_GROUP_POSITIONS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class AddGroupPositionsFailedAction implements Action {
  readonly type: string = ADD_GROUP_POSITIONS_FAILED;

  constructor(public payload: any) {}
}

export interface IPasteGroupsAndPositionsPayload {
  tradeTypeCode: string;
  sourceVariantId: number;
  targetVariantId: number;
  positionsType: PastePositionsAndGroupsRequest.PositionsTypeEnum;
  targetGroupId: string;
  orderFrom: number;
  groupIds: string[];
  regularPositionIds: string[];
  specialPositionIds: string[];
}

export class PasteGroupsAndPositionsAction implements Action {
  readonly type: string = PASTE_GROUPS_AND_POSITIONS;

  constructor(public payload: IPasteGroupsAndPositionsPayload) {}
}

export class PasteGroupsAndPositionsSuccessAction implements Action {
  readonly type: string = PASTE_GROUPS_AND_POSITIONS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PasteGroupsAndPositionsFailedAction implements Action {
  readonly type: string = PASTE_GROUPS_AND_POSITIONS_FAILED;

  constructor(public payload: any) {}
}

export interface IPutPositionPayload {
  tradeTypeCode: string;
  variantId: number;
  putPositionRequest: PutPositionOrderRequest;
}

export class PutPositionOrderAndGroupAction implements Action {
  readonly type: string = PUT_POSITION_ORDER_AND_GROUP;

  constructor(public payload: IPutPositionPayload) {}
}

export class PutPositionOrderAndGroupSuccessAction implements Action {
  readonly type: string = PUT_POSITION_ORDER_AND_GROUP_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PutPositionOrderAndGroupFailedAction implements Action {
  readonly type: string = PUT_POSITION_ORDER_AND_GROUP_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchPositionGroupPayload {
  tradeTypeCode: string;
  variantId: number;
  groupId: string;
  patchPositionGroupRequest: PatchPositionGroupRequest;
}

export class PatchPositionGroupAction implements Action {
  readonly type: string = PATCH_POSITION_GROUP;

  constructor(public payload: IPatchPositionGroupPayload) {}
}

export class PatchPositionGroupSuccessAction implements Action {
  readonly type: string = PATCH_POSITION_GROUP_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchPositionGroupFailedAction implements Action {
  readonly type: string = PATCH_POSITION_GROUP_FAILED;

  constructor(public payload: any) {}
}

export interface ISelectPositionGroupPayload {
  tradeTypeCode: string;
  variantId: number;
  groupId: string;
}

export class SelectPositionGroupAction implements Action {
  readonly type: string = SELECT_POSITION_GROUP;

  constructor(public payload: ISelectPositionGroupPayload) {}
}

export class SelectPositionGroupSuccessAction implements Action {
  readonly type: string = SELECT_POSITION_GROUP_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class SelectPositionGroupFailedAction implements Action {
  readonly type: string = SELECT_POSITION_GROUP_FAILED;

  constructor(public payload: any) {}
}

export class DeselectPositionGroupAction implements Action {
  readonly type: string = DESELECT_POSITION_GROUP;

  constructor(public payload: ISelectPositionGroupPayload) {}
}

export class DeselectPositionGroupSuccessAction implements Action {
  readonly type: string = DESELECT_POSITION_GROUP_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class DeselectPositionGroupFailedAction implements Action {
  readonly type: string = DESELECT_POSITION_GROUP_FAILED;

  constructor(public payload: any) {}
}

export interface IReplaceSuccessorPositionPayload extends IReplaceAllSuccessorPositionsPayload {
  positionId: string;
}

export interface IReplaceAllSuccessorPositionsPayload {
  tradeTypeCode: string;
  variantId: number;
}

export class ReplaceSuccessorPositionAction implements Action {
  readonly type: string = REPLACE_SUCCESSOR_POSITION;

  constructor(public payload: IReplaceSuccessorPositionPayload) {}
}

export class ReplaceSuccessorPositionSuccessAction implements Action {
  readonly type: string = REPLACE_SUCCESSOR_POSITION_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class ReplaceSuccessorPositionFailedAction implements Action {
  readonly type: string = REPLACE_SUCCESSOR_POSITION_FAILED;

  constructor(public payload: any) {}
}

export class ReplaceAllSuccessorPositionsAction implements Action {
  readonly type: string = REPLACE_ALL_SUCCESSOR_POSITIONS;

  constructor(public payload: IReplaceAllSuccessorPositionsPayload) {}
}

export class ReplaceAllSuccessorPositionsSuccessAction implements Action {
  readonly type: string = REPLACE_ALL_SUCCESSOR_POSITIONS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class ReplaceAllSuccessorPositionsFailedAction implements Action {
  readonly type: string = REPLACE_ALL_SUCCESSOR_POSITIONS_FAILED;

  constructor(public payload: any) {}
}

export interface IDeleteBomItemsPayload {
  tradeTypeCode: string;
  variantId: number;
  itemIds: string[];
}

export class DeleteBomItemsAction implements Action {
  readonly type: string = DELETE_BOM_ITEMS;

  constructor(public payload: IDeleteBomItemsPayload) {}
}

export class DeleteBomItemsSuccessAction implements Action {
  readonly type: string = DELETE_BOM_ITEMS_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class DeleteBomItemsFailedAction implements Action {
  readonly type: string = DELETE_BOM_ITEMS_FAILED;

  constructor(public payload: any) {}
}

export class RebuildVariantAction implements Action {
  readonly type: string = REBUILD_VARIANT;

  constructor(public payload: { tradeTypeCode: string; variantId: number }) {}
}

export class RebuildVariantMeierToblerAction implements Action {
  readonly type: string = REBUILD_VARIANT_MEIER_TOBLER;

  constructor(public payload: { variantId: number }) {}
}

export class RebuildVariantSuccessAction implements Action {
  readonly type: string = REBUILD_VARIANT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class RebuildVariantFailedAction implements Action {
  readonly type: string = REBUILD_VARIANT_FAILED;

  constructor(public payload: any) {}
}

export class AddVariantRebateAction implements Action {
  readonly type: string = ADD_VARIANT_REBATE;

  constructor(
    public payload: { tradeTypeCode: string; variantId: number; addRebateRequest: AddRebateRequest }
  ) {}
}

export class AddVariantRebateSuccessAction implements Action {
  readonly type: string = ADD_VARIANT_REBATE_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class AddVariantRebateFailedAction implements Action {
  readonly type: string = ADD_VARIANT_REBATE_FAILED;

  constructor(public payload: any) {}
}

export class UpdateVariantRebateAction implements Action {
  readonly type: string = UPDATE_VARIANT_REBATE;

  constructor(
    public payload: {
      tradeTypeCode: string;
      variantId: number;
      rebateId: number;
      addRebateRequest: AddRebateRequest;
    }
  ) {}
}

export class UpdateVariantRebateSuccessAction implements Action {
  readonly type: string = UPDATE_VARIANT_REBATE_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class UpdateVariantRebateFailedAction implements Action {
  readonly type: string = UPDATE_VARIANT_REBATE_FAILED;

  constructor(public payload: any) {}
}

export class RemoveVariantRebateAction implements Action {
  readonly type: string = REMOVE_VARIANT_REBATE;

  constructor(public payload: any) {}
}

export class RemoveVariantRebateSuccessAction implements Action {
  readonly type: string = REMOVE_VARIANT_REBATE_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class RemoveVariantRebateFailedAction implements Action {
  readonly type: string = REMOVE_VARIANT_REBATE_FAILED;

  constructor(public payload: any) {}
}

export class UpdatePricesAction implements Action {
  readonly type: string = UPDATE_PRICES;

  constructor(public payload: { tradeTypeCode: string; variantId: number }) {}
}

export class UpdatePricesSuccessAction implements Action {
  readonly type: string = UPDATE_PRICES_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class UpdatePricesFailedAction implements Action {
  readonly type: string = UPDATE_PRICES_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchRegularOrSpecialPositionPayload {
  tradeTypeCode: string;
  variantId: number;
  positions: PatchPositionRequest[];
}

export class PatchRegularOrSpecialPositionAction implements Action {
  readonly type: string = PATCH_REGULAR_OR_SPECIAL_POSITION;

  constructor(public payload: IPatchRegularOrSpecialPositionPayload) {}
}

export interface IPatchVariantRegularOrSpecialPositionPayload {
  tradeTypeCode: string;
  positions: PatchVariantPositionRequest[];
}

export class PatchRegularOrSpecialPositionsAction implements Action {
  readonly type: string = PATCH_REGULAR_OR_SPECIAL_POSITIONS;

  constructor(public payload: IPatchVariantRegularOrSpecialPositionPayload) {}
}

export class PatchRegularOrSpecialVariantPositionSuccessAction implements Action {
  readonly type: string = PATCH_REGULAR_OR_SPECIAL_VARIANT_POSITION_SUCCESS;

  constructor() {}
}

export class PatchRegularOrSpecialPositionSuccessAction implements Action {
  readonly type: string = PATCH_REGULAR_OR_SPECIAL_POSITION_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchRegularOrSpecialPositionFailedAction implements Action {
  readonly type: string = PATCH_REGULAR_OR_SPECIAL_POSITION_FAILED;

  constructor(public payload: any) {}
}

export interface IPatchDynamicPropertyPayload {
  tradeTypeCode: string;
  variantId: number;
  patchPropertyRequest: PatchVariantDynamicPropertyRequest[];
}

export class PatchDynamicPropertyAction implements Action {
  readonly type: string = PATCH_DYNAMIC_PROPERTY;

  constructor(public payload: IPatchDynamicPropertyPayload) {}
}

export function isPatchDynamicProperty(action: Action): action is PatchDynamicPropertyAction {
  return action.type === PATCH_DYNAMIC_PROPERTY;
}

export class PatchDynamicPropertySuccessAction implements Action {
  readonly type: string = PATCH_DYNAMIC_PROPERTY_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class PatchDynamicPropertyFailedAction implements Action {
  readonly type: string = PATCH_DYNAMIC_PROPERTY_FAILED;

  constructor(public payload: any) {}
}

export interface IDeleteDynamicPropertyPayload {
  tradeTypeCode: string;
  variantId: number;
  propertyName: string;
}

export class DeleteDynamicPropertyAction implements Action {
  readonly type: string = DELETE_DYNAMIC_PROPERTY;

  constructor(public payload: IDeleteDynamicPropertyPayload) {}
}

export class DeleteDynamicPropertySuccessAction implements Action {
  readonly type: string = DELETE_DYNAMIC_PROPERTY_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class DeleteDynamicPropertyFailedAction implements Action {
  readonly type: string = DELETE_DYNAMIC_PROPERTY_FAILED;

  constructor(public payload: any) {}
}

export class UpdateObjectDataAction implements Action {
  readonly type: string = UPDATE_OBJECT_DATA;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class UpdateObjectDataSuccessAction implements Action {
  readonly type: string = UPDATE_OBJECT_DATA_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class UpdateObjectDataFailedAction implements Action {
  readonly type: string = UPDATE_OBJECT_DATA_FAILED;

  constructor(public payload: any) {}
}

export class GetVariantPrecaculationAction implements Action {
  readonly type: string = GET_VARIANT_PRECALCULATION;

  constructor(public payload: IGenericVariantActionPayload) {}
}

export class GetVariantPrecalculationSuccessAction implements Action {
  readonly type: string = GET_VARIANT_PRECALCULATION_SUCCESS;

  constructor(public payload: VariantPrecalculationViewModel) {}
}

export class GetVariantPrecalculationFailedAction implements Action {
  readonly type: string = GET_VARIANT_PRECALCULATION_FAILED;

  constructor(public payload: any) {}
}

export interface IConvertSolarPlannerDynamicPropertyActionPayload {
  projectStoreJsonString: string;
  variantId: number;
  wizardData: PvWizardViewModel;
}

export class ConvertSolarPlannerDynamicPropertyAction implements Action {
  readonly type: string = CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY;

  constructor(public payload: IConvertSolarPlannerDynamicPropertyActionPayload) {}
}

export class ConvertSolarPlannerDynamicPropertySuccessAction implements Action {
  readonly type: string = CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY_SUCCESS;

  constructor(public payload: any) {}
}

export class ConvertSolarPlannerDynamicPropertyFailedAction implements Action {
  readonly type: string = CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY_FAILED;

  constructor(public payload: any) {}
}

export interface ISaveSolarPlannerAction {
  projectStoreJsonString: string;
  screenshot: any;
  variantId: number;
  wizardData: PvWizardViewModel;
}

export class SaveSolarPlannerAction implements Action {
  readonly type: string = SAVE_SOLAR_PLANNER_DATA;

  constructor(public payload: ISaveSolarPlannerAction) {}
}

export class SaveSolarPlannerSuccessAction implements Action {
  readonly type: string = SAVE_SOLAR_PLANNER_DATA_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class SaveSolarPlannerFailedAction implements Action {
  readonly type: string = SAVE_SOLAR_PLANNER_DATA_FAILED;

  constructor(public payload: any) {}
}

export interface IDeleteSolarPlannerScreenshotAction {
  variantId: number;
  wizardData: PvWizardViewModel;
}

export class DeleteSolarPlannerScreenshotOnVariantFileAction implements Action {
  readonly type: string = DELETE_SOLAR_PLANNER_SCREENSHOT;

  constructor(public payload: IDeleteSolarPlannerScreenshotAction) {}
}

export class DeleteSolarPlannerScreenshotOnVariantFileSuccessAction implements Action {
  readonly type: string = DELETE_SOLAR_PLANNER_SCREENSHOT_SUCCESS;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class DeleteSolarPlannerScreenshotOnVariantFileFailedAction implements Action {
  readonly type: string = DELETE_SOLAR_PLANNER_SCREENSHOT_FAILED;

  constructor(public payload: any) {}
}

// Variant signature
export interface IUploadVariantSignature {
  variantId: number;
  file: File;
}

export class UploadVariantSignatureAction implements Action {
  readonly type: string = UPLOAD_VARIANT_SIGNATURE;

  constructor(public payload: IUploadVariantSignature) {}
}

export class UploadVariantSignatureSuccessAction implements Action {
  readonly type: string = UPLOAD_VARIANT_SIGNATURE_SUCCESS;

  constructor(public payload: string) {}
}

export class UploadVariantSignatureFailedAction implements Action {
  readonly type: string = UPLOAD_VARIANT_SIGNATURE_FAILED;

  constructor(public payload: any) {}
}

export class GetVariantSignatureAction implements Action {
  readonly type: string = GET_VARIANT_SIGNATURE;

  constructor(public payload: number) {}
}

export class GetVariantSignatureSuccessAction implements Action {
  readonly type: string = GET_VARIANT_SIGNATURE_SUCCESS;

  constructor(public payload: string) {}
}

export class GetVariantSignatureFailedAction implements Action {
  readonly type: string = GET_VARIANT_SIGNATURE_FAILED;

  constructor(public payload: any) {}
}

// Account Assignment List
export class GetAccountAssignmentListAction implements Action {
  readonly type: string = GET_ACCOUNT_ASSIGNMENT_LIST;

  constructor(public payload: string) {}
}

export class GetAccountAssignmentListSuccessAction implements Action {
  readonly type: string = GET_ACCOUNT_ASSIGNMENT_LIST_SUCCESS;

  constructor(public payload: IAccountAssignmentData[]) {}
}

export class GetAccountAssignmentListFailedAction implements Action {
  readonly type: string = GET_ACCOUNT_ASSIGNMENT_LIST_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | ResetVariantAction
  | CreateVariantAction
  | CreateVariantSuccessAction
  | CreateVariantFailedAction
  | SaveVariantAction
  | SaveVariantSuccessAction
  | SaveVariantFailedAction
  | GetVariantAction
  | GetVariantSuccessAction
  | GetVariantFailedAction
  | PatchVariantAction
  | PatchVariantSuccessAction
  | PatchVariantFailedAction
  | PatchTradeSpecificVariantAndDynamicPropertyAction
  | PatchPvSpecificVariantAction
  | PatchPvSpecificVariantSuccessAction
  | PatchPvSpecificVariantFailedAction
  | PatchPvNumberOfModulesVariantAction
  | PatchPvModuleWattPeakVariantAction
  | PatchHtSpecificVariantAction
  | PatchHtSpecificVariantSuccessAction
  | PatchHtSpecificVariantFailedAction
  | PatchStSpecificVariantAction
  | PatchStSpecificVariantSuccessAction
  | PatchStSpecificVariantFailedAction
  | DeleteBomItemsAction
  | DeleteBomItemsSuccessAction
  | DeleteBomItemsFailedAction
  | RebuildVariantAction
  | RebuildVariantMeierToblerAction
  | RebuildVariantSuccessAction
  | RebuildVariantFailedAction
  | AddVariantRebateAction
  | AddVariantRebateSuccessAction
  | AddVariantRebateFailedAction
  | RemoveVariantRebateAction
  | RemoveVariantRebateSuccessAction
  | RemoveVariantRebateFailedAction
  | UpdatePricesAction
  | UpdatePricesSuccessAction
  | UpdatePricesFailedAction
  | PatchRegularOrSpecialPositionAction
  | PatchRegularOrSpecialPositionSuccessAction
  | PatchRegularOrSpecialPositionFailedAction
  | UpdateObjectDataAction
  | UpdateObjectDataSuccessAction
  | UpdateObjectDataFailedAction
  | GetVariantPrecaculationAction
  | GetVariantPrecalculationSuccessAction
  | GetVariantPrecalculationFailedAction
  | ConvertSolarPlannerDynamicPropertyAction
  | ConvertSolarPlannerDynamicPropertySuccessAction
  | ConvertSolarPlannerDynamicPropertyFailedAction
  | SaveSolarPlannerAction
  | SaveSolarPlannerSuccessAction
  | SaveSolarPlannerFailedAction
  | DeleteSolarPlannerScreenshotOnVariantFileAction
  | DeleteSolarPlannerScreenshotOnVariantFileSuccessAction
  | DeleteSolarPlannerScreenshotOnVariantFileFailedAction
  | UploadVariantSignatureAction
  | UploadVariantSignatureSuccessAction
  | UploadVariantSignatureFailedAction
  | GetVariantSignatureAction
  | GetVariantSignatureSuccessAction
  | GetVariantSignatureFailedAction
  | GetAccountAssignmentListAction
  | GetAccountAssignmentListSuccessAction
  | GetAccountAssignmentListFailedAction
  | SelectPositionGroupAction
  | SelectPositionGroupFailedAction
  | SelectPositionGroupSuccessAction
  | DeselectPositionGroupAction
  | DeselectPositionGroupFailedAction
  | DeselectPositionGroupSuccessAction;
