/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FieldServicePartsListPositionDto { 
    TheiaPositionId?: string;
    ArticleNumber?: string;
    Quantity?: number;
    Unit?: string;
    AlternativeArticleName?: string;
    EstimateUnitCost?: number;
    EstimateTotalCost?: number;
    DiscountAmount?: number;
    ReplacedBy?: string;
    LineStatus?: FieldServicePartsListPositionDto.LineStatusEnum;
    CustomerAsset?: string;
    WorkOrderId?: string;
    WorkOrderProductId?: string;
}
export namespace FieldServicePartsListPositionDto {
    export type LineStatusEnum = 'Warrant' | 'Goodwill' | 'Defect' | 'New' | 'Remove' | 'Active';
    export const LineStatusEnum = {
        Warrant: 'Warrant' as LineStatusEnum,
        Goodwill: 'Goodwill' as LineStatusEnum,
        Defect: 'Defect' as LineStatusEnum,
        New: 'New' as LineStatusEnum,
        Remove: 'Remove' as LineStatusEnum,
        Active: 'Active' as LineStatusEnum
    };
}
