/* eslint-disable max-classes-per-file */
import { IViewSettingBase } from './view-setting-base.model';
import { LeadRequest } from '../../apis/advis';

export class LeadOverviewSettingsModel implements IViewSettingBase<LeadOverviewSettingsModel> {
  public filter: LeadOverviewFilterVsModel = new LeadOverviewFilterVsModel();
  public filterVisible: boolean = true;
  public tableSort: any[] = [];
  public page: number = 0;
  public itemsPerPage: number = 100;
  public panel: LeadOverviewPanelModel = new LeadOverviewPanelModel();

  public clone(
    defaultProjectManagerId?: string,
    defaultProjectManagersOrganisationUnitId?: number
  ): LeadOverviewSettingsModel {
    const model: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    model.filter = this.filter.clone(
      defaultProjectManagerId,
      defaultProjectManagersOrganisationUnitId
    );
    model.filterVisible = this.filterVisible;
    model.tableSort = this.tableSort;
    model.page = this.page;
    model.itemsPerPage = this.itemsPerPage;
    model.panel = this.panel.clone();

    return model;
  }
}

export class LeadOverviewPanelModel {
  public panelExpanded: boolean = true;

  public clone(): LeadOverviewPanelModel {
    const panel: LeadOverviewPanelModel = new LeadOverviewPanelModel();
    panel.panelExpanded = this.panelExpanded;

    return panel;
  }
}

export const defaultFilterSettings: {
  projectManagerId: string;
  projectManagersOrganisationUnitId: number;
} = {
  projectManagerId: '',
  projectManagersOrganisationUnitId: undefined,
};

export class LeadOverviewFilterVsModel implements IViewSettingBase<LeadOverviewFilterVsModel> {
  public name: string = '';
  public street: string = '';
  public zipCity: string = '';
  public zip: string = '';
  public city: string = '';
  public owner: string = '';
  public sales: string = '';
  public date: string = '';
  public leadId: number = undefined;
  public publicLeadId: string = '';
  public variantId: number = undefined;
  public projectManagers: string[] = [];
  public planner: string = '';
  public plannerAc: string = '';
  public stage: string[] = [];
  public status: string[] = [];
  public hideLeadsWithFutureTasks: boolean = false;
  public mandators: number[] = [];
  public email: string = '';
  public tradeType: string = '';
  public tracers: string[] = [];
  public origins: string[] = [];
  public originFreeText: string = '';
  public phoneNumber: string = '';
  public companyName: string = '';
  public language: string = '';
  public tag: string = '';
  public projectSapNumber: string = '';
  public projectName: string = '';
  public mandant: string = '';
  public origin: string = '';
  public executingOrgUnitIds: number[] = [];
  public salesOrganisationUnitIds: number[] = [];
  public projectManagersOrganisationUnitId: number;
  public organisationUnitSalesPerson: string = '';
  public thirdPartyCustomerNumber: string = '';
  public addressLookupType: LeadRequest.AddressLookupTypeEnum =
    LeadRequest.AddressLookupTypeEnum.All;
  public dateRangeStart: string = '';
  public dateRangeEnd: string = '';
  public dateRangeType: LeadRequest.DateRangeTypeEnum = LeadRequest.DateRangeTypeEnum.Created;

  constructor(defaultProjectManagerId?: string, defaultProjectManagersOrganisationUnitId?: number) {
    defaultFilterSettings.projectManagerId = defaultProjectManagerId || '';
    defaultFilterSettings.projectManagersOrganisationUnitId =
      defaultProjectManagersOrganisationUnitId || undefined;
    this.projectManagers =
      this.projectManagers.length > 0 && !!this.projectManagers[0]
        ? this.projectManagers
        : defaultProjectManagerId
        ? [defaultProjectManagerId]
        : [];
    this.projectManagersOrganisationUnitId =
      this.projectManagersOrganisationUnitId || defaultProjectManagersOrganisationUnitId;
  }

  public clone(
    defaultProjectManagerId?: string,
    defaultProjectManagersOrganisationUnitId?: number
  ): LeadOverviewFilterVsModel {
    // save to use in reset
    defaultFilterSettings.projectManagerId =
      defaultProjectManagerId || defaultFilterSettings.projectManagerId;
    defaultFilterSettings.projectManagersOrganisationUnitId =
      defaultProjectManagersOrganisationUnitId ||
      defaultFilterSettings.projectManagersOrganisationUnitId;

    const filter: LeadOverviewFilterVsModel = new LeadOverviewFilterVsModel();
    filter.name = this.name;
    filter.street = this.street;
    filter.zipCity = this.zipCity;
    filter.zip = this.zip;
    filter.city = this.city;
    filter.owner = this.owner;
    filter.sales = this.sales;
    filter.projectManagers =
      this.projectManagers.length > 0 && !!this.projectManagers[0]
        ? this.projectManagers
        : defaultProjectManagerId
        ? [defaultProjectManagerId]
        : [];
    filter.projectManagersOrganisationUnitId =
      this.projectManagersOrganisationUnitId || defaultProjectManagersOrganisationUnitId;
    filter.planner = this.planner;
    filter.plannerAc = this.plannerAc;
    filter.stage = this.stage;
    filter.status = this.status;
    filter.leadId = this.leadId;
    filter.publicLeadId = this.publicLeadId;
    filter.variantId = this.variantId;
    filter.hideLeadsWithFutureTasks = this.hideLeadsWithFutureTasks;
    filter.mandators = this.mandators;
    filter.email = this.email;
    filter.tradeType = this.tradeType;
    filter.tracers = this.tracers;
    filter.origins = this.origins;
    filter.originFreeText = this.originFreeText;
    filter.phoneNumber = this.phoneNumber;
    filter.companyName = this.companyName;
    filter.language = this.language;
    filter.tag = this.tag;
    filter.mandant = this.mandant;
    filter.origin = this.origin;
    filter.executingOrgUnitIds = this.executingOrgUnitIds;
    filter.organisationUnitSalesPerson = this.organisationUnitSalesPerson || '';
    filter.salesOrganisationUnitIds = this.salesOrganisationUnitIds;
    filter.thirdPartyCustomerNumber = this.thirdPartyCustomerNumber;
    filter.addressLookupType = this.addressLookupType;
    filter.projectSapNumber = this.projectSapNumber;
    filter.projectName = this.projectName;
    filter.dateRangeEnd = this.dateRangeEnd;
    filter.dateRangeStart = this.dateRangeStart;
    filter.dateRangeType = this.dateRangeType;
    return filter;
  }

  public resetCustomerData(): LeadOverviewFilterVsModel {
    const filter = this.clone();
    filter.companyName = '';
    filter.name = '';
    filter.street = '';
    filter.zipCity = '';
    filter.zip = '';
    filter.city = '';
    filter.email = '';
    filter.phoneNumber = '';
    filter.language = '';
    filter.salesOrganisationUnitIds = [];

    return filter;
  }

  public resetLeadData(): LeadOverviewFilterVsModel {
    const filter = this.clone();
    filter.leadId = undefined;
    filter.publicLeadId = '';
    filter.variantId = undefined;
    filter.mandators = [];
    filter.tradeType = '';
    filter.origins = [];
    filter.originFreeText = '';
    filter.tracers = [];
    filter.tag = '';
    filter.stage = [];
    filter.status = [];
    filter.projectSapNumber = '';
    filter.projectName = '';

    return filter;
  }

  public resetTimeRange(): LeadOverviewFilterVsModel {
    const filter = this.clone();
    filter.date = '';
    filter.dateRangeEnd = '';
    filter.dateRangeStart = '';
    filter.dateRangeType = undefined;

    return filter;
  }

  public resetResponsibleData(): LeadOverviewFilterVsModel {
    const filter = this.clone();
    filter.owner = '';
    filter.sales = '';
    filter.organisationUnitSalesPerson = '';
    filter.projectManagers = [defaultFilterSettings.projectManagerId];
    filter.projectManagersOrganisationUnitId =
      defaultFilterSettings.projectManagersOrganisationUnitId;
    filter.planner = '';
    filter.plannerAc = '';
    filter.executingOrgUnitIds = [];

    return filter;
  }
}
