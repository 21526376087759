import * as auth from './auth.action';
import { IPrincipal } from '../../interfaces/principal.interface';

export interface IState {
  loggedIn: boolean;
  principal: IPrincipal | null;
  error: any;
  pending: boolean;
}

export const initialState: IState = {
  loggedIn: false,
  principal: undefined,
  error: undefined,
  pending: false,
};

export function reducer(state: IState = initialState, action: auth.Actions): IState {
  switch (action.type) {
    case auth.LOGIN: {
      return {
        ...state,
        loggedIn: false,
        principal: undefined,
        error: undefined,
        pending: true,
      };
    }

    case auth.LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        principal: action.payload.principal,
        error: undefined,
        pending: false,
      };
    }

    case auth.LOGIN_SUCCESS_SESSION: {
      return {
        ...state,
        loggedIn: true,
        principal: action.payload,
        error: undefined,
        pending: false,
      };
    }

    case auth.LOGIN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        principal: undefined,
        error: action.payload,
        pending: false,
      };
    }

    // TODO: Do we have sideeffects when not resetting the state? The whole ngrx-store will be cleared in the login page (auth.component.ts)
    case auth.LOGOUT_SESSION:
    case auth.LOGOUT: {
      return {
        ...state,
        loggedIn: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoggedIn: (state: IState) => boolean = (state: IState) => state.loggedIn;
export const getPrincipal: (state: IState) => IPrincipal | undefined = (state: IState) =>
  state.principal;
export const getError: (state: IState) => Object | string | undefined = (state: IState) =>
  state.error;
export const getPending: (state: IState) => boolean = (state: IState) => state.pending;
