import { Observable, Subscription } from 'rxjs';
import { Authenticate, Principal } from '../models/principal.model';
import { AccountService, UserInfo } from '../apis/advis';
import { Injectable } from '@angular/core';
import { IPrincipal } from '../interfaces/principal.interface';
import { LocalStorageKey } from './local-storage-key';
import { flatMap, map, tap, switchMap, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { SnackbarService } from '@sitewerk/theia-ui-lib';

export type DataJWT = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  tokenType: 'Bearer';
};

@Injectable()
export class AuthService {
  constructor(
    private accountApi: AccountService,
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  public subscribeToSsoErrors(): Subscription {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          e =>
            e.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
            e.eventType === EventType.LOGIN_FAILURE
        )
      )
      .subscribe(event => {
        this.router.navigate(['../../auth/login']);
        this.snackbarService.onShowSnackbar(
          {
            color: 'danger',
            message: event.error.message,
            closeButton: true,
          },
          {
            timeout: 30000,
          }
        );
      });
  }

  public onSsoRedirect(): Observable<HttpResponse<DataJWT>> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ),
      switchMap((result: EventMessage) =>
        this.http.get<DataJWT>(`${environment.apiBasePath}/api/pc/account/exchange-openid-token`, {
          observe: 'response',
          headers: new HttpHeaders({
            Authorization: `Bearer ${result.payload['accessToken']}`,
          }),
        })
      )
    );
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem(LocalStorageKey.MAIN_KEY_AUTH) || !!this.getToken();
  }

  public getTokenData(): DataJWT {
    return JSON.parse(localStorage.getItem(LocalStorageKey.MAIN_KEY_AUTH_JWT));
  }

  public getToken(): string {
    return this.getTokenData()?.accessToken ?? '';
  }

  public loginJWT(authenticate: Authenticate): Observable<DataJWT> {
    const loginViewModel = {
      UserName: authenticate.username,
      Password: authenticate.password,
      Email: authenticate.username,
    };
    return this.http
      .post<DataJWT>(`${environment.apiBasePath}/api/pc/auth/login`, loginViewModel)
      .pipe(
        tap(data => localStorage.setItem(LocalStorageKey.MAIN_KEY_AUTH_JWT, JSON.stringify(data)))
      );
  }

  public refreshToken(): Observable<DataJWT> {
    return this.http
      .post<DataJWT>(`${environment.apiBasePath}/api/pc/auth/refresh`, {
        RefreshToken: this.getTokenData()?.refreshToken ?? '',
      })
      .pipe(
        tap(data => localStorage.setItem(LocalStorageKey.MAIN_KEY_AUTH_JWT, JSON.stringify(data)))
      );
  }

  public login(authenticate: Authenticate): Observable<UserInfo | DataJWT> {
    return this.accountApi.accountIsJwtAuthEnabled().pipe(
      switchMap((enabled: boolean) =>
        enabled
          ? this.loginJWT(authenticate)
          : this.accountApi
              .accountLogin({
                UserName: authenticate.username,
                Password: authenticate.password,
                Remember: true,
              })
              .pipe(tap(() => localStorage.removeItem(LocalStorageKey.MAIN_KEY_AUTH_JWT)))
      )
    );
  }

  public afterSuccessLogin(authenticate: Authenticate): Observable<IPrincipal> {
    return this.accountApi.accountPutLanguage(authenticate.language).pipe(
      flatMap(() => {
        return this.getPrincipal().pipe(
          tap((principal: IPrincipal) => {
            localStorage.setItem(LocalStorageKey.MAIN_KEY_AUTH, JSON.stringify(principal));
          })
        );
      })
    );
  }

  public logout(): Observable<{}> {
    localStorage.removeItem(LocalStorageKey.MAIN_KEY_AUTH);
    localStorage.removeItem(LocalStorageKey.MAIN_KEY_AUTH_JWT);
    return this.accountApi.accountLogout();
  }

  // CAUTION: This method calls the API - don't use it except you know what you do.
  public getPrincipal(): Observable<IPrincipal> {
    return this.accountApi.accountGetUserInfo().pipe(
      map((data: any) => {
        return new Principal(data.User, data.UserId, data.Mandant, data.MandantId);
      })
    );
  }

  // CAUTION: This method reads the Principal from the local storage- don't use it except you know what you do.
  public getPrincipalSession(): IPrincipal | undefined {
    const principalObj: any = JSON.parse(localStorage.getItem(LocalStorageKey.MAIN_KEY_AUTH));
    if (principalObj) {
      return new Principal(
        principalObj.user,
        principalObj.userId,
        principalObj.mandant,
        principalObj.mandantId
      );
    }
    return undefined;
  }
}
