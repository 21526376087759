<div fxLayout="row" fxLayoutAlign="end center">
  <div class="contact-controls">
    <button>
      <pc-custom-file-upload
        [isOnlyIconButton]="false"
        accept=".png,.jpeg,.jpg,.gif"
        class="logo-upload-button"
        iconName="upload-cloud"
        (selectFile)="onLogoSelect($event)">
        <span>{{ 'ADDRESS.LOGO' | translate }}</span>
      </pc-custom-file-upload>
    </button>
    <div class="contact-checkbox-margin-right">
      <helion-ui-checkbox [isChecked]="isDataStateQualified" (change)="dataStateClick()">{{
        'ADDRESS.CREATE.VALIDATED' | translate
      }}</helion-ui-checkbox>
    </div>
    <button [attr.fill-type]="'icon'" [cdkMenuTriggerFor]="menu" #menuTrigger>
      <helion-ui-icon data-feather="more-vertical"></helion-ui-icon>
    </button>
    <ng-template #menu>
      <helion-ui-menu>
        <button class="menu-item" cdkMenuItem (click)="addEmail(contact.Person)">
          <helion-ui-icon data-feather="mail"></helion-ui-icon>
          <span>{{ 'ADDRESS.ADD_EMAIL' | translate }}</span>
        </button>
        <button class="menu-item" cdkMenuItem (click)="addPhone(contact.Person)">
          <helion-ui-icon data-feather="phone"></helion-ui-icon>
          <span>{{ 'ADDRESS.ADD_PHONE' | translate }}</span>
        </button>
        <pc-menu-save-to-external-managed-contact
          *ngIf="contact.Id > 0"
          [copyToExternalManaged]="true"
          (onCopyAsExternalManaged)="
            onCopyAsExternalManaged.emit({ contact: contact, addressType: $event })
          ">
        </pc-menu-save-to-external-managed-contact>
      </helion-ui-menu>
    </ng-template>
  </div>
</div>
<form class="contact-form" #singleContactForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="16px">
      <div
        fxLayout="column"
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 16px)"
        fxLayoutGap="24px">
        <helion-ui-select
          placeholder="{{ 'ADDRESS.TITLE' | translate }}"
          name="title"
          [ngModel]="_selectedTitleItem"
          (ngModelChange)="setSelectedTitleItem($event)">
          <helion-ui-option *ngFor="let item of titleItems" [value]="item" [label]="item.display">
          </helion-ui-option>
        </helion-ui-select>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.COMPANY' | translate }}"
          type="text"
          [maxlength]="requiresStrictValidation ? 30 : 50"
          name="company"
          ngDefaultControl
          [(ngModel)]="contact.Company.Name">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.FIRST_NAME' | translate }}"
          type="text"
          [maxlength]="30"
          name="firstName"
          ngDefaultControl
          [(ngModel)]="contact.Person.FirstName"
          [required]="true">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.LAST_NAME' | translate }}"
          type="text"
          [maxlength]="30"
          name="lastName"
          [(ngModel)]="contact.Person.LastName"
          [required]="true">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.STREET' | translate }}"
          type="text"
          [maxlength]="requiresStrictValidation ? 30 : 50"
          [required]="isObjectContact"
          name="street"
          [(ngModel)]="contact.Person.Street">
        </helion-ui-input>

        <helion-ui-select
          placeholder="{{ 'COMMON.LANGUAGE' | translate }}"
          name="language"
          [ngModel]="_selectedLanguageItem"
          (ngModelChange)="setPersonLanguage($event)"
          label="display">
          <helion-ui-option
            *ngFor="let item of languageItems"
            [value]="item"
            [label]="item?.display">
          </helion-ui-option>
        </helion-ui-select>

        <div
          fxLayout="column"
          fxLayoutGap="8px"
          *ngFor="let additionalEmail of contact.Person.Emails; let index = index">
          <div fxLayout="row" fxLayoutGap="10px" class="margin-top-0 additional-content-list">
            <helion-ui-input
              fxFlex="50"
              #email="ngModel"
              placeholder="{{
                (additionalEmail.EmailType === emailTypeEnum.Primary
                  ? 'ADDRESS.PRIMARY_EMAIL'
                  : 'ADDRESS.ADDITIONAL_EMAIL'
                ) | translate
              }}"
              email="true"
              type="email"
              [maxlength]="100"
              name="additionalEmail{{ index }}"
              [(ngModel)]="additionalEmail.Email">
            </helion-ui-input>

            <helion-ui-select
              fxFlex="100"
              name="additionalEmailType{{ index }}"
              [ngModel]="additionalEmail.EmailType">
              <helion-ui-option
                *ngFor="let emailType of emailTypes"
                [value]="emailType.Key"
                [label]="emailType.Translation"
                (click)="setEmailType(index, emailType)">
              </helion-ui-option>
            </helion-ui-select>
            <helion-ui-icon
              class="remove-icon cursor-pointer"
              data-feather="x-circle"
              (click)="removeEmail(contact.Person, index)">
            </helion-ui-icon>
          </div>
          <helion-ui-error
            [hidden]="email?.valid || (email?.pristine && email?.touched)"
            [message]="'COMMON.VALIDATION.INVALID_EMAIL_ADDRESS' | translate">
          </helion-ui-error>
        </div>
      </div>

      <div
        fxLayout="column"
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 16px)"
        fxLayoutGap="24px"
        class="contact-form__block">
        <div class="margin-top-0 zip-input">
          <helion-ui-input
            placeholder="{{ 'ADDRESS.ZIP' | translate }}"
            type="text"
            name="zip"
            [maxlength]="requiresStrictValidation ? 10 : 30"
            [required]="isObjectContact"
            [(ngModel)]="contact.Person.ZipCode">
          </helion-ui-input>
        </div>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CITY' | translate }}"
          type="text"
          name="city"
          [maxlength]="30"
          [required]="isObjectContact"
          [(ngModel)]="contact.Person.City">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CARE_OF_NAME' | translate }}"
          type="text"
          name="careOfName"
          maxlength="40"
          [(ngModel)]="contact.Person.CareOfName">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.POST_OFFICE_BOX' | translate }}"
          type="text"
          name="postOfficeBox"
          maxlength="30"
          [(ngModel)]="contact.Person.PostOfficeBox">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.ADDITIONAL_COMPANY' | translate }}"
          type="text"
          name="additionalCompanyName"
          [maxlength]="100"
          [(ngModel)]="contact.Company.AdditionalName">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.THIRD_PARTY_CUSTOMER_NUMBER' | translate }}"
          type="text"
          name="thirdPartyCustomerNumber"
          [maxlength]="50"
          [(ngModel)]="contact.Person.ThirdPartyCustomerNumber"
          data-cy="thirdPartyCustomerNumber">
        </helion-ui-input>

        <div
          fxLayout="column"
          fxLayoutGap="8px"
          *ngFor="let additionalPhone of contact.Person.Phones; let index = index">
          <div fxLayout="row" fxLayoutGap="10px" class="margin-top-0 additional-content-list">
            <helion-ui-input
              fxFlex="50"
              placeholder="{{
                (additionalPhone.PhoneType === phoneTypeEnum.Primary
                  ? 'ADDRESS.PRIMARY_PHONE'
                  : 'ADDRESS.ADDITIONAL_PHONE'
                ) | translate
              }}"
              [maxlength]="100"
              name="additionalPhone{{ index }}"
              [(ngModel)]="additionalPhone.PhoneNumber">
            </helion-ui-input>
            <helion-ui-select fxFlex="100" name="phone" [ngModel]="additionalPhone.PhoneType">
              <helion-ui-option
                *ngFor="let phoneType of phoneTypes"
                [value]="phoneType.Key"
                [label]="phoneType.Translation"
                (click)="setPhoneType(index, phoneType)">
              </helion-ui-option>
            </helion-ui-select>
            <helion-ui-icon
              class="remove-icon cursor-pointer"
              data-feather="x-circle"
              (click)="removePhone(contact.Person, index)">
            </helion-ui-icon>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="100" fxLayout="row" class="margin-top-2">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)" class="contact-form__block">
        <div fxLayout="column">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.NOTES' | translate }}</h4>
          <textarea
            fxFlex="100"
            class="textarea"
            placeholder="{{ 'ADDRESS.TEXT' | translate }}"
            rows="8"
            cols="40"
            [maxlength]="2000"
            name="notes"
            [(ngModel)]="contact.Person.Notes">
          </textarea>
        </div>
      </div>
      <div
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 16px)"
        class="contact-form__block"
        fxLayoutGap="20px">
        <pc-select-communication-type
          name="preferredCommunicationType"
          [(ngModel)]="contact.Person.PreferredCommunicationType">
        </pc-select-communication-type>
        <div *ngIf="logoUrl" fxLayout="column" class="contact-form__image">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.LOGO' | translate }}</h4>
          <img [src]="sanitizer.bypassSecurityTrustUrl(logoUrl)" class="img" alt="contact-image" />
          <button
            class="contact-form__btn-delete"
            [attr.fill-type]="'icon'"
            [attr.aria-label]="'COMMON.ACTION.DELETE' | translate"
            [tooltip]="'COMMON.ACTION.DELETE' | translate"
            (click)="logoDelete()">
            <helion-ui-icon data-feather="trash"></helion-ui-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="action-buttons-to-right">
  <div>
    <button
      data-cy="saveNewAddress"
      class="new-person-button"
      [attr.fill-type]="'contained'"
      [disabled]="
        (!singleContactForm.form.valid || !singleContactForm.form.touched) &&
        !isLogoChanged &&
        !addressTypeChanged
      "
      (click)="save()">
      {{ 'COMMON.BTN_SAVE' | translate }}
    </button>
  </div>
</div>
