import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateLeadName',
})
export class TruncateLeadNamePipe implements PipeTransform {
  public transform(value: string): string {
    if (value?.length > 20) {
      const commaIndex = value.indexOf(',');
      if (commaIndex > 0) {
        return value.slice(0, commaIndex);
      }
    }

    return value;
  }
}
