import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LicenseInfo, LicensesService } from '../../apis/advis';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as LicensesActions from './licenses.action';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class LicensesEffects {
  constructor(
    private actions$: Actions,
    private licensesService: LicensesService,
    private config: ConfigService
  ) {}

  getTextEditorLicense$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LicensesActions.GET_TEXT_EDITOR_LICENSE),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() =>
        this.licensesService.licenseKeyGetTextEditorLicenseKey().pipe(
          map(
            (payload: LicenseInfo) => new LicensesActions.GetTextEditorLicenseSuccessAction(payload)
          ),
          catchError((error: any) =>
            of(new LicensesActions.GetTextEditorLicenseFailureAction(error))
          )
        )
      )
    )
  );
}
