import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  MasterDataUserViewModel,
  MasterDataViewModel,
  RelatedLeadViewModel,
} from '../../apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import { LeadUtil } from '../../utils/lead.util';

export enum LeadSiblingsNotificationTypeEnum {
  SAME_CONTACT,
  SAME_COMPANY,
  SAME_OBJECT,
  SAME_GROUP,
}

export enum ActiveSiblingsE {
  SAME_MANDATORS,
  OTHER_MANDATORS,
}

@Component({
  selector: 'pc-lead-siblings-notification',
  templateUrl: './lead-siblings-notification.component.html',
  styleUrls: ['./lead-siblings-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadSiblingsNotificationComponent implements OnInit, OnChanges {
  @Input() public notificationType: LeadSiblingsNotificationTypeEnum =
    LeadSiblingsNotificationTypeEnum.SAME_CONTACT;
  @Input() public leadId: number;
  @Input() public sameMandatorSibling: RelatedLeadViewModel[] = [];
  @Input() public otherMandatorSiblings: RelatedLeadViewModel[] = [];
  @Input() public userMap: Map<string, MasterDataUserViewModel> = new Map<
    string,
    MasterDataUserViewModel
  >();
  @Input() public showCounter: boolean = true;
  @Input() public disableSiblingSelect: boolean = false;
  @Input() public showMandantName: boolean = false;
  @Input() public masterData: MasterDataViewModel;
  // Needs to be named differently than UI lib directive tooltip
  @Input() public intTooltip: string;

  public activeSiblingsEnum: typeof ActiveSiblingsE = ActiveSiblingsE;
  public activeSiblings: ActiveSiblingsE;

  public iconName: string;
  public showPersonAddress: boolean;
  public showObjectAddress: boolean;

  public notificationTypes: typeof LeadSiblingsNotificationTypeEnum =
    LeadSiblingsNotificationTypeEnum;

  public ngOnInit(): void {
    this.sameMandatorSibling = cloneDeep(this.sameMandatorSibling);

    if (this.sameMandatorSibling) {
      this.sameMandatorSibling.sort((s1: RelatedLeadViewModel, s2: RelatedLeadViewModel) => {
        if (s1.TradeTypeCode && s2.TradeTypeCode) {
          return s1.TradeTypeCode.toLowerCase().localeCompare(s2.TradeTypeCode.toLowerCase());
        }
        return 0;
      });
    }

    this.otherMandatorSiblings = cloneDeep(this.otherMandatorSiblings);

    if (this.otherMandatorSiblings) {
      this.otherMandatorSiblings.sort((s1: RelatedLeadViewModel, s2: RelatedLeadViewModel) => {
        if (s1.TradeTypeCode && s2.TradeTypeCode) {
          return s1.TradeTypeCode.toLowerCase().localeCompare(s2.TradeTypeCode.toLowerCase());
        }
        return 0;
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.notificationType) {
      if (this.checkSameContact()) {
        this.iconName = 'users';
      } else if (this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_COMPANY) {
        this.iconName = 'briefcase';
      } else if (this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_OBJECT) {
        this.iconName = 'home';
      } else if (this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_GROUP) {
        this.iconName = 'target';
      } else {
        this.iconName = undefined;
      }

      this.showPersonAddress =
        this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_COMPANY ||
        this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_OBJECT;
      this.showObjectAddress = this.checkSameContact();
    }
  }

  public setInitialActiveGroup(): void {
    this.activeSiblings = this.hasSameMandatorSiblings()
      ? ActiveSiblingsE.SAME_MANDATORS
      : ActiveSiblingsE.OTHER_MANDATORS;
  }

  public trackByLead(_: number, item: RelatedLeadViewModel): number {
    return item.LeadId;
  }

  public onClickNavList(event: Event): void {
    if (this.disableSiblingSelect) {
      event.stopPropagation();
    }
  }

  public getOwner(relatedLeadViewModel: RelatedLeadViewModel): string {
    return LeadUtil.buildOwnerName(relatedLeadViewModel);
  }

  public hasSameMandatorSiblings(): boolean {
    return this.sameMandatorSibling.length > 0;
  }

  public onToggleMandatorType(activeSiblings: ActiveSiblingsE): void {
    this.activeSiblings = activeSiblings;
  }

  private checkSameContact(): boolean {
    return this.notificationType === LeadSiblingsNotificationTypeEnum.SAME_CONTACT;
  }
}
