import { Component, Input } from '@angular/core';
import { LeadRegionalResponsibilityViewModel } from '../../../../shared/apis/advis';

@Component({
  selector: 'pc-trade-specifish-reginal-information[tradeTypeRegionalResponsibilities][isMobile]',
  templateUrl: './trade-specifish-reginal-information.component.html',
  styleUrls: ['./trade-specifish-reginal-information.component.scss'],
})
export class TradeSpecifishReginalInformationComponent {
  @Input() public tradeTypeRegionalResponsibilities: LeadRegionalResponsibilityViewModel;
  @Input() public isMobile: boolean;
}
