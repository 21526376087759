/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { NoteTemplateViewModel, NoteViewModel } from '../../apis/advis';

export const GET_NOTE_TEMPLATES: string = '[Lead Notes] Get all note templates';
export const GET_NOTE_TEMPLATES_SUCCESS: string = '[Lead Notes] Get note templates success';
export const GET_NOTE_TEMPLATES_FAILED: string = '[Lead Notes] Get note templates failed';

export const GET_ALL_NOTES: string = '[Lead Notes] Get all notes';
export const GET_ALL_NOTES_SUCCESS: string = '[Lead Notes] Get all notes success';
export const GET_ALL_NOTES_FAILED: string = '[Lead Notes] Get all notes failed';

export const GET_NOTE: string = '[Lead Notes] Get note';
export const GET_NOTE_SUCCESS: string = '[Lead Notes] Get note success';
export const GET_NOTE_FAILED: string = '[Lead Notes] Get note failed';

export const GET_PM_INFO_NOTE: string = '[Lead Notes] Get PM info note';
export const GET_PM_INFO_NOTE_SUCCESS: string = '[Lead Notes] Get PM info note success';
export const GET_PM_INFO_NOTE_FAILED: string = '[Lead Notes] Get PM info note failed';

export const ADD_TEXT_NOTE: string = '[Lead Notes] Add Text Note';
export const ADD_TEXT_NOTE_SUCCESS: string = '[Lead Notes] Add Text Note Success';
export const ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION: string =
  '[Lead Notes] Add Text Note Success without navigation';
export const ADD_TEXT_NOTE_FAILURE: string = '[Lead Notes] Add Text Note Failure';

export const UPDATE_TEXT_NOTE: string = '[Lead Notes] Update Text Note';
export const UPDATE_TEXT_NOTE_SUCCESS: string = '[Lead Notes] Update Text Note Success';
export const UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION: string =
  '[Lead Notes] Update Text Note Success without navigation';
export const UPDATE_TEXT_NOTE_FAILURE: string = '[Lead Notes] Update Text Note Failure';

export const ADD_PM_INFO_TEXT_NOTE: string = '[Lead Notes] Add PM info Text Note';
export const ADD_PM_INFO_TEXT_NOTE_SUCCESS: string = '[Lead Notes] Add PM info Text Note Success';
export const ADD_PM_INFO_TEXT_NOTE_FAILURE: string = '[Lead Notes] Add PM info Text Note Failure';

export const UPDATE_PM_INFO_TEXT_NOTE: string = '[Lead Notes] Update PM info Text Note';
export const UPDATE_PM_INFO_TEXT_NOTE_SUCCESS: string =
  '[Lead Notes] Update PM info Text Note Success';
export const UPDATE_PM_INFO_TEXT_NOTE_FAILURE: string =
  '[Lead Notes] Update PM info Text Note Failure';

export const ADD_DRAWING_NOTE: string = '[Lead Notes] Add Drawing Note';
export const ADD_DRAWING_NOTE_SUCCESS: string = '[Lead Notes] Add Drawing Note Success';
export const ADD_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION: string =
  '[Lead Notes] Add Drawing Note Success  without navigation';
export const ADD_DRAWING_NOTE_FAILURE: string = '[Lead Notes] Add Drawing Note Failure';

export const UPDATE_DRAWING_NOTE: string = '[Lead Notes] Update Drawing Note';
export const UPDATE_DRAWING_NOTE_SUCCESS: string = '[Lead Notes] Update Drawing Note Success';
export const UPDATE_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION: string =
  '[Lead Notes] Update Drawing Note Success  without navigation';
export const UPDATE_DRAWING_NOTE_FAILURE: string = '[Lead Notes] Update Drawing Note Failure';

export const UPDATE_NOTE_TAGS: string = '[Lead Notes] Update Note Text';

export const REMOVE_NOTE: string = '[Lead Notes] Remove Note';
export const REMOVE_NOTE_SUCCESS: string = '[Lead Notes] Remove Note Success';
export const REMOVE_NOTE_FAILURE: string = '[Lead Notes] Remove Note Failure';

export class GetNoteTemplatesAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES;

  constructor(public payload: number) {}
}

export class GetNoteTemplatesSuccessAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES_SUCCESS;

  constructor(public payload: NoteTemplateViewModel[]) {}
}

export class GetNoteTemplatesFailedAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES_FAILED;

  constructor(public payload: any) {}
}

export interface IGetAllNotes {
  leadId: number;
  useForceAllNotesLoad?: boolean;
}

export class GetAllNotesAction implements Action {
  readonly type: string = GET_ALL_NOTES;

  constructor(public payload: IGetAllNotes) {}
}

export class GetAllNotesFailedAction implements Action {
  readonly type: string = GET_ALL_NOTES_FAILED;

  constructor(public payload: any) {}
}

export interface IGetNotesSuccess {
  notes: NoteViewModel[];
  leadId: number;
}

export class GetAllNotesSuccessAction implements Action {
  readonly type: string = GET_ALL_NOTES_SUCCESS;

  constructor(public payload: IGetNotesSuccess) {}
}

export interface IGetNote {
  leadId: number;
  noteId: number;
  noteType: string;
}

// Get Single Note
export class GetNoteAction implements Action {
  readonly type: string = GET_NOTE;

  constructor(public payload: IGetNote) {}
}

export class GetNoteFailedAction implements Action {
  readonly type: string = GET_NOTE_FAILED;

  constructor(public payload: any) {}
}

export class GetNoteSuccessAction implements Action {
  readonly type: string = GET_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class GetPmInfoNoteAction implements Action {
  readonly type: string = GET_PM_INFO_NOTE;

  constructor(public payload: number) {}
}

export class GetPmInfoNoteFailedAction implements Action {
  readonly type: string = GET_PM_INFO_NOTE_FAILED;

  constructor(public payload: any) {}
}

export class GetPmInfoNoteSuccessAction implements Action {
  readonly type: string = GET_PM_INFO_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

// Add Text Note
export interface ITextNote {
  note: NoteViewModel;
  doNotNavigationBack?: boolean;
}

export class AddTextNoteAction implements Action {
  readonly type: string = ADD_TEXT_NOTE;

  constructor(public payload: ITextNote) {}
}

export class AddTextNoteSuccessAction implements Action {
  readonly type: string = ADD_TEXT_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class AddTextNoteSuccessWithoutNavigationAction implements Action {
  readonly type: string = ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION;

  constructor(public payload: NoteViewModel) {}
}

export class AddTextNoteFailureAction implements Action {
  readonly type: string = ADD_TEXT_NOTE_FAILURE;

  constructor(public payload: any) {}
}

export class AddPmInfoTextNoteAction implements Action {
  readonly type: string = ADD_PM_INFO_TEXT_NOTE;

  constructor(public payload: ITextNote) {}
}

export class AddPmInfoTextNoteSuccessAction implements Action {
  readonly type: string = ADD_PM_INFO_TEXT_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class AddPmInfoTextNoteFailureAction implements Action {
  readonly type: string = ADD_PM_INFO_TEXT_NOTE_FAILURE;

  constructor(public payload: any) {}
}

// Update Text Note
export class UpdateTextNoteAction implements Action {
  readonly type: string = UPDATE_TEXT_NOTE;

  constructor(public payload: ITextNote) {}
}

export class UpdateTextNoteSuccessAction implements Action {
  readonly type: string = UPDATE_TEXT_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class UpdateTextNoteSuccessWithoutNavigationAction implements Action {
  readonly type: string = UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION;

  constructor(public payload: NoteViewModel) {}
}

export class UpdateTextNoteFailureAction implements Action {
  readonly type: string = UPDATE_TEXT_NOTE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdatePmInfoTextNoteAction implements Action {
  readonly type: string = UPDATE_PM_INFO_TEXT_NOTE;

  constructor(public payload: ITextNote) {}
}

export class UpdatePmInfoTextNoteSuccessAction implements Action {
  readonly type: string = UPDATE_PM_INFO_TEXT_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class UpdatePmInfoTextNoteFailureAction implements Action {
  readonly type: string = UPDATE_PM_INFO_TEXT_NOTE_FAILURE;

  constructor(public payload: any) {}
}

export interface IDrawingNote {
  noteData: NoteViewModel;
  drawingImageBase64?: any;
  drawingImage?: Blob;
  doNotNavigationBack?: boolean;
}

// Add Drawing Note
export class AddDrawingNoteAction implements Action {
  readonly type: string = ADD_DRAWING_NOTE;

  constructor(public payload: IDrawingNote) {}
}

export class AddDrawingNoteSuccessAction implements Action {
  readonly type: string = ADD_DRAWING_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class AddDrawingNoteSuccessWithoutNavigationAction implements Action {
  readonly type: string = ADD_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION;

  constructor(public payload: NoteViewModel) {}
}

export class AddDrawingNoteFailureAction implements Action {
  readonly type: string = ADD_DRAWING_NOTE_FAILURE;

  constructor(public payload: any) {}
}

// Update Drawing Note
export class UpdateDrawingNoteAction implements Action {
  readonly type: string = UPDATE_DRAWING_NOTE;

  constructor(public payload: IDrawingNote) {}
}

export class UpdateDrawingNoteSuccessAction implements Action {
  readonly type: string = UPDATE_DRAWING_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class UpdateDrawingNoteSuccessWithoutNavigationAction implements Action {
  readonly type: string = UPDATE_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION;

  constructor(public payload: NoteViewModel) {}
}

export class UpdateDrawingNoteFailureAction implements Action {
  readonly type: string = UPDATE_DRAWING_NOTE_FAILURE;

  constructor(public payload: any) {}
}

// Delete Note
export class RemoveNoteAction implements Action {
  readonly type: string = REMOVE_NOTE;

  constructor(public payload: NoteViewModel) {}
}

export class RemoveNoteSuccessAction implements Action {
  readonly type: string = REMOVE_NOTE_SUCCESS;

  constructor(public payload: NoteViewModel) {}
}

export class RemoveNoteFailureAction implements Action {
  readonly type: string = REMOVE_NOTE_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateNoteTags implements Action {
  readonly type: string = UPDATE_NOTE_TAGS;

  constructor(public payload: boolean) {}
}

export type Actions =
  | GetNoteTemplatesAction
  | GetNoteTemplatesSuccessAction
  | GetNoteTemplatesFailedAction
  | GetAllNotesAction
  | GetAllNotesFailedAction
  | GetAllNotesSuccessAction
  | GetNoteAction
  | GetNoteFailedAction
  | GetNoteSuccessAction
  | AddTextNoteAction
  | AddTextNoteSuccessAction
  | AddTextNoteFailureAction
  | UpdateTextNoteAction
  | UpdateTextNoteSuccessAction
  | UpdateTextNoteFailureAction
  | AddDrawingNoteAction
  | AddDrawingNoteSuccessAction
  | AddDrawingNoteFailureAction
  | UpdateDrawingNoteAction
  | UpdateDrawingNoteSuccessAction
  | UpdateDrawingNoteFailureAction
  | RemoveNoteAction
  | RemoveNoteSuccessAction
  | RemoveNoteFailureAction;
