/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PvCustomModuleViewModel } from './pvCustomModuleViewModel';


/**
 * This model is used for both putting and receiving data for the wizard in the PC
 */
export interface PvWizardViewModel { 
    BuildingLevels?: number;
    RoofSlope?: string;
    ScaffoldingType?: string;
    EnergyConsumptionHt?: number;
    EnergyConsumptionLt?: number;
    ElectricityRateHt?: number;
    ElectricityRateLt?: number;
    OwnConsumptionRatioWithoutStorage?: number;
    OwnConsumptionRatioWithStorage?: number;
    FeedInTarifHt?: number;
    FeedInTarifLt?: number;
    RoofType: string;
    RoofCoverType: string;
    CoverOrientation?: string;
    MountingType?: string;
    ModuleId?: string;
    NumberOfModules?: number;
    TotalNumberOfBlindModules?: number;
    ModuleOrientation?: string;
    KwhYieldPerKwp?: number;
    MunicipalSubsidies?: number;
    FinancingType?: PvWizardViewModel.FinancingTypeEnum;
    StorageId?: string;
    InverterId?: string;
    SelectedInverterId?: string;
    ProposedInverterId?: string;
    ControllableSmartEnergyDevices?: string;
    AdditionalSmartEnergyDevices?: string;
    ShadingOptimizerId?: string;
    SnowProtection?: string;
    FallProtection?: string;
    SurgeProtection?: string;
    OwnConsumptionCommunity?: string;
    BuildingAccessibility?: string;
    MaintenanceContract?: string;
    MonitoringContract?: string;
    GsmInternetGateway?: string;
    IsNewDistributionBoard?: string;
    /**
     * This property is readonly and cannot be set here  Use the \"patch variant trade-specific\" endpoint to set the override
     */
    TaxSaving?: number;
    /**
     * This property is readonly and cannot be set here  Use the \"patch variant trade-specific\" endpoint to set the override
     */
    TotalKwp?: number;
    /**
     * This property is readonly and cannot be set here  Use the \"patch variant trade-specific\" endpoint to set the override
     */
    CoveredRoofArea?: number;
    /**
     * This property is readonly and cannot be set here  Use the \"patch variant trade-specific\" endpoint to set the override
     */
    KwhProducedPerYear?: number;
    /**
     * This property is readonly and cannot be set here  Use the \"patch variant trade-specific\" endpoint to set the override
     */
    EivOneOffPayment?: number;
    /**
     * A base64 encoded image
     */
    LayoutAugmentedImage?: string;
    SolarPlannerDataFilename?: string;
    FeedInTariffY?: number;
    FeasibilitySolarTarIfNewCustomers?: string;
    SmartMeterRollOut?: number;
    CustomModule?: PvCustomModuleViewModel;
    ModuleWattPeak?: number;
}
export namespace PvWizardViewModel {
    export type FinancingTypeEnum = 'None' | 'SubsidyPrefinancing' | 'Installments';
    export const FinancingTypeEnum = {
        None: 'None' as FinancingTypeEnum,
        SubsidyPrefinancing: 'SubsidyPrefinancing' as FinancingTypeEnum,
        Installments: 'Installments' as FinancingTypeEnum
    };
}
