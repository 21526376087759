<helion-ui-select
  *ngIf="complexityTypes"
  placeholder="{{ 'LEAD_PERSON.COMPLEXITY_TYPE_PLACEHOLDER' | translate }}"
  name="complexityType"
  [ngModel]="selectedComplexityType"
  (ngModelChange)="setSelectedComplexityType($event)">
  <helion-ui-option
    *ngFor="let complexityType of complexityTypes"
    [value]="complexityType"
    [label]="complexityType">
  </helion-ui-option>
</helion-ui-select>
