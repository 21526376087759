import { Action } from '@ngrx/store';
import {
  CurrentUserViewModel,
  FileLinkViewModel,
  NoteTemplateViewModel,
  PutUserRequest,
  ResetPasswordRequest,
  SaveNoteTemplateRequest,
  UserInfo,
} from '../../apis/advis';

export const GET_USER_INFO: string = '[User] Get user info';
export const GET_USER_INFO_SUCCESS: string = '[User] Get user info success';
export const GET_USER_INFO_FAILED: string = '[User] Get user info failed';
export const GET_USER: string = '[User] Get user';
export const GET_USER_SUCCESS: string = '[User] Get user success';
export const GET_USER_FAILED: string = '[User] Get user failed';
export const PATCH_USER: string = '[User] Patch user';
export const PATCH_USER_SUCCESS: string = '[User] Patch user success';
export const PATCH_USER_FAILED: string = '[User] Patch user failed';
export const GET_USER_FILES: string = '[User] Get user files';
export const GET_USER_FILES_SUCCESS: string = '[User] Get user files success';
export const GET_USER_FILES_FAILED: string = '[User] Get user files failed';
export const PUT_USER_PASSWORD: string = '[User] Patch user password';
export const PUT_USER_PASSWORD_SUCCESS: string = '[User] Patch user password success';
export const PUT_USER_PASSWORD_FAILED: string = '[User] Patch user password failed';
export const SAVE_NOTE_TEMPLATES: string = '[User] Save note templates';
export const SAVE_NOTE_TEMPLATES_SUCCESS: string = '[User] Save note template success';
export const SAVE_NOTE_TEMPLATES_FAILED: string = '[User] Save note template failed';
export const GET_NOTE_TEMPLATES: string = '[User] Get note template';
export const GET_NOTE_TEMPLATES_SUCCESS: string = '[User] Get note templates success';
export const GET_NOTE_TEMPLATES_FAILED: string = '[User] Get note templates failed';

export class GetUserInfoAction implements Action {
  readonly type: string = GET_USER_INFO;

  constructor(public payload: any = undefined) {}
}

export class GetUserInfoSuccessAction implements Action {
  readonly type: string = GET_USER_INFO_SUCCESS;

  constructor(public payload: UserInfo) {}
}

export class GetUserInfoFailedAction implements Action {
  readonly type: string = GET_USER_INFO_FAILED;

  constructor(public payload: any) {}
}

export class GetUserAction implements Action {
  readonly type: string = GET_USER;

  constructor(public payload: any = undefined) {}
}

export class GetUserSuccessAction implements Action {
  readonly type: string = GET_USER_SUCCESS;

  constructor(public payload: CurrentUserViewModel) {}
}

export class GetUserFailedAction implements Action {
  readonly type: string = GET_USER_FAILED;

  constructor(public payload: any) {}
}

export class GetUserFilesSuccessAction implements Action {
  readonly type: string = GET_USER_FILES_SUCCESS;

  constructor(public payload: FileLinkViewModel[]) {}
}

export class GetUserFilesFailedAction implements Action {
  readonly type: string = GET_USER_FILES_FAILED;

  constructor(public payload: any) {}
}

export interface IUpdateUser {
  userData: PutUserRequest;
  picture: IFileData;
  signature: IFileData;
}

export interface IFileData {
  file: any;
  filename: string;
  deleted: boolean;
}

export class PatchUserAction implements Action {
  readonly type: string = PATCH_USER;

  constructor(public payload: IUpdateUser) {}
}

export class PatchUserSuccessAction implements Action {
  readonly type: string = PATCH_USER_SUCCESS;

  constructor(public payload: CurrentUserViewModel) {}
}

export class PatchUserFailedAction implements Action {
  readonly type: string = PATCH_USER_FAILED;

  constructor(public payload: any) {}
}

export class PutUserPasswordAction implements Action {
  readonly type: string = PUT_USER_PASSWORD;

  constructor(public payload: ResetPasswordRequest) {}
}

export class PutUserPasswordSuccessAction implements Action {
  readonly type: string = PUT_USER_PASSWORD_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class PutUserPasswordFailedAction implements Action {
  readonly type: string = PUT_USER_PASSWORD_FAILED;

  constructor(public payload: any) {}
}

export class SaveNoteTemplateAction implements Action {
  readonly type: string = SAVE_NOTE_TEMPLATES;

  constructor(public payload: SaveNoteTemplateRequest) {}
}

export class SaveNoteTemplateSuccessAction implements Action {
  readonly type: string = SAVE_NOTE_TEMPLATES_SUCCESS;

  constructor(public payload: NoteTemplateViewModel[]) {}
}

export class SaveNoteTemplateFailedAction implements Action {
  readonly type: string = SAVE_NOTE_TEMPLATES_FAILED;

  constructor(public payload: any) {}
}

export class GetNoteTemplatesAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES;
}

export class GetNoteTemplatesSuccessAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES_SUCCESS;

  constructor(public payload: NoteTemplateViewModel[]) {}
}

export class GetNoteTemplatesFailedAction implements Action {
  readonly type: string = GET_NOTE_TEMPLATES_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | GetUserInfoAction
  | GetUserInfoSuccessAction
  | GetUserInfoFailedAction
  | GetUserAction
  | GetUserSuccessAction
  | GetUserFailedAction
  | PutUserPasswordAction
  | PutUserPasswordSuccessAction
  | PutUserPasswordFailedAction;
