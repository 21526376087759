import { CustomOption, QuillModules } from 'ngx-quill';
import { range } from 'lodash';

// https://github.com/KillerCodeMonkey/ngx-quill/issues/181

const fontSizeArr: string[] = [
  ...range(6, 32).map(value => `${value}px`),
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

export const NGX_QUILL_CUSTOM_OPTIONS: CustomOption[] = [
  {
    import: 'attributors/style/size',
    whitelist: fontSizeArr,
  },
];
export const NGX_QUILL_MODULES: QuillModules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline'],
      [{ size: fontSizeArr }],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['undo', 'redo'],
    ],
    handlers: {
      undo: function () {
        this.quill.history.undo();
      },
      redo: function () {
        this.quill.history.redo();
      },
    },
  },
  clipboard: {
    matchVisual: false,
  },
  history: { delay: 200, userOnly: true },
};

export function resizeBase64(base64: string, maxWidth: number, maxHeight: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const canvasCopy = document.createElement('canvas');
    const copyContext = canvasCopy.getContext('2d');

    const img = window.document.createElement('img');
    img.src = base64;
    img.onerror = reject;
    img.onload = () => {
      let ratio = 1;
      if (img.width > maxWidth) {
        ratio = maxWidth / img.width;
      } else if (img.height > maxHeight) {
        ratio = maxHeight / img.height;
      }

      canvasCopy.width = img.width;
      canvasCopy.height = img.height;
      copyContext.drawImage(img, 0, 0);

      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;
      ctx.drawImage(
        canvasCopy,
        0,
        0,
        canvasCopy.width,
        canvasCopy.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      resolve(canvas.toDataURL());
    };
  });
}
