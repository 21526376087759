<section class="dialog">
  <div class="dialog-content support-dialog" fxLayout="row">
    <div class="support-option-container">
      <div>
        <div class="support-option-title">
          {{ 'SUPPORT_DIALOG.PRODUCT_SUPPORT.TITLE' | translate }}
        </div>
        <ul class="support-option-list">
          <li *ngFor="let reason of productSupportReasons">{{ reason | translate }}</li>
        </ul>
      </div>
      <button (click)="onProductSupportClick()">
        <helion-ui-icon data-feather="message-square"></helion-ui-icon>
        {{ 'COMMON.SUPPORT_EMAIL_LABEL' | translate }}
      </button>
    </div>
    <div class="support-option-container">
      <div>
        <div class="support-option-title">
          {{ 'SUPPORT_DIALOG.TECHNICAL_SUPPORT.TITLE' | translate }}
        </div>
        <ul class="support-option-list">
          <li *ngFor="let reason of technicalSupportReasons">{{ reason | translate }}</li>
        </ul>
      </div>
      <button (click)="onTechnicalSupportClick()">
        <helion-ui-icon data-feather="message-square"></helion-ui-icon>
        {{ 'COMMON.SUPPORT_EMAIL_LABEL' | translate }}
      </button>
    </div>
  </div>
</section>
