<section class="dialog">
  <h2 class="dialog-title">{{ title }}</h2>

  <div class="dialog-content" *ngIf="!selectablePersonsDisabled">
    <div
      class="select-wrapper"
      *ngFor="let person of selectablePersons"
      (click)="onChangeSelection(person.id)">
      <helion-ui-radio-button-item
        [checked]="person.isSelected"
        [label]="person.displayText"
        (valueChange)="$event && onChangeSelection(person.id)">
      </helion-ui-radio-button-item>
    </div>
  </div>

  <div
    class="dialog-content address-dialog-content"
    style="box-shadow: none !important; margin-top: 30px !important">
    {{ 'ADDRESS.DIALOGS.SELECT_PERSON.SEARCH_PERSON' | translate }}
    <pc-contact-selector
      [selectType]="selectType"
      [mandator]="mandator"
      [contactType]="contactType"
      [detailSearchEnabled]="false"
      [companyId]="companyId"
      [excludePersonIds]="excludeIds"
      (onSelectedContactId)="selectExistPerson($event)">
    </pc-contact-selector>
  </div>

  <div class="dialog-actions">
    <button [attr.fill-type]="'contained'" (click)="onOkClick()">
      {{ 'COMMON.BTN_OK' | translate }}
    </button>
    <button (click)="onCloseClick()">{{ 'COMMON.BTN_CLOSE' | translate }}</button>
  </div>
</section>
