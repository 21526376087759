/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { RemoveMandatorFromCacheRequest } from '../model/removeMandatorFromCacheRequest';
import { RemoveUserFromCacheRequest } from '../model/removeUserFromCacheRequest';
import { UserViewModel } from '../model/userViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Removes mandator from cache.
     * 
     * @param apiKey The API key.
     * @param body The request.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mandatorCacheManagementRemoveMandatorFromCache(apiKey: string, body?: RemoveMandatorFromCacheRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mandatorCacheManagementRemoveMandatorFromCache(apiKey: string, body?: RemoveMandatorFromCacheRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mandatorCacheManagementRemoveMandatorFromCache(apiKey: string, body?: RemoveMandatorFromCacheRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mandatorCacheManagementRemoveMandatorFromCache(apiKey: string, body?: RemoveMandatorFromCacheRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling mandatorCacheManagementRemoveMandatorFromCache.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/mandator/remove-mandator-from-cache/${encodeURIComponent(String(apiKey))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes role from cache.
     * 
     * @param apiKey The API key.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public roleCacheManagementRemoveRoleFromCache(apiKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public roleCacheManagementRemoveRoleFromCache(apiKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public roleCacheManagementRemoveRoleFromCache(apiKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public roleCacheManagementRemoveRoleFromCache(apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling roleCacheManagementRemoveRoleFromCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/roles/remove-roles-from-cache/${encodeURIComponent(String(apiKey))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves an active user list that matches the client and the permissions
     * 
     * @param mandatorIds Mandator Ids can be given as comma-separated query arguments
     * @param permissions the permissions the users have to have, comma separated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetActiveUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserViewModel>>;
    public userGetActiveUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserViewModel>>>;
    public userGetActiveUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserViewModel>>>;
    public userGetActiveUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }
        if (permissions !== undefined && permissions !== null) {
            queryParameters = queryParameters.set('permissions', <any>permissions);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserViewModel>>(`${this.basePath}/api/pc/users/users-with-any-permission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a user list that matches the client and the permissions. This endpoint returns deactivated users.
     * 
     * @param mandatorIds Mandator Ids can be given as comma-separated query arguments
     * @param permissions the permissions the users have to have, comma separated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetAllUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserViewModel>>;
    public userGetAllUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserViewModel>>>;
    public userGetAllUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserViewModel>>>;
    public userGetAllUsersWithAnyPermission(mandatorIds?: string, permissions?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }
        if (permissions !== undefined && permissions !== null) {
            queryParameters = queryParameters.set('permissions', <any>permissions);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserViewModel>>(`${this.basePath}/api/pc/users/all-users-with-any-permission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves list of potential users whom the lead can be assigned to.
     * 
     * @param mandatorIds The ids of the mandators.
     * @param permissions the permissions the users have to have, comma separated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUsersWithPermissions(mandatorIds?: string, permissions?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserViewModel>>;
    public userGetUsersWithPermissions(mandatorIds?: string, permissions?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserViewModel>>>;
    public userGetUsersWithPermissions(mandatorIds?: string, permissions?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserViewModel>>>;
    public userGetUsersWithPermissions(mandatorIds?: string, permissions?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorIds !== undefined && mandatorIds !== null) {
            queryParameters = queryParameters.set('mandatorIds', <any>mandatorIds);
        }
        if (permissions !== undefined && permissions !== null) {
            queryParameters = queryParameters.set('permissions', <any>permissions);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserViewModel>>(`${this.basePath}/api/pc/users/assignment-candidates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes user from cache.
     * 
     * @param apiKey The API key.
     * @param body The remove user from cache request.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userRemoveUserFromCache(apiKey: string, body?: RemoveUserFromCacheRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userRemoveUserFromCache(apiKey: string, body?: RemoveUserFromCacheRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userRemoveUserFromCache(apiKey: string, body?: RemoveUserFromCacheRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userRemoveUserFromCache(apiKey: string, body?: RemoveUserFromCacheRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling userRemoveUserFromCache.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/users/remove-user-from-cache/${encodeURIComponent(String(apiKey))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
