<section class="dialog">
  <h2 class="dialog-title">{{ 'DIALOG.SELECT_NEXT_ADDRESS_STEP.TITLE' | translate }}</h2>
  <div class="dialog-content" data-cy="selectNextAddressStepDialog" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="32px">
      <button
        [attr.fill-type]="'contained'"
        (click)="onNextAddressStepSelect(createEditContactEnum.CREATE)">
        {{ 'DIALOG.SELECT_NEXT_ADDRESS_STEP.CREATE_CONTACT' | translate }}
      </button>
      <button
        [attr.fill-type]="'contained'"
        (click)="onNextAddressStepSelect(createEditContactEnum.EDIT)">
        {{ 'DIALOG.SELECT_NEXT_ADDRESS_STEP.EDIT_CONTACT' | translate }}
      </button>
      <button
        [attr.fill-type]="'contained'"
        (click)="onNextAddressStepSelect(createEditContactEnum.FINISH)">
        {{ 'DIALOG.SELECT_NEXT_ADDRESS_STEP.FINISH' | translate }}
      </button>
    </div>
  </div>
</section>
