import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoogleApiAccessService } from '../apis/advis';
import { mergeMap, share } from 'rxjs/operators';

export interface IPatchSpreadsheetRequest {
  minDbTeam?: string;
  minDbTradeType?: string;
  minDbChannel?: string;
  minDbkWp?: string;
  minDbStorage?: string;
}

export interface IPatchPreliminaryCostingRequest {
  Material?: IPreliminaryCostingValues;
  ForeignContribution?: IPreliminaryCostingValues;
  OwnContribution?: IPreliminaryCostingValues;
}

export interface IPreliminaryCostingValues {
  AugmentedPurchasePrice?: string;
  SellingPrice?: string;
}

export interface ISpreadsheetResult {
  targetDbEiv?: number;
  targetDb?: number;
  minDbEiv?: number;
  minDb?: number;
  ownCostsEIV?: number;
  ownCosts?: number;

  targetDbEivDigital?: number;
  targetDbDigital?: number;
  minDbEivDigital?: number;
  minDbDigital?: number;
  ownCostsEIVDigital?: number;
  ownCostsDigital?: number;
}

const SPREADSHEET_ID: string = '1IJqD_HY9Qmw5nce1nfKqr4lbdEwQ51jn2cvvJkzVCrE';

@Injectable()
export class GoogleSpreadsheetService {
  constructor(
    protected httpClient: HttpClient,
    private googleApiAccessService: GoogleApiAccessService
  ) {}

  getMinDbStorageTypesFromSpreadsheet(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/DB Speicher!A7:A60`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getMinDbTeamsFromSpreadsheet(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Listen & Berechnungen!C7:C13`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getMinDbCategoriesFromSpreadsheet(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Listen & Berechnungen!A7:A14`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getMinDbChanelsFromSpreadsheet(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Listen & Berechnungen!B7:B8`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getNlFromOu(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/OUNL_Mapping!A3:B35`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getMinDBPrices(): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .get<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/MinDB!C10:E15`,
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  setMinDBValues(
    spreadsheetRequest: IPatchSpreadsheetRequest,
    preliminaryCostingRequest: IPatchPreliminaryCostingRequest
  ): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .put<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/MinDB!C4:I8?valueInputOption=USER_ENTERED`,
            {
              values: [
                [
                  spreadsheetRequest.minDbTeam,
                  ,
                  ,
                  ,
                  ,
                  preliminaryCostingRequest.OwnContribution.AugmentedPurchasePrice,
                  preliminaryCostingRequest.OwnContribution.SellingPrice,
                ],
                [
                  spreadsheetRequest.minDbTradeType,
                  ,
                  ,
                  ,
                  ,
                  preliminaryCostingRequest.Material.AugmentedPurchasePrice,
                  preliminaryCostingRequest.Material.SellingPrice,
                ],
                [
                  spreadsheetRequest.minDbChannel,
                  ,
                  ,
                  ,
                  ,
                  preliminaryCostingRequest.ForeignContribution.AugmentedPurchasePrice,
                  preliminaryCostingRequest.ForeignContribution.SellingPrice,
                ],
                [spreadsheetRequest.minDbkWp],
                [spreadsheetRequest.minDbStorage],
              ],
            },
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  setMinDBValuesMinimal(spreadsheetRequest: IPatchSpreadsheetRequest): Observable<any> {
    return this.googleApiAccessService.googleApiAccessGetSpreadsheetAccessToken().pipe(
      mergeMap((token: string) => {
        return this.httpClient
          .put<any>(
            `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/MinDB!C4:C8?valueInputOption=USER_ENTERED`,
            {
              values: [
                [spreadsheetRequest.minDbTeam],
                [spreadsheetRequest.minDbTradeType],
                [spreadsheetRequest.minDbChannel],
                [spreadsheetRequest.minDbkWp],
                [spreadsheetRequest.minDbStorage],
              ],
            },
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
              }),
            }
          )
          .pipe(share());
      })
    );
  }

  getAccountAssignmentList(selectQuery: string): Observable<any> {
    return this.httpClient
      .get(
        `https://docs.google.com/spreadsheets/d/140o5WNxC0TvCk3YNYfegLGMiqUY-LHytRlb1ZIDbDPA/gviz/tq?tqx=out:json&tq=${selectQuery}`,
        { responseType: 'text' }
      )
      .pipe(share());
  }
}
