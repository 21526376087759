/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FieldServicePartsListPositionDto { 
    Amount?: number;
    ArticleNumber?: string;
    AlternativeArticleName?: string;
    SellingPrice?: number;
    TotalSellingPrice?: number;
    PositionRebate?: number;
    DynamicAssetId?: string;
    Status?: FieldServicePartsListPositionDto.StatusEnum;
    ReplacedBy?: string;
}
export namespace FieldServicePartsListPositionDto {
    export type StatusEnum = 'Warrant' | 'Goodwill' | 'Defect' | 'New' | 'Remove' | 'Active';
    export const StatusEnum = {
        Warrant: 'Warrant' as StatusEnum,
        Goodwill: 'Goodwill' as StatusEnum,
        Defect: 'Defect' as StatusEnum,
        New: 'New' as StatusEnum,
        Remove: 'Remove' as StatusEnum,
        Active: 'Active' as StatusEnum
    };
}
