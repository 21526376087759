import { Observable } from 'rxjs';
import { Logger } from '../services/logger.service';
import { LeadListViewModel, MasterDataMandatorViewModel } from '../apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from './isNullOrUndefined';

export class CommonUtil {
  private static readonly emailRegexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  public static createDateAndAddDays(daysToAdd: number): Date {
    const date: Date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date;
  }

  public static contains(searchString: string, filterString: string): boolean {
    return (
      !isNullOrUndefined(searchString) &&
      searchString.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
    );
  }

  public static containsPhoneNumber(str1: string, str2: string): boolean {
    if (str1 && str2) {
      const nr1: string = str1.replace(/[^0-9]/g, '');
      const nr2: string = str2.replace(/[^0-9]/g, '');

      return nr2.length === 0 ? false : CommonUtil.contains(nr1, nr2);
    }
    return false;
  }

  public static containsPhoneNumberOrEmpty(str1: string, str2: string): boolean {
    return (
      !str1 ||
      !str2 ||
      str1.length === 0 ||
      str2.length === 0 ||
      CommonUtil.containsPhoneNumber(str1, str2)
    );
  }

  public static containsOrEmpty(searchString: string, filterString: string): boolean {
    return filterString.length === 0 || CommonUtil.contains(searchString, filterString);
  }
  public static containsOrEmptyNumber(searchNumber: number, filterNumber: number): boolean {
    return isNullOrUndefined(filterNumber) || filterNumber === 0 || searchNumber === filterNumber;
  }

  public static containsNumberInArray(searchNumber: number, filterNumbers: number[]): boolean {
    if (isNullOrUndefined(searchNumber)) {
      return true;
    }

    return (
      isNullOrUndefined(filterNumbers) ||
      filterNumbers.length === 0 ||
      filterNumbers.includes(searchNumber)
    );
  }

  public static containsOrEmptyInList(searchString: string, filterString: string[]): boolean {
    return filterString.length === 0 || CommonUtil.contains(filterString.join(','), searchString);
  }

  public static filterOutLeadsWhichDoNotBelongToSelectedMandant(
    observable: Observable<LeadListViewModel[]>,
    selectedMandants: Set<number>
  ): Observable<LeadListViewModel[]> {
    return observable.pipe(
      map((leads: LeadListViewModel[]) => {
        if (isNullOrUndefined(leads)) {
          return [];
        } else {
          return leads.filter((leadViewModel: LeadListViewModel): boolean => {
            // In case of performance issues - change to map instead of array
            return selectedMandants.has(leadViewModel.MandantId);
          });
        }
      })
    );
  }

  public static getMandantShortcut(
    mandantId: number,
    mandantsMap: Map<number, MasterDataMandatorViewModel>,
    logger: Logger
  ): string {
    if (mandantsMap.has(mandantId)) {
      return mandantsMap.get(mandantId).Code.substr(0, 2).toUpperCase();
    }
    logger.error(`Unknown mandantId="${mandantId}"`);
    return CommonUtil.getNotAvailable();
  }

  public static isMandantEqual(shortcut: string, mandant: MasterDataMandatorViewModel): boolean {
    return shortcut.toLowerCase().localeCompare(mandant.Code.substr(0, 2).toLowerCase()) === 0;
  }

  public static getNotAvailable(): string {
    return 'n/a';
  }

  public static reloadPage(): void {
    console.info('Perform page reload');
    location.reload();
  }

  /**
   * Trim all string properties of the passed object
   * @param obj
   */
  public static trimAllStrings(obj: any): any {
    const clone: any = cloneDeep(obj);
    forOwn(clone, (value: any, key: any) => {
      if (typeof value === 'string') {
        if (!isNullOrUndefined(value)) {
          clone[key] = value.trim();
        }
      }
    });
    return clone;
  }

  public static trimAndLowerCase(str: string): string {
    if (isNullOrUndefined(str)) {
      return '';
    }
    return str.trim().toLowerCase();
  }

  public static isValidEmail(email: string): boolean {
    return this.emailRegexp.test(email);
  }
}
