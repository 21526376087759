import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { DevelopmentComponent } from './components/development/development.component';
import { ErrorComponent } from './components/error/error.component';
import { ViewSettingService } from './shared/services/view-setting.service';
import { AppMonitoringService } from './shared/services/app-monitoring.service';
import { AuthService } from './shared/services/auth.service';
import { EnumService } from './shared/services/enum.service';
import { ImageResizeService } from './shared/services/image-resize.service';
import { ConfigService } from './shared/services/config.service';
import { LoggerService } from './shared/services/logger.service';
import { RightsService } from './shared/services/rights.service';
import { GemeindeService } from 'app/shared/services/gemeinde.service';
import { UrlService } from './shared/services/url.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { AppInitializerGuard } from './shared/guards/app-initializer.guard';
import { BASE_PATH } from './shared/apis/advis';
import { DEFAULT_TIMEOUT, AppInterceptor } from './shared/interceptors/app.interceptor';
import { SignalRInterceptor } from './shared/interceptors/signalr.interceptor';
import { PersonLeadDetailLoadedResolver } from './shared/resolvers/person-lead-detail-loaded.resolver';
import { GlobalEffects } from './shared/states/global/global.effects';
import { NavigationEffects } from './shared/states/navigation/navigation.effects';
import { LeadEffects } from './shared/states/lead/lead.effect';
import { LeadNotesEffects } from './shared/states/lead-notes/lead-notes.effect';
import { ViewSettingEffects } from './shared/states/view-setting/view-setting.effect';
import { AuthEffects } from './shared/states/auth/auth.effects';
import { TaskEffects } from './shared/states/task/task.effects';
import { VariantEffects } from './shared/states/variant/variant.effect';
import { ActionsEffects } from './shared/states/actions/actions.effect';
import { HeatingEffects } from './shared/states/heating/heating.effect';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule, StoreDevtoolsOptions } from '@ngrx/store-devtools';
import * as RootReducer from './shared/states/index';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { ApiModule, Configuration } from './shared/apis/advis';
import { PreviousEnergyDemandComponent } from 'app/wizard-shared/previous-energy-demand/previous-energy-demand';
import { EnvironmentService } from './shared/services/environment.service';
import { GoogleSpreadsheetService } from './shared/services/google-spreadsheet.service';
import { VersionCheckService } from './shared/services/version-check.service';
import { EMobilityEffets } from './shared/states/electro-mobility/electro-mobility.effect';
import { UserComponent } from './components/user/user.component';
import { PermissionService } from './shared/services/permission.service';
import { UserEffects } from './shared/states/user/user.effects';
import { PhotovoltaikEffects } from './shared/states/photovoltaik/photovoltaik.effect';
import { LeadDocumentEffects } from './shared/states/lead-document/lead-document.effects';
import { MomentModule } from 'ngx-moment';
import { LeadProjectEffects } from './shared/states/lead-project/lead-project.effects';
import { LeadContactEffects } from './shared/states/lead-contact/lead-contact.effects';
import { LoadingModalService } from './shared/services/loading-modal.service';
import { UserPictureComponent } from './components/user/user-picture/user-picture.component';
import { AppMtInitializerGuard } from './shared/guards/mt/app-mt-initializer.guard';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProjectReportingResolver } from './shared/resolvers/project-reporting.resolver';
import { ProjectReportingEffects } from './shared/states/Project-reporting/Project-reporting.effects';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { MaterialModule } from './material/material.module';
import { CommonComponentsModule } from './common-components/common-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { AddressBookSharedModule } from './address-book-shared/address-book-shared.module';
import { LibraryModule } from '@sitewerk/theia-ui-lib';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

registerLocaleData(localeDECH);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // FIXME: Enable when use base-href, see: https://github.com/ngx-translate/http-loader/pull/12
  // return new TranslateHttpLoader(http, '/pc/assets/i18n/'); // **PROD-BUILD**
  // Simple cache buster - just add the version. Should be good enough in production.
  return new TranslateHttpLoader(http, undefined, '.json?v=' + environment.version);
}

export function apiConfigurationFactory(): Configuration {
  return new Configuration({ withCredentials: true });
}

const msalConfiguration = new PublicClientApplication({
  auth: {
    clientId: environment.ssoClientId,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: `${window.location.origin}/auth/sso-callback`,
  },
  cache: null,
});

@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    PreviousEnergyDemandComponent,
    DevelopmentComponent,
    ErrorComponent,
    UserComponent,
    UserPictureComponent,
  ],
  imports: [
    LibraryModule,
    CommonComponentsModule,
    AddressBookSharedModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    MsalModule.forRoot(msalConfiguration, null, null),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ApiModule.forRoot(apiConfigurationFactory),
    StoreModule.forRoot(RootReducer.reducers, {
      metaReducers: RootReducer.metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      LeadEffects,
      LeadNotesEffects,
      ViewSettingEffects,
      AuthEffects,
      UserEffects,
      ProjectReportingEffects,
      GlobalEffects,
      NavigationEffects,
      TaskEffects,
      LeadDocumentEffects,
      LeadProjectEffects,
      LeadContactEffects,
      VariantEffects,
      ActionsEffects,
      HeatingEffects,
      EMobilityEffets,
      PhotovoltaikEffects,
    ]),
    QuillModule.forRoot(),
    // StoreRouterConnectingModule,
    // you must instrument after importing StoreModule
    environment.instrumentStoreDevtools
      ? StoreDevtoolsModule.instrument({ maxAge: 50 } as StoreDevtoolsOptions)
      : [],
  ],
  providers: [
    Title,
    // Service
    DeviceDetectorService,
    ViewSettingService,
    AppMonitoringService,
    AuthService,
    PermissionService,
    ImageResizeService,
    EnumService,
    DatePipe,
    ConfigService,
    EnvironmentService,
    LoadingModalService,
    LoggerService,
    RightsService,
    VersionCheckService,
    GoogleSpreadsheetService,
    GemeindeService,
    UrlService,
    DragulaService,
    // Guard
    AuthGuard,
    AppInitializerGuard,
    AppMtInitializerGuard,
    // Resolver
    PersonLeadDetailLoadedResolver,
    ProjectReportingResolver,
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: BASE_PATH, useValue: environment.apiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SignalRInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 120000 },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ViewSettingService, Injector],
      multi: true,
    },
    // {
    //   provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    //   useValue: { showDelay: 700, hideDelay: 0 },
    // },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

/**
 * Preload language to use 'instant' method of the translation service
 * Ref: https://github.com/ngx-translate/core/issues/517
 * @param {ViewSettingService} vsService
 * @param {Injector} injector
 * @returns {any}
 */
export function appInitializerFactory(vsService: ViewSettingService, injector: Injector): any {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized: any = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(undefined)
      );
      locationInitialized.then(() => {
        const translate = injector.get(TranslateService);
        const langToSet: string = vsService.getLastLoginLang();
        translate.setDefaultLang('de');
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          () => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(undefined);
          }
        );
      });
    });
}
