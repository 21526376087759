import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@sitewerk/theia-ui-lib';

export interface ISupportDialogData {
  technicalSupportEmail: string;
  productSupportEmail: string;
  mailArguments: string;
}

@Component({
  selector: 'pc-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent {
  public readonly productSupportReasons: string[] = [
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.INCORRECT_PRODUCT_PRICES',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.WRONG_PRODUCT_TRANSLATION',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.PRODUCT_NOT_AVAILABLE',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.WRONG_DATASHEET',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.MISSING_DATASHEET',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.MISSING_PRODUCTS',
    'SUPPORT_DIALOG.PRODUCT_SUPPORT.REASON.OTHER',
  ];

  public readonly technicalSupportReasons: string[] = [
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.DATA_COULD_NOT_BE_LOADED',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.DATA_COULD_NOT_BE_SAVED',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.BUTTONS_DO_NOT_WORK',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.FILTER_DOES_NOT_WORK',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.PRESENTATION_OF_OFFERS',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.TRANSLATION_OF_OFFERS',
    'SUPPORT_DIALOG.TECHNICAL_SUPPORT.REASON.OTHER',
  ];

  constructor(@Inject(DIALOG_DATA) private data: ISupportDialogData) {}

  public onTechnicalSupportClick(): void {
    window.open(`mailto:${this.data.technicalSupportEmail}${this.data.mailArguments}`);
  }

  public onProductSupportClick(): void {
    window.open(`mailto:${this.data.productSupportEmail}${this.data.mailArguments}`);
  }
}
