import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ComponentType } from '@angular/cdk/portal';

import { DisplayUtil } from '../utils/display.util';
import { CustomCommonDialogComponent } from '../../common-components/dialog/custom-common-dialog/custom-common-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogs: any = [];

  constructor(private dialog: MatDialog, private translate: TranslateService) {
    // empty
  }

  public openConfirm(
    message?: string,
    title?: string,
    okText?: string,
    cancelText?: string
  ): MatDialogRef<CustomCommonDialogComponent> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.autoFocus = false;
    config.data = {
      message: message ? message : '',
      title: title ? title : this.translate.instant('DIALOG.TITLE_CONFIRM'),
      cancelButton: cancelText ? cancelText : this.translate.instant('COMMON.BTN_CANCEL'),
      acceptButton: okText ? okText : this.translate.instant('COMMON.BTN_OK'),
      isShowActionButtons: true,
    };

    return this.openCommonDialog(config);
  }

  public openAlert(
    message: string,
    title?: string,
    okText?: string
  ): MatDialogRef<CustomCommonDialogComponent> {
    const config: MatDialogConfig = new MatDialogConfig();
    config.autoFocus = false;
    config.data = {
      message: message,
      title: title ? title : this.translate.instant('DIALOG.TITLE_WARN'),
      cancelButton: okText ? okText : this.translate.instant('COMMON.BTN_OK'),
      isShowActionButtons: true,
    };

    return this.openCommonDialog(config);
  }

  public open<T, I>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data?: I,
    cfg?: MatDialogConfig
  ): MatDialogRef<T> {
    let config: MatDialogConfig = {
      // width: '800px', // Fit to content without specifying width
      data: data,
      role: 'dialog',
      disableClose: false,
      autoFocus: false,
    };

    Object.assign(config, cfg);

    return this._open(componentOrTemplateRef, config);
  }

  private openCommonDialog(config: MatDialogConfig): MatDialogRef<CustomCommonDialogComponent> {
    let cfg: MatDialogConfig = {
      role: 'dialog',
      disableClose: false,
      width: DisplayUtil.getDialogWidthPx(),
      autoFocus: false,
    };

    Object.assign(cfg, config);

    return this._open(CustomCommonDialogComponent, config);
  }

  private _open<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig
  ): MatDialogRef<T> {
    const dialogRef: MatDialogRef<T> = this.dialog.open(componentOrTemplateRef, config);
    return this.register(dialogRef);
  }

  private register<T>(dialogRef: MatDialogRef<T>): MatDialogRef<T> {
    this.dialogs.push(dialogRef);

    dialogRef.afterClosed().subscribe(undefined, undefined, () => {
      this.dialogs = this.dialogs.filter((ref: any) => {
        if (ref !== dialogRef) {
          return ref;
        }
      });
    });

    return dialogRef;
  }
}
