<div class="address-block">
  <ng-container *ngIf="!isAccordion">
    <div class="header">
      <pc-address-header [title]="title" [isReadOnly]="isReadOnly" (onEdit)="onEdit.emit()">
      </pc-address-header>

      <pc-action-buttons
        [showEditIcon]="!isReadOnly"
        [regionalInformation]="regionalInformation"
        [searchLink]="searchLink"
        (editAddress)="editAddress()"></pc-action-buttons>
    </div>

    <ng-container [ngTemplateOutlet]="addressBody"> </ng-container>
  </ng-container>

  <helion-ui-accordion *ngIf="isAccordion">
    <helion-ui-accordion-item>
      <div accordionItemHeader>
        <div class="accordion-header">
          {{ title }}
          <pc-action-buttons
            [showEditIcon]="!isReadOnly"
            [regionalInformation]="regionalInformation"
            [searchLink]="searchLink"
            (editAddress)="editAddress()"></pc-action-buttons>
        </div>

        <div *ngIf="isExternalManaged" class="debtor-state" fxLayout="row wrap" fxLayoutGap="8px">
          <helion-ui-checkbox
            [attr.data-cy]="'SapDebtorConfirmed'"
            name="SapDebtorConfirmed"
            [isChecked]="contact.DataState === LeadContactViewModel.DataStateEnum.Qualified"
            [disabled]="true">
            {{ 'ADDRESS.DATA_STATE.CONFIRMED' | translate }}
          </helion-ui-checkbox>
          <helion-ui-checkbox
            [attr.data-cy]="'SapDebtorRequested'"
            name="SapDebtorRequested"
            [isChecked]="contact.DataState === LeadContactViewModel.DataStateEnum.Unqualified"
            [disabled]="true">
            {{ 'ADDRESS.DATA_STATE.REQUESTED' | translate }}
          </helion-ui-checkbox>
        </div>
      </div>
      <ng-container accordionItemBody [ngTemplateOutlet]="addressBody"> </ng-container>
    </helion-ui-accordion-item>
  </helion-ui-accordion>
</div>

<ng-template #addressBody>
  <div class="address-block__body truncate-ellipsis">
    <p class="address-block__text" *ngIf="contact.Company?.Name">{{ contact.Company.Name }}</p>
    <p class="address-block__text" *ngIf="contact.Company?.AdditionalName">
      {{ contact.Company?.AdditionalName }}
    </p>
    <p class="address-block__text">
      {{ contact.Person?.FirstName }} {{ contact.Person?.LastName }}
    </p>

    <div fxLayout="column">
      <div fxLayout="row wrap">
        <div>
          <div fxLayout="column">
            <p class="address-block__Street">
              <a
                [href]="swissTopoLink"
                target="_blank"
                data-rel="external"
                class="address-block__link">
                {{ address?.Street }}
              </a>
            </p>
            <p class="address-block__city">
              <a
                [href]="swissTopoLink"
                target="_blank"
                data-rel="external"
                class="address-block__link">
                {{ address?.ZipCode }} {{ address?.City }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
      <div>
        <p *ngFor="let additionalPhone of getPhones()" class="address-block__text">
          {{ 'ADDRESS.PHONE_NUMBER' | translate }}
        </p>
        <p *ngFor="let additionalEmail of getMails()" class="address-block__text">
          {{ 'ADDRESS.EMAIL' | translate }}
        </p>
        <p
          *ngIf="contact.Person && contact.Person?.ThirdPartyCustomerNumber"
          class="address-block__text">
          {{ 'COMMON.REFERENCE' | translate }}
        </p>
        <p *ngIf="contact.Person && contact.Person?.Language" class="address-block__text">
          {{ 'COMMON.LANGUAGE' | translate }}
        </p>
        <p *ngIf="contact.Person?.PreferredCommunicationType" class="address-block__text">
          {{ 'ADDRESS.COMMUNICATION_TYPE' | translate }}
        </p>
      </div>
      <div class="truncate-ellipsis">
        <ng-container
          *ngFor="let additionalPhone of getPhones()"
          [ngTemplateOutlet]="phone"
          [ngTemplateOutletContext]="{
            phoneNumber: additionalPhone.PhoneNumber,
            additionalPhoneType: additionalPhone.PhoneType
          }">
        </ng-container>
        <ng-container
          *ngFor="let additionalEmail of getMails()"
          [ngTemplateOutlet]="email"
          [ngTemplateOutletContext]="{
            email: additionalEmail.Email,
            additionalEmailType: additionalEmail.EmailType
          }">
        </ng-container>
        <ng-container
          *ngIf="contact.Person?.ThirdPartyCustomerNumber"
          [ngTemplateOutlet]="thirdPartyCustomerNumber"
          [ngTemplateOutletContext]="{ email: contact.Person?.Emails[0] }">
        </ng-container>
        <ng-container
          *ngIf="contact.Person?.Language"
          [ngTemplateOutlet]="language"
          [ngTemplateOutletContext]="{ language: contact.Person?.Language }">
        </ng-container>
        <ng-container
          *ngIf="contact.Person?.PreferredCommunicationType"
          [ngTemplateOutlet]="communicationType"
          [ngTemplateOutletContext]="{
            communicationType: contact.Person?.PreferredCommunicationType
          }">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #phone let-phoneNumber="phoneNumber" let-additionalPhoneType="additionalPhoneType">
  <p class="address-block__text">
    <a
      [href]="'tel:' + phoneNumber"
      class="address-block__link phone-number"
      [class.primary]="primary">
      {{ phoneNumber }} ({{
        additionalPhoneTypesTranslations[additionalPhoneType] || 'ADDRESS.PRIMARY_PHONE_TYPE'
          | translate
      }})
    </a>
  </p>
</ng-template>

<ng-template #email let-email="email" let-additionalEmailType="additionalEmailType">
  <p class="address-block__text">
    <a
      [href]="'mailto:' + email"
      data-rel="external"
      data-cy="leadStageLabelEmail"
      class="address-block__link"
      [class.primary]="primary"
      *ngIf="!additionalEmailType">
      {{ email }} ({{ 'ADDRESS.PRIMARY_EMAIL_TYPE' | translate }})
    </a>
    <a
      [href]="'mailto:' + email"
      data-rel="external"
      class="address-block__link"
      [class.primary]="primary"
      *ngIf="additionalEmailType">
      {{ email }} ({{ additionalEmailTypesTranslations[additionalEmailType] | translate }})
    </a>
  </p>
</ng-template>

<ng-template #thirdPartyCustomerNumber>
  <p class="address-block__text">{{ contact.Person?.ThirdPartyCustomerNumber }}</p>
</ng-template>

<ng-template #language>
  <p class="address-block__text">{{ contact.Person?.Language }}</p>
</ng-template>

<ng-template #communicationType>
  <p class="address-block__text">
    {{
      contact.Person?.PreferredCommunicationType
        | enumText : DirectoryPersonViewModel.PreferredCommunicationTypeEnum
    }}
  </p>
</ng-template>
