import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageResizeService } from '../../../shared/services/image-resize.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
})
export class UserPictureComponent implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() picture: File;
  @Input() pictureUrl: string;
  @Input() titleKey: string;
  @Output() pictureChange: EventEmitter<File> = new EventEmitter<File>();
  @Output() pictureUrlChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private imageResizeService: ImageResizeService, public sanitizer: DomSanitizer) {}

  onPictureSelect(logo: File | FileList): void {
    this.picture = logo instanceof File ? logo : logo[0];
    this.subscriptions.add(
      this.imageResizeService
        .doImageResize([this.picture], 400, 400, { withAlpha: true, type: `${this.picture.type}` })
        .subscribe((result: File[]) => {
          this.pictureUrl = URL.createObjectURL(result[0]);
          this.onDelete.emit(false);
          this.pictureChange.emit(result[0]);
          this.pictureUrlChange.emit(this.pictureUrl);
        })
    );
  }

  onPictureDelete(): void {
    this.picture = undefined;
    this.pictureUrl = undefined;
    this.onDelete.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
