import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragulaModule } from 'ng2-dragula';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgxMasonryModule } from 'ngx-masonry';
import { QuillModule } from 'ngx-quill';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { NotificationCountComponent } from './notification-count/notification-count.component';
import { ReadOnlyLabelComponent } from './read-only-label/read-only-label.component';
import { MandantSelectorComponent } from './mandator-selector/mandant-selector.component';
import { MessageComponent } from './message/message.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MainComponent } from './main/main.component';
import { MandantIndicatorComponent } from './mandant-indicator/mandant-indicator.component';
import { ImageButtonComponent } from './image-carousel/image-button/image-button.component';
import { CustomFileUploadComponent } from './custom-file-upload/custom-file-upload.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ImageCardComponent } from './image-carousel/image-card/image-card.component';
import { ImageSketchDialogComponent } from './dialog/image-sketch-dialog/image-sketch-dialog.component';
import { NoteListComponent } from './note-list/note-list.component';
import { MapComponent } from './map/map.component';
import { Building3DComponent } from './map/building3-d/building3-d.component';
import { BurgerButtonComponent } from './burger-button/burger-button.component';
import { AccountAssignmentListComponent } from './account-assignment-list/account-assignment-list.component';
import { SaveCancelButtonsComponent } from './save-cancel-buttons/save-cancel-buttons.component';
import { EnterDirective } from './directives/enter/enter.directive';
import { FileDropDirective } from './directives/file-drop/file-drop.directive';
import { OwnerDisplayNamePipe } from './directives/owner-display-name.pipe';
import { DisableButtonOnSubmitDirective } from './directives/disable-button-on-submit.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TagsTextPipe } from './pipes/tags-text.pipe';
import { LeadNotePipe } from './pipes/lead-note.pipe';
import { EnumTextPipe } from './pipes/enum-text.pipe';
import { DateTicksPipe } from './pipes/date-ticks.pipe';
import { TruncateLeadNamePipe } from './truncate-lead-name.pipe';
import { LeadAddressCompoundForHeaderPipe } from './pipes/lead-address-compound-for-header.pipe';
import { ProjectChecklistSelectorComponent } from './project-checklist-selector/project-checklist-selector.component';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { LeadProjectAdditionalComponentsComponent } from './lead-project-additional-components/lead-project-additional-components.component';
import { AttachmentSelectorComponent } from './dialog/document-dialog/attachment-selector/attachment-selector.component';
import { AssignmentListWithFilterComponent } from './assignment-list-with-filter/assignment-list-with-filter.component';
import { SelectImageDialogComponent } from './dialog/select-image-dialog/select-image-dialog.component';
import { QuickSearchDialogComponent } from './dialog/quick-search-dialog/quick-search-dialog.component';
import { CustomCommonDialogComponent } from './dialog/custom-common-dialog/custom-common-dialog.component';
import { SearchableUserSelectorComponent } from './searchable-user-selector/searchable-user-selector.component';
import { SignatureComponent } from './signature/signature.component';
import { SupportDialogComponent } from './dialog/support-dialog/support-dialog.component';
import { LeadSiblingsIndComponent } from './lead-siblings-ind/lead-siblings-ind.component';
import { SelectDownloadDocumentTypeComponent } from './select-download-document-type/select-download-document-type.component';
import { InputWithSaveCancelButtonsComponent } from './input-with-save-cancel-buttons/input-with-save-cancel-buttons.component';
import { Roof3dPluginDialogComponent } from './map/building3-d/roof3d-plugin-dialog/roof3d-plugin-dialog.component';
import { LibraryModule } from '@sitewerk/theia-ui-lib';
import { MainDrawerContentComponent } from './main-drawer-content/main-drawer-content.component';
import { DragulaItemDirective } from './directives/dragula-item.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { FileIconComponent } from './file-icon/file-icon.component';
import { PrivacyModeSwitcherComponent } from './privacy-mode-switcher/privacy-mode-switcher.component';
import { SelectCommunicationTypeComponent } from './select-communication-type/select-communication-type.component';
import { WarrantiesSelectorComponent } from './warranties-selector/warranties-selector.component';
import { WarrantiesSelectorWebviewComponent } from './warranties-selector/warranties-selector-webview.component';
import { AutosaveService } from './quill-editor/autosave.service';
import { SolarTariffSelectorComponent } from './solartariff-selector/solar-tariff-selector.component';
import { LeadComplexityTypeSelectorComponent } from './lead-complexity-type-selector/lead-complexity-type-selector.component';
import { ConfirmWithReasonDialogComponent } from './dialog/confirm-with-reason-dialog/confirm-with-reason-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    LibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragulaModule,
    NgxMasonryModule,
    NgxDatatableModule,
    InputMaskModule,
    QuillModule,
    MatInputModule,
    MatDialogModule,
  ],
  declarations: [
    SelectDownloadDocumentTypeComponent,
    AssignmentListWithFilterComponent,
    LeadProjectAdditionalComponentsComponent,
    LoadingModalComponent,
    NotificationCountComponent,
    ReadOnlyLabelComponent,
    MandantSelectorComponent,
    MessageComponent,
    LanguageSelectorComponent,
    MainComponent,
    MandantIndicatorComponent,
    ImageButtonComponent,
    CustomFileUploadComponent,
    ImageCarouselComponent,
    NoteListComponent,
    MapComponent,
    BurgerButtonComponent,
    AccountAssignmentListComponent,
    Building3DComponent,
    SaveCancelButtonsComponent,
    ImageCardComponent,
    EnterDirective,
    FileDropDirective,
    SafeHtmlPipe,
    TagsTextPipe,
    LeadNotePipe,
    LeadAddressCompoundForHeaderPipe,
    ImageSketchDialogComponent,
    AttachmentSelectorComponent,
    SelectImageDialogComponent,
    CustomCommonDialogComponent,
    QuickSearchDialogComponent,
    SearchableUserSelectorComponent,
    DisableButtonOnSubmitDirective,
    DragulaItemDirective,
    SignatureComponent,
    SupportDialogComponent,
    OwnerDisplayNamePipe,
    LeadSiblingsIndComponent,
    EnumTextPipe,
    TruncateLeadNamePipe,
    InputWithSaveCancelButtonsComponent,
    QuillEditorComponent,
    ProjectChecklistSelectorComponent,
    Roof3dPluginDialogComponent,
    DateTicksPipe,
    MainDrawerContentComponent,
    FileIconComponent,
    PrivacyModeSwitcherComponent,
    SelectCommunicationTypeComponent,
    WarrantiesSelectorComponent,
    WarrantiesSelectorWebviewComponent,
    SolarTariffSelectorComponent,
    LeadComplexityTypeSelectorComponent,
    ConfirmWithReasonDialogComponent,
  ],
  exports: [
    SelectDownloadDocumentTypeComponent,
    LeadSiblingsIndComponent,
    InputMaskModule,
    SupportDialogComponent,
    SignatureComponent,
    DisableButtonOnSubmitDirective,
    DragulaItemDirective,
    SearchableUserSelectorComponent,
    CustomCommonDialogComponent,
    QuickSearchDialogComponent,
    SelectImageDialogComponent,
    AttachmentSelectorComponent,
    ImageSketchDialogComponent,
    AssignmentListWithFilterComponent,
    LeadProjectAdditionalComponentsComponent,
    LoadingModalComponent,
    NotificationCountComponent,
    ReadOnlyLabelComponent,
    MandantSelectorComponent,
    MessageComponent,
    LanguageSelectorComponent,
    MainComponent,
    MandantIndicatorComponent,
    ImageButtonComponent,
    CustomFileUploadComponent,
    ImageCarouselComponent,
    NoteListComponent,
    MapComponent,
    BurgerButtonComponent,
    AccountAssignmentListComponent,
    SaveCancelButtonsComponent,
    Building3DComponent,
    ImageCardComponent,
    EnterDirective,
    FileDropDirective,
    SafeHtmlPipe,
    TagsTextPipe,
    LeadNotePipe,
    OwnerDisplayNamePipe,
    LeadAddressCompoundForHeaderPipe,
    EnumTextPipe,
    TruncateLeadNamePipe,
    InputWithSaveCancelButtonsComponent,
    QuillEditorComponent,
    ProjectChecklistSelectorComponent,
    Roof3dPluginDialogComponent,
    DateTicksPipe,
    FileIconComponent,
    PrivacyModeSwitcherComponent,
    SelectCommunicationTypeComponent,
    WarrantiesSelectorComponent,
    WarrantiesSelectorWebviewComponent,
    SolarTariffSelectorComponent,
    LeadComplexityTypeSelectorComponent,
    ConfirmWithReasonDialogComponent,
  ],
  providers: [
    TruncateLeadNamePipe,
    EnumTextPipe,
    SafeHtmlPipe,
    TagsTextPipe,
    LeadNotePipe,
    LeadAddressCompoundForHeaderPipe,
    OwnerDisplayNamePipe,
    DateTicksPipe,
    AutosaveService,
  ],
})
export class CommonComponentsModule {}
