import * as VariantAction from './variant.action';
import {
  ADD_GROUP_POSITIONS,
  ADD_GROUP_POSITIONS_FAILED,
  ADD_GROUP_POSITIONS_SUCCESS,
  ADD_REGULAR_POSITIONS,
  ADD_REGULAR_POSITIONS_FAILED,
  ADD_REGULAR_POSITIONS_SUCCESS,
  ADD_SPECIAL_POSITIONS,
  ADD_SPECIAL_POSITIONS_FAILED,
  ADD_SPECIAL_POSITIONS_SUCCESS,
  ADD_VARIANT_REBATE,
  ADD_VARIANT_REBATE_FAILED,
  ADD_VARIANT_REBATE_SUCCESS,
  CREATE_PRICE_TABLE_VARIANT_SUCCESS,
  CREATE_VARIANT,
  CREATE_VARIANT_FAILED,
  CREATE_VARIANT_SUCCESS,
  DELETE_BOM_ITEMS,
  DELETE_BOM_ITEMS_FAILED,
  DELETE_BOM_ITEMS_SUCCESS,
  GET_VARIANT,
  GET_VARIANT_FAILED,
  GET_VARIANT_PRECALCULATION_SUCCESS,
  GET_VARIANT_SUCCESS,
  PATCH_DYNAMIC_PROPERTY,
  PATCH_DYNAMIC_PROPERTY_FAILED,
  PATCH_DYNAMIC_PROPERTY_SUCCESS,
  PATCH_EM_SPECIFIC_VARIANT,
  PATCH_EM_SPECIFIC_VARIANT_FAILED,
  PATCH_EM_SPECIFIC_VARIANT_SUCCESS,
  PATCH_POSITION_GROUP,
  PATCH_POSITION_GROUP_FAILED,
  PATCH_POSITION_GROUP_SUCCESS,
  PATCH_PV_SPECIFIC_VARIANT,
  PATCH_PV_SPECIFIC_VARIANT_FAILED,
  PATCH_PV_SPECIFIC_VARIANT_SUCCESS,
  PATCH_REGULAR_OR_SPECIAL_POSITION,
  PATCH_REGULAR_OR_SPECIAL_POSITION_FAILED,
  PATCH_REGULAR_OR_SPECIAL_POSITION_SUCCESS,
  PATCH_VARIANT,
  PATCH_VARIANT_FAILED,
  PATCH_VARIANT_SUCCESS,
  PATCH_HT_SPECIFIC_VARIANT,
  PATCH_HT_SPECIFIC_VARIANT_FAILED,
  PATCH_HT_SPECIFIC_VARIANT_SUCCESS,
  PUT_POSITION_ORDER_AND_GROUP,
  PUT_POSITION_ORDER_AND_GROUP_FAILED,
  PUT_POSITION_ORDER_AND_GROUP_SUCCESS,
  REBUILD_VARIANT,
  REBUILD_VARIANT_FAILED,
  REBUILD_VARIANT_SUCCESS,
  REMOVE_VARIANT_REBATE,
  REMOVE_VARIANT_REBATE_FAILED,
  REMOVE_VARIANT_REBATE_SUCCESS,
  RESET_VARIANT,
  SAVE_VARIANT,
  SAVE_VARIANT_FAILED,
  SAVE_VARIANT_SUCCESS,
  UPDATE_OBJECT_DATA,
  UPDATE_OBJECT_DATA_FAILED,
  UPDATE_OBJECT_DATA_SUCCESS,
  UPDATE_PRICES,
  UPDATE_PRICES_FAILED,
  UPDATE_PRICES_SUCCESS,
  UPDATE_VARIANT_REBATE_FAILED,
  UPDATE_VARIANT_REBATE_SUCCESS,
  SAVE_SOLAR_PLANNER_DATA,
  SAVE_SOLAR_PLANNER_DATA_SUCCESS,
  DELETE_SOLAR_PLANNER_SCREENSHOT_SUCCESS,
  PASTE_GROUPS_AND_POSITIONS_SUCCESS,
  PASTE_GROUPS_AND_POSITIONS_FAILED,
  PASTE_GROUPS_AND_POSITIONS,
  REBUILD_VARIANT_MEIER_TOBLER,
  SET_SELECTED_VARIANT,
  PATCH_ST_SPECIFIC_VARIANT_FAILED,
  PATCH_ST_SPECIFIC_VARIANT_SUCCESS,
  PATCH_ST_SPECIFIC_VARIANT,
  UPLOAD_VARIANT_SIGNATURE,
  UPLOAD_VARIANT_SIGNATURE_SUCCESS,
  UPLOAD_VARIANT_SIGNATURE_FAILED,
  GET_VARIANT_SIGNATURE,
  GET_VARIANT_SIGNATURE_SUCCESS,
  GET_VARIANT_SIGNATURE_FAILED,
  GET_ACCOUNT_ASSIGNMENT_LIST_SUCCESS,
  GET_ACCOUNT_ASSIGNMENT_LIST_FAILED,
  SELECT_POSITION_GROUP_FAILED,
  SELECT_POSITION_GROUP_SUCCESS,
  SELECT_POSITION_GROUP,
  DESELECT_POSITION_GROUP_SUCCESS,
  DESELECT_POSITION_GROUP_FAILED,
  DESELECT_POSITION_GROUP,
  REPLACE_SUCCESSOR_POSITION,
  REPLACE_SUCCESSOR_POSITION_SUCCESS,
  REPLACE_SUCCESSOR_POSITION_FAILED,
  REPLACE_ALL_SUCCESSOR_POSITIONS_FAILED,
  REPLACE_ALL_SUCCESSOR_POSITIONS_SUCCESS,
  REPLACE_ALL_SUCCESSOR_POSITIONS,
  PATCH_REGULAR_OR_SPECIAL_POSITIONS,
  PATCH_REGULAR_OR_SPECIAL_VARIANT_POSITION_SUCCESS,
} from './variant.action';
import { tassign } from 'tassign';
import {
  ValidationErrorViewModel,
  VariantCompositeViewModel,
  VariantPrecalculationViewModel,
} from '../../apis/advis';
import { IAccountAssignmentData } from '../../../common-components/account-assignment-list/account-assignment-list.component';

export interface IState {
  createVariantSuccess: boolean;
  createVariantRunning: boolean;
  saveVariantSuccess: boolean;
  saveVariantRunning: boolean;
  getVariantRunning: boolean;
  userActionRunning: boolean;
  variant: VariantCompositeViewModel;
  variantPrecalculation: VariantPrecalculationViewModel;
  validationResults: ValidationErrorViewModel[];
  variantSignature: string;
  variantSignatureLoading: boolean;
  accountAssignmentList: IAccountAssignmentData[];
}

export const initialState: IState = {
  createVariantSuccess: undefined,
  createVariantRunning: undefined,
  saveVariantSuccess: undefined,
  saveVariantRunning: undefined,
  getVariantRunning: undefined,
  userActionRunning: undefined,
  variant: undefined,
  variantPrecalculation: undefined,
  validationResults: undefined,
  variantSignature: undefined,
  variantSignatureLoading: undefined,
  accountAssignmentList: undefined,
};

export function reducer(state: IState = initialState, action: VariantAction.Actions): IState {
  switch (action.type) {
    case RESET_VARIANT: {
      return (state = initialState);
    }

    case CREATE_VARIANT: {
      return tassign(state, {
        createVariantRunning: true,
        createVariantSuccess: false,
      });
    }
    case CREATE_PRICE_TABLE_VARIANT_SUCCESS:
    case CREATE_VARIANT_SUCCESS: {
      return tassign(state, {
        createVariantSuccess: true,
        createVariantRunning: false,
        variant: action.payload.variantComposite,
        validationResults: action.payload.variantComposite.ValidationErrors,
      });
    }

    case CREATE_VARIANT_FAILED: {
      let validationResults: ValidationErrorViewModel[] = undefined;
      if (action.payload && action.payload.error) {
        validationResults = action.payload.error;
      }

      return tassign(state, {
        createVariantSuccess: false,
        createVariantRunning: false,
        variant: undefined,
        validationResults: validationResults,
      });
    }

    case SAVE_VARIANT: {
      return tassign(state, {
        saveVariantRunning: true,
        saveVariantSuccess: false,
      });
    }

    case SAVE_VARIANT_SUCCESS: {
      return tassign(state, {
        saveVariantSuccess: true,
        saveVariantRunning: false,
        variant: action.payload.variantComposite,
      });
    }

    case SAVE_VARIANT_FAILED: {
      let validationResults: ValidationErrorViewModel[] = undefined;
      if (action.payload && action.payload.error) {
        validationResults = action.payload.error;
      }

      return tassign(state, {
        saveVariantSuccess: false,
        saveVariantRunning: false,
        variant: undefined,
        validationResults: validationResults,
      });
    }

    case SET_SELECTED_VARIANT: {
      return tassign(state, {
        variant: action.payload,
      });
    }

    case GET_VARIANT: {
      return tassign(state, {
        getVariantRunning: true,
      });
    }
    case GET_VARIANT_SUCCESS: {
      return tassign(state, {
        getVariantRunning: false,
        variant: action.payload,
      });
    }
    case GET_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        getVariantRunning: false,
      });
    }
    case ADD_REGULAR_POSITIONS: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case ADD_REGULAR_POSITIONS_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case ADD_REGULAR_POSITIONS_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case REPLACE_ALL_SUCCESSOR_POSITIONS:
    case REPLACE_SUCCESSOR_POSITION: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case REPLACE_ALL_SUCCESSOR_POSITIONS_SUCCESS:
    case REPLACE_SUCCESSOR_POSITION_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case REPLACE_ALL_SUCCESSOR_POSITIONS_FAILED:
    case REPLACE_SUCCESSOR_POSITION_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case ADD_SPECIAL_POSITIONS: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case ADD_SPECIAL_POSITIONS_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case ADD_SPECIAL_POSITIONS_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case ADD_GROUP_POSITIONS: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case ADD_GROUP_POSITIONS_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case ADD_GROUP_POSITIONS_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case DELETE_BOM_ITEMS: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case DELETE_BOM_ITEMS_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case DELETE_BOM_ITEMS_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case PUT_POSITION_ORDER_AND_GROUP: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PUT_POSITION_ORDER_AND_GROUP_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PUT_POSITION_ORDER_AND_GROUP_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case PATCH_POSITION_GROUP: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_POSITION_GROUP_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_POSITION_GROUP_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case SELECT_POSITION_GROUP: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case SELECT_POSITION_GROUP_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case SELECT_POSITION_GROUP_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case DESELECT_POSITION_GROUP: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case DESELECT_POSITION_GROUP_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case DESELECT_POSITION_GROUP_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case PATCH_REGULAR_OR_SPECIAL_POSITION:
    case PATCH_REGULAR_OR_SPECIAL_POSITIONS: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_REGULAR_OR_SPECIAL_POSITION_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }

    case PATCH_REGULAR_OR_SPECIAL_VARIANT_POSITION_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PATCH_REGULAR_OR_SPECIAL_POSITION_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }

    case PATCH_REGULAR_OR_SPECIAL_POSITION_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PATCH_DYNAMIC_PROPERTY: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_DYNAMIC_PROPERTY_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_DYNAMIC_PROPERTY_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case REBUILD_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case REBUILD_VARIANT_MEIER_TOBLER: {
      return tassign(state, {
        userActionRunning: true,
      });
    }

    case REBUILD_VARIANT_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case REBUILD_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case ADD_VARIANT_REBATE: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case ADD_VARIANT_REBATE_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case ADD_VARIANT_REBATE_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case UPDATE_VARIANT_REBATE_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case UPDATE_VARIANT_REBATE_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case REMOVE_VARIANT_REBATE: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case REMOVE_VARIANT_REBATE_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case REMOVE_VARIANT_REBATE_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case PATCH_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_VARIANT_SUCCESS: {
      return {
        ...state,
        userActionRunning: false,
        variant: {
          ...state.variant,
          ...action.payload,
        },
      };
    }
    case PATCH_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PASTE_GROUPS_AND_POSITIONS: {
      return {
        ...state,
        userActionRunning: true,
      };
    }

    case PASTE_GROUPS_AND_POSITIONS_SUCCESS: {
      return {
        ...state,
        userActionRunning: false,
        variant: action.payload,
      };
    }

    case PASTE_GROUPS_AND_POSITIONS_FAILED: {
      return {
        ...state,
        userActionRunning: false,
      };
    }

    case PATCH_PV_SPECIFIC_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_PV_SPECIFIC_VARIANT_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_PV_SPECIFIC_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PATCH_HT_SPECIFIC_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_HT_SPECIFIC_VARIANT_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_HT_SPECIFIC_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PATCH_ST_SPECIFIC_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_ST_SPECIFIC_VARIANT_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_ST_SPECIFIC_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case PATCH_EM_SPECIFIC_VARIANT: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case PATCH_EM_SPECIFIC_VARIANT_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case PATCH_EM_SPECIFIC_VARIANT_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case UPDATE_PRICES: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case UPDATE_PRICES_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case UPDATE_PRICES_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }
    case UPDATE_OBJECT_DATA: {
      return tassign(state, {
        userActionRunning: true,
      });
    }
    case UPDATE_OBJECT_DATA_SUCCESS: {
      return tassign(state, {
        userActionRunning: false,
        variant: action.payload,
      });
    }
    case UPDATE_OBJECT_DATA_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        userActionRunning: false,
      });
    }

    case GET_VARIANT_PRECALCULATION_SUCCESS: {
      return tassign(state, {
        variantPrecalculation: action.payload,
      });
    }

    case SAVE_SOLAR_PLANNER_DATA: {
      return tassign(state, {
        saveVariantRunning: true,
        saveVariantSuccess: false,
      });
    }

    case SAVE_SOLAR_PLANNER_DATA_SUCCESS: {
      return tassign(state, {
        saveVariantSuccess: true,
        saveVariantRunning: false,
        variant: action.payload,
      });
    }

    case DELETE_SOLAR_PLANNER_SCREENSHOT_SUCCESS: {
      return tassign(state, {
        variant: action.payload,
      });
    }

    case UPLOAD_VARIANT_SIGNATURE:
    case GET_VARIANT_SIGNATURE: {
      return tassign(state, {
        variantSignatureLoading: true,
      });
    }

    case UPLOAD_VARIANT_SIGNATURE_SUCCESS:
    case GET_VARIANT_SIGNATURE_SUCCESS: {
      return tassign(state, {
        variantSignature: action.payload,
        variantSignatureLoading: false,
      });
    }

    case UPLOAD_VARIANT_SIGNATURE_FAILED:
    case GET_VARIANT_SIGNATURE_FAILED: {
      const err: any = action.payload;
      console.error('ERROR UPLOAD_VARIANT_SIGNATURE_FAILED/GET_VARIANT_SIGNATURE_FAILED: ', err);

      return tassign(state, {
        variantSignatureLoading: false,
      });
    }

    case GET_ACCOUNT_ASSIGNMENT_LIST_SUCCESS: {
      return tassign(state, {
        accountAssignmentList: action.payload,
      });
    }

    case GET_ACCOUNT_ASSIGNMENT_LIST_FAILED: {
      const err: any = action.payload;
      console.error('ERROR GET_ACCOUNT_ASSIGNMENT_LIST_FAILED: ', err);

      return state;
    }

    default: {
      return state;
    }
  }
}

export const getVariantState: (state: IState) => VariantCompositeViewModel = (state: IState) =>
  state.variant;
export const getValidationResultState: (state: IState) => ValidationErrorViewModel[] = (
  state: IState
) => state.validationResults;
export const getCreateVariantRunningState: (state: IState) => boolean = (state: IState) =>
  state.createVariantRunning;
export const getCreateVariantSuccessState: (state: IState) => boolean = (state: IState) =>
  state.createVariantSuccess;
export const getSaveVariantRunningState: (state: IState) => boolean = (state: IState) =>
  state.saveVariantRunning;
export const getSaveVariantSuccessState: (state: IState) => boolean = (state: IState) =>
  state.saveVariantSuccess;
export const getVariantRunningState: (state: IState) => boolean = (state: IState) =>
  state.getVariantRunning;
export const getUserActionRunningState: (state: IState) => boolean = (state: IState) =>
  state.userActionRunning;
export const getVariantPrecalculationState: (state: IState) => VariantPrecalculationViewModel = (
  state: IState
) => state.variantPrecalculation;
export const getAccountAssignmentListState: (state: IState) => IAccountAssignmentData[] = (
  state: IState
) => state.accountAssignmentList;
