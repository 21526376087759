/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SimpleTradeTradeSpecificDataViewModel { 
    TaxSaving?: number;
    MunicipalSubsidies?: number;
    CantonalSubsidies?: number;
    OtherSubsidies?: number;
    FederalSubsidies?: number;
    TotalSubsidies?: number;
    TotalPrice?: number;
    FinancingType?: SimpleTradeTradeSpecificDataViewModel.FinancingTypeEnum;
    SalesType?: SimpleTradeTradeSpecificDataViewModel.SalesTypeEnum;
    MinimalMargin?: number;
    GoalMargin?: number;
    ValidationDate?: Date;
}
export namespace SimpleTradeTradeSpecificDataViewModel {
    export type FinancingTypeEnum = 'None' | 'SubsidyPrefinancing' | 'Installments';
    export const FinancingTypeEnum = {
        None: 'None' as FinancingTypeEnum,
        SubsidyPrefinancing: 'SubsidyPrefinancing' as FinancingTypeEnum,
        Installments: 'Installments' as FinancingTypeEnum
    };
    export type SalesTypeEnum = 'Classic' | 'Digital';
    export const SalesTypeEnum = {
        Classic: 'Classic' as SalesTypeEnum,
        Digital: 'Digital' as SalesTypeEnum
    };
}
