import { Component } from '@angular/core';
import { DialogRef } from '@sitewerk/theia-ui-lib';

export enum CreateEditContactEnum {
  CREATE = 'create',
  EDIT = 'edit',
  FINISH = 'finish',
}

@Component({
  selector: 'pc-create-edit-address-dialog',
  templateUrl: './create-edit-address-dialog.component.html',
  styleUrls: ['./create-edit-address-dialog.component.scss'],
})
export class CreateEditAddressDialogComponent {
  public readonly createEditContactEnum: typeof CreateEditContactEnum = CreateEditContactEnum;

  constructor(public dialogRef: DialogRef) {}

  public onNextAddressStepSelect(step: CreateEditContactEnum): void {
    this.dialogRef.close(step);
  }
}
