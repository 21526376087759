import { Injectable } from '@angular/core';
import { StageE } from 'app/shared/models/enum/stage.enum';
import { StageUtil } from 'app/shared/utils/stage.util';
import {
  LeadCompositeViewModel,
  LeadContactViewModel,
  LeadListViewModel,
  LeadStageViewModel,
  LeadViewModel,
} from '../apis/advis';
import { PermissionService } from './permission.service';
import { RightsService } from './rights.service';
import { ILeadTableRowPermissions } from '../../lead-overview/lead-table/lead-table.component';
import * as RootReducer from '../states';
import { Store } from '@ngrx/store';
import { IExternManagedAddressTypeConfig } from '../utils/exter-managed-address-type.util';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;

export interface ILeadAllowedActions {
  canAssignToMe: boolean;
  canView: boolean;
  canModify: boolean;
  canAssignToOther: boolean;
  canReach: boolean;
  canBoSend: boolean;
  canFupUnreach: boolean;
  canUnreach: boolean;
  canAbort: boolean;
  canHold: boolean;
  canDelegate: boolean;
  canSendBackToOwner: boolean;
  canTriage: boolean;
  canSeeGrossMargin: boolean;
  canSeeValidationLight: boolean;
  canSeeHelionImportTool: boolean;
  canSendEmail: boolean;
  canCreateFromExistingLead: boolean;
  canSeeProjectDocumentation: boolean;
  canDeleteTasksAppointment: boolean;
  canSeeInvoice: boolean;
  canCreateInvoice: boolean;
  canRelease: boolean;
  canWonBackToFollowupBo: boolean;
  canSeeProjectReporting: boolean;
  canAssignProject: boolean;
  canStartProject: boolean;
  canFinishProject: boolean;
  canAccountProject: boolean;
  canPutAccountedProjectBackToFinished: boolean;
  canArchiveProject: boolean;
  canReleaseProject: boolean;
  canPutFinishedProjectBackToInProgress: boolean;
  canPutArchivedProjectBackToAccounted: boolean;
  canWin: boolean;
  canSeeFreeBillCard: boolean;
  canAddSupplementary: boolean;
  canEditManualProjectInfo: boolean;
  canSeeInvoiceCard: boolean;
  canManageElektroFormProjects: boolean;
  canSeeLeadReachedUnreachedDialog: boolean;
  canWinWithoutVariant: boolean;
  canSetVariantSalesProbability: boolean;
  canApproveSolarTarif: boolean;
  canStartOrder: boolean;
  canPutOrderBackToFollowUp: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LeadPermissionsService {
  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  constructor(
    private rights: RightsService,
    private store: Store<RootReducer.IState>,
    private permissions: PermissionService
  ) {
    this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
      this.allExternalManagedAddressConfig = x;
    });
  }

  public getRowPermissions(
    lead: LeadListViewModel,
    stages: LeadStageViewModel[]
  ): ILeadTableRowPermissions {
    return {
      canAssignMe: this.getCanAssignToMePermission(lead, stages),
      canLeadView: this.getCanViewPermission(lead),
      canLeadModify: this.getCanModifyPermission(lead),
      showBoSentButton: this.getCanBoSendPermission(lead, stages),
      canAssignOther: this.getCanAssignOtherPermission(lead, stages),
      canLeadAbort: this.getCanAbortPermission(lead, stages),
      canLeadHold: this.getCanHoldPermission(lead, stages),
      canDelegate: this.getCanDelegatePermission(lead, stages),
      canSendBackToOwner: this.getCanSendBackPermission(lead, stages),
      canTriageOrHandover: this.getCanTriageOrHandoverPermission(lead, stages),
      canCreateFromExistingLead: this.getCanCreateFromExistingLead(),
      canRelease: this.getCanReleasePermission(lead, stages),
      canWonBackToFollowupBo: this.getCanWonBackToFollowupBoPermisssion(lead, stages),
      canAssignProject: this.getCanAssignProjectPermission(lead, stages),
      canAssignProjectMe: this.getCanAssignProjectPermission(lead, stages),
      canStartProject: this.getCanStartProjectPermission(lead, stages) && lead.ProjectCanBeStarted,
      canFinishProject:
        this.getCanFinishProjectPermission(lead, stages) && lead.ProjectCanBeFinished,
      canAccountProject:
        this.getCanAccountProjectPermission(lead, stages) && lead.ProjectCanBeAccounted,
      canPutAccountedProjectBackToFinished: this.getCanPutAccountedProjectBackToFinishedPermission(
        lead,
        stages
      ),
      canPutArchivedProjectBackToAccounted: this.getCanPutArchivedProjectBackToAccountedPermission(
        lead,
        stages
      ),
      canPutFinishedProjectBackToInProgress:
        this.getCanPutFinishedProjectBackToInProgressPermission(lead, stages),
      canArchiveProject: this.getCanArchiveProjectPermission(lead, stages),
      canReleaseProject: this.getCanReleaseProjectPermission(lead, stages),
      canEditTracers: this.getCanEditTracersPermission(lead),
      canTransferBack: this.getCanTransferBackPermission(lead, stages),
    };
  }

  public getLeadAllowedActions(
    lead: LeadCompositeViewModel,
    stages: LeadStageViewModel[],
    isReadOnly: boolean
  ): ILeadAllowedActions {
    const canAssignToMe = this.getCanAssignToMePermission(lead.Lead, stages);
    const canAssignToOther = this.getCanAssignOtherPermission(lead.Lead, stages);
    const canView = this.getCanViewPermission(lead.Lead);
    const canModify = this.getCanModifyPermission(lead.Lead);
    const canReach = this.getCanReachPermissions(lead.Lead, stages);
    const canBoSend = this.getCanBoSendPermission(lead.Lead, stages);
    const canFupUnreach = this.getCanFupUnreachPermission(lead.Lead);
    const canUnreach = this.getCanUnreachPermission(lead.Lead, stages);
    const canAbort = this.getCanAbortPermission(lead.Lead, stages);
    const canHold = this.getCanHoldPermission(lead.Lead, stages);
    const canDelegate = this.getCanDelegatePermission(lead.Lead, stages);
    const canSendBackToOwner = this.getCanSendBackPermission(lead.Lead, stages);
    const canTriage = this.getCanTriageOrHandoverPermission(lead.Lead, stages);
    const canSeeGrossMargin = this.getCanSeeGrossMarginPermission();
    const canSeeValidationLight = this.getCanSeeValidationLightPermission();
    const canSeeHelionImportTool = StageUtil.canProjectSynchronize(lead.Lead, this.permissions);
    const canSendEmail = this.getCanSendEmailPermission();
    const canCreateFromExistingLead = this.getCanCreateFromExistingLead();
    const canSeeProjectDocumentation = this.getCanSeeProjectDocumentation();
    const canDeleteTasksAppointment = this.getCanDeleteTaskAppointmentPermission(lead.Lead);
    const canSeeInvoice = this.getCanSeeInvoicePermission();
    const canCreateInvoice = this.getCanCreateInvoicePermission(lead.Lead);
    const canRelease = this.getCanReleasePermission(lead.Lead, stages);
    const canWonBackToFollowupBo = this.getCanWonBackToFollowupBoPermisssion(lead.Lead, stages);
    const canSeeProjectReporting = this.getCanSeeProjectPerportingPermission();
    const canAssignProject = this.getCanAssignProjectPermission(lead.Lead, stages);
    const canStartProject = this.getCanStartProjectPermission(lead.Lead, stages);
    const canFinishProject = this.getCanFinishProjectPermission(lead.Lead, stages);
    const canArchiveProject = this.getCanArchiveProjectPermission(lead.Lead, stages);
    const canReleaseProject = this.getCanReleaseProjectPermission(lead.Lead, stages);
    const canWin = this.getCanWinProjectPermission(lead.Lead, stages);
    const canSeeFreeBillCard = this.getCanSeeFreeBillCardPermission(lead);
    const canAddSupplementary = this.getCanAddSupplimentaryPermission(isReadOnly, lead);
    const canEditManualProjectInfo = this.getCanEditManualProjectInfoPermission(lead);
    const canSeeInvoiceCard = this.getCanSeeInvoiceCardPermission(lead, canSeeInvoice);
    const canManageElektroFormProjects = this.getCanManageElektroformProjectsPermission();
    const canSeeLeadReachedUnreachedDialog = this.getCanSeeLeadReachedUnreachedDialog();
    const canWinWithoutVariant = this.getCanWinWithoutVariantPermission();
    const canAccountProject = this.getCanAccountProjectPermission(lead.Lead, stages);
    const canPutAccountedProjectBackToFinished =
      this.getCanPutAccountedProjectBackToFinishedPermission(lead.Lead, stages);
    const canPutFinishedProjectBackToInProgress =
      this.getCanPutFinishedProjectBackToInProgressPermission(lead.Lead, stages);
    const canPutArchivedProjectBackToAccounted =
      this.getCanPutArchivedProjectBackToAccountedPermission(lead.Lead, stages);
    const canSetVariantSalesProbability = this.getCanSetVariantSalesProbabilityPermission(
      lead.Lead
    );
    const canApproveSolarTarif = this.getCanApproveSolarTarifPermission();
    const canStartOrder = this.getCanStartOrderPermissions(lead.Lead, stages);
    const canPutOrderBackToFollowUp = this.getCanPutOrderBackToFollowUpPermissions(
      lead.Lead,
      stages
    );

    return {
      canAssignToMe,
      canView,
      canModify,
      canAssignToOther,
      canReach,
      canBoSend,
      canFupUnreach,
      canUnreach,
      canAbort,
      canHold,
      canDelegate,
      canSendBackToOwner,
      canTriage,
      canSeeGrossMargin,
      canSeeValidationLight,
      canSeeHelionImportTool,
      canSendEmail,
      canCreateFromExistingLead,
      canSeeProjectDocumentation,
      canDeleteTasksAppointment,
      canSeeInvoice,
      canCreateInvoice,
      canRelease,
      canWonBackToFollowupBo,
      canSeeProjectReporting,
      canAssignProject,
      canStartProject,
      canFinishProject,
      canArchiveProject,
      canReleaseProject,
      canWin,
      canSeeFreeBillCard,
      canAddSupplementary,
      canEditManualProjectInfo,
      canSeeInvoiceCard,
      canManageElektroFormProjects,
      canSeeLeadReachedUnreachedDialog,
      canWinWithoutVariant,
      canAccountProject,
      canPutAccountedProjectBackToFinished,
      canPutFinishedProjectBackToInProgress,
      canPutArchivedProjectBackToAccounted,
      canSetVariantSalesProbability,
      canApproveSolarTarif,
      canStartOrder,
      canPutOrderBackToFollowUp,
    };
  }

  public getCanSeeHelionImportPermission(): boolean {
    return this.permissions.userPermissions.CanSeeHelionImport;
  }

  public getCanSeeExternManagedAddressPermission(addressType: AddressTypeEnum): boolean {
    const externalManagedAddress = this.allExternalManagedAddressConfig.find(
      x => x.addressType === addressType
    );
    return this.permissions.userPermissions[externalManagedAddress?.canSeePermission] ?? false;
  }

  public getCanManageExternManagedAddressPermission(addressType: AddressTypeEnum): boolean {
    const externalManagedAddress = this.allExternalManagedAddressConfig.find(
      x => x.addressType === addressType
    );
    return this.permissions.userPermissions[externalManagedAddress?.canManagePermission] ?? false;
  }

  public getCanUploadProjectDocumentPermission(lead: LeadCompositeViewModel): boolean {
    return this.rights.getLeadRights().canUploadProjectDocumentation(lead.Lead);
  }

  private getCanWinWithoutVariantPermission(): boolean {
    return this.permissions.userPermissions.CanWinWithoutVariant;
  }

  private getCanSeeLeadReachedUnreachedDialog(): boolean {
    return this.permissions.userPermissions.CanSeeLeadReachedUnreachedDialog;
  }

  private getCanManageElektroformProjectsPermission(): boolean {
    return this.permissions.userPermissions.CanManageElektroFormProjects;
  }

  private getCanSeeInvoiceCardPermission(
    lead: LeadCompositeViewModel,
    canSeeInvoice: boolean
  ): boolean {
    return (
      lead.Lead.Project &&
      canSeeInvoice &&
      StageUtil.isStageInArr(lead.Lead.Stage, [
        StageE.PROJECT_IN_PROGRESS,
        StageE.PROJECT_IN_OPERATION,
        StageE.PROJECT_ACCOUNTED,
        StageE.PROJECT_ARCHIVED,
      ])
    );
  }

  private getCanEditManualProjectInfoPermission(lead: LeadCompositeViewModel): boolean {
    return lead.Lead.Project && this.permissions.userPermissions.CanEditManualProjectInformation;
  }

  private getCanAddSupplimentaryPermission(
    isReadOnly: boolean,
    lead: LeadCompositeViewModel
  ): boolean {
    return (
      !isReadOnly &&
      this.permissions.userPermissions.CanCreateInvoice &&
      StageUtil.isStageInArr(lead.Lead.Stage, [
        StageE.PROJECT_IN_PROGRESS,
        StageE.PROJECT_IN_OPERATION,
      ])
    );
  }

  private getCanSeeFreeBillCardPermission(lead: LeadCompositeViewModel): boolean {
    return StageUtil.isStageInArr(lead.Lead.Stage, [
      StageE.PROJECT_IN_PROGRESS,
      StageE.PROJECT_IN_OPERATION,
    ]);
  }

  private getCanWinProjectPermission(
    lead: LeadListViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canWin(lead, stages);
  }

  private getCanTransferBackPermission(
    lead: LeadListViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canTransferback(lead, stages);
  }

  private getCanAbortPermission(lead: LeadListViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canAbort(lead, stages);
  }

  private getCanReleaseProjectPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canReleaseProject(lead, stages);
  }

  private getCanArchiveProjectPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canArchiveProject(lead, stages);
  }

  private getCanFinishProjectPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canFinishProject(lead, stages);
  }

  private getCanPutAccountedProjectBackToFinishedPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canPutAccountedProjectBackToFinished(lead, stages);
  }

  private getCanPutArchivedProjectBackToAccountedPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canPutArchivedProjectBackToAccounted(lead, stages);
  }

  private getCanPutFinishedProjectBackToInProgressPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canPutFinishedProjectBackToInProgress(lead, stages);
  }

  private getCanAccountProjectPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canAccountProject(lead, stages);
  }

  private getCanStartProjectPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canStartProject(lead, stages);
  }

  private getCanAssignProjectPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canAssignProject(lead, stages);
  }

  private getCanSeeProjectPerportingPermission(): boolean {
    return this.permissions.userPermissions.CanSeeProjectReporting;
  }

  private getCanWonBackToFollowupBoPermisssion(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canWonBackToFollowupBo(lead, stages);
  }

  private getCanReleasePermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canRelease(lead, stages);
  }

  private getCanCreateInvoicePermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canCreateInvoice(lead);
  }

  private getCanSeeInvoicePermission(): boolean {
    return this.permissions.userPermissions.CanSeeInvoice;
  }

  private getCanDeleteTaskAppointmentPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canDeleteTaskAppointment(lead);
  }

  private getCanSeeProjectDocumentation(): boolean {
    return this.permissions.userPermissions.CanSeeProjectDocumentation;
  }

  private getCanCreateFromExistingLead(): boolean {
    return this.permissions.userPermissions.CanCreateFromExistingLead;
  }

  private getCanSendEmailPermission(): boolean {
    return this.permissions.userPermissions.CanSendMails;
  }

  private getCanSeeValidationLightPermission(): boolean {
    return this.permissions.userPermissions.CanSeeValidationLight;
  }

  private getCanSeeGrossMarginPermission(): boolean {
    return this.permissions.userPermissions.CanSeeGrossMargin;
  }

  private getCanHoldPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canHold(lead, stages);
  }

  private getCanUnreachPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canUnreach(lead, stages);
  }

  private getCanBoSendPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canBoSend(lead, stages);
  }

  private getCanFupUnreachPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canFupUnreach(lead);
  }

  private getCanReachPermissions(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canReach(lead, stages);
  }

  private getCanViewPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canView(lead);
  }

  private getCanModifyPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canModify(lead);
  }

  private getCanAssignToMePermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canAssignMe(lead, stages);
  }

  private getCanAssignOtherPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canAssignOther(lead, stages);
  }

  private getCanTriageOrHandoverPermission(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.rights.getLeadRights().canTriage(lead, stages) ||
      this.rights.getLeadRights().canHandover(lead, stages)
    );
  }

  private getCanSendBackPermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canSendBackToOwner(lead, stages);
  }

  private getCanEditTracersPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canEditTracers(lead);
  }

  private getCanDelegatePermission(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canDelegate(lead, stages);
  }

  private getCanSetVariantSalesProbabilityPermission(lead: LeadViewModel): boolean {
    return this.rights.getLeadRights().canSetVariantSalesProbability(lead);
  }

  private getCanApproveSolarTarifPermission(): boolean {
    return this.rights.getLeadRights().canApproveSolarTarif();
  }

  private getCanStartOrderPermissions(lead: LeadViewModel, stages: LeadStageViewModel[]): boolean {
    return this.rights.getLeadRights().canStartOrder(lead, stages);
  }

  private getCanPutOrderBackToFollowUpPermissions(
    lead: LeadViewModel,
    stages: LeadStageViewModel[]
  ): boolean {
    return this.rights.getLeadRights().canPutOrderBackToFollowUp(lead, stages);
  }
}
