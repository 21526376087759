import {
  DocumentTemplatesViewModel,
  LeadListViewModel,
  LeadViewModel,
  MasterDataLeadProjectPhaseViewModel,
  PatchChecklistPhaseRequest,
  ProjectChecklistPhaseViewModel,
  ProjectChecklistStepViewModel,
  ProjectChecklistTemplateViewModel,
  ProjectChecklistViewModel,
  SopChecklistPhaseViewModel,
  TaskViewModel,
  UserService,
  UserViewModel,
} from 'app/shared/apis/advis';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  reflectComponentType,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as RootReducer from '../../../shared/states/index';
import {
  CLICK_PROJECT_CHECKLIST_SOP_PHASE_FAILURE,
  CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS,
  CLICK_PROJECT_CHECKLIST_SOP_STEP_FAILURE,
  CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS,
  CLICK_PROJECT_CHECKLIST_STEP_FAILURE,
  CLICK_PROJECT_CHECKLIST_STEP_SUCCESS,
  ICreateProjectChecklistPayload,
  PATCH_PROJECT_CHECKLIST_PHASE_FAILURE,
  PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS,
  ProjectChecklistClickSopPhaseAction,
  ProjectChecklistClickSopStepAction,
  ProjectChecklistClickStepWithSopAction,
  ProjectChecklistCreateAction,
  ProjectChecklistPatchPhaseAction,
  ProjectChecklistTemplatesLoadAction,
  ProjectSopChecklistLoadAction,
  ProjectSopChecklistLoadSuccessAction,
  ProjectSopChecklistAddStepAction,
  ADD_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
  ADD_CUSTOM_SOP_CHECKLIST_STEP_FAILURE,
  ProjectSopChecklistDeleteStepAction,
  DELETE_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
  DELETE_CUSTOM_SOP_CHECKLIST_STEP_FAILURE,
  EDIT_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
  EDIT_CUSTOM_SOP_CHECKLIST_STEP_FAILURE,
  ProjectSopChecklistEditStepAction,
} from '../../../shared/states/lead-project/lead-project.action';
import moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { NotificationService, TypeE } from 'app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModalService } from 'app/shared/services/loading-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as confetti from 'canvas-confetti';
import { QuillModules } from 'ngx-quill';
import { NGX_QUILL_MODULES } from 'app/shared/utils/ngx-quill-editor.settings';
import {
  DocumentDialogComponent,
  IVariantDocumentData,
} from 'app/common-components/dialog/document-dialog/document-dialog.component';
import {
  AccordionComponent,
  COMPONENT_OVERLAY_REF,
  DialogService,
  ProgressBoxStyleEnum,
} from '@sitewerk/theia-ui-lib';
import { OverlayRef } from '@angular/cdk/overlay';
import { filter, take } from 'rxjs/operators';
import {
  IProjectChecklistStepViewModel,
  ISopChecklistPhaseViewModel,
  ISopChecklistStepViewModel,
  ISopChecklistViewModel,
} from '../../../shared/states/lead-project/lead-project.effects';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import { cloneDeep } from 'lodash';
import { AutosaveService } from 'app/common-components/pc-text-editor/autosave.service';
import { environment } from '../../../../environments/environment';
import { PcTextEditorComponent } from '../../../common-components/pc-text-editor/pc-text-editor.component';
import StatusEnum = TaskViewModel.StatusEnum;
import CheckedStateEnum = ProjectChecklistStepViewModel.CheckedStateEnum;

@Component({
  selector: 'pc-lead-project-sop-checklist',
  templateUrl: './lead-project-sop-checklist.component.html',
  styleUrls: ['./lead-project-sop-checklist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LeadProjectSopChecklistComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public lead: LeadListViewModel | LeadViewModel;
  @Input() public isFullScreen: boolean = false;
  @Input() phases: MasterDataLeadProjectPhaseViewModel[] = [];
  @Input() togglePhaseIndex: number | undefined = undefined;
  @Input() selectedPhase: string;
  @Output() selectedPhaseChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('confettiCanvas', { static: false })
  public confettiCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChildren(AccordionComponent)
  public accordion: QueryList<AccordionComponent>;

  public readonly modules: QuillModules = NGX_QUILL_MODULES;

  public checkedStates = SopChecklistPhaseViewModel.CheckedStateEnum;
  public stepCheckedStates: any = ProjectChecklistStepViewModel.CheckedStateEnum;
  public checklistTemplates: ProjectChecklistTemplateViewModel[] = [];
  public projectCheckList: ISopChecklistViewModel = {};
  public users: UserViewModel[] = [];
  public dirtyPhaseNotes: Set<number> = new Set<number>();
  public modalName: string;
  public documentTemplates: DocumentTemplatesViewModel;
  public progressBoxStyle = ProgressBoxStyleEnum;
  public isResponsible: boolean = false;
  public activeCustomStepIndex: string;
  public customStepName: string;
  public customStepBottom: boolean;
  public editingCustomStep: IProjectChecklistStepViewModel;

  private readonly subscriptions: Subscription = new Subscription();
  private leadId: number;
  private leadLanguage: LeadViewModel.LanguageEnum;
  private confettiAnimationEnd: number;
  private isCurrentChecklistCompleted: boolean | undefined = undefined;
  private initialChecklist: ISopChecklistViewModel;

  constructor(
    private userService: UserService,
    private store: Store,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private action$: Actions,
    private translate: TranslateService,
    private loadingModalService: LoadingModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private autosaveService: AutosaveService,
    @Optional() @Inject(COMPONENT_OVERLAY_REF) public overlayRef: OverlayRef
  ) {
    this.modalName = reflectComponentType(LeadProjectSopChecklistComponent).selector;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.lead && this.lead) {
      this.leadId = this.lead.Id;
      if ('PublicLeadId' in this.lead) {
        this.leadLanguage = this.lead.LeadLanguage as LeadViewModel.LanguageEnum;
      } else if ('PublicId' in this.lead) {
        this.leadLanguage = this.lead.Language;
        this.synchronizeChecklistWithTasks(this.lead);
      }

      if (isNullOrUndefined(this.projectCheckList?.SopListId)) {
        this.store.dispatch(new ProjectSopChecklistLoadAction(this.leadId));
      }

      if (!this.checklistTemplates?.length) {
        this.store.dispatch(new ProjectChecklistTemplatesLoadAction(this.leadId));
      }
    }

    if (changes.selectedPhase) {
      this.accordion.forEach(accordion => accordion.openAll());
    }
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.data.subscribe((data: any) => {
        this.isResponsible = data?.action?.isResponsible;
      })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getProjectSopChecklistLoading)
        .subscribe((sopChecklistLoading: boolean) => {
          if (sopChecklistLoading) {
            this.loadingModalService.openModal(this.modalName);
          } else {
            this.loadingModalService.closeModal(this.modalName);
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getProjectSopChecklistLoaded)
        .subscribe((checklistLoaded: boolean) => {
          if (checklistLoaded) {
            this.loadingModalService.closeModal(this.modalName);
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select<Set<number>>(RootReducer.getGlobalSelectedMandants)
        .subscribe((selectedMandators: Set<number>) =>
          this.subscriptions.add(
            this.userService
              .userGetAllUsersWithAnyPermission(
                Array.from(selectedMandators.values()).join(','),
                'CanSeeProjectLeads'
              )
              .subscribe((users: UserViewModel[]) => (this.users = users))
          )
        )
    );

    this.subscriptions.add(
      combineLatest([
        this.store.select(RootReducer.getProjectSopChecklist),
        this.store.select(RootReducer.getProjectChecklistTemplates),
      ]).subscribe(
        ([checklist, templates]: [
          ProjectChecklistViewModel,
          ProjectChecklistTemplateViewModel[]
        ]) => {
          this.initializeTemplates(checklist, templates);
        }
      )
    );

    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(
            CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS,
            CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS
          )
        )
        .subscribe(() => this.notificationService.notifyDataSaved())
    );

    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(
            CLICK_PROJECT_CHECKLIST_STEP_SUCCESS,
            CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS,
            CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS
          )
        )
        .subscribe(() => {
          this.loadingModalService.closeModal(this.modalName);
        })
    );

    this.subscriptions.add(
      this.action$.pipe(ofType(CLICK_PROJECT_CHECKLIST_STEP_SUCCESS)).subscribe(() => {
        this.onChecklistClickSuccess();
      })
    );

    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(
            CLICK_PROJECT_CHECKLIST_STEP_FAILURE,
            PATCH_PROJECT_CHECKLIST_PHASE_FAILURE,
            CLICK_PROJECT_CHECKLIST_SOP_PHASE_FAILURE,
            CLICK_PROJECT_CHECKLIST_SOP_STEP_FAILURE
          )
        )
        .subscribe(() => this.notificationService.notifySimple('Failed to save', TypeE.ALERT))
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getDocumentTemplates)
        .subscribe((documentTemplates: DocumentTemplatesViewModel) => {
          this.documentTemplates = documentTemplates;
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store.dispatch(new ProjectSopChecklistLoadSuccessAction({}));
  }

  public getTimeWarningClass(step: IProjectChecklistStepViewModel): string {
    if (step.CheckedState === this.stepCheckedStates.Unchecked && step.Deadline) {
      const deadline = moment(step.Deadline);
      const now = moment();
      const days = deadline.diff(now, 'days');

      if (step.IsTimeWarning) {
        return 'text-warning';
      }

      if (days < 1) {
        return 'text-orange';
      }

      return 'text-green';
    }

    return undefined;
  }

  public getTimeWarningTooltip(step: IProjectChecklistStepViewModel): string {
    if (step.CheckedState === this.stepCheckedStates.Unchecked && step.Deadline) {
      const deadline = this.translate.instant(
        'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.DEADLINE'
      );
      return `${deadline}: ${moment(step.Deadline).format('DD.MM.YYYY')}`;
    }
    return undefined;
  }

  public getTooltip(step: IProjectChecklistStepViewModel): string {
    if (step.IsLockedByRequiredSteps) {
      const requiredSteps = this.translate.instant(
        'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.REQUIRED_STEPS'
      );
      return `${requiredSteps}: ${step.RequiredStepIndexes}`;
    }
    if (
      (step.CheckedState === this.stepCheckedStates.Checked ||
        step.CheckedState === this.stepCheckedStates.NotRequired) &&
      step.ModifiedDate &&
      step.ModifiedById
    ) {
      const user: UserViewModel = this.users.find((x: UserViewModel) => x.Id === step.ModifiedById);
      if (user) {
        return `${user.FirstName} ${user.LastName} ${
          user.IsLocked ? ' (deactivated)' : ''
        }, ${moment(step.ModifiedDate).format('DD.MM.YYYY')}`;
      } else {
        return `${moment(step.ModifiedDate).format('DD.MM.YYYY')}`;
      }
    }
    return undefined;
  }

  public trackByPhaseKey(_: number, phase: ISopChecklistPhaseViewModel): number {
    return phase.SopPhase.Key;
  }

  public trackBySopChecklistStepId(_: number, step: ISopChecklistStepViewModel): number {
    return step.Id;
  }

  public trackByProjectChecklistStepId(_: number, step: IProjectChecklistStepViewModel): string {
    return step.Id;
  }

  public focusPhaseNotes(phase: ISopChecklistPhaseViewModel): void {
    this.dirtyPhaseNotes.add(phase.SopPhase.Key);
  }

  public blurPhaseNotes(phase: ISopChecklistPhaseViewModel): void {
    const phaseIndex = this.projectCheckList.SopChecklistPhases.findIndex(p => p === phase);
    const hasUnsavedNotes =
      this.initialChecklist.SopChecklistPhases[phaseIndex].Notes !==
      this.projectCheckList.SopChecklistPhases[phaseIndex].Notes;

    if (!hasUnsavedNotes) {
      this.dirtyPhaseNotes.delete(phase.SopPhase.Key);
    }
  }

  public saveNotes(phase: ISopChecklistPhaseViewModel, editorId: string): void {
    this.autosaveService.autosaveInProgress(editorId);
    this.store.dispatch(
      new ProjectChecklistPatchPhaseAction({
        leadId: this.leadId,
        request: [
          {
            Phase: PatchChecklistPhaseRequest.PhaseEnum[phase.SopPhase.DisplayKey],
            Notes: phase.Notes,
          },
        ],
      })
    );
    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS, PATCH_PROJECT_CHECKLIST_PHASE_FAILURE),
          take(1)
        )
        .subscribe((action: ProjectChecklistPatchPhaseAction | ProjectChecklistCreateAction) =>
          action.type === PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS
            ? this.autosaveService.autosaveSuccess(editorId)
            : this.autosaveService.autosaveFailed(editorId)
        )
    );
    this.dirtyPhaseNotes.delete(phase.SopPhase.Key);
  }

  public setInitialPhaseNotesValue(
    editorComponent: PcTextEditorComponent,
    phase: ProjectChecklistPhaseViewModel
  ): void {
    editorComponent.setEditorData(phase.Notes);
  }

  public clickChecklistStep(step: IProjectChecklistStepViewModel, event: MouseEvent): void {
    if (step.IsLockedByRequiredSteps || this.isHtmlTag(event.target as HTMLElement, 'a')) {
      event.stopImmediatePropagation();
      return;
    }

    this.clickChecklistStepById(step.Id);
  }

  public onStepLinkClick(event: MouseEvent, step: IProjectChecklistStepViewModel): void {
    if (step.DocumentTemplateId) {
      event.preventDefault();

      const selectedTemplateId = this.documentTemplates.DocumentTemplates.find(
        t => t.Id === +step.DocumentTemplateId
      );
      this.dialogService.open(DocumentDialogComponent, {
        data: {
          id: this.leadId,
          isLead: true,
          language: this.leadLanguage,
          selectedTemplateId: selectedTemplateId?.Id,
          preselection: false,
          warningText: !selectedTemplateId
            ? this.translate.instant(
                'VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.MESSAGE_TEMPLATE_NOT_FOUND'
              )
            : undefined,
          templates: {
            Attachments: [],
            EmailTemplates: [],
            DocumentTemplates: this.documentTemplates.DocumentTemplates,
          },
        } as IVariantDocumentData,
      });
    }
  }

  public onAddTask(step: IProjectChecklistStepViewModel): void {
    const title = step.Title;
    // sop phase keys start at 1, previous phases at 0, therefore we need to subtract 1 to get the correct phase
    const phaseId = step.SopPhase.Key - 1;
    const stepId = step.Id;
    this.router.navigate([`lead/detail/${this.projectCheckList.ChecklistId}/task/add`], {
      queryParams: { title: title, checklistStepPhaseId: phaseId, checklistStepId: stepId },
    });
  }

  public onEditTask(taskId: number): void {
    this.router.navigate([`lead/detail/${this.projectCheckList.ChecklistId}/task`, taskId]);
  }

  public goToFullChecklist(): void {
    this.router.navigate([`lead/detail/${this.lead.Id}/project-status`]);
  }

  public clickSopStep(step: ISopChecklistStepViewModel, event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant(
            'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.CONFIRM_SOP_CLICK'
          ),
        })
        .afterClosed()
        .pipe(filter((res: boolean) => !!res))
        .subscribe(() => {
          this.loadingModalService.openModal(this.modalName);

          this.store.dispatch(
            new ProjectChecklistClickSopStepAction({
              leadId: this.leadId,
              stepId: step.Id,
              desiredCheckedState: step.CheckedState !== CheckedStateEnum.Checked,
            })
          );
        })
    );
  }

  public clickPhase(phase: ISopChecklistPhaseViewModel, event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant(
            'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.CONFIRM_SOP_CLICK'
          ),
        })
        .afterClosed()
        .pipe(filter((res: boolean) => !!res))
        .subscribe(() => {
          this.loadingModalService.openModal(this.modalName);

          this.store.dispatch(
            new ProjectChecklistClickSopPhaseAction({
              leadId: this.leadId,
              phaseKey: phase.SopPhase.Key,
              desiredCheckedState: phase.CheckedState !== CheckedStateEnum.Checked,
            })
          );
        })
    );
  }

  public toggleAccordionCollapsedState(event: boolean): void {
    if (event) {
      this.accordion.forEach(accordion => accordion.openAll());
    } else {
      this.accordion.forEach(accordion => accordion.closeAll());
    }
  }

  public createChecklist(template: ProjectChecklistTemplateViewModel): void {
    if (
      this.getTicks(template.TemplateDate) ===
      this.getTicks(this.projectCheckList.ChecklistTemplateDate)
    ) {
      return;
    }

    const createChecklistPayload: ICreateProjectChecklistPayload = {
      leadId: this.leadId,
      templateId: template.Id,
    };

    if (this.projectCheckList?.SopListId) {
      this.subscriptions.add(
        this.dialogService
          .openConfirm({
            message: this.translate.instant(
              'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.CONFIRM_CREATE_NEW'
            ),
            title: this.translate.instant('DIALOG.TITLE_CONFIRM'),
            acceptButton: this.translate.instant('COMMON.BTN_OK'),
            cancelButton: this.translate.instant('COMMON.BTN_CLOSE'),
          })
          .afterClosed()
          .subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.store.dispatch(new ProjectChecklistCreateAction(createChecklistPayload));
              this.toggleAccordionCollapsedState(false);
            }
          })
      );
    } else {
      this.store.dispatch(new ProjectChecklistCreateAction(createChecklistPayload));
    }
  }

  public onCustomStepClick(
    index: string,
    sopStepId: number,
    customStepName: HTMLDivElement,
    bottom?: boolean
  ): void {
    this.customStepBottom = bottom;
    this.activeCustomStepIndex = index;
    setTimeout(() => {
      const customStepNameInput = customStepName?.querySelector('input');
      if (customStepNameInput) {
        customStepNameInput.focus();
        customStepNameInput.onblur = () => {
          if (this.customStepName) {
            this.subscriptions.add(
              this.action$
                .pipe(
                  ofType(
                    ADD_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
                    ADD_CUSTOM_SOP_CHECKLIST_STEP_FAILURE
                  ),
                  take(1)
                )
                .subscribe((action: Action) => {
                  if (action.type === ADD_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS) {
                    this.store.dispatch(new ProjectSopChecklistLoadAction(this.leadId));
                  }
                  this.loadingModalService.closeModal();
                  this.activeCustomStepIndex = undefined;
                  this.customStepName = undefined;
                  this.customStepBottom = undefined;
                })
            );

            this.loadingModalService.openModal();
            this.store.dispatch(
              new ProjectSopChecklistAddStepAction({
                leadId: this.leadId,
                TargetIndex: bottom
                  ? this.activeCustomStepIndex
                      .split('.')
                      .reduce(
                        (res, i, index, arr) => `${res}.${index === arr.length - 1 ? +i + 1 : i}`
                      )
                  : this.activeCustomStepIndex,
                SopStepId: sopStepId,
                Title: this.customStepName,
              })
            );
          } else {
            this.activeCustomStepIndex = undefined;
            this.customStepName = undefined;
            this.customStepBottom = undefined;
          }
        };
      }
    });
  }

  deleteCustomStep(step: IProjectChecklistStepViewModel): void {
    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant(
            'LEAD_DETAIL.PROJECT_STATUS.DELETE_CUSTOM_SOP_CHECKLIST_STEP'
          ),
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.loadingModalService.openModal();
            this.store.dispatch(
              new ProjectSopChecklistDeleteStepAction({
                leadId: this.leadId,
                stepId: step.Id,
              })
            );
          }
        })
    );

    this.subscriptions.add(
      this.action$
        .pipe(
          ofType(
            DELETE_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
            DELETE_CUSTOM_SOP_CHECKLIST_STEP_FAILURE
          ),
          take(1)
        )
        .subscribe((action: Action) => {
          if (action.type === DELETE_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS) {
            this.store.dispatch(new ProjectSopChecklistLoadAction(this.leadId));
          }
          this.loadingModalService.closeModal();
        })
    );
  }

  editCustomStep(step: IProjectChecklistStepViewModel, stepRef: HTMLDivElement): void {
    this.editingCustomStep = step;
    const customStepName = step.Title;
    setTimeout(() => {
      const customStepNameInput: HTMLInputElement = stepRef?.querySelector('input[type="text"]');
      if (customStepNameInput) {
        customStepNameInput.focus();
        customStepNameInput.onblur = () => {
          if (customStepName !== this.editingCustomStep.Title) {
            this.subscriptions.add(
              this.action$
                .pipe(
                  ofType(
                    EDIT_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS,
                    EDIT_CUSTOM_SOP_CHECKLIST_STEP_FAILURE
                  ),
                  take(1)
                )
                .subscribe((action: Action) => {
                  if (action.type === EDIT_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS) {
                    this.store.dispatch(new ProjectSopChecklistLoadAction(this.leadId));
                  }
                  this.editingCustomStep = undefined;
                  this.loadingModalService.closeModal();
                })
            );

            this.loadingModalService.openModal();
            this.store.dispatch(
              new ProjectSopChecklistEditStepAction({
                leadId: this.leadId,
                body: {
                  Title: this.editingCustomStep.Title,
                },
                stepId: step.Id,
              })
            );
          } else {
            this.editingCustomStep = undefined;
          }
        };
      }
    });
  }

  private getTicks(input: Date): number {
    return new Date(input).getTime();
  }

  private initializeTemplates(
    checklist: ISopChecklistViewModel,
    templates: ProjectChecklistTemplateViewModel[]
  ): void {
    this.projectCheckList = cloneDeep(checklist);
    this.initialChecklist = cloneDeep(checklist);
    if (checklist.ChecklistId) {
      const currentChecklistTemplateIndex: number = templates.findIndex(
        (x: ProjectChecklistTemplateViewModel) =>
          this.getTicks(x.TemplateDate) === this.getTicks(checklist.ChecklistTemplateDate)
      );
      if (currentChecklistTemplateIndex < 0) {
        const currentChecklistTemplate: ProjectChecklistTemplateViewModel = {
          Title: checklist.ChecklistTitle,
          TemplateDate: checklist.ChecklistTemplateDate,
        };
        this.checklistTemplates = [currentChecklistTemplate, ...templates];
      } else {
        this.checklistTemplates = templates;
      }
    } else {
      this.checklistTemplates = templates;
    }
  }

  private onChecklistClickSuccess(): void {
    const wasCompleted = this.isCurrentChecklistCompleted;
    this.isCurrentChecklistCompleted = this.projectCheckList.ChecklistIsCompleted;

    if (wasCompleted === false && this.isCurrentChecklistCompleted === true) {
      this.notifyProjectComplete();
    } else {
      this.notificationService.notifyDataSaved();
    }
  }

  private notifyProjectComplete(): void {
    const alertSnackbar = this.notificationService.notifyWithAction(
      this.translate.instant(
        this.translate.instant('LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.PROJECT_COMPLETE')
      ),
      this.translate.instant('COMMON.BTN_DISMISS'),
      TypeE.PRIMARY,
      10 * 1000
    );

    this.subscriptions.add(
      alertSnackbar.afterDismissed().subscribe(() => {
        this.confettiAnimationEnd = Date.now();
      })
    );

    this.startConfettiFirework();
  }

  private startConfettiFirework(): void {
    const confettiOnCanvas = confetti.create(this.confettiCanvas.nativeElement, {
      resize: true,
    });

    const duration = 10 * 1000;
    this.confettiAnimationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min: number, max: number): number {
      return Math.random() * (max - min) + min;
    }

    const interval: any = setInterval(() => {
      const timeLeft = this.confettiAnimationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confettiOnCanvas(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confettiOnCanvas(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  }

  private isHtmlTag(element: HTMLElement, tagName: string): boolean {
    return element?.tagName.toLocaleLowerCase() === tagName;
  }

  private clickChecklistStepById(stepId: string): void {
    this.loadingModalService.openModal(this.modalName);

    this.store.dispatch(
      new ProjectChecklistClickStepWithSopAction({
        leadId: this.leadId,
        stepId: stepId,
      })
    );
  }

  private synchronizeChecklistWithTasks(leadViewModel: LeadViewModel): void {
    const linkedTasks = leadViewModel.Tasks.filter(t => !!t.LinkedProjectChecklistStepId);
    linkedTasks.forEach(task => {
      const step = this.projectCheckList?.SopChecklistPhases?.flatMap(p => p.SopSteps)
        ?.flatMap(p => p.ChecklistSteps)
        ?.find(s => s.Id === task.LinkedProjectChecklistStepId);
      if (step) {
        this.syncronizeTaskWithStep(task, step);
      }
    });
  }

  private syncronizeTaskWithStep(task: TaskViewModel, step: IProjectChecklistStepViewModel): void {
    const taskIsChecked = task.Status === StatusEnum.Completed;
    const stepIsChecked = step.CheckedState === CheckedStateEnum.Checked;

    if (taskIsChecked !== stepIsChecked) {
      step.CheckedState = taskIsChecked ? CheckedStateEnum.Checked : CheckedStateEnum.Unchecked;
    }
  }

  protected readonly environment = environment;
}
