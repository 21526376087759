import { tassign } from 'tassign';

import * as TaskAction from './task.action';
import { TaskViewModel } from '../../apis/advis/model/taskViewModel';

export interface IState {
  tasks: TaskViewModel[];
  tasksLoaded: boolean;
  tasksLoading: boolean;
}

export const initialState: IState = {
  tasks: [],
  tasksLoaded: false,
  tasksLoading: false,
};

export function reducer(state: IState = initialState, action: TaskAction.Actions): IState {
  switch (action.type) {
    case TaskAction.TASKS_LOAD: {
      return tassign(state, state, {
        tasks: action.payload,
        tasksLoaded: false,
        tasksLoading: true,
      });
    }

    case TaskAction.TASKS_LOAD_SUCCESS: {
      return tassign(state, {
        tasks: action.payload,
        tasksLoaded: true,
        tasksLoading: false,
      });
    }

    case TaskAction.TASKS_LOAD_FAILURE: {
      return tassign(state, {
        tasks: [],
        tasksLoaded: true,
        tasksLoading: false,
      });
    }

    default: {
      return state;
    }
  }
}

export const getTasksState: (state: IState) => TaskViewModel[] = (state: IState) => state.tasks;
export const getTasksLoadedState: (state: IState) => boolean = (state: IState) => state.tasksLoaded;
export const getTasksLoadingState: (state: IState) => boolean = (state: IState) =>
  state.tasksLoading;
