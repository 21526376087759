import * as user from './user.action';
import {
  CurrentUserViewModel,
  FileLinkViewModel,
  NoteTemplateViewModel,
  UserInfo,
} from '../../apis/advis';
import { tassign } from 'tassign';

export interface IState {
  userInfo: UserInfo;
  currentUser: CurrentUserViewModel;
  currentUserFiles: FileLinkViewModel[];
  userLoading: boolean;
  userFilesLoading: boolean;
  noteTemplates: NoteTemplateViewModel[];
  noteTemplatesLoading: boolean;
}

export const initialState: IState = {
  userInfo: undefined,
  currentUser: {
    CompanyAddress: {},
  },
  currentUserFiles: [],
  userLoading: false,
  userFilesLoading: false,
  noteTemplates: [],
  noteTemplatesLoading: false,
};

export function reducer(state: IState = initialState, action: user.Actions): IState {
  switch (action.type) {
    case user.GET_USER_INFO_SUCCESS: {
      return tassign(state, {
        userInfo: action.payload,
      });
    }

    case user.GET_USER_INFO_FAILED: {
      return tassign(state, {
        userInfo: undefined,
      });
    }

    case user.GET_USER: {
      return tassign(state, {
        userLoading: true,
      });
    }

    case user.GET_USER_SUCCESS: {
      return tassign(state, {
        currentUser: action.payload,
        userLoading: false,
      });
    }

    case user.GET_USER_FAILED: {
      return tassign(state, {
        currentUser: undefined,
        userLoading: false,
      });
    }

    case user.GET_USER_FILES: {
      return tassign(state, {
        userFilesLoading: true,
      });
    }

    case user.GET_USER_FILES_SUCCESS: {
      return tassign(state, {
        currentUserFiles: action.payload,
        userFilesLoading: false,
      });
    }

    case user.GET_USER_FILES_FAILED: {
      return tassign(state, {
        currentUser: undefined,
        currentUserFiles: [],
        userFilesLoading: false,
      });
    }

    case user.PATCH_USER: {
      return tassign(state, {
        userLoading: true,
      });
    }

    case user.PATCH_USER_SUCCESS: {
      return tassign(state, {
        currentUser: action.payload,
        userLoading: false,
      });
    }

    case user.PATCH_USER_FAILED: {
      return tassign(state, {
        userLoading: false,
      });
    }

    case user.GET_NOTE_TEMPLATES: {
      return tassign(state, {
        noteTemplatesLoading: true,
      });
    }

    case user.GET_NOTE_TEMPLATES_SUCCESS: {
      return tassign(state, {
        noteTemplatesLoading: false,
        noteTemplates: action.payload,
      });
    }

    case user.GET_NOTE_TEMPLATES_FAILED: {
      return tassign(state, {
        noteTemplatesLoading: false,
        noteTemplates: [],
      });
    }

    case user.SAVE_NOTE_TEMPLATES: {
      return tassign(state, {
        noteTemplatesLoading: true,
      });
    }

    case user.SAVE_NOTE_TEMPLATES_SUCCESS: {
      return tassign(state, {
        noteTemplatesLoading: false,
        noteTemplates: action.payload,
      });
    }

    case user.SAVE_NOTE_TEMPLATES_FAILED: {
      return tassign(state, {
        noteTemplatesLoading: false,
        noteTemplates: [],
      });
    }

    default: {
      return state;
    }
  }
}

export const getUserInfo: (state: IState) => UserInfo = (state: IState) => state.userInfo;
export const getCurrentUser: (state: IState) => CurrentUserViewModel = (state: IState) =>
  state.currentUser;
export const getCurrentUserFiles: (state: IState) => FileLinkViewModel[] = (state: IState) =>
  state.currentUserFiles;
export const getUserLoading: (state: IState) => boolean = (state: IState) => state.userLoading;
export const getNoteTemplates: (state: IState) => NoteTemplateViewModel[] = (state: IState) =>
  state.noteTemplates;
export const getNoteTemplatesLoading: (state: IState) => boolean = (state: IState) =>
  state.noteTemplatesLoading;
