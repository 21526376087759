import { LeadOverviewVsModel } from './lead-overview-vs.model';
import { LeadOverviewOwnVsModel } from './lead-overview-own-vs.model';
import { SelectedMandantsVsModel } from './selected-mandants-vs.model';
import { VariantVsModel } from './variant-vs.model';
import { LeadOverviewTasksVsModel } from './lead-overview-tasks-vs.model';
import { LeadOverviewSettingsModel } from './lead-overview-settings.model';
import { AddressbookLeadOverviewSettingsModel } from './address-book-overview-settings.model';

export class ViewSettingModel {
  selectedMandants: SelectedMandantsVsModel;
  // selectedMandantsOnlinepool: SelectedMandantsVsModel;
  leadOverview: LeadOverviewVsModel;
  leadOverviewOwn: LeadOverviewOwnVsModel;
  leadOverviewNew: LeadOverviewSettingsModel;
  leadOverviewFollowup: LeadOverviewSettingsModel;
  leadOverviewOrderEntry: LeadOverviewSettingsModel;
  leadOverviewAll: LeadOverviewSettingsModel;
  leadOverviewOnlinepool: LeadOverviewSettingsModel;
  leadOverviewDownloadpool: LeadOverviewSettingsModel;
  leadOverviewCallpool: LeadOverviewSettingsModel;
  leadOverviewTriage: LeadOverviewSettingsModel;
  leadOverviewOwnTasks: LeadOverviewTasksVsModel;
  leadOverviewDelegatedTasks: LeadOverviewTasksVsModel;
  leadOverviewOnHold: LeadOverviewSettingsModel;
  leadOverviewWon: LeadOverviewSettingsModel;
  leadOverviewNotAssigned: LeadOverviewSettingsModel;
  leadOverviewProjectsInProgress: LeadOverviewSettingsModel;
  leadOverviewProjectOverview: LeadOverviewSettingsModel;
  leadOverviewProjectOverviewOwn: LeadOverviewSettingsModel;
  leadOverviewEsApproved: LeadOverviewSettingsModel;
  leadOverviewEsDisapproved: LeadOverviewSettingsModel;
  leadOverviewEsNotSet: LeadOverviewSettingsModel;
  addressbookOverview: AddressbookLeadOverviewSettingsModel;
  variant: VariantVsModel;

  constructor() {
    this.selectedMandants = new SelectedMandantsVsModel();
    // this.selectedMandantsOnlinepool = new SelectedMandantsVsModel();
    this.leadOverview = new LeadOverviewVsModel();
    this.leadOverviewOwn = new LeadOverviewOwnVsModel();
    this.leadOverviewNew = new LeadOverviewSettingsModel();
    this.leadOverviewFollowup = new LeadOverviewSettingsModel();
    this.leadOverviewOrderEntry = new LeadOverviewSettingsModel();
    this.leadOverviewAll = new LeadOverviewSettingsModel();
    this.leadOverviewOnlinepool = new LeadOverviewSettingsModel();
    this.leadOverviewDownloadpool = new LeadOverviewSettingsModel();
    this.leadOverviewCallpool = new LeadOverviewSettingsModel();
    this.leadOverviewTriage = new LeadOverviewSettingsModel();
    this.leadOverviewOwnTasks = new LeadOverviewTasksVsModel();
    this.leadOverviewDelegatedTasks = new LeadOverviewTasksVsModel();
    this.leadOverviewOnHold = new LeadOverviewSettingsModel();
    this.leadOverviewWon = new LeadOverviewSettingsModel();
    this.leadOverviewNotAssigned = new LeadOverviewSettingsModel();
    this.leadOverviewProjectsInProgress = new LeadOverviewSettingsModel();
    this.leadOverviewProjectOverview = new LeadOverviewSettingsModel();
    this.leadOverviewProjectOverviewOwn = new LeadOverviewSettingsModel();
    this.leadOverviewEsApproved = new LeadOverviewSettingsModel();
    this.leadOverviewEsDisapproved = new LeadOverviewSettingsModel();
    this.leadOverviewEsNotSet = new LeadOverviewSettingsModel();
    this.addressbookOverview = new AddressbookLeadOverviewSettingsModel();
    this.variant = new VariantVsModel();
  }
}
