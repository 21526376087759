import { Action } from '@ngrx/store';
import { LeadCompositeViewModel, LeadStatusViewModel, TaskViewModel } from '../../apis/advis';
import { HttpErrorResponse } from '@angular/common/http';
import { WinLeadRequest } from '../../apis/advis/model/winLeadRequest';

export const ACTION_CONTACT: string = '[Lead] Action - Contact';
export const ACTION_CONTACT_SUCCESS: string = '[Lead] Action - Contact Success';
export const ACTION_CONTACT_FAILURE: string = '[Lead] Action - Contact Failure';

export const ACTION_UNREACHED: string = '[Lead] Action - Unreached';
export const ACTION_UNREACHED_SUCCESS: string = '[Lead] Action - Unreached Success';
export const ACTION_UNREACHED_FAILURE: string = '[Lead] Action - Unreached Failure';

export const ACTION_WIN: string = '[Lead] Action - Variant win';
export const ACTION_WIN_SUCCESS: string = '[Lead] Action - Variant win Success';
export const ACTION_WIN_FAILURE: string = '[Lead] Action - Variant win Failure';

export const ACTION_START_ORDER: string = '[Lead] Action - Start order';
export const ACTION_START_ORDER_SUCCESS: string = '[Lead] Action - Start order Success';
export const ACTION_START_ORDER_FAILURE: string = '[Lead] Action - Start order Failure';

export const ACTION_ORDER_BACK_TO_FOLLOWUP: string = '[Lead] Action - Order back to followup';
export const ACTION_ORDER_BACK_TO_FOLLOWUP_SUCCESS: string =
  '[Lead] Action - Order back to followup Success';
export const ACTION_ORDER_BACK_TO_FOLLOWUP_FAILURE: string =
  '[Lead] Action - Order back to followup Failure';

export const ACTION_ASSIGN: string = '[Lead] Action - Assign';
export const ACTION_ASSIGN_SUCCESS: string = '[Lead] Action - Assign Success';
export const ACTION_ASSIGN_FAILURE: string = '[Lead] Action - Assign Failure';

export const ACTION_DELEGATE: string = '[Lead] Action - Delegate';
export const ACTION_DELEGATE_SUCCESS: string = '[Lead] Action - Delegate Success';
export const ACTION_DELEGATE_FAILURE: string = '[Lead] Action - Delegate Failure';

export const ACTION_SEND_BACK: string = '[Lead] Action - Send Back';
export const ACTION_SEND_BACK_SUCCESS: string = '[Lead] Action - Send Back Success';
export const ACTION_SEND_BACK_FAILURE: string = '[Lead] Action - Send Back Failure';

export const ACTION_TRIAGE: string = '[Lead] Action - Triage';
export const ACTION_TRIAGE_SUCCESS: string = '[Lead] Action - Triage Success';
export const ACTION_TRIAGE_FAILURE: string = '[Lead] Action - Triage Failure';

export const ACTION_CONTACT_AND_TRIAGE: string = '[Lead] Action - Contact and Triage';
export const ACTION_CONTACT_AND_TRIAGE_SUCCESS: string =
  '[Lead] Action - Contact and Triage Success';
export const ACTION_CONTACT_AND_TRIAGE_FAILURE: string =
  '[Lead] Action - Contact and Triage Failure';

export const ACTION_CONTACT_AND_TS_WIN: string = '[Lead] Action - Contact and TS Win';
export const ACTION_CONTACT_AND_TS_WIN_SUCCESS: string =
  '[Lead] Action - Contact and TS Win Success';
export const ACTION_CONTACT_AND_TS_WIN_FAILURE: string =
  '[Lead] Action - Contact and TS Win Failure';

export const ACTION_DEPOSIT: string = '[Lead] Action - Deposit';
export const ACTION_DEPOSIT_SUCCESS: string = '[Lead] Action - Deposit Success';
export const ACTION_DEPOSIT_FAILURE: string = '[Lead] Action - Deposit Failure';

export const ACTION_TRANSFER: string = '[Lead] Action - Transfer';
export const ACTION_TRANSFER_SUCCESS: string = '[Lead] Action - Transfer Success';
export const ACTION_TRANSFER_FAILURE: string = '[Lead] Action - Transfer Failure';

export const ACTION_TRANSFER_MULTIPLE: string = '[Lead] Action - Transfer Multiple';
export const ACTION_TRANSFER_MULTIPLE_SUCCESS: string = '[Lead] Action - Transfer Multiple Success';

export const ACTION_RELEASE: string = '[Lead] Action - Release';
export const ACTION_RELEASE_SUCCESS: string = '[Lead] Action - Release Success';
export const ACTION_RELEASE_FAILURE: string = '[Lead] Action - Release Failure';

export const ACTION_WON_BACK_TO_FOLLOWUP_BO: string = '[Lead] Action - WonBackToFollowupBo';
export const ACTION_WON_BACK_TO_FOLLOWUP_BO_SUCCESS: string =
  '[Lead] Action - WonBackToFollowupBo Success';
export const ACTION_WON_BACK_TO_FOLLOWUP_BO_FAILURE: string =
  '[Lead] Action - WonBackToFollowupBo Failure';

export const ACTION_TRANSFER_BACK: string = '[Lead] Action - Transfer Back';
export const ACTION_TRANSFER_BACK_SUCCESS: string = '[Lead] Action - Transfer Back Success';
export const ACTION_TRANSFER_BACK_FAILURE: string = '[Lead] Action - Transfer Back Failure';

export const ACTION_ON_HOLD: string = '[Lead] Action - On Hold';
export const ACTION_ON_HOLD_SUCCESS: string = '[Lead] Action - On Hold Success';
export const ACTION_ON_HOLD_FAILURE: string = '[Lead] Action - On Hold Failure';

export const ACTION_ABORT: string = '[Lead] Action - Abort';
export const ACTION_ABORT_SUCCESS: string = '[Lead] Action - Abort Success';
export const ACTION_ABORT_FAILURE: string = '[Lead] Action - Abort Failure';

export const ACTION_ABORT_MULTIPLE: string = '[Lead] Action - Abort Multiple';
export const ACTION_ABORT_MULTIPLE_SUCCESS: string = '[Lead] Action - Abort Multiple Success';

export const ACTION_HOLD_MULTIPLE: string = '[Lead] Action - OnHold Multiple';
export const ACTION_HOLD_MULTIPLE_SUCCESS: string = '[Lead] OnHold - Abort Multiple Success';

export const ACTION_RELEASE_MULTIPLE: string = '[Lead] Action - Release Multiple';
export const ACTION_RELEASE_MULTIPLE_SUCCESS: string = '[Lead] Release - Release Multiple Success';

export const ACTION_NBO_SENT: string = '[Lead] Action - NBO Sent';
export const ACTION_NBO_SENT_SUCCESS: string = '[Lead] Action - NBO Sent Success';
export const ACTION_NBO_SENT_FAILURE: string = '[Lead] Action - NBO Sent Failure';

export const ACTION_BO_SENT: string = '[Lead] Action - BO Sent';
export const ACTION_BO_SENT_SUCCESS: string = '[Lead] Action - BO Sent Success';
export const ACTION_BO_SENT_FAILURE: string = '[Lead] Action - BO Sent Failure';

export const ACTION_PROJECT_ASSIGN: string = '[Lead] Action - Project assign';
export const ACTION_PROJECT_ASSIGN_SUCCESS: string = '[Lead] Action - Project assign Success';
export const ACTION_PROJECT_ASSIGN_FAILURE: string = '[Lead] Action - Project assign Failure';

export const ACTION_PROJECT_START: string = '[Lead] Action - Project start';
export const ACTION_PROJECT_START_SUCCESS: string = '[Lead] Action - Project start Success';
export const ACTION_PROJECT_START_FAILURE: string = '[Lead] Action - Project start Failure';

export const ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS: string =
  '[Lead] Action - Put Finished Project Back To In Progress';
export const ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_SUCCESS: string =
  '[Lead] Action - Put Finished Project Back To In Progress Success';
export const ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_FAILURE: string =
  '[Lead] Action - Put Finished Project Back To In Progress Failure';

export const ACTION_PROJECT_FINISH: string = '[Lead] Action - Project finish';
export const ACTION_PROJECT_FINISH_SUCCESS: string = '[Lead] Action - Project finish Success';
export const ACTION_PROJECT_FINISH_FAILURE: string = '[Lead] Action - Project finish Failure';

export const ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED: string =
  '[Lead] Action - Put Accounted Project back to Finished';
export const ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_SUCCESS: string =
  '[Lead] Action - Put Accounted Project back to Finished Success';
export const ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_FAILURE: string =
  '[Lead] Action - Put Accounted Project back to Finished Failure';

export const ACTION_PROJECT_ACCOUNT: string = '[Lead] Action - Project account';
export const ACTION_PROJECT_ACCOUNT_SUCCESS: string = '[Lead] Action - Project account Success';
export const ACTION_PROJECT_ACCOUNT_FAILURE: string = '[Lead] Action - Project account Failure';

export const ACTION_PROJECT_ARCHIVED: string = '[Lead] Action - Project archived';
export const ACTION_PROJECT_ARCHIVED_SUCCESS: string = '[Lead] Action - Project archived Success';
export const ACTION_PROJECT_ARCHIVED_FAILURE: string = '[Lead] Action - Project archived Failure';

export const ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED: string =
  '[Lead] Action - Put Archived Project back to Accounted';
export const ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_SUCCESS: string =
  '[Lead] Action - Put Archived Project back to Accounted Success';
export const ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_FAILURE: string =
  '[Lead] Action - Put Archived Project back to Accounted Failure';

export const ACTION_PROJECT_RELEASE: string = '[Lead] Action - Project release';
export const ACTION_PROJECT_RELEASE_SUCCESS: string = '[Lead] Action - Project release Success';
export const ACTION_PROJECT_RELEASE_FAILURE: string = '[Lead] Action - Project release Failure';

// Lead Actions
export class ActionContactAction implements Action {
  readonly type: string = ACTION_CONTACT;

  constructor(public payload: { leadId: number }) {}
}

export class ActionContactSuccessAction implements Action {
  readonly type: string = ACTION_CONTACT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionContactFailureAction implements Action {
  readonly type: string = ACTION_CONTACT_FAILURE;

  constructor(public payload: any) {}
}

export class ActionUnreachedAction implements Action {
  readonly type: string = ACTION_UNREACHED;

  constructor(public payload: { leadId: number }) {}
}

export class ActionUnreachedSuccessAction implements Action {
  readonly type: string = ACTION_UNREACHED_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionUnreachedFailureAction implements Action {
  readonly type: string = ACTION_UNREACHED_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionWinActionPayload {
  leadId: number;
  variantId: number;
  winLeadRequest: WinLeadRequest;
}

export class ActionStartOrderAction implements Action {
  readonly type: string = ACTION_START_ORDER;

  constructor(public payload: { leadId: number; variantId: number; winRequest: WinLeadRequest }) {}
}

export class ActionStartOrderSuccessAction implements Action {
  readonly type: string = ACTION_START_ORDER_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionStartOrderFailureAction implements Action {
  readonly type: string = ACTION_START_ORDER_FAILURE;

  constructor(public payload: any) {}
}

export class ActionOrderBackToFollowUpAction implements Action {
  readonly type: string = ACTION_ORDER_BACK_TO_FOLLOWUP;

  constructor(public payload: { leadId: number }) {}
}

export class ActionOrderBackToFollowUpSuccessAction implements Action {
  readonly type: string = ACTION_ORDER_BACK_TO_FOLLOWUP_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionOrderBackToFollowUpFailureAction implements Action {
  readonly type: string = ACTION_ORDER_BACK_TO_FOLLOWUP_FAILURE;

  constructor(public payload: any) {}
}

export class ActionWinAction implements Action {
  readonly type: string = ACTION_WIN;

  constructor(public payload: IActionWinActionPayload) {}
}

export class ActionWinSuccessAction implements Action {
  readonly type: string = ACTION_WIN_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionWinFailureAction implements Action {
  readonly type: string = ACTION_WIN_FAILURE;

  constructor(public payload: any) {}
}

export class ActionAssignAction implements Action {
  readonly type: string = ACTION_ASSIGN;

  constructor(public payload: { leadId: number }) {}
}

export class ActionAssignSuccessAction implements Action {
  readonly type: string = ACTION_ASSIGN_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionAssignFailureAction implements Action {
  readonly type: string = ACTION_ASSIGN_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionDelegatePayload {
  leadId: number;
  delegateId?: string;
  task: TaskViewModel;
}

export class ActionDelegateAction implements Action {
  readonly type: string = ACTION_DELEGATE;

  constructor(public payload: IActionDelegatePayload) {}
}

export class ActionDelegateSuccessAction implements Action {
  readonly type: string = ACTION_DELEGATE_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionDelegateFailureAction implements Action {
  readonly type: string = ACTION_DELEGATE_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionSendBackActionPayload {
  leadId: number;
  abortStatus?: LeadStatusViewModel;
  onHoldStatus?: LeadStatusViewModel;
}

export class ActionSendBackAction implements Action {
  readonly type: string = ACTION_SEND_BACK;

  constructor(public payload: IActionSendBackActionPayload) {}
}

export class ActionSendBackSuccessAction implements Action {
  readonly type: string = ACTION_SEND_BACK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionSendBackFailureAction implements Action {
  readonly type: string = ACTION_SEND_BACK_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export interface IActionTriageActionPayload {
  leadId: number;
  newOwnerId: string;
  variantId: number;
}

export interface IActionContactAndTriageActionPayload {
  leadId: number;
  newOwnerId: string;
  variantId: number;
}

export class ActionTriageAction implements Action {
  readonly type: string = ACTION_TRIAGE;

  constructor(public payload: IActionTriageActionPayload) {}
}

export class ActionTriageSuccessAction implements Action {
  readonly type: string = ACTION_TRIAGE_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionTriageFailureAction implements Action {
  readonly type: string = ACTION_TRIAGE_FAILURE;

  constructor(public payload: any) {}
}

export class ActionContactAndTriageAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TRIAGE;

  constructor(public payload: IActionContactAndTriageActionPayload) {}
}

export class ActionContactAndTriageSuccessAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TRIAGE_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionContactAndTriageFailureAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TRIAGE_FAILURE;

  constructor(public payload: any) {}
}

export class ActionContactAndTsWinAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TS_WIN;

  constructor(public payload: { leadId: number }) {}
}

export class ActionContactAndTsWinSuccessAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TS_WIN_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionContactAndTsWinFailureAction implements Action {
  readonly type: string = ACTION_CONTACT_AND_TS_WIN_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionDepositActionPayload {
  leadId: number;
  variantId: number;
}

export class ActionDepositAction implements Action {
  readonly type: string = ACTION_DEPOSIT;

  constructor(public payload: IActionDepositActionPayload) {}
}

export class ActionDepositSuccessAction implements Action {
  readonly type: string = ACTION_DEPOSIT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionDepositFailureAction implements Action {
  readonly type: string = ACTION_DEPOSIT_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionTransferPayload {
  leadId: number;
  newOwnerId?: string;
}

export class ActionTransferAction implements Action {
  readonly type: string = ACTION_TRANSFER;

  constructor(public payload: IActionTransferPayload) {}
}

export class ActionTransferSuccessAction implements Action {
  readonly type: string = ACTION_TRANSFER_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionTransferFailureAction implements Action {
  readonly type: string = ACTION_TRANSFER_FAILURE;

  constructor(public payload: any) {}
}

export class ActionTransferMultipleAction implements Action {
  readonly type: string = ACTION_TRANSFER_MULTIPLE;

  constructor(public payload: IActionMultiplePayload) {}
}

export class ActionTransferMultipleSuccessAction implements Action {
  readonly type: string = ACTION_TRANSFER_MULTIPLE_SUCCESS;

  constructor(public payload: IActionMultipleSuccessPayload) {}
}

export interface IActionReleasePayload {
  leadId: number;
  newOwner?: string;
}

export class ActionReleaseAction implements Action {
  readonly type: string = ACTION_RELEASE;

  constructor(public payload: IActionReleasePayload) {}
}

export class ActionWonBackToFollowupBoSuccessAction implements Action {
  readonly type: string = ACTION_WON_BACK_TO_FOLLOWUP_BO_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionWonBackToFollowupBoFailureAction implements Action {
  readonly type: string = ACTION_WON_BACK_TO_FOLLOWUP_BO_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionWonBackToFollowupBoPayload {
  leadId: number;
}

export class ActionWonBackToFollowupBoAction implements Action {
  readonly type: string = ACTION_WON_BACK_TO_FOLLOWUP_BO;

  constructor(public payload: IActionWonBackToFollowupBoPayload) {}
}

export class ActionReleaseSuccessAction implements Action {
  readonly type: string = ACTION_RELEASE_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionReleaseFailureAction implements Action {
  readonly type: string = ACTION_RELEASE_FAILURE;

  constructor(public payload: any) {}
}

export class ActionTransferBackAction implements Action {
  readonly type: string = ACTION_TRANSFER_BACK;

  constructor(public payload: { leadId: number }) {}
}

export class ActionTransferBackSuccessAction implements Action {
  readonly type: string = ACTION_TRANSFER_BACK_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionTransferBackFailureAction implements Action {
  readonly type: string = ACTION_TRANSFER_BACK_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionOnHoldActionPayload {
  leadId: number;
  status: string;
}

export class ActionOnHoldAction implements Action {
  readonly type: string = ACTION_ON_HOLD;

  constructor(public payload: IActionOnHoldActionPayload) {}
}

export class ActionOnHoldSuccessAction implements Action {
  readonly type: string = ACTION_ON_HOLD_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionOnHoldFailureAction implements Action {
  readonly type: string = ACTION_ON_HOLD_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionAbortPayload {
  leadId: number;
  status: string;
}

export class ActionAbortAction implements Action {
  readonly type: string = ACTION_ABORT;

  constructor(public payload: IActionAbortPayload) {}
}

export class ActionAbortSuccessAction implements Action {
  readonly type: string = ACTION_ABORT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionAbortFailureAction implements Action {
  readonly type: string = ACTION_ABORT_FAILURE;

  constructor(public payload: any) {}
}

export class ActionAbortMultipleAction implements Action {
  readonly type: string = ACTION_ABORT_MULTIPLE;

  constructor(public payload: IActionMultiplePayload) {}
}

export interface IActionMultipleSuccessPayload {
  leads: LeadCompositeViewModel[];
  failed: IActionAbortPayload[];
}

export class ActionAbortMultipleSuccessAction implements Action {
  readonly type: string = ACTION_ABORT_MULTIPLE_SUCCESS;

  constructor(public payload: IActionMultipleSuccessPayload) {}
}

export interface IActionMultiplePayload {
  leadIds: string[];
  status: string;
}

export interface IActionMultiplePayloadWithoutStatus {
  leadIds: string[];
}

export class ActionHoldMultipleAction implements Action {
  readonly type: string = ACTION_HOLD_MULTIPLE;

  constructor(public payload: IActionMultiplePayload) {}
}

export class ActionHoldMultipleSuccessAction implements Action {
  readonly type: string = ACTION_HOLD_MULTIPLE_SUCCESS;

  constructor(public payload: IActionMultipleSuccessPayload) {}
}

export class ActionReleaseMultipleAction implements Action {
  readonly type: string = ACTION_RELEASE_MULTIPLE;

  constructor(public payload: IActionMultiplePayloadWithoutStatus) {}
}

export class ActionReleaseMultipleSuccessAction implements Action {
  readonly type: string = ACTION_RELEASE_MULTIPLE_SUCCESS;

  constructor(public payload: IActionMultipleSuccessPayload) {}
}

export interface IActionSentActionPayload {
  leadId: number;
}

export class ActionNboSentAction implements Action {
  readonly type: string = ACTION_NBO_SENT;

  constructor(public payload: IActionSentActionPayload) {}
}

export class ActionNboSentSuccessAction implements Action {
  readonly type: string = ACTION_NBO_SENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionNboSentFailureAction implements Action {
  readonly type: string = ACTION_NBO_SENT_FAILURE;

  constructor(public payload: any) {}
}

export class ActionBoSentAction implements Action {
  readonly type: string = ACTION_BO_SENT;

  constructor(public payload: IActionSentActionPayload) {}
}

export class ActionBoSentSuccessAction implements Action {
  readonly type: string = ACTION_BO_SENT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionBoSentFailureAction implements Action {
  readonly type: string = ACTION_BO_SENT_FAILURE;

  constructor(public payload: any) {}
}

export interface IActionProjectAssignedPayload {
  leadIds: string;
  newUserId: string;
}

export class ActionProjectAssignedAction implements Action {
  readonly type: string = ACTION_PROJECT_ASSIGN;

  constructor(public payload: IActionProjectAssignedPayload) {}
}

export class ActionProjectAssignedSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_ASSIGN_SUCCESS;

  constructor(public payload: IActionProjectAssignedPayload) {}
}

export class ActionProjectAssignedFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_ASSIGN_FAILURE;

  constructor(public payload: any) {}
}

export class ActionProjectStartAction implements Action {
  readonly type: string = ACTION_PROJECT_START;

  constructor(public payload: number) {}
}

export class ActionProjectStartSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_START_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionProjectStartFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_START_FAILURE;

  constructor(public payload: any) {}
}

export class ActionProjectFinishAction implements Action {
  readonly type: string = ACTION_PROJECT_FINISH;

  constructor(public payload: number) {}
}

export class ActionProjectFinishSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_FINISH_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionProjectFinishFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_FINISH_FAILURE;

  constructor(public payload: any) {}
}

export class ActionPutFinishedProjectBackToInProgressAction implements Action {
  readonly type: string = ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS;

  constructor(public payload: number) {}
}

export class ActionPutFinishedProjectBackToInProgressSuccessAction implements Action {
  readonly type: string = ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionPutFinishedProjectBackToInProgressFailureAction implements Action {
  readonly type: string = ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_FAILURE;

  constructor(public payload: any) {}
}

export class ActionProjectAccountAction implements Action {
  readonly type: string = ACTION_PROJECT_ACCOUNT;

  constructor(public payload: number) {}
}

export class ActionProjectAccountSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_ACCOUNT_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionProjectAccountFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_ACCOUNT_FAILURE;

  constructor(public payload: any) {}
}

export class ActionPutAccountedProjectBackToFinishedAction implements Action {
  readonly type: string = ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED;

  constructor(public payload: number) {}
}

export class ActionPutAccountedProjectBackToFinishedSuccessAction implements Action {
  readonly type: string = ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionPutAccountedProjectBackToFinishedFailureAction implements Action {
  readonly type: string = ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_FAILURE;

  constructor(public payload: any) {}
}

export class ActionProjectArchivedAction implements Action {
  readonly type: string = ACTION_PROJECT_ARCHIVED;

  constructor(public payload: number) {}
}

export class ActionProjectArchivedSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_ARCHIVED_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionProjectArchivedFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_ARCHIVED_FAILURE;

  constructor(public payload: any) {}
}

export class ActionPutArchivedProjectBackToAccountedAction implements Action {
  readonly type: string = ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED;

  constructor(public payload: number) {}
}

export class ActionPutArchivedProjectBackToAccountedSuccessAction implements Action {
  readonly type: string = ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionPutArchivedProjectBackToAccountedFailureAction implements Action {
  readonly type: string = ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_FAILURE;

  constructor(public payload: any) {}
}

export class ActionProjectReleaseAction implements Action {
  readonly type: string = ACTION_PROJECT_RELEASE;

  constructor(public payload: number) {}
}

export class ActionProjectReleaseSuccessAction implements Action {
  readonly type: string = ACTION_PROJECT_RELEASE_SUCCESS;

  constructor(public payload: LeadCompositeViewModel) {}
}

export class ActionProjectReleaseFailureAction implements Action {
  readonly type: string = ACTION_PROJECT_RELEASE_FAILURE;

  constructor(public payload: any) {}
}

export type Actions =
  | ActionContactAction
  | ActionContactSuccessAction
  | ActionContactFailureAction
  | ActionUnreachedAction
  | ActionUnreachedSuccessAction
  | ActionUnreachedFailureAction
  | ActionWinAction
  | ActionWinSuccessAction
  | ActionWinFailureAction
  | ActionAssignAction
  | ActionAssignSuccessAction
  | ActionAssignFailureAction
  | ActionDelegateAction
  | ActionDelegateSuccessAction
  | ActionDelegateFailureAction
  | ActionSendBackAction
  | ActionSendBackSuccessAction
  | ActionSendBackFailureAction
  | ActionTriageAction
  | ActionTriageSuccessAction
  | ActionTriageFailureAction
  | ActionDepositAction
  | ActionDepositSuccessAction
  | ActionDepositFailureAction
  | ActionTransferAction
  | ActionTransferSuccessAction
  | ActionTransferFailureAction
  | ActionReleaseAction
  | ActionReleaseSuccessAction
  | ActionReleaseFailureAction
  | ActionTransferBackAction
  | ActionTransferBackSuccessAction
  | ActionTransferBackFailureAction
  | ActionOnHoldAction
  | ActionOnHoldSuccessAction
  | ActionOnHoldFailureAction
  | ActionAbortAction
  | ActionAbortSuccessAction
  | ActionAbortFailureAction
  | ActionAbortMultipleAction
  | ActionAbortMultipleSuccessAction
  | ActionHoldMultipleAction
  | ActionHoldMultipleSuccessAction
  | ActionNboSentAction
  | ActionNboSentSuccessAction
  | ActionNboSentFailureAction
  | ActionBoSentAction
  | ActionBoSentSuccessAction
  | ActionBoSentFailureAction
  | ActionContactAndTriageAction
  | ActionContactAndTriageSuccessAction
  | ActionContactAndTriageFailureAction
  | ActionProjectAssignedAction
  | ActionProjectAssignedSuccessAction
  | ActionProjectAssignedFailureAction
  | ActionProjectStartAction
  | ActionProjectStartSuccessAction
  | ActionProjectStartFailureAction
  | ActionProjectFinishAction
  | ActionProjectFinishSuccessAction
  | ActionProjectFinishFailureAction
  | ActionPutFinishedProjectBackToInProgressAction
  | ActionPutFinishedProjectBackToInProgressSuccessAction
  | ActionPutFinishedProjectBackToInProgressFailureAction
  | ActionProjectArchivedAction
  | ActionProjectArchivedSuccessAction
  | ActionProjectArchivedFailureAction
  | ActionPutArchivedProjectBackToAccountedAction
  | ActionPutArchivedProjectBackToAccountedSuccessAction
  | ActionPutArchivedProjectBackToAccountedFailureAction
  | ActionProjectReleaseAction
  | ActionProjectReleaseSuccessAction
  | ActionProjectReleaseFailureAction
  | ActionStartOrderAction
  | ActionStartOrderSuccessAction
  | ActionStartOrderFailureAction;
