import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import * as RootReducer from '../../shared/states';
import { NavigationBackAction } from '../../shared/states/navigation/navigation.action';
import {
  AdditionalAddressTypeViewModel,
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  DirectoryService,
  FileLinkViewModel,
  LeadCompositeViewModel,
  LeadContactViewModel,
  LeadService,
  LeadViewModel,
  MasterDataMandatorViewModel,
  MasterDataViewModel,
  PostPutEmailRequest,
  PostPutPhoneRequest,
  PostPutSingleContactRequest,
  PutPvBuildingData,
  VariantListCompositionViewModel,
} from '../../shared/apis/advis';
import { combineLatest, Observable, of, Subscription, switchMap } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import {
  AddLeadAdditionalAddressBookContactAction,
  AddLeadAdditionalSingleContactAction,
  DeleteAdditionalLeadContactAction,
  LEAD_CONTACT_UPDATE_SUCCESS,
  UpdateLeadAddressBookContactAction,
  UpdateLeadSingleContactAction,
} from '../../shared/states/lead-contact/lead-contact.action';
import * as LeadDocumentReducer from '../../shared/states/lead-document/lead-document.reducer';
import { LoadingModalService } from '../../shared/services/loading-modal.service';
import { ContactViewModelUtil } from '../../shared/utils/contact-view-model.util';
import { EnumService, IEnumData } from '../../shared/services/enum.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import {
  CreateEditAddressDialogComponent,
  CreateEditContactEnum,
} from '../../address-book-shared/dialog/create-edit-address-dialog/create-edit-address-dialog.component';
import { SingleContactComponent } from '../../address-book-shared/lead-address/single-contact/single-contact.component';
import { AddressBookContactComponent } from '../../address-book-shared/lead-address/address-book-contact/address-book-contact.component';
import { routesNames } from '../../app.component';
import { DetailLoadAction, IDetailLoad } from '../../shared/states/lead/lead.action';
import { AddressBookBackendService } from '../../shared/services/address-book-backend.service';
import { NotificationService, TypeE } from '../../shared/services/notification.service';
import { IExternalManagedAddress } from '../../shared/interfaces/ExternalManagedAddress.interface';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../shared/utils/exter-managed-address-type.util';
import { DialogRef, DialogService, TabsData } from '@sitewerk/theia-ui-lib';
import {
  LOCATION_SEARCH_API_URL,
  Roof3dPluginDialogComponent,
} from '../../common-components/map/building3-d/roof3d-plugin-dialog/roof3d-plugin-dialog.component';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/utils/constants';
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;

enum ActiveTab {
  singleAddress = 'single-address',
  addressBook = 'address-book',
}

@Component({
  selector: 'pc-lead-address',
  templateUrl: './lead-address.component.html',
  styleUrls: ['./lead-address.component.scss'],
})
export class LeadAddressComponent implements OnInit, OnDestroy {
  @ViewChild('singleContactComponent', { static: false })
  public singleContactComponent: SingleContactComponent;
  @ViewChild('addressBookComponent', { static: false })
  public addressBookComponent: AddressBookContactComponent;
  @ViewChild('addressTypeFilter', { static: false })
  public addressTypeFilter: ElementRef;

  public companyFile: FileLinkViewModel;
  public personFile: FileLinkViewModel;
  public addressTypeChanged: boolean = false;

  public lead: LeadViewModel;
  public leadId: number;
  public mandator: MasterDataMandatorViewModel;
  public titleItemsPerson: IEnumData[] = [];
  public titleItemsSingleContact: IEnumData[] = [];
  public selectedTabIndex: number;
  public contactOrigin: LeadContactViewModel;
  public editContactAddressbook: LeadContactViewModel;
  public editContactSingle: LeadContactViewModel;
  public contactTypes: AdditionalAddressTypeViewModel[];
  public filteredAddressTypes: AdditionalAddressTypeViewModel[] = [];
  public emailTypes: AdditionalAddressTypeViewModel[];
  public phoneTypes: AdditionalAddressTypeViewModel[];
  public addressIdsInUseInInvoices: number[] = [];
  public isContactTypeEmpty: boolean = true;
  public selectedAddressType: AddressTypeEnum;
  public isExternalManaged: boolean = false;
  public searchInput: string = '';
  public selectedTab: ActiveTab = ActiveTab.singleAddress;
  public activeTabs = ActiveTab;
  public tabsData: TabsData[] = [];

  private subscriptions: Subscription = new Subscription();
  private addToLeadNotification: boolean = false;
  private personNewLogo: File;
  private companyNewLogo: File;
  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  constructor(
    private store: Store<RootReducer.IState>,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private loadingModalService: LoadingModalService,
    private actions: Actions,
    private dialogService: DialogService,
    private enumService: EnumService,
    private directoryService: DirectoryService,
    private notificationService: NotificationService,
    private addressBookBackendService: AddressBookBackendService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private leadService: LeadService
  ) {
    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
    this.tabsData = [
      {
        id: ActiveTab.addressBook,
        tabLabel: 'ADDRESS.CREATE.IN_ADDRESS_BOOK',
        tabName: 'ADDRESS.CREATE.IN_ADDRESS_BOOK',
      },
    ] as TabsData[];
  }

  public ngOnInit(): void {
    this.titleItemsPerson = this.enumService.getEnumDataExclude(TitleEnum, [
      TitleEnum.None,
      TitleEnum.Company,
    ]);
    this.titleItemsSingleContact = this.enumService.getEnumDataExclude(TitleEnum, [TitleEnum.None]);

    this.subscriptions.add(
      this.store.select(RootReducer.getLeadContactLoading).subscribe((loading: boolean) => {
        if (loading) {
          this.loadingModalService.openModal();
        } else {
          this.loadingModalService.closeModal();
        }
      })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getLeadContactAddedToLeadFailed)
        .pipe(filter(failed => failed === true))
        .subscribe(() => {
          if (this.addToLeadNotification) {
            this.addToLeadNotification = false;
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_ADD_TO_LEAD_FAILED'),
              TypeE.ALERT
            );
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getLeadContactAddedToLeadSuccess)
        .pipe(filter(success => success === true))
        .subscribe(() => {
          if (this.addToLeadNotification) {
            this.addToLeadNotification = false;
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_ADD_TO_LEAD_SUCCESS'),
              TypeE.PRIMARY
            );
          }
        })
    );

    this.subscriptions.add(
      this.store.select(RootReducer.getLeadContactLoading).subscribe((loading: boolean) => {
        if (loading) {
          this.loadingModalService.openModal();
        } else {
          this.loadingModalService.closeModal();
        }
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.store.select(RootReducer.getLeadDetailEntity),
        this.store.select<LeadDocumentReducer.IState>(RootReducer.getLeadDocumentState),
        this.store.select<MasterDataViewModel>(RootReducer.getGlobalMasterData),
        this.store.select(RootReducer.getGlobalMandantsMap),
      ])
        .pipe(
          filter(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_, { files }]: [
              LeadCompositeViewModel,
              LeadDocumentReducer.IState,
              MasterDataViewModel,
              Map<number, MasterDataMandatorViewModel>
            ]) => !!files
          ),
          take(1)
        )
        .subscribe(
          ([leadVm, { files }, masterData, mandatorMap]: [
            LeadCompositeViewModel,
            LeadDocumentReducer.IState,
            MasterDataViewModel,
            Map<number, MasterDataMandatorViewModel>
          ]) => {
            leadVm.Variants.forEach((variant: VariantListCompositionViewModel) => {
              if (
                !isNullOrUndefined(variant.LeadVariant?.VariantInvoices?.SelectedLeadAddressId) &&
                (variant.LeadVariant?.VariantInvoices?.PartialInvoices?.length > 0 ||
                  variant.LeadVariant?.VariantInvoices?.FinalInvoices?.length > 0)
              ) {
                this.addressIdsInUseInInvoices.push(
                  variant.LeadVariant.VariantInvoices.SelectedLeadAddressId
                );
              }
            });

            this.lead = cloneDeep(leadVm.Lead);
            this.leadId = this.lead.Id;
            this.mandator = mandatorMap.get(this.lead.MandantId);

            if (this.router.url.includes(routesNames.LEAD_EDIT_OBJECT)) {
              // load exist object address
              this.contactOrigin = this.lead.ObjectAddress;
              this.loadContact();
            } else if (this.router.url.includes(routesNames.LEAD_EDIT_DEBITOR)) {
              // load exist debitor address
              this.contactOrigin = this.lead.DebitorAddress;
              this.loadContact();
            } else if (this.route.snapshot.queryParams.addressId) {
              // load exist additional address
              this.contactOrigin = this.lead.AdditionalAddresses.find(
                (a: LeadContactViewModel) =>
                  a.Id.toString() === this.route.snapshot.queryParams.addressId
              );
              this.loadContact();
            } else {
              this.setInitialDataForEmptyContact();
              this.subscriptions.add(
                this.store
                  .select(RootReducer.getLeadDetailEntity)
                  .subscribe((leadComposite: LeadCompositeViewModel) => {
                    if (this.selectedTab === ActiveTab.addressBook) {
                      this.addressBookComponent.isSaved();
                    }
                    this.showCreateNewDialogAfterSave(leadComposite.Lead);
                  })
              );
            }
            this.personFile = files.find(
              (l: FileLinkViewModel) => l.FileName === this.contactOrigin.Person?.LogoFileName
            );
            this.companyFile = files.find(
              (l: FileLinkViewModel) => l.FileName === this.contactOrigin.Company?.LogoFileName
            );
            this.contactTypes = this.getAllowedAddressTypes(this.contactOrigin, masterData);
            this.filteredAddressTypes = this.contactTypes;
            this.emailTypes = masterData.AdditionalEmailTypes;
            this.phoneTypes = masterData.AdditionalPhoneTypes;
            this.selectedTab = this.contactOrigin.IsSingleContact
              ? ActiveTab.singleAddress
              : ActiveTab.addressBook;
            this.cdr.markForCheck();
          }
        )
    );
  }

  public ngOnDestroy(): void {
    const payload: IDetailLoad = {
      leadId: this.leadId,
      useForceDetailLoad: true,
    };
    this.store.dispatch(new DetailLoadAction(payload));
    this.subscriptions.unsubscribe();
  }

  public switchTab(id: string): void {
    this.selectedTab = id as ActiveTab;
  }

  public canDelete(): boolean {
    if (!this.contactOrigin || !this.contactOrigin.Id) {
      return false;
    }

    const requiredAddressTypes: LeadContactViewModel.AddressTypeEnum[] = [
      LeadContactViewModel.AddressTypeEnum.Object,
      LeadContactViewModel.AddressTypeEnum.Debitor,
    ];

    return (
      this.contactOrigin && requiredAddressTypes.indexOf(this.contactOrigin.AddressType) === -1
    );
  }

  public checkDuplicateAndCopyAsExternalManaged(
    externalManagedAddress: IExternalManagedAddress
  ): void {
    this.loadingModalService.openModal();

    const externalManagedConfig = this.allExternalManagedAddressConfig.find(
      x => x.addressType === externalManagedAddress.addressType
    );

    this.addressBookBackendService
      .externManagedRequest(
        this.mandator.SharedAddressBookMandatorIds.join(','),
        this.loadingModalService,
        externalManagedConfig,
        externalManagedAddress.contact.ContactId,
        externalManagedAddress.contact.Person?.LastName,
        externalManagedAddress.contact.Person?.FirstName,
        externalManagedAddress.contact.Company?.Name,
        externalManagedAddress.contact.MainContact.Street,
        externalManagedAddress.contact.MainContact.City,
        externalManagedAddress.contact.MainContact.ZipCode,
        externalManagedAddress.contact.MainContact.PrimaryEmail.Email,
        externalManagedAddress.contact.MainContact.PrimaryPhone.PhoneNumber
      )
      .subscribe(result => {
        if (result.save && !isNullOrUndefined(result.addContactToLead)) {
          this.addToLeadNotification = true;
          this.addAddressBookContact(result.addContactToLead, externalManagedAddress.addressType);
        } else if (
          (result.save || (result.saveExternalManagedContactRequest ?? false)) &&
          !isNullOrUndefined(result.selectNewId)
        ) {
          this.copyAsExternalManagedContact(
            externalManagedAddress.contact.ContactId,
            externalManagedConfig
          );
        } else {
          this.loadingModalService.closeModal();
        }
      });
  }

  public copyAsExternalManagedContact(
    contactId: number,
    externalManagedConfig: IExternManagedAddressTypeConfig
  ): void {
    this.subscriptions.add(
      this.directoryService
        .directoryCreateExternalManagedFromExistsContact(
          contactId,
          externalManagedConfig.addressType,
          this.lead.Language
        )
        .subscribe(
          c => {
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_SUCCESS', {
                addressTypeName: externalManagedConfig.translation,
              }),
              TypeE.PRIMARY
            );
            this.addAddressBookContact(c.Id, externalManagedConfig.addressType);
          },
          () => {
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_FAILED', {
                addressTypeName: externalManagedConfig.translation,
              }),
              TypeE.ALERT
            );
          }
        )
    );
  }

  public onBack(): void {
    if (
      !isNullOrUndefined(this.singleContactComponent) &&
      this.singleContactComponent.hasUnsavedData()
    ) {
      this.unsavedDataDialog();
      return;
    }

    if (
      !isNullOrUndefined(this.addressBookComponent) &&
      !this.addressBookComponent.saveSelectionDisabled()
    ) {
      this.unsavedDataDialog();
      return;
    }

    this.store.dispatch(new NavigationBackAction());
  }

  public onSelectedIndexChange(): void {
    this.contactOrigin.IsSingleContact = this.selectedTab === ActiveTab.singleAddress;
  }

  public onAddressTypeSelect(value: AddressTypeEnum): void {
    this.selectedAddressType = value;
    if (this.selectedAddressType) {
      this.isExternalManaged = ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
        this.selectedAddressType,
        this.allExternalManagedAddressConfig
      );
      this.insertSingleAddressTabIfRequired(this.isExternalManaged);
      if (this.contactOrigin?.Id === 0) {
        this.editContactAddressbook.AddressType = this.selectedAddressType;
        this.editContactSingle.AddressType = this.selectedAddressType;
      } else {
        this.editContactAddressbook.AddressType = this.contactOrigin.AddressType;
        this.editContactSingle.AddressType = this.contactOrigin.AddressType;
      }

      if (this.contactOrigin.Id === 0) {
        this.createEmptyContact();
      } else {
        this.addressTypeChanged = true;
      }
      this.isContactTypeEmpty = false;
      this.refreshModel(this.contactOrigin);
    }
    this.cdr.detectChanges();
  }

  public onAddressTypeSelectOpened(): void {
    this.addressTypeFilter.nativeElement.focus();
  }

  public personLogoFileSelect(logo: File): void {
    this.personNewLogo = logo;
  }

  public personLogoDelete(event: undefined): void {
    this.personNewLogo = event;
  }

  public canChangeTab(): boolean {
    return true;
  }

  public contactDelete(): void {
    if (
      this.addressIdsInUseInInvoices.findIndex(
        (addressId: number) => addressId === this.contactOrigin.Id
      ) > -1
    ) {
      this.dialogService.openAlert(
        this.translate.instant('ADDRESS.REMOVE_USED_ADDRESS.MESSAGE'),
        this.translate.instant('ADDRESS.REMOVE_USED_ADDRESS.TITLE')
      );
      return;
    }

    this.subscriptions.add(
      this.dialogService
        .openConfirm({ message: this.translate.instant('ADDRESS.CONFIRM_ADDRESS_DELETION') })
        .afterClosed()
        .pipe(filter((res: boolean) => !!res))
        .subscribe(() => {
          this.store.dispatch(
            new DeleteAdditionalLeadContactAction({
              leadId: this.leadId,
              contactId: this.contactOrigin.Id,
            })
          );
          this.subscriptions.add(
            this.actions
              .pipe(ofType(LEAD_CONTACT_UPDATE_SUCCESS))
              .subscribe(() => this.navigateToDetailView(this.leadId))
          );
        })
    );
  }

  public filterAddressTypes(event: string): void {
    this.searchInput = event;
    if (!this.searchInput) {
      this.filteredAddressTypes = this.contactTypes;
    } else {
      this.filteredAddressTypes = this.contactTypes.filter(
        addressType =>
          addressType.Translation.toLocaleLowerCase().indexOf(
            this.searchInput.toLocaleLowerCase()
          ) >= 0
      );
    }
  }

  public saveSingleContact(contact: LeadContactViewModel): void {
    if (contact.IsSingleContact) {
      const saveContact: LeadContactViewModel =
        AddressBookBackendService.removeEmptyEmailAndPhonesFromContact(cloneDeep(contact));

      if (!isNullOrUndefined(saveContact.Company) && isNullOrUndefined(saveContact.Company.Id)) {
        saveContact.Company = undefined;
      }
      if (!isNullOrUndefined(saveContact.Person) && isNullOrUndefined(saveContact.Person.Id)) {
        saveContact.Person = undefined;
      }

      saveContact.AddressType = this.selectedAddressType;

      const searchAddress = `${saveContact?.Person?.Street} ${saveContact?.Person?.ZipCode} ${saveContact?.Person?.City}`;
      const presaveRequest: Observable<boolean> =
        this.selectedAddressType === AddressTypeEnum.Object
          ? this.checkIfAddressValid(searchAddress).pipe(
              switchMap(({ valid, addressData }) =>
                !valid
                  ? this.invalidAddressSavingDialog(searchAddress)
                  : this.lead.TradeTypeCode === Constants.TRADE_TYPE_CODE_PV
                  ? this.getUpdatePV3dBuildingDataRequest(addressData, searchAddress)
                  : of(true)
              )
            )
          : of(true);

      this.subscriptions.add(
        presaveRequest.pipe(filter(save => save === true)).subscribe(() => {
          if (this.contactOrigin.Id === 0) {
            this.addSingleContact(this.mapToRequest(saveContact));
          } else {
            this.updateSingleContact(this.contactOrigin.Id);
          }

          this.addressTypeChanged = false;
        })
      );
    }
  }

  public saveAddressBookSelection(contact: DirectoryContactViewModel): void {
    this.editContactAddressbook.ContactId = contact.Id;
    this.editContactAddressbook.Person = contact.Person;
    this.editContactAddressbook.Company = contact.Company;
    this.editContactAddressbook.AddressType = this.selectedAddressType;
    if (isNullOrUndefined(this.contactOrigin.Id) || this.contactOrigin.Id === 0) {
      this.addAddressBookContact(contact.Id, this.selectedAddressType);
    } else {
      this.updateAddressBookContact(contact.Id, this.selectedAddressType, this.contactOrigin.Id);
    }

    this.addressTypeChanged = false;
  }

  private unsavedDataDialog(): void {
    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant('ADDRESS.DIALOGS.CLOSE_WITH_UNSAVED_DATA.MESSAGE'),
          title: this.translate.instant('ADDRESS.DIALOGS.CLOSE_WITH_UNSAVED_DATA.TITLE'),
          acceptButton: this.translate.instant('COMMON.BTN_CLOSE'),
          cancelButton: this.translate.instant('COMMON.BTN_CANCEL'),
        })
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            this.store.dispatch(new NavigationBackAction());
          }
        })
    );
  }

  private setInitialDataForEmptyContact(): void {
    this.contactOrigin = ContactViewModelUtil.createEmptyContactAddressbook();
    this.isContactTypeEmpty = true;
    this.editContactAddressbook = cloneDeep(this.contactOrigin);
    this.editContactAddressbook.AddressType = this.selectedAddressType;
    this.editContactSingle = cloneDeep(this.contactOrigin);
  }

  private refreshModel(contact: LeadContactViewModel): void {
    if (!isNullOrUndefined(contact.Company) && !isNullOrUndefined(contact.Company.Id)) {
      this.contactOrigin.Company = contact.Company;
    }
    if (!isNullOrUndefined(contact.Person) && !isNullOrUndefined(contact.Person.Id)) {
      this.contactOrigin.Person = contact.Person;
    }
    if (contact.IsSingleContact) {
      this.contactOrigin.DataState = contact.DataState;
    }
  }

  private addSingleContact(contactRequest: PostPutSingleContactRequest): void {
    this.store.dispatch(
      new AddLeadAdditionalSingleContactAction({
        leadId: this.leadId,
        contact: contactRequest,
      })
    );
  }

  private updateSingleContact(leadContactId: number): void {
    this.subscriptions.add(
      this.actions
        .pipe(ofType(LEAD_CONTACT_UPDATE_SUCCESS))
        .subscribe(() => this.navigateToDetailView(this.leadId))
    );

    this.store.dispatch(
      new UpdateLeadSingleContactAction({
        leadId: this.leadId,
        contactId: leadContactId,
        contact: {
          LeadAddressId: this.contactOrigin.Id,
          AddressType: this.selectedAddressType,
          FirstName: this.editContactSingle.Person.FirstName,
          LastName: this.editContactSingle.Person.LastName,
          Title: this.editContactSingle.Person.Title,
          ThirdPartyCustomerNumber: this.editContactSingle.Person.ThirdPartyCustomerNumber,
          Notes: this.editContactSingle.Person.Notes,
          Street: this.editContactSingle.Person.Street,
          ZipCode: this.editContactSingle.Person.ZipCode,
          City: this.editContactSingle.Person.City,
          CareOfName: this.editContactSingle.Person.CareOfName,
          PostOfficeBox: this.editContactSingle.Person.PostOfficeBox,
          AdditionalEmails: isNullOrUndefined(this.editContactSingle.Person.Emails)
            ? []
            : this.editContactSingle.Person.Emails.filter(x => x.Email.length > 0).map(
                (e: DirectoryEmailViewModel) => this.mapEmailToRequest(e)
              ),
          AdditionalPhones: isNullOrUndefined(this.editContactSingle.Person.Phones)
            ? []
            : this.editContactSingle.Person.Phones.filter(x => x.PhoneNumber.length > 0).map(
                (p: DirectoryPhoneViewModel) => this.mapPhoneToRequest(p)
              ),
          CompanyName: this.editContactSingle.Company?.Name ?? undefined,
          AdditionalCompanyName: this.editContactSingle.Company?.AdditionalName ?? undefined,
          Language: this.editContactSingle.Person.Language,
          PreferredCommunicationType: this.editContactSingle.Person.PreferredCommunicationType,
        } as PostPutSingleContactRequest,
        personLogo: this.personNewLogo,
        companyLogo: this.companyNewLogo,
      })
    );
  }

  private addAddressBookContact(contactId: number, addressType: AddressTypeEnum): void {
    this.store.dispatch(
      new AddLeadAdditionalAddressBookContactAction({
        leadId: this.leadId,
        request: {
          AddressType: addressType,
          NewContactId: contactId,
        },
      })
    );
  }

  private updateAddressBookContact(
    contactId: number,
    addressType: AddressTypeEnum,
    addressId: number
  ): void {
    this.subscriptions.add(
      this.store.select(RootReducer.getLeadDetailEntity).subscribe((l: LeadCompositeViewModel) => {
        this.navigateToDetailView(l.Lead.Id);
      })
    );

    this.store.dispatch(
      new UpdateLeadAddressBookContactAction({
        leadId: this.leadId,
        request: {
          AddressType: addressType,
          LeadAddressId: addressId,
          NewContactId: contactId,
        },
      })
    );
  }

  private mapToRequest(contact: LeadContactViewModel): PostPutSingleContactRequest {
    return {
      LeadAddressId: contact.Id,
      AddressType: contact.AddressType,
      IsValidated: contact.DataState === LeadContactViewModel.DataStateEnum.Qualified,
      Title: contact.Person.Title,
      CompanyName: contact.Company?.Name ?? undefined,
      AdditionalCompanyName: contact.Company?.AdditionalName ?? undefined,
      FirstName: contact.Person.FirstName,
      LastName: contact.Person.LastName,
      Street: contact.Person.Street,
      ZipCode: contact.Person.ZipCode,
      City: contact.Person.City,
      CareOfName: contact.Person.CareOfName,
      PostOfficeBox: contact.Person.PostOfficeBox,
      Notes: contact.Person.Notes,
      Language: contact.Person.Language ?? PostPutSingleContactRequest.LanguageEnum.DE,
      PreferredCommunicationType: contact.Person.PreferredCommunicationType,
      ThirdPartyCustomerNumber: contact.Person.ThirdPartyCustomerNumber,
      AdditionalEmails: isNullOrUndefined(contact.Person.Emails)
        ? []
        : contact.Person.Emails.map((e: DirectoryEmailViewModel) => this.mapEmailToRequest(e)),
      AdditionalPhones: isNullOrUndefined(contact.Person.Phones)
        ? []
        : contact.Person.Phones.map((p: DirectoryPhoneViewModel) => this.mapPhoneToRequest(p)),
    };
  }

  private mapEmailToRequest(email: DirectoryEmailViewModel): PostPutEmailRequest {
    return {
      Email: email.Email,
      EmailType: email.EmailType,
    };
  }

  private mapPhoneToRequest(phone: DirectoryPhoneViewModel): PostPutPhoneRequest {
    return {
      PhoneNumber: phone.PhoneNumber,
      PhoneType: phone.PhoneType,
    };
  }

  private getAllowedAddressTypes(
    address: LeadContactViewModel,
    masterData: MasterDataViewModel
  ): AdditionalAddressTypeViewModel[] {
    if (address.AddressType === LeadContactViewModel.AddressTypeEnum.Object) {
      return [
        {
          Key: LeadContactViewModel.AddressTypeEnum.Object,
          Translation: this.translate.instant('LEAD_DETAIL.OBJECT_ADDR'),
        },
      ];
    }

    if (address.AddressType === LeadContactViewModel.AddressTypeEnum.Debitor) {
      return [
        {
          Key: LeadContactViewModel.AddressTypeEnum.Debitor,
          Translation: this.translate.instant('LEAD_DETAIL.DEBITOR_ADDR'),
        },
      ];
    }

    if (!this.isNewAddress() && this.isExternalManaged) {
      return masterData.AllowedAdditionalAddressTypes.filter(x =>
        this.allExternalManagedAddressConfig.some(y => y.addressType === x.Key)
      );
    }

    if (!this.isNewAddress() && !this.isExternalManaged) {
      return masterData.AllowedAdditionalAddressTypes.filter(x =>
        this.allExternalManagedAddressConfig.every(y => y.addressType !== x.Key)
      );
    }

    return masterData.AllowedAdditionalAddressTypes;
  }

  private isNewAddress(): boolean {
    return isNullOrUndefined(this.contactOrigin.Id) || this.contactOrigin.Id === 0;
  }

  private createEmptyContact(): void {
    this.contactOrigin = ContactViewModelUtil.createEmptyContact(
      this.selectedAddressType,
      this.contactOrigin.IsSingleContact
    ) as LeadContactViewModel;
  }

  private navigateToDetailView(leadId: number): void {
    this.router.navigate(['lead/detail', leadId]);
  }

  private loadContact(): void {
    this.isContactTypeEmpty = false;
    const emptyContact: LeadContactViewModel = ContactViewModelUtil.createEmptyContactAddressbook();
    emptyContact.AddressType = this.contactOrigin.AddressType;
    emptyContact.IsSingleContact = !this.contactOrigin.IsSingleContact;
    if (this.contactOrigin.IsSingleContact) {
      this.editContactAddressbook = emptyContact;
      this.editContactSingle = cloneDeep(this.contactOrigin);
      this.editContactSingle = SingleContactComponent.PrepareCompanyData(this.editContactSingle);
    } else {
      this.editContactAddressbook = cloneDeep(this.contactOrigin);
      this.editContactSingle = emptyContact;
    }

    this.selectedAddressType = this.contactOrigin.AddressType;
    this.isExternalManaged = ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
      this.contactOrigin.AddressType,
      this.allExternalManagedAddressConfig
    );
    this.insertSingleAddressTabIfRequired(this.isExternalManaged);

    this.cdr.detectChanges();
  }

  private showCreateNewDialogAfterSave(lead: LeadViewModel): void {
    if (this.lead.AdditionalAddresses.length === lead.AdditionalAddresses.length) {
      return;
    }

    this.subscriptions.add(
      this.dialogService
        .open(CreateEditAddressDialogComponent)
        .afterClosed()
        .subscribe((step: CreateEditContactEnum) => {
          if (step === CreateEditContactEnum.EDIT) {
            lead.AdditionalAddresses.forEach((newContact: LeadContactViewModel) => {
              if (
                this.lead.AdditionalAddresses.findIndex(
                  (c: LeadContactViewModel) => c.Id === newContact.Id
                ) < 0
              ) {
                this.contactOrigin = cloneDeep(newContact);
                this.loadContact();
                this.router.navigate(['lead/leadaddr/', this.lead.Id], {
                  queryParams: { addressId: newContact.Id },
                  replaceUrl: true,
                });
              }
            });
          } else if (step === CreateEditContactEnum.CREATE) {
            this.setInitialDataForEmptyContact();
          } else {
            this.onBack();
          }
          this.cdr.detectChanges();
        })
    );
  }

  private insertSingleAddressTabIfRequired(isExternalManaged: boolean): void {
    const existingIndex = this.tabsData.findIndex(x => x.id === ActiveTab.singleAddress);
    if (isExternalManaged && existingIndex >= 0) {
      this.tabsData.splice(existingIndex, 1);
    } else if (!isExternalManaged && existingIndex < 0) {
      const singleAddressTab = {
        id: ActiveTab.singleAddress,
        tabLabel: 'ADDRESS.CREATE.AS_SINGLE_ADDRESS',
        tabName: 'ADDRESS.CREATE.AS_SINGLE_ADDRESS',
      } as TabsData;
      this.tabsData = [singleAddressTab, ...this.tabsData];
    }
  }

  private checkIfAddressValid(
    searchAddress: string
  ): Observable<{ valid: boolean; addressData?: any }> {
    return this.http
      .get(
        `${LOCATION_SEARCH_API_URL}?searchText=${encodeURI(
          searchAddress
        )}&type=locations&origins=address&limit=1`
      )
      .pipe(
        map((c: any) => ({ valid: c.results.length === 1, addressData: c.results?.[0]?.attrs })),
        catchError(() => of({ valid: true }))
      );
  }

  private invalidAddressSavingDialog(searchAddress: string): Observable<boolean> {
    return this.dialogService
      .openConfirm({
        message: this.translate.instant('ADDRESS.CONFIRM_NOT_VALID_IN_3D', {
          address: searchAddress,
        }),
      })
      .afterClosed();
  }

  private getUpdatePV3dBuildingDataRequest(
    addressData: any,
    searchAddress: string
  ): Observable<boolean> {
    return this.dialogService
      .open(Roof3dPluginDialogComponent, {
        data: {
          address: { lat: +addressData.lat, lon: +addressData.lon },
          onLoadError: (dialogRef: DialogRef) => {
            dialogRef.close(searchAddress);
          },
        },
      })
      .afterClosed()
      .pipe(
        filter(data => !isNullOrUndefined(data)),
        switchMap((data: PutPvBuildingData | string) =>
          typeof data === 'string'
            ? this.invalidAddressSavingDialog(data)
            : this.leadService
                .pvLeadPutPvLeadBuildingData(this.leadId, {
                  ...data,
                  SelectedRoofAres: Math.round(data.SelectedRoofAres),
                  KwhYieldPerKwp: Math.round(data.KwhYieldPerKwp),
                })
                .pipe(map(() => true))
        ),
        catchError(() => of(true))
      );
  }
}
