import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { LeadRequest, LeadService } from '../apis/advis';
import { finalize, map } from 'rxjs/operators';
import { LoadingModalService } from '../services/loading-modal.service';

@Injectable({
  providedIn: 'root',
})
class LeadByPhoneService {
  constructor(
    private router: Router,
    private leadService: LeadService,
    private loadingModalService: LoadingModalService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot): ReturnType<CanActivateFn> {
    const urlSegments = next.pathFromRoot
      .map(snapshot => snapshot.url)
      .reduce((acc, urlSegments) => acc.concat(urlSegments), [])
      .filter(urlSegment => !!urlSegment);
    const currentUrl = urlSegments.map(urlSegment => urlSegment.path);

    const PhoneNumber = next.queryParams?.CallerId;
    const AddressLookupType = next.queryParams?.Type;

    if (!PhoneNumber) {
      return 'CallerId' in next.queryParams
        ? this.router.createUrlTree(currentUrl.concat(['overview']))
        : true;
    }

    this.loadingModalService.openModal();

    return this.leadService
      .leadGetLeads({
        PhoneNumber: PhoneNumber.replaceAll(' ', '').slice(-9),
        AddressLookupType: AddressLookupType || LeadRequest.AddressLookupTypeEnum.Contact,
        PageIndex: 1,
        // @ts-ignore
        SortField: LeadRequest.SortFieldEnum.ModiDate,
        SortOrder: LeadRequest.SortOrderEnum.Desc,
      })
      .pipe(
        map(res => {
          const relativeUrl = currentUrl.concat([
            ...(res?.Entries?.length ? ['detail', res.Entries[0].Id?.toString()] : ['overview']),
          ]);
          return this.router.createUrlTree(relativeUrl);
        }),
        finalize(() => {
          this.loadingModalService.closeModal();
        })
      );
  }
}

export const LeadByPhoneGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
): ReturnType<CanActivateFn> => {
  return inject(LeadByPhoneService).canActivate(next);
};
