import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
// @ts-ignore
import initSketchApp from '@sitewerk/image-sketch-app';
import {
  LeadNoteService,
  MasterDataViewModel,
  NoteTemplateViewModel,
  NoteViewModel,
  RoleTargetGroupViewModel,
} from '../../shared/apis/advis';
import { BehaviorSubject, combineLatest, Observable, Observer, Subject, Subscription } from 'rxjs';
import { NoteTypeEnum } from '../../shared/models/enum/note-type.enum';
import {
  ADD_DRAWING_NOTE_SUCCESS,
  ADD_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION,
  ADD_TEXT_NOTE_FAILURE,
  ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION,
  AddDrawingNoteAction,
  AddDrawingNoteSuccessAction,
  AddTextNoteAction,
  AddTextNoteFailureAction,
  AddTextNoteSuccessWithoutNavigationAction,
  GetNoteTemplatesAction,
  IDrawingNote,
  REMOVE_NOTE_FAILURE,
  REMOVE_NOTE_SUCCESS,
  RemoveNoteAction,
  UPDATE_DRAWING_NOTE_SUCCESS,
  UPDATE_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION,
  UPDATE_TEXT_NOTE_FAILURE,
  UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION,
  UpdateDrawingNoteAction,
  UpdateDrawingNoteSuccessAction,
  UpdateTextNoteAction,
  UpdateTextNoteFailureAction,
  UpdateTextNoteSuccessWithoutNavigationAction,
} from '../../shared/states/lead-notes/lead-notes.action';
import { ImageResizeService } from '../../shared/services/image-resize.service';
import { PictureUploadService } from '../../shared/services/picture-upload.service';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../shared/states';
import { IState } from '../../shared/states';
import { SYSTEM_TAG_MARKER } from '../../lead/dialog/lead-tag/lead-tag-dialog.component';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, filter, skip, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { LoadingModalService } from '../../shared/services/loading-modal.service';
import { AutosaveService } from '../../common-components/pc-text-editor/autosave.service';
import { PcTextEditorComponent } from '../../common-components/pc-text-editor/pc-text-editor.component';

@Component({
  selector: 'pc-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit, OnDestroy, OnChanges {
  public sharedNoteTemplates: NoteTemplateViewModel[] = [];
  public individualNoteTemplates: NoteTemplateViewModel[] = [];
  public selectedNoteTemplate: NoteTemplateViewModel;

  protected readonly noteTypeEnum: typeof NoteTypeEnum = NoteTypeEnum;

  private subscriptions: Subscription = new Subscription();
  private handwrittenNoteRef: HTMLDivElement;
  private sketchApp: any = {
    destroy: () => {
      return {};
    },
  };
  protected roleTargetGroups: RoleTargetGroupViewModel[] = [];
  protected selectedRoleTargets: string[] = [];

  @Input() protected hiddenSystemTag: boolean = false;
  @Input() protected tags: string[];
  @Input() isNotesOnFullScreen: boolean;
  @Input() isNew: boolean;
  @Input() isEditMode: boolean;
  @Input() editOnly: boolean;
  @Input() readOnly: boolean;
  @Input() note: NoteViewModel;
  @Input() isTaggedNote: boolean = false;
  @Input() translateRoleTargetGroup: Function;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBack: EventEmitter<NoteViewModel> = new EventEmitter<NoteViewModel>();
  @Output() onEditModeChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(PcTextEditorComponent, { static: false }) editorComponent: PcTextEditorComponent;

  public existingLeadNotesTags: string[];
  private tags$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private targetGroupChange$ = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private store: Store<IState>,
    private translate: TranslateService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private leadNoteService: LeadNoteService,
    public sanitizer: DomSanitizer,
    private autosaveService: AutosaveService,
    private actions$: Actions,
    private pictureUploadService: PictureUploadService,
    private imageResizeService: ImageResizeService,
    private loadingModalService: LoadingModalService
  ) {}

  static Save(
    isNew: boolean,
    note: NoteViewModel,
    isDrawingNote: boolean,
    noteComponent: NoteComponent,
    imageResizeService: ImageResizeService,
    pictureUploadService: PictureUploadService,
    store: Store<RootReducer.IState>,
    subscriptions: Subscription,
    navigationBackToLastUrl: boolean
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (isDrawingNote) {
        noteComponent.getDrawingNote().then((blob: Blob) => {
          const drawFile: File = new File([blob], pictureUploadService.generateFileName('png', 0), {
            type: 'image/png',
          });

          subscriptions.add(
            imageResizeService
              .doImageResize([drawFile], 500, 300, { withAlpha: true, type: 'image/png' })
              .subscribe((result: File[]) => {
                const sketchData: string = noteComponent.getDrawingExportData();
                const noteData: NoteViewModel = {
                  Id: note.Id || Date.now(),
                  LeadId: note.LeadId,
                  NoteType: NoteTypeEnum.DRAWING,
                  DrawingNoteImageData: sketchData,
                  Tags: note.Tags && note.Tags?.length > 0 ? note.Tags : undefined,
                  Created: note.Created || (new Date().toISOString() as any),
                  CreatedUser: note.CreatedUser || undefined,
                  TargetGroupRoleIds: note.TargetGroupRoleIds,
                };
                const fileReader: FileReader = new FileReader();
                fileReader.onloadend = () => {
                  let resultBase64: string = fileReader.result.toString();
                  resultBase64 = resultBase64.replace('application/octet-stream', result[0].type);
                  const data: IDrawingNote = {
                    noteData,
                    drawingImageBase64: resultBase64,
                    drawingImage: result[0],
                    targetGroupRoleIds: note.TargetGroupRoleIds,
                    doNotNavigationBack: !navigationBackToLastUrl,
                  };
                  if (isNew) {
                    store.dispatch(new AddDrawingNoteAction(data));
                  } else {
                    store.dispatch(new UpdateDrawingNoteAction(data));
                  }
                  observer.next(true);
                  observer.complete();
                };
                fileReader.readAsDataURL(result[0].slice(0));
              })
          );
        });
      } else {
        if (isNew) {
          store.dispatch(
            new AddTextNoteAction({ note: note, doNotNavigationBack: !navigationBackToLastUrl })
          );
        } else {
          store.dispatch(
            new UpdateTextNoteAction({ note: note, doNotNavigationBack: !navigationBackToLastUrl })
          );
        }
        observer.next(true);
        observer.complete();
      }
    });
  }

  ngOnInit(): void {
    this.cdr.markForCheck();

    this.selectedRoleTargets = this.note.TargetGroupRoleIds ?? [];
    this.subscriptions.add(
      combineLatest([
        this.store.select<MasterDataViewModel>(RootReducer.getGlobalMasterData),
        this.store.select<Set<number>>(RootReducer.getGlobalSelectedMandants),
      ]).subscribe(([masterData, selectedMandants]) => {
        if (masterData && selectedMandants) {
          this.roleTargetGroups = masterData.RoleTargetGroups.filter(
            rtg =>
              rtg.IsEnabledForNotesTargetGroup &&
              rtg.MandatorIds.some(mId => selectedMandants.has(mId))
          );
        }
      })
    );

    this.targetGroupChange$.pipe(debounceTime(2000)).subscribe(() => {
      if (!this.isNew) {
        this.save(this.editorComponent.editorId);
      }
    });

    this.subscriptions.add(
      this.store.select(RootReducer.getNoteTemplates).subscribe(templates => {
        this.sharedNoteTemplates = templates?.filter(
          t => t.Type === NoteTemplateViewModel.TypeEnum.Shared
        );
        this.individualNoteTemplates = templates?.filter(
          t => t.Type === NoteTemplateViewModel.TypeEnum.Individual
        );
        this.cdr.markForCheck();
      })
    );
    this.store.dispatch(new GetNoteTemplatesAction(this.note.LeadId));

    this.subscriptions.add(
      this.tags$
        .pipe(
          skip(1),
          distinctUntilChanged((a, b) => a?.length === b?.length)
        )
        .subscribe(() => {
          if (
            this.isTaggedNote &&
            this.note.NoteType === NoteTypeEnum.TEXT &&
            this.note.Text?.length &&
            !this.isNew
          ) {
            this.save();
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.note || changes.isTaggedNote) &&
      !!this.isTaggedNote &&
      !!this.note?.LeadId &&
      !this.existingLeadNotesTags
    ) {
      this.setExistingNotesTags();
    }

    if (changes.note && this.note.NoteType === NoteTypeEnum.DRAWING && this.isEditMode) {
      setTimeout(() => {
        this.setSketchApp();
      });
    }

    if (changes.note && this.note) {
      this.tags$.next(this.note.Tags);
    }
  }

  onTextNoteChange(data: any): void {
    this.note.Text = data;
    this.onChange.emit(true);
  }

  protected onTargetGroupChange(event: any): void {
    this.selectedRoleTargets.push = event.target.dataset.value.split(',');
    this.targetGroupChange$.next();
  }

  focusEditor(): void {
    this.editorComponent.focusEditor();
  }

  getDrawingNote(): any {
    return this.sketchApp.exportImage();
  }

  getDrawingExportData(): any {
    return this.sketchApp.exportData();
  }

  ngOnDestroy(): void {
    if (this.isEditMode && this.note.NoteType === NoteTypeEnum.TEXT && this.note.Text?.length) {
      this.save();
    }
    this.subscriptions.unsubscribe();
    this.sketchApp.destroy();
  }

  public setSketchApp(): void {
    this.handwrittenNoteRef = this.elementRef.nativeElement.querySelector('#handwrittenNote');

    this.sketchApp = initSketchApp(
      this.handwrittenNoteRef,
      undefined,
      'png',
      {
        fontSizeScale: [8, 16, 24, 32, 48, 64, 80, 96, 112],
        colorPalette: ['#000000', '#f03e3e', '#3b5bdb', '#2f9e44', '#ffd43b'],
        useExtendedApp: true,
      },
      this.isNew ? undefined : this.note.DrawingNoteImageData
    );

    this.sketchApp.onChange(() => {
      this.onChange.emit(true);
      this.cdr.markForCheck();
    });
  }

  public tagsChange(): void {
    this.tags$.next(this.note.Tags);
  }

  public save(editorId?: string): void {
    this.note.TargetGroupRoleIds = this.selectedRoleTargets;

    if (this.note.NoteType === NoteTypeEnum.DRAWING) {
      this.loadingModalService.openModal();

      this.subscriptions.add(
        this.actions$
          .pipe(
            ofType(
              ADD_DRAWING_NOTE_SUCCESS,
              ADD_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION,
              UPDATE_DRAWING_NOTE_SUCCESS,
              UPDATE_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION
            )
          )
          .subscribe((action: AddDrawingNoteSuccessAction | UpdateDrawingNoteSuccessAction) => {
            this.loadingModalService.closeModal();
            this.isEditMode = this.editOnly ?? false;
            this.onBack.emit(action.payload);
          })
      );
    } else if (this.isNew) {
      this.subscriptions.add(
        this.actions$
          .pipe(
            ofType(
              ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION,
              UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION
            )
          )
          .subscribe(
            (
              action:
                | AddTextNoteSuccessWithoutNavigationAction
                | UpdateTextNoteSuccessWithoutNavigationAction
            ) => {
              this.isEditMode = this.editOnly ?? false;
              this.onBack.emit(action.payload);
            }
          )
      );
    }

    this.subscriptions.add(
      NoteComponent.Save(
        !this.note.Id,
        this.note,
        this.note.NoteType === NoteTypeEnum.DRAWING,
        this,
        this.imageResizeService,
        this.pictureUploadService,
        this.store,
        this.subscriptions,
        false
      )
        .pipe(filter(success => !!success))
        .subscribe()
    );

    if (editorId) {
      this.autosaveService.autosaveInProgress(editorId);
      this.subscriptions.add(
        this.isNew
          ? this.actions$
              .pipe(
                ofType(ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION, ADD_TEXT_NOTE_FAILURE),
                take(1)
              )
              .subscribe(
                (action: AddTextNoteSuccessWithoutNavigationAction | AddTextNoteFailureAction) =>
                  action.type === ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION
                    ? this.autosaveService.autosaveSuccess(editorId)
                    : this.autosaveService.autosaveFailed(editorId)
              )
          : this.actions$
              .pipe(
                ofType(UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION, UPDATE_TEXT_NOTE_FAILURE),
                take(1)
              )
              .subscribe(
                (
                  action: UpdateTextNoteSuccessWithoutNavigationAction | UpdateTextNoteFailureAction
                ) =>
                  action.type === UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION
                    ? this.autosaveService.autosaveSuccess(editorId)
                    : this.autosaveService.autosaveFailed(editorId)
              )
      );
    }
  }

  public selectNoteTemplate(title: string): void {
    this.selectedNoteTemplate =
      this.individualNoteTemplates.find(t => t.Title === title) ||
      this.sharedNoteTemplates.find(t => t.Title === title);
    this.note.Text = this.selectedNoteTemplate?.Content;
    setTimeout(() => this.editorComponent.setEditorData(this.selectedNoteTemplate?.Content));
  }

  public back(): void {
    this.isEditMode = false;
    this.onBack.emit(this.note);
  }

  public scrollToNote(): void {
    this.elementRef.nativeElement.scrollIntoView();
  }

  protected getTags(note: NoteViewModel): string[] {
    return !note.Tags
      ? []
      : note.Tags?.filter(
          x => !this.hiddenSystemTag || (this.hiddenSystemTag && !x.startsWith(SYSTEM_TAG_MARKER))
        );
  }

  protected showTags(note: NoteViewModel): boolean {
    return this.getTags(note)?.length > 0;
  }

  protected onEdit(): void {
    this.onEditModeChange.emit(this.note?.Id);
    this.isEditMode = true;
    this.focusEditor();
    if (this.note.NoteType === NoteTypeEnum.DRAWING) {
      setTimeout(() => {
        this.setSketchApp();
      });
    }
  }

  protected onDelete(note: NoteViewModel): void {
    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant('LEAD_NOTE.DELETE_QUESTION'),
        })
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            this.loadingModalService.openModal();
            this.subscriptions.add(
              this.actions$
                .pipe(ofType(REMOVE_NOTE_SUCCESS, REMOVE_NOTE_FAILURE))
                .subscribe(() => this.loadingModalService.closeModal())
            );
            this.store.dispatch(new RemoveNoteAction(note));
          }
        })
    );
  }

  private setExistingNotesTags() {
    this.subscriptions.add(
      this.leadNoteService.leadNoteGetNotes(this.note.LeadId).subscribe(res => {
        this.existingLeadNotesTags = [...new Set(res?.flatMap(({ Tags }) => Tags))];
      })
    );
  }
}
