/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PutDirectoryContactAddressRequest { 
    NewContactId: number;
    UpdateObjectAddress?: boolean;
    UpdateDebitorAddress?: boolean;
    LeadLanguage?: PutDirectoryContactAddressRequest.LeadLanguageEnum;
}
export namespace PutDirectoryContactAddressRequest {
    export type LeadLanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LeadLanguageEnum = {
        DE: 'DE' as LeadLanguageEnum,
        FR: 'FR' as LeadLanguageEnum,
        IT: 'IT' as LeadLanguageEnum,
        EN: 'EN' as LeadLanguageEnum
    };
}
