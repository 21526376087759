import * as LeadDocument from './lead-document.action';
import {
  LeadDocumentListViewModel,
  LeadDocumentViewModel,
  FileLinkViewModel,
  PictureListViewModel,
  PictureViewModel,
  DocumentTemplatesViewModel,
} from '../../apis/advis';
import { tassign } from 'tassign';
import cloneDeep from 'lodash/cloneDeep';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export interface IState {
  picturesLoading: boolean;
  pictures: PictureListViewModel;
  picturesLeadId: number;
  documentsLoading: boolean;
  documents: LeadDocumentListViewModel;
  documentsLeadId: number;
  filesLoading: boolean;
  files: FileLinkViewModel[];
  stencilsLoading: boolean;
  stencils: string[];
  documentAmdPictureSync: boolean;
  documentTemplates: DocumentTemplatesViewModel;
  documentTemplatesLoading: boolean;
}

export const initialState: IState = {
  picturesLoading: false,
  pictures: undefined,
  picturesLeadId: 0,
  documentsLoading: false,
  documents: undefined,
  documentsLeadId: undefined,
  filesLoading: false,
  files: undefined,
  documentAmdPictureSync: false,
  stencilsLoading: false,
  stencils: [],
  documentTemplates: undefined,
  documentTemplatesLoading: false,
};

export function reducer(state: IState = initialState, action: LeadDocument.Actions): IState {
  switch (action.type) {
    case LeadDocument.LOAD_LEAD_DOCUMENT_TEMPLATES: {
      return tassign(state, {
        documentTemplatesLoading: true,
      });
    }

    case LeadDocument.LOAD_LEAD_DOCUMENT_TEMPLATES_SUCCESS: {
      return tassign(state, {
        documentTemplatesLoading: false,
        documentTemplates: action.payload,
      });
    }

    case LeadDocument.LOAD_LEAD_DOCUMENT_TEMPLATES_SUCCESS: {
      return tassign(state, {
        documentTemplatesLoading: false,
        documentTemplates: undefined,
      });
    }

    case LeadDocument.GET_DOCUMENTS: {
      return tassign(state, {
        documentsLoading: true,
      });
    }

    case LeadDocument.GET_DOCUMENTS_SUCCESS: {
      return tassign(state, {
        documentsLoading: false,
        documents: action.payload.documents,
        documentsLeadId: action.payload.leadId,
      });
    }

    case LeadDocument.UPLOAD_DOCUMENT: {
      return tassign(state, {
        documentsLoading: true,
      });
    }

    case LeadDocument.UPLOAD_DOCUMENT_SUCCESS: {
      return tassign(state, {
        documentsLoading: false,
      });
    }

    case LeadDocument.GET_DOCUMENTS_FAILED:
    case LeadDocument.UPLOAD_DOCUMENT_FAILED:
    case LeadDocument.DELETE_DOCUMENT_FAILED: {
      console.error('ERROR is: ', action.payload);
      return tassign(state, {
        documentsLoading: false,
      });
    }

    case LeadDocument.DELETE_DOCUMENT: {
      return tassign(state, {
        documentsLoading: true,
      });
    }

    case LeadDocument.DELETE_DOCUMENT_SUCCESS: {
      const documents: LeadDocumentListViewModel = cloneDeep(state.documents);
      documents.Documents = state.documents.Documents.filter((document: LeadDocumentViewModel) => {
        if (
          action.payload.fileName === document.FileName &&
          action.payload.fileFolder === document.Folder
        ) {
          return false;
        }
        return true;
      });

      return tassign(state, {
        documentsLoading: false,
        documents,
      });
    }

    case LeadDocument.SYNC_DOCUMENT_AND_PICTURE: {
      return tassign(state, {
        documentAmdPictureSync: true,
      });
    }

    case LeadDocument.SYNC_DOCUMENT_AND_PICTURE_FAILED:
    case LeadDocument.SYNC_DOCUMENT_AND_PICTURE_SUCCESS: {
      return tassign(state, {
        documentAmdPictureSync: false,
      });
    }

    case LeadDocument.GET_PICTURES: {
      return tassign(state, {
        picturesLoading: true,
        pictures:
          isNullOrUndefined(state.pictures) ||
          isNullOrUndefined(action.payload.leadId) ||
          action.payload.leadId.toString() !== state.picturesLeadId.toString()
            ? undefined
            : state.pictures,
      });
    }

    case LeadDocument.GET_PICTURES_SUCCESS: {
      return tassign(state, {
        picturesLoading: false,
        pictures: action.payload.pictures,
        picturesLeadId: action.payload.leadId,
      });
    }

    case LeadDocument.UPLOAD_PICTURE: {
      return tassign(state, {
        picturesLoading: true,
      });
    }

    case LeadDocument.UPLOAD_PICTURE_SUCCESS: {
      return tassign(state, {
        picturesLoading: false,
      });
    }

    case LeadDocument.DELETE_PICTURE: {
      return tassign(state, {
        picturesLoading: true,
      });
    }

    case LeadDocument.DELETE_PICTURE_SUCCESS: {
      const pictures: PictureListViewModel = cloneDeep(state.pictures);
      pictures.Pictures = state.pictures.Pictures.filter((picture: PictureViewModel) => {
        if (
          action.payload.fileName === picture.FileName &&
          action.payload.folder === picture.Folder
        ) {
          return false;
        }
        return true;
      });

      return tassign(state, {
        picturesLoading: false,
        pictures,
      });
    }

    case LeadDocument.GET_PICTURES_FAILED:
    case LeadDocument.UPLOAD_PICTURE_FAILED:
    case LeadDocument.DELETE_PICTURE_FAILED: {
      console.error('ERROR is: ', action.payload);
      return tassign(state, {
        picturesLoading: false,
      });
    }

    case LeadDocument.PATCH_PICTURE_TAG_SUCCESS: {
      const pictures: PictureListViewModel = cloneDeep(state.pictures);
      const pictureIndex: number = pictures.Pictures.findIndex(
        (picture: PictureViewModel) =>
          action.payload.FileName === picture.FileName && action.payload.Folder === picture.Folder
      );
      if (pictureIndex > -1) {
        pictures.Pictures.splice(pictureIndex, 1, action.payload);
      }
      return tassign(state, {
        pictures,
      });
    }

    case LeadDocument.GET_FILES: {
      return tassign(state, {
        filesLoading: true,
        files: undefined,
      });
    }

    case LeadDocument.GET_FILES_SUCCESS: {
      return tassign(state, {
        filesLoading: false,
        files: action.payload,
      });
    }

    case LeadDocument.UPLOAD_FILE: {
      return tassign(state, {
        filesLoading: true,
      });
    }

    case LeadDocument.UPLOAD_FILE_SUCCESS: {
      return tassign(state, {
        filesLoading: false,
      });
    }

    case LeadDocument.GET_FILES_FAILED:
    case LeadDocument.UPLOAD_FILE_FAILED:
    case LeadDocument.DELETE_FILE_FAILED: {
      console.error('ERROR is: ', action.payload);
      return tassign(state, {
        filesLoading: false,
      });
    }

    case LeadDocument.DELETE_FILE: {
      return tassign(state, {
        filesLoading: true,
      });
    }

    case LeadDocument.DELETE_FILE_SUCCESS: {
      const fileNameToDelete: string = action.payload.fileName;
      const files: FileLinkViewModel[] = state.files.filter((document: FileLinkViewModel) => {
        if (fileNameToDelete.localeCompare(document.FileName) !== 0) {
          return true;
        }
        return false;
      });

      return tassign(state, {
        filesLoading: false,
        files,
      });
    }

    case LeadDocument.GET_LEAD_STENCILS_FAILED: {
      return tassign(state, {
        stencilsLoading: false,
        stencils: [],
      });
    }

    case LeadDocument.GET_LEAD_STENCILS_SUCCESS: {
      return tassign(state, {
        stencils: action.payload,
        stencilsLoading: false,
      });
    }

    case LeadDocument.GET_LEAD_STENCILS: {
      return tassign(state, {
        stencilsLoading: true,
      });
    }

    case LeadDocument.CREATE_LEAD_FOLDER_SUCCESS: {
      return tassign(state, {
        documents: action.payload,
      });
    }

    default: {
      return state;
    }
  }
}

export const getDocumentsLoading: (state: IState) => boolean = (state: IState) =>
  state.documentsLoading;
export const getDocuments: (state: IState) => LeadDocumentListViewModel = (state: IState) =>
  state.documents;
export const getPicturesLoading: (state: IState) => boolean = (state: IState) =>
  state.picturesLoading;
export const getPictures: (state: IState) => PictureListViewModel = (state: IState) =>
  state.pictures;
export const getFilesLoading: (state: IState) => boolean = (state: IState) => state.filesLoading;
export const getFiles: (state: IState) => FileLinkViewModel[] = (state: IState) => state.files;
export const getDocumentAndPictureSync: (state: IState) => boolean = (state: IState) =>
  state.documentAmdPictureSync;
export const getDocumentTemplates: (state: IState) => DocumentTemplatesViewModel = (
  state: IState
) => state.documentTemplates;
