<helion-ui-drawer [noDrawerIcon]="true">
  <div role="header" fxLayout="row" fxLayoutAlign="start center" class="width-100 max-width-100">
    <helion-ui-tabs class="lead-tabs" fxFlex fxFlex.lt-md="0 0 calc(100% - 120px)">
      <button>
        <div
          [class.active-tab]="activeUserViewE === ActiveUserViewE.UserAdministration"
          (click)="setActiveView(ActiveUserViewE.UserAdministration)">
          {{ 'USER.EDIT.USER_EDIT' | translate | titlecase }}
        </div>
      </button>

      <button>
        <div
          [class.active-tab]="activeUserViewE === ActiveUserViewE.PasswordAdministration"
          (click)="setActiveView(ActiveUserViewE.PasswordAdministration)">
          {{ 'USER.EDIT.PASSWORD' | translate | titlecase }}
        </div>
      </button>

      <button *ngIf="this.canEditNotes">
        <div
          [class.active-tab]="activeUserViewE === ActiveUserViewE.NoteTemplatesAdministration"
          (click)="setActiveView(ActiveUserViewE.NoteTemplatesAdministration)">
          {{ 'USER.EDIT.NOTE_TEMPLATES' | translate | titlecase }}
        </div>
      </button>
    </helion-ui-tabs>

    <span style="flex: 1"></span>

    <div fxLayoutGap="16px">
      <button class="new-person-button" (click)="onBack()">
        {{ 'COMMON.BTN_CANCEL' | translate }}
      </button>
      <button
        [attr.fill-type]="'contained'"
        (click)="onSave()"
        [disabled]="isSaveButtonDisabled()"
        data-cy="newLeadButtonSave">
        {{ 'COMMON.BTN_SAVE' | translate }}
      </button>
    </div>
  </div>
</helion-ui-drawer>

<div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start">
  <div fxFlex.gt-xs="70">
    <helion-ui-card *ngIf="activeUserViewE === ActiveUserViewE.UserAdministration">
      <div content>
        <form #userEditForm="ngForm">
          <div class="position-relative">
            <div *ngIf="user">
              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #firstName
                  placeholder="{{ 'USER.EDIT.FIRSTNAME' | translate }}"
                  type="text"
                  maxlength="100"
                  name="FirstName"
                  [(ngModel)]="user.FirstName"
                  required>
                </helion-ui-input>

                <helion-ui-input
                  fxFlex="100"
                  #lastName
                  placeholder="{{ 'USER.EDIT.LASTNAME' | translate }}"
                  type="text"
                  maxlength="100"
                  name="LastName"
                  [(ngModel)]="user.LastName"
                  required>
                </helion-ui-input>
              </div>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #email
                  placeholder="{{ 'USER.EDIT.EMAIL' | translate }}"
                  type="text"
                  maxlength="100"
                  name="Email"
                  [(ngModel)]="user.Email"
                  required>
                </helion-ui-input>

                <helion-ui-input
                  fxFlex="100"
                  #phoneNumber
                  placeholder="{{ 'USER.EDIT.PHONE' | translate }}"
                  type="text"
                  maxlength="100"
                  name="PhoneNumber"
                  [(ngModel)]="user.PhoneNumber"
                  required>
                </helion-ui-input>
              </div>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #companyName
                  placeholder="{{ 'USER.EDIT.COMPANY_ADDRESS.COMPANY_NAME' | translate }}"
                  type="text"
                  maxlength="100"
                  name="CompanyName"
                  [(ngModel)]="user.CompanyAddress.CompanyName">
                </helion-ui-input>

                <helion-ui-input
                  fxFlex="100"
                  #jobTitle
                  placeholder="{{ 'USER.EDIT.JOBTITLE' | translate }}"
                  type="text"
                  maxlength="100"
                  name="JobTitle"
                  [(ngModel)]="user.JobTitle">
                </helion-ui-input>
              </div>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #street
                  placeholder="{{ 'USER.EDIT.COMPANY_ADDRESS.STREET' | translate }}"
                  type="text"
                  maxlength="100"
                  name="Street"
                  [(ngModel)]="user.CompanyAddress.Street">
                </helion-ui-input>
              </div>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #zipCode
                  placeholder="{{ 'USER.EDIT.COMPANY_ADDRESS.ZIP_CODE' | translate }}"
                  type="Number"
                  name="ZipCode"
                  [(ngModel)]="user.CompanyAddress.ZipCode">
                </helion-ui-input>

                <helion-ui-input
                  fxFlex="100"
                  #city
                  placeholder="{{ 'USER.EDIT.COMPANY_ADDRESS.CITY' | translate }}"
                  type="text"
                  maxlength="100"
                  name="City"
                  [(ngModel)]="user.CompanyAddress.City">
                </helion-ui-input>
              </div>

              <div fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="25px">
                <helion-ui-checkbox
                  fxFlex.lt-md="50%"
                  fxFlex.gt-sm="50%"
                  [(ngModel)]="user.SendEmailOnLeadAssigned">
                  {{ 'USER.EDIT.SEND_EMAIL_ON_LEAD_ASSIGNED' | translate }}
                </helion-ui-checkbox>
              </div>

              <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="25px">
                <div fxFlex.lt-md="100" fxFlex.gt-sm="50">
                  <pc-user-picture
                    [titleKey]="'USER.EDIT.PICTURE_FILE'"
                    [(pictureUrl)]="pictureUrl"
                    [(picture)]="newPicture"
                    (onDelete)="onPictureDelete($event)"
                    (pictureChange)="onChangePicture()">
                  </pc-user-picture>
                </div>

                <div fxFlex.lt-md="100" fxFlex.gt-sm="50">
                  <pc-user-picture
                    [titleKey]="'USER.EDIT.SIGNATURE_FILE'"
                    [(pictureUrl)]="signatureUrl"
                    [(picture)]="newSignature"
                    (onDelete)="onSignatureDelete($event)"
                    (pictureChange)="onChangePicture()">
                  </pc-user-picture>
                </div>
              </div>
            </div>
            <pc-loading-modal
              [id]="modalLoaderIdTypesEnum.USER_EDIT"
              type="overlay"></pc-loading-modal>
          </div>
        </form>
      </div>
    </helion-ui-card>

    <helion-ui-card *ngIf="activeUserViewE === ActiveUserViewE.PasswordAdministration">
      <div content>
        <form #passwordEditForm="ngForm">
          <div class="position-relative">
            <div>
              <div fxLayout.sm="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #firstName
                  placeholder="{{ 'USER.EDIT.CURRENT_PASSWORD' | translate }}"
                  type="password"
                  maxlength="100"
                  [(ngModel)]="passwordRequest.currentPassword"
                  name="currentPassword"
                  required>
                </helion-ui-input>
              </div>
              <div fxLayout.sm="column" fxLayoutGap="25px">
                <helion-ui-input
                  fxFlex="100"
                  #firstName
                  placeholder="{{ 'USER.EDIT.NEW_PASSWORD' | translate }}"
                  type="password"
                  maxlength="100"
                  [(ngModel)]="passwordRequest.newPassword"
                  name="newPassword"
                  required
                  minlength="9">
                </helion-ui-input>

                <helion-ui-input
                  fxFlex="100"
                  #lastName
                  placeholder="{{ 'USER.EDIT.NEW_PASSWORD_AGAIN' | translate }}"
                  type="password"
                  maxlength="100"
                  [(ngModel)]="passwordRequest.againNewPassword"
                  name="newPasswordAgain"
                  required
                  minlength="9">
                </helion-ui-input>
              </div>
              <div fxLayout.sm="column" fxLayoutGap="25px">
                <ul>
                  <li>
                    {{ 'USER.EDIT.MIN_NINE_CHARS' | translate }}
                  </li>
                </ul>
                <ul>
                  <li>
                    {{ 'USER.EDIT.MIN_ONE_UPPER_LOWER' | translate }}
                  </li>
                </ul>
                <ul>
                  <li>
                    {{ 'USER.EDIT.MIN_ONE_NUMBER' | translate }}
                  </li>
                </ul>
                <ul>
                  <li>
                    {{ 'USER.EDIT.MIN_ONE_SPECIAL_CHAR' | translate }}
                  </li>
                </ul>
              </div>
            </div>
            <pc-loading-modal
              [id]="modalLoaderIdTypesEnum.PASSWORD_EDIT"
              type="overlay"></pc-loading-modal>
          </div>
        </form>
      </div>
    </helion-ui-card>

    <helion-ui-card
      *ngIf="activeUserViewE === ActiveUserViewE.NoteTemplatesAdministration && this.canEditNotes">
      <div content>
        <div class="position-relative">
          <div *ngFor="let formControl of noteTemplatesEditForm.controls" style="margin-top: 36px">
            <helion-ui-error
              [hidden]="isNoteTemplateValid(formControl.value)"
              [message]="'USER.EDIT.NOTE_TEMPLATE_INVALID' | translate"></helion-ui-error>
            <helion-ui-input
              placeholder="{{ 'USER.EDIT.NOTE_TEMPLATE_TITLE' | translate }}"
              type="text"
              [(ngModel)]="formControl.value.Title"></helion-ui-input>
            <div style="margin-top: 16px">
              <pc-text-editor
                #editor
                [initialData]="formControl.value.Content || ''"
                (textChange)="formControl.value.Content = $event"
                [placeholder]="'USER.EDIT.NOTE_TEMPLATE_CONTENT' | translate"></pc-text-editor>
            </div>
          </div>
        </div>
      </div>
    </helion-ui-card>
  </div>
</div>
