<div class="action-buttons">
  <button
    *ngIf="showEditIcon"
    (click)="onEditAddress($event)"
    [attr.aria-label]="'COMMON.ACTION.MODIFY' | translate"
    [tooltip]="'COMMON.ACTION.MODIFY' | translate">
    <helion-ui-icon [size]="20" data-feather="edit-2"></helion-ui-icon>
  </button>
  <button *ngIf="hasRegionalInformation" (click)="openRegionalInfo()">
    <helion-ui-icon [size]="20" data-feather="help-circle"></helion-ui-icon>
  </button>
  <a [href]="searchLink" target="_blank" data-rel="external">
    <helion-ui-icon [size]="20" data-feather="corner-up-right"></helion-ui-icon>
  </a>
</div>
