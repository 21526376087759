/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SopChecklistPhaseViewModel } from './sopChecklistPhaseViewModel';


export interface SopChecklistViewModel { 
    SopListId?: number;
    ChecklistId?: number;
    ChecklistTitle?: string;
    ChecklistTemplateDate?: Date;
    ChecklistIsCompleted?: boolean;
    ChecklistActivePhase?: SopChecklistViewModel.ChecklistActivePhaseEnum;
    SopChecklistPhases?: Array<SopChecklistPhaseViewModel>;
}
export namespace SopChecklistViewModel {
    export type ChecklistActivePhaseEnum = 'Definition' | 'Planning' | 'Sourcing' | 'Preparation' | 'Installation' | 'Closure';
    export const ChecklistActivePhaseEnum = {
        Definition: 'Definition' as ChecklistActivePhaseEnum,
        Planning: 'Planning' as ChecklistActivePhaseEnum,
        Sourcing: 'Sourcing' as ChecklistActivePhaseEnum,
        Preparation: 'Preparation' as ChecklistActivePhaseEnum,
        Installation: 'Installation' as ChecklistActivePhaseEnum,
        Closure: 'Closure' as ChecklistActivePhaseEnum
    };
}
