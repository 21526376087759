<section class="dialog">
  <h2 class="dialog-title">
    {{ 'DIALOG.REGIONAL_INFORMATION.TITLE' | translate }}
  </h2>

  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <helion-ui-tabs>
      <button *ngFor="let tab of tabsData; let i = index" (click)="activeView = tab.id">
        <div [ngClass]="{ 'active-tab': tab.id === activeView }">
          {{ tab.tabLabel | translate }}
        </div>
      </button>
    </helion-ui-tabs>
    <pc-trade-specifish-reginal-information
      *ngIf="tradeTypeRegionalResponsibilities.length > 0"
      [tradeTypeRegionalResponsibilities]="tradeTypeRegionalResponsibilities[+activeView]"
      [isMobile]="isMobile">
    </pc-trade-specifish-reginal-information>

    <div *ngIf="tradeTypeRegionalResponsibilities.length === 0">
      <helion-ui-divider dividerColor="gray" class="margin-bottom-20"></helion-ui-divider>
      <div style="margin-top: 20px !important">
        {{ 'DIALOG.REGIONAL_INFORMATION.NO_TRADE_DATA' | translate }}
      </div>
    </div>

    <helion-ui-divider dividerColor="gray" class="margin-bottom-20"></helion-ui-divider>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="with-font-bold">
      <span>{{ 'DIALOG.REGIONAL_INFORMATION.ELECTRICITY_SUPPLY' | translate }}:</span>
    </div>

    <div fxLayout="column" fxLayoutGap="50px">
      <form>
        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 10px)" class="contact-form__block">
            <div fxLayout="column" fxLayoutGap="16px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width">EVU: </span>
                <span>
                  <a
                    *ngIf="
                      leadRegionalAddressViewModel?.RegionalInformation?.UtilityUrl !== undefined &&
                      leadRegionalAddressViewModel?.RegionalInformation?.UtilityUrl !== null &&
                      leadRegionalAddressViewModel?.RegionalInformation?.UtilityUrl.length > 0
                    "
                    [href]="leadRegionalAddressViewModel?.RegionalInformation?.UtilityUrl"
                    target="_blank">
                    {{ 'DIALOG.REGIONAL_INFORMATION.EVU_LINK_LABEL' | translate }}
                  </a>
                </span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width">{{ 'VARIANT.TARIFS' | translate }}:</span>
                <span>
                  <a
                    *ngIf="tariffUrl !== undefined && tariffUrl !== null && tariffUrl.length > 0"
                    [href]="tariffUrl"
                    target="_blank">
                    {{ 'DIALOG.REGIONAL_INFORMATION.TARIFE_URL_LABEL' | translate }}
                  </a>
                </span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width"
                  >{{
                    'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.POWER_CONSUMP.ELECTRICITY_RATE_HT'
                      | translate : { currecny: 'CHF' }
                  }}:</span
                >
                <span>
                  {{ leadRegionalAddressViewModel?.RegionalInformation?.ElectricityRateHt }}
                </span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width"
                  >{{
                    'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.POWER_CONSUMP.ELECTRICITY_RATE_LT'
                      | translate : { currecny: 'CHF' }
                  }}:</span
                >
                <span>{{
                  leadRegionalAddressViewModel?.RegionalInformation?.ElectricityRateLt
                }}</span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <label>
                    <textarea
                      class="textarea"
                      placeholder="{{ 'DIALOG.REGIONAL_INFORMATION.TARIF_NOTES' | translate }}"
                      [disabled]="true"
                      name="notes"
                      [value]="
                        leadRegionalAddressViewModel?.RegionalInformation?.TariffNotes === undefined
                          ? ''
                          : leadRegionalAddressViewModel?.RegionalInformation?.TariffNotes
                      "></textarea>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="isMobile"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="contact-form__field"
            style="min-height: 16px"></div>

          <div
            fxFlex.lt-md="100"
            fxFlex.gt-sm="0 0 calc(50% - 10px)"
            class="contact-form__block"
            fxLayoutAlign="{{ fxLayoutAlign() }}">
            <div fxLayout="column" fxLayoutGap="16px">
              <div fxLayout="row" fxLayoutAlign="start center" class="contact-form__field">
                <span class="label-min-width"
                  >{{ 'DIALOG.REGIONAL_INFORMATION.INSTALLATION' | translate }}:</span
                >
                <span>
                  <a
                    *ngIf="
                      leadRegionalAddressViewModel?.RegionalInformation
                        ?.UtilityInstallationRegulationsUrl !== undefined &&
                      leadRegionalAddressViewModel?.RegionalInformation
                        ?.UtilityInstallationRegulationsUrl !== null &&
                      leadRegionalAddressViewModel?.RegionalInformation
                        ?.UtilityInstallationRegulationsUrl.length > 0
                    "
                    [href]="
                      leadRegionalAddressViewModel?.RegionalInformation
                        ?.UtilityInstallationRegulationsUrl
                    "
                    target="_blank">
                    {{ 'DIALOG.REGIONAL_INFORMATION.INSTALLATION_URL_LABEL' | translate }}
                  </a>
                </span>
              </div>

              <div
                *ngIf="!isMobile"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="contact-form__field"
                style="min-height: 16px"></div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width"
                  >{{
                    'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.POWER_CONSUMP.FEED_IN_TARIF_HT'
                      | translate : { currency: 'CHF' }
                  }}:</span
                >
                <span>{{ leadRegionalAddressViewModel?.RegionalInformation?.FeedInTariffHt }}</span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="label-min-width"
                  >{{
                    'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.POWER_CONSUMP.FEED_IN_TARIF_LT'
                      | translate : { currency: 'CHF' }
                  }}:</span
                >
                <span>{{ leadRegionalAddressViewModel?.RegionalInformation?.FeedInTariffLt }}</span>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <label>
                    <textarea
                      class="textarea"
                      placeholder="{{
                        'DIALOG.REGIONAL_INFORMATION.UTILITY_INSTALLATION_REGULATIONS_NOTES'
                          | translate
                      }}"
                      [disabled]="true"
                      name="notes"
                      [value]="
                        leadRegionalAddressViewModel?.RegionalInformation
                          ?.UtilityInstallationRegulationsNotes === undefined
                          ? ''
                          : leadRegionalAddressViewModel?.RegionalInformation
                              ?.UtilityInstallationRegulationsNotes
                      "></textarea>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="dialog-actions">
    <button [attr.fill-type]="'contained'" (click)="closeDialog()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
  </div>
</section>
