<h1 mat-dialog-title *ngIf="data.title && data.title.length > 0">{{ data.title }}</h1>
<div mat-dialog-content *ngIf="data.message && data.message.length > 0">
  <div class="confirm-dialog-message" [innerHTML]="data.message"></div>
</div>
<div mat-dialog-actions *ngIf="data.isShowActionButtons">
  <ng-container *ngIf="data.cancelButton && data.cancelButton.length > 0">
    <button
      [attr.fill-type]="'contained'"
      data-cy="customDialogCancelButton"
      *ngIf="!(data.acceptButton && data.acceptButton.length > 0)"
      (click)="dialogClose(false)">
      {{ data.cancelButton | translate }}
    </button>
    <button *ngIf="data.acceptButton && data.acceptButton.length > 0" (click)="dialogClose(false)">
      {{ data.cancelButton | translate }}
    </button>
  </ng-container>
  <button
    #accept
    [attr.fill-type]="'contained'"
    *ngIf="data.acceptButton && data.acceptButton.length > 0"
    class="button"
    data-cy="customDialogYesButton"
    (click)="dialogClose(true)"
    style="margin-left: 1rem">
    {{ data.acceptButton | translate }}
  </button>
</div>
