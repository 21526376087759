import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LoadingModalService } from '../../shared/services/loading-modal.service';
import { DOCUMENT } from '@angular/common';

export enum LoadingModalStrategy {
  Overlay = 'overlay',
  Replace = 'replace',
}

@Component({
  selector: 'pc-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingModalComponent implements OnInit, OnDestroy {
  private readonly element: any;
  private elementToAppend: any;
  private _progressText: string;

  @Input()
  id: any;

  @Input()
  type: string = LoadingModalStrategy.Replace;

  constructor(
    private loadingModalService: LoadingModalService,
    private cdr: ChangeDetectorRef,
    el: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    this.element.style.display = 'none';

    if (this.type === LoadingModalStrategy.Overlay) {
      this.elementToAppend = this.element.parentNode;
    } else {
      this.elementToAppend = this.document.body;
    }

    this.elementToAppend.appendChild(this.element);

    this.loadingModalService.addModal(this);
  }

  ngOnDestroy(): void {
    this.loadingModalService.removeModal(this.id);
    this.element.remove();
  }

  get progressText(): string {
    return this._progressText;
  }

  set progressText(value: string) {
    this._progressText = value;
    this.cdr.markForCheck();
  }

  openModal(): void {
    this.element.style.display = 'block';
    this.document.body.style.overflow = 'hidden';
  }

  closeModal(): void {
    this.element.style.display = 'none';
    this.document.body.style.overflow = 'initial';
  }
}
