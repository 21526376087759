import { HeatingLeadViewModel, VariantCompositeViewModel } from '../../apis/advis';
import { Action } from '@ngrx/store';

export const SAVE_LEAD_DATA: string = '[MT] save CRM lead data';
export const SAVE_VARIANT_DATA: string = '[MT] save CRM variant data';
export const RESET_LEAD_DATA: string = '[MT] reset CRM lead data';
export const RESET_VARIANT_DATA: string = '[MT] reset CRM variant data';

export class SaveLeadDataAction implements Action {
  readonly type: string = SAVE_LEAD_DATA;

  constructor(public payload: HeatingLeadViewModel) {}
}

export class SaveVariantDataAction implements Action {
  readonly type: string = SAVE_VARIANT_DATA;

  constructor(public payload: VariantCompositeViewModel) {}
}

export class ResetLeadDataAction implements Action {
  readonly type: string = RESET_LEAD_DATA;

  constructor(public payload: any = undefined) {}
}

export class ResetVariantDataAction implements Action {
  readonly type: string = SAVE_VARIANT_DATA;

  constructor(public payload: any = undefined) {}
}

export type Actions =
  | SaveLeadDataAction
  | SaveVariantDataAction
  | ResetLeadDataAction
  | ResetVariantDataAction;
