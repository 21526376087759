import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SelectContactTypeEnum } from '../../../../lead/common/base/BaseContactDirective';
import {
  ContactDuplicateAndTypeaheadViewModel,
  DirectoryPersonViewModel,
  DirectoryService,
  LeadContactViewModel,
  MasterDataMandatorViewModel,
} from '../../../../shared/apis/advis';
import { AddressDetailSearchDialogComponent } from '../../../dialog/address-detail-search-dialog/address-detail-search-dialog.component';
import { isNullOrUndefined } from '../../../../shared/utils/isNullOrUndefined';
import {
  IResultPayload,
  AddressBookBackendService,
} from '../../../../shared/services/address-book-backend.service';
import {
  IDialogData,
  SelectPersonDialogComponent,
} from '../../../dialog/select-person-dialog/select-person-dialog.component';
import { Subscription } from 'rxjs';
import {
  EditPersonContactDialogComponent,
  IEditPersonData,
} from '../../../dialog/edit-person-contact-dialog/edit-person-contact-dialog.component';
import { filter, map } from 'rxjs/operators';
import { IEditResult } from '../../../../lead/common/base/BaseContactFormDirective';
import { EnumService } from '../../../../shared/services/enum.service';
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import { TranslateService } from '@ngx-translate/core';
import {
  EditCompanyContactDialogComponent,
  IEditCompanyData,
} from '../../../dialog/edit-company-contact-dialog/edit-company-contact-dialog.component';
import { ContactViewModelUtil } from '../../../../shared/utils/contact-view-model.util';
import { NotificationService, TypeE } from '../../../../shared/services/notification.service';
import { LoadingModalService } from '../../../../shared/services/loading-modal.service';
import { AddExternalManagedContactDialogComponent } from '../../../../address-book/dialog/add-sap-debtor-dialog/add-external-managed-contact-dialog.component';
import { IExternManagedAddressTypeConfig } from '../../../../shared/utils/exter-managed-address-type.util';
import * as RootReducer from '../../../../shared/states';
import { Store } from '@ngrx/store';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-contact-selector[mandator] [selectType] [contactType]',
  templateUrl: './contact-selector.component.html',
  styleUrls: ['./contact-selector.component.scss'],
})
export class ContactSelectorComponent implements OnInit, OnDestroy {
  @Input() public mandator: MasterDataMandatorViewModel;
  @Input() public selectType: SelectContactTypeEnum;
  @Input() public contactType: LeadContactViewModel.AddressTypeEnum;
  @Input() public detailSearchEnabled: boolean = true;
  @Input() public companyId: number;
  @Input() public language: 'DE' | 'FR' | 'IT' | 'EN';
  @Input() public excludePersonIds: number[] = [];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onSelectedContactId: EventEmitter<number> = new EventEmitter<number>();

  public externalManagedAddress: IExternManagedAddressTypeConfig;
  public contactsFromBook: ContactDuplicateAndTypeaheadViewModel[] = [];
  public isContactsFromBookEmpty: boolean = true;
  public inputPlaceholder: string = '';
  public searchString: string = '';
  public createCompanyEnabled: boolean = false;
  public createPersonEnabled: boolean = false;
  public createExternalManagedAddressEnabled: boolean = false;

  private subscriptions: Subscription = new Subscription();
  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  constructor(
    private addressBookService: AddressBookBackendService,
    private dialogService: DialogService,
    private translation: TranslateService,
    private enumService: EnumService,
    private loadingModalService: LoadingModalService,
    private notificationService: NotificationService,
    private directory: DirectoryService,
    private store: Store<RootReducer.IState>,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
  }

  public ngOnInit(): void {
    if (this.selectType === SelectContactTypeEnum.COMPANY) {
      this.inputPlaceholder = this.translation.instant('ADDRESS.CREATE.PLACEHOLDER.COMPANY');
      this.createCompanyEnabled = true;
      this.createPersonEnabled = false;
      this.createExternalManagedAddressEnabled = false;
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON) {
      this.inputPlaceholder = this.translation.instant(
        'ADDRESS.CREATE.PLACEHOLDER.COMPANY_OR_PERSON_NAME'
      );
      this.createCompanyEnabled = true;
      this.createPersonEnabled = true;
      this.createExternalManagedAddressEnabled = false;
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON_ADDRESS) {
      this.inputPlaceholder = this.translation.instant(
        'ADDRESS.CREATE.PLACEHOLDER.COMPANY_PERSON_ADDRESS'
      );
      this.createCompanyEnabled = true;
      this.createPersonEnabled = true;
      this.createExternalManagedAddressEnabled = false;
    } else if (
      this.selectType === SelectContactTypeEnum.PERSON ||
      this.selectType === SelectContactTypeEnum.PERSON_EXCLUDING_COMPANYPERSON
    ) {
      this.inputPlaceholder = this.translation.instant('ADDRESS.CREATE.PLACEHOLDER.PERSON');
      this.createCompanyEnabled = false;
      this.createPersonEnabled = true;
      this.createExternalManagedAddressEnabled = false;
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID) {
      this.externalManagedAddress = this.allExternalManagedAddressConfig.find(
        x => x.addressType === this.contactType
      );
      this.inputPlaceholder = this.translation.instant(
        'ADDRESS.CREATE.PLACEHOLDER.COMPANY_PERSON_EXTERNAL_MANAGED_ID',
        { addressTypeName: this.externalManagedAddress.translation }
      );
      this.createCompanyEnabled = false;
      this.createPersonEnabled = false;
      this.createExternalManagedAddressEnabled = true;
    }
  }

  public getToolTip(contact: ContactDuplicateAndTypeaheadViewModel): string {
    if (contact.IsExternalManaged) {
      const prefix: string = contact.DataState === 0 ? 'Unconfirmed: ' : 'Confirmed:';
      return prefix + contact.Contact;
    }

    return contact.Contact;
  }

  public onTypeaheadChange(event: string): void {
    this.searchString = event;

    if (this.searchString.length <= 2) {
      this.contactsFromBook = [];
      return;
    }

    if (this.selectType === SelectContactTypeEnum.COMPANY) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryCompanyTypeaheadSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryCompanyPersonSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON_ADDRESS) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryCompanyPersonAddressSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    } else if (this.selectType === SelectContactTypeEnum.PERSON) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryPersonSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    } else if (this.selectType === SelectContactTypeEnum.PERSON_EXCLUDING_COMPANYPERSON) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryPersonExcludingCompanyPersonSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.companyId,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    } else if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID) {
      this.subscriptions.add(
        this.addressBookService
          .GetDirectoryCompanyPersonExternalManagedIdSuggestions(
            this.mandator.SharedAddressBookMandatorIds,
            this.externalManagedAddress.addressType,
            this.searchString
          )
          .subscribe((c: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
            this.checkResult(c);
          })
      );
    }
  }

  public detailSearch(): void {
    this.subscriptions.add(
      this.dialogService
        .open(AddressDetailSearchDialogComponent, {
          data: {
            mandatorIds: this.mandator.SharedAddressBookMandatorIds.join(','),
            searchExternalManagedAddress:
              this.selectType === SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID,
            language: this.language,
            externalManagedAddress: this.externalManagedAddress,
          },
        })
        .afterClosed()
        .subscribe((contactId: number) => {
          if (!isNullOrUndefined(contactId)) {
            this.onSelectedContactId.emit(contactId);
          }
        })
    );
  }

  public selectContact(selectedContact: ContactDuplicateAndTypeaheadViewModel): void {
    if (
      this.selectType === SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID &&
      !selectedContact.IsExternalManaged
    ) {
      this.copyToExternalManagedContact(selectedContact);
    } else {
      this.getSelectContact(selectedContact);
    }
  }

  public createCompany(): void {
    const dialogData: IEditCompanyData = {
      mandatorId: this.mandator.Id,
      contactId: 0,
      company: ContactViewModelUtil.createEmptyCompany(),
      addressType: this.contactType,
      dialogTitle: this.translation.instant('ADDRESS.EDIT.ADD_COMPANY'),
      isNew: true,
    };

    this.subscriptions.add(
      this.dialogService
        .open(EditCompanyContactDialogComponent, { data: dialogData })
        .afterClosed()
        .pipe(filter((data: any) => !isNullOrUndefined(data)))
        .subscribe((data: IEditResult) => {
          this.onSelectedContactId.emit(data.contact.Id);
        })
    );
  }

  public createPerson(): void {
    const dialogData: IEditPersonData = {
      mandatorId: this.mandator.Id,
      contactId: 0,
      person: ContactViewModelUtil.createEmptyPerson(),
      titleItems: this.enumService.getEnumDataExclude(TitleEnum, [
        TitleEnum.None,
        TitleEnum.Company,
      ]),
      addressType: this.contactType,
      dialogTitle: this.translation.instant('ADDRESS.EDIT.ADD_PERSON'),
      isNew: true,
    };

    this.subscriptions.add(
      this.dialogService
        .open(EditPersonContactDialogComponent, { data: dialogData })
        .afterClosed()
        .pipe(filter((data: any) => !isNullOrUndefined(data)))
        .subscribe((data: IEditResult) => {
          this.onSelectedContactId.emit(data.contact.Id);
        })
    );
  }

  public createExternalManaged(): void {
    this.subscriptions.add(
      this.dialogService
        .open(AddExternalManagedContactDialogComponent, {
          data: {
            mandatorId: this.mandator.Id,
            language: this.language,
            externalManagedAddressConfig: this.externalManagedAddress,
          },
        })
        .afterClosed()
        .pipe(filter((data: any) => !isNullOrUndefined(data)))
        .subscribe(contact => {
          this.onSelectedContactId.emit(contact.Id);
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkResult(result: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>): void {
    if (result.successful) {
      this.contactsFromBook =
        result.payload.length > 0 && this.searchString.length > 2
          ? result.payload.filter(
              x => this.excludePersonIds.findIndex(e => e === x.ContactId) === -1
            )
          : [];
      this.isContactsFromBookEmpty = result.payload.length <= 0 || this.searchString.length < 2;
    }
    this.cdr.detectChanges();
  }

  private getSelectContact(selectedContact: ContactDuplicateAndTypeaheadViewModel): void {
    this.subscriptions.add(
      this.addressBookService.getContact(selectedContact.ContactId).subscribe(contact => {
        if (contact.successful) {
          if (isNullOrUndefined(contact.payload.Company)) {
            this.onSelectedContactId.emit(contact.payload.Id);
            return;
          }
          this.subscriptions.add(
            this.addressBookService
              .getPersonsContactsOfCompany(contact.payload.Company.Id)
              .pipe(
                filter(x => x.successful),
                map(x => x.payload)
              )
              .subscribe(persons => {
                const dialogData: IDialogData = {
                  mandator: this.mandator,
                  contactType: this.contactType,
                  companyId: contact.payload.Company.Id,
                  companyName: contact.payload.Company.Name,
                  selectablePersons: persons.Entries,
                };
                this.subscriptions.add(
                  this.dialogService
                    .open(SelectPersonDialogComponent, { data: dialogData })
                    .afterClosed()
                    .subscribe((contactId: number) => {
                      if (contactId === 0) {
                        this.onSelectedContactId.emit(selectedContact.ContactId);
                      } else {
                        this.onSelectedContactId.emit(contactId);
                      }
                    })
                );
              })
          );
        }
      })
    );
  }

  private copyToExternalManagedContact(
    selectedContact: ContactDuplicateAndTypeaheadViewModel
  ): void {
    if (this.selectType === SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID) {
      this.subscriptions.add(
        this.dialogService
          .openConfirm({
            message: this.translation.instant(
              'ADDRESS.DIALOGS.SEARCH.ADD_EXTERNAL_MANAGED_ADDRESS.MESSAGE',
              { addressTypeName: this.externalManagedAddress.translation }
            ),
            title: this.translation.instant(
              'ADDRESS.DIALOGS.SEARCH.ADD_EXTERNAL_MANAGED_ADDRESS.TITLE',
              {
                addressTypeName: this.externalManagedAddress.translation,
              }
            ),
          })
          .afterClosed()
          .pipe(filter(ok => ok))
          .subscribe(() => {
            this.subscriptions.add(
              this.directory
                .directoryCreateExternalManagedFromExistsContact(
                  selectedContact.ContactId,
                  this.externalManagedAddress.addressType,
                  this.language
                )
                .subscribe(
                  c => {
                    this.loadingModalService.closeModal();
                    this.notificationService.notifySimple(
                      this.translation.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_SUCCESS', {
                        addressTypeName: this.externalManagedAddress.translation,
                      }),
                      TypeE.PRIMARY
                    );
                    this.onSelectedContactId.emit(c.Id);
                  },
                  () => {
                    this.loadingModalService.closeModal();
                    this.notificationService.notifySimple(
                      this.translation.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_FAILED', {
                        addressTypeName: this.externalManagedAddress.translation,
                      }),
                      TypeE.ALERT
                    );
                  }
                )
            );
          })
      );
    }
  }
}
