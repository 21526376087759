import { HeatingLeadViewModel, VariantCompositeViewModel } from '../../apis/advis';
import { tassign } from 'tassign';
import * as MtCrmActions from './mt-crm.actions';

export interface IState {
  leadData: HeatingLeadViewModel;
  variantData: VariantCompositeViewModel;
}

export const initialState: IState = {
  leadData: undefined,
  variantData: undefined,
};

export function reducer(state: IState = initialState, action: MtCrmActions.Actions): IState {
  switch (action.type) {
    case MtCrmActions.SAVE_LEAD_DATA: {
      return tassign(state, {
        leadData: action.payload,
      });
    }

    case MtCrmActions.SAVE_VARIANT_DATA: {
      return tassign(state, {
        variantData: action.payload,
      });
    }

    case MtCrmActions.RESET_LEAD_DATA: {
      return tassign(state, {
        leadData: undefined,
      });
    }

    case MtCrmActions.RESET_VARIANT_DATA: {
      return tassign(state, {
        variantData: undefined,
      });
    }

    default: {
      return state;
    }
  }
}
