import { Component, Inject, OnInit } from '@angular/core';
import {
  LeadRegionalAddressViewModel,
  LeadRegionalResponsibilityViewModel,
} from '../../../shared/apis/advis';
import { LeadUtil } from '../../../shared/utils/lead.util';
import { MediaObserver } from '@angular/flex-layout';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import { DIALOG_DATA, DialogRef, TabsData } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-regional-information-dialog',
  templateUrl: './regional-information-dialog.component.html',
  styleUrls: ['./regional-information-dialog.component.scss'],
})
export class RegionalInformationDialogComponent implements OnInit {
  protected isMobile: boolean;
  protected leadRegionalAddressViewModel: LeadRegionalAddressViewModel;
  protected selectedTabIndex: number = 0;
  protected tariffUrl: string = '';
  protected tradeTypeRegionalResponsibilities: LeadRegionalResponsibilityViewModel[] = [];
  protected tabsData: TabsData[] = [];
  protected activeView: string = '';

  constructor(
    public dialogRef: DialogRef,
    private media: MediaObserver,
    @Inject(DIALOG_DATA)
    public dialogData: {
      leadRegionalAddressViewModel: LeadRegionalAddressViewModel;
      tradeTypeCode: string;
    }
  ) {}

  public ngOnInit(): void {
    this.leadRegionalAddressViewModel = this.dialogData?.leadRegionalAddressViewModel;
    if (
      !isNullOrUndefined(this.leadRegionalAddressViewModel.TradeTypeRegionalResponsibilities) &&
      this.leadRegionalAddressViewModel.TradeTypeRegionalResponsibilities?.length > 0
    ) {
      this.tradeTypeRegionalResponsibilities =
        this.leadRegionalAddressViewModel.TradeTypeRegionalResponsibilities.sort((x, y) =>
          x.TradeTypeCode.localeCompare(y.TradeTypeCode)
        );
      this.tabsData = this.tradeTypeRegionalResponsibilities.map(
        (responsibility, i) =>
          ({
            id: i.toString(),
            tabName: responsibility.TradeTypeCode,
            tabLabel: responsibility.TradeTypeCode,
          } as TabsData)
      );
      const activeViewIndex =
        this.leadRegionalAddressViewModel.TradeTypeRegionalResponsibilities.findIndex(
          x => x.TradeTypeCode === this.dialogData.tradeTypeCode
        );
      this.activeView = activeViewIndex === -1 ? '0' : activeViewIndex.toString();
    }

    this.isMobile = this.media.isActive('lt-md');

    this.tariffUrl = LeadUtil.getElectricTariffsLink(
      this.leadRegionalAddressViewModel.RegionalInformation
    );
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public fxLayoutAlign(): string {
    this.isMobile = this.media.isActive('lt-md');
    return this.isMobile ? 'start center' : 'end center';
  }
}
