import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@sitewerk/theia-ui-lib';
import { DocumentTemplateViewModel, EmailTemplatesViewModel } from '../../../shared/apis/advis';
import { Subscription } from 'rxjs';
import { PermissionService } from '../../../shared/services/permission.service';
import { DOCUMENT_TYPE_ENUM } from '../../../shared/models/enum/document-type.enum';
import { FileDownloadService } from '../../../shared/services/file-download.service';

export interface IVariantDocumentData {
  language: string;
  preselection: boolean;
  id: number;
  isLead: boolean;
  templates: EmailTemplatesViewModel;
  selectedTemplateId?: number;
  warningText?: string;
}

export enum PDF_OFFER_TITLE {
  DE = 'PDF-Offerte',
  EN = 'PDF offer',
  FR = 'PDF offre',
  IT = 'Offerta PDF',
}

@Component({
  selector: 'pc-offer-download-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentDialogComponent implements OnInit, OnDestroy {
  public loaded: boolean = false;

  public documentTemplates?: DocumentTemplateViewModel[];

  public selectedDocumentType: number = 0;
  public selectedTemplateId: number;
  public attachments: DocumentTemplateViewModel[];

  public selectedAttachments: number[] = [];
  public id: number;
  public language: string;
  public preselection: boolean = false;
  public pdfOfferTitle: string = PDF_OFFER_TITLE.DE.toString();

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: IVariantDocumentData,
    private permissions: PermissionService,
    private fileService: FileDownloadService
  ) {
    this.id = data.id;
    this.language = data.language;
    this.preselection = data.preselection;
  }

  public download(): void {
    const attachments: string = this.selectedAttachments.join(',');

    let url = '/api/pc/';
    url += this.data.isLead ? 'leads/' : 'variants/';
    url += encodeURIComponent(String(this.id));
    url += '/documents/';
    url += encodeURIComponent(String(+this.selectedTemplateId));
    url += this.selectedDocumentType === DOCUMENT_TYPE_ENUM.PDF ? '/pdf' : '/native';
    url = attachments ? url + `?attachmentIds=${attachments}` : url;

    this.fileService.downloadFile(url);
  }

  public isValid(): boolean {
    return this.selectedTemplateId !== undefined && this.documentTemplates !== undefined;
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public ngOnInit(): void {
    this.pdfOfferTitle = PDF_OFFER_TITLE[this.language];
    this.documentTemplates = [...this.data.templates.DocumentTemplates].sort(
      (a: DocumentTemplateViewModel, b: DocumentTemplateViewModel) => {
        return (a.Name || '').toLowerCase().localeCompare((b.Name || '').toLowerCase());
      }
    );
    this.selectedTemplateId = this.getTemplateTypeSelection();
    if (this.data.templates && this.data.templates.Attachments) {
      this.attachments = this.data.templates.Attachments;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getTemplateTypeSelection(): number {
    if (this.data.selectedTemplateId) {
      return this.data.selectedTemplateId;
    }
    if (this.permissions.userPermissions.CanAssignMeBo) {
      return this.findDocumentTemplate(DocumentTemplateViewModel.DocumentTemplateTypeEnum.DefaultBo)
        ? this.findDocumentTemplate(DocumentTemplateViewModel.DocumentTemplateTypeEnum.DefaultBo).Id
        : undefined;
    } else if (this.permissions.userPermissions.CanAssignMeNbo) {
      return this.findDocumentTemplate(
        DocumentTemplateViewModel.DocumentTemplateTypeEnum.DefaultNbo
      )
        ? this.findDocumentTemplate(DocumentTemplateViewModel.DocumentTemplateTypeEnum.DefaultNbo)
            .Id
        : undefined;
    }
    return 0;
  }

  private findDocumentTemplate(
    type: DocumentTemplateViewModel.DocumentTemplateTypeEnum
  ): DocumentTemplateViewModel {
    return this.documentTemplates.find((x: DocumentTemplateViewModel) => {
      return x.DocumentTemplateType === type;
    });
  }
}
