import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BuildingPlayerApiAccessService, PutPvBuildingData } from '../../../../shared/apis/advis';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'lodash';
import { DIALOG_DATA, DialogRef, DialogService } from '@sitewerk/theia-ui-lib';
import { map } from 'rxjs/operators';
export const A2C_PLUGIN_API_URL: string = '//v2.buildingsapi.ch/player';
export const LOCATION_SEARCH_API_URL: string = '//api3.geo.admin.ch/rest/services/api/SearchServer';

export interface ILatLonAddress {
  lat: number;
  lon: number;
}

interface IBuildingRoofSurfacePluginData {
  area: number;
  faceId?: string;
  orientation: string;
  pvEarningsChf: number;
  pvEarningsKwh: number;
  pvEarningsKwhM2: number;
  solarheatEarningsKwh: number;
  solarheatEarningsKwhM2: number;
  tilt: string;
}

export class SurfacesSummary {
  area: number = 0;
  pvEarningsChf: number = 0;
  pvEarningsKwh: number = 0;
  pvEarningsKwhM2: number = 0;
  solarheatEarningsKwh: number = 0;
  solarheatEarningsKwhM2: number = 0;
  tilt: string = '0';
  orientation: string = '0';

  constructor(data?: IBuildingRoofSurfacePluginData) {
    if (!isNullOrUndefined(data)) {
      this.area = data.area;
      this.orientation = data.orientation;
      this.pvEarningsChf = data.pvEarningsChf;
      this.pvEarningsKwh = data.pvEarningsKwh;
      this.pvEarningsKwhM2 = data.pvEarningsKwhM2;
      this.solarheatEarningsKwh = data.solarheatEarningsKwh;
      this.solarheatEarningsKwhM2 = data.solarheatEarningsKwhM2;
      this.tilt = data.tilt;
    }
  }

  combine = (cur: IBuildingRoofSurfacePluginData, prop: string) =>
    (this[prop] = Math.round(parseInt(this[prop], 10) + parseInt(cur[prop], 10)));

  biggerThan = (cur: IBuildingRoofSurfacePluginData, prop: string) =>
    (this[prop] = Math.round(
      this[prop] < cur[prop] ? parseInt(cur[prop], 10) : parseInt(this[prop], 10)
    ));
}

export interface IRoof3dPluginDialogComponentData {
  address: ILatLonAddress;
  onLoadError?: (dialogRef: DialogRef) => void;
}

@Component({
  selector: 'pc-roof3d-plugin-dialog',
  templateUrl: './roof3d-plugin-dialog.component.html',
  styleUrls: ['./roof3d-plugin-dialog.component.scss'],
})
export class Roof3dPluginDialogComponent implements OnInit, OnDestroy {
  private readonly pluginHeight: number = 400; // px
  private readonly defaultAddress: ILatLonAddress = { lat: 47.71845, lon: 8.65122 };
  private subscriptions: Subscription = new Subscription();

  private firstLoad: boolean = true;
  private surfacesSelected: BehaviorSubject<IBuildingRoofSurfacePluginData[]> = new BehaviorSubject(
    []
  );
  private plugInObjectData: any;
  private myBuilding: any;
  private address: { lat: number; lon: number };

  selectedSurfaces: any;
  areaSummary: number = 0;
  pvEarningsKwhM2Summary: number = 0;
  bestRoofSurfaceOrientation: string = '0';
  bestRoofSurfaceRoofSlope: string = '0';

  delay: number = 500;

  public pluginLoaded: boolean = false;

  constructor(
    private buildingPlayerApiAccessService: BuildingPlayerApiAccessService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogInputData: IRoof3dPluginDialogComponentData,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {
    // empty
  }

  ngOnInit(): void {
    if (this.dialogInputData.address) {
      this.address = this.dialogInputData.address
        ? this.dialogInputData.address
        : this.defaultAddress;
      this.subscriptions.add(
        this.buildingPlayerApiAccessService
          .buildingPlayerApiAccessGetBuildingPlayerAccessToken()
          .pipe(map((accessToken: string) => encodeURIComponent(accessToken)))
          .subscribe((accessToken: string) => {
            if (this.firstLoad) {
              setTimeout(() => {
                this.loadPlugin(
                  this.dialogInputData.address.lat,
                  this.dialogInputData.address.lon,
                  accessToken
                );
              }, this.delay);
              this.firstLoad = false;
            } else {
              this.loadPlugin(
                this.dialogInputData.address.lat,
                this.dialogInputData.address.lon,
                accessToken
              );
            }
          })
      );
    }
  }

  getCanvasImage(): string {
    return this.myBuilding.getImage();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant(
            'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.SITUATION.DIALOG.SAVE_INFO'
          ),
        })
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            const patchData: PutPvBuildingData = {
              SituationImage3D: this.getCanvasImage(),
              BuildingRoofShape: this.plugInObjectData.buildingRoofShape,
              BuildingVolume: this.plugInObjectData.buildingWallArea,
              BuildingGroundArea: this.plugInObjectData.buildingGroundArea,
              BuildingRoofArea: this.plugInObjectData.buildingRoofArea,
              BuildingEavesHeight: this.plugInObjectData.buildingEavesHeight,
              BuildingRidgeHeight: this.plugInObjectData.buildingRidgeHeight,
              BuildingRoofOverhangArea: this.plugInObjectData.buildingRoofOverhangArea,
              BuildingWallArea: this.plugInObjectData.buildingWallArea,
              BuildingAboveSea: this.plugInObjectData.aboveSea,
              LatLonCoordinates: this.address.lat + ';' + this.address.lon,
              KwhYieldPerKwp: Math.round(
                (this.calcPvEarningsKwhM2(this.plugInObjectData.buildingRoofSurface) / 180) * 1000
              ),
              RoofOrientation: this.bestRoofSurfaceOrientation?.toString(),
              RoofSlope: this.bestRoofSurfaceRoofSlope?.toString(),
              SelectedRoofAres: this.areaSummary,
            };
            this.dialogRef.close(patchData);
          }
        })
    );
  }

  private calcPvEarningsKwhM2(buildingRoofSurfaceArray: any[]): number {
    if (buildingRoofSurfaceArray) {
      let result: number = 0;
      const totalArea: number = buildingRoofSurfaceArray.reduce((prev: any, cur: any) => {
        return prev + cur.area;
      }, 0);
      // tslint:disable-next-line: no-increment-decrement
      for (let i: number = 0; i < buildingRoofSurfaceArray.length; i++) {
        result +=
          (buildingRoofSurfaceArray[i].area / totalArea) *
          buildingRoofSurfaceArray[i].pvEarningsKwhM2;
      }

      return result;
    }
    return 0;
  }

  private loadPlugin(lat: number, lon: number, accessToken: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      if (!this.pluginLoaded) {
        this.getScript(`${A2C_PLUGIN_API_URL}/?token=${accessToken}`)
          .then(() => {
            this.myBuilding = new (window as any).buildingPlayer({
              container: 'plugin-viewer',
              loadCallback: (data: any) => {
                console.log('*******************loadCallback');
                if (data.status === 'ok') {
                  this.surfacesSelected.next(data.buildingRoofSurface);
                  this.plugInObjectData = data;
                } else {
                  resolve(false);
                  this.dialogInputData.onLoadError &&
                    this.dialogInputData.onLoadError(this.dialogRef);
                }
              },
              roofClickCallback: (data: any) => {
                console.log('*******************roofClickCallback');
                if (data.status === 'ok') {
                  this.plugInObjectData = data;
                  this.surfacesSelected.next(data.buildingRoofSurface);
                } else {
                  resolve(false);
                }
              },
              minWidth: 500,
              minHeigt: this.pluginHeight,
              zoomControls: true,
              rotateControls: true,
              rotateAngle: 0.5,
              compass: true,
              roofColor: '#333333',
              wallColor: '#c0c0c0',
              roofColoring: true,
              showBestRoof: true,
              roofSelectable: 2,
              opacity: 1,
              groundMap: true,
              mapType: 'swisstopo-image',
              terrain: true,
              rotateHorizontal: true,
              rotateVertical: true,
              roofSelectedBorderColor: '#ff00b7',
              roofSelectedBorderWidth: 22,
            });
            this.pluginLoaded = true;

            console.log('Plugin loaded');

            this.myBuilding.getBuilding(lat, lon);
            this.watchSurfaces();
            resolve(true);
          })
          .catch((e: any) => {
            console.error(`Couldn't load 3D plugin: ` + e);
            reject(true);
          });
      } else {
        this.myBuilding.getBuilding(lat, lon);
        this.watchSurfaces();
        resolve(true);
      }
    });
  }

  private getScript = (src: string) => {
    return new Promise((resolve: any, reject: any) => {
      const s: any = document.createElement('script');
      s.src = src;
      s.async = true;
      s.defer = true;
      s.onload = () => {
        resolve(true);
      };
      s.addEventListener('error', reject);
      s.addEventListener('load', resolve);
      s.onerror = () => reject(true);
      document.querySelector('head').appendChild(s);
    });
  };

  private watchSurfaces(): void {
    this.subscriptions.add(
      this.surfacesSelected.subscribe((data: IBuildingRoofSurfacePluginData[]) => {
        this.selectedSurfaces = data;

        const surfacesSummary: SurfacesSummary = new SurfacesSummary();
        data.forEach((d: IBuildingRoofSurfacePluginData) => {
          surfacesSummary.combine(d, 'area');
          surfacesSummary.combine(d, 'pvEarningsChf');
          surfacesSummary.combine(d, 'pvEarningsKwh');
          surfacesSummary.combine(d, 'pvEarningsKwhM2');
          surfacesSummary.combine(d, 'solarheatEarningsKwh');
          surfacesSummary.biggerThan(d, 'tilt');
          surfacesSummary.orientation = isNumber(d.orientation)
            ? Math.round(d.orientation).toString()
            : d.orientation;
        });
        this.areaSummary = surfacesSummary.area;
        this.pvEarningsKwhM2Summary = this.calcPvEarningsKwhM2(this.selectedSurfaces);
        this.bestRoofSurfaceOrientation = surfacesSummary.orientation;
        this.bestRoofSurfaceRoofSlope = surfacesSummary.tilt;
      })
    );
  }
}
