<pc-lead-action-bar [title]="newPersonTitle" [hideNotes]="true">
  <button class="new-person-button" (click)="onBack()">
    {{ 'COMMON.BTN_CANCEL' | translate }}
  </button>
  <button
    class="new-person-button"
    [attr.fill-type]="'contained'"
    (click)="onSave()"
    data-cy="newLeadButtonSave"
    [disabled]="saveButtonDisabled()">
    {{ 'COMMON.BTN_SAVE' | translate }}
  </button>
</pc-lead-action-bar>

<div class="pc-lead-content lead-person">
  <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" class="margin">
    <div fxFlex.gt-xs="70">
      <helion-ui-card>
        <div title fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            {{ (isNew ? 'LEAD_PERSON.NEW_LEAD' : 'LEAD_PERSON.CONTACT_ADDRESS') | translate }}
          </div>

          <helion-ui-icon
            *ngIf="selectedTab === activeTabs.singleAddress"
            [cdkMenuTriggerFor]="menu"
            data-feather="more-vertical">
          </helion-ui-icon>

          <ng-template #menu>
            <helion-ui-menu>
              <button class="menu-item" cdkMenuItem (click)="addAdditionalEmail()">
                <helion-ui-icon data-feather="mail"></helion-ui-icon>
                <span>{{ 'ADDRESS.ADD_EMAIL' | translate }}</span>
              </button>
              <button class="menu-item" cdkMenuItem (click)="addAdditionalPhone()">
                <helion-ui-icon data-feather="phone"></helion-ui-icon>
                <span>{{ 'ADDRESS.ADD_PHONE' | translate }}</span>
              </button>
              <pc-menu-save-to-external-managed-contact
                *ngIf="!isNew"
                [copyToExternalManaged]="true"
                (onCopyAsExternalManaged)="checkDuplicateAndCopyAsExternalManaged($event)">
              </pc-menu-save-to-external-managed-contact>
            </helion-ui-menu>
          </ng-template>
        </div>

        <div content>
          <helion-ui-tabs>
            <button *ngFor="let tab of tabsData; let i = index" (click)="switchActiveTab(tab.id)">
              <div [ngClass]="{ 'active-tab': tab.id === selectedTab }">
                {{ tab.tabLabel | translate }}
              </div>
            </button>
          </helion-ui-tabs>

          <div
            class="contact-form-content-wrapper"
            *ngIf="selectedTab === activeTabs.singleAddress">
            <div class="position-relative">
              <form #addressForm="ngForm" fxLayout="row wrap">
                <div
                  fxFlex.lt-md="100"
                  fxFlex.gt-sm="0 0 calc(50% - 16px)"
                  class="lead-person__form-block">
                  <helion-ui-select
                    placeholder="{{ 'ADDRESS.TITLE' | translate }}"
                    name="personAnrede"
                    [ngModel]="selectedTitleItem"
                    (ngModelChange)="singleContact.Title = $event">
                    <helion-ui-option
                      *ngFor="let item of titleItems"
                      [value]="item.key"
                      [label]="item.display">
                    </helion-ui-option>
                  </helion-ui-select>

                  <pc-company-name-typeahead
                    [isRequired]="false"
                    class="lead-person__form-field"
                    [cyAttr]="'newLeadInputCompany'"
                    label="{{ 'ADDRESS.COMPANY' | translate }}"
                    [value]="singleContact.CompanyName"
                    (updateName)="onCompanyNameUpdate($event)">
                  </pc-company-name-typeahead>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.FIRST_NAME' | translate }}"
                    type="text"
                    [maxlength]="30"
                    name="firstName"
                    [(ngModel)]="singleContact.FirstName"
                    data-cy="newLeadInputFirstname">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.LAST_NAME' | translate }}"
                    type="text"
                    [maxlength]="30"
                    [required]="true"
                    name="lastName"
                    [(ngModel)]="singleContact.LastName"
                    data-cy="newLeadInputLastname">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.STREET' | translate }}"
                    type="text"
                    [maxlength]="50"
                    name="street"
                    [(ngModel)]="singleContact.Street"
                    [required]="true"
                    data-cy="newLeadInputStreet"></helion-ui-input>

                  <helion-ui-input
                    placeholder="{{ 'ADDRESS.CARE_OF_NAME' | translate }}"
                    type="text"
                    name="careOfName"
                    maxlength="40"
                    [(ngModel)]="singleContact.CareOfName">
                  </helion-ui-input>
                </div>

                <div
                  fxFlex.lt-md="100"
                  fxFlex.gt-sm="0 0 calc(50% - 16px)"
                  class="lead-person__form-block">
                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.ZIP' | translate }}"
                    type="text"
                    [maxlength]="30"
                    name="zip"
                    [(ngModel)]="singleContact.ZipCode"
                    [required]="true"
                    data-cy="newLeadInputZIP">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.CITY' | translate }}"
                    type="text"
                    [maxlength]="30"
                    name="city"
                    [(ngModel)]="singleContact.City"
                    [required]="true"
                    data-cy="newLeadInputCity">
                  </helion-ui-input>

                  <helion-ui-input
                    placeholder="{{ 'ADDRESS.POST_OFFICE_BOX' | translate }}"
                    type="text"
                    name="postOfficeBox"
                    maxlength="30"
                    [(ngModel)]="singleContact.PostOfficeBox">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.ADDITIONAL_COMPANY' | translate }}"
                    type="text"
                    [maxlength]="100"
                    name="additionalCompanyName"
                    [(ngModel)]="singleContact.AdditionalCompanyName">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    #email="ngModel"
                    placeholder="{{ 'ADDRESS.EMAIL' | translate }}"
                    email="true"
                    type="email"
                    [ngClass]="!email?.valid ? 'errorColor' : ''"
                    [maxlength]="100"
                    name="email"
                    [(ngModel)]="singleContact.Email"
                    data-cy="newLeadInputEmail"
                    (error)="(true)">
                  </helion-ui-input>
                  <helion-ui-error
                    [hidden]="(email?.valid && email?.touched) || !email?.touched"
                    [message]="'COMMON.VALIDATION.INVALID_EMAIL_ADDRESS' | translate">
                  </helion-ui-error>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.PHONE_NUMBER' | translate }}"
                    type="text"
                    [maxlength]="50"
                    name="phone"
                    [(ngModel)]="singleContact.PhoneNumber"
                    data-cy="newLeadInputPhone">
                  </helion-ui-input>

                  <helion-ui-input
                    class="lead-person__form-field"
                    placeholder="{{ 'ADDRESS.THIRD_PARTY_CUSTOMER_NUMBER' | translate }}"
                    type="text"
                    [maxlength]="50"
                    name="thirdPartyCustomerNumber"
                    [(ngModel)]="singleContact.ThirdPartyCustomerNumber"
                    data-cy="thirdPartyCustomerNumber">
                  </helion-ui-input>
                </div>

                <div
                  *ngFor="let additionalEmail of singleContact.AdditionalEmails; let index = index"
                  fxFlex.lt-md="100"
                  fxFlex.gt-sm="0 0 calc(50% - 16px)"
                  class="lead-person__form-block">
                  <div
                    fxLayout="row"
                    fxLayoutGap="10px"
                    class="lead-person-additional lead-person__form-field">
                    <div fxFlex="50%">
                      <helion-ui-input
                        #email="ngModel"
                        placeholder="{{ 'ADDRESS.ADDITIONAL_EMAIL' | translate }}"
                        email="true"
                        type="email"
                        [maxlength]="100"
                        name="additionalEmail{{ index }}"
                        [(ngModel)]="additionalEmail.Email"
                        [required]="true">
                      </helion-ui-input>

                      <helion-ui-error
                        [hidden]="(email?.valid && email?.touched) || !email?.touched"
                        [message]="'COMMON.VALIDATION.INVALID_EMAIL_ADDRESS' | translate">
                      </helion-ui-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex="50%">
                      <helion-ui-select
                        [(ngModel)]="additionalEmail.EmailType"
                        name="additionalEmailType{{ index }}">
                        <helion-ui-option
                          *ngFor="let emailType of emailTypes"
                          [value]="emailType.Key"
                          [label]="emailType.Translation">
                        </helion-ui-option>
                      </helion-ui-select>
                      <helion-ui-icon
                        data-feather="minus-circle"
                        class="remove-icon cursor-pointer"
                        (click)="removeEmail(index)">
                      </helion-ui-icon>
                    </div>
                  </div>
                </div>

                <div
                  *ngFor="let additionalPhone of singleContact.AdditionalPhones; let index = index"
                  fxFlex.lt-md="100"
                  fxFlex.gt-sm="0 0 calc(50% - 16px)"
                  class="lead-person__form-block">
                  <div
                    fxLayout="row"
                    fxLayoutGap="10px"
                    class="lead-person-additional lead-person__form-field">
                    <div fxFlex="50%">
                      <helion-ui-input
                        placeholder="{{ 'ADDRESS.ADDITIONAL_PHONE' | translate }}"
                        [maxlength]="100"
                        name="additionalPhone{{ index }}"
                        [(ngModel)]="additionalPhone.PhoneNumber"
                        [required]="true"></helion-ui-input>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex="50%">
                      <div fxFlex="100%">
                        <helion-ui-select
                          [(ngModel)]="additionalPhone.PhoneType"
                          name="additionalPhoneType{{ index }}">
                          <helion-ui-option
                            *ngFor="let phoneType of phoneTypes"
                            [value]="phoneType.Key"
                            [label]="phoneType.Translation">
                          </helion-ui-option>
                        </helion-ui-select>
                      </div>
                      <helion-ui-icon
                        data-feather="minus-circle"
                        class="remove-icon cursor-pointer"
                        (click)="removePhone(index)">
                      </helion-ui-icon>
                    </div>
                  </div>
                </div>
              </form>

              <pc-loading-modal
                [id]="modalLoaderIdTypesEnum.PERSON"
                type="overlay"></pc-loading-modal>
            </div>
          </div>

          <div class="contact-form-content-wrapper" *ngIf="selectedTab === activeTabs.addressBook">
            <pc-address-book-contact
              [originContact]="originAddressBookContact"
              [mandator]="mandator"
              [titleItems]="titleItemsPerson"
              [showSaveButton]="false"
              (onContactSelected)="contactSelected($event)"
              (onCopyAsExternalManaged)="checkDuplicateAndCopyAsExternalManaged($event.addressType)"
              (onSaveButtonDisabled)="
                addressBookSaveButtonDisable($event)
              "></pc-address-book-contact>
          </div>

          <form #language="ngForm" class="card-content-wrapper">
            <div fxLayoutAlign="space-between start" fxLayoutGap.lt-md="16px">
              <div
                class="lead-person__form-block"
                fxFlex.lt-md="100"
                fxFlex.gt-sm="0 0 calc(50% - 16px)"
                fxLayoutGap="10px">
                <div class="lead-person__form-field">
                  <helion-ui-select
                    placeholder="{{ 'COMMON.LANGUAGE' | translate }}"
                    name="personSprache"
                    [ngModel]="selectedLangItem"
                    (ngModelChange)="singleContact.Language = $event">
                    <helion-ui-option
                      *ngFor="let item of langItems"
                      [value]="item.key"
                      [label]="item.display">
                    </helion-ui-option>
                  </helion-ui-select>
                </div>
                <div class="lead-person__form-field" style="padding-top: 1.25rem">
                  <pc-select-communication-type
                    name="preferredCommunicationType"
                    [(ngModel)]="singleContact.PreferredCommunicationType">
                  </pc-select-communication-type>
                </div>
              </div>
            </div>
          </form>

          <form #addressesCheckboxes="ngForm" class="card-content-wrapper" *ngIf="!isNew">
            <div
              fxFlex="100"
              fxLayoutAlign.gt-sm="start center"
              fxLayout.lt-md="column"
              fxLayout.gt-sm="row wrap"
              fxLayoutGap="16px"
              class="override-addresses-block">
              <helion-ui-checkbox
                data-cy="leadStageCheckboxDebitorAddress"
                fxFlex.lt-md="100%"
                name="updateDebitorAddr"
                [(ngModel)]="updateDebitorAddress">
                {{ 'LEAD_PERSON.OVERWRITE_DEBITOR_ADDRESS' | translate }}
              </helion-ui-checkbox>

              <helion-ui-checkbox
                data-cy="leadStageCheckboxObjektAddress"
                fxFlex.lt-md="100%"
                name="updateObjectAddr"
                [(ngModel)]="updateObjectAddress">
                {{ 'LEAD_PERSON.OVERWRITE_OBJECT_ADDRESS' | translate }}
              </helion-ui-checkbox>
            </div>
          </form>
        </div>
      </helion-ui-card>
    </div>
  </div>
</div>
