export enum LeadOriginE {
  TIKO = <any>'TIKO',
  SONNEN = <any>'Sonnen',
  MESSE = <any>'Messe / Event',
  SOLARABO_WWF = <any>'Solarabo WWF',
  SOLARMACHER_OPFIKON = <any>'Solarmacher Opfikon',
  CALL = <any>'Call',
  CONTACT_FORM = <any>'Contact Form',
  SOLARENERGIE_VERGLEICH = <any>'Solarenergie-Vergleich',
  HAUSHELD = <any>'Hausheld',
  MAILING = <any>'Mailing / Kampagne',
  PERS_CONTACT = <any>'Pers. Kontakt',
  SPONSORING = <any>'Sponsoring / Verband',
  PUBLICATIONS = <any>'Publikation',
  PARTNER = <any>'Partner / Agenten',
  WORKSHOP = <any>'Workshop',
  TELENOT = <any>'Telenot',
  VANDERBILT = <any>'Vanderbilt',
  BNI = <any>'BNI – Business Network Intl.',
  DEVIS = <any>'devis.ch',
  AUSSCHREIBUNG = <any>'Ausschreibung',
  AIL = <any>'AIL',
  VITOGAZ = <any>'Vitogaz',
  PRONTO = <any>'Pronto',
}
