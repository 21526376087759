<section class="dialog">
  <h2 class="dialog-title">
    {{ 'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.SITUATION.DIALOG.TITLE' | translate }}
  </h2>

  <div class="dialog-content">
    <div class="roof-3d-card">
      <div class="roof-3d-card-body">
        <div id="plugin-viewer"></div>
        <div>
          <div>
            {{
              'LEAD_VARIANT.PHOTOVOLTAICS.WIZARD.SITUATION.DIALOG.SELECTED_ROOF_AREA' | translate
            }}:
            {{ areaSummary }}
          </div>
          <div>
            {{ 'ROOF_3D_PLUGIN.EXPECTED_YIELD' | translate }}
            {{ (pvEarningsKwhM2Summary / 180) * 1000 | number : '1.0-0' }}
          </div>

          <div *ngFor="let suface of selectedSurfaces; let i = index" style="margin-top: 16px">
            <div style="font-weight: bold">{{ 'ROOF_3D_PLUGIN.ROOF' | translate }} {{ i + 1 }}</div>
            <div>{{ 'ROOF_3D_PLUGIN.ROOF_AREA' | translate }} {{ suface.area }}</div>
            <div>{{ 'ROOF_3D_PLUGIN.SLOPE_OF_THE_ROOF' | translate }} {{ suface.tilt }}</div>
            <div>
              {{ 'ROOF_3D_PLUGIN.EXPECTED_YIELD' | translate }}
              {{ (suface.pvEarningsKwhM2 / 180) * 1000 | number : '1.0-0' }}
            </div>
            <div>
              {{ 'ROOF_3D_PLUGIN.ORIENTATION_OF_THE_ROOF' | translate }} {{ suface.orientation }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <button (click)="onCancel()">{{ 'COMMON.BTN_CANCEL' | translate }}</button>
    <button [attr.fill-type]="'contained'" (click)="onSave()">
      {{ 'COMMON.BTN_SAVE' | translate }}
    </button>
  </div>
</section>
