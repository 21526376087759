<section class="dialog">
  <h2 class="dialog-title">{{ 'ADDRESS.DIALOGS.DETAIL_SEARCH.TITLE' | translate }}</h2>
  <div class="dialog-content">
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.FIRST_NAME' | translate }}"
          type="text"
          maxlength="30"
          name="firstName"
          [(ngModel)]="filter.FirstName">
        </helion-ui-input>
        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.LAST_NAME' | translate }}"
          type="text"
          maxlength="30"
          name="lastName"
          [(ngModel)]="filter.LastName">
        </helion-ui-input>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.COMPANY' | translate }}"
          type="text"
          maxlength="30"
          name="CompanyName"
          [(ngModel)]="filter.CompanyName">
        </helion-ui-input>

        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.COMPANY_ADDITIONAL_NAME' | translate }}"
          type="text"
          maxlength="100"
          name="AdditionalCompanyName"
          [(ngModel)]="filter.AdditionalCompanyName">
        </helion-ui-input>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.STREET' | translate }}"
          type="text"
          maxlength="30"
          name="Street"
          [(ngModel)]="filter.Street">
        </helion-ui-input>

        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.ZIP' | translate }}"
          type="text"
          maxlength="30"
          name="ZipCode"
          [(ngModel)]="filter.ZipCode">
        </helion-ui-input>
      </div>

      <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.CITY' | translate }}"
          type="text"
          maxlength="30"
          name="City"
          [(ngModel)]="filter.City">
        </helion-ui-input>

        <helion-ui-input
          fxFlex
          fxFlex.gt-sm="100"
          fxFlex.lt-md="50"
          placeholder="{{ 'ADDRESS.THIRD_PARTY_CUSTOMER_NUMBER' | translate }}"
          type="text"
          maxlength="30"
          name="ThirdPartyCustomerNumber"
          [(ngModel)]="filter.ThirdPartyCustomerNumber">
        </helion-ui-input>
      </div>
    </div>

    <helion-ui-divider class="address-card__divider" [margin]="'16px'"></helion-ui-divider>

    <div class="container ngx-datatable-align-content-vertical-center" #tableWrapper>
      <div fxLayout="column">
        <div class="app-content">
          <ngx-datatable
            #leadTable
            class="material"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [headerHeight]="40"
            [footerHeight]="40"
            [rowHeight]="'auto'"
            [limit]="rowsPerPage"
            [messages]="tableMessage">
            <ngx-datatable-column
              prop="DisplayContactName"
              [cellClass]="'grow-mobile'"
              [headerClass]="'hide-mobile'"
              [maxWidth]="250">
              <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="cursor-pointer">{{
                  'LEADS.TABLE_HEADER.NAME' | translate
                }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span flex class="cursor-pointer with-font-bold" (click)="onActionModify(row)">
                  <helion-ui-icon
                    class="customIconSize"
                    *ngIf="row.externalManagedAddress"
                    [ngClass]="{ confirmedColor: row.DataState === 1 }"
                    data-feather="dollar-sign"></helion-ui-icon>
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="DisplayContactDetail"
              [name]="'LEADS.TABLE_HEADER.DETAIL' | translate"
              [headerClass]="'hide-mobile'"
              [cellClass]="'grow-mobile'"
              [maxWidth]="400"></ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <pc-loading-modal
        [id]="modalLoaderIdTypesEnum.LEAD_ADDRESS_DETAIL_SEARCH"
        type="overlay"></pc-loading-modal>
    </div>
  </div>
  <div class="dialog-actions">
    <button (click)="cancel()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>

    <button [attr.fill-type]="'contained'" (click)="search()" [disabled]="!hasFilterValue()">
      {{ 'COMMON.SEARCH' | translate }}
    </button>
  </div>
</section>
