/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PastePositionsAndGroupsRequest { 
    TargetGroupId?: string;
    OrderFrom?: number;
    SourceVariantId: number;
    PositionsType?: PastePositionsAndGroupsRequest.PositionsTypeEnum;
    GroupIds: Array<string>;
    RegularPositionIds: Array<string>;
    SpecialPositionIds: Array<string>;
}
export namespace PastePositionsAndGroupsRequest {
    export type PositionsTypeEnum = 'Basic' | 'Option' | 'Mixed';
    export const PositionsTypeEnum = {
        Basic: 'Basic' as PositionsTypeEnum,
        Option: 'Option' as PositionsTypeEnum,
        Mixed: 'Mixed' as PositionsTypeEnum
    };
}
