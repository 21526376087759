<div *ngIf="isPersonContactSelect">
  <div>
    <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)">
      <h4>{{ 'ADDRESS.CONTACT' | translate }}</h4>
      <pc-contact-info
        class="contact-form__contact-info"
        *ngIf="person"
        [displayContactInfo]="setPersonInfo"></pc-contact-info>
    </div>
  </div>
</div>
