import * as PhotovoltaikAction from './photovoltaik.action';
import {
  GET_MASTER_DATA,
  GET_MASTER_DATA_FAILED,
  GET_MASTER_DATA_SUCCESS,
} from './photovoltaik.action';
import { tassign } from 'tassign';
import { PvMasterDataViewModel } from '../../apis/advis';

export interface IState {
  getMasterDataRunning: boolean;
  masterData: PvMasterDataViewModel;
}

export const initialState: IState = {
  getMasterDataRunning: undefined,
  masterData: undefined,
};

export function reducer(state: IState = initialState, action: PhotovoltaikAction.Actions): IState {
  switch (action.type) {
    case GET_MASTER_DATA: {
      return tassign(state, {
        getMasterDataRunning: true,
      });
    }

    case GET_MASTER_DATA_SUCCESS: {
      return tassign(state, {
        getMasterDataRunning: false,
        masterData: action.payload,
      });
    }

    case GET_MASTER_DATA_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        getMasterDataRunning: false,
        masterData: action.payload,
      });
    }

    default: {
      return state;
    }
  }
}

export const getPvMasterDataState: (state: IState) => PvMasterDataViewModel = (state: IState) =>
  state.masterData;
export const getPvMasterDataRunningState: (state: IState) => boolean = (state: IState) =>
  state.getMasterDataRunning;
