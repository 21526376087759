<section class="dialog">
  <h2 class="dialog-title">{{ 'LEAD_DETAIL.ADDRESSES' | translate }}</h2>
  <div *ngIf="isShow" class="dialog-content address-dialog-content">
    <div>
      <pc-address-card-content
        [isReadOnly]="true"
        [lead]="lead$ | async"
        [masterData]="masterData$ | async"></pc-address-card-content>
    </div>
    <div class="dialog-actions">
      <button (click)="onCloseClick()">{{ 'COMMON.BTN_CLOSE' | translate }}</button>
    </div>
  </div>
</section>
