import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { DirectoryService } from '../../shared/apis/advis';

@Component({
  selector: 'pc-company-name-typeahead',
  templateUrl: './company-name-typeahead.component.html',
  styleUrls: ['./company-name-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyNameTypeaheadComponent),
      multi: true,
    },
  ],
})
export class CompanyNameTypeaheadComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public label: string = '';
  @Input() public isMandatory: boolean = false;
  @Input() public cyAttr: string = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') public _value: string = '';
  @Input() public disabled: boolean = false;
  @Input() public isRequired: boolean = true;

  @Output() public updateName: EventEmitter<any> = new EventEmitter<any>();

  public companyNamesLoading: boolean = false;
  public filteredCompanyNames: string[] = [];

  private readonly subscriptions: Subscription = new Subscription();
  private readonly companyNameSubject: Subject<string> = new Subject();
  private readonly companyNameLoadingSubject: Subject<boolean> = new Subject();
  private readonly minLength: number = 1; // same as in the api 'companyGetCompanyNameSuggestions'

  constructor(private directoryService: DirectoryService) {
    // empty
  }

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.onUpdate();
  }

  public ngOnInit(): void {
    const companyLoading$: Observable<boolean> = this.companyNameLoadingSubject.asObservable();
    const filteredCompanyNames$: Observable<string[]> = this.getCompanyNameSuggestions(
      this.companyNameSubject.asObservable(),
      this.companyNameLoadingSubject
    );

    this.subscriptions.add(
      combineLatest([filteredCompanyNames$, companyLoading$]).subscribe(
        ([companyNames, loading]: [string[], boolean]) => {
          this.filteredCompanyNames = companyNames;
          this.companyNamesLoading = loading;
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onChange: any = () => {
    /* empty */
  };

  public onTouched: any = () => {
    /* empty */
  };

  public onUpdate(): void {
    this.updateName.emit(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(val: string): void {
    if (val) {
      this.value = val;
    }
  }

  public onCompanyNameChange(name: string): void {
    this.value = name;
    this.filteredCompanyNames = name.length <= this.minLength ? [] : this.filteredCompanyNames;
    this.companyNameSubject.next(name);
  }

  public onCompanyNameSelected(name: string): void {
    this.value = name;
  }

  private getCompanyNameSuggestions(
    value$: Observable<string>,
    loading: Subject<boolean>
  ): Observable<string[]> {
    return value$.pipe(
      debounceTime(500),
      // .distinctUntilChanged()
      filter((value: string) => value && value.length > 0),
      tap(() => loading.next(true)),
      switchMap((value: string) => {
        return this.directoryService.directoryGetNameSuggestionsFromAllCompanies(value);
      }),
      tap(() => loading.next(false))
    );
  }
}
