import { tassign } from 'tassign';
import cloneDeep from 'lodash/cloneDeep';

import * as global from './global.action';
import * as GlobalAction from './global.action';
import {
  AdditionalAddressTypeViewModel,
  ArticleForestViewModel,
  ExternalManagedAddressTypeViewModel,
  MasterDataMandatorViewModel,
  MasterDataUserViewModel,
  MasterDataViewModel,
} from '../../apis/advis';
import { MasterDataTradeTypeViewModel } from '../../apis/advis';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../utils/exter-managed-address-type.util';

export enum ErrorTypeE {
  UNDEF = 0,
  SAVE,
  LOAD,
  PERMISSION,
  SESSION_TIMEOUT,
}

export interface IErrorInfo {
  type: ErrorTypeE;
  data: any;
}

export interface IState {
  navigation_processing: boolean;
  masterData: MasterDataViewModel;
  allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];
  allowedCanSeeExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];
  allowedCanManagedExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];
  articlesForests: ArticleForestViewModel[];
  masterDataLoaded: boolean;
  masterDataLoading: boolean;
  mandantsMap: Map<number, MasterDataMandatorViewModel>;
  selectedMandants: Set<number>;
  errors: IErrorInfo[];
  users: MasterDataUserViewModel[];
  userMap: Map<string, MasterDataUserViewModel>;
  usersForSelectedMandants: MasterDataUserViewModel[];
  tradeTypesForSelectedMandants: MasterDataTradeTypeViewModel[];
}

export const initialState: IState = {
  navigation_processing: false,
  masterData: {},
  allExternalManagedAddressConfig: [],
  allowedCanSeeExternalManagedAddressConfig: [],
  allowedCanManagedExternalManagedAddressConfig: [],
  masterDataLoaded: false,
  masterDataLoading: false,
  articlesForests: [],
  mandantsMap: new Map<number, MasterDataMandatorViewModel>(),
  selectedMandants: new Set<number>(),
  errors: [],
  users: [],
  userMap: new Map<string, MasterDataUserViewModel>(),
  usersForSelectedMandants: [],
  tradeTypesForSelectedMandants: [],
};

export function reducer(state: IState = initialState, action: global.Actions): IState {
  switch (action.type) {
    case global.SET_NAVIGATION_PROCESS: {
      return tassign(state, {
        navigation_processing: action.payload,
      });
    }

    case GlobalAction.MASTER_DATA_LOAD: {
      return tassign(state, {
        masterDataLoading: true,
      });
    }

    case GlobalAction.MASTER_DATA_SUCCESS: {
      const masterData: MasterDataViewModel = cloneDeep(action.payload);
      const mandants: MasterDataMandatorViewModel[] = masterData.Mandators;
      const tmpMandantsMap: Map<number, MasterDataMandatorViewModel> = new Map<
        number,
        MasterDataMandatorViewModel
      >();
      const tmpUserMap: Map<string, MasterDataUserViewModel> = new Map<
        string,
        MasterDataUserViewModel
      >();
      const tmpUsers: MasterDataUserViewModel[] = [];
      const allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[] =
        masterData.AllExternalManagedAddressTypes.map((x: ExternalManagedAddressTypeViewModel) =>
          ExternManagedAddressTypeUtils.getConfigForAddressType(x.Key, x)
        );
      const allowedCanSeeExternalManagedAddressConfig: IExternManagedAddressTypeConfig[] =
        masterData.AllowedCanSeeExternalManagedAddressTypes.map(
          (x: AdditionalAddressTypeViewModel) =>
            ExternManagedAddressTypeUtils.getConfigForAddressType(x.Key, x)
        );
      const allowedCanManagedExternalManagedAddressConfig: IExternManagedAddressTypeConfig[] =
        masterData.AllowedCanManagedExternalManagedAddressTypes.map(
          (x: AdditionalAddressTypeViewModel) =>
            ExternManagedAddressTypeUtils.getConfigForAddressType(x.Key, x)
        );

      for (const mandant of mandants) {
        for (const user of mandant.Users) {
          if (!tmpUserMap.has(user.Id)) {
            tmpUserMap.set(user.Id, user);
            tmpUsers.push(user);
          }
        }

        if (!tmpMandantsMap.has(mandant.Id)) {
          tmpMandantsMap.set(mandant.Id, mandant);
        }
      }

      return tassign(state, {
        masterData: masterData,
        masterDataLoaded: true,
        masterDataLoading: false,
        allExternalManagedAddressConfig: allExternalManagedAddressConfig,
        allowedCanSeeExternalManagedAddressConfig: allowedCanSeeExternalManagedAddressConfig,
        allowedCanManagedExternalManagedAddressConfig:
          allowedCanManagedExternalManagedAddressConfig,
        mandantsMap: tmpMandantsMap,
        users: tmpUsers,
        userMap: tmpUserMap,
      });
    }

    case GlobalAction.MASTER_DATA_FAILURE: {
      return tassign(state, {
        masterData: {},
        masterDataLoaded: false,
        masterDataLoading: false,
      });
    }

    case GlobalAction.ARTICLES_FORESTS_LOAD_SUCCESS: {
      return tassign(state, {
        articlesForests: action.payload,
      });
    }

    case GlobalAction.ARTICLES_FORESTS_LOAD_FAILURE: {
      return tassign(state, {
        articlesForests: [],
      });
    }

    case GlobalAction.ERROR_ADD: {
      const errorsCopy: any = cloneDeep(state.errors);
      errorsCopy.push(action.payload);
      return tassign(state, {
        errors: errorsCopy,
      });
    }

    case GlobalAction.ERROR_CLEAR: {
      return tassign(state, {
        errors: [],
      });
    }

    case GlobalAction.SET_SELECTED_MANDANTS: {
      const selectedMandants: Set<number> = new Set<number>(action.payload);

      const mandants: MasterDataMandatorViewModel[] = state.masterData.Mandators;
      const tmpUserMap: Map<string, MasterDataUserViewModel> = new Map<
        string,
        MasterDataUserViewModel
      >();
      const tmpUsers: MasterDataUserViewModel[] = [];

      const tmpTradeTypeMap: Map<string, MasterDataTradeTypeViewModel> = new Map<
        string,
        MasterDataTradeTypeViewModel
      >();
      const tmpTradeTypes: MasterDataTradeTypeViewModel[] = [];

      for (const mandant of mandants) {
        if (selectedMandants.has(mandant.Id)) {
          // Users
          for (const user of mandant.Users) {
            if (!tmpUserMap.has(user.Id)) {
              tmpUserMap.set(user.Id, user);
              tmpUsers.push(user);
            }
          }

          // Trade types
          for (const tradeType of mandant.TradeTypes) {
            if (!tmpTradeTypeMap.has(tradeType.Code)) {
              tmpTradeTypeMap.set(tradeType.Code, tradeType);
              tmpTradeTypes.push(tradeType);
            }
          }
        }
      }

      return tassign(state, {
        selectedMandants: selectedMandants,
        usersForSelectedMandants: tmpUsers,
        tradeTypesForSelectedMandants: tmpTradeTypes,
      });
    }

    default: {
      return state;
    }
  }
}

export const getMasterDataState: (state: IState) => MasterDataViewModel = (state: IState) =>
  state.masterData;

export const getAllExternalManagedAddressConfigState: (
  state: IState
) => IExternManagedAddressTypeConfig[] = (state: IState) => state.allExternalManagedAddressConfig;
export const getAllowedCanSeeExternalManagedAddressConfigState: (
  state: IState
) => IExternManagedAddressTypeConfig[] = (state: IState) =>
  state.allowedCanSeeExternalManagedAddressConfig;
export const getAllowedCanManagedExternalManagedAddressConfigState: (
  state: IState
) => IExternManagedAddressTypeConfig[] = (state: IState) =>
  state.allowedCanManagedExternalManagedAddressConfig;

export const getArticlesForestsState: (state: IState) => ArticleForestViewModel[] = (
  state: IState
) => state.articlesForests;

export const getErrorsState: (state: IState) => any = (state: IState) => state.errors;

export const getMasterDataLoadedState: (state: IState) => boolean = (state: IState) =>
  state.masterDataLoaded;

export const getMasterDataLoadingState: (state: IState) => boolean = (state: IState) =>
  state.masterDataLoading;

export const getSelectedMandantsState: (state: IState) => Set<number> = (state: IState) =>
  state.selectedMandants;

export const getMandantsMapState: (state: IState) => Map<number, MasterDataMandatorViewModel> = (
  state: IState
) => state.mandantsMap;

export const getUserMapState: (state: IState) => Map<string, MasterDataUserViewModel> = (
  state: IState
) => state.userMap;

export const getUsersState: (state: IState) => MasterDataUserViewModel[] = (state: IState) =>
  state.users;

export const getUsersForSelectedMandantsState: (state: IState) => MasterDataUserViewModel[] = (
  state: IState
) => state.usersForSelectedMandants;

export const getTradeTypesForSelectedMandantsState: (
  state: IState
) => MasterDataTradeTypeViewModel[] = (state: IState) => state.tradeTypesForSelectedMandants;
