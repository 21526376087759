import { StageUtil } from '../../utils/stage.util';
import { LeadUtil } from '../../utils/lead.util';
import { IPrincipal } from '../../interfaces/principal.interface';
import { ILeadRights } from '../../interfaces/lead-rights.interface';
import { StageE } from '../enum/stage.enum';
import {
  LeadActionViewModel,
  LeadListViewModel,
  LeadStageViewModel,
  LeadViewModel,
} from '../../apis/advis';
import { ActionsUtil } from '../../utils/actions.util';
import { PermissionService } from '../../services/permission.service';
import { MEIER_TOBLER_MANDANT_ID } from 'app/mt-crm/mt-overview/mt-overview.component';
import ActionTypeEnum = LeadActionViewModel.ActionTypeEnum;

export class LeadRights implements ILeadRights {
  constructor(private principal: IPrincipal, private permissions: PermissionService) {
    // empty
  }

  public canUnreach<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.ContactFail,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanContactFail
    );
  }

  public canFupUnreach<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      LeadUtil.isOwner(lead, this.principal) &&
      StageUtil.isStageInArr(lead.Stage, [StageE.FOLLOWUP_NBO]) &&
      this.permissions.userPermissions.CanSeeFupContactFail
    );
  }

  public canBoSend<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      StageUtil.isStage(lead.Stage, StageE.ASSIGNED_BO) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.ContactSuccess,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanBoSent
    );
  }

  public canReach<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      !(lead as LeadViewModel).IsLocked &&
      !this.isReadOnly(lead) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.ContactSuccess,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      !StageUtil.isStage(lead.Stage, StageE.ASSIGNED_BO) &&
      this.permissions.userPermissions.CanContactSuccess
    );
  }

  public canModify<LeadType extends LeadViewModel | LeadListViewModel>(lead: LeadType): boolean {
    if (LeadUtil.isResponsible(lead, this.principal)) {
      if (
        !StageUtil.isStageInArr(lead.Stage, [
          StageE.ON_HOLD,
          StageE.ABORT,
          StageE.HANDOVER,
          StageE.WON,
          StageE.PROJECT_ARCHIVED,
        ]) &&
        this.permissions.userPermissions.CanLeadModify
      ) {
        return true;
      }
    }
    return false;
  }

  public canView<LeadType extends LeadViewModel | LeadListViewModel>(lead: LeadType): boolean {
    return !this.canModify(lead) && this.permissions.userPermissions.CanLeadView;
  }

  public isReadOnly<LeadType extends LeadViewModel | LeadListViewModel>(lead: LeadType): boolean {
    return !this.canModify(lead);
  }

  public canAbort<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      (LeadUtil.isResponsibleAndOwner(lead, this.principal) ||
        StageUtil.isStage(lead.Stage, StageE.NEW)) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.Abort,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanAbort
    );
  }

  public canHold<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      (LeadUtil.isResponsibleAndOwner(lead, this.principal) ||
        StageUtil.isStage(lead.Stage, StageE.NEW)) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.Hold,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanHold
    );
  }

  public canStartOrder<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      lead.MandantId !== MEIER_TOBLER_MANDANT_ID &&
      this.permissions.userPermissions.CanStartOrder &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.StartOrder,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canPutOrderBackToFollowUp<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      lead.MandantId !== MEIER_TOBLER_MANDANT_ID &&
      this.permissions.userPermissions.CanStartOrder &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.OrderBackToFollowUp,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canWin<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      !this.canStartOrder(lead, stages) &&
      ((LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
        this.permissions.userPermissions.CanWinWithVariant &&
        ActionsUtil.isAllowed(
          ActionTypeEnum.WinWithVariant,
          StageUtil.getAllowedActionsForStage(lead.Stage, stages)
        ) &&
        StageUtil.isStageInArr(lead.Stage, [StageE.FOLLOWUP_BO, StageE.ORDER_ENTRY])) ||
        (this.permissions.userPermissions.CanWinWithoutVariant &&
          ActionsUtil.isAllowed(
            ActionTypeEnum.Win,
            StageUtil.getAllowedActionsForStage(lead.Stage, stages)
          )))
    );
  }

  public canTransferback<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (this.permissions.userPermissions.CanTransferback) {
      return (
        ActionsUtil.isAllowed(
          ActionTypeEnum.TransferBack,
          StageUtil.getAllowedActionsForStage(lead.Stage, stages)
        ) && StageUtil.isStageInArr(lead.Stage, [StageE.HANDOVER])
      );
    }
    return false;
  }

  public canUploadProjectDocumentation<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      LeadUtil.isOwner(lead, this.principal) ||
      LeadUtil.isDelegate(lead, this.principal) ||
      this.permissions.userPermissions.CanAlwaysEditProjectData
    );
  }

  public canRelease<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (
      !this.permissions.userPermissions.CanRelease ||
      !StageUtil.canReleaseAndAssignnMe(lead, this.permissions)
    ) {
      return false;
    }
    return ActionsUtil.isAllowed(
      ActionTypeEnum.Release,
      StageUtil.getAllowedActionsForStage(lead.Stage, stages)
    );
  }

  public canWonBackToFollowupBo<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanWonBackToFollowupBo &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.PutWonBackToFollowupBo,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canDeleteTaskAppointment<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      this.permissions.userPermissions.CanDeleteTasksAppointment
    );
  }

  public canCreateInvoice<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      this.permissions.userPermissions.CanCreateInvoice
    );
  }

  public canAssignMe<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (
      LeadUtil.isOwner(lead, this.principal) ||
      !this.permissions.userPermissions.CanAssignMe ||
      !StageUtil.canAssignMe(lead, this.permissions)
    ) {
      return false;
    }
    return ActionsUtil.isAllowed(
      ActionTypeEnum.Transfer,
      StageUtil.getAllowedActionsForStage(lead.Stage, stages)
    );
  }

  public canAssignOther<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (
      !LeadUtil.isResponsibleAndOwner(lead, this.principal) ||
      StageUtil.isStageInArr(lead.Stage, [StageE.ABORT, StageE.ON_HOLD])
    ) {
      return false;
    }
    return (
      ActionsUtil.isAllowed(
        ActionTypeEnum.Transfer,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) && this.permissions.userPermissions.CanAssignOther
    );
  }

  public canDelegate<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (!LeadUtil.isResponsible(lead, this.principal)) {
      return false;
    }
    return (
      ActionsUtil.isAllowed(
        ActionTypeEnum.Delegate,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) && this.permissions.userPermissions.CanDelegate
    );
  }

  public canSendBackToOwner<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    if (!lead.DelegateId || lead.DelegateId.length === 0) {
      return false;
    }

    if (!LeadUtil.isDelegate(lead, this.principal) && !LeadUtil.isOwner(lead, this.principal)) {
      return false;
    }
    return (
      ActionsUtil.isAllowed(
        ActionTypeEnum.SendBackToOwner,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) && this.permissions.userPermissions.CanSendBackToOwner
    );
  }

  public canTriage<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.Triage,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanTriage
    );
  }

  public canHandover<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.Handover,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      ) &&
      this.permissions.userPermissions.CanHandover
    );
  }

  public canAssignProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanAssignProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.AssignProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canStartProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanStartProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.StartProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canFinishProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanFinishProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.FinishProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canArchiveProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanArchiveProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.ArchiveProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canReleaseProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanReleaseProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.ReleaseProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canPutFinishedProjectBackToInProgress<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanStartProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.PutFinishedProjectBackToInProgress,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canPutArchivedProjectBackToAccounted<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanArchiveProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.PutArchivedProjectBackToAccounted,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canEditTracers<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      LeadUtil.isResponsibleAndOwner(lead, this.principal) &&
      this.permissions.userPermissions.CanSeeTracerDialog
    );
  }

  public canCreateVariant<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    const forbiddenStages = [
      StageE.PROJECT_ASSIGNED,
      StageE.PROJECT_IN_PROGRESS,
      StageE.PROJECT_ARCHIVED,
      StageE.PROJECT_IN_OPERATION,
    ];

    return (
      this.permissions.userPermissions.CanCreateVariant &&
      !StageUtil.isStageInArr(lead.Stage, forbiddenStages)
    );
  }

  public canPutAccountedProjectBackToFinished<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanFinishProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.PutAccountedBackToFinished,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canAccountProject<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    stages: LeadStageViewModel[]
  ): boolean {
    return (
      this.permissions.userPermissions.CanFinishProject &&
      ActionsUtil.isAllowed(
        ActionTypeEnum.AccountProject,
        StageUtil.getAllowedActionsForStage(lead.Stage, stages)
      )
    );
  }

  public canSetVariantSalesProbability<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType
  ): boolean {
    return (
      (LeadUtil.isResponsible(lead, this.principal) || LeadUtil.isOwner(lead, this.principal)) &&
      this.permissions.userPermissions.CanSetSalesProbability
    );
  }

  public canApproveSolarTarif(): boolean {
    return this.permissions.userPermissions.CanApproveSolarTarif;
  }

  public canEditNotes(): boolean {
    return this.permissions.userPermissions.CanEditNotes;
  }
}
