/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalAddressTypeViewModel } from './additionalAddressTypeViewModel';
import { AdditionalComponentInstallationLocationViewModel } from './additionalComponentInstallationLocationViewModel';
import { ContactCustomerReminderTypeViewModel } from './contactCustomerReminderTypeViewModel';
import { ExternalManagedAddressTypeViewModel } from './externalManagedAddressTypeViewModel';
import { InvoiceStatusViewModel } from './invoiceStatusViewModel';
import { LeadProjectAdditionalComponentViewModel } from './leadProjectAdditionalComponentViewModel';
import { LeadStageViewModel } from './leadStageViewModel';
import { LeadStatusViewModel } from './leadStatusViewModel';
import { MasterDataLeadProjectPhaseViewModel } from './masterDataLeadProjectPhaseViewModel';
import { MasterDataMandatorViewModel } from './masterDataMandatorViewModel';
import { MasterDataPaymentTargetViewModel } from './masterDataPaymentTargetViewModel';
import { MasterDataProjectOrderTypeViewModel } from './masterDataProjectOrderTypeViewModel';
import { MasterDataProjectTypeViewModel } from './masterDataProjectTypeViewModel';
import { MasterDataSpecialPositionViewModel } from './masterDataSpecialPositionViewModel';
import { MasterDataTradeTypeViewModel } from './masterDataTradeTypeViewModel';
import { MasterDataWarrantyPeriodViewModel } from './masterDataWarrantyPeriodViewModel';
import { MasterDataWarrantyViewModel } from './masterDataWarrantyViewModel';
import { ProjectChecklistTemplateViewModel } from './projectChecklistTemplateViewModel';
import { ProjectFinanceTrafficLightViewModel } from './projectFinanceTrafficLightViewModel';


export interface MasterDataViewModel { 
    /**
     * All available currencies
     */
    Currencies?: Array<MasterDataViewModel.CurrenciesEnum>;
    /**
     * All available languages
     */
    Languages?: Array<MasterDataViewModel.LanguagesEnum>;
    /**
     * A collection of all available mandators
     */
    Mandators?: Array<MasterDataMandatorViewModel>;
    /**
     * All available trade types
     */
    TradeTypes?: Array<MasterDataTradeTypeViewModel>;
    /**
     * All available special positions
     */
    SpecialPositions?: Array<MasterDataSpecialPositionViewModel>;
    /**
     * All available stages
     */
    Stages?: Array<LeadStageViewModel>;
    /**
     * All available statuses
     */
    Statuses?: Array<LeadStatusViewModel>;
    DefaultOnHoldStatusForOnlinePool?: LeadStatusViewModel;
    /**
     * All available external managed address types
     */
    AllExternalManagedAddressTypes?: Array<ExternalManagedAddressTypeViewModel>;
    /**
     * All User permission allowed can managed external managed address types
     */
    AllowedCanSeeExternalManagedAddressTypes?: Array<ExternalManagedAddressTypeViewModel>;
    /**
     * All User permission allowed can see external managed address types
     */
    AllowedCanManagedExternalManagedAddressTypes?: Array<ExternalManagedAddressTypeViewModel>;
    /**
     * All User permission allowed available additional address types
     */
    AllowedAdditionalAddressTypes?: Array<AdditionalAddressTypeViewModel>;
    /**
     * All available additional address types
     */
    AllAdditionalAddressTypes?: Array<AdditionalAddressTypeViewModel>;
    /**
     * All available additional email types
     */
    AdditionalEmailTypes?: Array<AdditionalAddressTypeViewModel>;
    /**
     * All available additional phone types
     */
    AdditionalPhoneTypes?: Array<AdditionalAddressTypeViewModel>;
    /**
     * All available project types
     */
    ProjectTypes?: Array<MasterDataProjectTypeViewModel>;
    /**
     * All available project orders
     */
    ProjectOrderTypes?: Array<MasterDataProjectOrderTypeViewModel>;
    /**
     * All available additional components for project
     */
    AdditionalProjectComponents?: Array<LeadProjectAdditionalComponentViewModel>;
    /**
     * All available project phases
     */
    ProjectPhases?: Array<MasterDataLeadProjectPhaseViewModel>;
    /**
     * All available project check list templates
     */
    ProjectChecklistTemplates?: Array<ProjectChecklistTemplateViewModel>;
    /**
     * Enable new MeierTobler SAP api
     */
    EnableNewMeierToblerSapApi?: boolean;
    /**
     * Enable new EsErp project transfer feature
     */
    EnableEsErpProjectTransfer?: boolean;
    EnableJwtTokenAuth?: boolean;
    /**
     * Project finance traffic light options
     */
    ProjectFinanceTrafficLightOptions?: Array<ProjectFinanceTrafficLightViewModel>;
    /**
     * Contact customer reminder types
     */
    ContactCustomerReminderTypes?: Array<ContactCustomerReminderTypeViewModel>;
    /**
     * Warranty periods
     */
    WarrantyPeriods?: Array<MasterDataWarrantyPeriodViewModel>;
    /**
     * Warranties
     */
    Warranties?: Array<MasterDataWarrantyViewModel>;
    /**
     * Additional component installation locations
     */
    AdditionalComponentInstallationLocations?: Array<AdditionalComponentInstallationLocationViewModel>;
    /**
     * Payment targets
     */
    PaymentTargets?: Array<MasterDataPaymentTargetViewModel>;
    InvoiceStatuses?: Array<InvoiceStatusViewModel>;
    LeadComplexityTypes?: Array<string>;
}
export namespace MasterDataViewModel {
    export type CurrenciesEnum = 'EUR' | 'CHF' | 'USD';
    export const CurrenciesEnum = {
        EUR: 'EUR' as CurrenciesEnum,
        CHF: 'CHF' as CurrenciesEnum,
        USD: 'USD' as CurrenciesEnum
    };
    export type LanguagesEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguagesEnum = {
        DE: 'DE' as LanguagesEnum,
        FR: 'FR' as LanguagesEnum,
        IT: 'IT' as LanguagesEnum,
        EN: 'EN' as LanguagesEnum
    };
}
