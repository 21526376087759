import { Injectable } from '@angular/core';
import {
  DirectoryPersonViewModel,
  LeadViewModel,
  ProjectReportingSnapshotViewModel,
  TaskViewModel,
} from '../apis/advis';
import { TranslateService } from '@ngx-translate/core';
import { LeadOriginE } from '../models/enum/origin.enum';
import { TaskCutOffE } from '../models/enum/task-cut-off.enum';
import StatusEnum = TaskViewModel.StatusEnum;
import LanguageEnum = LeadViewModel.LanguageEnum;
import ReschedulingReasonEnum = ProjectReportingSnapshotViewModel.ProjectAccountedDateReschedulingReasonEnum;
import PreferredCommunicationTypeEnum = DirectoryPersonViewModel.PreferredCommunicationTypeEnum;

export interface IEnumData {
  key: any;
  display: string;
}

@Injectable()
export class EnumService {
  private enuMap: Map<any, IEnumData[]> = new Map<any, IEnumData[]>();

  constructor(private translate: TranslateService) {
    /* tslint:disable:max-line-length */

    //////////////
    // API Enum //
    //////////////

    // StatusEnum
    this.addToMap(
      StatusEnum,
      this.createMapEntry(
        StatusEnum.Completed,
        this.translate.instant('ENUM.TASK_VIEW_MODEL.STATUS_ENUM.ABGESCHLOSSEN')
      )
    );
    this.addToMap(
      StatusEnum,
      this.createMapEntry(
        StatusEnum.Open,
        this.translate.instant('ENUM.TASK_VIEW_MODEL.STATUS_ENUM.OFFEN')
      )
    );

    // ReschedulingReasonEnum
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.WEATHERCONDITIONS,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.WEATHER_CONDITIONS'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.LACKOFLABOR,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.LACK_OF_LABOR'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.SUPPLYCHAINISSUES,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.SUPPLY_CHAIN_ISSUES'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.PERMITSANDBUREAUCRACY,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.PERMITS_AND_BUREAUCRACY'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.SCOPECHANGES,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.SCOPE_CHANGES'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.TECHNICALISSUES,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.TECHNICAL_ISSUES'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.FINANCIALCHALLENGES,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.FINANCIAL_CHALLENGES'
        )
      )
    );
    this.addToMap(
      ReschedulingReasonEnum,
      this.createMapEntry(
        ReschedulingReasonEnum.UNFORESEENOBJECTCONDITIONS,
        this.translate.instant(
          'ENUM.PROJECT_REPORTING_SNAPSHOT_VIEW_MODEL.PROJECT_ACCOUNTED_DATE_RESCHEDULING_REASON_ENUM.UNFORESEEN_OBJECT_CONDITIONS'
        )
      )
    );

    // PersonTitleEnum
    this.addToMap(
      DirectoryPersonViewModel.TitleEnum,
      this.createMapEntry(
        DirectoryPersonViewModel.TitleEnum.None,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.ANREDE_ENUM.NONE')
      )
    );
    this.addToMap(
      DirectoryPersonViewModel.TitleEnum,
      this.createMapEntry(
        DirectoryPersonViewModel.TitleEnum.Sir,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.ANREDE_ENUM.HERR')
      )
    );
    this.addToMap(
      DirectoryPersonViewModel.TitleEnum,
      this.createMapEntry(
        DirectoryPersonViewModel.TitleEnum.Madam,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.ANREDE_ENUM.FRAU')
      )
    );
    this.addToMap(
      DirectoryPersonViewModel.TitleEnum,
      this.createMapEntry(
        DirectoryPersonViewModel.TitleEnum.Company,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.ANREDE_ENUM.FIRMA')
      )
    );
    this.addToMap(
      DirectoryPersonViewModel.TitleEnum,
      this.createMapEntry(
        DirectoryPersonViewModel.TitleEnum.Family,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.ANREDE_ENUM.FAMILIE')
      )
    );

    // LanguageEnum
    this.addToMap(
      LanguageEnum,
      this.createMapEntry(
        LanguageEnum.DE,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.SPRACHE_ENUM.DE')
      )
    );
    this.addToMap(
      LanguageEnum,
      this.createMapEntry(
        LanguageEnum.FR,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.SPRACHE_ENUM.FR')
      )
    );
    this.addToMap(
      LanguageEnum,
      this.createMapEntry(
        LanguageEnum.IT,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.SPRACHE_ENUM.IT')
      )
    );
    this.addToMap(
      LanguageEnum,
      this.createMapEntry(
        LanguageEnum.EN,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.SPRACHE_ENUM.EN')
      )
    );

    // CommunicationTypeEnum
    this.addToMap(
      PreferredCommunicationTypeEnum,
      this.createMapEntry(
        PreferredCommunicationTypeEnum.Email,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.COMMUNICATION_TYPE.EMAIL')
      )
    );

    this.addToMap(
      PreferredCommunicationTypeEnum,
      this.createMapEntry(
        PreferredCommunicationTypeEnum.Letter,
        this.translate.instant('ENUM.PERSON_VIEW_MODEL.COMMUNICATION_TYPE.LETTER')
      )
    );

    /////////////////
    // Custom Enum //
    /////////////////

    // OriginEnum
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.TIKO,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.TIKO')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.SONNEN,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.SONNEN')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.MESSE,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.EXPO')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.SOLARABO_WWF,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.SOLARABO')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.SOLARMACHER_OPFIKON,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.SOLARMACHER')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.CALL,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.CALL')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.CONTACT_FORM,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.CONTACT_FORM')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.SOLARENERGIE_VERGLEICH,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.SOLARENERGIE_VERGLEICH')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.HAUSHELD,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.HAUSHELD')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.MAILING,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.MAILING')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.PERS_CONTACT,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.PERS_CONTACT')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.SPONSORING,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.SPONSORING')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.PUBLICATIONS,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.PUBLICATIONS')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.PARTNER,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.PARTNER')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.WORKSHOP,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.WORKSHOP')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.PARTNER,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.TELENOT')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.VANDERBILT,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.VANDERBILT')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(LeadOriginE.BNI, this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.BNI'))
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.DEVIS,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.DEVIS')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.AUSSCHREIBUNG,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.TENDER')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(LeadOriginE.AIL, this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.AIL'))
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.VITOGAZ,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.VITOGAZ')
      )
    );
    this.addToMap(
      LeadOriginE,
      this.createMapEntryRaw(
        LeadOriginE.PRONTO,
        this.translate.instant('CUSTOM_ENUM.LEAD.ORIGIN.PRONTO')
      )
    );

    this.addToMap(
      TaskCutOffE,
      this.createMapEntryRaw(
        TaskCutOffE.FUTURE,
        this.translate.instant('CUSTOM_ENUM.TASK.CUTOFF.FUTURE')
      )
    );
    this.addToMap(
      TaskCutOffE,
      this.createMapEntryRaw(
        TaskCutOffE.FUTURE1DAY,
        this.translate.instant('CUSTOM_ENUM.TASK.CUTOFF.FUTURE_AFTER_TODAY')
      )
    );
    this.addToMap(
      TaskCutOffE,
      this.createMapEntryRaw(
        TaskCutOffE.FUTURE7DAYS,
        this.translate.instant('CUSTOM_ENUM.TASK.CUTOFF.FUTURE7DAYS')
      )
    );

    /* tslint:enable:max-line-length */
    // Add more ...
  }

  public getEnumData(enumType: any): IEnumData[] {
    if (this.enuMap.has(enumType)) {
      const enumDataArr: IEnumData[] = this.enuMap.get(enumType);

      // Sort alphabetically
      enumDataArr.sort((e1: IEnumData, e2: IEnumData) => {
        return e1.display.localeCompare(e2.display);
      });

      return enumDataArr;
    }
    console.error(`Undef EnumType="${this.EnumToString(enumType)}"`);
    return [this.createDummy(`Undef EnumType="${this.EnumToString(enumType)}"`)];
  }

  public getEnumDataExclude(enumType: any, enumsToExclude: any[]): IEnumData[] {
    const enumDataArr: IEnumData[] = this.getEnumData(enumType);

    return enumDataArr.filter((enumData: IEnumData) => {
      const exclude: string = enumsToExclude.join(',');
      return exclude.includes(enumData.key.toString()) === false;
    });
  }

  public fromValue(enumType: any, value: string): IEnumData {
    if (this.enuMap.has(enumType)) {
      const enumDataArr: IEnumData[] = this.enuMap.get(enumType);
      for (const enumData of enumDataArr) {
        if (enumData.key === value) {
          return enumData;
        }
      }
      console.error(`Undef value="${value}" for EnumType="${this.EnumToString(enumType)}"`);
      return this.createDummy(
        `Undef value="${value}" for EnumType="${this.EnumToString(enumType)}"`
      );
    }
    console.error(`Undef EnumType="${this.EnumToString(enumType)}"`);
    return this.createDummy(`Undef EnumType="${this.EnumToString(enumType)}"`);
  }

  private createMapEntry(enumValue: any, displayStr: string): IEnumData {
    return {
      key: enumValue,
      display: displayStr,
    };
  }

  private createMapEntryRaw(enumValue: any, displayStr: string): IEnumData {
    return {
      key: enumValue,
      display: displayStr,
    };
  }

  private addToMap(enumType: any, enumData: IEnumData): void {
    if (this.enuMap.has(enumType)) {
      this.enuMap.get(enumType).push(enumData);
    } else {
      this.enuMap.set(enumType, [enumData]);
    }
  }

  private createDummy(msg: string): IEnumData {
    return {
      key: undefined,
      display: msg,
    };
  }

  private EnumToString(enumType: any): string {
    let str: string = '';
    let idx: number = 0;
    for (const item in enumType) {
      const entry: any = enumType[item];
      str += entry + `=${idx}, `;
      idx++;
    }
    return str;
  }
}
