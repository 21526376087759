/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ContactAddressContactListViewModel { 
    LastName?: string;
    FirstName?: string;
    DisplayName?: string;
    Street?: string;
    ZipCode?: string;
    City?: string;
    Id?: number;
    MandatorId?: number;
    Title?: ContactAddressContactListViewModel.TitleEnum;
    CompanyName?: string;
    AdditionalCompanyName?: string;
    Email?: string;
    PhoneNumber?: string;
    Language?: ContactAddressContactListViewModel.LanguageEnum;
}
export namespace ContactAddressContactListViewModel {
    export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
    export const TitleEnum = {
        None: 'None' as TitleEnum,
        Sir: 'Sir' as TitleEnum,
        Madam: 'Madam' as TitleEnum,
        Company: 'Company' as TitleEnum,
        Family: 'Family' as TitleEnum
    };
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
}
