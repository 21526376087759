import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import * as LeadContact from './lead-contact.action';
import {
  IAddLeadAdditionalAddressBookContactActionPayload,
  IDeleteAdditionalLeadContactActionPayload,
} from './lead-contact.action';
import {
  LeadCompositeViewModel,
  LeadService,
  PostContactResponse,
  PostPutSingleContactRequest,
} from '../../apis/advis';
import { CommonUtil } from '../../utils/common.util';
import * as LeadAction from '../lead/lead.action';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

@Injectable()
export class LeadContactEffects {
  addLeadAdditionalSingleContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.ADD_LEAD_ADDITIONAL_SINGLE_CONTACT),
      map((action: LeadContact.AddLeadAdditionalSingleContactAction) => action.payload),
      switchMap((payload: LeadContact.IAddLeadAdditionalSingleContactActionPayload) => {
        const tmp: PostPutSingleContactRequest = CommonUtil.trimAllStrings(payload.contact);
        return this.leadService.leadAddressPostAdditionalSingleContact(payload.leadId, tmp).pipe(
          map((response: PostContactResponse) => {
            return new LeadContact.PutContactLogoAction({
              isNew: true,
              lead: response.Lead,
              contactId: response.ContactId,
              companyLogo: payload.companyLogo,
              personLogo: payload.personLogo,
            });
          })
        );
      }),
      catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
    )
  );

  addLeadAdditionalAddressbookContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT),
      map((action: LeadContact.AddLeadAdditionalAddressBookContactAction) => action.payload),
      switchMap((payload: IAddLeadAdditionalAddressBookContactActionPayload) => {
        return this.leadService
          .leadAddressPostAdditionalDirectoryContact(payload.leadId, payload.request)
          .pipe(
            mergeMap((lead: LeadCompositeViewModel) => {
              return [
                new LeadAction.DetailLoadSuccessAction(lead),
                new LeadContact.AddLeadAdditionalContactSuccessAction(),
                new LeadContact.AddLeadAdditionalAddressBookContactSuccessAction(),
              ];
            })
          );
      }),
      catchError(() => of(new LeadContact.AddLeadAdditionalAddressBookContactFailedAction()))
    )
  );

  updateLeadAddressSingleAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.UPDATE_LEAD_SINGLE_CONTACT),
      map((action: LeadContact.UpdateLeadSingleContactAction) => action.payload),
      switchMap((payload: LeadContact.IUpdateLeadSingleContactActionPayload) => {
        return this.leadService
          .leadAddressPutSingleContact(payload.leadId, CommonUtil.trimAllStrings(payload.contact))
          .pipe(
            map((response: LeadCompositeViewModel) => {
              return new LeadContact.PutContactLogoAction({
                isNew: false,
                lead: response,
                contactId: payload.contactId,
                companyLogo: payload.companyLogo,
                personLogo: payload.personLogo,
              });
            })
          )
          .pipe(
            map(() => {
              return new LeadContact.LeadContactUpdateSuccessAction();
            })
          );
      }),
      catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
    )
  );

  updateLeadAddressAddressBook$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.UPDATE_LEAD_ADDRESS_BOOK_CONTACT),
      map((action: LeadContact.UpdateLeadAddressBookContactAction) => action.payload),
      switchMap((payload: LeadContact.IUpdateLeadAddressBookContactActionPayload) => {
        return this.leadService
          .leadAddressPutDirectoryContact(payload.leadId, payload.request)
          .pipe(
            map(() => {
              return new LeadContact.LeadContactUpdateSuccessAction();
            })
          );
      }),
      catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
    )
  );

  getCompanyLogo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.PUT_CONTACT_LOGO),
      map((action: LeadContact.PutContactLogoAction) => action.payload),
      filter((c: LeadContact.IPutContactLogoActionPayload) => !isNullOrUndefined(c.companyLogo)),
      switchMap((payload: LeadContact.IPutContactLogoActionPayload) => {
        return this.leadService
          .leadAddressPutContactLogo(
            payload.lead.Lead.Id,
            payload.contactId,
            'Company',
            payload.companyLogo
          )
          .pipe(
            mergeMap((lead: LeadCompositeViewModel) => {
              return [
                new LeadAction.DetailLoadSuccessAction(lead),
                new LeadContact.AddLeadAdditionalContactSuccessAction(),
                new LeadContact.PutContactLogoSuccessAction(payload),
              ];
            }),
            catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
          );
      })
    )
  );

  noCompanyLogo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.PUT_CONTACT_LOGO),
      map((action: LeadContact.PutContactLogoAction) => action.payload),
      filter((c: LeadContact.IPutContactLogoActionPayload) => isNullOrUndefined(c.companyLogo)),
      mergeMap((payload: LeadContact.IPutContactLogoActionPayload) => {
        return [
          new LeadAction.DetailLoadSuccessAction(payload.lead),
          new LeadContact.AddLeadAdditionalContactSuccessAction(),
          new LeadContact.PutContactLogoSuccessAction(payload),
        ];
      })
    )
  );

  getPersonLogo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.PUT_CONTACT_LOGO_SUCCESS),
      map((action: LeadContact.PutContactLogoAction) => action.payload),
      filter((c: LeadContact.IPutContactLogoActionPayload) => !isNullOrUndefined(c.personLogo)),
      switchMap((payload: LeadContact.IPutContactLogoActionPayload) =>
        this.leadService.leadAddressPutContactLogo(
          payload.lead.Lead.Id,
          payload.contactId,
          'Person',
          payload.personLogo
        )
      ),
      mergeMap((lead: LeadCompositeViewModel) => [
        new LeadContact.LeadContactUpdateSuccessAction(),
        new LeadAction.DetailLoadSuccessAction(lead),
      ]),
      catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
    )
  );

  noPersonLogo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.PUT_CONTACT_LOGO_SUCCESS),
      map((action: LeadContact.PutContactLogoAction) => action.payload),
      filter((c: LeadContact.IPutContactLogoActionPayload) => isNullOrUndefined(c.personLogo)),
      mergeMap((payload: LeadContact.IPutContactLogoActionPayload) => [
        new LeadContact.LeadContactUpdateSuccessAction(),
        new LeadAction.DetailLoadSuccessAction(payload.lead),
      ])
    )
  );

  deleteAdditionalLeadAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadContact.DELETE_ADDITIONAL_LEAD_CONTACT),
      map((action: LeadContact.DeleteAdditionalLeadContactAction) => action.payload),
      switchMap((payload: IDeleteAdditionalLeadContactActionPayload) =>
        this.leadService.leadAddressRemoveLeadContact(payload.leadId, payload.contactId)
      ),
      mergeMap((lead: LeadCompositeViewModel) => [
        new LeadContact.LeadContactUpdateSuccessAction(),
        new LeadAction.DetailLoadSuccessAction(lead),
      ]),
      catchError(() => of(new LeadContact.LeadContactUpdateFailedAction()))
    )
  );

  constructor(private actions$: Actions, private leadService: LeadService) {}
}
