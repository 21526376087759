import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createSelector, MemoizedSelector, Selector, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import isEmpty from 'lodash/isEmpty';

import * as LeadNotesAction from './lead-notes.action';
import { ITextNote } from './lead-notes.action';

import { LeadNoteService, NoteTemplateViewModel, NoteViewModel } from '../../apis/advis';
import { ConfigService } from '../../services/config.service';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import * as RootReducer from '../index';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ErrorTypeE } from '../global/global.reducer';
import { ErrorAddAction } from '../global/global.action';

@Injectable()
export class LeadNotesEffects implements OnDestroy {
  public getAllNotes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.GET_ALL_NOTES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.GetAllNotesAction) => action.payload),
      switchMap((payload: LeadNotesAction.IGetAllNotes) => {
        const selectNotes: Selector<RootReducer.IState, NoteViewModel[]> = (
          storeState: RootReducer.IState
        ) => storeState.leadNotes.notes;
        const selectLeadId: Selector<RootReducer.IState, number> = (
          storeState: RootReducer.IState
        ) => storeState.leadNotes.notesLeadId;
        const combinedSelectors: MemoizedSelector<RootReducer.IState, any> = createSelector(
          selectNotes,
          selectLeadId,
          (notes: NoteViewModel[], leadId: number) => ({ notes, leadId })
        );
        return this.store.select(combinedSelectors).pipe(
          take(1),
          switchMap((selectResult: any) => {
            if (
              !isEmpty(selectResult.notes) &&
              payload.leadId === selectResult.leadId &&
              !isNotNullOrUndefined(payload.useForceAllNotesLoad) &&
              !payload.useForceAllNotesLoad
            ) {
              return [
                new LeadNotesAction.GetAllNotesSuccessAction({
                  notes: selectResult.notes,
                  leadId: payload.leadId,
                }),
              ];
            }
            return this.leadNotesService.leadNoteGetNotes(payload.leadId).pipe(
              map((notes: NoteViewModel[]) => {
                return new LeadNotesAction.GetAllNotesSuccessAction({
                  notes,
                  leadId: payload.leadId,
                });
              }),
              catchError((e: any) => of(new LeadNotesAction.GetAllNotesFailedAction(e)))
            );
          })
        );
      })
    )
  );

  public getNoteTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.GET_NOTE_TEMPLATES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.GetNoteTemplatesAction) => action.payload),
      switchMap((payload: number) => {
        return this.leadNotesService.leadNoteGetNoteTemplates(payload).pipe(
          map(
            (templates: NoteTemplateViewModel[]) =>
              new LeadNotesAction.GetNoteTemplatesSuccessAction(templates)
          ),
          catchError((e: any) => of(new LeadNotesAction.GetNoteTemplatesFailedAction(e)))
        );
      })
    )
  );

  public getNote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.GET_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.GetNoteAction) => action.payload),
      switchMap((payload: LeadNotesAction.IGetNote) => {
        return this.leadNotesService.leadNoteGetNote(payload.leadId, payload.noteId).pipe(
          map((note: NoteViewModel) => {
            return new LeadNotesAction.GetNoteSuccessAction(note);
          }),
          catchError((e: any) => of(new LeadNotesAction.GetNoteFailedAction(e)))
        );
      })
    )
  );

  public getPmInfoNote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.GET_PM_INFO_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.GetPmInfoNoteAction) => action.payload),
      switchMap((leadId: number) => {
        return this.leadNotesService.leadNoteGetPmInfoNote(leadId).pipe(
          map((note: NoteViewModel) => new LeadNotesAction.GetPmInfoNoteSuccessAction(note)),
          catchError((e: any) => of(new LeadNotesAction.GetPmInfoNoteFailedAction(e)))
        );
      })
    )
  );

  public addPmInfoTextNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.ADD_PM_INFO_TEXT_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.AddPmInfoTextNoteAction) => action.payload),
      switchMap((payload: ITextNote) => {
        return this.leadNotesService
          .leadNoteAddPmInfoTextNote(payload.note.LeadId, {
            Text: payload.note.Text,
            Tags: payload.note.Tags,
          })
          .pipe(
            map(
              (addedNote: NoteViewModel) =>
                new LeadNotesAction.AddPmInfoTextNoteSuccessAction(addedNote)
            ),
            catchError((e: any) => of(new LeadNotesAction.AddPmInfoTextNoteFailureAction(e)))
          );
      })
    )
  );

  public addTextNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.ADD_TEXT_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.AddTextNoteAction) => action.payload),
      switchMap((payload: ITextNote) => {
        return this.leadNotesService
          .leadNoteAddTextNote(payload.note.LeadId, {
            Text: payload.note.Text,
            Tags: payload.note.Tags,
          })
          .pipe(
            map((addedNote: NoteViewModel) => {
              if (
                isNotNullOrUndefined(payload.doNotNavigationBack) &&
                payload.doNotNavigationBack
              ) {
                return new LeadNotesAction.AddTextNoteSuccessWithoutNavigationAction(addedNote);
              }
              return new LeadNotesAction.AddTextNoteSuccessAction(addedNote);
            }),
            catchError((e: any) => of(new LeadNotesAction.AddTextNoteFailureAction(e)))
          );
      })
    )
  );

  public updateTextNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.UPDATE_TEXT_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.UpdateTextNoteAction) => action.payload),
      switchMap((payload: ITextNote) => {
        return this.leadNotesService
          .leadNoteUpdateTextNote(payload.note.LeadId, payload.note.Id, {
            Text: payload.note.Text,
            Tags: payload.note.Tags,
          })
          .pipe(
            map((updatedNote: NoteViewModel) => {
              if (
                isNotNullOrUndefined(payload.doNotNavigationBack) &&
                payload.doNotNavigationBack
              ) {
                return new LeadNotesAction.UpdateTextNoteSuccessWithoutNavigationAction(
                  updatedNote
                );
              }

              return new LeadNotesAction.UpdateTextNoteSuccessAction(updatedNote);
            }),
            catchError((e: any) => of(new LeadNotesAction.UpdateTextNoteFailureAction(e)))
          );
      })
    )
  );

  public updatePmInfoTextNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.UPDATE_PM_INFO_TEXT_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.UpdatePmInfoTextNoteAction) => action.payload),
      switchMap((payload: ITextNote) => {
        return this.leadNotesService
          .leadNoteUpdatePmInfoTextNote(payload.note.LeadId, payload.note.Id, {
            Text: payload.note.Text,
          })
          .pipe(
            map(
              (updatedNote: NoteViewModel) =>
                new LeadNotesAction.UpdatePmInfoTextNoteSuccessAction(updatedNote)
            ),
            catchError((e: any) => of(new LeadNotesAction.UpdatePmInfoTextNoteFailureAction(e)))
          );
      })
    )
  );

  public addDrawingNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.ADD_DRAWING_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.AddDrawingNoteAction) => action.payload),
      switchMap((data: LeadNotesAction.IDrawingNote) => {
        return this.leadNotesService
          .leadNoteAddDrawingNote(
            data.noteData.LeadId,
            data.drawingImage,
            data.noteData.Tags,
            '',
            data.noteData.DrawingNoteImageData
          )
          .pipe(
            map((addedNote: NoteViewModel) => {
              if (isNotNullOrUndefined(data.doNotNavigationBack) && data.doNotNavigationBack) {
                return new LeadNotesAction.AddDrawingNoteSuccessWithoutNavigationAction(addedNote);
              }
              return new LeadNotesAction.AddDrawingNoteSuccessAction(addedNote);
            }),
            catchError((e: any) => of(new LeadNotesAction.AddDrawingNoteFailureAction(e)))
          );
      })
    )
  );

  public updateDrawingNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.UPDATE_DRAWING_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.UpdateDrawingNoteAction) => action.payload),
      switchMap((data: LeadNotesAction.IDrawingNote) => {
        return this.leadNotesService
          .leadNoteUpdateDrawingNote(
            data.noteData.LeadId,
            data.noteData.Id,
            data.noteData.Tags,
            '',
            data.noteData.DrawingNoteImageData,
            data.drawingImage
          )
          .pipe(
            map((updatedNote: NoteViewModel) => {
              if (isNotNullOrUndefined(data.doNotNavigationBack) && data.doNotNavigationBack) {
                return new LeadNotesAction.UpdateDrawingNoteSuccessWithoutNavigationAction(
                  updatedNote
                );
              }
              return new LeadNotesAction.UpdateDrawingNoteSuccessAction(updatedNote);
            }),
            catchError((e: any) => of(new LeadNotesAction.UpdateDrawingNoteFailureAction(e)))
          );
      })
    )
  );

  public removeNote: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadNotesAction.REMOVE_NOTE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadNotesAction.RemoveNoteAction) => action.payload),
      switchMap((note: NoteViewModel) => {
        return this.leadNotesService.leadNoteRemoveNote(note.LeadId, note.Id).pipe(
          map(() => {
            return new LeadNotesAction.RemoveNoteSuccessAction(note);
          }),
          catchError((e: any) => of(new LeadNotesAction.RemoveNoteFailureAction(e)))
        );
      })
    )
  );

  public saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LeadNotesAction.ADD_TEXT_NOTE_FAILURE,
        LeadNotesAction.UPDATE_TEXT_NOTE_FAILURE,
        LeadNotesAction.ADD_DRAWING_NOTE_FAILURE,
        LeadNotesAction.UPDATE_DRAWING_NOTE_FAILURE,
        LeadNotesAction.REMOVE_NOTE_FAILURE,
        LeadNotesAction.ADD_PM_INFO_TEXT_NOTE_FAILURE,
        LeadNotesAction.UPDATE_PM_INFO_TEXT_NOTE_FAILURE
      ),
      debounceTime(this.config.apiDebounceTimeMs),
      map(
        (
          action:
            | LeadNotesAction.AddTextNoteFailureAction
            | LeadNotesAction.UpdateTextNoteFailureAction
            | LeadNotesAction.AddDrawingNoteFailureAction
            | LeadNotesAction.UpdateDrawingNoteFailureAction
            | LeadNotesAction.RemoveNoteFailureAction
            | LeadNotesAction.AddPmInfoTextNoteFailureAction
            | LeadNotesAction.UpdatePmInfoTextNoteFailureAction
        ) => {
          return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
        }
      )
    )
  );

  private subscriptions: Subscription = new Subscription();

  constructor(
    private actions$: Actions,
    private leadNotesService: LeadNoteService,
    private config: ConfigService,
    private store: Store<RootReducer.IState>
  ) {
    // empty
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
