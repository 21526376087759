import { Component, forwardRef } from '@angular/core';
import { DirectoryPersonViewModel } from '../../shared/apis/advis';
import PreferredCommunicationTypeEnum = DirectoryPersonViewModel.PreferredCommunicationTypeEnum;
import { RadioButtonItem } from '@sitewerk/theia-ui-lib';
import { EnumService } from '../../shared/services/enum.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pc-select-communication-type',
  templateUrl: './select-communication-type.component.html',
  styleUrls: ['./select-communication-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCommunicationTypeComponent),
      multi: true,
    },
  ],
})
export class SelectCommunicationTypeComponent implements ControlValueAccessor {
  public options: RadioButtonItem[] = [];
  public selectedValue: any;

  constructor(private enumService: EnumService) {
    this.options = this.enumService
      .getEnumData(PreferredCommunicationTypeEnum)
      .sort(x => x.key)
      .map(x => {
        return {
          value: x.key,
          name: 'communication-type-radio-group',
          label: x.display,
        };
      });
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  public onSelectedValueChange(value: any): void {
    this.onTouch();
    this.onChange(value);
    this.writeValue(value);
  }
}
