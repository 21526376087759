export interface IEnvironmentInterface {
  production: boolean;
  apiBasePath: string;
  protectDevelopmentPage: boolean;
  versionInfo: string;
  version: string;
  versionCheckIntervalInMs: number;
  googleMapsApiKey: string;
  googleMapsApiKeyNEW: string;
  veseApiKey: string;
  instrumentStoreDevtools: boolean;
  environmentType: EnvironmentTypeEnum;
  checkVersionUrl: string;
  screenshotServiceBasePath: string;
  screenshotServiceApiToken: string;
  appInsightsInstrumentationKey?: string;
  elektroFormAuthIssuer: string;
  elektroFormAuthClientId: string;
  elektroFormAuthClientSecret: string;
  electroFormProjectdef: string;
  ssoClientId: string;
  ssoApiApplicationId: string;
  trackAndTraceUrl: string;
}

export enum EnvironmentTypeEnum {
  LOCAL = <any>'LOCAL',
  DEV = <any>'DEVELOPMENT',
  TEST = <any>'TESTING',
  STAGE = <any>'STAGING',
  PROD = <any>'PRODUCTION',
}
