<div class="lead-detail-cards notes-card" [class.side]="!isFullScreenMode">
  <div>
    <div fxLayoutAlign="space-between" class="margin-bottom-10">
      <div fxLayoutAlign="center center" class="text-bold text-24">
        {{ 'LEAD_DETAIL.NOTES' | translate }}
      </div>
      <div class="d-flex align-items-center gap-16">
        <helion-ui-button-toggle-group
          name="toggleGroup"
          [(ngModel)]="selectedNoteType"
          *ngIf="isFullScreenMode"
          class="toggle-group-container-notes">
          <button
            class="d-flex align-items-center gap-8"
            *ngFor="let noteType of noteTypes"
            [attr.data-cy]="noteType.cyAttr ? noteType.cyAttr : ''"
            helion-ui-button-toggle
            fill-type="contained"
            (click)="onSetNoteType(noteType.type)"
            [value]="noteType.type">
            <helion-ui-icon [size]="16" [attr.data-feather]="noteType.icon"></helion-ui-icon>
            <label>{{ noteType.name }}</label>
          </button>
        </helion-ui-button-toggle-group>
        <a
          fxHide.lt-sm
          style="display: inline-block"
          (click)="this.printNotes()"
          class="no-underline">
          <helion-ui-icon
            data-feather="printer"
            attr.aria-label="{{ 'PROJECT_DESCRIPTION.PRINT' | translate }}">
          </helion-ui-icon>
        </a>
        <a
          [routerLink]="['lead', 'detail', lead.Lead.Id, 'notes']"
          class="no-underline"
          *ngIf="!isFullScreenMode">
          <helion-ui-icon
            data-feather="maximize"
            attr.aria-label="{{ 'COMMON.FULLSCREEN' | translate }}"
            [tooltip]="'COMMON.FULLSCREEN' | translate">
          </helion-ui-icon>
        </a>
        <helion-ui-icon
          *ngIf="overlayRef"
          role="button"
          data-feather="x"
          (click)="overlayRef.dispose()"></helion-ui-icon>
      </div>
    </div>
  </div>
  <div>
    <helion-ui-button-toggle-group
      name="toggleGroup"
      [(ngModel)]="selectedNoteType"
      *ngIf="!isFullScreenMode"
      class="toggle-group-container-notes">
      <button
        class="d-flex align-items-center gap-8"
        *ngFor="let noteType of noteTypes"
        [attr.data-cy]="noteType.cyAttr ? noteType.cyAttr : ''"
        helion-ui-button-toggle
        fill-type="contained"
        (click)="onSetNoteType(noteType.type)"
        [value]="noteType.type">
        <helion-ui-icon [size]="16" [attr.data-feather]="noteType.icon"></helion-ui-icon>
        <label>{{ noteType.name }}</label>
      </button>
    </helion-ui-button-toggle-group>

    <pc-note
      *ngIf="selectedNoteType"
      (onBack)="onBack()"
      [isEditMode]="true"
      [isNew]="!note.Id"
      [isTaggedNote]="true"
      [note]="note"
      [translateRoleTargetGroup]="bindGetRoleTargetGroupTranslation"></pc-note>

    <pc-lead-note-card
      *ngIf="lead"
      [selectedNote]="selectedNote"
      [selectedTags]="selectedTags"
      [leadId]="lead.Lead.Id"
      [editNavigateCommand]="'./note'"
      [roleTargetGroups]="this.roleTargetGroups"
      [translateRoleTargetGroup]="bindGetRoleTargetGroupTranslation"></pc-lead-note-card>
  </div>
</div>
<pc-loading-modal [id]="modalLoaderIdTypesEnum.NOTES_LIST" type="overlay"></pc-loading-modal>
