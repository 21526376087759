<button
  class="overflow-visible"
  [tooltip]="intTooltip"
  [cdkMenuTriggerFor]="menu"
  (click)="setInitialActiveGroup()">
  <pc-notification-count
    *ngIf="showCounter"
    [notifications]="sameMandatorSibling?.length + otherMandatorSiblings?.length">
    <helion-ui-icon [attr.data-feather]="iconName"></helion-ui-icon>
  </pc-notification-count>
  <helion-ui-icon *ngIf="!showCounter" data-feather="users">assignment_ind</helion-ui-icon>
</button>

<ng-template #menu>
  <helion-ui-menu>
    <div class="controls">
      <helion-ui-button-toggle-group
        *ngIf="notificationType !== notificationTypes.SAME_GROUP"
        class="active-siblings-group"
        [ngModel]="activeSiblings"
        (ngModelChange)="onToggleMandatorType($event)">
        <button
          helion-ui-button-toggle
          *ngIf="sameMandatorSibling.length > 0"
          class="active-siblings-group-button"
          (click)="$event.stopPropagation()"
          [value]="activeSiblingsEnum.SAME_MANDATORS">
          {{ 'LEAD_DETAIL.SIBLINGS.SAME_MANDATORS' | translate }}
        </button>

        <button
          helion-ui-button-toggle
          *ngIf="otherMandatorSiblings.length > 0"
          class="active-siblings-group-button"
          (click)="$event.stopPropagation()"
          [value]="activeSiblingsEnum.OTHER_MANDATORS">
          {{ 'LEAD_DETAIL.SIBLINGS.OTHER_MANDATORS' | translate }}
        </button>
      </helion-ui-button-toggle-group>
    </div>

    <div
      *ngIf="activeSiblings === activeSiblingsEnum.SAME_MANDATORS"
      class="nav-item"
      (click)="onClickNavList($event)">
      <cdk-virtual-scroll-viewport itemSize="61" class="siblings-list-viewport">
        <ng-container
          *cdkVirtualFor="let item of sameMandatorSibling; let last = last"
          class="siblings-list-item">
          <a
            class="sibling-item"
            [class.disabled-events]="disableSiblingSelect"
            [routerLink]="['/lead/detail', item.LeadId]">
            <helion-ui-tag-trade-type
              [tradeTypeCode]="item.TradeTypeCode"></helion-ui-tag-trade-type>
            <h4 class="lead-id">
              <span>{{ item.LeadId }}</span>
            </h4>
            <h4 *ngIf="showPersonAddress" class="address">
              <span class="address-name">
                {{ item.PersonFirstName }} {{ item.PersonLastName }}</span
              >
              <span class="address-additional"
                >{{ item.PersonContactStreet }}, <br />
                {{ item.PersonContactZipCode }} {{ item.PersonContactCity }}</span
              >
            </h4>
            <h4 *ngIf="showObjectAddress" class="address">
              <span class="address-name">
                {{ item.ObjectAddressFirstName }} {{ item.ObjectAddressLastName }}</span
              >
              <span class="address-additional"
                >{{ item.ObjectAddressStreet }}, <br />
                {{ item.ObjectAddressZipCode }} {{ item.ObjectAddressCity }}</span
              >
            </h4>
            <h4 *ngIf="showMandantName" class="mandant-name">
              <span>{{ item.MandatorId | siblingsListMandator : masterData.Mandators }}</span>
            </h4>
            <h4 class="owner">
              <span class="text-wrap">{{ item | ownerDisplayName }}</span>
            </h4>
            <h4 class="stage">
              <span class="text-wrap">{{ item.Stage }}</span>
            </h4>
            <h4 class="status">
              <span class="text-wrap">{{ item.Status }}</span>
            </h4>
            <h4 class="modifiedDate">
              <span class="text-wrap">{{ item.ModifiedDate | date : 'dd.MM.yy' }}</span>
            </h4>
            <h4 class="mandator">
              <span class="text-wrap">{{
                item.MandatorId | siblingsListMandator : masterData.Mandators
              }}</span>
            </h4>
          </a>
          <helion-ui-divider *ngIf="!last"></helion-ui-divider>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>

    <div
      *ngIf="activeSiblings === activeSiblingsEnum.OTHER_MANDATORS"
      class="nav-item"
      dense
      (click)="onClickNavList($event)">
      <cdk-virtual-scroll-viewport appendOnly itemSize="61" class="siblings-list-viewport">
        <ng-container
          *cdkVirtualFor="let item of otherMandatorSiblings; let last = last"
          class="siblings-list-item">
          <a
            class="sibling-item"
            [class.disabled-events]="disableSiblingSelect"
            [routerLink]="['/lead/detail', item.LeadId]">
            <helion-ui-tag-trade-type
              [tradeTypeCode]="item.TradeTypeCode"></helion-ui-tag-trade-type>
            <h4 class="lead-id">
              <span>{{ item.LeadId }}</span>
            </h4>
            <h4 class="address">
              <h4 *ngIf="showPersonAddress" class="address">
                <span class="address-name">
                  {{ item.PersonFirstName }} {{ item.PersonLastName }}</span
                >
                <span class="address-additional"
                  >{{ item.PersonContactStreet }}, <br />
                  {{ item.PersonContactZipCode }} {{ item.PersonContactCity }}</span
                >
              </h4>
              <h4 *ngIf="showObjectAddress" class="address">
                <span class="address-name">
                  {{ item.ObjectAddressFirstName }} {{ item.ObjectAddressLastName }}</span
                >
                <span class="address-additional"
                  >{{ item.ObjectAddressStreet }}, <br />
                  {{ item.ObjectAddressZipCode }} {{ item.ObjectAddressCity }}</span
                >
              </h4>
            </h4>
            <h4 *ngIf="showMandantName" class="mandant-name">
              <span>{{ item.MandatorId | siblingsListMandator : masterData.Mandators }}</span>
            </h4>
            <h4 class="owner">
              <span class="text-wrap">{{ item | ownerDisplayName }}</span>
            </h4>
            <h4 class="stage">
              <span class="text-wrap">{{ item.Stage }}</span>
            </h4>
            <h4 class="status">
              <span class="text-wrap">{{ item.Status }}</span>
            </h4>
            <h4 class="modifiedDate">
              <span class="text-wrap">{{ item.ModifiedDate | date : 'dd.MM.yy' }}</span>
            </h4>
            <h4 class="mandator">
              <span class="text-wrap">{{
                item.MandatorId | siblingsListMandator : masterData.Mandators
              }}</span>
            </h4>
          </a>
          <helion-ui-divider *ngIf="!last"></helion-ui-divider>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </helion-ui-menu>
</ng-template>
