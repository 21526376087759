import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DirectoryPersonViewModel,
  DirectoryEmailViewModel,
  DirectoryPhoneViewModel,
} from '../../../../../shared/apis/advis';
import { BaseContactFormDirective } from '../../../../../lead/common/base/BaseContactFormDirective';
import { DomSanitizer } from '@angular/platform-browser';
import { EnumService, IEnumData } from '../../../../../shared/services/enum.service';
import { NgForm } from '@angular/forms';
import { LoadingModalService } from '../../../../../shared/services/loading-modal.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-person-contact-form',
  templateUrl: './person-contact-form.component.html',
  styleUrls: ['./person-contact-form.component.scss'],
})
export class PersonContactFormComponent extends BaseContactFormDirective implements OnInit {
  @Input() public person: DirectoryPersonViewModel;
  @Input() public titleItems: IEnumData[] = [];
  @ViewChild('form', { static: false }) public form: NgForm;

  public selectedTitleItem: string;
  public selectedLanguageItem: string;
  public selectedEmailItems: string[];
  public selectedPhoneItems: string[];

  constructor(
    dialogService: DialogService,
    loadingModalService: LoadingModalService,
    notification: NotificationService,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(sanitizer, translate, dialogService, notification, loadingModalService, enumService);
  }

  public setPersonTitle(title: IEnumData): void {
    if (typeof title === 'string') {
      return;
    }
    this.person.Title = title.key;
    this.selectedTitleItem = title.display;
    this.valueChange.emit(true);
  }

  public setPersonLanguage(language: IEnumData): void {
    if (typeof language === 'string') {
      return;
    }
    this.person.Language = language.key;
    this.selectedLanguageItem = language.display;
    this.valueChange.emit(true);
  }

  public setAdditionalEmailType(index: number, type: DirectoryEmailViewModel.EmailTypeEnum): void {
    this.person.Emails[index].EmailType = type;
    this.valueChange.emit(true);
  }

  public setAdditionalPhoneType(index: number, type: DirectoryPhoneViewModel.PhoneTypeEnum): void {
    this.person.Phones[index].PhoneType = type;
    this.valueChange.emit(true);
  }

  public ngOnInit(): void {
    this.selectedTitleItem = this.titleItems.find(
      title => title.key === this.person.Title
    )?.display;
    this.selectedLanguageItem = this.person.Language;
  }

  protected saveEntry(): void {}
}
