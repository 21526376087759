/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PositionOrGroupViewModel } from './positionOrGroupViewModel';
import { RebateViewModel } from './rebateViewModel';
import { TarifViewModel } from './tarifViewModel';
import { VariantDynamicPropertyViewModel } from './variantDynamicPropertyViewModel';


export interface VariantViewModel { 
    Id?: number;
    Title?: string;
    CustomText?: string;
    DescriptionText?: string;
    ConditionsText?: string;
    OrderReference?: string;
    ProductlineId?: number;
    Currency?: string;
    CurrencyExchangeRate?: number;
    IsReadOnly?: boolean;
    RebuildDisabled?: boolean;
    BomRebuildInstruction?: VariantViewModel.BomRebuildInstructionEnum;
    Language?: VariantViewModel.LanguageEnum;
    BasePositionsOrGroups?: Array<PositionOrGroupViewModel>;
    OptionPositionsOrGroups?: Array<PositionOrGroupViewModel>;
    Tarifs?: Array<TarifViewModel>;
    Rebates?: Array<RebateViewModel>;
    DynamicProperties?: Array<VariantDynamicPropertyViewModel>;
    NettoPriceWithoutRebate?: number;
    NettoPrice?: number;
    TotalRebate?: number;
    Vat?: number;
    VatFactor?: number;
    GrossMargin?: number;
    BruttoPrice?: number;
    SumSelectedBasicPositions?: number;
    SumSelectedOptions?: number;
}
export namespace VariantViewModel {
    export type BomRebuildInstructionEnum = 'RebuildAutomatically' | 'RebuildManually' | 'NeverRebuild';
    export const BomRebuildInstructionEnum = {
        RebuildAutomatically: 'RebuildAutomatically' as BomRebuildInstructionEnum,
        RebuildManually: 'RebuildManually' as BomRebuildInstructionEnum,
        NeverRebuild: 'NeverRebuild' as BomRebuildInstructionEnum
    };
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
}
