/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PatchVariantRequest { 
    Title?: string;
    Currency?: PatchVariantRequest.CurrencyEnum;
    CurrencyExchangeRate?: number;
    CustomText?: string;
    DescriptionText?: string;
    ConditionsText?: string;
    OrderReference?: string;
    RebuildDisabled?: boolean;
}
export namespace PatchVariantRequest {
    export type CurrencyEnum = 'EUR' | 'CHF' | 'USD';
    export const CurrencyEnum = {
        EUR: 'EUR' as CurrencyEnum,
        CHF: 'CHF' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum
    };
}
