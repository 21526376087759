import { ApplicationRef, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { first, tap } from 'rxjs/operators';

@Injectable()
export class AppMonitoringService {
  appInsights: ApplicationInsights;

  constructor(private appRef: ApplicationRef) {
    this.loadAppInsights();
  }

  loadAppInsights(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        disableAjaxTracking: true,
        maxAjaxCallsPerView: 0,
      },
    });

    this.appRef.isStable
      .pipe(
        first(isStable => isStable === true),
        tap(() => console.log('App is stable now'))
      )
      .subscribe(() => this.appInsights.loadAppInsights());
  }

  logPageView(name?: string, url?: string): void {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error | HttpErrorResponse, severityLevel?: number): void {
    let httpException: Error;
    let properties: any = {};
    if (exception instanceof HttpErrorResponse) {
      httpException = {
        name: 'HttpErrorResponse',
        message: `${exception.message}`,
      };
      properties = {
        httpErrorResponseBody: JSON.stringify(exception.error),
        httpErrorResponseProblemId: `pc-${exception.status}`,
      };
    }

    this.appInsights.trackException({
      exception: httpException || exception,
      severityLevel: severityLevel,
      properties: properties,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
