import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEvent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  Autosave,
  Base64UploadAdapter,
  Bold,
  ClassicEditor,
  Editor,
  type EditorConfig,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from 'ckeditor5';
import { AutosaveService, AutosaveStatusEnum } from './autosave.service';
import { LibraryModule } from '@sitewerk/theia-ui-lib';
// @ts-ignore-expected https://github.com/ckeditor/ckeditor5/issues/16535
import deTranslations from 'ckeditor5/translations/de.js';
// @ts-ignore-expected
import frTranslations from 'ckeditor5/translations/fr.js';
// @ts-ignore-expected
import itTranslations from 'ckeditor5/translations/it.js';
// @ts-ignore-expected
import enTranslations from 'ckeditor5/translations/en.js';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LicenseManagementService } from '../license-management-service/license-management-service';
import { PermissionService } from '../../shared/services/permission.service';

const AUTOSAVE_INTERVAL = 5000;

@Component({
  selector: 'pc-text-editor',
  standalone: true,
  imports: [CommonModule, CKEditorModule, LibraryModule],
  templateUrl: './pc-text-editor.component.html',
  styleUrls: ['./pc-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

// If you have problems with two-way binding of [(text)] property, use one way binding to set [initialData] first and then listen to (textChange) event to get the updated text.
// alternatively you may set the data via the setEditorData method
export class PcTextEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public disabled: boolean;
  @Input() public text: string;
  @Input() public initialData: string;
  @Input() public placeholder: string;
  @Input() public shouldNotGroupWhenFull: boolean;
  @Input() public cyAttr: string = '';

  // controls whether the editor should be loaded with a reduced toolbar feature set or not
  @Input() public reducedMode: boolean = false;

  protected readonly AutosaveStatusEnum = AutosaveStatusEnum;
  @Output() public autoSave: EventEmitter<string> = new EventEmitter();
  @Output() public textChange: EventEmitter<string> = new EventEmitter();
  @Output() public editorCreated: EventEmitter<PcTextEditorComponent> = new EventEmitter();

  @ViewChild('editor', { static: false }) editorComponent: any;

  public isLayoutReady = false;
  public Editor = ClassicEditor;
  public config: EditorConfig = {};
  public editorId: string;
  private subscriptions: Subscription = new Subscription();

  private canEditNotes: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public autosaveService: AutosaveService,
    private translate: TranslateService,
    private licenseManagementService: LicenseManagementService,
    permissions: PermissionService
  ) {
    this.editorId = crypto.randomUUID();
    this.canEditNotes = permissions.userPermissions.CanEditNotes;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialData) {
      this.getEditor().disableReadOnlyMode(this.editorId);
    } else if (this.disabled || !this.canEditNotes) {
      this.getEditor().enableReadOnlyMode(this.editorId);
    }
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.licenseManagementService.getLicenseKey$('textEditor').subscribe(licenseKey => {
        if (licenseKey) {
          this.initialize(licenseKey);
        } else {
          console.warn('No license key found for text editor, continuing without license.');
          this.initialize('not-a-license-key');
        }
      })
    );
  }

  private initialize(licenseKey: string) {
    this.setupConfig(licenseKey);
    this.isLayoutReady = true;
    this.changeDetector.detectChanges();
    this.editorCreated.emit(this);

    if (this.disabled || !this.canEditNotes) {
      this.getEditor().enableReadOnlyMode(this.editorId);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onTextChange({ editor }: ChangeEvent): void {
    this.textChange.emit(editor.getData());
  }

  public setEditorData(data: string) {
    this.getEditor().setData(data);
  }

  public saveData(data: any) {
    this.autoSave.emit(data);
    return new Promise(resolve => {
      resolve(data);
    });
  }

  public focusEditor(): void {
    this.getEditor().focus();
  }

  public getEditor(): Editor {
    return this.editorComponent.editorInstance;
  }

  public getCurrentUILanguage(): string {
    return this.translate.currentLang;
  }

  protected setupConfig(licenseKey: string): void {
    this.reducedMode
      ? (this.config = this.getReducedConfig(licenseKey))
      : (this.config = this.getExtendendConfig(licenseKey));
  }

  private getReducedConfig(licenseKey: string): EditorConfig {
    return {
      licenseKey: licenseKey,
      placeholder: this.placeholder
        ? this.placeholder
        : this.translate.instant('COMMON.PLACEHOLDER_TEXT_INPUT'),
      initialData: this.initialData,
      toolbar:
        this.disabled || !this.canEditNotes
          ? {
              items: [],
              shouldNotGroupWhenFull: true,
            }
          : {
              items: ['heading', '|', 'undo', 'redo'],
              shouldNotGroupWhenFull: this.shouldNotGroupWhenFull,
            },
      translations: [itTranslations, frTranslations, deTranslations, enTranslations],
      language: { ui: this.getCurrentUILanguage() },
      plugins: [Heading, Undo, Essentials],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6',
          },
        ],
      },
      autosave: {
        waitingTime: AUTOSAVE_INTERVAL,
        save: () => this.saveData(this.editorId),
      },
    };
  }

  private getExtendendConfig(licenseKey: string): EditorConfig {
    return {
      licenseKey: licenseKey,
      placeholder: this.placeholder
        ? this.placeholder
        : this.translate.instant('COMMON.PLACEHOLDER_TEXT_INPUT'),
      initialData: this.initialData,
      toolbar:
        this.disabled || !this.canEditNotes
          ? {
              items: [],
              shouldNotGroupWhenFull: true,
            }
          : {
              items: [
                'heading',
                '|',
                'fontFamily',
                'fontSize',
                'fontColor',
                '|',
                'bold',
                'italic',
                '|',
                'insertTable',
                'bulletedList',
                'numberedList',
                'todoList',
                '|',
                'outdent',
                'indent',
                'fontBackgroundColor',
                'strikethrough',
                'subscript',
                'superscript',
                'highlight',
                '|',
                'link',
                'insertImage',
                'mediaEmbed',
                '|',
                'undo',
                'redo',
              ],
              shouldNotGroupWhenFull: this.shouldNotGroupWhenFull,
            },
      translations: [itTranslations, frTranslations, deTranslations, enTranslations],
      language: { ui: this.getCurrentUILanguage() },
      plugins: [
        AccessibilityHelp,
        Autoformat,
        AutoImage,
        Autosave,
        Base64UploadAdapter,
        Bold,
        Essentials,
        Heading,
        Highlight,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        SelectAll,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextTransformation,
        TodoList,
        Underline,
        Undo,
        FontFamily,
        FontSize,
        FontColor,
        FontBackgroundColor,
        Strikethrough,
        Subscript,
        Superscript,
      ],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6',
          },
        ],
      },
      image: {
        toolbar: [
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage',
        ],
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file',
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableProperties',
          'tableCellProperties',
        ],
      },
      autosave: {
        waitingTime: AUTOSAVE_INTERVAL,
        save: () => this.saveData(this.editorId),
      },
    };
  }
}
