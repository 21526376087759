/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AddRebateRequest { 
    /**
     * A title for the discount. Should be used for displaying purposes.
     */
    Title?: string;
    /**
     * An optional field for saving comments
     */
    Comment?: string;
    RebateType?: AddRebateRequest.RebateTypeEnum;
    /**
     * The rebate of a discount. Its type is defined by RebateType
     */
    Rebate?: number;
    /**
     * The order of the discount. This is for display purposes only. Discounts do not cascade. The default behavior is that in case this is not being sent or '0', we will add up the order of existing discounts.
     */
    Order?: number;
}
export namespace AddRebateRequest {
    export type RebateTypeEnum = 'Percent' | 'Netto' | 'Brutto';
    export const RebateTypeEnum = {
        Percent: 'Percent' as RebateTypeEnum,
        Netto: 'Netto' as RebateTypeEnum,
        Brutto: 'Brutto' as RebateTypeEnum
    };
}
