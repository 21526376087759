import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  AdditionalAddressTypeViewModel,
  DirectoryCompanyViewModel,
  DirectoryEmailViewModel,
  DirectoryPhoneViewModel,
} from '../../../../../shared/apis/advis';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseContactFormDirective } from '../../../../../lead/common/base/BaseContactFormDirective';
import { NgForm } from '@angular/forms';
import { LoadingModalService } from '../../../../../shared/services/loading-modal.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { EnumService } from '../../../../../shared/services/enum.service';

@Component({
  selector: 'pc-company-contact-form',
  templateUrl: './company-contact-form.component.html',
  styleUrls: ['./company-contact-form.component.scss'],
})
export class CompanyContactFormComponent extends BaseContactFormDirective implements OnChanges {
  @ViewChild('form', { static: false }) public form: NgForm;
  @Input() public company: DirectoryCompanyViewModel;

  public selectedEmailItems: string[];
  public selectedPhoneItems: string[];

  constructor(
    dialogService: DialogService,
    loadingModalService: LoadingModalService,
    notification: NotificationService,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(sanitizer, translate, dialogService, notification, loadingModalService, enumService);
  }

  public onCompanyNameUpdate(name: string): void {
    this.company.Name = name;
    this.valueChange.emit(true);
  }

  public setAdditionalEmailType(index: number, type: AdditionalAddressTypeViewModel): void {
    this.company.Emails[index].EmailType = type.Key as DirectoryEmailViewModel.EmailTypeEnum;
    if (this.selectedEmailItems.length > index) {
      this.selectedEmailItems[index] = type.Translation;
    }
    this.valueChange.emit(true);
  }

  public setAdditionalPhoneType(index: number, type: AdditionalAddressTypeViewModel): void {
    this.company.Phones[index].PhoneType = type.Key as DirectoryPhoneViewModel.PhoneTypeEnum;
    if (this.selectedPhoneItems.length > index) {
      this.selectedPhoneItems[index] = type.Translation;
    }
    this.valueChange.emit(true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      this.selectedEmailItems = (
        changes.company.currentValue as DirectoryCompanyViewModel
      ).Emails.map(
        email => this.emailTypes.filter(type => type.Key === email.EmailType)[0].Translation
      );
      this.selectedPhoneItems = (
        changes.company.currentValue as DirectoryCompanyViewModel
      ).Phones.map(
        phone => this.phoneTypes.filter(type => type.Key === phone.PhoneType)[0].Translation
      );

      this.initEmptyEmailAndPhone(this.company);
    }
  }

  protected saveEntry(): void {
    this.resetEmptyEmailAndPhone(this.company);
    this.valueChange.emit(true);
  }
}
