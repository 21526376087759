/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Representation of a flat view of leads, as they are displayed in the leadListView overview.
 */
export interface SearchAddressBookListViewModel { 
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page
     */
    PageSize?: number;
    SortField?: SearchAddressBookListViewModel.SortFieldEnum;
    SortOrder?: SearchAddressBookListViewModel.SortOrderEnum;
    ContactId?: number;
    ContactType?: number;
    MandatorId?: number;
    PersonAddressId?: number;
    FirstName?: string;
    LastName?: string;
    PersonStreet?: string;
    PersonZipCode?: string;
    PersonCity?: string;
    PersonPhones?: string;
    PersonEmails?: string;
    CompanyAddressId?: number;
    CompanyName?: string;
    AdditionalCompanyName?: string;
    CompanyStreet?: string;
    CompanyZipCode?: string;
    CompanyCity?: string;
    CompanyPhones?: string;
    CompanyEmails?: string;
}
export namespace SearchAddressBookListViewModel {
    export type SortFieldEnum = 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton';
    export const SortFieldEnum = {
        None: 'None' as SortFieldEnum,
        LeadId: 'LeadId' as SortFieldEnum,
        LeadStage: 'LeadStage' as SortFieldEnum,
        LeadStatus: 'LeadStatus' as SortFieldEnum,
        Created: 'Created' as SortFieldEnum,
        OwnerId: 'OwnerId' as SortFieldEnum,
        AccessType: 'AccessType' as SortFieldEnum,
        MandatorId: 'MandatorId' as SortFieldEnum,
        OrganisationUnitId: 'OrganisationUnitId' as SortFieldEnum,
        PersonContactCity: 'PersonContactCity' as SortFieldEnum,
        PersonLastName: 'PersonLastName' as SortFieldEnum,
        PersonFirstName: 'PersonFirstName' as SortFieldEnum,
        PersonContactStreet: 'PersonContactStreet' as SortFieldEnum,
        PersonContactZipCode: 'PersonContactZipCode' as SortFieldEnum,
        ObjectAddressCity: 'ObjectAddressCity' as SortFieldEnum,
        ObjectAddressLastName: 'ObjectAddressLastName' as SortFieldEnum,
        ObjectAddressFirstName: 'ObjectAddressFirstName' as SortFieldEnum,
        ObjectAddressStreet: 'ObjectAddressStreet' as SortFieldEnum,
        ObjectAddressZipCode: 'ObjectAddressZipCode' as SortFieldEnum,
        Rating: 'Rating' as SortFieldEnum,
        PersonLanguage: 'PersonLanguage' as SortFieldEnum,
        TradeType: 'TradeType' as SortFieldEnum,
        HasOpenTasks: 'HasOpenTasks' as SortFieldEnum,
        Tag: 'Tag' as SortFieldEnum,
        WonDate: 'WonDate' as SortFieldEnum,
        ProjectManagerId: 'ProjectManagerId' as SortFieldEnum,
        ProjectOrderNumber: 'ProjectOrderNumber' as SortFieldEnum,
        ProjectChecklistCompletionDefinition: 'ProjectChecklistCompletionDefinition' as SortFieldEnum,
        ProjectChecklistCompletionPlanning: 'ProjectChecklistCompletionPlanning' as SortFieldEnum,
        ProjectChecklistCompletionSourcing: 'ProjectChecklistCompletionSourcing' as SortFieldEnum,
        ProjectChecklistCompletionPreparation: 'ProjectChecklistCompletionPreparation' as SortFieldEnum,
        ProjectChecklistCompletionInstallation: 'ProjectChecklistCompletionInstallation' as SortFieldEnum,
        ProjectChecklistCompletionClosure: 'ProjectChecklistCompletionClosure' as SortFieldEnum,
        PersonPhoneNumber: 'PersonPhoneNumber' as SortFieldEnum,
        Tracers: 'Tracers' as SortFieldEnum,
        Origin: 'Origin' as SortFieldEnum,
        Language: 'Language' as SortFieldEnum,
        OwnerFirstName: 'OwnerFirstName' as SortFieldEnum,
        MandatorShortName: 'MandatorShortName' as SortFieldEnum,
        ExecutingOrgUnit: 'ExecutingOrgUnit' as SortFieldEnum,
        ProjectManagerFirstName: 'ProjectManagerFirstName' as SortFieldEnum,
        PriorityDate: 'PriorityDate' as SortFieldEnum,
        ProjectStartInstallationDate: 'ProjectStartInstallationDate' as SortFieldEnum,
        ProjectAdditionalComponents: 'ProjectAdditionalComponents' as SortFieldEnum,
        ProjectSize: 'ProjectSize' as SortFieldEnum,
        ProjectType: 'ProjectType' as SortFieldEnum,
        FinancingType: 'FinancingType' as SortFieldEnum,
        Canton: 'Canton' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
}
