import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as RootReducer from '../../shared/states/index';
import { IDetailResolve, LeadDetailResolver } from './lead-detail.resolver';
import { RightsService } from '../../shared/services/rights.service';
import { LeadUtil } from '../../shared/utils/lead.util';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { LeadCompositeViewModel } from '../../shared/apis/advis';
import { flatMap, take } from 'rxjs/operators';
import { PermissionService } from '../../shared/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class LeadDetailLoadedResolver {
  constructor(
    private store: Store<RootReducer.IState>,
    private leadDetailResolver: LeadDetailResolver,
    private permissionsService: PermissionService,
    private rights: RightsService
  ) {
    // empty
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IDetailResolve> {
    return this.store
      .select((storeState: RootReducer.IState) => storeState.lead.leadsDetailLoaded)
      .pipe(
        flatMap((loaded: boolean) => {
          if (loaded) {
            return this.store
              .select((storeState: RootReducer.IState) => storeState.lead.leadsDetail)
              .pipe(
                flatMap((lead: LeadCompositeViewModel) => {
                  if (lead.Lead.Id === Number(route.parent.params.id)) {
                    return this.store.select<IPrincipal>(RootReducer.getAuthPrincipal).pipe(
                      flatMap((principal: IPrincipal) => {
                        const answer: IDetailResolve = {
                          isResponsible: LeadUtil.isResponsible(lead.Lead, principal),
                          isOwner: LeadUtil.isOwner(lead.Lead, principal),
                          isDelegate: LeadUtil.isDelegate(lead.Lead, principal),
                          isReadOnly: this.rights.getLeadRights().isReadOnly(lead.Lead),
                          canEditProjectData: LeadUtil.canEditProjectData(
                            lead.Lead,
                            principal,
                            this.permissionsService.userPermissions
                          ),
                        };
                        return of(answer);
                      })
                    );
                  }
                  return this.leadDetailResolver.resolve(route);
                })
              );
          } else {
            return this.leadDetailResolver.resolve(route);
          }
        }),
        take(1)
      );
  }
}
