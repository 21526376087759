/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EnergyUnitViewModel { 
    Key?: string;
    Unit?: EnergyUnitViewModel.UnitEnum;
    /**
     * \"Caloric value\" or the conversion factor for this unit to convert from kwh  [unit] * ConversionFactorFromKwh = kwh  kwh = [unit] / ConversionFactorFromKwh
     */
    ConversionFactorFromKwh?: number;
    Translation?: string;
}
export namespace EnergyUnitViewModel {
    export type UnitEnum = 'Kwh' | 'Liter' | 'M3' | 'Ster' | 'Kg';
    export const UnitEnum = {
        Kwh: 'Kwh' as UnitEnum,
        Liter: 'Liter' as UnitEnum,
        M3: 'M3' as UnitEnum,
        Ster: 'Ster' as UnitEnum,
        Kg: 'Kg' as UnitEnum
    };
}
