import { IViewSettingBase } from './view-setting-base.model';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export class AddressbookLeadOverviewSettingsModel
  implements IViewSettingBase<AddressbookLeadOverviewSettingsModel>
{
  public filter: AddressbookOverviewFilterVsModel = new AddressbookOverviewFilterVsModel();
  public filterVisible: boolean = true;
  public tableSort: any[] = [];
  public page: number = 0;
  public panel: LeadOverviewPanelModel = new LeadOverviewPanelModel();
  public activeView: number;

  clone(): AddressbookLeadOverviewSettingsModel {
    const model: AddressbookLeadOverviewSettingsModel = new AddressbookLeadOverviewSettingsModel();
    model.filter = this.filter.clone();
    model.filterVisible = this.filterVisible;
    model.tableSort = this.tableSort;
    model.page = this.page;
    model.panel = this.panel.clone();
    model.activeView = isNullOrUndefined(this.activeView) ? 0 : this.activeView;

    return model;
  }
}

export class LeadOverviewPanelModel {
  public panelExpanded: boolean = true;

  clone(): LeadOverviewPanelModel {
    const panel: LeadOverviewPanelModel = new LeadOverviewPanelModel();
    panel.panelExpanded = this.panelExpanded;

    return panel;
  }
}

export class AddressbookOverviewFilterVsModel
  implements IViewSettingBase<AddressbookOverviewFilterVsModel>
{
  name: string = '';
  additionalName: string = '';
  firstName: string = '';
  lastName: string = '';
  street: string = '';
  zipCity: string = '';
  zip: string = '';
  city: string = '';
  phoneNumber: string = '';
  email: string = '';
  mandators: number[] = [];
  contactId: number = undefined;
  externalManagedContactId: string = '';
  dataState: string = '';

  clone(): AddressbookOverviewFilterVsModel {
    const filter: AddressbookOverviewFilterVsModel = new AddressbookOverviewFilterVsModel();
    filter.name = this.name;
    filter.additionalName = this.additionalName;
    filter.firstName = this.firstName;
    filter.lastName = this.lastName;
    filter.street = this.street;
    filter.zipCity = this.zipCity;
    filter.zip = this.zip;
    filter.city = this.city;
    filter.mandators = this.mandators;
    filter.email = this.email;
    filter.phoneNumber = this.phoneNumber;
    filter.externalManagedContactId = this.externalManagedContactId;
    filter.dataState = this.dataState;
    filter.contactId = this.contactId;

    return filter;
  }
}
