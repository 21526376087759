import {
  LeadViewModel,
  MasterDataMandatorViewModel,
  MasterDataOrganisationUnitViewModel,
  VariantCompositeViewModel,
} from '../apis/advis';
import { IPrincipal } from '../interfaces/principal.interface';
import { CommonUtil } from './common.util';
import { isNullOrUndefined } from './isNullOrUndefined';

const NOT_AVAILABLE: string = CommonUtil.getNotAvailable();

enum SupportTemplateVarE {
  VERSION = <any>'$$VERSION$$',
  DEVICE_INFO = <any>'$$DEVICE_INFO$$',

  PRINCIPAL_EMAIL = <any>'$$PRINCIPAL_EMAIL$$',
  PRINCIPAL_ROLE = <any>'$$PRINCIPAL_ROLE$$',
  PRINCIPAL_MANDANT = <any>'$$PRINCIPAL_MANDANT$$',
  PRINCIPAL_PHONE = <any>'$$PRINCIPAL_PHONE$$',

  LEAD_ID = <any>'$$LEAD_ID$$',
  LEAD_NAME = <any>'$$LEAD_NAME$$',
  LEAD_MANDANT = <any>'$$LEAD_MANDANT$$',
  LEAD_OU = <any>'$$LEAD_OU$$',
  LEAD_PERSON_ID = <any>'$$LEAD_PERSON_ID$$',
  LEAD_PERSON_EMAIL = <any>'$$LEAD_PERSON_EMAIL$$',
  LEAD_TRADE_TYPE = <any>'$$LEAD_TRADE_TYPE$$',

  VARIANT_ID = <any>'$$VARIANT_ID$$',
  VARIANT_TITLE = <any>'$$VARIANT_TITLE$$',

  CURRENT_URL = <any>'$$CURRENT_URL$$',
}

export function fillTemplate(
  template: string,
  version: string,
  deviceInfo: string,
  principal: IPrincipal,
  phone: string,
  lead: LeadViewModel,
  variantComposite: VariantCompositeViewModel,
  mandantMap: Map<number, MasterDataMandatorViewModel>,
  currentUrl: string,
  userRole: string
): string | undefined {
  template = template.replace(
    SupportTemplateVarE.VERSION.toString(),
    getTextOrNotAvailable(version)
  );
  template = template.replace(
    SupportTemplateVarE.DEVICE_INFO.toString(),
    getTextOrNotAvailable(deviceInfo)
  );

  template = template.replace(
    SupportTemplateVarE.PRINCIPAL_EMAIL.toString(),
    getTextOrNotAvailable(principal.getUser())
  );
  template = template.replace(
    SupportTemplateVarE.PRINCIPAL_ROLE.toString(),
    getTextOrNotAvailable(userRole)
  );
  template = template.replace(
    SupportTemplateVarE.PRINCIPAL_MANDANT.toString(),
    getTextOrNotAvailable(principal.getMandant())
  );
  template = template.replace(
    SupportTemplateVarE.PRINCIPAL_PHONE.toString(),
    getTextOrNotAvailable(phone)
  );

  template = template.replace(
    SupportTemplateVarE.LEAD_ID.toString(),
    getTextOrNotAvailable(lead ? lead.Id : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_NAME.toString(),
    getTextOrNotAvailable(
      lead
        ? lead.ContactAddress?.Person?.LastName + ' ' + lead.ContactAddress?.Person?.FirstName
        : undefined
    )
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_MANDANT.toString(),
    getTextOrNotAvailable(lead ? getMandantStr(mandantMap, lead.MandantId) : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_OU.toString(),
    getTextOrNotAvailable(
      lead ? getOuStr(mandantMap, lead.MandantId, lead.OrganizationalUnitId) : undefined
    )
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_PERSON_ID.toString(),
    getTextOrNotAvailable(lead ? lead.ContactAddress?.Id : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_PERSON_EMAIL.toString(),
    getTextOrNotAvailable(lead ? lead.ContactAddress?.Person?.PrimaryEmail?.Email : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.LEAD_TRADE_TYPE.toString(),
    getTextOrNotAvailable(lead ? lead.TradeTypeCode : undefined)
  );

  template = template.replace(
    SupportTemplateVarE.VARIANT_ID.toString(),
    getTextOrNotAvailable(variantComposite ? variantComposite.GenericVariantData?.Id : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.VARIANT_TITLE.toString(),
    getTextOrNotAvailable(variantComposite ? variantComposite.GenericVariantData?.Title : undefined)
  );
  template = template.replace(
    SupportTemplateVarE.CURRENT_URL.toString(),
    getTextOrNotAvailable(currentUrl)
  );

  return template;
}

function getTextOrNotAvailable(txt: string | number | undefined): string {
  if (isNullOrUndefined(txt)) {
    return NOT_AVAILABLE;
  }
  return '' + txt;
}

function getMandantStr(
  mandantMap: Map<number, MasterDataMandatorViewModel>,
  mandantId: number | undefined
): string | undefined {
  if (mandantId === undefined) {
    return NOT_AVAILABLE;
  }
  if (mandantMap.has(mandantId)) {
    return mandantMap.get(mandantId)?.Name;
  }
  return NOT_AVAILABLE;
}

function getOuStr(
  mandantMap: Map<number, MasterDataMandatorViewModel>,
  mandantId: number | undefined,
  ouId: number | undefined
): string | undefined {
  if (mandantId === undefined || ouId === undefined) {
    return NOT_AVAILABLE;
  }

  if (mandantMap.has(mandantId)) {
    const ou = mandantMap
      .get(mandantId)
      ?.OrganisationUnits?.find((ouVm: MasterDataOrganisationUnitViewModel) => {
        return ouVm.Id === ouId;
      });

    if (ou) {
      return ou.Code;
    }
  }
  return NOT_AVAILABLE;
}
