import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataMandatorViewModel } from 'app/shared/apis/advis';
import { CommonUtil } from 'app/shared/utils/common.util';

@Pipe({
  name: 'siblingsListMandator',
})
export class SiblingsListMandatorPipe implements PipeTransform {
  public transform(mandatorId: unknown, mandators: MasterDataMandatorViewModel[]): string {
    if (mandators) {
      const foundMandantVm: MasterDataMandatorViewModel = mandators.find(
        (mandantVm: MasterDataMandatorViewModel) => {
          return mandantVm.Id === mandatorId;
        }
      );

      if (foundMandantVm) {
        return foundMandantVm.Code;
      }
    }
    return CommonUtil.getNotAvailable();
  }
}
