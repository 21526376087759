<div
  fxLayout.gt-md="row wrap"
  fxLayoutAlign.gt-md="space-between start"
  fxLayout.lt-lg="column wrap"
  class="address-card__row">
  <div fxFlex.gt-lg="0 0 40" fxFlex.gt-md="0 0 calc(50% - 32px)" fxFlex.lt-lg="0 0 100">
    <pc-address
      [primary]="true"
      [isReadOnly]="isReadOnly"
      [masterData]="masterData"
      [regionalInformation]="{
        regionalInformation: lead.RegionalInformation?.ContactPersonRegionalInformation,
        tradeTypeCode: lead.Lead.TradeTypeCode
      }"
      [address]="lead.Lead.ContactAddress ? lead.Lead.ContactAddress : emptyContactAddress"
      [title]="'LEAD_DETAIL.CONTACT_ADDR'"
      (onEdit)="onEditPerson()"
      data-cy="leadDetailPersonAddress"></pc-address>
  </div>

  <div fxFlex.gt-lg="0 0 40" fxFlex.gt-md="0 0 calc(50% - 32px)" fxFlex.lt-lg="0 0 100">
    <pc-contact
      [primary]="true"
      [isReadOnly]="isReadOnly"
      [masterData]="masterData"
      [allExternalManagedAddressConfig]="allExternalManagedAddressConfig"
      [regionalInformation]="{
        regionalInformation: lead.RegionalInformation?.ObjectRegionalInformation,
        tradeTypeCode: lead?.Lead?.TradeTypeCode
      }"
      [contact]="lead.Lead.ObjectAddress"
      [title]="'LEAD_DETAIL.OBJECT_ADDR'"
      (onEdit)="onEditObjectAddress()"></pc-contact>
  </div>
</div>
<div *ngIf="externalManagedAddresses.length > 0">
  <helion-ui-divider dividerColor="gray" margin="16px"></helion-ui-divider>

  <div
    fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="space-between start"
    fxLayout.lt-lg="column wrap"
    class="address-card__row">
    <div
      fxFlex.gt-lg="0 0 40"
      fxFlex.gt-md="0 0 calc(50% - 32px)"
      fxFlex.lt-lg="0 0 100"
      *ngFor="let externalManagedAddress of externalManagedAddresses">
      <pc-contact
        [isAccordion]="true"
        [isReadOnly]="isReadOnly"
        [masterData]="masterData"
        [allExternalManagedAddressConfig]="allExternalManagedAddressConfig"
        [contact]="externalManagedAddress"
        [title]="externalManagedAddress.localizedTitle"
        (onEdit)="onEditLeadAddress(externalManagedAddress)"></pc-contact>
    </div>
  </div>
</div>

<helion-ui-divider dividerColor="gray" margin="16px"></helion-ui-divider>

<div
  fxLayout.gt-md="row wrap"
  fxLayoutAlign.gt-md="space-between start"
  fxLayout.lt-lg="column wrap"
  class="address-card__row">
  <div fxFlex.gt-lg="0 0 40" fxFlex.gt-md="0 0 calc(50% - 32px)" fxFlex.lt-lg="0 0 100">
    <pc-contact
      [isAccordion]="true"
      [isReadOnly]="isReadOnly"
      [masterData]="masterData"
      [allExternalManagedAddressConfig]="allExternalManagedAddressConfig"
      [contact]="lead.Lead.DebitorAddress"
      [title]="debitorTitle"
      (onEdit)="onEditDebitorAddress()"></pc-contact>
  </div>

  <div
    fxFlex.gt-lg="0 0 40"
    fxFlex.gt-md="0 0 calc(50% - 32px)"
    fxFlex.lt-lg="0 0 100"
    *ngFor="let additionalAddress of additionalAddresses">
    <pc-contact
      [isAccordion]="true"
      [isReadOnly]="isReadOnly"
      [masterData]="masterData"
      [allExternalManagedAddressConfig]="allExternalManagedAddressConfig"
      [contact]="additionalAddress"
      [title]="additionalAddress.localizedTitle"
      (onEdit)="onEditLeadAddress(additionalAddress)"></pc-contact>
  </div>
</div>
