<section class="dialog">
  <h2 class="dialog-title">{{ 'VARIANT_OFFER_DOWNLOAD.CHOOSE_DOCUMENT' | translate }}</h2>

  <div class="dialog-content">
    <div *ngIf="documentTemplates">
      <div class="text-warning" *ngIf="data.warningText && !selectedTemplateId">
        {{ data.warningText }}
      </div>

      <pc-choose-template-for-download
        [(selectedTemplateId)]="selectedTemplateId"
        [preselection]="preselection"
        [documentTemplates]="documentTemplates">
      </pc-choose-template-for-download>
    </div>

    <pc-attachment-selector
      *ngIf="attachments"
      [attachments]="attachments"
      (onAttachmentChange)="selectedAttachments = $event"
      (onSelectedDocumentType)="selectedDocumentType = $event">
    </pc-attachment-selector>
  </div>

  <div class="dialog-actions">
    <button (click)="cancel()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button [attr.fill-type]="'contained'" (click)="download()" [disabled]="!isValid()">
      {{ 'COMMON.BTN_OK' | translate }}
    </button>
  </div>
</section>
