/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddTaskRequest } from '../model/addTaskRequest';
import { AddressSearchLeadListViewModelPageResultViewModel } from '../model/addressSearchLeadListViewModelPageResultViewModel';
import { AddressSearchListLeadRequest } from '../model/addressSearchListLeadRequest';
// @ts-ignore
import { AddressViewModel } from '../model/addressViewModel';
import { AppointmentViewModel } from '../model/appointmentViewModel';
import { AutoOfferResult } from '../model/autoOfferResult';
import { EmobilityLeadViewModel } from '../model/emobilityLeadViewModel';
import { HeatingLeadViewModel } from '../model/heatingLeadViewModel';
import { HeatingPowerCalculationRequest } from '../model/heatingPowerCalculationRequest';
import { HeatingPowerCalculationsViewModel } from '../model/heatingPowerCalculationsViewModel';
import { LeadCompositeViewModel } from '../model/leadCompositeViewModel';
import { LeadDocumentListViewModel } from '../model/leadDocumentListViewModel';
import { LeadDynamicPropertyViewModel } from '../model/leadDynamicPropertyViewModel';
import { LeadListViewModelWithSopPageResultViewModel } from '../model/leadListViewModelWithSopPageResultViewModel';
import { LeadRequest } from '../model/leadRequest';
import { LeadRequestWithSop } from '../model/leadRequestWithSop';
import { LeadSiblingsViewModel } from '../model/leadSiblingsViewModel';
import { PageResultViewModelLeadListViewModel } from '../model/pageResultViewModelLeadListViewModel';
import { PatchLeadVariantRequest } from '../model/patchLeadVariantRequest';
import { PatchLeadViewModel } from '../model/patchLeadViewModel';
import { PatchProjectDocumentationRequest } from '../model/patchProjectDocumentationRequest';
import { PatchProjectRequest } from '../model/patchProjectRequest';
import { PatchProjectWithSapDataRequest } from '../model/patchProjectWithSapDataRequest';
import { PatchVariantInvoiceRequest } from '../model/patchVariantInvoiceRequest';
import { PatchVariantInvoiceStatusRequest } from '../model/patchVariantInvoiceStatusRequest';
import { PostContactResponse } from '../model/postContactResponse';
import { PostFinalInvoiceRequest } from '../model/postFinalInvoiceRequest';
import { PostLeadRequest } from '../model/postLeadRequest';
import { PostPutDirectoryContactRequest } from '../model/postPutDirectoryContactRequest';
import { PostPutSingleContactRequest } from '../model/postPutSingleContactRequest';
import { ProjectScoreViewModel } from '../model/projectScoreViewModel';
import { PutDirectoryContactAddressRequest } from '../model/putDirectoryContactAddressRequest';
import { PutPvBuildingData } from '../model/putPvBuildingData';
import { PutSingleContactAddressRequest } from '../model/putSingleContactAddressRequest';
import { PvAutoOfferRequest } from '../model/pvAutoOfferRequest';
import { PvLeadViewModel } from '../model/pvLeadViewModel';
import { TaskListViewModel } from '../model/taskListViewModel';
import { TaskViewModel } from '../model/taskViewModel';
import { UserViewModel } from '../model/userViewModel';
import { VariantSearchResultViewModel } from '../model/variantSearchResultViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LeadService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets an existing emobility lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityLeadGetEmobilityLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<EmobilityLeadViewModel>;
    public emobilityLeadGetEmobilityLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmobilityLeadViewModel>>;
    public emobilityLeadGetEmobilityLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmobilityLeadViewModel>>;
    public emobilityLeadGetEmobilityLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling emobilityLeadGetEmobilityLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<EmobilityLeadViewModel>(`${this.basePath}/api/pc/leads/em/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the emobility lead.
     * 
     * @param leadId the id of the lead
     * @param body The request to patch the lead, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityLeadPatchEmobilityLead(leadId: number, body?: EmobilityLeadViewModel, observe?: 'body', reportProgress?: boolean): Observable<EmobilityLeadViewModel>;
    public emobilityLeadPatchEmobilityLead(leadId: number, body?: EmobilityLeadViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmobilityLeadViewModel>>;
    public emobilityLeadPatchEmobilityLead(leadId: number, body?: EmobilityLeadViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmobilityLeadViewModel>>;
    public emobilityLeadPatchEmobilityLead(leadId: number, body?: EmobilityLeadViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling emobilityLeadPatchEmobilityLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<EmobilityLeadViewModel>(`${this.basePath}/api/pc/leads/em/${encodeURIComponent(String(leadId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets heating power calculations
     * 
     * @param body the calculation request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingLeadCalculateHeatingPower(body?: HeatingPowerCalculationRequest, observe?: 'body', reportProgress?: boolean): Observable<HeatingPowerCalculationsViewModel>;
    public heatingLeadCalculateHeatingPower(body?: HeatingPowerCalculationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatingPowerCalculationsViewModel>>;
    public heatingLeadCalculateHeatingPower(body?: HeatingPowerCalculationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatingPowerCalculationsViewModel>>;
    public heatingLeadCalculateHeatingPower(body?: HeatingPowerCalculationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HeatingPowerCalculationsViewModel>(`${this.basePath}/api/pc/leads/ht/heating-power`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets an existing Heating lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingLeadGetHeatingLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<HeatingLeadViewModel>;
    public heatingLeadGetHeatingLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatingLeadViewModel>>;
    public heatingLeadGetHeatingLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatingLeadViewModel>>;
    public heatingLeadGetHeatingLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling heatingLeadGetHeatingLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<HeatingLeadViewModel>(`${this.basePath}/api/pc/leads/ht/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the Heating lead.
     * 
     * @param leadId the id of the lead
     * @param body The request to patch the lead, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingLeadPatchHeatingLead(leadId: number, body?: HeatingLeadViewModel, observe?: 'body', reportProgress?: boolean): Observable<HeatingLeadViewModel>;
    public heatingLeadPatchHeatingLead(leadId: number, body?: HeatingLeadViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatingLeadViewModel>>;
    public heatingLeadPatchHeatingLead(leadId: number, body?: HeatingLeadViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatingLeadViewModel>>;
    public heatingLeadPatchHeatingLead(leadId: number, body?: HeatingLeadViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling heatingLeadPatchHeatingLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<HeatingLeadViewModel>(`${this.basePath}/api/pc/leads/ht/${encodeURIComponent(String(leadId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an appointment to a lead
     * 
     * @param leadId the id of the lead
     * @param body the appointment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddAppointment(leadId: number, body?: AppointmentViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddAppointment(leadId: number, body?: AppointmentViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddAppointment(leadId: number, body?: AppointmentViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddAppointment(leadId: number, body?: AppointmentViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddAppointment.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/appointments`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a task to a lead
     * 
     * @param leadId the id of the lead
     * @param body the add task request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddTask(leadId: number, body?: AddTaskRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddTask(leadId: number, body?: AddTaskRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddTask(leadId: number, body?: AddTaskRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddTask(leadId: number, body?: AddTaskRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddTask.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/tasks`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an additional address to a lead from directory
     * 
     * @param leadId the id of the lead
     * @param body the contact data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressPostAdditionalDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressPostAdditionalDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressPostAdditionalDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressPostAdditionalDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressPostAdditionalDirectoryContact.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/directory`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an additional address to a lead as a single address
     * 
     * @param leadId the id of the lead
     * @param body the contact data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressPostAdditionalSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'body', reportProgress?: boolean): Observable<PostContactResponse>;
    public leadAddressPostAdditionalSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostContactResponse>>;
    public leadAddressPostAdditionalSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostContactResponse>>;
    public leadAddressPostAdditionalSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressPostAdditionalSingleContact.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostContactResponse>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/single-contact`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload new lead contact file
     * 
     * @param leadId the id of the lead
     * @param contactId the id of the contact
     * @param logoType the type of the logo
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressPutContactLogo(leadId: number, contactId: number, logoType: 'Person' | 'Company', file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressPutContactLogo(leadId: number, contactId: number, logoType: 'Person' | 'Company', file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressPutContactLogo(leadId: number, contactId: number, logoType: 'Person' | 'Company', file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressPutContactLogo(leadId: number, contactId: number, logoType: 'Person' | 'Company', file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressPutContactLogo.');
        }

        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling leadAddressPutContactLogo.');
        }

        if (logoType === null || logoType === undefined) {
            throw new Error('Required parameter logoType was null or undefined when calling leadAddressPutContactLogo.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/${encodeURIComponent(String(contactId))}/logo/${encodeURIComponent(String(logoType))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the a contact of a lead from directory (everything but the contact address)
     * 
     * @param leadId the id of the lead
     * @param body the contact data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressPutDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressPutDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressPutDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressPutDirectoryContact(leadId: number, body?: PostPutDirectoryContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressPutDirectoryContact.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/directory`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the a contact of a lead as single address (everything but the contact address)
     * 
     * @param leadId the id of the lead
     * @param body the contact data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressPutSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressPutSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressPutSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressPutSingleContact(leadId: number, body?: PostPutSingleContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressPutSingleContact.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/single-contact`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * removes a additional contact (object, debitor and contact not allowed) of a lead
     * 
     * @param leadId the id of the lead
     * @param leadAddressId the id of the leadAddress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressRemoveLeadContact(leadId: number, leadAddressId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressRemoveLeadContact(leadId: number, leadAddressId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressRemoveLeadContact(leadId: number, leadAddressId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressRemoveLeadContact(leadId: number, leadAddressId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressRemoveLeadContact.');
        }

        if (leadAddressId === null || leadAddressId === undefined) {
            throw new Error('Required parameter leadAddressId was null or undefined when calling leadAddressRemoveLeadContact.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contacts/${encodeURIComponent(String(leadAddressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the contact address from directory.
     * 
     * @param leadId the lead id
     * @param body The Update request data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressUpdateDirectoryContactAddress(leadId: number, body?: PutDirectoryContactAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressUpdateDirectoryContactAddress(leadId: number, body?: PutDirectoryContactAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressUpdateDirectoryContactAddress(leadId: number, body?: PutDirectoryContactAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressUpdateDirectoryContactAddress(leadId: number, body?: PutDirectoryContactAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressUpdateDirectoryContactAddress.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contact-address/directory`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the contact address as a single contact  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The lead id&lt;/param&gt;&lt;param name&#x3D;\&quot;request\&quot;&gt;The contact data and some more information like override behavior&lt;/param&gt;&lt;returns&gt;the lead composite&lt;/returns&gt;
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadAddressUpdateSingleContactAddress(leadId: number, body?: PutSingleContactAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadAddressUpdateSingleContactAddress(leadId: number, body?: PutSingleContactAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadAddressUpdateSingleContactAddress(leadId: number, body?: PutSingleContactAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadAddressUpdateSingleContactAddress(leadId: number, body?: PutSingleContactAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadAddressUpdateSingleContactAddress.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/contact-address/single-contact`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param apiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadDeleteLeadData(leadId: number, apiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadDeleteLeadData(leadId: number, apiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadDeleteLeadData(leadId: number, apiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadDeleteLeadData(leadId: number, apiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadDeleteLeadData.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiKey !== undefined && apiKey !== null) {
            queryParameters = queryParameters.set('apiKey', <any>apiKey);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a file from project documentation
     * 
     * @param leadId the id of the lead
     * @param fileName the filename to be deleted
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadDeleteLeadProjectDocumentation(leadId: number, fileName?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadDeleteLeadProjectDocumentation(leadId: number, fileName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadDeleteLeadProjectDocumentation(leadId: number, fileName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadDeleteLeadProjectDocumentation(leadId: number, fileName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadDeleteLeadProjectDocumentation.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/project-documentation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds or updates a dynamic property for the lead with the provided Id. The will be a check whether the current user is allowed to perform this action.
     * 
     * @param leadId The id of the lead for which the property should be added/updated for.
     * @param propertyName The name of the dynamic property to be added/updated.
     * @param body The dynamic property value.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty(leadId: number, propertyName: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty(leadId: number, propertyName: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty(leadId: number, propertyName: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty(leadId: number, propertyName: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty.');
        }

        if (propertyName === null || propertyName === undefined) {
            throw new Error('Required parameter propertyName was null or undefined when calling leadDynamicPropertiesCreateOrUpdateLeadDynamicProperty.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/dynamicproperties/${encodeURIComponent(String(propertyName))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the dynamic property for the lead with the provided Id. The will be a check whether the current user is allowed to perform this action.
     * 
     * @param leadId The id of the lead
     * @param propertyName The dynamic property name to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadDynamicPropertiesDeleteDynamicProperty(leadId: number, propertyName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadDynamicPropertiesDeleteDynamicProperty(leadId: number, propertyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadDynamicPropertiesDeleteDynamicProperty(leadId: number, propertyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadDynamicPropertiesDeleteDynamicProperty(leadId: number, propertyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadDynamicPropertiesDeleteDynamicProperty.');
        }

        if (propertyName === null || propertyName === undefined) {
            throw new Error('Required parameter propertyName was null or undefined when calling leadDynamicPropertiesDeleteDynamicProperty.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/dynamicproperties/${encodeURIComponent(String(propertyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the dynamic properties for the lead with the provided Id. The will be a check whether the current user is allowed to perform this action.
     * 
     * @param leadId The lead identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadDynamicPropertiesGetDynamicPropertiesForLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<LeadDynamicPropertyViewModel>>;
    public leadDynamicPropertiesGetDynamicPropertiesForLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LeadDynamicPropertyViewModel>>>;
    public leadDynamicPropertiesGetDynamicPropertiesForLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LeadDynamicPropertyViewModel>>>;
    public leadDynamicPropertiesGetDynamicPropertiesForLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadDynamicPropertiesGetDynamicPropertiesForLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LeadDynamicPropertyViewModel>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/dynamicproperties`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a folder inside the lead&#39;s directory.
     * 
     * @param leadId 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileCreateLeadFolder(leadId: number, folderName: string, observe?: 'body', reportProgress?: boolean): Observable<LeadDocumentListViewModel>;
    public leadFileCreateLeadFolder(leadId: number, folderName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadDocumentListViewModel>>;
    public leadFileCreateLeadFolder(leadId: number, folderName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadDocumentListViewModel>>;
    public leadFileCreateLeadFolder(leadId: number, folderName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileCreateLeadFolder.');
        }

        if (folderName === null || folderName === undefined) {
            throw new Error('Required parameter folderName was null or undefined when calling leadFileCreateLeadFolder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderName !== undefined && folderName !== null) {
            queryParameters = queryParameters.set('folderName', <any>folderName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadDocumentListViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/folder/stencils`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets list of available stencils
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetAvailableStencils(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadFileGetAvailableStencils(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadFileGetAvailableStencils(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadFileGetAvailableStencils(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetAvailableStencils.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/folder/stencils`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the existing document tags list.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetExistingDocumentTagsList(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadFileGetExistingDocumentTagsList(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadFileGetExistingDocumentTagsList(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadFileGetExistingDocumentTagsList(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetExistingDocumentTagsList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents/existing-document-tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the existing image tags list.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetExistingImageTagsList(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadFileGetExistingImageTagsList(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadFileGetExistingImageTagsList(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadFileGetExistingImageTagsList(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetExistingImageTagsList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/picture/existing-image-tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a file by its token  This endpoint only works if a link has been generated recently with this token  Token expire after 5hrs.
     * 
     * @param leadId 
     * @param fileToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileGetFileByToken(leadId: number, fileToken: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadFileGetFileByToken(leadId: number, fileToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadFileGetFileByToken(leadId: number, fileToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadFileGetFileByToken(leadId: number, fileToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadFileGetFileByToken.');
        }

        if (fileToken === null || fileToken === undefined) {
            throw new Error('Required parameter fileToken was null or undefined when calling leadFileGetFileByToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/files/token/${encodeURIComponent(String(fileToken))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the number of file tokens in the cache
     * 
     * @param apiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileNumberOfFileTokenInTheCache(apiKey: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public leadFileNumberOfFileTokenInTheCache(apiKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public leadFileNumberOfFileTokenInTheCache(apiKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public leadFileNumberOfFileTokenInTheCache(apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling leadFileNumberOfFileTokenInTheCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<number>(`${this.basePath}/api/pc/leads/files/token/count/${encodeURIComponent(String(apiKey))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove all file token from cache
     * 
     * @param apiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileRemoveAllFileTokenFromCache(apiKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileRemoveAllFileTokenFromCache(apiKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileRemoveAllFileTokenFromCache(apiKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileRemoveAllFileTokenFromCache(apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling leadFileRemoveAllFileTokenFromCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/files/token/remove-from-cache/${encodeURIComponent(String(apiKey))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove a file token from cache
     * 
     * @param fileToken 
     * @param apiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadFileRemoveOneFileTokenFromCache(fileToken: string, apiKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadFileRemoveOneFileTokenFromCache(fileToken: string, apiKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadFileRemoveOneFileTokenFromCache(fileToken: string, apiKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadFileRemoveOneFileTokenFromCache(fileToken: string, apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileToken === null || fileToken === undefined) {
            throw new Error('Required parameter fileToken was null or undefined when calling leadFileRemoveOneFileTokenFromCache.');
        }

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling leadFileRemoveOneFileTokenFromCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/leads/files/token/remove-from-cache/${encodeURIComponent(String(fileToken))}/${encodeURIComponent(String(apiKey))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a paged, sorted and address filtered set of leads.
     * 
     * @param body the lead request includes default values and all possible parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetAddressSearchLeadList(body?: AddressSearchListLeadRequest, observe?: 'body', reportProgress?: boolean): Observable<AddressSearchLeadListViewModelPageResultViewModel>;
    public leadGetAddressSearchLeadList(body?: AddressSearchListLeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddressSearchLeadListViewModelPageResultViewModel>>;
    public leadGetAddressSearchLeadList(body?: AddressSearchListLeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddressSearchLeadListViewModelPageResultViewModel>>;
    public leadGetAddressSearchLeadList(body?: AddressSearchListLeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AddressSearchLeadListViewModelPageResultViewModel>(`${this.basePath}/api/pc/leads/addresssearch`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves an appointment from a lead
     * 
     * @param leadId the id of the lead
     * @param appointmentId the id of the appointment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetAppointment(leadId: number, appointmentId: number, observe?: 'body', reportProgress?: boolean): Observable<AppointmentViewModel>;
    public leadGetAppointment(leadId: number, appointmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentViewModel>>;
    public leadGetAppointment(leadId: number, appointmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentViewModel>>;
    public leadGetAppointment(leadId: number, appointmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadGetAppointment.');
        }

        if (appointmentId === null || appointmentId === undefined) {
            throw new Error('Required parameter appointmentId was null or undefined when calling leadGetAppointment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<AppointmentViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/appointments/${encodeURIComponent(String(appointmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the existing tags list.
     * 
     * @param mandatorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetExistingTagsList(mandatorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadGetExistingTagsList(mandatorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadGetExistingTagsList(mandatorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadGetExistingTagsList(mandatorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mandatorId !== undefined && mandatorId !== null) {
            queryParameters = queryParameters.set('mandatorId', <any>mandatorId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/existing-tags`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * CHeck if sap number exist
     * 
     * @param sapNumber The SAP number which is to be checked for existence.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetLeadIdIfSpaNumberIsExist(sapNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public leadGetLeadIdIfSpaNumberIsExist(sapNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public leadGetLeadIdIfSpaNumberIsExist(sapNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public leadGetLeadIdIfSpaNumberIsExist(sapNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sapNumber !== undefined && sapNumber !== null) {
            queryParameters = queryParameters.set('sapNumber', <any>sapNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/pc/leads/project/sap-number-exist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a paged, sorted and filtered set of leads (some filtering will be based on the role of the current user)
     * 
     * @param body the lead request includes default values and all possible parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetLeads(body?: LeadRequest, observe?: 'body', reportProgress?: boolean): Observable<PageResultViewModelLeadListViewModel>;
    public leadGetLeads(body?: LeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageResultViewModelLeadListViewModel>>;
    public leadGetLeads(body?: LeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageResultViewModelLeadListViewModel>>;
    public leadGetLeads(body?: LeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PageResultViewModelLeadListViewModel>(`${this.basePath}/api/pc/leads/list`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a paged, sorted and filtered set of leads (some filtering will be based on the role of the current user)  with the possibility to filter after sop step completion
     * 
     * @param body the lead request includes default values and all possible parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetLeadsWithSop(body?: LeadRequestWithSop, observe?: 'body', reportProgress?: boolean): Observable<LeadListViewModelWithSopPageResultViewModel>;
    public leadGetLeadsWithSop(body?: LeadRequestWithSop, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadListViewModelWithSopPageResultViewModel>>;
    public leadGetLeadsWithSop(body?: LeadRequestWithSop, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadListViewModelWithSopPageResultViewModel>>;
    public leadGetLeadsWithSop(body?: LeadRequestWithSop, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadListViewModelWithSopPageResultViewModel>(`${this.basePath}/api/pc/leads/list-sop`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list with the ranking of the top project managers of the user&#39;s mandator
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetProjectScoreRanking(observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectScoreViewModel>>;
    public leadGetProjectScoreRanking(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectScoreViewModel>>>;
    public leadGetProjectScoreRanking(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectScoreViewModel>>>;
    public leadGetProjectScoreRanking(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectScoreViewModel>>(`${this.basePath}/api/pc/leads/project-score-ranking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a task from a lead
     * 
     * @param leadId the id of the lead
     * @param taskId the id of the task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadGetTask(leadId: number, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<TaskViewModel>;
    public leadGetTask(leadId: number, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TaskViewModel>>;
    public leadGetTask(leadId: number, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TaskViewModel>>;
    public leadGetTask(leadId: number, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadGetTask.');
        }

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling leadGetTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<TaskViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/tasks/${encodeURIComponent(String(taskId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Synchronize the Lead with Theia-Bridge
     * 
     * @param leadId The lead identifier.
     * @param leadSynchronizationToExternalSystem To which system will be synchronized.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadLeadSynchronizeWithTheiaBridge(leadId: number, leadSynchronizationToExternalSystem: 'BlueOffice' | 'EsErp' | 'Sap', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadLeadSynchronizeWithTheiaBridge(leadId: number, leadSynchronizationToExternalSystem: 'BlueOffice' | 'EsErp' | 'Sap', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadLeadSynchronizeWithTheiaBridge(leadId: number, leadSynchronizationToExternalSystem: 'BlueOffice' | 'EsErp' | 'Sap', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadLeadSynchronizeWithTheiaBridge(leadId: number, leadSynchronizationToExternalSystem: 'BlueOffice' | 'EsErp' | 'Sap', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadLeadSynchronizeWithTheiaBridge.');
        }

        if (leadSynchronizationToExternalSystem === null || leadSynchronizationToExternalSystem === undefined) {
            throw new Error('Required parameter leadSynchronizationToExternalSystem was null or undefined when calling leadLeadSynchronizeWithTheiaBridge.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadSynchronizationToExternalSystem !== undefined && leadSynchronizationToExternalSystem !== null) {
            queryParameters = queryParameters.set('leadSynchronizationToExternalSystem', <any>leadSynchronizationToExternalSystem);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/synchronize`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Switches to offline mode.
     * 
     * @param leadId The lead identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadLockLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadLockLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadLockLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadLockLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadLockLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/lock`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates project (with SapNumber and ExecutingOrganisationUnit) of a lead
     * 
     * @param leadId the id of the lead
     * @param body Request payload containing the information about the project.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadPatchLeadProject(leadId: number, body?: PatchProjectRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadPatchLeadProject(leadId: number, body?: PatchProjectRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadPatchLeadProject(leadId: number, body?: PatchProjectRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadPatchLeadProject(leadId: number, body?: PatchProjectRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadPatchLeadProject.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/project`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates project documentation
     * 
     * @param leadId the id of the lead
     * @param body Request payload containing the information about the project.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadPatchLeadProjectDocumentation(leadId: number, body?: PatchProjectDocumentationRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadPatchLeadProjectDocumentation(leadId: number, body?: PatchProjectDocumentationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadPatchLeadProjectDocumentation(leadId: number, body?: PatchProjectDocumentationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadPatchLeadProjectDocumentation(leadId: number, body?: PatchProjectDocumentationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadPatchLeadProjectDocumentation.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/project-documentation`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates project (with SapNumber and ExecutingOrganisationUnit) of a lead
     * 
     * @param leadId the id of the lead
     * @param body Request payload containing the information about the project.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadPatchLeadProjectWithSapRelevantData(leadId: number, body?: PatchProjectWithSapDataRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadPatchLeadProjectWithSapRelevantData(leadId: number, body?: PatchProjectWithSapDataRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadPatchLeadProjectWithSapRelevantData(leadId: number, body?: PatchProjectWithSapDataRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadPatchLeadProjectWithSapRelevantData(leadId: number, body?: PatchProjectWithSapDataRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadPatchLeadProjectWithSapRelevantData.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/project-with-sap-relevant-data`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes an appointment from a lead
     * 
     * @param leadId the id of the lead
     * @param appointmentId the id of the appointment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadRemoveAppointment(leadId: number, appointmentId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadRemoveAppointment(leadId: number, appointmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadRemoveAppointment(leadId: number, appointmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadRemoveAppointment(leadId: number, appointmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadRemoveAppointment.');
        }

        if (appointmentId === null || appointmentId === undefined) {
            throw new Error('Required parameter appointmentId was null or undefined when calling leadRemoveAppointment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/appointments/${encodeURIComponent(String(appointmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes an task from a lead
     * 
     * @param leadId the id of the lead
     * @param taskId the id of the task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadRemoveTask(leadId: number, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadRemoveTask(leadId: number, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadRemoveTask(leadId: number, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadRemoveTask(leadId: number, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadRemoveTask.');
        }

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling leadRemoveTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/tasks/${encodeURIComponent(String(taskId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Switches to online mode.
     * 
     * @param leadId The lead identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadUnlockLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadUnlockLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadUnlockLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadUnlockLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadUnlockLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/unlock`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing appointment of a lead
     * 
     * @param leadId the id of the lead
     * @param appointmentId the id of the appointment
     * @param body the appointment data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadUpdateAppointment(leadId: number, appointmentId: number, body?: AppointmentViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadUpdateAppointment(leadId: number, appointmentId: number, body?: AppointmentViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadUpdateAppointment(leadId: number, appointmentId: number, body?: AppointmentViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadUpdateAppointment(leadId: number, appointmentId: number, body?: AppointmentViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadUpdateAppointment.');
        }

        if (appointmentId === null || appointmentId === undefined) {
            throw new Error('Required parameter appointmentId was null or undefined when calling leadUpdateAppointment.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/appointments/${encodeURIComponent(String(appointmentId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates and existing task of a lead
     * 
     * @param leadId the id of the lead
     * @param taskId the id of the task
     * @param body the task data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadUpdateTask(leadId: number, taskId: number, body?: TaskViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public leadUpdateTask(leadId: number, taskId: number, body?: TaskViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public leadUpdateTask(leadId: number, taskId: number, body?: TaskViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public leadUpdateTask(leadId: number, taskId: number, body?: TaskViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadUpdateTask.');
        }

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling leadUpdateTask.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/tasks/${encodeURIComponent(String(taskId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a project size for the selected variant
     * 
     * @param leadId 
     * @param variantId 
     * @param tradeType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsGetProjectSizeForVariant(leadId?: number, variantId?: number, tradeType?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public pCApiLeadActionsGetProjectSizeForVariant(leadId?: number, variantId?: number, tradeType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public pCApiLeadActionsGetProjectSizeForVariant(leadId?: number, variantId?: number, tradeType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public pCApiLeadActionsGetProjectSizeForVariant(leadId?: number, variantId?: number, tradeType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadId !== undefined && leadId !== null) {
            queryParameters = queryParameters.set('leadId', <any>leadId);
        }
        if (variantId !== undefined && variantId !== null) {
            queryParameters = queryParameters.set('variantId', <any>variantId);
        }
        if (tradeType !== undefined && tradeType !== null) {
            queryParameters = queryParameters.set('tradeType', <any>tradeType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/pc/leads/actions/project-size`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marking a task as completed
     * 
     * @param taskId the id of the task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadControllerCompleteTask(taskId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiLeadControllerCompleteTask(taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiLeadControllerCompleteTask(taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiLeadControllerCompleteTask(taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling pCApiLeadControllerCompleteTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/pc/leads/tasks/${encodeURIComponent(String(taskId))}/complete`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of specific task of the current user lead which delegated to other people  It will only return the oldest task per lead and only of leads that are in  stage ASSIGNED_BO and FOLLOWUP_BO.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadControllerGetDelegatedTaskListForCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<Array<TaskListViewModel>>;
    public pCApiLeadControllerGetDelegatedTaskListForCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskListViewModel>>>;
    public pCApiLeadControllerGetDelegatedTaskListForCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskListViewModel>>>;
    public pCApiLeadControllerGetDelegatedTaskListForCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskListViewModel>>(`${this.basePath}/api/pc/leads/tasks/delegatedList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of specific task of the current user with lead information  It will only return the oldest task per lead and only of leads that are in  stage ASSIGNED_BO and FOLLOWUP_BO.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadControllerGetTaskListForCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<Array<TaskListViewModel>>;
    public pCApiLeadControllerGetTaskListForCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskListViewModel>>>;
    public pCApiLeadControllerGetTaskListForCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskListViewModel>>>;
    public pCApiLeadControllerGetTaskListForCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskListViewModel>>(`${this.basePath}/api/pc/leads/tasks/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves open tasks of the current user (Taskbell)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadControllerGetTasksForCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<Array<TaskViewModel>>;
    public pCApiLeadControllerGetTasksForCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskViewModel>>>;
    public pCApiLeadControllerGetTasksForCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskViewModel>>>;
    public pCApiLeadControllerGetTasksForCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaskViewModel>>(`${this.basePath}/api/pc/leads/tasks/current-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Copy the specified variant.
     * 
     * @param variantId the id of the source variant
     * @param leadId the id of the lead to copy the variant to.              Can be null, which will copy the target variant to the same lead of the source variant.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadCopyVariant(variantId: number, leadId?: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadCopyVariant(variantId: number, leadId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadCopyVariant(variantId: number, leadId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadCopyVariant(variantId: number, leadId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadCopyVariant.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadId !== undefined && leadId !== null) {
            queryParameters = queryParameters.set('leadId', <any>leadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/clone`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a expense Variant.
     * 
     * @param leadId the id of the lead to copy the variant to.
     * @param freeBillType the type of the free bill.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadCreateFreeBillVariant(leadId: number, freeBillType: 'Expense' | 'Supplementary' | 'PartList', observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadCreateFreeBillVariant(leadId: number, freeBillType: 'Expense' | 'Supplementary' | 'PartList', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadCreateFreeBillVariant(leadId: number, freeBillType: 'Expense' | 'Supplementary' | 'PartList', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadCreateFreeBillVariant(leadId: number, freeBillType: 'Expense' | 'Supplementary' | 'PartList', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadCreateFreeBillVariant.');
        }

        if (freeBillType === null || freeBillType === undefined) {
            throw new Error('Required parameter freeBillType was null or undefined when calling pCApiLeadCreateFreeBillVariant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/freeBill/${encodeURIComponent(String(freeBillType))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a from an existing lead
     * 
     * @param leadIdToCopyAddresses the leadId. Required. All addresses will be copied from the lead
     * @param tradeTypeCode the trade type code. Should not be null
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadCreateFromExistingLead(leadIdToCopyAddresses: number, tradeTypeCode?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadCreateFromExistingLead(leadIdToCopyAddresses: number, tradeTypeCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadCreateFromExistingLead(leadIdToCopyAddresses: number, tradeTypeCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadCreateFromExistingLead(leadIdToCopyAddresses: number, tradeTypeCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadIdToCopyAddresses === null || leadIdToCopyAddresses === undefined) {
            throw new Error('Required parameter leadIdToCopyAddresses was null or undefined when calling pCApiLeadCreateFromExistingLead.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tradeTypeCode !== undefined && tradeTypeCode !== null) {
            queryParameters = queryParameters.set('tradeTypeCode', <any>tradeTypeCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/fromExistingLead/${encodeURIComponent(String(leadIdToCopyAddresses))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the given lead variant
     * 
     * @param variantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadDeleteLeadVariant(variantId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadDeleteLeadVariant(variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadDeleteLeadVariant(variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadDeleteLeadVariant(variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadDeleteLeadVariant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadGetLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadGetLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadGetLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadGetLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadGetLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadGetLeadSiblings(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadSiblingsViewModel>;
    public pCApiLeadGetLeadSiblings(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadSiblingsViewModel>>;
    public pCApiLeadGetLeadSiblings(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadSiblingsViewModel>>;
    public pCApiLeadGetLeadSiblings(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadGetLeadSiblings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<LeadSiblingsViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/siblings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves list of variant list view models, depending on the search parameter, that can be interpreted in different ways.
     * 
     * @param tradeTypeCode The trade type code of the variant to search
     * @param searchParameter The search parameter is interpreted in the following order:              1. If it matches a leadId, the variants of this lead are returned.              3. If it matches the last name of lead&#39;s person, the variants of all the matching leads are returned.
     * @param mandatorId The mandator id of the lead of the variant to search
     * @param abortNotIncluded if true, the aborted variants are not included
     * @param excludedLeadId variants from the lead with that ID are to be excluded.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadGetLeadVariants(tradeTypeCode: string, searchParameter: string, mandatorId?: number, abortNotIncluded?: boolean, excludedLeadId?: number, observe?: 'body', reportProgress?: boolean): Observable<VariantSearchResultViewModel>;
    public pCApiLeadGetLeadVariants(tradeTypeCode: string, searchParameter: string, mandatorId?: number, abortNotIncluded?: boolean, excludedLeadId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantSearchResultViewModel>>;
    public pCApiLeadGetLeadVariants(tradeTypeCode: string, searchParameter: string, mandatorId?: number, abortNotIncluded?: boolean, excludedLeadId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantSearchResultViewModel>>;
    public pCApiLeadGetLeadVariants(tradeTypeCode: string, searchParameter: string, mandatorId?: number, abortNotIncluded?: boolean, excludedLeadId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling pCApiLeadGetLeadVariants.');
        }

        if (searchParameter === null || searchParameter === undefined) {
            throw new Error('Required parameter searchParameter was null or undefined when calling pCApiLeadGetLeadVariants.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchParameter !== undefined && searchParameter !== null) {
            queryParameters = queryParameters.set('searchParameter', <any>searchParameter);
        }
        if (mandatorId !== undefined && mandatorId !== null) {
            queryParameters = queryParameters.set('mandatorId', <any>mandatorId);
        }
        if (abortNotIncluded !== undefined && abortNotIncluded !== null) {
            queryParameters = queryParameters.set('abortNotIncluded', <any>abortNotIncluded);
        }
        if (excludedLeadId !== undefined && excludedLeadId !== null) {
            queryParameters = queryParameters.set('excludedLeadId', <any>excludedLeadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<VariantSearchResultViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(tradeTypeCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Moves the specified variant to another lead of the same trade type.
     * 
     * @param variantId the id of the source variant
     * @param leadId the id of the lead to move the variant to.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadMoveVariant(variantId: number, leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadMoveVariant(variantId: number, leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadMoveVariant(variantId: number, leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadMoveVariant(variantId: number, leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadMoveVariant.');
        }

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadMoveVariant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/move/${encodeURIComponent(String(leadId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches a lead
     * 
     * @param leadId the id of the lead
     * @param body The patch lead view model.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLead(leadId: number, body?: PatchLeadViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLead(leadId: number, body?: PatchLeadViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLead(leadId: number, body?: PatchLeadViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLead(leadId: number, body?: PatchLeadViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadPatchLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadComplexityType(leadId: number, body?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadComplexityType(leadId: number, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadComplexityType(leadId: number, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadComplexityType(leadId: number, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadPatchLeadComplexityType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/complexity-type`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches a lead tags
     * 
     * @param leadId the id of the lead
     * @param tags The comma-separated string.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadTags(leadId: number, tags?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadTags(leadId: number, tags?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadTags(leadId: number, tags?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadTags(leadId: number, tags?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadPatchLeadTags.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tags !== undefined && tags !== null) {
            queryParameters = queryParameters.set('tags', <any>tags);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/tags`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches status and rating on a given lead variant
     * 
     * @param variantId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadVariant(variantId: number, body?: PatchLeadVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadVariant(variantId: number, body?: PatchLeadVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariant(variantId: number, body?: PatchLeadVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariant(variantId: number, body?: PatchLeadVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadPatchLeadVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the invoice status
     * 
     * @param variantId Variant identifier.
     * @param invoiceId Invoice identifier.
     * @param body Request payload containing the information about the invoice status.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadVariantFinalInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadVariantFinalInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantFinalInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantFinalInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadPatchLeadVariantFinalInvoiceStatus.');
        }

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling pCApiLeadPatchLeadVariantFinalInvoiceStatus.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/invoice/final/${encodeURIComponent(String(invoiceId))}/status`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the invoices of a variant
     * 
     * @param body Request payload containing the information about the invoice information.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadVariantInvoices(body?: Array<PatchVariantInvoiceRequest>, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadVariantInvoices(body?: Array<PatchVariantInvoiceRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantInvoices(body?: Array<PatchVariantInvoiceRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantInvoices(body?: Array<PatchVariantInvoiceRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/invoices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the invoice status
     * 
     * @param variantId Variant identifier.
     * @param invoiceId Invoice identifier.
     * @param body Request payload containing the information about the invoice status.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPatchLeadVariantPartialInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPatchLeadVariantPartialInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantPartialInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPatchLeadVariantPartialInvoiceStatus(variantId: number, invoiceId: number, body?: PatchVariantInvoiceStatusRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadPatchLeadVariantPartialInvoiceStatus.');
        }

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling pCApiLeadPatchLeadVariantPartialInvoiceStatus.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/invoice/partial/${encodeURIComponent(String(invoiceId))}/status`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new empty lead with an address
     * 
     * @param body Everything needed to create a new lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPostLead(body?: PostLeadRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPostLead(body?: PostLeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPostLead(body?: PostLeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPostLead(body?: PostLeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add final invoice to variant
     * 
     * @param variantId The id of the variant
     * @param body Request payload containing the information about the final invoice information.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadPostLeadVariantFinalInvoices(variantId: number, body?: PostFinalInvoiceRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadPostLeadVariantFinalInvoices(variantId: number, body?: PostFinalInvoiceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadPostLeadVariantFinalInvoices(variantId: number, body?: PostFinalInvoiceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadPostLeadVariantFinalInvoices(variantId: number, body?: PostFinalInvoiceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadPostLeadVariantFinalInvoices.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/final-invoices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a Project offer variant to won
     * 
     * @param variantId the id of the source variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadProjectOfferVariantToWon(variantId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadProjectOfferVariantToWon(variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadProjectOfferVariantToWon(variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadProjectOfferVariantToWon(variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadProjectOfferVariantToWon.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/variants/${encodeURIComponent(String(variantId))}/project-offer/won`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves list of potential users whom the lead task can be assigned to.
     * 
     * @param leadId The id of the lead.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadTaskGetTaskAssignmentCandidates(leadId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserViewModel>>;
    public pCApiLeadTaskGetTaskAssignmentCandidates(leadId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserViewModel>>>;
    public pCApiLeadTaskGetTaskAssignmentCandidates(leadId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserViewModel>>>;
    public pCApiLeadTaskGetTaskAssignmentCandidates(leadId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadId !== undefined && leadId !== null) {
            queryParameters = queryParameters.set('leadId', <any>leadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserViewModel>>(`${this.basePath}/api/pc/leads/tasks/assignment-candidates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCAutoOfferPostPvAutoOffer(leadId: number, body?: PvAutoOfferRequest, observe?: 'body', reportProgress?: boolean): Observable<AutoOfferResult>;
    public pCAutoOfferPostPvAutoOffer(leadId: number, body?: PvAutoOfferRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AutoOfferResult>>;
    public pCAutoOfferPostPvAutoOffer(leadId: number, body?: PvAutoOfferRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AutoOfferResult>>;
    public pCAutoOfferPostPvAutoOffer(leadId: number, body?: PvAutoOfferRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCAutoOfferPostPvAutoOffer.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AutoOfferResult>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/pv/auto-offer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets an existing pv lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvLeadGetPvLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<PvLeadViewModel>;
    public pvLeadGetPvLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PvLeadViewModel>>;
    public pvLeadGetPvLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PvLeadViewModel>>;
    public pvLeadGetPvLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pvLeadGetPvLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<PvLeadViewModel>(`${this.basePath}/api/pc/leads/pv/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the pv lead.
     * 
     * @param leadId the id of the lead
     * @param body The request to patch the lead, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvLeadPatchPvLead(leadId: number, body?: PvLeadViewModel, observe?: 'body', reportProgress?: boolean): Observable<PvLeadViewModel>;
    public pvLeadPatchPvLead(leadId: number, body?: PvLeadViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PvLeadViewModel>>;
    public pvLeadPatchPvLead(leadId: number, body?: PvLeadViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PvLeadViewModel>>;
    public pvLeadPatchPvLead(leadId: number, body?: PvLeadViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pvLeadPatchPvLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<PvLeadViewModel>(`${this.basePath}/api/pc/leads/pv/${encodeURIComponent(String(leadId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches buildingData on the pv lead.
     * 
     * @param leadId the id of the lead
     * @param body The request to patch the lead building data, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvLeadPutPvLeadBuildingData(leadId: number, body?: PutPvBuildingData, observe?: 'body', reportProgress?: boolean): Observable<PvLeadViewModel>;
    public pvLeadPutPvLeadBuildingData(leadId: number, body?: PutPvBuildingData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PvLeadViewModel>>;
    public pvLeadPutPvLeadBuildingData(leadId: number, body?: PutPvBuildingData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PvLeadViewModel>>;
    public pvLeadPutPvLeadBuildingData(leadId: number, body?: PutPvBuildingData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pvLeadPutPvLeadBuildingData.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PvLeadViewModel>(`${this.basePath}/api/pc/leads/pv/${encodeURIComponent(String(leadId))}/building3d`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
