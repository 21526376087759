import { IPrincipal } from '../interfaces/principal.interface';
import {
  ContactAddressContactListViewModel,
  ContactListViewModel,
  DirectoryPersonViewModel,
  LeadCompositeViewModel,
  LeadListViewModel,
  LeadRegionalInformationViewModel,
  LeadViewModel,
  PcUserPermissionsViewModel,
  RelatedLeadViewModel,
  VariantListCompositionViewModel,
} from '../apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import { isNullOrUndefined } from './isNullOrUndefined';

export class LeadUtil {
  public static filterOutEqualId<LeadType extends LeadViewModel | LeadListViewModel>(
    leads: LeadType[],
    leadToFilter: LeadType
  ): LeadType[] {
    if (leads && leadToFilter) {
      return leads.filter((lead: LeadType) => {
        if (leadToFilter.Id !== lead.Id) {
          return lead;
        }

        return undefined;
      });
    }
    return leads;
  }

  public static getElectricTariffsLinkFromLeadComposit(
    leadComposite: LeadCompositeViewModel
  ): string {
    if (
      isNullOrUndefined(leadComposite) ||
      isNullOrUndefined(
        leadComposite?.RegionalInformation?.ObjectRegionalInformation?.RegionalInformation
          ?.CommuneNo
      ) ||
      isNaN(
        +leadComposite.RegionalInformation.ObjectRegionalInformation.RegionalInformation.CommuneNo
      ) ||
      +leadComposite.RegionalInformation.ObjectRegionalInformation.RegionalInformation.CommuneNo ===
        0
    ) {
      return 'https://www.strompreis.elcom.admin.ch/';
    }
    return (
      'https://www.strompreis.elcom.admin.ch/municipality/' +
      leadComposite.RegionalInformation.ObjectRegionalInformation.RegionalInformation.CommuneNo
    );
  }

  public static getElectricTariffsLink(
    regionalInformation?: LeadRegionalInformationViewModel
  ): string {
    if (
      isNullOrUndefined(regionalInformation?.CommuneNo) ||
      isNaN(+regionalInformation.CommuneNo) ||
      +regionalInformation.CommuneNo === 0
    ) {
      return 'https://www.strompreis.elcom.admin.ch/';
    }
    return 'https://www.strompreis.elcom.admin.ch/municipality/' + regionalInformation.CommuneNo;
  }

  public static isResponsible(
    lead: LeadViewModel | LeadListViewModel,
    principal: IPrincipal
  ): boolean {
    return lead.ResponsibleId.localeCompare(principal.getUserId()) === 0;
  }

  public static canEditProjectData(
    lead: LeadViewModel | LeadListViewModel,
    principal: IPrincipal,
    userPermissions: PcUserPermissionsViewModel
  ): boolean {
    return lead.MandantId === principal.getMandantId() && userPermissions.CanAlwaysEditProjectData;
  }

  public static isOwner(lead: LeadViewModel | LeadListViewModel, principal: IPrincipal): boolean {
    return lead.OwnerId.localeCompare(principal.getUserId()) === 0;
  }

  public static isResponsibleAndOwner(
    lead: LeadViewModel | LeadListViewModel,
    principal: IPrincipal
  ): boolean {
    return this.isOwner(lead, principal) && !lead.DelegateId;
  }

  public static isPreviousOwner(
    lead: LeadViewModel | LeadListViewModel,
    principal: IPrincipal
  ): boolean {
    return lead.PreviousOwnerId && lead.PreviousOwnerId.localeCompare(principal.getUserId()) === 0;
  }

  public static isDelegate(
    lead: LeadViewModel | LeadListViewModel,
    principal: IPrincipal
  ): boolean {
    return lead.DelegateId && lead.DelegateId.localeCompare(principal.getUserId()) === 0;
  }

  public static findLeadById<LeadType extends LeadViewModel | LeadListViewModel>(
    leadId: number,
    leads: LeadType[]
  ): LeadType {
    return leads.find((lead: LeadType) => {
      return lead.Id === leadId;
    });
  }

  public static filterOutVariantsWithStatus(
    variants: VariantListCompositionViewModel[],
    status: string
  ): VariantListCompositionViewModel[] {
    let filteredVariants: VariantListCompositionViewModel[] = [];
    if (variants && variants.length > 0) {
      filteredVariants = variants.filter((variant: VariantListCompositionViewModel) => {
        if (variant.LeadVariant.Status.toLowerCase().localeCompare(status.toLowerCase()) !== 0) {
          return true;
        }
        return false;
      });
    }
    return filteredVariants;
  }

  public static mapLeadViewModelToLeadListViewModelArr(
    leadVmArr: LeadCompositeViewModel[]
  ): LeadListViewModel[] {
    const leadListViewModelArr: LeadListViewModel[] = [];
    for (const leadVm of leadVmArr) {
      leadListViewModelArr.push(LeadUtil.mapLeadViewModelToLeadListViewModel(leadVm));
    }
    return leadListViewModelArr;
  }

  public static mapLeadViewModelToLeadListViewModel(
    leadVm: LeadCompositeViewModel
  ): LeadListViewModel {
    const leadListVm: LeadListViewModel = {
      Id: leadVm.Lead.Id,
      ContactDesired: leadVm.Lead.ContactDesired,
      Date: leadVm.Lead.Date,
      MandantId: leadVm.Lead.MandantId,
      ModiDate: leadVm.Lead.ModiDate,
      OrganizationalUnitId: leadVm.Lead.OrganizationalUnitId,
      Origin: leadVm.Lead.Origin,
      OwnerId: leadVm.Lead.OwnerId,
      OwnerFirstName: leadVm.Lead.OwnerFirstName,
      OwnerLastName: leadVm.Lead.OwnerLastName,
      DelegateId: leadVm.Lead.DelegateId,
      ResponsibleId: leadVm.Lead.ResponsibleId,
      ContactAddressContact: this.mapContactAddressToContact(leadVm.Lead),
      ObjectAddressContact: this.mapObjectAddressToContact(leadVm.Lead),
      PreviousStatus: leadVm.Lead.PreviousStatus,
      PreviousStage: leadVm.Lead.PreviousStage,
      PreviousOwnerId: leadVm.Lead.PreviousOwnerId,
      Stage: leadVm.Lead.Stage,
      Status: leadVm.Lead.Status,
      Rating: leadVm.Lead.Rating,
      TradeTypeCode: leadVm.Lead.TradeTypeCode,
      MandatorSiblingLeadCount: 0,
    };
    return cloneDeep(leadListVm);
  }

  public static buildOwnerName(leadViewModel: LeadListViewModel | RelatedLeadViewModel): string {
    return `${leadViewModel.OwnerFirstName} ${leadViewModel.OwnerLastName}`;
  }

  public static buildProjectManagerName(leadViewModel: LeadListViewModel): string {
    return `${leadViewModel.ProjectManagerFirstName} ${leadViewModel.ProjectManagerLastName}`;
  }

  public static getMandatorsFilterValue(
    filterMandators: number[],
    selectedMandators: number[]
  ): number[] {
    return isNullOrUndefined(filterMandators) || filterMandators.length === 0
      ? Array.from(selectedMandators)
      : filterMandators;
  }

  public static openNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  private static mapContactAddressToContact(
    lead: LeadViewModel
  ): ContactAddressContactListViewModel {
    return {
      Id: lead.ContactAddress?.Id,
      MandatorId: lead.MandantId,
      DisplayName: [
        lead.DebitorAddress?.Company?.Name || '',
        `${lead.DebitorAddress?.Person?.FirstName || ''} ${
          lead.DebitorAddress?.Person?.FirstName || ''
        }`,
      ]
        .filter(x => x)
        .join(', '),
      AdditionalCompanyName: lead.ContactAddress?.Company?.AdditionalName || '',
      CompanyName: lead.ContactAddress?.Company?.Name || '',
      Title: lead.ContactAddress?.Person?.Title || DirectoryPersonViewModel.TitleEnum.None,
      Email: lead.ContactAddress?.Person?.PrimaryEmail.Email || '',
      City: lead.ContactAddress?.MainContact?.City || '',
      FirstName: lead.ContactAddress?.Person?.FirstName || '',
      LastName: lead.ContactAddress?.Person?.LastName || '',
      Language: lead.ContactAddress?.Person?.Language || 'DE',
      PhoneNumber: lead.ContactAddress?.MainContact?.PrimaryPhone.PhoneNumber || '',
      Street: lead.ContactAddress?.MainContact?.Street || '',
      ZipCode: lead.ContactAddress?.MainContact?.ZipCode || '',
    };
  }

  private static mapObjectAddressToContact(lead: LeadViewModel): ContactListViewModel {
    return {
      City: lead.ObjectAddress?.MainContact?.City || '',
      ZipCode: lead.ObjectAddress?.MainContact?.ZipCode || '',
      FirstName: lead.ObjectAddress?.Person?.FirstName || '',
      LastName: lead.ObjectAddress?.Person?.LastName || '',
      Street: lead.ObjectAddress?.MainContact?.Street || '',
      DisplayName: [
        lead.ObjectAddress?.Company?.Name || '',
        `${lead.ObjectAddress?.Person?.FirstName || ''} ${
          lead.ObjectAddress?.Person?.FirstName || ''
        }`,
      ]
        .filter(x => x)
        .join(', '),
    };
  }
}
