import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export enum TypeE {
  DEFAULT,
  PRIMARY,
  ALERT,
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {
    // empty
  }

  public notifyDataSaved(): MatSnackBarRef<SimpleSnackBar> {
    return this.notifySimple(this.translate.instant('COMMON.DATA_SAVED'), TypeE.PRIMARY);
  }

  public notifyLoadFailed(): MatSnackBarRef<SimpleSnackBar> {
    return this.notifySimple(this.translate.instant('COMMON.LOAD_FAILED'), TypeE.ALERT);
  }

  public notifySimple(
    message: string,
    type: TypeE = TypeE.DEFAULT,
    timeout: number = 4000
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, undefined, type, timeout);
  }

  public notifyWithAction(
    message: string,
    action: string,
    type: TypeE = TypeE.DEFAULT,
    timeout: number = undefined
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, action, type, timeout);
  }

  private open(
    message: string,
    action: string,
    type: TypeE,
    timeout: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = timeout;

    const cssClasses: string[] = [];
    switch (type) {
      case TypeE.PRIMARY:
        cssClasses.push('snackbar-primary');
        break;

      case TypeE.ALERT:
        cssClasses.push('snackbar-alert');
        break;

      default:
        // Nothing to do
        break;
    }
    config.panelClass = cssClasses;
    return this.snackBar.open(message, action, config);
  }
}
