import { OptionGroupModel } from '../models/option.group.model';
import { Option } from '../models/option.model';
import * as models from '../../shared/apis/advis/model/models';

export class OptionsUtil {
  // TODO: PVRE
  // public static initializeOptions(objectData: models.PvObjectDataViewModel): models.PvObjectDataViewModel {
  //   objectData.Option1 = objectData.Option1 ? objectData.Option1 : 0;
  //   objectData.Option2 = objectData.Option2 ? objectData.Option2 : 0;
  //   objectData.Option5 = objectData.Option5 ? objectData.Option5 : 0;
  //   objectData.Option6 = objectData.Option6 ? objectData.Option6 : 0;
  //   objectData.Option7 = objectData.Option7 ? objectData.Option7 : 0;
  //   objectData.Option8 = objectData.Option8 ? objectData.Option8 : 0;
  //   objectData.Option9 = objectData.Option9 ? objectData.Option9 : 0;
  //   objectData.Option10 = objectData.Option10 ? objectData.Option10 : 0;
  //   objectData.Option11 = objectData.Option11 ? objectData.Option11 : 0;
  //   objectData.Option12 =  objectData.Option12 ? objectData.Option12 : 0;
  //   objectData.Option20 = objectData.Option20 ? objectData.Option20 : 0;
  //   return objectData;
  // }
  public static initializeOptions(
    wizardViewModel: models.PvWizardViewModel
  ): models.PvWizardViewModel {
    return wizardViewModel;
  }

  public static getOptions(): OptionGroupModel[] {
    const optionGroups: OptionGroupModel[] = [];

    // scaffold options
    const optionGroupScaffold: OptionGroupModel = new OptionGroupModel();
    optionGroupScaffold.id = 20;

    const optionScaffold1: Option = new Option();
    optionScaffold1.id = 1;
    optionScaffold1.de = 'Ost/West';
    optionScaffold1.en = 'East/West';
    optionScaffold1.fr = 'Est/Ouest';
    optionScaffold1.it = 'Est/Ovest';
    optionScaffold1.fileName = 'scaffold_1.png';
    optionGroupScaffold.options.push(optionScaffold1);

    const optionScaffold2: Option = new Option();
    optionScaffold2.id = 2;
    optionScaffold2.de = 'Süd mit Ortgang';
    optionScaffold2.en = 'South with vergeboard';
    optionScaffold2.fr = 'Sud avec rive du toit';
    optionScaffold2.it = 'Sud con bordo frontale';
    optionScaffold2.fileName = 'scaffold_2.png';
    optionGroupScaffold.options.push(optionScaffold2);

    const optionScaffold3: Option = new Option();
    optionScaffold3.id = 3;
    optionScaffold3.de = 'Ost/West nur ein Ortgang';
    optionScaffold3.en = 'East/West only one vergeboard';
    optionScaffold3.fr = 'Ost/West seulment une rive';
    optionScaffold3.it = 'Est/Ovest solo uno bordo frontale';
    optionScaffold3.fileName = 'scaffold_3.png';
    optionGroupScaffold.options.push(optionScaffold3);

    const optionScaffold4: Option = new Option();
    optionScaffold4.id = 4;
    optionScaffold4.de = 'Süd nur ein Ortgang';
    optionScaffold4.en = 'South only one vergeboard';
    optionScaffold4.fr = 'Sud seulment une rive';
    optionScaffold4.it = 'Sud solo uno bordo frontale';
    optionScaffold4.fileName = 'scaffold_4.png';
    optionGroupScaffold.options.push(optionScaffold4);

    const optionScaffold5: Option = new Option();
    optionScaffold5.id = 5;
    optionScaffold5.de = 'Ost/West ohne Ortgang';
    optionScaffold5.en = 'East/West w/o vergeboard';
    optionScaffold5.fr = 'Est/Ouest sans rive du toit';
    optionScaffold5.it = 'Est/Ovest senza bordo frontale';
    optionScaffold5.fileName = 'scaffold_5.png';
    optionGroupScaffold.options.push(optionScaffold5);

    const optionScaffold6: Option = new Option();
    optionScaffold6.id = 6;
    optionScaffold6.de = 'Süd ohne Ortgang';
    optionScaffold6.en = 'South w/o vergeboard';
    optionScaffold6.fr = 'Sud sans rive du toit';
    optionScaffold6.it = 'Sud senza bordo frontale';
    optionScaffold6.fileName = 'scaffold_6.png';
    optionGroupScaffold.options.push(optionScaffold6);

    const optionScaffold7: Option = new Option();
    optionScaffold7.id = 7;
    optionScaffold7.de = 'Flachdach Klemmgerüst';
    optionScaffold7.en = 'Flat roof Klemmgerüst';
    optionScaffold7.fr = 'Toit plat Klemmgerüst';
    optionScaffold7.it = 'Tetto piano Klemmgerüst';
    optionScaffold7.fileName = 'scaffold_7.png';
    optionGroupScaffold.options.push(optionScaffold7);

    const optionScaffold8: Option = new Option();
    optionScaffold8.id = 8;
    optionScaffold8.de = 'Flachdach Innengerüst';
    optionScaffold8.en = 'Flat roof Innengerüst';
    optionScaffold8.fr = 'Toit Plat Innengerüst';
    optionScaffold8.it = 'Tetto piano Innengerüst';
    optionScaffold8.fileName = 'scaffold_8.png';
    optionGroupScaffold.options.push(optionScaffold8);

    const optionScaffold99: Option = new Option();
    optionScaffold99.id = 99;
    optionScaffold99.de = 'Bauseits';
    optionScaffold99.en = 'On site';
    optionScaffold99.fr = 'À assurer par le client';
    optionScaffold99.it = 'Sul cantiere';
    optionScaffold99.fileName = 'bauseits.svg';
    optionGroupScaffold.options.push(optionScaffold99);

    optionGroups.push(optionGroupScaffold);

    // BYD options
    const optionGroupBYD: OptionGroupModel = new OptionGroupModel();
    optionGroupBYD.id = 1;
    optionGroupBYD.de = 'BYD';
    optionGroupBYD.en = 'BYD';
    optionGroupBYD.it = 'BYD';
    optionGroupBYD.fr = 'BYD';

    const optionBYD1: Option = new Option();
    optionBYD1.id = 1;
    optionBYD1.de = '6.4 kWh';
    optionBYD1.en = '6.4 kWh';
    optionBYD1.fr = '6.4 kWh';
    optionBYD1.it = '6.4 kWh';
    optionBYD1.fileName = 'byd_battery.png';
    optionGroupBYD.options.push(optionBYD1);

    const optionBYD2: Option = new Option();
    optionBYD2.id = 2;
    optionBYD2.de = '9 kWh';
    optionBYD2.en = '9 kWh';
    optionBYD2.fr = '9 kWh';
    optionBYD2.it = '9 kWh';
    optionBYD2.fileName = 'byd_battery.png';
    optionGroupBYD.options.push(optionBYD2);

    const optionBYD3: Option = new Option();
    optionBYD3.id = 3;
    optionBYD3.de = '11.5 kWh';
    optionBYD3.en = '11.5 kWh';
    optionBYD3.fr = '11.5 kWh';
    optionBYD3.it = '11.5 kWh';
    optionBYD3.fileName = 'byd_battery.png';
    optionGroupBYD.options.push(optionBYD3);

    optionGroups.push(optionGroupBYD);

    // Sonnen options
    const optionGroupSonnen: OptionGroupModel = new OptionGroupModel();
    optionGroupSonnen.id = 10;
    optionGroupSonnen.de = 'Sonnen';
    optionGroupSonnen.en = 'Sonnen';
    optionGroupSonnen.it = 'Sonnen';
    optionGroupSonnen.fr = 'Sonnen';

    const optionSonnen1: Option = new Option();
    optionSonnen1.id = 1;
    optionSonnen1.de = '2.5 kWh';
    optionSonnen1.en = '2.5 kWh';
    optionSonnen1.fr = '2.5 kWh';
    optionSonnen1.it = '2.5 kWh';
    optionSonnen1.fileName = 'sonnen_battery.png';
    optionGroupSonnen.options.push(optionSonnen1);

    const optionSonnen2: Option = new Option();
    optionSonnen2.id = 2;
    optionSonnen2.de = '5 kWh';
    optionSonnen2.en = '5 kWh';
    optionSonnen2.fr = '5 kWh';
    optionSonnen2.it = '5 kWh';
    optionSonnen2.fileName = 'sonnen_battery.png';
    optionGroupSonnen.options.push(optionSonnen2);

    const optionSonnen3: Option = new Option();
    optionSonnen3.id = 3;
    optionSonnen3.de = '7.5 kWh';
    optionSonnen3.en = '7.5 kWh';
    optionSonnen3.fr = '7.5 kWh';
    optionSonnen3.it = '7.5 kWh';
    optionSonnen3.fileName = 'sonnen_battery.png';
    optionGroupSonnen.options.push(optionSonnen3);

    const optionSonnen4: Option = new Option();
    optionSonnen4.id = 4;
    optionSonnen4.de = '10 kWh';
    optionSonnen4.en = '10 kWh';
    optionSonnen4.fr = '10 kWh';
    optionSonnen4.it = '10 kWh';
    optionSonnen4.fileName = 'sonnen_battery.png';
    optionGroupSonnen.options.push(optionSonnen4);

    optionGroups.push(optionGroupSonnen);

    // E3DC options
    const optionGroupE3dc: OptionGroupModel = new OptionGroupModel();
    optionGroupE3dc.id = 11;
    optionGroupE3dc.de = 'E3DC';
    optionGroupE3dc.en = 'E3DC';
    optionGroupE3dc.it = 'E3DC';
    optionGroupE3dc.fr = 'E3DC';

    const optionE3dc1: Option = new Option();
    optionE3dc1.id = 1;
    optionE3dc1.de = '6.5 kWh';
    optionE3dc1.en = '6.5 kWh';
    optionE3dc1.fr = '6.5 kWh';
    optionE3dc1.it = '6.5 kWh';
    optionE3dc1.fileName = 'e3dc_battery.png';
    optionGroupE3dc.options.push(optionE3dc1);

    // const optionE3dc2: Option = new Option();
    // optionE3dc2.id = 2;
    // optionE3dc2.de = '10 kWh';
    // optionE3dc2.en = '10 kWh';
    // optionE3dc2.fr = '10 kWh';
    // optionE3dc2.it = '10 kWh';
    // optionE3dc2.fileName = 'e3dc_battery.png';
    // optionGroupE3dc.options.push(optionE3dc2);

    const optionE3dc3: Option = new Option();
    optionE3dc3.id = 3;
    optionE3dc3.de = '13 kWh';
    optionE3dc3.en = '13 kWh';
    optionE3dc3.fr = '13 kWh';
    optionE3dc3.it = '13 kWh';
    optionE3dc3.fileName = 'e3dc_battery.png';
    optionGroupE3dc.options.push(optionE3dc3);

    // const optionE3dc4: Option = new Option();
    // optionE3dc4.id = 4;
    // optionE3dc4.de = '15 kWh';
    // optionE3dc4.en = '15 kWh';
    // optionE3dc4.fr = '15 kWh';
    // optionE3dc4.it = '15 kWh';
    // optionE3dc4.fileName = 'e3dc_battery.png';
    // optionGroupE3dc.options.push(optionE3dc4);

    const optionE3dc5: Option = new Option();
    optionE3dc5.id = 5;
    optionE3dc5.de = '19.5 kWh';
    optionE3dc5.en = '19.5 kWh';
    optionE3dc5.fr = '19.5 kWh';
    optionE3dc5.it = '19.5 kWh';
    optionE3dc5.fileName = 'e3dc_battery.png';
    optionGroupE3dc.options.push(optionE3dc5);

    optionGroups.push(optionGroupE3dc);

    // Greenrock options
    const optionGroupGreenrock: OptionGroupModel = new OptionGroupModel();
    optionGroupGreenrock.id = 12;
    optionGroupGreenrock.de = 'Greenrock';
    optionGroupGreenrock.en = 'Greenrock';
    optionGroupGreenrock.fr = 'Greenrock';
    optionGroupGreenrock.it = 'Greenrock';

    const optionGreenrock1: Option = new Option();
    optionGreenrock1.id = 1;
    optionGreenrock1.de = '7.5 kWh';
    optionGreenrock1.en = '7.5 kWh';
    optionGreenrock1.fr = '7.5 kWh';
    optionGreenrock1.it = '7.5 kWh';
    optionGreenrock1.fileName = 'greenrock_battery.png';
    optionGroupGreenrock.options.push(optionGreenrock1);

    const optionGreenrock2: Option = new Option();
    optionGreenrock2.id = 2;
    optionGreenrock2.de = '12.5 kWh';
    optionGreenrock2.en = '12.5 kWh';
    optionGreenrock2.fr = '12.5 kWh';
    optionGreenrock2.it = '12.5 kWh';
    optionGreenrock2.fileName = 'greenrock_battery.png';
    optionGroupGreenrock.options.push(optionGreenrock2);

    const optionGreenrock3: Option = new Option();
    optionGreenrock3.id = 3;
    optionGreenrock3.de = '15 kWh';
    optionGreenrock3.en = '15 kWh';
    optionGreenrock3.fr = '15 kWh';
    optionGreenrock3.it = '15 kWh';
    optionGreenrock3.fileName = 'greenrock_battery.png';
    optionGroupGreenrock.options.push(optionGreenrock3);

    optionGroups.push(optionGroupGreenrock);

    // Smart Energy options
    const optionGroupSmartEnergy: OptionGroupModel = new OptionGroupModel();

    const optionSmartEnergy2: Option = new Option();
    optionSmartEnergy2.id = 2;
    optionSmartEnergy2.de = 'Elektroboiler 1-Step';
    optionSmartEnergy2.en = 'Electric boiler 1-Step';
    optionSmartEnergy2.fr = 'Chaudière électrique 1-step';
    optionSmartEnergy2.it = 'Boiler 1-Step';
    optionSmartEnergy2.fileName = 'SmartEnergyBoiler.svg';
    optionGroupSmartEnergy.options.push(optionSmartEnergy2);

    const optionSmartEnergy9: Option = new Option();
    optionSmartEnergy9.id = 9;
    optionSmartEnergy9.de = 'Elektroboiler 2-Step';
    optionSmartEnergy9.en = 'Electric boiler 2-Step';
    optionSmartEnergy9.fr = 'Chaudière électrique 2-step';
    optionSmartEnergy9.it = 'Boiler 2-Step';
    optionSmartEnergy9.fileName = 'SmartEnergyBoiler.svg';
    optionGroupSmartEnergy.options.push(optionSmartEnergy9);

    const optionSmartEnergy7: Option = new Option();
    optionSmartEnergy7.id = 7;
    optionSmartEnergy7.de = 'Wärmepumpenboiler';
    optionSmartEnergy7.en = 'Heatpump boiler';
    optionSmartEnergy7.fr = 'Chaudière PAC';
    optionSmartEnergy7.it = 'Boiler con pompa di calore';
    optionSmartEnergy7.fileName = 'SmartEnergyBoiler.svg';
    optionGroupSmartEnergy.options.push(optionSmartEnergy7);

    const optionSmartEnergy6: Option = new Option();
    optionSmartEnergy6.id = 6;
    optionSmartEnergy6.de = 'Wärmepumpe';
    optionSmartEnergy6.en = 'Heatpump';
    optionSmartEnergy6.fr = 'PAC';
    optionSmartEnergy6.it = 'Pompa di calore';
    optionSmartEnergy6.fileName = 'SmartEnergyWp.svg';
    optionGroupSmartEnergy.options.push(optionSmartEnergy6);

    const optionSmartEnergy8: Option = new Option();
    optionSmartEnergy8.id = 8;
    optionSmartEnergy8.de = 'Ladestation';
    optionSmartEnergy8.en = 'Charging Station';
    optionSmartEnergy8.fr = 'Station de charge électrique';
    optionSmartEnergy8.it = 'Stazione di ricarica';
    optionSmartEnergy8.fileName = 'electric-car.svg';
    optionGroupSmartEnergy.options.push(optionSmartEnergy8);

    optionGroups.push(optionGroupSmartEnergy);

    // Smart Energy options
    const optionsOtherGroup: OptionGroupModel = new OptionGroupModel();
    optionsOtherGroup.id = 5;

    const optionOthers1: Option = new Option();
    optionOthers1.id = 1;
    optionOthers1.de = 'Solar Edge';
    optionOthers1.en = 'Solar Edge';
    optionOthers1.fr = 'Solar Edge';
    optionOthers1.it = 'Solar Edge';
    optionOthers1.fileName = 'SolarEdge.jpg';
    optionsOtherGroup.options.push(optionOthers1);

    optionGroups.push(optionsOtherGroup);
    console.log(optionGroups);
    return optionGroups;
  }
}
