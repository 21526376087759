import { tassign } from 'tassign';
import * as LeadContact from './lead-contact.action';

export interface IState {
  loading: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isAddedToLeadSuccess: boolean;
  isAddedToLeadFailed: boolean;
}

export const initialState: IState = {
  loading: false,
  isCreated: false,
  isUpdated: false,
  isAddedToLeadFailed: false,
  isAddedToLeadSuccess: false,
};

export function reducer(state: IState = initialState, action: LeadContact.Actions): IState {
  switch (action.type) {
    case LeadContact.UPDATE_LEAD_SINGLE_CONTACT:
    case LeadContact.UPDATE_LEAD_ADDRESS_BOOK_CONTACT:
    case LeadContact.ADD_LEAD_ADDITIONAL_SINGLE_CONTACT:
    case LeadContact.ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT:
    case LeadContact.DELETE_ADDITIONAL_LEAD_CONTACT:
      return tassign(state, {
        loading: true,
        isCreated: false,
        isUpdated: false,
        isAddedToLeadFailed: false,
        isAddedToLeadSuccess: false,
      });

    case LeadContact.LEAD_CONTACT_UPDATE_SUCCESS:
    case LeadContact.LEAD_CONTACT_ADD_SUCCESS:
      return tassign(state, {
        loading: false,
        isCreated: true,
        isUpdated: true,
        isAddedToLeadFailed: false,
        isAddedToLeadSuccess: false,
      });

    case LeadContact.LEAD_CONTACT_UPDATE_FAILED:
      return tassign(state, {
        loading: false,
        isCreated: false,
        isUpdated: false,
        isAddedToLeadFailed: false,
        isAddedToLeadSuccess: false,
      });

    case LeadContact.ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_SUCCESS:
      return tassign(state, {
        loading: false,
        isCreated: false,
        isUpdated: false,
        isAddedToLeadFailed: false,
        isAddedToLeadSuccess: true,
      });

    case LeadContact.ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_SUCCESS:
      return tassign(state, {
        loading: false,
        isCreated: false,
        isUpdated: false,
        isAddedToLeadFailed: true,
        isAddedToLeadSuccess: false,
      });

    default: {
      return state;
    }
  }
}

export const getLoading: (state: IState) => boolean = (state: IState) => state.loading;
export const getIsCreated: (state: IState) => boolean = (state: IState) => state.isCreated;
export const getIsUpdated: (state: IState) => boolean = (state: IState) => state.isUpdated;
export const getIsAddedToLeadFailed: (state: IState) => boolean = (state: IState) =>
  state.isAddedToLeadFailed;
export const getIsAddedToLeadSuccess: (state: IState) => boolean = (state: IState) =>
  state.isAddedToLeadSuccess;
export const getState: (state: IState) => IState = (state: IState) => state;
