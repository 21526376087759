/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TaskViewModel { 
    Id?: number;
    Status?: TaskViewModel.StatusEnum;
    Title: string;
    Description?: string;
    DueDate?: Date;
    LeadId?: number;
    Created?: Date;
    CreatedBy?: string;
    CustomerName?: string;
    AssignedToId?: string;
    LinkedProjectChecklistStepPhaseId?: number;
    LinkedProjectChecklistStepId?: string;
}
export namespace TaskViewModel {
    export type StatusEnum = 'Open' | 'Completed';
    export const StatusEnum = {
        Open: 'Open' as StatusEnum,
        Completed: 'Completed' as StatusEnum
    };
}
