<section class="dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="dialog-title">{{ dialogTitle }}</h2>
    <span>
      <button [attr.fill-type]="'icon'" [cdkMenuTriggerFor]="companyAddressMenu">
        <helion-ui-icon data-feather="more-vertical"></helion-ui-icon>
      </button>
      <ng-template #companyAddressMenu>
        <helion-ui-menu>
          <button class="menu-item" cdkMenuItem (click)="addEmail(person)">
            <helion-ui-icon data-feather="mail"></helion-ui-icon>
            <span>{{ 'ADDRESS.ADD_EMAIL' | translate }}</span>
          </button>
          <button class="menu-item" cdkMenuItem (click)="addPhone(person)">
            <helion-ui-icon data-feather="phone"></helion-ui-icon>
            <span>{{ 'ADDRESS.ADD_PHONE' | translate }}</span>
          </button>
        </helion-ui-menu>
      </ng-template>
    </span>
  </div>

  <div class="dialog-content">
    <form class="person-contact-form">
      <pc-person-contact-form
        #personForm
        [addressType]="addressType"
        [titleItems]="titleItems"
        [person]="person"
        [emailTypes]="emailTypes"
        [logoUrl]="logoUrl"
        [phoneTypes]="phoneTypes"></pc-person-contact-form>
    </form>
    <pc-loading-modal [id]="modalName" type="overlay"></pc-loading-modal>
  </div>

  <div class="dialog-actions">
    <button (click)="onCloseClick()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button [attr.fill-type]="'contained'" [disabled]="saveDisabled()" (click)="save(isNew)">
      {{
        (person.Id && person.Id > 0 ? 'ADDRESS.UPDATE.PERSON' : 'ADDRESS.CREATE_AND_SAVE.PERSON')
          | translate
      }}
    </button>
  </div>
</section>
