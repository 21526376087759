import { StageE } from '../models/enum/stage.enum';
import {
  LeadActionViewModel,
  LeadListViewModel,
  LeadStageViewModel,
  LeadStatusViewModel,
  LeadViewModel,
} from '../apis/advis';

import { PermissionService } from '../services/permission.service';
import { isNullOrUndefined } from './isNullOrUndefined';
import intersectionBy from 'lodash/intersectionBy';

export class StageUtil {
  // @formatter:off
  private static STAGES_TS_ASSIGN_ME: StageE[] = [
    StageE.ASSIGNED_NBO,
    StageE.FOLLOWUP_NBO,
    StageE.UNREACHED_NBO,
  ];

  private static STAGES_S_ASSIGN_ME: StageE[] = [
    StageE.ASSIGNED_BO,
    StageE.FOLLOWUP_BO,
    StageE.UNREACHED_BO,
    StageE.WON,
  ];

  private static STAGES_PM_ASSIGN_ME: StageE[] = [
    StageE.PROJECT_ARCHIVED,
    StageE.PROJECT_ASSIGNED,
    StageE.PROJECT_IN_OPERATION,
    StageE.PROJECT_ACCOUNTED,
    StageE.PROJECT_IN_PROGRESS,
    StageE.WON,
  ];

  private static STAGES_LEAD_SYNCHRONIZE_BO: StageE[] = [
    StageE.WON,
    StageE.PROJECT_ASSIGNED,
    StageE.PROJECT_IN_OPERATION,
    StageE.PROJECT_IN_PROGRESS,
    StageE.PROJECT_ACCOUNTED,
    // all Project sate without archived -> add the new Stage from HP-7192: New Stage and Mandatory documents to finish a project
  ];

  private static STAGES_LEAD_SYNCHRONIZE_ESERP: StageE[] = [
    StageE.PROJECT_IN_OPERATION,
    StageE.PROJECT_ACCOUNTED,
    StageE.PROJECT_ARCHIVED,
  ];

  private static ONLINEPOOL_HOLD_REASONS: string[] = [
    'MISSING_PHONE_AND_NAME',
    'NO_CAPACITY',
    'UNREACHED_MARKETINGAUTOMATION',
    'FORWARDED_THIRDPARTY',
  ];

  private static ALL_DEPRECATED_REASONS: string[] = ['NO_CAPACITY'];

  public static getAllowedActionsForStage(
    stage: string,
    stages: LeadStageViewModel[]
  ): LeadActionViewModel[] {
    let allowedActions: LeadActionViewModel[] = [];
    stages.forEach((x: LeadStageViewModel) => {
      if (x.Key.toLowerCase().localeCompare(stage.toLowerCase()) === 0) {
        allowedActions = x.AllowedLeadActions;
      }
    });
    return allowedActions;
  }

  public static getAbortReasonsForStage(
    stage: string,
    stages: LeadStageViewModel[]
  ): LeadStatusViewModel[] {
    const lead: LeadActionViewModel[] = StageUtil.getAllowedActionsForStage(stage, stages).filter(
      (x: LeadActionViewModel) => {
        return x.NextStageKey === 'ABORT';
      }
    );
    if (!isNullOrUndefined(lead) && lead.length > 0) {
      return this.sortReasons(lead[0].AllowedNextStatuses);
    } else {
      return [];
    }
  }

  public static getOnHoldReasonsForStage(
    stage: string,
    stages: LeadStageViewModel[],
    isOnlinePool: boolean,
    isNew: boolean
  ): LeadStatusViewModel[] {
    let actionsForStage: LeadActionViewModel[] = StageUtil.getAllowedActionsForStage(stage, stages);

    actionsForStage = actionsForStage.filter((x: LeadActionViewModel) => {
      return x.NextStageKey === 'ON_HOLD';
    });

    const reasons = isNullOrUndefined(actionsForStage[0])
      ? []
      : this.sortReasons(actionsForStage[0].AllowedNextStatuses);

    return isOnlinePool || isNew
      ? reasons.filter(r => this.ONLINEPOOL_HOLD_REASONS.includes(r.Name))
      : reasons.filter(r => !this.ALL_DEPRECATED_REASONS.includes(r.Name));
  }

  public static getAbortReasonsForMultipleStages(
    leadStages: string[],
    stages: LeadStageViewModel[]
  ): any {
    const allAbortReasons: LeadStatusViewModel[][] = leadStages
      .map((stage: string) => StageUtil.getAbortReasonsForStage(stage, stages))
      .filter((singleArray: LeadStatusViewModel[]) => singleArray.length > 0);

    const commonReasons: LeadStatusViewModel[] = intersectionBy(
      ...allAbortReasons,
      'Name'
    ) as LeadStatusViewModel[];

    return this.sortReasons(commonReasons);
  }

  public static getOnHoldReasonsForMultipleStages(
    leadStages: string[],
    stages: LeadStageViewModel[],
    isOnlinePool: boolean,
    isNew: boolean
  ): LeadStatusViewModel[] {
    const allOnHoldReasons: LeadStatusViewModel[][] = leadStages
      .map((leadStage: string) =>
        StageUtil.getOnHoldReasonsForStage(leadStage, stages, isOnlinePool, isNew)
      )
      .filter((singleArray: LeadStatusViewModel[]) => singleArray.length > 0);

    const commonReasons: LeadStatusViewModel[] = intersectionBy(
      ...allOnHoldReasons,
      'Name'
    ) as LeadStatusViewModel[];

    const sortedReasons = this.sortReasons(commonReasons);

    return isOnlinePool || isNew
      ? sortedReasons.filter(r => this.ONLINEPOOL_HOLD_REASONS.includes(r.Name))
      : sortedReasons.filter(r => !this.ALL_DEPRECATED_REASONS.includes(r.Name));
  }

  public static isStageInArr(stageStr: string, stagesArr: StageE[]): boolean {
    if (!isNullOrUndefined(stageStr)) {
      for (let idx: number = 0; idx < stagesArr.length; idx++) {
        const stage: any = stagesArr[idx];
        if (stageStr.toLowerCase().localeCompare(stage.toLowerCase()) === 0) {
          return true;
        }
      }
    }
    return false;
  }

  public static isStage(stageStr: string, stage: StageE): boolean {
    const value: string = StageE[stage];

    return (
      !isNullOrUndefined(stageStr) &&
      stageStr.toLowerCase().localeCompare(value.toLowerCase()) === 0
    );
  }

  public static canAssignMe<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    permissions: PermissionService
  ): boolean {
    return StageUtil.isStageInArr(lead.Stage, this.getAssignMeStages(permissions));
  }

  public static canProjectSynchronize<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    permissions: PermissionService
  ): boolean {
    return (
      StageUtil.isStageInArr(lead.Stage, StageUtil.STAGES_LEAD_SYNCHRONIZE_BO) &&
      permissions.userPermissions.CanSeeHelionImport
    );
  }

  public static canReleaseAndAssignnMe<LeadType extends LeadViewModel | LeadListViewModel>(
    lead: LeadType,
    permissions: PermissionService
  ): boolean {
    return StageUtil.isStageInArr(
      lead.PreviousStage,
      this.addReleaseStages(this.getAssignMeStages(permissions))
    );
  }

  private static sortReasons(reasons: LeadStatusViewModel[]): LeadStatusViewModel[] {
    return [...reasons].sort((l: LeadStatusViewModel, r: LeadStatusViewModel) =>
      l.LocalizedName.localeCompare(r.LocalizedName)
    );
  }

  private static getAssignMeStages(permissions: PermissionService): StageE[] {
    const stages: StageE[] = [];

    if (permissions.userPermissions.CanAssignMeNew) {
      stages.push(StageE.NEW);
    }

    if (permissions.userPermissions.CanAssignMeNbo) {
      stages.push(...StageUtil.STAGES_TS_ASSIGN_ME);
    } else if (permissions.userPermissions.CanSeeProjectLeads) {
      stages.push(...StageUtil.STAGES_PM_ASSIGN_ME);
    } else if (permissions.userPermissions.CanAssignMeBo) {
      stages.push(...StageUtil.STAGES_S_ASSIGN_ME);
    }

    return stages;
  }

  private static addReleaseStages(stages: StageE[]): StageE[] {
    let releaseStages: StageE[] = [];
    releaseStages = releaseStages.concat(stages, StageE.ON_HOLD, StageE.ABORT, StageE.MIGRATION);
    return releaseStages;
  }

  public static isInEsErpProjectTransferStage(stage: string): boolean {
    return this.isStageInArr(stage, this.STAGES_LEAD_SYNCHRONIZE_ESERP);
  }
}
