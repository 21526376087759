import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
// @ts-ignore
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { BlueOfficeCacheManagementService } from './api/blueOfficeCacheManagement.service';
import { BuildingPlayerApiAccessService } from './api/buildingPlayerApiAccess.service';
import { DirectoryService } from './api/directory.service';
import { DocumentService } from './api/document.service';
import { ElektroFormService } from './api/elektroForm.service';
import { EmailService } from './api/email.service';
import { FieldServiceService } from './api/fieldService.service';
import { GoogleApiAccessService } from './api/googleApiAccess.service';
import { InvoiceService } from './api/invoice.service';
import { LeadService } from './api/lead.service';
import { LeadActionsService } from './api/leadActions.service';
import { LeadChecklistService } from './api/leadChecklist.service';
import { LeadFileService } from './api/leadFile.service';
import { LeadNoteService } from './api/leadNote.service';
import { MasterDataService } from './api/masterData.service';
import { MeierToblerService } from './api/meierTobler.service';
import { MicrosoftGraphService } from './api/microsoftGraph.service';
import { ProjectReportingService } from './api/projectReporting.service';
import { SsoAccountService } from './api/ssoAccount.service';
import { UserService } from './api/user.service';
import { VariantService } from './api/variant.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    BlueOfficeCacheManagementService,
    BuildingPlayerApiAccessService,
    DirectoryService,
    DocumentService,
    ElektroFormService,
    EmailService,
    FieldServiceService,
    GoogleApiAccessService,
    InvoiceService,
    LeadService,
    LeadActionsService,
    LeadChecklistService,
    LeadFileService,
    LeadNoteService,
    MasterDataService,
    MeierToblerService,
    MicrosoftGraphService,
    ProjectReportingService,
    SsoAccountService,
    UserService,
    VariantService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
