/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AddSpecialPositionRequest { 
    Type?: AddSpecialPositionRequest.TypeEnum;
    Title?: string;
    IsOption?: boolean;
    Order?: number;
    GroupId?: string;
}
export namespace AddSpecialPositionRequest {
    export type TypeEnum = 'Title' | 'Text' | 'PageBreak';
    export const TypeEnum = {
        Title: 'Title' as TypeEnum,
        Text: 'Text' as TypeEnum,
        PageBreak: 'PageBreak' as TypeEnum
    };
}
