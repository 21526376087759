import { Component, Inject, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@sitewerk/theia-ui-lib';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../../shared/states';
import {
  MasterDataMandatorTradeTypeViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { Subscription } from 'rxjs';

export interface ILeadComplexityTypeDialogData {
  leadComplexityType: string;
  mandantId: number;
  tradeTypeCode: string;
}

@Component({
  selector: 'pc-lead-complexity-type-dialog',
  templateUrl: './lead-complexity-type-dialog.component.html',
  styleUrls: ['./lead-complexity-type-dialog.component.scss'],
})
export class LeadComplexityTypeDialogComponent implements OnInit, OnDestroy {
  @ViewChild('input') public input: ElementRef;

  protected tradeType: MasterDataMandatorTradeTypeViewModel;
  protected selectedComplexityTypes: string;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: ILeadComplexityTypeDialogData,
    private store: Store<RootReducer.IState>
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store
        .select<MasterDataViewModel>(RootReducer.getGlobalMasterData)
        .subscribe(masterData => {
          const mandator = masterData.Mandators.find(m => m.Id === this.data.mandantId);
          this.tradeType = mandator.TradeTypes.find(t => t.Code === this.data.tradeTypeCode);
          this.selectedComplexityTypes = this.data.leadComplexityType;
        })
    );
  }

  public onOk(): void {
    this.dialogRef.close(this.selectedComplexityTypes);
  }

  public onCancel(): void {
    this.dialogRef.close(undefined);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
