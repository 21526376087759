import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  GET_TEXT_EDITOR_LICENSE_FAILURE,
  GET_TEXT_EDITOR_LICENSE_SUCCESS,
  GetTextEditorLicenseAction,
} from '../../shared/states/licenses/licenses.action';

type LicenseType = 'textEditor';

@Injectable({
  providedIn: 'root',
})
// This service makes sure that licenses are only requested once, and that the result is shared between all components that subscribe to it
export class LicenseManagementService {
  private licenseSubjects: Map<LicenseType, ReplaySubject<string | null>> = new Map();
  private licenseRequestStatus: Map<LicenseType, boolean> = new Map();

  constructor(private store: Store, private actions$: Actions) {}

  loadLicense(licenseType: LicenseType): void {
    if (!this.licenseSubjects.has(licenseType)) {
      this.licenseSubjects.set(licenseType, new ReplaySubject<string | null>(1));
    }

    if (!this.licenseRequestStatus.get(licenseType)) {
      this.licenseRequestStatus.set(licenseType, true);

      this.dispatchGetLicenseAction(licenseType);

      // success
      this.actions$
        .pipe(
          ofType(this.getSuccessActionType(licenseType)),
          take(1),
          map((action: any) => action.payload.LicenseKey)
        )
        .subscribe(licenseKey => {
          this.licenseSubjects.get(licenseType)?.next(licenseKey);
          this.licenseSubjects.get(licenseType)?.complete();
          console.info(`Successfully retrieved license key for ${licenseType}`);
        });

      // failure
      this.actions$.pipe(ofType(this.getFailureActionType(licenseType)), take(1)).subscribe(() => {
        console.error(`Failed to get license key for ${licenseType}`);
        this.licenseSubjects.get(licenseType)?.next(null);
        this.licenseSubjects.get(licenseType)?.complete();
      });
    }
  }

  public getLicenseKey$(licenseType: LicenseType): Observable<string | null> {
    if (!this.licenseSubjects.has(licenseType)) {
      this.loadLicense(licenseType);
    }
    return this.licenseSubjects.get(licenseType).asObservable().pipe(take(1));
  }

  private dispatchGetLicenseAction(licenseType: LicenseType): void {
    switch (licenseType) {
      case 'textEditor':
        this.store.dispatch(new GetTextEditorLicenseAction());
        break;
      default:
        throw new Error(`No action configured for license type: ${licenseType}`);
    }
  }

  private getSuccessActionType(licenseType: LicenseType) {
    switch (licenseType) {
      case 'textEditor':
        return GET_TEXT_EDITOR_LICENSE_SUCCESS;
      default:
        throw new Error(`No success action type configured for license type: ${licenseType}`);
    }
  }

  private getFailureActionType(licenseType: LicenseType) {
    switch (licenseType) {
      case 'textEditor':
        return GET_TEXT_EDITOR_LICENSE_FAILURE;
      default:
        throw new Error(`No failure action type configured for license type: ${licenseType}`);
    }
  }
}
