import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  AdditionalAddressTypeViewModel,
  LeadCompositeViewModel,
  LeadContactViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ContactViewModelUtil } from '../../../shared/utils/contact-view-model.util';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../../shared/utils/exter-managed-address-type.util';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

export interface IAddressWithLocalizedTitle extends LeadContactViewModel {
  localizedTitle: string;
}

@Component({
  selector: 'pc-address-card-content',
  templateUrl: './address-card-content.component.html',
  styleUrls: ['./address-card-content.component.scss'],
})
export class AddressCardContentComponent implements OnChanges, OnDestroy {
  @Input() public isReadOnly: boolean = false;

  @Input() public lead: LeadCompositeViewModel;

  @Input() public masterData: MasterDataViewModel;

  public allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  public debitorTitle: string;

  public emptyContactAddress: LeadContactViewModel = ContactViewModelUtil.createEmptyPersonContact(
    'Contact',
    true
  );

  public additionalAddresses: IAddressWithLocalizedTitle[] = [];
  public externalManagedAddresses: IAddressWithLocalizedTitle[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<RootReducer.IState>,
    private translate: TranslateService
  ) {
    this.debitorTitle = this.translate.instant('LEAD_DETAIL.DEBITOR_ADDR');
    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
  }

  public ngOnChanges(): void {
    if (this.lead && this.masterData) {
      this.additionalAddresses = this.lead.Lead.AdditionalAddresses.filter(
        x =>
          !ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
            x.AddressType,
            this.allExternalManagedAddressConfig
          )
      )
        .map((a: LeadContactViewModel) => ({
          ...a,
          localizedTitle: this.getLeadAddressTitle(a.AddressType),
        }))
        .sort((l: IAddressWithLocalizedTitle, r: IAddressWithLocalizedTitle) =>
          l.localizedTitle.toLowerCase().localeCompare(r.localizedTitle.toLowerCase())
        );

      this.externalManagedAddresses = this.lead.Lead.AdditionalAddresses.filter(x =>
        ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
          x.AddressType,
          this.allExternalManagedAddressConfig
        )
      )
        .map((a: LeadContactViewModel) => ({
          ...a,
          localizedTitle: this.getLeadAddressTitle(a.AddressType),
        }))
        .sort((l: IAddressWithLocalizedTitle, r: IAddressWithLocalizedTitle) =>
          l.localizedTitle.toLowerCase().localeCompare(r.localizedTitle.toLowerCase())
        );
    }
  }

  public onEditPerson(): void {
    if (!this.isReadOnly) {
      this.router.navigate(['lead/person', this.lead.Lead.Id]);
    }
  }

  public onEditObjectAddress(): void {
    if (!this.isReadOnly) {
      this.router.navigate(['lead/objectaddr', this.lead.Lead.Id]);
    }
  }

  public onEditDebitorAddress(): void {
    if (!this.isReadOnly) {
      this.router.navigate(['lead/debitoraddr', this.lead.Lead.Id]);
    }
  }

  public onEditLeadAddress(address: LeadContactViewModel): void {
    if (!this.isReadOnly) {
      this.router.navigate(['lead/leadaddr', this.lead.Lead.Id], {
        queryParams: { addressId: address.Id },
      });
    }
  }

  public onAddAddress(): void {
    if (!this.isReadOnly) {
      this.router.navigate(['lead/leadaddr', this.lead.Lead.Id]);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getLeadAddressTitle(addressType: LeadContactViewModel.AddressTypeEnum): string {
    if (addressType === LeadContactViewModel.AddressTypeEnum.Debitor) {
      return this.translate.instant('LEAD_DETAIL.DEBITOR_ADDR');
    }

    return this.masterData.AllAdditionalAddressTypes.find(
      (t: AdditionalAddressTypeViewModel) => t.Key === addressType
    ).Translation;
  }
}
