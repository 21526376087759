import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as TaskAction from './task.action';
import { ConfigService } from '../../services/config.service';
import { TaskViewModel } from '../../apis/advis';
import { LeadService } from '../../apis/advis';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Injectable()
export class TaskEffects {
  taskLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskAction.TASKS_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: TaskAction.TasksLoadAction) => action.payload),
      switchMap(() => {
        return this.leadService.pCApiLeadControllerGetTasksForCurrentUser().pipe(
          map((tasks: TaskViewModel[]) => {
            return new TaskAction.TasksLoadSuccessAction(tasks);
          }),
          catchError((e: any) => {
            console.info('Failed to load tasks', e);
            return of(new TaskAction.TasksLoadFailureAction(e));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private leadService: LeadService,
    private config: ConfigService
  ) {}
}
