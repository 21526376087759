/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PatchChecklistPhaseRequest } from '../model/patchChecklistPhaseRequest';
import { ProjectChecklistTemplateViewModel } from '../model/projectChecklistTemplateViewModel';
import { ProjectChecklistViewModel } from '../model/projectChecklistViewModel';
import { SopChecklistViewModel } from '../model/sopChecklistViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LeadChecklistService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Patches a checklist step, when a checklist step has been clicked. Returns the updated checklist without any SOP information.
     * 
     * @param leadId The lead identifier.
     * @param stepId The id of the clicked step.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistClickChecklistStep(leadId: number, stepId?: string, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistClickChecklistStep(leadId: number, stepId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistClickChecklistStep(leadId: number, stepId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistClickChecklistStep(leadId: number, stepId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistClickChecklistStep.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stepId !== undefined && stepId !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/click-checklist-step`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches a checklist step that belongs to a SOP checklist. Returns an SopChecklistViewModel that contains the updated SOP List information.  In other words, this endpoint returns updated SOP Step information if a checklist step is clicked.
     * 
     * @param leadId The lead identifier.
     * @param stepId The id of the clicked step.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistClickChecklistStepWithSop(leadId: number, stepId?: string, observe?: 'body', reportProgress?: boolean): Observable<SopChecklistViewModel>;
    public projectChecklistClickChecklistStepWithSop(leadId: number, stepId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopChecklistViewModel>>;
    public projectChecklistClickChecklistStepWithSop(leadId: number, stepId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopChecklistViewModel>>;
    public projectChecklistClickChecklistStepWithSop(leadId: number, stepId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistClickChecklistStepWithSop.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stepId !== undefined && stepId !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<SopChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/click-checklist-step-with-sop`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cascading propagation of a an action to child steps belonging to this phase
     * 
     * @param leadId The lead identifier.
     * @param phaseKey The key of the enum phase value.
     * @param desiredCheckedState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistClickSopPhase(leadId: number, phaseKey?: number, desiredCheckedState?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistClickSopPhase(leadId: number, phaseKey?: number, desiredCheckedState?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistClickSopPhase(leadId: number, phaseKey?: number, desiredCheckedState?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistClickSopPhase(leadId: number, phaseKey?: number, desiredCheckedState?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistClickSopPhase.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (phaseKey !== undefined && phaseKey !== null) {
            queryParameters = queryParameters.set('phaseKey', <any>phaseKey);
        }
        if (desiredCheckedState !== undefined && desiredCheckedState !== null) {
            queryParameters = queryParameters.set('desiredCheckedState', <any>desiredCheckedState);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/click-sop-phase`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches checklist steps that are linked to SOP step
     * 
     * @param leadId The lead identifier.
     * @param stepId The id of the clicked SOP step.
     * @param desiredCheckedState Overwrites checked state of checklist steps with this value.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistClickSopStep(leadId: number, stepId?: number, desiredCheckedState?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistClickSopStep(leadId: number, stepId?: number, desiredCheckedState?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistClickSopStep(leadId: number, stepId?: number, desiredCheckedState?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistClickSopStep(leadId: number, stepId?: number, desiredCheckedState?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistClickSopStep.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stepId !== undefined && stepId !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId);
        }
        if (desiredCheckedState !== undefined && desiredCheckedState !== null) {
            queryParameters = queryParameters.set('desiredCheckedState', <any>desiredCheckedState);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/click-sop-step`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new checklist for a lead from a template  This also deletes the existing checklist if there was one
     * 
     * @param leadId The lead identifier.
     * @param templateId The checklist template id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistCreateChecklistFromTemplate(leadId: number, templateId?: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistCreateChecklistFromTemplate(leadId: number, templateId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistCreateChecklistFromTemplate(leadId: number, templateId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistCreateChecklistFromTemplate(leadId: number, templateId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistCreateChecklistFromTemplate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/checklist`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all active checklist templates that are applicable for the given lead.
     * 
     * @param leadId The lead id for which to receive possible active templates for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistGetAvailableChecklistTemplatesForLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectChecklistTemplateViewModel>>;
    public projectChecklistGetAvailableChecklistTemplatesForLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectChecklistTemplateViewModel>>>;
    public projectChecklistGetAvailableChecklistTemplatesForLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectChecklistTemplateViewModel>>>;
    public projectChecklistGetAvailableChecklistTemplatesForLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistGetAvailableChecklistTemplatesForLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectChecklistTemplateViewModel>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/checklist-templates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a checklist for a lead. May return not found if the lead has no checklist
     * 
     * @param leadId The lead identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistGetChecklistForLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistGetChecklistForLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistGetChecklistForLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistGetChecklistForLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistGetChecklistForLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/checklist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistGetSopChecklistForLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<SopChecklistViewModel>;
    public projectChecklistGetSopChecklistForLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SopChecklistViewModel>>;
    public projectChecklistGetSopChecklistForLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SopChecklistViewModel>>;
    public projectChecklistGetSopChecklistForLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistGetSopChecklistForLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<SopChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/sop-checklist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches one more checklist phases for an existing checklist
     * 
     * @param leadId The lead identifier.
     * @param body Request parameters to patch a step.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectChecklistPatchChecklistPhase(leadId: number, body?: Array<PatchChecklistPhaseRequest>, observe?: 'body', reportProgress?: boolean): Observable<ProjectChecklistViewModel>;
    public projectChecklistPatchChecklistPhase(leadId: number, body?: Array<PatchChecklistPhaseRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectChecklistViewModel>>;
    public projectChecklistPatchChecklistPhase(leadId: number, body?: Array<PatchChecklistPhaseRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectChecklistViewModel>>;
    public projectChecklistPatchChecklistPhase(leadId: number, body?: Array<PatchChecklistPhaseRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectChecklistPatchChecklistPhase.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<ProjectChecklistViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/checklist-phase`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
