import { Action } from '@ngrx/store';

export const GET_MASTER_DATA: string = '[EM] Get masterdata';
export const GET_MASTER_DATA_SUCCESS: string = '[EM] Get masterdata success';
export const GET_MASTER_DATA_FAILED: string = '[EM] Get masterdata failed';

export class GetEmMasterDataAction implements Action {
  readonly type: string = GET_MASTER_DATA;

  constructor(public payload?: undefined) {}
}

export class GetEmMasterDataSuccessAction implements Action {
  readonly type: string = GET_MASTER_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetEmMasterDataFailedAction implements Action {
  readonly type: string = GET_MASTER_DATA_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | GetEmMasterDataAction
  | GetEmMasterDataSuccessAction
  | GetEmMasterDataFailedAction;
