export * from './account.service';
import { AccountService } from './account.service';
export * from './blueOfficeCacheManagement.service';
import { BlueOfficeCacheManagementService } from './blueOfficeCacheManagement.service';
export * from './buildingPlayerApiAccess.service';
import { BuildingPlayerApiAccessService } from './buildingPlayerApiAccess.service';
export * from './directory.service';
import { DirectoryService } from './directory.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './elektroForm.service';
import { ElektroFormService } from './elektroForm.service';
export * from './email.service';
import { EmailService } from './email.service';
export * from './fieldService.service';
import { FieldServiceService } from './fieldService.service';
export * from './googleApiAccess.service';
import { GoogleApiAccessService } from './googleApiAccess.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './lead.service';
import { LeadService } from './lead.service';
export * from './leadActions.service';
import { LeadActionsService } from './leadActions.service';
export * from './leadChecklist.service';
import { LeadChecklistService } from './leadChecklist.service';
export * from './leadFile.service';
import { LeadFileService } from './leadFile.service';
export * from './leadNote.service';
import { LeadNoteService } from './leadNote.service';
export * from './masterData.service';
import { MasterDataService } from './masterData.service';
export * from './meierTobler.service';
import { MeierToblerService } from './meierTobler.service';
export * from './microsoftGraph.service';
import { MicrosoftGraphService } from './microsoftGraph.service';
export * from './projectReporting.service';
import { ProjectReportingService } from './projectReporting.service';
export * from './ssoAccount.service';
import { SsoAccountService } from './ssoAccount.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './variant.service';
import { VariantService } from './variant.service';
export const APIS = [AccountService, BlueOfficeCacheManagementService, BuildingPlayerApiAccessService, DirectoryService, DocumentService, ElektroFormService, EmailService, FieldServiceService, GoogleApiAccessService, InvoiceService, LeadService, LeadActionsService, LeadChecklistService, LeadFileService, LeadNoteService, MasterDataService, MeierToblerService, MicrosoftGraphService, ProjectReportingService, SsoAccountService, UserService, VariantService];
