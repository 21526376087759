/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ProjectChecklistPhaseSummaryViewModel { 
    Phase?: ProjectChecklistPhaseSummaryViewModel.PhaseEnum;
    CheckedState?: ProjectChecklistPhaseSummaryViewModel.CheckedStateEnum;
    Title?: string;
    IsTimeWarning?: boolean;
    StartDate?: Date;
    LastUpdateDate?: Date;
    CompletedSteps?: number;
    TotalSteps?: number;
}
export namespace ProjectChecklistPhaseSummaryViewModel {
    export type PhaseEnum = 'Definition' | 'Planning' | 'Sourcing' | 'Preparation' | 'Installation' | 'Closure';
    export const PhaseEnum = {
        Definition: 'Definition' as PhaseEnum,
        Planning: 'Planning' as PhaseEnum,
        Sourcing: 'Sourcing' as PhaseEnum,
        Preparation: 'Preparation' as PhaseEnum,
        Installation: 'Installation' as PhaseEnum,
        Closure: 'Closure' as PhaseEnum
    };
    export type CheckedStateEnum = 'Unchecked' | 'PartiallyChecked' | 'Checked';
    export const CheckedStateEnum = {
        Unchecked: 'Unchecked' as CheckedStateEnum,
        PartiallyChecked: 'PartiallyChecked' as CheckedStateEnum,
        Checked: 'Checked' as CheckedStateEnum
    };
}
