import { IViewSettingBase } from './view-setting-base.model';
import { LeadOverviewFilterVsModel } from './lead-overview-settings.model';

export class LeadOverviewOwnPanelVsModel {
  public panelNewExpanded: boolean = true;
  public panelFollowUpExpanded: boolean = true;
  public panelUnreachedExpanded: boolean = true;
  public panelDelegatedExpanded: boolean = true;
  public panelDelegatedToMeExpanded: boolean = true;
  public panelProjectAssigned: boolean = true;
  public panelProjectArchived: boolean = true;
  public panelProjectInOperation: boolean = true;
  public panelProjectInProgress: boolean = true;
  public panelProjectAccounted: boolean = true;
  public panelOrderEntryExpanded: boolean = true;

  clone(): LeadOverviewOwnPanelVsModel {
    const panel: LeadOverviewOwnPanelVsModel = new LeadOverviewOwnPanelVsModel();
    panel.panelNewExpanded = this.panelNewExpanded;
    panel.panelFollowUpExpanded = this.panelFollowUpExpanded;
    panel.panelUnreachedExpanded = this.panelUnreachedExpanded;
    panel.panelDelegatedExpanded = this.panelDelegatedExpanded;
    panel.panelDelegatedToMeExpanded = this.panelDelegatedToMeExpanded;
    panel.panelProjectAssigned = this.panelProjectAssigned;
    panel.panelProjectArchived = this.panelProjectArchived;
    panel.panelProjectInOperation = this.panelProjectInOperation;
    panel.panelProjectInProgress = this.panelProjectInProgress;
    panel.panelProjectAccounted = this.panelProjectAccounted;
    panel.panelOrderEntryExpanded = this.panelOrderEntryExpanded;

    return panel;
  }
}

export class LeadOverviewOwnVsModel implements IViewSettingBase<LeadOverviewOwnVsModel> {
  public filter: LeadOverviewFilterVsModel = new LeadOverviewFilterVsModel();
  public filterVisible: boolean = true;

  public newSort: any[] = [];
  public unreachedSort: any[] = [];
  public delegatedSort: any[] = [];
  public delegatedToMeSort: any[] = [];
  public followupSort: any[] = [];
  public orderEntrySort: any[] = [];
  public projectAssignedSort: any[] = [];
  public projectArchivedSort: any[] = [];
  public projectInOperationSort: any[] = [];
  public projectInProgressSort: any[] = [];
  public projectAccountedSort: any[] = [];

  public newPage: number = 0;
  public unreachedPage: number = 0;
  public delegatedPage: number = 0;
  public delegatedToMePage: number = 0;
  public followupPage: number = 0;
  public orderEntryPage: number = 0;
  public projectAssignedPage: number = 0;
  public projectArchivedPage: number = 0;
  public projectInOperationPage: number = 0;
  public projectInProgressPage: number = 0;
  public projectAccountedPage: number = 0;

  public panel: LeadOverviewOwnPanelVsModel = new LeadOverviewOwnPanelVsModel();

  clone(): LeadOverviewOwnVsModel {
    const model: LeadOverviewOwnVsModel = new LeadOverviewOwnVsModel();
    model.filter = this.filter.clone();
    model.filterVisible = this.filterVisible;

    model.newSort = this.newSort;
    model.unreachedSort = this.unreachedSort;
    model.delegatedSort = this.delegatedSort;
    model.delegatedToMeSort = this.delegatedToMeSort;
    model.followupSort = this.followupSort;
    model.orderEntrySort = this.orderEntrySort;
    model.projectAssignedSort = this.projectAssignedSort;
    model.projectArchivedSort = this.projectArchivedSort;
    model.projectInOperationSort = this.projectInOperationSort;
    model.projectInProgressSort = this.projectInProgressSort;

    model.newPage = this.newPage;
    model.unreachedPage = this.unreachedPage;
    model.delegatedPage = this.delegatedPage;
    model.delegatedToMePage = this.delegatedToMePage;
    model.followupPage = this.followupPage;
    model.orderEntryPage = this.orderEntryPage;
    model.projectAssignedPage = this.projectAssignedPage;
    model.projectArchivedPage = this.projectArchivedPage;
    model.projectInOperationPage = this.projectInOperationPage;
    model.projectInProgressPage = this.projectInProgressPage;
    model.panel = this.panel.clone();

    return model;
  }
}
