/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { NoteTemplateViewModel } from '../model/noteTemplateViewModel';
import { NoteViewModel } from '../model/noteViewModel';
import { PmInfoNoteUpdateRequest } from '../model/pmInfoNoteUpdateRequest';
import { SaveNoteTemplateRequest } from '../model/saveNoteTemplateRequest';
import { UpdateTextNoteRequest } from '../model/updateTextNoteRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LeadNoteService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds an drawing note to a lead
     * 
     * @param leadId the id of the lead
     * @param drawingImage 
     * @param Tags 
     * @param Category 
     * @param DrawingData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteAddDrawingNote(leadId: number, drawingImage: Blob, Tags?: Array<string>, Category?: string, DrawingData?: string, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteAddDrawingNote(leadId: number, drawingImage: Blob, Tags?: Array<string>, Category?: string, DrawingData?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteAddDrawingNote(leadId: number, drawingImage: Blob, Tags?: Array<string>, Category?: string, DrawingData?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteAddDrawingNote(leadId: number, drawingImage: Blob, Tags?: Array<string>, Category?: string, DrawingData?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteAddDrawingNote.');
        }

        if (drawingImage === null || drawingImage === undefined) {
            throw new Error('Required parameter drawingImage was null or undefined when calling leadNoteAddDrawingNote.');
        }




        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Tags) {
            formParams = formParams.append('Tags', Tags.join(COLLECTION_FORMATS['csv'])) || formParams;
        }
        if (Category !== undefined) {
            formParams = formParams.append('Category', <any>Category) || formParams;
        }
        if (DrawingData !== undefined) {
            formParams = formParams.append('DrawingData', <any>DrawingData) || formParams;
        }
        if (drawingImage !== undefined) {
            formParams = formParams.append('drawingImage', <any>drawingImage) || formParams;
        }

        return this.httpClient.post<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/drawing`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a pm-info text note to a lead
     * 
     * @param leadId the id of the lead
     * @param body the note
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteAddPmInfoTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteAddPmInfoTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteAddPmInfoTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteAddPmInfoTextNote(leadId: number, body?: UpdateTextNoteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteAddPmInfoTextNote.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/pm-info`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a text note to a lead
     * 
     * @param leadId the id of the lead
     * @param body the note
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteAddTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteAddTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteAddTextNote(leadId: number, body?: UpdateTextNoteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteAddTextNote(leadId: number, body?: UpdateTextNoteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteAddTextNote.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/text`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the existing note tags list.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetExistingNoteTagsList(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public leadNoteGetExistingNoteTagsList(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public leadNoteGetExistingNoteTagsList(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public leadNoteGetExistingNoteTagsList(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteGetExistingNoteTagsList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/existing-note-tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a note templates for a lead
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetIndividualNoteTemplates(observe?: 'body', reportProgress?: boolean): Observable<Array<NoteTemplateViewModel>>;
    public leadNoteGetIndividualNoteTemplates(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NoteTemplateViewModel>>>;
    public leadNoteGetIndividualNoteTemplates(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NoteTemplateViewModel>>>;
    public leadNoteGetIndividualNoteTemplates(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NoteTemplateViewModel>>(`${this.basePath}/api/pc/leads/note-templates/individual`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a note from a lead
     * 
     * @param leadId the id of the lead
     * @param noteId the id of the note
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetNote(leadId: number, noteId: number, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteGetNote(leadId: number, noteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteGetNote(leadId: number, noteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteGetNote(leadId: number, noteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteGetNote.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling leadNoteGetNote.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/${encodeURIComponent(String(noteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a note templates for a lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetNoteTemplates(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<NoteTemplateViewModel>>;
    public leadNoteGetNoteTemplates(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NoteTemplateViewModel>>>;
    public leadNoteGetNoteTemplates(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NoteTemplateViewModel>>>;
    public leadNoteGetNoteTemplates(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteGetNoteTemplates.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NoteTemplateViewModel>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/note-templates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all notes from a lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetNotes(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<NoteViewModel>>;
    public leadNoteGetNotes(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NoteViewModel>>>;
    public leadNoteGetNotes(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NoteViewModel>>>;
    public leadNoteGetNotes(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteGetNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NoteViewModel>>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves pm-info note from a lead
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteGetPmInfoNote(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteGetPmInfoNote(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteGetPmInfoNote(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteGetPmInfoNote(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteGetPmInfoNote.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/pm-info-note`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes a note from a lead
     * 
     * @param leadId the id of the lead
     * @param noteId the id of the note
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteRemoveNote(leadId: number, noteId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public leadNoteRemoveNote(leadId: number, noteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public leadNoteRemoveNote(leadId: number, noteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public leadNoteRemoveNote(leadId: number, noteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteRemoveNote.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling leadNoteRemoveNote.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/${encodeURIComponent(String(noteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Saves the note template.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteSaveNoteTemplate(body?: SaveNoteTemplateRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<NoteTemplateViewModel>>;
    public leadNoteSaveNoteTemplate(body?: SaveNoteTemplateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NoteTemplateViewModel>>>;
    public leadNoteSaveNoteTemplate(body?: SaveNoteTemplateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NoteTemplateViewModel>>>;
    public leadNoteSaveNoteTemplate(body?: SaveNoteTemplateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<NoteTemplateViewModel>>(`${this.basePath}/api/pc/leads/note-templates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates and existing note of a lead
     * 
     * @param leadId the id of the lead
     * @param noteId the id of the note
     * @param Tags 
     * @param Category 
     * @param DrawingData 
     * @param drawingImage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteUpdateDrawingNote(leadId: number, noteId: number, Tags?: Array<string>, Category?: string, DrawingData?: string, drawingImage?: Blob, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteUpdateDrawingNote(leadId: number, noteId: number, Tags?: Array<string>, Category?: string, DrawingData?: string, drawingImage?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteUpdateDrawingNote(leadId: number, noteId: number, Tags?: Array<string>, Category?: string, DrawingData?: string, drawingImage?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteUpdateDrawingNote(leadId: number, noteId: number, Tags?: Array<string>, Category?: string, DrawingData?: string, drawingImage?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteUpdateDrawingNote.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling leadNoteUpdateDrawingNote.');
        }





        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Tags) {
            formParams = formParams.append('Tags', Tags.join(COLLECTION_FORMATS['csv'])) || formParams;
        }
        if (Category !== undefined) {
            formParams = formParams.append('Category', <any>Category) || formParams;
        }
        if (DrawingData !== undefined) {
            formParams = formParams.append('DrawingData', <any>DrawingData) || formParams;
        }
        if (drawingImage !== undefined) {
            formParams = formParams.append('drawingImage', <any>drawingImage) || formParams;
        }

        return this.httpClient.put<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/drawing/${encodeURIComponent(String(noteId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates and existing pm-info note of a lead
     * 
     * @param leadId the id of the lead
     * @param noteId the id of the note
     * @param body the note data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteUpdatePmInfoTextNote(leadId: number, noteId: number, body?: PmInfoNoteUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteUpdatePmInfoTextNote(leadId: number, noteId: number, body?: PmInfoNoteUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteUpdatePmInfoTextNote(leadId: number, noteId: number, body?: PmInfoNoteUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteUpdatePmInfoTextNote(leadId: number, noteId: number, body?: PmInfoNoteUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteUpdatePmInfoTextNote.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling leadNoteUpdatePmInfoTextNote.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/pm-info/${encodeURIComponent(String(noteId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates and existing note of a lead
     * 
     * @param leadId the id of the lead
     * @param noteId the id of the note
     * @param body the note data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leadNoteUpdateTextNote(leadId: number, noteId: number, body?: UpdateTextNoteRequest, observe?: 'body', reportProgress?: boolean): Observable<NoteViewModel>;
    public leadNoteUpdateTextNote(leadId: number, noteId: number, body?: UpdateTextNoteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoteViewModel>>;
    public leadNoteUpdateTextNote(leadId: number, noteId: number, body?: UpdateTextNoteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoteViewModel>>;
    public leadNoteUpdateTextNote(leadId: number, noteId: number, body?: UpdateTextNoteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling leadNoteUpdateTextNote.');
        }

        if (noteId === null || noteId === undefined) {
            throw new Error('Required parameter noteId was null or undefined when calling leadNoteUpdateTextNote.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<NoteViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/notes/text/${encodeURIComponent(String(noteId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
