<ng-container *ngIf="contactOrigin">
  <pc-lead-action-bar>
    <button class="new-person-button" data-cy="closeButton" (click)="onBack()">
      {{ 'COMMON.BTN_CLOSE' | translate }}
    </button>
  </pc-lead-action-bar>

  <div class="pc-lead-content">
    <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" class="margin">
      <div fxFlex.gt-xs="70">
        <helion-ui-card>
          <div title fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              {{ (contactOrigin.Id ? 'ADDRESS.EDIT_ADDRESS' : 'ADDRESS.NEW_ADDRESS') | translate }}
            </div>
          </div>
          <div content>
            <form class="contact-form" #addressForm="ngForm">
              <div fxLayout="row" fxLayoutAlign="space-between flex-start">
                <div
                  *ngIf="contactTypes"
                  fxFlex.lt-md="100"
                  fxFlex.gt-sm="0 0 calc(50% - 16px)"
                  class="contact-form__block">
                  <helion-ui-select
                    [required]="true"
                    placeholder="{{ 'ADDRESS.TYPE' | translate }}"
                    name="addressType"
                    data-cy="addressType"
                    [ngModel]="selectedAddressType"
                    [disabled]="isExternalManaged && contactOrigin?.Id > 0"
                    (ngModelChange)="onAddressTypeSelect($event)"
                    (onDropdownOpen)="onAddressTypeSelectOpened()">
                    <div class="address-type-filter">
                      <helion-ui-input fxFlex>
                        <input
                          #addressTypeFilter
                          placeholder="{{ 'COMMON.SEARCH' | translate }}"
                          [ngModel]="searchInput"
                          (ngModelChange)="filterAddressTypes($event)" />
                      </helion-ui-input>
                    </div>
                    <helion-ui-option
                      *ngFor="let addressType of filteredAddressTypes"
                      [attr.data-cy]="addressType.Key"
                      [value]="addressType.Key"
                      [label]="addressType.Translation">
                    </helion-ui-option>
                  </helion-ui-select>
                </div>

                <button *ngIf="canDelete()" [attr.fill-type]="'icon'">
                  <helion-ui-icon
                    [cdkMenuTriggerFor]="companyAddressMenu"
                    data-feather="more-vertical">
                  </helion-ui-icon>
                </button>

                <ng-template #companyAddressMenu>
                  <helion-ui-menu>
                    <button
                      class="menu-item"
                      cdkMenuItem
                      (click)="contactDelete()"
                      *ngIf="canDelete()">
                      <helion-ui-icon data-feather="trash-2"></helion-ui-icon>
                      <span>{{ 'ADDRESS.DELETE.CONTACT' | translate }}</span>
                    </button>
                  </helion-ui-menu>
                </ng-template>
              </div>

              <div *ngIf="!isContactTypeEmpty" class="margin-top-2">
                <helion-ui-tabs>
                  <button *ngFor="let tab of tabsData" (click)="switchTab(tab.id)">
                    <div [ngClass]="{ 'active-tab': tab.id === selectedTab }">
                      {{ tab.tabLabel | translate }}
                    </div>
                  </button>
                </helion-ui-tabs>

                <div
                  class="contact-form-content-wrapper"
                  *ngIf="selectedTab === activeTabs.singleAddress">
                  <pc-single-contact
                    #singleContactComponent
                    [contact]="editContactSingle"
                    [leadId]="leadId"
                    [logoFileSingle]="personFile"
                    [emailTypes]="emailTypes"
                    [phoneTypes]="phoneTypes"
                    [titleItems]="titleItemsSingleContact"
                    [mandatorId]="mandator.Id"
                    [addressTypeChanged]="addressTypeChanged"
                    (onCopyAsExternalManaged)="checkDuplicateAndCopyAsExternalManaged($event)"
                    (onLogoDelete)="personLogoDelete($event)"
                    (onLogoFileSelect)="personLogoFileSelect($event)"
                    (onContactSave)="saveSingleContact($event)"></pc-single-contact>
                </div>

                <div
                  class="contact-form-content-wrapper"
                  *ngIf="selectedTab === activeTabs.addressBook || isExternalManaged">
                  <pc-address-book-contact
                    #addressBookComponent
                    [originContact]="editContactAddressbook"
                    [leadId]="leadId"
                    [mandator]="mandator"
                    [isExternalManaged]="isExternalManaged"
                    [titleItems]="titleItemsPerson"
                    [language]="lead.Language"
                    [addressTypeChanged]="addressTypeChanged"
                    (onCopyAsExternalManaged)="checkDuplicateAndCopyAsExternalManaged($event)"
                    (onContactSave)="saveAddressBookSelection($event)">
                  </pc-address-book-contact>
                </div>
              </div>
            </form>
          </div>
        </helion-ui-card>
      </div>
    </div>
  </div>
</ng-container>
