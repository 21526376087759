import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';

export interface IConfirmDialogData {
  acceptButton?: string;
  cancelButton?: string;
  message?: string;
  title?: string;
  isShowActionButtons?: boolean;
}

@Component({
  selector: 'pc-custom-common-dialog',
  templateUrl: './custom-common-dialog.component.html',
  styleUrls: ['./custom-common-dialog.component.scss'],
})
export class CustomCommonDialogComponent implements AfterViewInit {
  @ViewChild('accept') acceptButton: MatButton;

  constructor(
    public dialogRef: MatDialogRef<CustomCommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
  ) {}

  ngAfterViewInit(): void {
    if (this.acceptButton?.focus) {
      this.acceptButton.focus();
    }
  }

  dialogClose(isConfirm: boolean): void {
    this.dialogRef.close(isConfirm);
  }
}
