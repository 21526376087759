/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PatchPartialInvoiceRequest { 
    InvoiceNumber?: string;
    Name?: string;
    Amount?: number;
    InvoiceDate?: Date;
    DeliveryDate?: Date;
    State: PatchPartialInvoiceRequest.StateEnum;
    PaymentTarget?: number;
    Notes?: string;
    ReferenceNumber?: string;
    ShowPartslistAggregatedInSap?: boolean;
}
export namespace PatchPartialInvoiceRequest {
    export type StateEnum = 'Active' | 'Inactive';
    export const StateEnum = {
        Active: 'Active' as StateEnum,
        Inactive: 'Inactive' as StateEnum
    };
}
