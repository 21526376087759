/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentTemplateViewModel { 
    Id?: number;
    Name?: string;
    Path?: string;
    AccessLink?: string;
    DocumentTemplateType?: DocumentTemplateViewModel.DocumentTemplateTypeEnum;
}
export namespace DocumentTemplateViewModel {
    export type DocumentTemplateTypeEnum = 'DocumentTemplate' | 'Anlage' | 'Report' | 'Invoice' | 'DefaultBo' | 'DefaultNbo' | 'DefaultProject' | 'AddressAddedTemplate';
    export const DocumentTemplateTypeEnum = {
        DocumentTemplate: 'DocumentTemplate' as DocumentTemplateTypeEnum,
        Anlage: 'Anlage' as DocumentTemplateTypeEnum,
        Report: 'Report' as DocumentTemplateTypeEnum,
        Invoice: 'Invoice' as DocumentTemplateTypeEnum,
        DefaultBo: 'DefaultBo' as DocumentTemplateTypeEnum,
        DefaultNbo: 'DefaultNbo' as DocumentTemplateTypeEnum,
        DefaultProject: 'DefaultProject' as DocumentTemplateTypeEnum,
        AddressAddedTemplate: 'AddressAddedTemplate' as DocumentTemplateTypeEnum
    };
}
