<section class="dialog">
  <h2 class="dialog-title">{{ 'DIALOG.QUICK_SEARCH.TITLE' | translate }}</h2>

  <div class="dialog-content container ngx-datatable-align-content-vertical-center" #tableWrapper>
    <div fxLayout="column">
      <div class="app-content">
        <ngx-datatable
          #leadTable
          class="material"
          [rows]="rows"
          [columns]="columns"
          [columnMode]="'force'"
          [headerHeight]="40"
          [footerHeight]="40"
          [rowHeight]="'auto'"
          [limit]="rowsPerPage"
          [messages]="tableMessage">
          <ngx-datatable-column
            prop="name"
            [cellClass]="'grow-mobile'"
            [headerClass]="'hide-mobile'"
            [maxWidth]="250">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="cursor-pointer">{{
                'LEADS.TABLE_HEADER.NAME' | translate
              }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span
                flex
                class="cursor-pointer with-font-bold"
                (click)="onActionModify(row['id'])"
                >{{ value }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            prop="street"
            [name]="'LEADS.TABLE_HEADER.STREET' | translate"
            [headerClass]="'hide-on-less-1024'"
            [cellClass]="'hide-on-less-1024'"
            [maxWidth]="250"></ngx-datatable-column>

          <ngx-datatable-column
            prop="zipCity"
            [name]="'LEADS.TABLE_HEADER.ZIP_CITY' | translate"
            [headerClass]="'hide-mobile'"
            [cellClass]="'hide-mobile'"
            [maxWidth]="250"></ngx-datatable-column>

          <ngx-datatable-column
            prop="objectAddress"
            [cellClass]="'hide-on-less-1024'"
            [headerClass]="'hide-on-less-1024'">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="cursor-pointer" style="margin-right: 60px">{{
                'LEADS.TABLE_HEADER.OBJECT_ADDRESS' | translate
              }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span style="margin-right: 60px">
                {{ value }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            prop="mandantName"
            [maxWidth]="60"
            [cellClass]="'hide-mobile min-width-60'"
            [headerClass]="'hide-mobile min-width-60'"
            [name]="'LEADS.TABLE_HEADER.MANDANT' | translate"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <pc-loading-modal [id]="modalLoaderIdTypesEnum.QUICK_SEARCH" type="overlay"></pc-loading-modal>
  </div>
</section>
