import { Component, Inject, OnInit } from '@angular/core';
import { ContactDuplicateAndTypeaheadViewModel } from '../../../shared/apis/advis';
import { IExternManagedAddressTypeConfig } from '../../../shared/utils/exter-managed-address-type.util';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DIALOG_DATA, RadioButtonItem } from '@sitewerk/theia-ui-lib';

export enum TypeOfDuplicateEnum {
  EMAIL = <any>'email',
  PHONE = <any>'phone',
  ADDRESS = <any>'address',
  EXTERNAL_MANAGED = <any>'externalManaged',
}

export interface IDuplicateData {
  newContactLabel: string;
  duplicate: ContactDuplicateAndTypeaheadViewModel[];
  typeOfDuplicate: TypeOfDuplicateEnum;
  externalManagedAddress?: IExternManagedAddressTypeConfig;
}

@Component({
  selector: 'pc-duplicate-contact-dialog',
  templateUrl: './duplicate-contact-dialog.component.html',
  styleUrls: ['./duplicate-contact-dialog.component.scss'],
})
export class DuplicateContactDialogComponent implements OnInit {
  public dialogTitle: string;
  public dialogDescription: string;
  public subTitleExist: string;
  public subTitleNew: string;

  public newContactLabel: string;

  public contacts: RadioButtonItem[] = [];
  public selectedContact: number;

  constructor(
    public dialogRef: DialogRef,
    private translate: TranslateService,
    @Inject(DIALOG_DATA) public data: IDuplicateData
  ) {}

  public ngOnInit(): void {
    this.newContactLabel = this.data.newContactLabel;
    this.contacts.push({ name: this.data.newContactLabel, value: 0 });
    if (this.data && this.data.duplicate) {
      this.contacts = this.data.duplicate.map(contact => ({
        name: contact.Contact,
        value: contact.ContactId,
      }));
    }

    switch (this.data.typeOfDuplicate) {
      case TypeOfDuplicateEnum.PHONE:
        this.dialogTitle = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.TITLE.PHONE_ALREADY_EXIST'
        );
        this.dialogDescription = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.DESCRIPTION.PHONE_ALREADY_EXIST'
        );
        this.subTitleExist = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_EXIST');
        this.subTitleNew = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_NEW');
        break;
      case TypeOfDuplicateEnum.ADDRESS:
        this.dialogTitle = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.TITLE.ADDRESS_ALREADY_EXIST'
        );
        this.dialogDescription = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.DESCRIPTION.ADDRESS_ALREADY_EXIST'
        );
        this.subTitleExist = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_EXIST');
        this.subTitleNew = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_NEW');
        break;
      case TypeOfDuplicateEnum.EXTERNAL_MANAGED:
        this.dialogTitle = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.TITLE.EXTERNAL_MANAGED_ALREADY_EXIST',
          { addressTypeName: this.data.externalManagedAddress.translation }
        );
        this.dialogDescription = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.DESCRIPTION.EXTERNAL_MANAGED_ALREADY_EXIST',
          { addressTypeName: this.data.externalManagedAddress.translation }
        );
        this.subTitleExist = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_EXIST_EXTERNAL_MANAGED',
          { addressTypeName: this.data.externalManagedAddress.translation }
        );
        this.subTitleNew = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_NEW_EXTERNAL_MANAGED',
          { addressTypeName: this.data.externalManagedAddress.translation }
        );
        break;
      default:
        this.dialogTitle = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.TITLE.EMAIL_ALREADY_EXIST'
        );
        this.dialogDescription = this.translate.instant(
          'ADDRESS.DIALOGS.DUPLICATE.DESCRIPTION.EMAIL_ALREADY_EXIST'
        );
        this.subTitleExist = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_EXIST');
        this.subTitleNew = this.translate.instant('ADDRESS.DIALOGS.DUPLICATE.SUB_TITLE_NEW');
        break;
    }
  }

  public cancel(): void {
    this.dialogRef.close({ confirmed: false, selectedContact: -1 });
  }

  public save(): void {
    this.dialogRef.close({ confirmed: true, selectedContact: this.selectedContact });
  }
}
