import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentTemplateViewModel } from '../../apis/advis';

@Component({
  selector: 'pc-choose-template-for-download',
  templateUrl: './choose-template-for-download.component.html',
  styleUrls: ['./choose-template-for-download.component.scss'],
})
export class ChooseTemplateForDownloadComponent {
  @Input()
  public documentTemplates: DocumentTemplateViewModel[];
  @Input()
  public preselection: boolean = false;
  @Output()
  public selectedTemplateIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public selectionChange: EventEmitter<any> = new EventEmitter<any>();

  public selectedId: number;

  @Input()
  public get selectedTemplateId(): number {
    return this.selectedId;
  }

  public set selectedTemplateId(value: number) {
    if (value !== this.selectedId) {
      this.selectedId = value;
      this.selectedTemplateIdChange.emit(this.selectedId);
      this.selectionChange.emit(this.selectedId);
    }
  }
}
