/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmobilityWizardDataViewModel } from './emobilityWizardDataViewModel';


export interface EmobilityTradeSpecificDataViewModel { 
    WizardData?: EmobilityWizardDataViewModel;
    MunicipalSubsidies?: number;
    CantonalSubsidies?: number;
    FederalSubsidies?: number;
    OtherSubsidies?: number;
    CarManufacturerSubsidy?: number;
    TotalPrice?: number;
    TotalSubsidies?: number;
    Template?: string;
    FinancingType?: EmobilityTradeSpecificDataViewModel.FinancingTypeEnum;
    SalesType?: EmobilityTradeSpecificDataViewModel.SalesTypeEnum;
    MinimalMargin?: number;
    GoalMargin?: number;
}
export namespace EmobilityTradeSpecificDataViewModel {
    export type FinancingTypeEnum = 'None' | 'SubsidyPrefinancing' | 'Installments';
    export const FinancingTypeEnum = {
        None: 'None' as FinancingTypeEnum,
        SubsidyPrefinancing: 'SubsidyPrefinancing' as FinancingTypeEnum,
        Installments: 'Installments' as FinancingTypeEnum
    };
    export type SalesTypeEnum = 'Classic' | 'Digital';
    export const SalesTypeEnum = {
        Classic: 'Classic' as SalesTypeEnum,
        Digital: 'Digital' as SalesTypeEnum
    };
}
