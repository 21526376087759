import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MasterDataMandatorTradeTypeViewModel, MasterDataViewModel } from '../../shared/apis/advis';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../shared/states';

@Component({
  selector: 'pc-lead-complexity-type-selector',
  templateUrl: './lead-complexity-type-selector.component.html',
  styleUrls: ['./lead-complexity-type-selector.component.scss'],
})
export class LeadComplexityTypeSelectorComponent implements OnInit, OnChanges, OnDestroy {
  protected allComplexityTypes: string[] = [];
  protected complexityTypes: string[] = [];

  @Input() public tradeType: MasterDataMandatorTradeTypeViewModel;
  @Input() public selectedComplexityType: string;
  @Output() public selectedComplexityTypeChange: EventEmitter<string> = new EventEmitter();

  private subscription: Subscription = new Subscription();

  constructor(private store: Store<RootReducer.IState>) {}

  public ngOnInit() {
    this.subscription.add(
      this.store
        .select<MasterDataViewModel>(RootReducer.getGlobalMasterData)
        .subscribe(masterData => {
          this.allComplexityTypes = masterData.LeadComplexityTypes;
          this.setComplexityTypes();
        })
    );
  }

  public ngOnChanges() {
    this.setComplexityTypes();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public setSelectedComplexityType(complexityType: string): void {
    this.selectedComplexityType = complexityType;
    this.selectedComplexityTypeChange.emit(this.selectedComplexityType);
  }

  private setComplexityTypes() {
    this.complexityTypes =
      this.tradeType?.ComplexityTypes?.length > 0
        ? this.tradeType.ComplexityTypes
        : this.allComplexityTypes;

    if (this.selectedComplexityType && this.complexityTypes.includes(this.selectedComplexityType)) {
      return;
    }

    this.setSelectedComplexityType(
      this.complexityTypes.length === 1 ? this.complexityTypes[0] : undefined
    );
  }
}
