import { Component, Input } from '@angular/core';

export interface IContactInfo {
  contactLine1: string;
  contactLine2: string;
  email: string;
  phone: string;
  language: string;
}

@Component({
  selector: 'pc-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent {
  @Input() public displayContactInfo: IContactInfo;
}
