import * as ViewSettingsAction from './view-setting.action';
import { ViewSettingKeyE } from '../../services/view-setting.service';
import { LeadOverviewVsModel } from '../../models/view-setting/lead-overview-vs.model';
import { LeadOverviewOwnVsModel } from '../../models/view-setting/lead-overview-own-vs.model';
import { tassign } from 'tassign';
import { SelectedMandantsVsModel } from '../../models/view-setting/selected-mandants-vs.model';
import { VariantVsModel } from '../../models/view-setting/variant-vs.model';
import { LeadOverviewTasksVsModel } from '../../models/view-setting/lead-overview-tasks-vs.model';
import { LeadOverviewSettingsModel } from '../../models/view-setting/lead-overview-settings.model';
import { AddressbookLeadOverviewSettingsModel } from '../../models/view-setting/address-book-overview-settings.model';

export interface IState {
  loaded: boolean;
  loading: boolean;
  selectedMandants: SelectedMandantsVsModel;
  leadOverview: LeadOverviewVsModel;
  mtCrmLeadOverviewOwn: LeadOverviewSettingsModel;
  leadOverviewOwn: LeadOverviewOwnVsModel;
  leadOverviewNew: LeadOverviewSettingsModel;
  leadOverviewFollowup: LeadOverviewSettingsModel;
  leadOverviewOrderEntry: LeadOverviewSettingsModel;
  leadOverviewOnlinepool: LeadOverviewSettingsModel;
  leadOverviewDownloadpool: LeadOverviewSettingsModel;
  leadOverviewCallpool: LeadOverviewSettingsModel;
  leadOverviewAll: LeadOverviewSettingsModel;
  leadOverviewTriage: LeadOverviewSettingsModel;
  leadOverviewOwnTasks: LeadOverviewTasksVsModel;
  leadOverviewDelegatedTasks: LeadOverviewTasksVsModel;
  leadOverviewOnHold: LeadOverviewSettingsModel;
  leadOverviewWon: LeadOverviewSettingsModel;
  leadOverviewNotAssigned: LeadOverviewSettingsModel;
  leadOverviewProjectsInProgress: LeadOverviewSettingsModel;
  leadOverviewProjectOverview: LeadOverviewSettingsModel;
  leadOverviewProjectOverviewOwn: LeadOverviewSettingsModel;
  leadOverviewEsApproved: LeadOverviewSettingsModel;
  leadOverviewEsDisapproved: LeadOverviewSettingsModel;
  leadOverviewEsNotSet: LeadOverviewSettingsModel;
  addressbookOverview: AddressbookLeadOverviewSettingsModel;
  variant: VariantVsModel;
}

export const initialState: IState = {
  loaded: false,
  loading: false,
  selectedMandants: new SelectedMandantsVsModel(),
  leadOverview: new LeadOverviewVsModel(),
  mtCrmLeadOverviewOwn: new LeadOverviewSettingsModel(),
  leadOverviewOwn: new LeadOverviewOwnVsModel(),
  leadOverviewNew: new LeadOverviewSettingsModel(),
  leadOverviewFollowup: new LeadOverviewSettingsModel(),
  leadOverviewOrderEntry: new LeadOverviewSettingsModel(),
  leadOverviewOnlinepool: new LeadOverviewSettingsModel(),
  leadOverviewDownloadpool: new LeadOverviewSettingsModel(),
  leadOverviewCallpool: new LeadOverviewSettingsModel(),
  leadOverviewAll: new LeadOverviewSettingsModel(),
  leadOverviewTriage: new LeadOverviewSettingsModel(),
  leadOverviewOwnTasks: new LeadOverviewTasksVsModel(),
  leadOverviewDelegatedTasks: new LeadOverviewTasksVsModel(),
  leadOverviewOnHold: new LeadOverviewSettingsModel(),
  leadOverviewWon: new LeadOverviewSettingsModel(),
  leadOverviewNotAssigned: new LeadOverviewSettingsModel(),
  leadOverviewProjectsInProgress: new LeadOverviewSettingsModel(),
  leadOverviewProjectOverview: new LeadOverviewSettingsModel(),
  leadOverviewProjectOverviewOwn: new LeadOverviewSettingsModel(),
  leadOverviewEsApproved: new LeadOverviewSettingsModel(),
  leadOverviewEsDisapproved: new LeadOverviewSettingsModel(),
  leadOverviewEsNotSet: new LeadOverviewSettingsModel(),
  addressbookOverview: new AddressbookLeadOverviewSettingsModel(),
  variant: new VariantVsModel(),
};

export function reducer(state: IState = initialState, action: ViewSettingsAction.Actions): IState {
  switch (action.type) {
    case ViewSettingsAction.LOAD:
      return tassign(state, {
        loading: true,
      });

    case ViewSettingsAction.LOAD_SUCCESS:
      return tassign(state, {
        loaded: true,
        loading: false,
        selectedMandants: action.payload.selectedMandants.clone(),
        leadOverview: action.payload.leadOverview.clone(),
        leadOverviewOwn: action.payload.leadOverviewOwn.clone(),
        leadOverviewNew: action.payload.leadOverviewNew.clone(),
        leadOverviewFollowup: action.payload.leadOverviewFollowup.clone(),
        leadOverviewOrderEntry: action.payload.leadOverviewOrderEntry?.clone(),
        leadOverviewOnlinepool: action.payload.leadOverviewOnlinepool.clone(),
        leadOverviewCallpool: action.payload.leadOverviewCallpool.clone(),
        leadOverviewDownloadpool: action.payload.leadOverviewDownloadpool.clone(),
        leadOverviewAll: action.payload.leadOverviewAll.clone(),
        leadOverviewTriage: action.payload.leadOverviewTriage.clone(),
        leadOverviewOwnTasks: action.payload.leadOverviewOwnTasks.clone(),
        leadOverviewDelegatedTasks: action.payload.leadOverviewDelegatedTasks.clone(),
        leadOverviewOnHold: action.payload.leadOverviewOnHold.clone(),
        leadOverviewWon: action.payload.leadOverviewWon.clone(),
        leadOverviewNotAssigned: action.payload.leadOverviewNotAssigned.clone(),
        leadOverviewProjectsInProgress: action.payload.leadOverviewProjectsInProgress.clone(),
        leadOverviewProjectOverview: action.payload.leadOverviewProjectOverview.clone(),
        leadOverviewProjectOverviewOwn: action.payload.leadOverviewProjectOverviewOwn.clone(),
        leadOverviewEsApproved: action.payload.leadOverviewEsApproved.clone(),
        leadOverviewEsDisapproved: action.payload.leadOverviewEsDisapproved.clone(),
        leadOverviewEsNotSet: action.payload.leadOverviewEsNotSet.clone(),
        addressbookOverview: action.payload.addressbookOverview.clone(),
        variant: action.payload.variant.clone(),
      });

    case ViewSettingsAction.LOAD_FAILURE:
      console.error(ViewSettingsAction.LOAD_FAILURE + ' ' + action.payload);
      return state;

    case ViewSettingsAction.SAVE:
      return state;

    case ViewSettingsAction.SAVE_SUCCESS:
      switch (action.payload.key) {
        case ViewSettingKeyE.SELECTED_MANDANTS_V2:
          return tassign(state, {
            selectedMandants: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_V1:
          return tassign(state, {
            leadOverview: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_OWN_V1:
          return tassign(state, {
            leadOverviewOwn: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_NEW:
          return tassign(state, {
            leadOverviewNew: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_FOLLOWUP:
          return tassign(state, {
            leadOverviewFollowup: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ORDER_ENTRY:
          return tassign(state, {
            leadOverviewOrderEntry: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_V1:
          return tassign(state, {
            leadOverviewOnlinepool: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_CALLPOOL:
          return tassign(state, {
            leadOverviewCallpool: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_DOWNLOAD:
          return tassign(state, {
            leadOverviewDownloadpool: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ALL_V1:
          return tassign(state, {
            leadOverviewAll: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_TRIAGE_V1:
          return tassign(state, {
            leadOverviewTriage: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.TASK_OWN_OVERVIEW_LIST:
          return tassign(state, {
            leadOverviewOwnTasks: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.TASK_DELEGATED_OVERVIEW_LIST:
          return tassign(state, {
            leadOverviewDelegatedTasks: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.VARIANT:
          return tassign(state, {
            variant: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ON_HOLD:
          return tassign(state, {
            leadOverviewOnHold: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_WON:
          return tassign(state, {
            leadOverviewWon: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_NOT_ASSIGNED:
          return tassign(state, {
            leadOverviewNotAssigned: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_PROJECTS_IN_PROGRESS:
          return tassign(state, {
            leadOverviewProjectsInProgress: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_PROJECT_OVERVIEW:
          return tassign(state, {
            leadOverviewProjectOverview: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_PROJECT_OVERVIEW_OWN:
          return tassign(state, {
            leadOverviewProjectOverviewOwn: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ES_APPROVED:
          return tassign(state, {
            leadOverviewEsApproved: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ES_DISAPPROVED:
          return tassign(state, {
            leadOverviewEsDisapproved: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.LEAD_OVERVIEW_ES_NOT_SET:
          return tassign(state, {
            leadOverviewEsNotSet: action.payload.setting.clone(),
          });

        case ViewSettingKeyE.ADDRESSBOOK_OVERVIEW:
          return tassign(state, {
            addressbookOverview: action.payload.setting.clone(),
          });
        default:
          throw new Error('Unknown key=' + action.payload.key);
      }

    case ViewSettingsAction.SAVE_FAILURE:
      throw new Error('Save failed Error=' + action.payload);

    default:
      return state;
  }
}

export const getLoadedState: (state: IState) => boolean = (state: IState) => state.loaded;
export const getLoadingState: (state: IState) => boolean = (state: IState) => state.loading;
export const getSelectedMandantsState: (state: IState) => SelectedMandantsVsModel = (
  state: IState
) => state.selectedMandants;
export const getLeadOverviewState: (state: IState) => LeadOverviewVsModel = (state: IState) =>
  state.leadOverview;
export const getMtCrmLeadOverviewOwnFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.mtCrmLeadOverviewOwn;
export const getLeadOverviewOwnFilterState: (state: IState) => LeadOverviewOwnVsModel = (
  state: IState
) => state.leadOverviewOwn;
export const getLeadOverviewNewFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewNew;
export const getLeadOverviewFollowupFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewFollowup;
export const getLeadOverviewOrderEntryFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewOrderEntry;
export const getAddressbookOverviewFilterState: (
  state: IState
) => AddressbookLeadOverviewSettingsModel = (state: IState) => state.addressbookOverview;
export const getLeadOverviewOnHoldFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewOnHold;
export const getLeadOverviewNotAssignedFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewNotAssigned;
export const getLeadOverviewProjectsInProgressFilterState: (
  state: IState
) => LeadOverviewSettingsModel = (state: IState) => state.leadOverviewProjectsInProgress;
export const getLeadOverviewProjectOverviewFilterState: (
  state: IState
) => LeadOverviewSettingsModel = (state: IState) => state.leadOverviewProjectOverview;
export const getLeadOverviewProjectOverviewOwnFilterState: (
  state: IState
) => LeadOverviewSettingsModel = (state: IState) => state.leadOverviewProjectOverviewOwn;
export const getLeadOverviewWonFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewWon;
export const getLeadOverviewOnlinepoolFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewOnlinepool;
export const getLeadOverviewCallpoolFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewCallpool;
export const getLeadOverviewDownloadpoolFilterState: (
  state: IState
) => LeadOverviewSettingsModel = (state: IState) => state.leadOverviewDownloadpool;
export const getLeadOverviewAllFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewAll;
export const getLeadOverviewOwnTasksFilterState: (state: IState) => LeadOverviewTasksVsModel = (
  state: IState
) => state.leadOverviewOwnTasks;
export const getLeadOverviewDelegatedTasksFilterState: (
  state: IState
) => LeadOverviewTasksVsModel = (state: IState) => state.leadOverviewDelegatedTasks;
export const getLeadOverviewTriageFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewTriage;

export const getLeadOverviewEsApprovedFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewEsApproved;
export const getLeadOverviewEsDisapprovedFilterState: (
  state: IState
) => LeadOverviewSettingsModel = (state: IState) => state.leadOverviewEsDisapproved;
export const getLeadOverviewEsNotSetFilterState: (state: IState) => LeadOverviewSettingsModel = (
  state: IState
) => state.leadOverviewEsNotSet;
export const getVariantState: (state: IState) => VariantVsModel = (state: IState) => state.variant;
