export enum TradeTypeEnum {
  PV = <any>'0 - Photovoltaics',
  HT = <any>'1 - Heating',
  SEC = <any>'2 - Security Systems',
  S = <any>'3 - Service',
  EM = <any>'4 - E-Mobility',
  BAT = <any>'5 - Storage',
  AC = <any>'6 - Air conditioning',
  DHW = <any>'7 - Domestic hot water',
  WS = <any>'8 - Water Softener',
  NA = <any>'9 - Not available',
  PVX = <any>'10 - PV Large',
  ENG = <any>'11 - Engineering',
}

export class TradeTypeUtils {
  public static toTradeTypeEnum(tradeTypeCode: string): TradeTypeEnum {
    if (tradeTypeCode && tradeTypeCode.length > 0) {
      switch (tradeTypeCode.toLowerCase()) {
        case 'pv':
          return TradeTypeEnum.PV;
        case 'ht':
          return TradeTypeEnum.HT;
        case 'pvx':
          return TradeTypeEnum.PVX;
        case 'sec':
          return TradeTypeEnum.SEC;
        case 'em':
          return TradeTypeEnum.EM;
        case 'na':
          return TradeTypeEnum.NA;
        case 'bat':
          return TradeTypeEnum.BAT;
        case 'dhw':
          return TradeTypeEnum.DHW;
        case 'ws':
          return TradeTypeEnum.WS;
        case 'ac':
          return TradeTypeEnum.AC;
        case 's':
          return TradeTypeEnum.S;
        case 'eng':
          return TradeTypeEnum.ENG;
        default:
          return undefined;
      }
    }

    return undefined;
  }

  public static toTradeTypeCode(tradeType: TradeTypeEnum): string {
    if (tradeType) {
      switch (tradeType) {
        case TradeTypeEnum.PV:
          return 'pv';
        case TradeTypeEnum.HT:
          return 'ht';
        case TradeTypeEnum.PVX:
          return 'pvx';
        case TradeTypeEnum.SEC:
          return 'sec';
        case TradeTypeEnum.EM:
          return 'em';
        case TradeTypeEnum.NA:
          return 'na';
        case TradeTypeEnum.BAT:
          return 'bat';
        case TradeTypeEnum.DHW:
          return 'dhw';
        case TradeTypeEnum.WS:
          return 'ws';
        case TradeTypeEnum.AC:
          return 'ac';
        case TradeTypeEnum.S:
          return 's';
        case TradeTypeEnum.ENG:
          return 'eng';
        default:
          return undefined;
      }
    }

    return undefined;
  }

  public static isTradeType(tradeTypeCode: string, tradeType: TradeTypeEnum): boolean {
    if (tradeTypeCode && tradeTypeCode.length > 0) {
      return TradeTypeUtils.toTradeTypeEnum(tradeTypeCode) === tradeType;
    }
    return false;
  }
}
