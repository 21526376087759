import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { NAVIGATION_BACK } from './navigation.action';
import {
  ADD_DRAWING_NOTE_SUCCESS,
  ADD_TEXT_NOTE_SUCCESS,
  UPDATE_DRAWING_NOTE_SUCCESS,
  UPDATE_TEXT_NOTE_SUCCESS,
  UpdateNoteTags,
} from '../lead-notes/lead-notes.action';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../../services/config.service';
import { Logger, LoggerService } from '../../services/logger.service';
import { debounceTime, take, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { IState } from '../index';

@Injectable()
export class NavigationEffects {
  private logger: Logger;

  updateSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ADD_TEXT_NOTE_SUCCESS,
          ADD_DRAWING_NOTE_SUCCESS,
          UPDATE_TEXT_NOTE_SUCCESS,
          UPDATE_DRAWING_NOTE_SUCCESS,
          NAVIGATION_BACK
        ),
        debounceTime(this.config.apiDebounceTimeMs),
        tap(() => {
          this.store
            .select((storeState: IState) => storeState.leadNotes.updatingTags)
            .pipe(take(1), withLatestFrom(this.route.queryParams.pipe(timeout(300), take(1))))
            .subscribe((result: any) => {
              if (!result[0]) {
                if (result[1].returnUrl) {
                  this.logger.debug('Return to URL=' + result[1].returnUrl);
                  this.router.navigate([result[1].returnUrl]);
                } else {
                  this.logger.debug('Navigate back');
                  this.location.back();
                }
              } else {
                this.store.dispatch(new UpdateNoteTags(false));
              }
            });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private config: ConfigService,
    private store: Store<IState>,
    private loggerService: LoggerService
  ) {
    this.logger = this.loggerService.create(NavigationEffects.name);
  }
}
