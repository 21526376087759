import { Action } from '@ngrx/store';

export const GET_MASTER_DATA: string = '[PV] Get masterdata';
export const GET_MASTER_DATA_SUCCESS: string = '[PV] Get masterdata success';
export const GET_MASTER_DATA_FAILED: string = '[PV] Get masterdata failed';

export class GetPvMasterDataAction implements Action {
  readonly type: string = GET_MASTER_DATA;

  constructor(public payload?: undefined) {}
}

export class GetPvMasterDataSuccessAction implements Action {
  readonly type: string = GET_MASTER_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetPvMasterDataFailedAction implements Action {
  readonly type: string = GET_MASTER_DATA_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | GetPvMasterDataAction
  | GetPvMasterDataSuccessAction
  | GetPvMasterDataFailedAction;
