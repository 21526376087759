import { Component, Input } from '@angular/core';
import { DirectoryPersonViewModel } from '../../../../shared/apis/advis';
import { IContactInfo } from '../contact-info/contact-info.component';
import { EnumService } from '../../../../shared/services/enum.service';
import TitleEnum = DirectoryPersonViewModel.TitleEnum;

@Component({
  selector: 'pc-person-contact',
  templateUrl: './person-contact.component.html',
  styleUrls: ['./person-contact.component.scss'],
})
export class PersonContactComponent {
  @Input() isPersonContactSelect: boolean;
  @Input() person: DirectoryPersonViewModel;

  constructor(private enumService: EnumService) {}

  get setPersonInfo(): IContactInfo {
    return {
      contactLine1: this.enumService.fromValue(TitleEnum, this.person.Title).display,
      contactLine2: `${this.person.FirstName} ${this.person.LastName}`,
      email: this.person.Emails && this.person.Emails.length > 0 ? this.person.Emails[0].Email : '',
      phone:
        this.person.Phones && this.person.Phones.length > 0
          ? this.person.Phones[0].PhoneNumber
          : '',
      language: this.person.Language ?? '',
    };
  }
}
