import { IViewSettingBase } from './view-setting-base.model';

export class SelectedMandantsVsModel implements IViewSettingBase<SelectedMandantsVsModel> {
  public mandants: string[];

  constructor(mandants: string[] = []) {
    this.mandants = mandants;
  }

  clone(): SelectedMandantsVsModel {
    const model: SelectedMandantsVsModel = new SelectedMandantsVsModel(this.mandants);
    return model;
  }
}
