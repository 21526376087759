<helion-ui-select
  type="search"
  maxlength="50"
  name="companyName"
  [required]="isRequired"
  [autocomplete]="true"
  [disabled]="disabled"
  [ngModel]="value"
  [placeholder]="label"
  [attr.data-cy]="cyAttr"
  (onAutocomplete)="onCompanyNameChange($event)">
  <helion-ui-option *ngIf="companyNamesLoading">Loading</helion-ui-option>
  <ng-container *ngIf="!companyNamesLoading">
    <helion-ui-option
      *ngFor="let companyName of filteredCompanyNames"
      (click)="onCompanyNameSelected(companyName)"
      [value]="companyName"
      [label]="companyName">
    </helion-ui-option>
  </ng-container>
</helion-ui-select>
