/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PostPutEmailRequest } from './postPutEmailRequest';
import { PostPutPhoneRequest } from './postPutPhoneRequest';


export interface PostPutPersonRequest { 
    Street?: string;
    ZipCode?: string;
    City?: string;
    CareOfName?: string;
    PostOfficeBox?: string;
    Phones?: Array<PostPutPhoneRequest>;
    Emails?: Array<PostPutEmailRequest>;
    Id?: number;
    Title?: PostPutPersonRequest.TitleEnum;
    LastName?: string;
    FirstName?: string;
    AdditionalName?: string;
    ThirdPartyCustomerNumber?: string;
    Notes?: string;
    Language?: PostPutPersonRequest.LanguageEnum;
    PreferredCommunicationType: PostPutPersonRequest.PreferredCommunicationTypeEnum;
}
export namespace PostPutPersonRequest {
    export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
    export const TitleEnum = {
        None: 'None' as TitleEnum,
        Sir: 'Sir' as TitleEnum,
        Madam: 'Madam' as TitleEnum,
        Company: 'Company' as TitleEnum,
        Family: 'Family' as TitleEnum
    };
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
    export type PreferredCommunicationTypeEnum = 'Email' | 'Letter';
    export const PreferredCommunicationTypeEnum = {
        Email: 'Email' as PreferredCommunicationTypeEnum,
        Letter: 'Letter' as PreferredCommunicationTypeEnum
    };
}
