import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CurrentUserViewModel,
  FileLinkViewModel,
  NoteTemplateViewModel,
  ResetPasswordRequest,
} from '../../shared/apis/advis';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../shared/states';
import * as UserAction from '../../shared/states/user/user.action';
import { PatchUserAction, PutUserPasswordAction } from '../../shared/states/user/user.action';
import { Actions, ofType } from '@ngrx/effects';
import cloneDeep from 'lodash/cloneDeep';
import { NavigationBackAction } from '../../shared/states/navigation/navigation.action';
import { FormArray, FormControl, NgForm } from '@angular/forms';
import { NotificationService, TypeE } from '../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { LoadingModalService } from '../../shared/services/loading-modal.service';
import { ModalLoaderIdTypesEnum } from '../../shared/models/enum/modal-loader-id-types.enum';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import _ from 'lodash';

export enum ActiveUserViewE {
  UserAdministration,
  PasswordAdministration,
  NoteTemplatesAdministration,
}

export interface IResetPasswordModel {
  currentPassword: string;
  newPassword: string;
  againNewPassword: string;
}

@Component({
  selector: 'pc-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('userEditForm') public userEditForm: NgForm;
  @ViewChild('passwordEditForm') public passwordEditForm: NgForm;
  public noteTemplatesEditForm: FormArray = new FormArray([]);

  protected readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;

  protected ActiveUserViewE: any = ActiveUserViewE;
  protected activeUserViewE: any = ActiveUserViewE.UserAdministration;

  protected passwordRequest: IResetPasswordModel;

  protected user: CurrentUserViewModel;

  protected pictureUrl: string;
  protected newPicture: File;
  protected removePicture: boolean;
  protected signatureUrl: string;
  protected newSignature: File;
  protected removeSignature: boolean;

  private userFormChanged: boolean = false;
  private originalUser: CurrentUserViewModel;

  private userFormChangedSubscribed: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<RootReducer.IState>,
    private loadingModalService: LoadingModalService,
    private action$: Actions,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.initializeNoteTemplates();

    this.subscription.add(
      this.store
        .select(RootReducer.getCurrentUser)
        .subscribe((currentUser: CurrentUserViewModel) => {
          this.originalUser = cloneDeep(currentUser);
          this.user = cloneDeep(currentUser);
        })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getIndividualNoteTemplates)
        .subscribe(templates => this.updateNoteTemplates(templates))
    );

    this.subscription.add(
      this.store.select(RootReducer.getIndividualNoteTemplatesLoading).subscribe(loading => {
        if (this.activeUserViewE === ActiveUserViewE.NoteTemplatesAdministration) {
          if (loading) {
            this.loadingModalService.openModal();
          } else {
            this.loadingModalService.closeModal();
          }
        }
      })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getCurrentUserFiles)
        .subscribe((currentUserFiles: FileLinkViewModel[]) => {
          const pictureFile: FileLinkViewModel =
            this.user.PictureFilename &&
            currentUserFiles.find(
              (f: FileLinkViewModel) => f.FileName === this.user.PictureFilename
            );
          this.pictureUrl = pictureFile && pictureFile.AccessUri;

          const signatureFile: FileLinkViewModel =
            this.user.SignatureFilename &&
            currentUserFiles.find(
              (f: FileLinkViewModel) => f.FileName === this.user.SignatureFilename
            );
          this.signatureUrl = signatureFile && signatureFile.AccessUri;
        })
    );

    this.subscription.add(
      this.action$.pipe(ofType(UserAction.PUT_USER_PASSWORD_SUCCESS), take(1)).subscribe(() => {
        this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.PASSWORD_EDIT);
        this.notificationService.notifySimple(
          this.translate.instant('USER.EDIT.RESET_SUCCESSFUL'),
          TypeE.PRIMARY
        );
        this.passwordEditForm.resetForm();
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(UserAction.SAVE_NOTE_TEMPLATES_SUCCESS)).subscribe(() => {
        this.notificationService.notifySimple(
          this.translate.instant('COMMON.DATA_SAVED'),
          TypeE.PRIMARY
        );
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(UserAction.SAVE_NOTE_TEMPLATES_FAILED)).subscribe(() => {
        this.notificationService.notifySimple(
          this.translate.instant('ERROR.SAVE_FAILED'),
          TypeE.PRIMARY
        );
      })
    );

    this.subscription.add(
      this.action$.pipe(ofType(UserAction.GET_NOTE_TEMPLATES_FAILED)).subscribe(() => {
        this.notificationService.notifySimple(
          this.translate.instant('ERROR.LOAD_FAILED'),
          TypeE.PRIMARY
        );
      })
    );

    this.passwordRequest = {
      currentPassword: '',
      newPassword: '',
      againNewPassword: '',
    };
  }

  public ngAfterViewInit(): void {
    this.subscription.add(
      this.store.select(RootReducer.getUserLoading).subscribe((loading: boolean) => {
        this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.USER_EDIT);
        if (loading) {
          this.loadingModalService.openModal(this.modalLoaderIdTypesEnum.USER_EDIT);
        } else {
          this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.USER_EDIT);
        }
      })
    );
  }

  public ngAfterViewChecked(): void {
    if (!this.userFormChanged && this.userEditForm && !this.userFormChangedSubscribed) {
      this.subscription.add(
        this.userEditForm.valueChanges.subscribe(() => {
          this.userFormChangedSubscribed = true;
          setTimeout(() => (this.userFormChanged = !_.isEqual(this.user, this.originalUser)));
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isSaveButtonDisabled(): boolean {
    if (this.activeUserViewE === ActiveUserViewE.UserAdministration) {
      if (!this.userEditForm) {
        return false;
      }
      return !this.isUserFormValid() || !this.userFormChanged;
    } else if (this.activeUserViewE === ActiveUserViewE.PasswordAdministration) {
      if (!this.passwordEditForm) {
        return false;
      }
      return !this.isPasswordFormValid() || !this.passwordEditForm.touched;
    } else if (this.activeUserViewE === ActiveUserViewE.NoteTemplatesAdministration) {
      return this.noteTemplatesEditForm.controls.some(c => !this.isNoteTemplateValid(c.value));
    }

    return false;
  }

  public isUserFormValid(): boolean {
    return this.userEditForm.form.valid;
  }

  public isPasswordFormValid(): boolean {
    if (!this.passwordEditForm.form.valid) {
      return false;
    }

    return this.passwordRequest.newPassword === this.passwordRequest.againNewPassword;
  }

  public setActiveView(newView: ActiveUserViewE): void {
    this.activeUserViewE = newView;
    if (newView === ActiveUserViewE.NoteTemplatesAdministration) {
      this.store.dispatch(new UserAction.GetNoteTemplatesAction());
      this.loadingModalService.openModal();
    }
  }

  public onBack(): void {
    this.store.dispatch(new NavigationBackAction());
  }

  public onPictureDelete(value: boolean): void {
    if (!value) {
      this.removePicture = false;
      return;
    }
    this.newPicture = undefined;
    this.pictureUrl = undefined;
    this.removePicture = true;
    this.userFormChanged = true;
  }

  public onSignatureDelete(value: boolean): void {
    if (!value) {
      this.removeSignature = false;
      return;
    }
    this.newSignature = undefined;
    this.signatureUrl = undefined;
    this.removeSignature = true;
    this.userFormChanged = true;
  }

  public onChangePicture(): void {
    this.userFormChanged = true;
  }

  public onSave(): void {
    if (this.activeUserViewE === ActiveUserViewE.UserAdministration) {
      this.userFormChangedSubscribed = false;
      this.originalUser = cloneDeep(this.user);
      this.userFormChanged = false;
      this.store.dispatch(
        new PatchUserAction({
          userData: {
            Email: this.user.Email,
            FirstName: this.user.FirstName,
            LastName: this.user.LastName,
            JobTitle: this.user.JobTitle,
            PhoneNumber: this.user.PhoneNumber,
            SendEmailOnLeadAssigned: this.user.SendEmailOnLeadAssigned,
            CompanyAddress: {
              CompanyName: this.user.CompanyAddress.CompanyName,
              Street: this.user.CompanyAddress.Street,
              ZipCode: this.user.CompanyAddress.ZipCode,
              City: this.user.CompanyAddress.City,
            },
          },
          signature: {
            file: this.newSignature,
            deleted: this.removeSignature,
            filename: isNullOrUndefined(this.newSignature) ? '' : this.newSignature.name,
          },
          picture: {
            file: this.newPicture,
            deleted: this.removePicture,
            filename: isNullOrUndefined(this.newPicture) ? '' : this.newPicture.name,
          },
        })
      );
    } else if (this.activeUserViewE === ActiveUserViewE.PasswordAdministration) {
      this.loadingModalService.openModal(this.modalLoaderIdTypesEnum.PASSWORD_EDIT);

      const resetPassworRequest: ResetPasswordRequest = {
        CurrentPassword: this.passwordRequest.currentPassword,
        NewPassword: this.passwordRequest.newPassword,
      };

      this.store.dispatch(new PutUserPasswordAction(resetPassworRequest));
    } else if (this.activeUserViewE === ActiveUserViewE.NoteTemplatesAdministration) {
      const templates = this.noteTemplatesEditForm.controls
        .filter(x => !!x.value.Content && !!x.value.Title)
        .map(
          x =>
            ({
              FileName: x.value.FileName,
              Content: x.value.Content,
              Title: x.value.Title,
              Type: NoteTemplateViewModel.TypeEnum.Individual,
            } as NoteTemplateViewModel)
        );

      if (templates.length > 0) {
        this.store.dispatch(
          new UserAction.SaveNoteTemplateAction({
            NoteTemplates: templates,
          })
        );
      }
    }
  }

  public isNoteTemplateValid(template: NoteTemplateViewModel): boolean {
    return (
      (!!this.removeHtmlTags(template.Content) && !!template.Title) ||
      (!this.removeHtmlTags(template.Content) && !template.Title)
    );
  }

  private initializeNoteTemplates(): void {
    const formControls = [];

    for (let i = 0; i < 5; i++) {
      const fileName = `note-template-${i + 1}.txt`;
      const control = new FormControl({
        FileName: fileName,
        Content: '',
        Title: '',
        Type: NoteTemplateViewModel.TypeEnum.Individual,
      } as NoteTemplateViewModel);
      formControls.push(control);
    }

    this.noteTemplatesEditForm = new FormArray(formControls);
  }

  private updateNoteTemplates(noteTemplates: NoteTemplateViewModel[]): void {
    noteTemplates.forEach(template => {
      const existingTemplateControl = this.noteTemplatesEditForm.controls.find(
        nt => nt.value.FileName === template.FileName
      );
      if (!!existingTemplateControl) {
        existingTemplateControl.value.Content = template.Content;
        existingTemplateControl.value.Title = template.Title;
      }
    });
  }

  private removeHtmlTags(input: string): string {
    return input.replace(/<[^>]*>/g, '').trim();
  }
}
