import { PermissionService } from '../services/permission.service';

export enum BomViewModeE {
  Short = 0,
  Extended = 1,
  All = 2,
}

export class BomViewConfig {
  private bomViewMode: BomViewModeE = BomViewModeE.Short;
  public modeToggleVisible: boolean = false;
  public extendedOrAll: boolean = false;
  public short: boolean = false;
  public all: boolean = false;
  public extended: boolean = false;
  public hidePriceInfo: boolean = false;

  constructor(private permissions?: PermissionService) {
    this.modeToggleVisible = this.permissions?.userPermissions?.CanSeeBomToggle; // todo change back
  }

  public setBomViewMode(viewMode: BomViewModeE): void {
    this.bomViewMode = viewMode;
    this.extendedOrAll = this.isExtendedOrAll();
    this.short = this.isShort();
    this.extended = this.isExtended();
    this.all = this.isAll();
  }

  public getBomViewMode(): BomViewModeE {
    return this.bomViewMode;
  }

  private isShort(): boolean {
    return this.bomViewMode === BomViewModeE.Short;
  }

  private isExtended(): boolean {
    return this.bomViewMode === BomViewModeE.Extended;
  }

  private isAll(): boolean {
    return this.bomViewMode === BomViewModeE.All;
  }

  private isExtendedOrAll(): boolean {
    return this.bomViewMode === BomViewModeE.Extended || this.bomViewMode === BomViewModeE.All;
  }
}
