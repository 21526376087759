import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticks',
})
export class DateTicksPipe implements PipeTransform {
  public transform(input: Date | undefined): number {
    return input && new Date(input).getTime();
  }
}
