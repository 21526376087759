<div class="loading-modal-wrapper">
  <div
    class="loading-modal-wrapper-overlay loading-modal-wrapper-loading"
    data-cy="loadingModalSpinner">
    <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
      <helion-ui-spinner></helion-ui-spinner>
      <label *ngIf="progressText">{{ progressText | translate }}</label>
    </div>
  </div>
</div>
