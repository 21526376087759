import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutAction } from '../states/auth/auth.action';
import { Store } from '@ngrx/store';
import * as RootReducer from '../states';
import { environment } from '../../../environments/environment';
import { routesNames } from '../../app.component';

export const SKIP_PATHS = [
  'jwt-auth-enabled',
  'exchange-openid-token',
  'login',
  'refresh',
  environment.electroFormProjectdef,
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(public auth: AuthService, private store: Store<RootReducer.IState>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreTokenHandling =
      !request.url.includes(environment.apiBasePath) ||
      request.url.endsWith('exchange-openid-token');
    if (ignoreTokenHandling) {
      return next.handle(request);
    }

    const lastPart = request.url.split('/').pop();
    return this.auth.getToken() && !SKIP_PATHS.some(path => path === lastPart)
      ? this.handleErrorJWT(request, next)
      : this.handleErrorNonJWT(request, next);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.auth.getTokenData()) {
        return this.auth.refreshToken().pipe(
          switchMap(() => {
            this.isRefreshing = false;
            return this.handleErrorJWT(request, next);
          }),
          catchError(error => {
            this.isRefreshing = false;
            if (error.status == '403') {
              this.store.dispatch(new LogoutAction());
            }

            return throwError(() => error);
          })
        );
      }
    }
    return this.handleErrorJWT(request, next);
  }

  private handleErrorJWT(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handleErrorNonJWT(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.error(
            `onResponseError status "${error.status}" error "${error}" redirect to login page`
          );
          if (window.location.pathname.includes(routesNames.MT_CRM)) {
            const queryParam: string =
              window.location.search.length > 0
                ? window.location.search + '&timeout=1'
                : '?timeout=1';
            window.location.href =
              location.protocol +
              '//' +
              location.host +
              '/auth/login' +
              window.location.pathname +
              queryParam;
          } else {
            window.location.href =
              location.protocol + '//' + location.host + '/auth/login?timeout=1';
          }
          return throwError(() => error);
        }

        return throwError(() => error);
      })
    );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
    });
  }
}
