export enum ModalLoaderIdTypesEnum {
  BOM_PRECALCULATION_DIALOG = <any>'bom-precalculation-dialog.load',
  EM_LEAD_WIZARD = <any>'em-lead-wizard.load',
  FOLLOWUP = <any>'followup.load',
  HT_LEAD_WIZARD = <any>'ht-lead-wizard.load',
  IMAGE_CAROUSEL = <any>'image-carousel.load',
  LEAD_ALL = <any>'leads-all.load',
  LEAD_NOT_ASSIGNED = <any>'leads-not-assigned.load',
  PROJECTS_IN_PROGRESS = <any>'projects-in-progress.load',
  PROJECTS_IN_PROGRESS_OWN = <any>'projects-in-progress-own.load',
  PROJECT_OVERVIEW = <any>'project-overview.load',
  PROJECT_OVERVIEW_OWN = <any>'project-overview-own.load',
  LEAD_DETAIL_DOCUMENTS = <any>'lead-detail-documents.load',
  LEAD_DETAIL_DOCUMENTATION = <any>'lead-detail-documentation.load',
  LEAD_FOLLOWUP = <any>'lead-followup.load',
  LEAD_NEW = <any>'lead-new.load',
  LEAD_ON_HOLD = <any>'lead-on-hold.load',
  LEAD_WON = <any>'lead-won.load',
  LEAD_OWN_DELEGATED = <any>'lead-own-delegated.load',
  LEAD_OWN_FOLLOW_UP = <any>'lead-own-follow-up.load',
  LEAD_OWN_ORDER_ENTRY = <any>'lead-own-order-entry.load',
  LEAD_OWN_DELEGATED_TO_ME = <any>'lead-own-delegated-to-me.load',
  LEAD_OWN_NEW = <any>'lead-own-new.load',
  LEAD_OWN_PROJECT_ARCHIVED = <any>'lead-own-project-archived.load',
  LEAD_OWN_PROJECT_ASSIGN = <any>'lead-own-project-assign.load',
  LEAD_OWN_PROJECT_IN_OPERATION = <any>'lead-own-project-in-operation.load',
  LEAD_OWN_PROJECT_IN_PROGRESS = <any>'lead-own-project-in-progress.load',
  LEAD_OWN_PROJECT_ACCOUNTED = <any>'lead-own-project-accounted.load',
  LEAD_OWN_UNREACHED = <any>'lead-own-unreached.load',
  LEAD_TASK_LIST = <any>'lead-task-list.load',
  LEADS_ONLINEPOOL = <any>'leads-onlinepool.load',
  LEADS_ONLINEPOOL_CALLPOOL = <any>'leads-onlinepool-callpool.load',
  LEADS_ONLINEPOOL_DOWNLOADPOOL = <any>'leads-onlinepool-downloadpool.load',
  LEADS_TRIAGE = <any>'leads-triage.load',
  MAIN_LOADING_MODAL = <any>'main-loading-modal.load',
  MIN_DB_DIALOG = <any>'min-db-dialog.load',
  PASSWORD_EDIT = <any>'password-edit.load',
  PERSON = <any>'person.load',
  PREVIEW = <any>'preview.load',
  PROJECT_DATA_ASSIGNMENT = <any>'project-data-assignment.load',
  PV_LEAD_WIZARD = <any>'pv-lead-wizard.load',
  DELEGATE = <any>'delegate.load',
  TASK_DETAIL_LIST = <any>'task-detail-list.load',
  OWN_TASK_LIST = <any>'own-task-list.load',
  DELEGATED_TASK_LIST = <any>'delegated-task-list.load',
  TRIAGE = <any>'triage.load',
  USER_ASSIGNMENT = <any>'user-assignment.load',
  USER_EDIT = <any>'user-edit.load',
  VARIANT_DETAIL_LIST = <any>'variant-detail-list.load',
  VARIANT_LIST = <any>'variant-list.load',
  VARIANT_SEARCH = <any>'variant-search.load',
  SEND_BACK = <any>'send-back.load',
  MT_CRM_LEAD_SAVE = <any>'mt-crm-lead-own-new.save',
  MT_CRM_LEAD_OWN_NEW = <any>'mt-crm-lead-own-new.load',
  MT_CRM_LEAD_OWN_IS_SEND = <any>'mt-crm-lead-own-is-send.load',
  MT_CRM_LEAD_SEARCH = <any>'mt-crm-lead-search.load',
  QUICK_SEARCH = <any>'quick-search.load',
  NOTES_LIST = <any>'notes-list.load',
  ADDRESS_BOOK_COMPANY_LIST = <any>'address_book_company_list.load',
  LEAD_ADDRESS_DETAIL_SEARCH = <any>'lead-address-detail-search.load',
  PM_INFO_NOTE = <any>'lead-pm-info-note.load',
  LEAD_OVERVIEW_ENERGY_SOLUTION_APPROVED = <any>'lead-overview-energy-solution-approved.load',
  LEAD_OVERVIEW_ENERGY_SOLUTIONS_DISAPPROVED = <any>(
    'lead-overview-energy-solutions-disapproved.load'
  ),
  LEAD_OVERVIEW_ENERGY_SOLUTIONS_NOT_SET = <any>'lead-overview-energy-solutions-not-approved.load',
  LEAD_ORDER_ENTRY = <any>'lead-order-entry.load',
}
