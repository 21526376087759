import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pc-read-only-label',
  templateUrl: './read-only-label.component.html',
  styleUrls: ['./read-only-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyLabelComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}
