import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AdditionalAddressTypeViewModel,
  DirectoryCompanyViewModel,
  DirectoryContactAddressViewModel,
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  LeadViewModel,
} from '../../../shared/apis/advis';
import { DomSanitizer } from '@angular/platform-browser';
import { EnumService, IEnumData } from '../../../shared/services/enum.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { IResultPayload } from '../../../shared/services/address-book-backend.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { DialogRef, DialogService } from '@sitewerk/theia-ui-lib';
import PhoneTypeEnum = DirectoryPhoneViewModel.PhoneTypeEnum;
import EmailTypeEnum = DirectoryEmailViewModel.EmailTypeEnum;
import AddressTypeEnum = DirectoryContactViewModel.ContactTypeEnum;
import LanguageEnum = LeadViewModel.LanguageEnum;

export interface IEditResult {
  contact: DirectoryContactViewModel | DirectoryCompanyViewModel | DirectoryPersonViewModel;
}

@Directive()
export abstract class BaseContactFormDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() public logoUrl: string;
  @Input() public emailTypes: AdditionalAddressTypeViewModel[];
  @Input() public phoneTypes: AdditionalAddressTypeViewModel[];
  @Input() public addressType: AddressTypeEnum;
  @Input() public titleItems: IEnumData[] = [];
  @Input() public languageItems: IEnumData[] = [];

  @Output() public logoDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('form', { static: false }) public ngForm: NgForm;

  public readonly addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public readonly phoneTypeEnum: typeof PhoneTypeEnum = PhoneTypeEnum;
  public readonly emailTypeEnum: typeof EmailTypeEnum = EmailTypeEnum;

  public isObjectContact: boolean;
  public requiresStrictValidation: boolean = false;

  protected subscriptions: Subscription = new Subscription();

  protected constructor(
    public sanitizer: DomSanitizer,
    protected translate: TranslateService,
    protected dialogService: DialogService,
    protected notification: NotificationService,
    protected loadingModalService: LoadingModalService,
    protected enumService: EnumService
  ) {
    this.languageItems = this.enumService.getEnumDataExclude(LanguageEnum, [LanguageEnum.EN]);
  }

  public ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.ngForm)) {
      this.subscriptions.add(
        this.ngForm.form.valueChanges.subscribe(() => {
          this.valueChange.emit(true);
          if (this.ngForm.invalid) {
            Object.values(this.ngForm.controls)
              .filter(c => c.invalid)
              .forEach(c => {
                c.markAllAsTouched();
              });
          }
        })
      );
    }
  }

  public ngOnInit(): void {
    if (this.addressType) {
      this.isObjectContact = this.addressType === this.addressTypeEnum.Object;
      this.requiresStrictValidation =
        this.addressType === this.addressTypeEnum.Delivery ||
        this.addressType === this.addressTypeEnum.Object;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addEmail(address: DirectoryContactAddressViewModel): void {
    if (!address.Emails) {
      address.Emails = [];
    }

    address.Emails.push({ EmailType: this.emailTypeEnum.Work, Email: '' });
  }

  public addPhone(address: DirectoryContactAddressViewModel): void {
    if (!address.Phones) {
      address.Phones = [];
    }

    address.Phones.push({ PhoneType: this.phoneTypeEnum.Mobile, PhoneNumber: '' });
  }

  public removeEmail(address: DirectoryContactAddressViewModel, index: number): void {
    address.Emails.splice(index, 1);
  }

  public removePhone(address: DirectoryContactAddressViewModel, index: number): void {
    address.Phones.splice(index, 1);
  }

  public initEmptyEmailAndPhone(address: DirectoryContactAddressViewModel): void {
    if (isNullOrUndefined(address)) {
      return;
    }

    if (isNullOrUndefined(address?.Emails)) {
      address.Emails = [];
    }

    if (isNullOrUndefined(address?.Phones)) {
      address.Phones = [];
    }

    if (address.Emails.length === 0) {
      address.Emails.push({
        EmailType: DirectoryEmailViewModel.EmailTypeEnum.Primary,
        Email: '',
      });
    }

    if (address.Phones.length === 0) {
      address.Phones.push({
        PhoneType: DirectoryPhoneViewModel.PhoneTypeEnum.Primary,
        PhoneNumber: '',
      });
    }
  }

  public resetEmptyEmailAndPhone(address: DirectoryContactAddressViewModel): void {
    if (!isNullOrUndefined(address.Emails)) {
      address.Emails = address.Emails.filter(email => email.Email.length > 0);
    }

    if (!isNullOrUndefined(address.Phones)) {
      address.Phones = address.Phones.filter(phone => phone.PhoneNumber.length > 0);
    }
  }

  public save(isNew?: boolean): void {
    if (isNotNullOrUndefined(isNew) && isNew) {
      this.saveEntry();
      return;
    }

    this.subscriptions.add(
      this.dialogService
        .openConfirm({
          message: this.translate.instant('ADDRESS.DIALOGS.CONFIRM_UPDATE_ADDRESSBOOK.MESSAGE'),
          title: this.translate.instant('ADDRESS.DIALOGS.CONFIRM_UPDATE_ADDRESSBOOK.TITLE'),
        })
        .afterClosed()
        .subscribe((proceed: boolean) => {
          if (proceed) {
            this.saveEntry();
          }
        })
    );
  }

  protected saveResult(
    result: IResultPayload<
      DirectoryContactViewModel | DirectoryCompanyViewModel | DirectoryPersonViewModel
    >,
    modalName: string,
    dialogRef: DialogRef
  ): void {
    this.loadingModalService.closeModal(modalName);
    if (result.successful) {
      dialogRef.close({
        contact: result.payload,
        logoFile: '',
        logoUrl: this.logoUrl,
      } as IEditResult);
    }
  }

  protected abstract saveEntry(): void;
}
