/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SopChecklistStepViewModel } from './sopChecklistStepViewModel';
import { SopPhaseViewModel } from './sopPhaseViewModel';


export interface SopChecklistPhaseViewModel { 
    SopPhase?: SopPhaseViewModel;
    CheckedState?: SopChecklistPhaseViewModel.CheckedStateEnum;
    Title?: string;
    IsTimeWarning?: boolean;
    Notes?: string;
    Link?: string;
    StartDate?: Date;
    LastUpdateDate?: Date;
    SopSteps?: Array<SopChecklistStepViewModel>;
}
export namespace SopChecklistPhaseViewModel {
    export type CheckedStateEnum = 'Unchecked' | 'PartiallyChecked' | 'Checked';
    export const CheckedStateEnum = {
        Unchecked: 'Unchecked' as CheckedStateEnum,
        PartiallyChecked: 'PartiallyChecked' as CheckedStateEnum,
        Checked: 'Checked' as CheckedStateEnum
    };
}
