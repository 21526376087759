/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DocumentTemplateViewModel } from '../../../../shared/apis/advis';
import { DOCUMENT_TYPE_ENUM } from '../../../../shared/models/enum/document-type.enum';

@Component({
  selector: 'pc-attachment-selector',
  templateUrl: './attachment-selector.component.html',
  styleUrls: ['./attachment-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttachmentSelectorComponent implements OnInit {
  @Input() public attachments: DocumentTemplateViewModel[];
  @Input() public hideToggle: boolean = false;
  @Output() public onAttachmentChange: EventEmitter<number[]> = new EventEmitter();
  @Output() public onSelectedDocumentType: EventEmitter<DOCUMENT_TYPE_ENUM> = new EventEmitter();
  public preselection: boolean = false;
  public isNativeDocument: boolean;

  public nodes: any = [];

  private selectedAttachments: number[] = [];
  private documentAttachments?: DocumentTemplateViewModel[];

  private selectedDocumentType: number = 0;

  public ngOnInit(): void {
    this.documentAttachments = [...this.attachments].sort(
      (a: DocumentTemplateViewModel, b: DocumentTemplateViewModel) => {
        return (a.Name || '').toLowerCase().localeCompare((b.Name || '').toLowerCase());
      }
    );

    this.nodes = this.arrangeIntoTree(
      this.documentAttachments.map((d: DocumentTemplateViewModel) => ({
        att: d,
        path: d.Name.split(/[/\\]/),
      }))
    );
    this.updateIsNativeDocument();
  }

  public changeFileType(value: boolean): void {
    this.selectedDocumentType = value ? DOCUMENT_TYPE_ENUM.NATIVE : DOCUMENT_TYPE_ENUM.PDF;
    this.onSelectedDocumentType.emit(value ? DOCUMENT_TYPE_ENUM.NATIVE : DOCUMENT_TYPE_ENUM.PDF);
    this.updateIsNativeDocument();
  }

  public onCheckboxChange(checked: boolean, attachmentId: number): void {
    if (checked) {
      this.selectedAttachments.push(attachmentId);
    } else {
      this.selectedAttachments = this.selectedAttachments.filter((id: number) => {
        return id !== attachmentId;
      });
    }

    this.onAttachmentChange.emit(this.selectedAttachments);
  }

  public previewAttachment(path: string): void {
    window.open(path, '_blank');
  }

  private arrangeIntoTree(paths: any): any {
    const tree: any = [];

    for (let i: number = 0; i < paths.length; i++) {
      const path: string[] = paths[i].path;
      const data: any = paths[i].att;
      let currentLevel: any = tree;
      for (let j: number = 0; j < path.length; j++) {
        const part: string = path[j];

        const existingPath: any = this.findWhere(currentLevel, 'name', part);

        if (existingPath) {
          currentLevel = existingPath.children;
        } else {
          const newPart: any = {
            id: data.Id,
            name: part,
            path: data.AccessLink,
            children: [],
          };

          currentLevel.push(newPart);
          currentLevel = newPart.children;
        }
      }
    }
    return tree;
  }

  private updateIsNativeDocument(): void {
    this.isNativeDocument = this.selectedDocumentType === DOCUMENT_TYPE_ENUM.NATIVE;
  }

  private findWhere(array: any, key: string, value: string): any {
    let t: number = 0; // t is used as a counter
    while (t < array.length && array[t][key] !== value) {
      t++;
    } // find the index where the id is the as the aValue

    if (t < array.length) {
      return array[t];
    } else {
      return false;
    }
  }
}
