/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InvoiceStatusViewModel } from './invoiceStatusViewModel';


export interface LeadVariantPartialInvoiceViewModel { 
    Id?: number;
    InvoiceNumber?: string;
    InvoiceDate?: Date;
    DeliveryDate?: Date;
    Name?: string;
    CreatedBy?: string;
    Created?: Date;
    State?: LeadVariantPartialInvoiceViewModel.StateEnum;
    Status?: InvoiceStatusViewModel;
    PaymentTarget?: number;
    PreviewDocumentUrl?: string;
    ThirdPartyId?: string;
    StatusChangedOn?: Date;
    Notes?: string;
    ReferenceNumber?: string;
    ShowPartslistAggregatedInSap?: boolean;
    DunningLevel?: number;
    BelongsToFinalInvoice?: string;
    Amount?: number;
}
export namespace LeadVariantPartialInvoiceViewModel {
    export type StateEnum = 'Active' | 'Inactive';
    export const StateEnum = {
        Active: 'Active' as StateEnum,
        Inactive: 'Inactive' as StateEnum
    };
}
