/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PersonInfo { 
    Title?: PersonInfo.TitleEnum;
    LastName?: string;
    FirstName?: string;
    AdditionalName?: string;
    Email?: string;
    Street?: string;
    ZipCode?: string;
    City?: string;
    CareOfName?: string;
    PostOfficeBox?: string;
    PhoneNumber?: string;
    CompanyName?: string;
    AdditionalCompanyName?: string;
}
export namespace PersonInfo {
    export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
    export const TitleEnum = {
        None: 'None' as TitleEnum,
        Sir: 'Sir' as TitleEnum,
        Madam: 'Madam' as TitleEnum,
        Company: 'Company' as TitleEnum,
        Family: 'Family' as TitleEnum
    };
}
