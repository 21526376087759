/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentTemplatesViewModel } from '../model/documentTemplatesViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Generates a dox42 pdf variant document, given document id and variantId.  Attachment Ids can be given as comma-separated query arguments and will be attached to the returned pdf
     * 
     * @param variantId Variant Id of the variant for which the document is generated.
     * @param language The language used to identify the proper template.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiInvoiceDocumentGetInvoiceDocumentPdf(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiInvoiceDocumentGetInvoiceDocumentPdf(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiInvoiceDocumentGetInvoiceDocumentPdf(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiInvoiceDocumentGetInvoiceDocumentPdf(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiInvoiceDocumentGetInvoiceDocumentPdf.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/invoices/${encodeURIComponent(String(variantId))}/documents/pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a dox42 pdf lead document, given document id and leadId.  Attachment Ids can be given as comma-separated query arguments and will be attached to the returned pdf
     * 
     * @param leadId Lead Id of the lead for which the document is generated
     * @param documentId id of the document to be generated
     * @param attachmentIds the ids of the attachments comma-separated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadDocumentGetLeadDocumentPdfWithAttachments(leadId: number, documentId: number, attachmentIds?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiLeadDocumentGetLeadDocumentPdfWithAttachments(leadId: number, documentId: number, attachmentIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiLeadDocumentGetLeadDocumentPdfWithAttachments(leadId: number, documentId: number, attachmentIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiLeadDocumentGetLeadDocumentPdfWithAttachments(leadId: number, documentId: number, attachmentIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadDocumentGetLeadDocumentPdfWithAttachments.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling pCApiLeadDocumentGetLeadDocumentPdfWithAttachments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (attachmentIds !== undefined && attachmentIds !== null) {
            queryParameters = queryParameters.set('attachmentIds', <any>attachmentIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents/${encodeURIComponent(String(documentId))}/pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of document templates and attachments for a lead.  Trade type code and mandator are derived from that lead.  The matching roles are taken from the application user
     * 
     * @param leadId Lead Id of the lead for which the documents are retrieved
     * @param language The language of the templates
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadDocumentGetLeadDocumentTemplates(leadId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplatesViewModel>;
    public pCApiLeadDocumentGetLeadDocumentTemplates(leadId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplatesViewModel>>;
    public pCApiLeadDocumentGetLeadDocumentTemplates(leadId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplatesViewModel>>;
    public pCApiLeadDocumentGetLeadDocumentTemplates(leadId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadDocumentGetLeadDocumentTemplates.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTemplatesViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents/document-templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a dox42 lead document, given document id and leadId.  The file format will be the natively defined format in the path of the document template
     * 
     * @param leadId Lead Id of the lead for which the document is generated
     * @param documentId id of the document to be generated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadDocumentGetNativeLeadDocument(leadId: number, documentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiLeadDocumentGetNativeLeadDocument(leadId: number, documentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiLeadDocumentGetNativeLeadDocument(leadId: number, documentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiLeadDocumentGetNativeLeadDocument(leadId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadDocumentGetNativeLeadDocument.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling pCApiLeadDocumentGetNativeLeadDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/documents/${encodeURIComponent(String(documentId))}/native`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a dox42 variant document, given document id and variantId.  The file format will be the natively defined format in the path of the document template
     * 
     * @param variantId Variant Id of the variant for which the document is generated
     * @param documentId id of the document to be generated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiVariantDocumentGetNativeVariantDocument(variantId: number, documentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiVariantDocumentGetNativeVariantDocument(variantId: number, documentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiVariantDocumentGetNativeVariantDocument(variantId: number, documentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiVariantDocumentGetNativeVariantDocument(variantId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiVariantDocumentGetNativeVariantDocument.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling pCApiVariantDocumentGetNativeVariantDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/documents/${encodeURIComponent(String(documentId))}/native`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a dox42 pdf variant document, given document id and variantId.  Attachment Ids can be given as comma-separated query arguments and will be attached to the returned pdf
     * 
     * @param variantId Variant Id of the variant for which the document is generated
     * @param documentId id of the document to be generated
     * @param attachmentIds the ids of the attachments comma-separated
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiVariantDocumentGetVariantDocumentPdfWithAttachments(variantId: number, documentId: number, attachmentIds?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiVariantDocumentGetVariantDocumentPdfWithAttachments(variantId: number, documentId: number, attachmentIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiVariantDocumentGetVariantDocumentPdfWithAttachments(variantId: number, documentId: number, attachmentIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiVariantDocumentGetVariantDocumentPdfWithAttachments(variantId: number, documentId: number, attachmentIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiVariantDocumentGetVariantDocumentPdfWithAttachments.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling pCApiVariantDocumentGetVariantDocumentPdfWithAttachments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (attachmentIds !== undefined && attachmentIds !== null) {
            queryParameters = queryParameters.set('attachmentIds', <any>attachmentIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/documents/${encodeURIComponent(String(documentId))}/pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of variant document templates and attachments for a variant.  Trade type code and mandator are derived from that variant.  The matching roles are taken from the application user
     * 
     * @param variantId Variant Id of the variant for which the documents are retrieved
     * @param language The language of the templates
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiVariantDocumentGetVariantDocumentTemplates(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'body', reportProgress?: boolean): Observable<DocumentTemplatesViewModel>;
    public pCApiVariantDocumentGetVariantDocumentTemplates(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTemplatesViewModel>>;
    public pCApiVariantDocumentGetVariantDocumentTemplates(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTemplatesViewModel>>;
    public pCApiVariantDocumentGetVariantDocumentTemplates(variantId: number, language?: 'DE' | 'FR' | 'IT' | 'EN', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiVariantDocumentGetVariantDocumentTemplates.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTemplatesViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/documents/document-templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a dox42 variant preview document, given path and file format
     * 
     * @param variantId Variant Id of the variant for which the preview document is generated
     * @param fileFormat file format for the generated document
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiVariantDocumentGetVariantPreview(variantId: number, fileFormat?: 'Pdf' | 'Word', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pCApiVariantDocumentGetVariantPreview(variantId: number, fileFormat?: 'Pdf' | 'Word', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pCApiVariantDocumentGetVariantPreview(variantId: number, fileFormat?: 'Pdf' | 'Word', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pCApiVariantDocumentGetVariantPreview(variantId: number, fileFormat?: 'Pdf' | 'Word', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiVariantDocumentGetVariantPreview.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileFormat !== undefined && fileFormat !== null) {
            queryParameters = queryParameters.set('fileFormat', <any>fileFormat);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/documents/preview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param invoiceNumber 
     * @param fiscalYear 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingGetCreditorInvoicePdf(leadId: number, invoiceNumber: number, fiscalYear: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public projectReportingGetCreditorInvoicePdf(leadId: number, invoiceNumber: number, fiscalYear: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public projectReportingGetCreditorInvoicePdf(leadId: number, invoiceNumber: number, fiscalYear: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public projectReportingGetCreditorInvoicePdf(leadId: number, invoiceNumber: number, fiscalYear: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingGetCreditorInvoicePdf.');
        }

        if (invoiceNumber === null || invoiceNumber === undefined) {
            throw new Error('Required parameter invoiceNumber was null or undefined when calling projectReportingGetCreditorInvoicePdf.');
        }

        if (fiscalYear === null || fiscalYear === undefined) {
            throw new Error('Required parameter fiscalYear was null or undefined when calling projectReportingGetCreditorInvoicePdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/creditor-invoice/${encodeURIComponent(String(invoiceNumber))}/${encodeURIComponent(String(fiscalYear))}`,
{
					withCredentials: this.configuration.withCredentials,
					headers: headers,
					observe: observe,
					reportProgress: reportProgress,
					responseType: 'blob',
				}
			);
    }

}
