<div class="address-block">
  <ng-container *ngIf="!isAccordion">
    <div class="header">
      <pc-address-header [title]="title" [isReadOnly]="isReadOnly" (onEdit)="onEdit.emit()">
      </pc-address-header>

      <pc-action-buttons
        [showEditIcon]="!isReadOnly"
        [regionalInformation]="regionalInformation"
        [searchLink]="searchLink"
        (editAddress)="editAddress()"></pc-action-buttons>
    </div>

    <ng-container [ngTemplateOutlet]="addressBody"> </ng-container>
  </ng-container>

  <helion-ui-accordion *ngIf="isAccordion">
    <helion-ui-accordion-item>
      <div accordionItemHeader>
        <div class="accordion-header">
          {{ title }}
          <pc-action-buttons
            [showEditIcon]="!isReadOnly"
            [regionalInformation]="regionalInformation"
            [searchLink]="searchLink"
            (editAddress)="editAddress()"></pc-action-buttons>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="addressBody">
        <div accordionItemBody></div>
      </ng-container>
    </helion-ui-accordion-item>
  </helion-ui-accordion>
</div>

<ng-template #addressBody>
  <div class="address-block__body truncate-ellipsis">
    <p class="address-block__text" *ngIf="address.Company && address.Company?.Name">
      {{ address.Company?.Name }}
    </p>
    <p class="address-block__text" *ngIf="address.Company && address.Company?.AdditionalName">
      {{ address.Company?.AdditionalName }}
    </p>
    <p class="address-block__text">
      {{ address.Person?.FirstName }} {{ address.Person?.LastName }}
    </p>

    <div fxLayout="column">
      <div fxLayout="row wrap">
        <div>
          <div fxLayout="column">
            <p class="address-block__Street">
              <a
                [href]="swissTopoLink"
                target="_blank"
                data-rel="external"
                class="address-block__link">
                {{ address.MainContact.Street }}
              </a>
            </p>
            <p class="address-block__city">
              <a
                [href]="swissTopoLink"
                target="_blank"
                data-rel="external"
                class="address-block__link">
                {{ address.MainContact.ZipCode }} {{ address.MainContact.City }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
      <div>
        <p *ngIf="address.MainContact.AdditionalPhones" class="address-block__text">
          {{ 'ADDRESS.PHONE_NUMBER' | translate }}
        </p>
        <p
          *ngFor="let additionalPhone of address.MainContact.AdditionalPhones"
          class="address-block__text">
          {{ 'ADDRESS.PHONE_NUMBER' | translate }}
        </p>
        <p *ngIf="address.MainContact.AdditionalEmails" class="address-block__text">
          {{ 'ADDRESS.EMAIL' | translate }}
        </p>
        <p
          *ngFor="let additionalEmail of address.MainContact.AdditionalEmails"
          class="address-block__text">
          {{ 'ADDRESS.EMAIL' | translate }}
        </p>
        <p *ngIf="address.Person?.ThirdPartyCustomerNumber" class="address-block__text">
          {{ 'COMMON.REFERENCE' | translate }}
        </p>
        <p *ngIf="address.Company?.ThirdPartyCustomerNumber" class="address-block__text">
          {{ 'COMMON.REFERENCE' | translate }}
        </p>
        <p *ngIf="address.Person?.Language" class="address-block__text">
          {{ 'COMMON.LANGUAGE' | translate }}
        </p>
        <p *ngIf="address.Person?.PreferredCommunicationType" class="address-block__text">
          {{ 'ADDRESS.COMMUNICATION_TYPE' | translate }}
        </p>
      </div>
      <div class="truncate-ellipsis">
        <ng-container
          *ngIf="address.MainContact.PrimaryPhone"
          [ngTemplateOutlet]="phone"
          [ngTemplateOutletContext]="{
            phoneNumber: address.MainContact.PrimaryPhone.PhoneNumber
          }"></ng-container>
        <ng-container
          *ngFor="let additionalPhone of address.MainContact.AdditionalPhones"
          [ngTemplateOutlet]="phone"
          [ngTemplateOutletContext]="{
            phoneNumber: additionalPhone.PhoneNumber,
            additionalPhoneType: additionalPhone.PhoneType
          }">
        </ng-container>
        <ng-container
          *ngIf="address.MainContact.PrimaryEmail"
          [ngTemplateOutlet]="email"
          [ngTemplateOutletContext]="{ email: address.MainContact.PrimaryEmail.Email }">
        </ng-container>
        <ng-container
          *ngFor="let additionalEmail of address.MainContact.AdditionalEmails"
          [ngTemplateOutlet]="email"
          [ngTemplateOutletContext]="{
            email: additionalEmail.Email,
            additionalEmailType: additionalEmail.EmailType
          }">
        </ng-container>
        <ng-container
          *ngIf="address.Person?.ThirdPartyCustomerNumber"
          [ngTemplateOutlet]="thirdPartyCustomerNumber"
          [ngTemplateOutletContext]="{
            thirdPartyCustomerNumber: address.Person.ThirdPartyCustomerNumber
          }">
        </ng-container>
        <ng-container
          *ngIf="address.Company?.ThirdPartyCustomerNumber"
          [ngTemplateOutlet]="thirdPartyCustomerNumber"
          [ngTemplateOutletContext]="{
            thirdPartyCustomerNumber: address.Company.ThirdPartyCustomerNumber
          }">
        </ng-container>
        <ng-container
          *ngIf="address.Person?.Language"
          [ngTemplateOutlet]="language"
          [ngTemplateOutletContext]="{
            language: address.Person?.Language
          }">
        </ng-container>
        <ng-container
          *ngIf="address.Person?.PreferredCommunicationType"
          [ngTemplateOutlet]="communicationType"
          [ngTemplateOutletContext]="{
            communicationType: address.Person?.PreferredCommunicationType
          }">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #phone let-phoneNumber="phoneNumber" let-additionalPhoneType="additionalPhoneType">
  <p class="address-block__text">
    <a
      [href]="'tel:' + phoneNumber"
      class="address-block__link phone-number"
      [class.primary]="primary">
      {{ phoneNumber }} ({{
        additionalPhoneTypesTranslations[additionalPhoneType] || 'ADDRESS.PRIMARY_PHONE_TYPE'
          | translate
      }})
    </a>
  </p>
</ng-template>

<ng-template #email let-email="email" let-additionalEmailType="additionalEmailType">
  <p class="address-block__text">
    <a
      [href]="'mailto:' + email"
      data-rel="external"
      data-cy="leadStageLabelEmail"
      class="address-block__link"
      [class.primary]="primary"
      *ngIf="!additionalEmailType">
      {{ email }} ({{ 'ADDRESS.PRIMARY_EMAIL_TYPE' | translate }})
    </a>
    <a
      [href]="'mailto:' + email"
      data-rel="external"
      class="address-block__link"
      [class.primary]="primary"
      *ngIf="additionalEmailType">
      {{ email }} ({{ additionalEmailTypesTranslations[additionalEmailType] | translate }})
    </a>
  </p>
</ng-template>

<ng-template #thirdPartyCustomerNumber>
  <p class="address-block__text">{{ address.Person?.ThirdPartyCustomerNumber }}</p>
</ng-template>

<ng-template #language>
  <p class="address-block__text">{{ address.Person?.Language }}</p>
</ng-template>

<ng-template #communicationType>
  <p class="address-block__text">
    {{
      address.Person?.PreferredCommunicationType
        | enumText : DirectoryPersonViewModel.PreferredCommunicationTypeEnum
    }}
  </p>
</ng-template>
