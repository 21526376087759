import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProjectReporting from './Project-reporting.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ProjectReportingService } from '../../apis/advis/api/projectReporting.service';
import { ProjectReportingViewModel } from '../../apis/advis/model/projectReportingViewModel';
import { saveAs } from 'file-saver';
import {
  DocumentService,
  ProjectReportingSnapshotViewModel,
  ProjectReportingTimeRequest,
} from '../../apis/advis';

@Injectable()
export class ProjectReportingEffects {
  public getProjectReporting$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.GET_PROJECT_REPORTING),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.GetProjectReportingAction) => action.payload),
      switchMap(leadId => {
        return this.projectReportingApi.projectReportingGetProjectReporting(leadId).pipe(
          map((res: ProjectReportingViewModel) => {
            return new ProjectReporting.GetProjectReportingSuccessAction(res);
          }),
          catchError((error: any) =>
            of(new ProjectReporting.GetProjectReportingFailedAction(error))
          )
        );
      })
    )
  );

  public getProjectReportingSnapshots$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.GET_PROJECT_REPORTING_SNAPSHOTS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.GetProjectReportingSnapshotsAction) => action.payload),
      switchMap(leadId => {
        return this.projectReportingApi.projectReportingGetProjectReportingSnapshot(leadId).pipe(
          map((res: ProjectReportingSnapshotViewModel[]) => {
            return new ProjectReporting.GetProjectReportingSnapshotsSuccessAction(res);
          }),
          catchError((error: any) =>
            of(new ProjectReporting.GetProjectReportingSnapshotsFailedAction(error))
          )
        );
      })
    )
  );

  public addProjectReportingSnapshots$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.ADD_PROJECT_REPORTING_SNAPSHOT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.AddProjectReportingSnapshotAction) => action.payload),
      switchMap(payload => {
        return this.projectReportingApi
          .projectReportingAddProjectReportingSnapshot(payload.leadId, payload.body)
          .pipe(
            map((res: ProjectReportingSnapshotViewModel[]) => {
              return new ProjectReporting.AddProjectReportingSnapshotSuccessAction(res);
            }),
            catchError((error: any) =>
              of(new ProjectReporting.AddProjectReportingSnapshotFailedAction(error))
            )
          );
      })
    )
  );

  public putFinancesIncomeAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.PUT_FINANCES_INCOME_ACCOUNT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.PutFinancesIncomeAccount) => action.payload),
      switchMap(payload => {
        return this.projectReportingApi
          .projectReportingPutFinancesIncomeAccount(
            payload.leadId,
            payload.finances.map(x => {
              return { Id: x.Id, IncomeAccount: x.IncomeAccount };
            })
          )
          .pipe(
            map((res: ProjectReportingViewModel) => {
              return new ProjectReporting.PutFinancesIncomeAccountSuccessAction(res);
            }),
            catchError((error: any) =>
              of(new ProjectReporting.PutFinancesIncomeAccountFailedAction(error))
            )
          );
      })
    )
  );

  public putFinancesTime$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.PUT_FINANCES_TIME),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.PutFinancesTime) => action.payload),
      switchMap(payload => {
        const body: ProjectReportingTimeRequest = {
          HourlyRateProjectManagement: Math.round(
            payload.ownContributionFinances.ProjectManagementTime.HourlyRate
          ),
          ExpectedHoursProjectManagement: Math.round(
            payload.ownContributionFinances.ProjectManagementTime.ExpectedHours
          ),
          HourlyRateMontage: Math.round(payload.ownContributionFinances.MontageTime.HourlyRate),
          ExpectedHoursMontage: Math.round(
            payload.ownContributionFinances.MontageTime.ExpectedHours
          ),
          HourlyRateMontageAc: Math.round(payload.ownContributionFinances.MontageAcTime.HourlyRate),
          ExpectedHoursMontageAc: Math.round(
            payload.ownContributionFinances.MontageAcTime.ExpectedHours
          ),
          HourlyRateMontageScaffolding: Math.round(
            payload.ownContributionFinances.MontageScaffoldingTime.HourlyRate
          ),
          ExpectedHoursMontageScaffolding: Math.round(
            payload.ownContributionFinances.MontageScaffoldingTime.ExpectedHours
          ),
          HourlyRateOthers: Math.round(payload.ownContributionFinances.OtherTime.HourlyRate),
          ExpectedHoursOthers: Math.round(payload.ownContributionFinances.OtherTime.ExpectedHours),
        };
        return this.projectReportingApi
          .projectReportingPutProjectReportingTime(payload.leadId, body)
          .pipe(
            map((res: ProjectReportingViewModel) => {
              return new ProjectReporting.PutFinancesTimeSuccessAction(res);
            }),
            catchError((error: any) => of(new ProjectReporting.PutFinancesTimeFailedAction(error)))
          );
      })
    )
  );

  public resetFinancesTime$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.RESET_FINANCES_TIME),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.ResetFinancesTime) => action.leadId),
      switchMap(leadId => {
        return this.projectReportingApi.projectReportingResetProjectReportingTime(leadId).pipe(
          map((res: ProjectReportingViewModel) => {
            return new ProjectReporting.ResetFinancesTimeSuccessAction(res);
          }),
          catchError((error: any) => of(new ProjectReporting.ResetFinancesTimeFailedAction(error)))
        );
      })
    )
  );

  public creditorInvoicePdfDownload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectReporting.DOWNLOAD_PFK_INVOICE_PDF),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectReporting.CreditorInvoicePdfDownloadAction) => action.payload),
      switchMap(payload => {
        return this.documentApi
          .projectReportingGetCreditorInvoicePdf(
            payload.leadId,
            payload.invoiceNumber,
            payload.fiscalYear
          )
          .pipe(
            map((res: any) => {
              const mediaType = 'application/pdf';
              const blob = new Blob([res], { type: mediaType });

              const fileName = `CreditorInvoice-${payload.leadId}-${payload.invoiceNumber}-${payload.fiscalYear}.pdf`;
              saveAs(blob, fileName);

              return new ProjectReporting.CreditorInvoicePdfDownloadSuccessAction();
            }),
            catchError(() => {
              return of(new ProjectReporting.CreditorInvoicePdfDownloadFailedAction());
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private config: ConfigService,
    private projectReportingApi: ProjectReportingService,
    private documentApi: DocumentService
  ) {}
}
