import { Action } from '@ngrx/store';
import { TaskViewModel } from '../../apis/advis/model/taskViewModel';

export const TASKS_LOAD: string = '[Tasks] Load';
export const TASKS_LOAD_SUCCESS: string = '[Tasks] Load Success';
export const TASKS_LOAD_FAILURE: string = '[Tasks] Load Failure';

export class TasksLoadAction implements Action {
  readonly type: string = TASKS_LOAD;

  constructor(public payload: any = undefined) {}
}

export class TasksLoadSuccessAction implements Action {
  readonly type: string = TASKS_LOAD_SUCCESS;

  constructor(public payload: TaskViewModel[]) {}
}

export class TasksLoadFailureAction implements Action {
  readonly type: string = TASKS_LOAD_FAILURE;

  constructor(public payload: any) {}
}

export type Actions = TasksLoadAction | TasksLoadSuccessAction | TasksLoadFailureAction;
