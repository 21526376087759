import { Action } from '@ngrx/store';
import {
  LeadCompositeViewModel,
  PostPutContactRequest,
  PostPutDirectoryContactRequest,
  PostPutSingleContactRequest,
} from 'app/shared/apis/advis';

export const ADD_LEAD_ADDITIONAL_SINGLE_CONTACT: string =
  '[LeadContact] Add lead additional single contact';
export const ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT: string =
  '[LeadContact] Add lead additional address book contact';
export const ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_SUCCESS: string =
  '[LeadContact] Add lead additional address book contact success';
export const ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_FAILED: string =
  '[LeadContact] Add lead additional address book contact failed';
export const UPDATE_LEAD_SINGLE_CONTACT: string = '[LeadContact] Update lead single contact';
export const UPDATE_LEAD_ADDRESS_BOOK_CONTACT: string =
  '[LeadContact] Update lead address book contact';
export const PUT_CONTACT_LOGO: string = '[LeadContact] Update contact';

export const LEAD_CONTACT_UPDATE_SUCCESS: string = '[LeadContact] Lead contact update success';
export const LEAD_CONTACT_UPDATE_FAILED: string = '[LeadContact] Lead contact update failed';

export const LEAD_CONTACT_ADD_SUCCESS: string = '[LeadContact] Lead contact add success';
export const LEAD_CONTACT_ADD_FAILED: string = '[LeadContact] Lead contact add failed';

export const PUT_CONTACT_LOGO_SUCCESS: string = '[LeadContact] Contact logo success';

export const DELETE_ADDITIONAL_LEAD_CONTACT: string =
  '[LeadContact] Delete additional lead contact';

export class AddLeadAdditionalContactSuccessAction implements Action {
  readonly type: string = LEAD_CONTACT_ADD_SUCCESS;

  constructor() {}
}

export interface IAddLeadAdditionalSingleContactActionPayload {
  leadId: number;
  contact: PostPutContactRequest;
  personLogo?: File;
  companyLogo?: File;
}

export class AddLeadAdditionalSingleContactAction implements Action {
  readonly type: string = ADD_LEAD_ADDITIONAL_SINGLE_CONTACT;

  constructor(public payload: IAddLeadAdditionalSingleContactActionPayload) {}
}

export interface IAddLeadAdditionalAddressBookContactActionPayload {
  leadId: number;
  request: PostPutDirectoryContactRequest;
}

export class AddLeadAdditionalAddressBookContactAction implements Action {
  readonly type: string = ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT;

  constructor(public payload: IAddLeadAdditionalAddressBookContactActionPayload) {}
}

export class AddLeadAdditionalAddressBookContactSuccessAction implements Action {
  readonly type: string = ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_SUCCESS;

  constructor() {}
}

export class AddLeadAdditionalAddressBookContactFailedAction implements Action {
  readonly type: string = ADD_LEAD_ADDITIONAL_ADDRESSBOOK_CONTACT_FAILED;

  constructor() {}
}

export interface IUpdateLeadSingleContactActionPayload {
  leadId: number;
  contactId?: number;
  contact: PostPutSingleContactRequest;
  personLogo?: File;
  companyLogo?: File;
}

export class UpdateLeadSingleContactAction implements Action {
  readonly type: string = UPDATE_LEAD_SINGLE_CONTACT;

  constructor(public payload: IUpdateLeadSingleContactActionPayload) {}
}

export interface IUpdateLeadAddressBookContactActionPayload {
  leadId: number;
  request: PostPutDirectoryContactRequest;
}

export class UpdateLeadAddressBookContactAction implements Action {
  readonly type: string = UPDATE_LEAD_ADDRESS_BOOK_CONTACT;

  constructor(public payload: IUpdateLeadAddressBookContactActionPayload) {}
}

export interface IPutContactLogoActionPayload {
  lead: LeadCompositeViewModel;
  isNew: boolean;
  contactId: number;
  personLogo?: File;
  companyLogo?: File;
}

export class PutContactLogoAction implements Action {
  readonly type: string = PUT_CONTACT_LOGO;

  constructor(public payload: IPutContactLogoActionPayload) {}
}

export class PutContactLogoSuccessAction implements Action {
  readonly type: string = PUT_CONTACT_LOGO_SUCCESS;
  constructor(public payload: IPutContactLogoActionPayload) {}
}

export interface IDeleteAdditionalLeadContactActionPayload {
  leadId: number;
  contactId: number;
}

export class DeleteAdditionalLeadContactAction implements Action {
  readonly type: string = DELETE_ADDITIONAL_LEAD_CONTACT;

  constructor(public payload: IDeleteAdditionalLeadContactActionPayload) {}
}

export class LeadContactUpdateSuccessAction implements Action {
  readonly type: string = LEAD_CONTACT_UPDATE_SUCCESS;
}

export class LeadContactUpdateFailedAction implements Action {
  readonly type: string = LEAD_CONTACT_UPDATE_FAILED;
}

export type Actions =
  | UpdateLeadSingleContactAction
  | UpdateLeadAddressBookContactAction
  | LeadContactUpdateSuccessAction
  | LeadContactUpdateFailedAction;
