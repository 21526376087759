import { Action } from '@ngrx/store';
import { LicenseInfo } from '../../apis/advis';

export const GET_TEXT_EDITOR_LICENSE: string = '[Licenses] Editor License';
export const GET_TEXT_EDITOR_LICENSE_SUCCESS: string = '[Licenses] Editor License Success';
export const GET_TEXT_EDITOR_LICENSE_FAILURE: string = '[Licenses] Editor License Failure';

export class GetTextEditorLicenseAction implements Action {
  readonly type = GET_TEXT_EDITOR_LICENSE;
}

export class GetTextEditorLicenseSuccessAction implements Action {
  readonly type = GET_TEXT_EDITOR_LICENSE_SUCCESS;

  constructor(public payload: LicenseInfo) {}
}

export class GetTextEditorLicenseFailureAction implements Action {
  readonly type = GET_TEXT_EDITOR_LICENSE_FAILURE;

  constructor(public payload: string) {}
}

export type Actions =
  | GetTextEditorLicenseAction
  | GetTextEditorLicenseSuccessAction
  | GetTextEditorLicenseFailureAction;
