import { Pipe, PipeTransform } from '@angular/core';
import { LeadListViewModel } from '../../shared/apis/advis';
import { LeadUtil } from '../../shared/utils/lead.util';

@Pipe({
  name: 'ownerDisplayName',
})
export class OwnerDisplayNamePipe implements PipeTransform {
  transform(user: LeadListViewModel): string {
    return LeadUtil.buildOwnerName(user);
  }
}
