import { Component, Inject, OnInit } from '@angular/core';
import { EnumService, IEnumData } from '../../../shared/services/enum.service';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { NotificationService, TypeE } from '../../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseContactFormDirective } from '../../../lead/common/base/BaseContactFormDirective';
import {
  DirectoryPersonViewModel,
  DirectoryService,
  LeadViewModel,
  MasterDataViewModel,
  PostPutContactRequest,
  PostPutEmailRequest,
  PostPutPhoneRequest,
} from '../../../shared/apis/advis';
import LanguageEnum = LeadViewModel.LanguageEnum;
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { AddressBookBackendService } from '../../../shared/services/address-book-backend.service';
import { IExternManagedAddressTypeConfig } from '../../../shared/utils/exter-managed-address-type.util';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';
import PreferredCommunicationTypeEnum = DirectoryPersonViewModel.PreferredCommunicationTypeEnum;

export interface IExternalManagedData {
  mandatorId: number;
  language: string;
  externalManagedAddressConfig: IExternManagedAddressTypeConfig;
}

@Component({
  selector: 'pc-add-external-managed-contact-dialog',
  templateUrl: './add-external-managed-contact-dialog.component.html',
  styleUrls: ['./add-external-managed-contact-dialog.component.scss'],
})
export class AddExternalManagedContactDialogComponent
  extends BaseContactFormDirective
  implements OnInit
{
  public selectedTitleItem: string = TitleEnum.None;
  public titleItems: IEnumData[] = [];
  public selectedLanguageItem: string;
  public selectedCommunicationType: PreferredCommunicationTypeEnum;
  public modalName: string = AddExternalManagedContactDialogComponent.name;
  public sharedAddressBookMandatorIds: number[];
  public externalManagedAddressName: string;

  public companyName?: string;
  public firstName?: string;
  public lastName?: string;
  public street?: string;
  public zipCode?: string;
  public city?: string;
  public careOfName?: string;
  public postOfficeBox?: string;
  public primaryEmail?: string;
  public reminderEmail?: string;
  public primaryPhone?: string;
  public mobilePhone?: string;

  constructor(
    dialogService: DialogService,
    private dialogRef: DialogRef,
    private addressBookBackendService: AddressBookBackendService,
    loadingModalService: LoadingModalService,
    notification: NotificationService,
    private store: Store<RootReducer.IState>,
    private directoryService: DirectoryService,
    @Inject(DIALOG_DATA) private data: IExternalManagedData,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(sanitizer, translate, dialogService, notification, loadingModalService, enumService);
  }

  public ngOnInit(): void {
    this.externalManagedAddressName = this.data.externalManagedAddressConfig.translation;
    this.selectedLanguageItem = this.data.language;
    this.titleItems = this.enumService.getEnumDataExclude(TitleEnum, [TitleEnum.None]);
    this.subscriptions.add(
      this.store
        .select<MasterDataViewModel>(RootReducer.getGlobalMasterData)
        .subscribe(masterData => {
          this.sharedAddressBookMandatorIds = masterData.Mandators.find(
            x => x.Id === this.data.mandatorId
          ).SharedAddressBookMandatorIds;
        })
    );

    super.ngOnInit();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public isValid(): boolean {
    return (
      isNullOrUndefined(this.zipCode) ||
      isNullOrUndefined(this.street) ||
      isNullOrUndefined(this.city)
    );
  }

  public saveExternalManagedContact(): void {
    if (
      isNullOrUndefined(this.companyName) &&
      isNullOrUndefined(this.firstName) &&
      isNullOrUndefined(this.lastName)
    ) {
      this.subscriptions.add(
        this.dialogService
          .openAlert(
            this.translate.instant('ADDRESS.DIALOGS.ADD_EXTERNAL_MANAGED_CONTACT.VALIDATION_ERROR')
          )
          .afterClosed()
          .subscribe()
      );
      return;
    }

    this.save(true);
  }

  protected saveEntry(): void {
    const request: PostPutContactRequest = {
      ContactId: 0,
      AddressType: this.data.externalManagedAddressConfig.addressType,
      Person: {
        Language:
          this.selectedLanguageItem === LanguageEnum.EN
            ? 'EN'
            : this.selectedLanguageItem === LanguageEnum.FR
            ? 'FR'
            : this.selectedLanguageItem === LanguageEnum.IT
            ? 'IT'
            : 'DE',
        PreferredCommunicationType: this.selectedCommunicationType,
      },
      IsSingleContact: false,
      IsValidated: true,
    };

    const emails = this.buildEmailList([]);
    const phones = this.buildPhoneList([]);

    if (!isNullOrUndefined(this.firstName) || !isNullOrUndefined(this.lastName)) {
      request.Person = {
        Title:
          this.selectedTitleItem === TitleEnum.Company
            ? 'Company'
            : this.selectedTitleItem === TitleEnum.Sir
            ? 'Sir'
            : this.selectedTitleItem === TitleEnum.Family
            ? 'Family'
            : this.selectedTitleItem === TitleEnum.Madam
            ? 'Madam'
            : 'None',
        FirstName: this.firstName,
        LastName: this.lastName,
        City: this.city,
        Street: this.street,
        ZipCode: this.zipCode,
        CareOfName: this.careOfName,
        PostOfficeBox: this.postOfficeBox,
        Emails: emails,
        Phones: phones,
        Language: request.Person.Language,
        PreferredCommunicationType: request.Person.PreferredCommunicationType,
      };

      if (!isNullOrUndefined(this.companyName)) {
        request.Company = {
          Name: this.companyName,
        };
      }
    } else {
      request.Company = {
        Name: this.companyName,
        City: this.city,
        Street: this.street,
        ZipCode: this.zipCode,
        CareOfName: this.careOfName,
        PostOfficeBox: this.postOfficeBox,
        Emails: emails,
        Phones: phones,
      };
    }

    this.addressBookBackendService
      .externManagedRequest(
        this.sharedAddressBookMandatorIds.join(','),
        this.loadingModalService,
        this.data.externalManagedAddressConfig,
        0,
        request.Person?.LastName,
        request.Person?.FirstName,
        request.Company?.Name,
        request.Person.Street ?? request.Company.Street,
        request.Person.City ?? request.Company.City,
        request.Person.ZipCode ?? request.Company.ZipCode,
        emails.length > 0 ? emails[0].Email : undefined,
        phones.length > 0 ? phones[0].PhoneNumber : undefined
      )
      .subscribe(result => {
        if (result.save && !isNullOrUndefined(result.addContactToLead)) {
          this.dialogRef.close({ Id: result.addContactToLead });
        } else if (
          (result.save || (result.saveExternalManagedContactRequest ?? false)) &&
          !isNullOrUndefined(result.selectNewId) &&
          result.selectNewId === 0
        ) {
          this.subscriptions.add(
            this.subscriptions.add(
              this.directoryService
                .directoryCreateExternalManagedContact(this.data.mandatorId, request)
                .subscribe(
                  contact => {
                    this.notification.notifySimple(
                      this.translate.instant('ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL'),
                      TypeE.PRIMARY
                    );
                    this.dialogRef.close(contact);
                  },
                  () =>
                    this.notification.notifySimple(
                      this.translate.instant('ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'),
                      TypeE.ALERT
                    )
                )
            )
          );
        } else {
          this.loadingModalService.closeModal();
        }
      });
  }

  private buildEmailList(list: PostPutEmailRequest[]): PostPutEmailRequest[] {
    if (!isNullOrUndefined(this.primaryEmail) && this.primaryEmail.length > 0) {
      list.push({
        EmailType: 'Primary',
        Email: this.primaryEmail,
      });
    }

    if (!isNullOrUndefined(this.reminderEmail) && this.reminderEmail.length > 0) {
      list.push({
        EmailType: 'Reminder',
        Email: this.reminderEmail,
      });
    }
    return list;
  }

  private buildPhoneList(list: PostPutPhoneRequest[]): PostPutPhoneRequest[] {
    if (!isNullOrUndefined(this.primaryPhone) && this.primaryPhone.length > 0) {
      list.push({
        PhoneType: 'Mobile',
        PhoneNumber: this.primaryPhone,
      });
    }

    if (!isNullOrUndefined(this.mobilePhone) && this.mobilePhone.length > 0) {
      list.push({
        PhoneType: 'Mobile',
        PhoneNumber: this.mobilePhone,
      });
    }
    return list;
  }
}
