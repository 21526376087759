import { IViewSettingBase } from './view-setting-base.model';

export enum ActiveViewE {
  Undefined = 0,
  All = 1,
  Onlinepool = 2,
  Own = 3,
  Triage = 4,
  New = 5,
  Followup = 6,
  Tasks = 7,
  OnHold,
  Won,
  NotAssigned = 10,
  ProjectsInProgress = 11,
  ProjectOverview = 12,
  ProjectOverviewOwn = 13,
  Callpool = 14,
  Downloadpool = 15,
  ProjectOverviewOwnOrdered = 16,
  EnergySolutionApprovalNotSet = 17,
  EnergySolutionApproved = 18,
  EnergySolutionDisapproved = 19,
  OrderEntry = 21,
}

export class LeadOverviewVsModel implements IViewSettingBase<LeadOverviewVsModel> {
  public activeView: number = ActiveViewE.Undefined;

  clone(): LeadOverviewVsModel {
    const model: LeadOverviewVsModel = new LeadOverviewVsModel();
    model.activeView = this.activeView;
    return model;
  }
}
