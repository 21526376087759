// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';
import {
  LeadDocumentTypeViewModel,
  PatchChecklistPhaseRequest,
  PatchCustomChecklistStepRequest,
  PostCustomChecklistStepRequest,
  ProjectChecklistTemplateViewModel,
  ProjectChecklistViewModel,
} from 'app/shared/apis/advis';
import { ISopChecklistViewModel } from './lead-project.effects';

export const PATCH_PROJECT_SUCCESS: string = '[LeadProject] Patch project success';
export const PATCH_PROJECT_FAILED: string = '[LeadProject] Patch project failed';

export const PATCH_PROJECT_DOCUMENTATION: string = '[LeadProject] Patch project documentation';

export const DELETE_PROJECT_DOCUMENTATION_SUCCESS: string =
  '[LeadProject] Delete project documentation success';
export const DELETE_PROJECT_DOCUMENTATION_FAILED: string =
  '[LeadProject] Delete project documentation failed';

export const DELETE_PROJECT_DOCUMENTATION: string = '[LeadProject] Delete project documentation';

export const LOAD_PROJECT_CHECKLIST: string = '[LeadProject] Load checklist';
export const LOAD_PROJECT_CHECKLIST_SUCCESS: string = '[LeadProject] Load checklist success';
export const LOAD_PROJECT_CHECKLIST_FAILURE: string = '[LeadProject] Load checklist failure';

export const LOAD_PROJECT_SOP_CHECKLIST: string = '[LeadProject] Load SOP checklist';
export const LOAD_PROJECT_SOP_CHECKLIST_SUCCESS: string =
  '[LeadProject] Load checklist SOP success';
export const LOAD_PROJECT_SOP_CHECKLIST_FAILURE: string =
  '[LeadProject] Load checklist SOP failure';

export const CREATE_PROJECT_CHECKLIST: string = '[LeadProject] Create checklist';
export const CREATE_PROJECT_CHECKLIST_SUCCESS: string = '[LeadProject] Create checklist success';
export const CREATE_PROJECT_CHECKLIST_FAILURE: string = '[LeadProject] Create checklist failure';

export const PATCH_PROJECT_CHECKLIST_PHASE: string = '[LeadProject] Patch checklist phase';
export const PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS: string =
  '[LeadProject] Patch checklist phase success';
export const PATCH_PROJECT_CHECKLIST_PHASE_FAILURE: string =
  '[LeadProject] Patch checklist phase failure';

export const CLICK_PROJECT_CHECKLIST_STEP: string = '[LeadProject] Click checklist step';
export const CLICK_PROJECT_CHECKLIST_STEP_SUCCESS: string =
  '[LeadProject] Click checklist step success';
export const CLICK_PROJECT_CHECKLIST_STEP_FAILURE: string =
  '[LeadProject] Click checklist step failure';

export const CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP: string =
  '[LeadProject] Click checklist step and get updated SOP step';
export const CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_SUCCESS: string =
  '[LeadProject] Click checklist step success and update SOP step success';
export const CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_FAILURE: string =
  '[LeadProject] Click checklist step failure and update SOP step failure';

export const CLICK_PROJECT_CHECKLIST_SOP_STEP: string = '[LeadProject] Click checklist SOP step';
export const CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS: string =
  '[LeadProject] Click checklist SOP step success';
export const CLICK_PROJECT_CHECKLIST_SOP_STEP_FAILURE: string =
  '[LeadProject] Click checklist SOP step failure';

export const CLICK_PROJECT_CHECKLIST_SOP_PHASE: string = '[LeadProject] Click checklist SOP phase';
export const CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS: string =
  '[LeadProject] Click checklist SOP phase success';
export const CLICK_PROJECT_CHECKLIST_SOP_PHASE_FAILURE: string =
  '[LeadProject] Click checklist SOP phase failure';

export const LOAD_PROJECT_CHECKLIST_TEMPLATES: string = '[LeadProject] Load checklist templates';
export const LOAD_PROJECT_CHECKLIST_TEMPLATES_SUCCESS: string =
  '[LeadProject] Load checklist templates success';
export const LOAD_PROJECT_CHECKLIST_TEMPLATES_FAILURE: string =
  '[LeadProject] Load checklist templates failure';

export const ADD_CUSTOM_SOP_CHECKLIST_STEP: string = '[LeadProject] Add custom SOP checklist step';
export const ADD_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS: string =
  '[LeadProject] Add custom SOP checklist step success';
export const ADD_CUSTOM_SOP_CHECKLIST_STEP_FAILURE: string =
  '[LeadProject] Add custom SOP checklist step failure';

export const DELETE_CUSTOM_SOP_CHECKLIST_STEP: string =
  '[LeadProject] Delete custom SOP checklist step';
export const DELETE_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS: string =
  '[LeadProject] Delete custom SOP checklist step success';
export const DELETE_CUSTOM_SOP_CHECKLIST_STEP_FAILURE: string =
  '[LeadProject] Delete custom SOP checklist step failure';

export const EDIT_CUSTOM_SOP_CHECKLIST_STEP: string =
  '[LeadProject] Edit custom SOP checklist step';
export const EDIT_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS: string =
  '[LeadProject] Edit custom SOP checklist step success';
export const EDIT_CUSTOM_SOP_CHECKLIST_STEP_FAILURE: string =
  '[LeadProject] Edit custom SOP checklist step failure';

export interface IPatchDocumentationPayload {
  leadId: number;
  documents: IPatchDocumentPayload[];
}

export interface IPatchDocumentPayload {
  file: File;
  fileToken: string;
  fileName: string;
  documentType: LeadDocumentTypeViewModel;
  documentId: number;
}

export interface IDeleteProjectDocumentationPayload {
  leadId: number;
  fileNames: string[];
  documentType: LeadDocumentTypeViewModel;
}

export interface ICreateProjectChecklistPayload {
  leadId: number;
  templateId: number;
}

export interface IClickProjectChecklistStepPayload {
  leadId: number;
  stepId: string;
}

export interface IClickProjectChecklistSopPhasePayload {
  leadId: number;
  phaseKey: number;
  desiredCheckedState: boolean;
}

export interface IClickProjectChecklistSopStepPayload {
  leadId: number;
  stepId: number;
  desiredCheckedState: boolean;
}

export interface IPatchChecklistPhasePayload {
  leadId: number;
  request: PatchChecklistPhaseRequest[];
}

export class PatchProjectDocumentation implements Action {
  public readonly type: string = PATCH_PROJECT_DOCUMENTATION;

  constructor(public payload: IPatchDocumentationPayload) {}
}

export class PatchProjectSuccess implements Action {
  public type: string = PATCH_PROJECT_SUCCESS;
}

export class PatchProjectFailed implements Action {
  public type: string = PATCH_PROJECT_FAILED;
}

export class DeleteProjectDocumentation implements Action {
  public type: string = DELETE_PROJECT_DOCUMENTATION;

  constructor(public payload: IDeleteProjectDocumentationPayload) {}
}

export class DeleteProjectDocumentationSuccess implements Action {
  public type: string = DELETE_PROJECT_DOCUMENTATION_SUCCESS;
}

export class DeleteProjectDocumentationFailed implements Action {
  public type: string = DELETE_PROJECT_DOCUMENTATION_FAILED;
}

export class ProjectChecklistLoadAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST;

  constructor(public payload: number) {}
}

export class ProjectChecklistLoadSuccessAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST_SUCCESS;

  constructor(public payload: ProjectChecklistViewModel) {}
}

export class ProjectChecklistLoadFailureAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectSopChecklistLoadAction implements Action {
  public type: string = LOAD_PROJECT_SOP_CHECKLIST;

  constructor(public payload: number) {}
}

export class ProjectSopChecklistLoadSuccessAction implements Action {
  public type: string = LOAD_PROJECT_SOP_CHECKLIST_SUCCESS;

  constructor(public payload: ISopChecklistViewModel) {}
}

export class ProjectSopChecklistLoadFailureAction implements Action {
  public type: string = LOAD_PROJECT_SOP_CHECKLIST_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistTemplatesLoadAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST_TEMPLATES;

  constructor(public payload: number) {}
}

export class ProjectChecklistTemplatesLoadSuccessAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST_TEMPLATES_SUCCESS;

  constructor(public payload: ProjectChecklistTemplateViewModel[]) {}
}

export class ProjectChecklistTemplatesLoadFailureAction implements Action {
  public type: string = LOAD_PROJECT_CHECKLIST_TEMPLATES_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistCreateAction implements Action {
  public type: string = CREATE_PROJECT_CHECKLIST;

  constructor(public payload: ICreateProjectChecklistPayload) {}
}

export class ProjectChecklistCreateSuccessAction implements Action {
  public type: string = CREATE_PROJECT_CHECKLIST_SUCCESS;

  constructor(public payload: ProjectChecklistViewModel) {}
}

export class ProjectChecklistCreateFailureAction implements Action {
  public type: string = CREATE_PROJECT_CHECKLIST_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistPatchPhaseAction implements Action {
  public type: string = PATCH_PROJECT_CHECKLIST_PHASE;

  constructor(public payload: IPatchChecklistPhasePayload) {}
}

export class ProjectChecklistPatchPhaseSuccessAction implements Action {
  public type: string = PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS;

  constructor(public payload: ProjectChecklistViewModel) {}
}

export class ProjectChecklistPatchPhaseFailureAction implements Action {
  public type: string = PATCH_PROJECT_CHECKLIST_PHASE_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistClickStepAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP;

  constructor(public payload: IClickProjectChecklistStepPayload) {}
}

export class ProjectChecklistClickStepSuccessAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP_SUCCESS;

  constructor(public payload: ProjectChecklistViewModel) {}
}

export class ProjectChecklistClickStepFailureAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistClickStepWithSopAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP;

  constructor(public payload: IClickProjectChecklistStepPayload) {}
}

export class ProjectChecklistClickStepWithSopSuccessAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_SUCCESS;

  constructor(public payload: ISopChecklistViewModel) {}
}

export class ProjectChecklistClickStepWithSopFailureAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistClickSopStepAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_STEP;

  constructor(public payload: IClickProjectChecklistSopStepPayload) {}
}

export class ProjectChecklistClickSopStepSuccessAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS;

  constructor(public payload: ISopChecklistViewModel) {}
}

export class ProjectChecklistClickSopStepFailureAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_STEP_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectChecklistClickSopPhaseAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_PHASE;

  constructor(public payload: IClickProjectChecklistSopPhasePayload) {}
}

export class ProjectChecklistClickSopPhaseSuccessAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS;

  constructor(public payload: ISopChecklistViewModel) {}
}

export class ProjectChecklistClickSopPhaseFailureAction implements Action {
  public type: string = CLICK_PROJECT_CHECKLIST_SOP_PHASE_FAILURE;

  constructor(public payload: string) {}
}

export class ProjectSopChecklistAddStepAction implements Action {
  public type: string = ADD_CUSTOM_SOP_CHECKLIST_STEP;

  constructor(public payload: PostCustomChecklistStepRequest & { leadId: number }) {}
}

export class ProjectSopChecklistAddStepSuccessAction implements Action {
  public type: string = ADD_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS;

  constructor(public payload: any) {}
}

export class ProjectSopChecklistAddStepFailureAction implements Action {
  public type: string = ADD_CUSTOM_SOP_CHECKLIST_STEP_FAILURE;

  constructor(public payload: any) {}
}

export class ProjectSopChecklistEditStepAction implements Action {
  public type: string = EDIT_CUSTOM_SOP_CHECKLIST_STEP;

  constructor(
    public payload: { leadId: number; stepId: string; body: PatchCustomChecklistStepRequest }
  ) {}
}

export class ProjectSopChecklistEditStepSuccessAction implements Action {
  public type: string = EDIT_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS;

  constructor(public payload: any) {}
}

export class ProjectSopChecklistEditStepFailureAction implements Action {
  public type: string = EDIT_CUSTOM_SOP_CHECKLIST_STEP_FAILURE;

  constructor(public payload: any) {}
}

export class ProjectSopChecklistDeleteStepAction implements Action {
  public type: string = DELETE_CUSTOM_SOP_CHECKLIST_STEP;

  constructor(public payload: { leadId: number; stepId: string }) {}
}

export class ProjectSopChecklistDeleteStepSuccessAction implements Action {
  public type: string = DELETE_CUSTOM_SOP_CHECKLIST_STEP_SUCCESS;

  constructor(public payload: any) {}
}

export class ProjectSopChecklistDeleteStepFailureAction implements Action {
  public type: string = DELETE_CUSTOM_SOP_CHECKLIST_STEP_FAILURE;

  constructor(public payload: any) {}
}

export type Actions =
  | PatchProjectDocumentation
  | PatchProjectSuccess
  | PatchProjectFailed
  | DeleteProjectDocumentation
  | DeleteProjectDocumentationSuccess
  | DeleteProjectDocumentationFailed
  | ProjectChecklistLoadAction
  | ProjectChecklistLoadSuccessAction
  | ProjectChecklistLoadFailureAction
  | ProjectChecklistTemplatesLoadAction
  | ProjectChecklistTemplatesLoadSuccessAction
  | ProjectChecklistTemplatesLoadFailureAction
  | ProjectChecklistCreateAction
  | ProjectChecklistCreateSuccessAction
  | ProjectChecklistCreateFailureAction
  | ProjectChecklistPatchPhaseAction
  | ProjectChecklistPatchPhaseSuccessAction
  | ProjectChecklistPatchPhaseFailureAction
  | ProjectChecklistClickStepAction
  | ProjectChecklistClickStepSuccessAction
  | ProjectChecklistClickStepFailureAction
  | ProjectSopChecklistLoadAction
  | ProjectSopChecklistLoadSuccessAction
  | ProjectSopChecklistLoadFailureAction
  | ProjectChecklistClickSopStepAction
  | ProjectChecklistClickSopStepSuccessAction
  | ProjectChecklistClickSopStepFailureAction
  | ProjectChecklistClickSopPhaseAction
  | ProjectChecklistClickSopPhaseSuccessAction
  | ProjectChecklistClickSopPhaseFailureAction
  | ProjectSopChecklistAddStepAction
  | ProjectSopChecklistAddStepSuccessAction
  | ProjectSopChecklistAddStepFailureAction
  | ProjectSopChecklistEditStepAction
  | ProjectSopChecklistEditStepSuccessAction
  | ProjectSopChecklistEditStepFailureAction
  | ProjectSopChecklistDeleteStepAction
  | ProjectSopChecklistDeleteStepSuccessAction
  | ProjectSopChecklistDeleteStepFailureAction;
