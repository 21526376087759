<div fxLayout="column" fxLayoutGap="20px">
  <form #form="ngForm">
    <div fxLayout="row wrap" fxLayoutGap="16">
      <div class="flex-col">
        <helion-ui-select
          placeholder="{{ 'ADDRESS.TITLE' | translate }}"
          name="title"
          [ngModel]="selectedTitleItem"
          (ngModelChange)="setPersonTitle($event)"
          label="display">
          <helion-ui-option *ngFor="let item of titleItems" [value]="item" [label]="item?.display">
          </helion-ui-option>
        </helion-ui-select>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.FIRST_NAME' | translate }}"
          type="text"
          maxlength="30"
          name="firstName"
          [(ngModel)]="person.FirstName"
          [required]="true">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.LAST_NAME' | translate }}"
          type="text"
          maxlength="30"
          name="lastName"
          [(ngModel)]="person.LastName"
          [required]="true">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.STREET' | translate }}"
          type="text"
          [maxlength]="requiresStrictValidation ? 30 : 50"
          [required]="isObjectContact"
          name="street"
          [(ngModel)]="person.Street">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CARE_OF_NAME' | translate }}"
          type="text"
          name="careOfName"
          maxlength="40"
          [(ngModel)]="person.CareOfName">
        </helion-ui-input>

        <div
          *ngFor="let additionalEmail of person.Emails; let index = index"
          class="width-100"
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start end">
          <helion-ui-input
            fxFlex="50"
            placeholder="{{
              (additionalEmail.EmailType === emailTypeEnum.Primary
                ? 'ADDRESS.PRIMARY_EMAIL'
                : 'ADDRESS.ADDITIONAL_EMAIL'
              ) | translate
            }}"
            email="true"
            type="email"
            maxlength="100"
            name="additionalEmail{{ index }}"
            [(ngModel)]="additionalEmail.Email">
          </helion-ui-input>

          <ng-container
            [ngTemplateOutlet]="contactMethodTypeSelector"
            [ngTemplateOutletContext]="{
              model: additionalEmail.EmailType,
              source: emailTypes,
              index: index,
              removeFunction: removeEmail,
              modelChangeFunction: setAdditionalEmailType.bind(this)
            }"></ng-container>
        </div>
      </div>

      <div class="flex-col">
        <helion-ui-input
          placeholder="{{ 'ADDRESS.ZIP' | translate }}"
          type="text"
          [maxlength]="requiresStrictValidation ? 10 : 30"
          name="zip"
          [required]="isObjectContact"
          [(ngModel)]="person.ZipCode">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CITY' | translate }}"
          type="text"
          maxlength="30"
          name="city"
          [required]="isObjectContact"
          [(ngModel)]="person.City">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.POST_OFFICE_BOX' | translate }}"
          type="text"
          name="postOfficeBox"
          maxlength="30"
          [(ngModel)]="person.PostOfficeBox">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.THIRD_PARTY_CUSTOMER_NUMBER' | translate }}"
          type="text"
          maxlength="50"
          name="thirdPartyCustomerNumber"
          [(ngModel)]="person.ThirdPartyCustomerNumber"
          data-cy="thirdPartyCustomerNumber">
        </helion-ui-input>

        <helion-ui-select
          placeholder="{{ 'COMMON.LANGUAGE' | translate }}"
          name="language"
          [ngModel]="selectedLanguageItem"
          (ngModelChange)="setPersonLanguage($event)"
          label="display">
          <helion-ui-option
            *ngFor="let item of languageItems"
            [value]="item"
            [label]="item?.display">
          </helion-ui-option>
        </helion-ui-select>

        <div
          *ngFor="let additionalPhone of person.Phones; let index = index"
          class="width-100"
          fxLayout="row"
          fxLayoutAlign="start end"
          fxLayoutGap="10px">
          <helion-ui-input
            fxFlex="50"
            placeholder="{{
              (additionalPhone.PhoneType === phoneTypeEnum.Primary
                ? 'ADDRESS.PRIMARY_PHONE'
                : 'ADDRESS.ADDITIONAL_PHONE'
              ) | translate
            }}"
            name="additionalPhone{{ index }}"
            [(ngModel)]="additionalPhone.PhoneNumber">
          </helion-ui-input>

          <ng-container
            [ngTemplateOutlet]="contactMethodTypeSelector"
            [ngTemplateOutletContext]="{
              model: additionalPhone.PhoneType,
              source: phoneTypes,
              index: index,
              removeFunction: removePhone,
              modelChangeFunction: setAdditionalPhoneType.bind(this)
            }"></ng-container>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)" class="contact-form__block">
        <div fxLayout="column">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.NOTES' | translate }}</h4>
          <textarea
            class="textarea"
            fxFlex="100"
            placeholder="{{ 'ADDRESS.TEXT' | translate }}"
            rows="8"
            cols="40"
            maxlength="2000"
            name="notes"
            [(ngModel)]="person.Notes"></textarea>
        </div>
      </div>
      <div
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 16px)"
        class="contact-form__block"
        fxLayoutGap="20px">
        <pc-select-communication-type
          name="preferredCommunicationType"
          [(ngModel)]="person.PreferredCommunicationType">
        </pc-select-communication-type>
        <div *ngIf="logoUrl" fxLayout="column" class="contact-form__image">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.LOGO' | translate }}</h4>
          <img [alt]="logoUrl" [src]="sanitizer.bypassSecurityTrustUrl(logoUrl)" class="img" />
          <button
            style="margin-top: 16px"
            [attr.fill-type]="'icon'"
            [attr.aria-label]="'COMMON.ACTION.DELETE' | translate"
            [tooltip]="'COMMON.ACTION.DELETE' | translate"
            (click)="logoDelete.emit()">
            <helion-ui-icon style="display: block" data-feather="trash"></helion-ui-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template
  #contactMethodTypeSelector
  let-model="model"
  let-modelChangeFunction="modelChangeFunction"
  let-source="source"
  let-removeFunction="removeFunction"
  let-index="index">
  <helion-ui-select
    fxFlex="100"
    [ngModel]="model"
    (ngModelChange)="modelChangeFunction(index, $event)">
    <helion-ui-option *ngFor="let item of source" [value]="item.Key" [label]="item.Translation">
    </helion-ui-option>
  </helion-ui-select>

  <helion-ui-icon
    class="select-icon"
    style="align-items: center"
    data-feather="x-circle"
    (click)="removeFunction(person, index)">
  </helion-ui-icon>
</ng-template>
