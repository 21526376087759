import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { PictureUploadService } from './picture-upload.service';

export interface IImageResizeOptions {
  withAlpha: boolean;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageResizeService {
  constructor(private pictureUploadService: PictureUploadService) {
    // empty
  }

  public generateThumbnails(files: File[], options: IImageResizeOptions): Observable<File[]> {
    return from(
      Promise.all(
        files.map((f: File) =>
          this.resizeImage(f, options, 200)
            .then((returnedFile: Blob | File) => {
              const thumbnailName: string = this.pictureUploadService.generateThumbnailName(f.name);

              if (returnedFile instanceof File) {
                return new File([returnedFile], thumbnailName, { type: returnedFile.type });
              } else {
                return new File([returnedFile], thumbnailName, { type: 'image/jpeg' });
              }
            })
            .catch(() => null)
        )
      )
    );
  }

  public doImageResize(
    files: File[],
    maxWidth: number,
    maxHeight: number,
    options: IImageResizeOptions
  ): Observable<File[]> {
    return from(
      Promise.all(
        files.map((f: File, i: number) =>
          this.resizeImage(f, options, maxWidth, maxHeight)
            .then((returnedFile: Blob | File) => {
              let resultFile: File;
              if (returnedFile instanceof File) {
                resultFile = new File(
                  [returnedFile],
                  this.pictureUploadService.generateNewFileName(returnedFile, i),
                  { type: returnedFile.type }
                );
              } else {
                const type: string = options.withAlpha ? 'png' : 'jpeg';
                resultFile = new File(
                  [returnedFile],
                  this.pictureUploadService.generateFileName(type, i),
                  { type: `image/${type}` }
                );
              }

              return resultFile;
            })
            .catch(() => null)
        )
      )
    );
  }

  private resizeImage(
    file: File,
    options: IImageResizeOptions,
    maxWidth: number,
    maxHeight?: number
  ): Promise<Blob | File> {
    return new Promise((resolve: any, reject: any) => {
      const image: HTMLImageElement = new Image();

      image.onload = () => {
        const width: number = image.width;
        const height: number = image.height;

        if (!maxHeight) {
          const divisor: number = width / maxWidth;
          maxHeight = Math.round(height / divisor);
        }

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth: number;
        let newHeight: number;

        if (width > height) {
          newHeight = Math.floor(height * (maxWidth / width));
          newWidth = Math.floor(maxWidth);
        } else {
          newWidth = Math.floor(width * (maxHeight / height));
          newHeight = Math.floor(maxHeight);
        }

        let canvasElement: HTMLCanvasElement = document.createElement('canvas');
        canvasElement.width = newWidth;
        canvasElement.height = newHeight;

        let context: CanvasRenderingContext2D = canvasElement.getContext('2d', {
          alpha: options.withAlpha,
        });

        context.drawImage(image, 0, 0, newWidth, newHeight);

        setTimeout(() => {
          canvasElement.toBlob(resolve, options.type);
          canvasElement.width = 0;
          canvasElement.height = 0;

          canvasElement = undefined;
          context = undefined;
        }, 100);
      };

      image.onerror = reject;

      image.src = URL.createObjectURL(file);
    });
  }
}
