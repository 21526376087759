/**
 * Created by Aron Heynen on 11.10.2017.
 */
import { Option } from './option.model';

export class OptionGroupModel {
  // Should be the one and only exception to have capital letter at the beginning
  /* tslint:disable */

  /**
   * The id to be used for further calls
   */
  id?: number;

  /**
   * A translated name of the group title
   */
  de?: string;

  /**
   * A translated name of the group title
   */
  it?: string;

  /**
   * A translated name of the group title
   */
  fr?: string;

  /**
   * A translated name of the group title
   */
  en?: string;

  /**
   * Options
   */
  options?: Option[] = [];

  /* tslint:enable */
}
