import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  private _apiDebounceTimeMs: number = 400;
  private _apiMaxPageSize: number = 10000; // elasticsearch limit;
  private _apiPollOwnOpenTasksIntervalMs: number = 3600 * 1000; // every hour
  private _onlinepoolIndicators: string[] = [' OW', ' CC']; // Space by intention

  constructor() {
    // empty
  }

  get apiDebounceTimeMs(): number {
    return this._apiDebounceTimeMs;
  }

  get apiMaxPageSize(): number {
    return this._apiMaxPageSize;
  }

  get apiPollOwnOpenTasksIntervalMs(): number {
    return this._apiPollOwnOpenTasksIntervalMs;
  }

  get onlinepoolIndicators(): string[] {
    return this._onlinepoolIndicators;
  }
}
