import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { EnvironmentTypeEnum } from '../../../environments/environment.interface';

@Injectable()
export class EnvironmentService {
  environmentType: EnvironmentTypeEnum = environment.environmentType;

  constructor() {
    // empty
  }

  public getEnvironmentType(): EnvironmentTypeEnum {
    return this.environmentType;
  }

  public isProd(): boolean {
    return this.environmentType === EnvironmentTypeEnum.PROD;
  }

  public isStaging(): boolean {
    return this.environmentType === EnvironmentTypeEnum.STAGE;
  }

  public isTest(): boolean {
    return this.environmentType === EnvironmentTypeEnum.TEST;
  }

  public isDev(): boolean {
    return this.environmentType === EnvironmentTypeEnum.DEV;
  }

  public getLocal(): EnvironmentTypeEnum {
    return EnvironmentTypeEnum.LOCAL;
  }

  public getDev(): EnvironmentTypeEnum {
    return EnvironmentTypeEnum.DEV;
  }

  public getTest(): EnvironmentTypeEnum {
    return EnvironmentTypeEnum.TEST;
  }

  public getStaging(): EnvironmentTypeEnum {
    return EnvironmentTypeEnum.STAGE;
  }

  public getProd(): EnvironmentTypeEnum {
    return EnvironmentTypeEnum.PROD;
  }
}
