import { Action } from '@ngrx/store';

export const NAVIGATION_BACK: string = '[Navigation] Back';

// Lead
export class NavigationBackAction implements Action {
  readonly type: string = NAVIGATION_BACK;

  constructor(public payload: any = undefined) {}
}

export type Actions = NavigationBackAction;
