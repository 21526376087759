<div class="lead-main-wrapper">
  <helion-ui-drawer
    drawerClass="{{ debugInfo.debugClass }} lead-main-drawer"
    [drawerContentComponent]="drawerContentComponent">
    <div
      role="header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      fxLayoutGap.lt-sm="0"
      class="width-100">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
        <span fxHide.lt-sm>THEIA {{ 'COMMON.PC.LONG' | translate }}</span>
        <span fxHide fxShow.lt-sm>THEIA {{ 'COMMON.PC.SHORT' | translate }}</span>
        <helion-ui-tag-header
          class="debug-chip hide-mobile"
          *ngIf="debugInfo.isDisplayed"
          [tagHeaderText]="debugInfo.debugText">
        </helion-ui-tag-header>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px" fxLayoutGap.lt-sm="10px">
        <div class="user-email-row" fxLayout="row" fxLayoutAlign="left center">
          <p class="user-email">Meier Tobler</p>
        </div>

        <div class="user-email-row hide-mobile" fxLayout="row" fxLayoutAlign="left center">
          <mat-icon>person</mat-icon>
          <p class="user-email">{{ email }}</p>
        </div>
      </div>
    </div>
  </helion-ui-drawer>
  <div class="lead-main-content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
