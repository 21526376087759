import { Injectable } from '@angular/core';
import { SignalRService } from './signalr-notification.service';
import { Observable } from 'rxjs';
import { switchMap, filter, delay } from 'rxjs/operators';
import { LeadCompositeViewModel, NoteViewModel, NotificationMessages } from '../shared/apis/advis';
import { SnackbarService } from '@sitewerk/theia-ui-lib';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LeadNotificationService {
  constructor(
    private signalRService: SignalRService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  public onLeadUpdated(): Observable<LeadCompositeViewModel> {
    return this.signalRService.getSubscription(NotificationMessages.LeadUpdated).pipe(
      switchMap(s => s.pipe(filter(l => !!l))),
      delay(5000)
    );
  }

  public onLeadNotesUpdated(): Observable<{ LeadId: number; Notes: NoteViewModel[] }> {
    return this.signalRService
      .getSubscription<{ LeadId: number; Notes: NoteViewModel[] }>(
        NotificationMessages.LeadNotesUpdated
      )
      .pipe(
        switchMap(s => s.pipe(filter(n => !!n))),
        delay(5000)
      );
  }

  public showLeadListUpdatedNotification(leadId: number): void {
    this.snackbarService.onShowSnackbar(
      {
        color: 'success',
        message: `${this.translateService.instant(
          'LEAD.NOTIFICATION.LIST_UPDATED.TEXT_1'
        )} ${leadId} ${this.translateService.instant('LEAD.NOTIFICATION.LIST_UPDATED.TEXT_2')}`,
      },
      {
        timeout: 5000,
      }
    );
  }
}
