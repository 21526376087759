import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'leadNote' })
export class LeadNotePipe implements PipeTransform {
  transform(note: string): string {
    let result = note;
    const bold = [
      'Urgent:',
      'Only one to one replacement:',
      'Like to be contacted:',
      'Heating installer informed:',
      'Heating installer informed:',
      'Heating installer:',
      'Note for heating installers:',
    ];
    bold.forEach(text => {
      result = result.replace(text, `<b>${text}</b>`);
    });
    return result;
  }
}
