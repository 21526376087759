import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

export enum AutosaveStatusEnum {
  PROGRESS,
  SUCCESS,
  FAILURE,
}

@Injectable()
export class AutosaveService {
  private autosaveStatus: Record<string, AutosaveStatusEnum> = {};
  private _autosaveStatus$: Subject<Record<string, AutosaveStatusEnum>> = new Subject<
    Record<string, AutosaveStatusEnum>
  >();

  public formattedTime: Record<string, string> = {};

  constructor(private datePipe: DatePipe) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public autosaveStatus$: Observable<Record<string, AutosaveStatusEnum>> =
    this._autosaveStatus$.asObservable();

  public autosaveFailed(id: string): void {
    if (!id) return;
    this.updateFormattedTime(id);
    this.autosaveStatus[id] = AutosaveStatusEnum.FAILURE;
    this._autosaveStatus$.next(this.autosaveStatus);
  }

  public autosaveSuccess(id: string): void {
    if (!id) return;
    this.updateFormattedTime(id);
    this.autosaveStatus[id] = AutosaveStatusEnum.SUCCESS;
    this._autosaveStatus$.next(this.autosaveStatus);
  }

  public autosaveInProgress(id: string): void {
    if (!id) return;
    this.updateFormattedTime(id);
    this.autosaveStatus[id] = AutosaveStatusEnum.PROGRESS;
    this._autosaveStatus$.next(this.autosaveStatus);
  }

  public updateFormattedTime(id: string): void {
    this.formattedTime[id] = this.datePipe.transform(new Date(), 'hh:mm a');
  }
}
