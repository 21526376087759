<quill-editor
  #quill
  [customOptions]="customOptions"
  [modules]="modules"
  [maxLength]="100000"
  [placeholder]="placeholder"
  (onEditorCreated)="setInitialValue($event)"
  (onContentChanged)="onContentChanged()"
  [sanitize]="true"
  [attr.data-cy]="cyAttr">
</quill-editor>
<p class="save-indicator" [ngSwitch]="(autosaveService.autosaveStatus$ | async)?.[id]">
  <ng-container *ngSwitchCase="AutosaveStatusEnum.PROGRESS">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-circle">
      <circle cx="12" cy="12" r="10" stroke-dasharray="63" stroke-dashoffset="21">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 12 12"
          to="360 12 12"
          dur="0.5s"
          repeatCount="indefinite" />
        <animate
          attributeName="stroke-dashoffset"
          dur="8s"
          repeatCount="indefinite"
          keyTimes="0; 0.5; 1"
          values="-16; -47; -16"
          calcMode="spline"
          keySplines="0.4 0 0.2 1; 0.4 0 0.2 1" />
      </circle>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="AutosaveStatusEnum.SUCCESS">
    <helion-ui-icon [size]="16" class="text-green" data-feather="check-circle"></helion-ui-icon>
  </ng-container>
  <ng-container *ngSwitchCase="AutosaveStatusEnum.FAILURE">
    <helion-ui-icon [size]="16" class="text-warning" data-feather="alert-circle"></helion-ui-icon>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span class="text-transparent">Empty</span>
  </ng-container>
</p>
