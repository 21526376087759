import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as VariantAction from './variant.action';
import {
  AddGroupPositionsAction,
  AddRegularPositionsAction,
  AddRegularPositionsFailedAction,
  AddSpecialPositionsAction,
  AddSpecialPositionsFailedAction,
  CreateVariantFailedAction,
  DeleteBomItemsAction,
  GetVariantFailedAction,
  IAddGroupPayload,
  IAddRegularPositionsPayload,
  IAddSpecialPositionsPayload,
  ICreatePriceTableVariantActionData,
  ICreateVariantActionData,
  IDeleteBomItemsPayload,
  IGenericVariantActionPayload,
  IPatchPositionGroupPayload,
  IPatchRegularOrSpecialPositionPayload,
  IPutPositionPayload,
  ISaveVariantActionPayload,
  PatchDynamicPropertyFailedAction,
  PatchPositionGroupAction,
  PatchPositionGroupFailedAction,
  PatchPvSpecificVariantFailedAction,
  PatchRegularOrSpecialPositionAction,
  PatchRegularOrSpecialPositionFailedAction,
  PatchVariantAction,
  PatchVariantFailedAction,
  PatchHtSpecificVariantFailedAction,
  PutPositionOrderAndGroupAction,
  PutPositionOrderAndGroupFailedAction,
  RebuildVariantFailedAction,
  RemoveVariantRebateFailedAction,
  SaveVariantFailedAction,
  UpdatePricesFailedAction,
  PatchEmSpecificVariantAction,
  IPatchEmSpecificVariantPayload,
  PatchEmSpecificVariantFailedAction,
  DeleteDynamicPropertyFailedAction,
  IDeleteDynamicPropertyPayload,
  IConvertSolarPlannerDynamicPropertyActionPayload,
  ISaveSolarPlannerAction,
  SaveSolarPlannerAction,
  ConvertSolarPlannerDynamicPropertyAction,
  PasteGroupsAndPositionsAction,
  PatchStSpecificVariantFailedAction,
  PatchStSpecificVariantAction,
  IPatchStSpecificVariantPayload,
  UploadVariantSignatureFailedAction,
  isPatchDynamicProperty,
  isPvPatchPvVariant,
  PatchTradeSpecificVariantAndDynamicPropertyAction,
  PatchPvNumberOfModulesVariantAction,
  ISelectPositionGroupPayload,
  ReplaceSuccessorPositionAction,
  IReplaceSuccessorPositionPayload,
  ReplaceAllSuccessorPositionsAction,
  IReplaceAllSuccessorPositionsPayload,
  PatchRegularOrSpecialPositionsAction,
  IPatchVariantRegularOrSpecialPositionPayload,
} from './variant.action';
import {
  MeierToblerService,
  PastePositionsAndGroupsRequest,
  PvWizardViewModel,
  VariantCompositeViewModel,
  VariantPrecalculationViewModel,
  VariantService,
} from '../../apis/advis';
import { Logger, LoggerService } from '../../services/logger.service';
import { ErrorAddAction } from '../global/global.action';
import { ErrorTypeE } from '../global/global.reducer';
import { TradeTypeEnum, TradeTypeUtils } from '../../utils/trade-type.util';
import { OptionsUtil } from '../../utils/options.util';
import cloneDeep from 'lodash/cloneDeep';
import { catchError, concatMap, flatMap, map, switchMap } from 'rxjs/operators';
import { NotificationService, TypeE } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { IPasteGroupsAndPositionsPayload } from './variant.action';
import { GoogleSpreadsheetService } from '../../services/google-spreadsheet.service';
import { IPatchTradeSpecificVariantAndDynamicPropertyActionPayload } from './variant.action';
import { IAccountAssignmentData } from '../../../common-components/account-assignment-list/account-assignment-list.component';

@Injectable()
export class VariantEffects {
  private logger: Logger;

  createPriceTableVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.CREATE_PRICE_TABLE_VARIANT),
      map((action: VariantAction.CreatePriceTableVariantAction) => action.payload),
      switchMap((payload: ICreatePriceTableVariantActionData) => {
        return this.variantService
          .pvVariantPostPvVariant(payload.leadId, {
            Title: payload.title,
            TemplateId: payload.templateId,
          })
          .pipe(
            flatMap((variantComposite1: VariantCompositeViewModel) => {
              return this.variantService
                .genericVariantPatchVariant(
                  TradeTypeUtils.toTradeTypeCode(TradeTypeEnum.PV),
                  variantComposite1.GenericVariantData.Id,
                  { RebuildDisabled: false }
                )
                .pipe(
                  flatMap((variantComposite2: VariantCompositeViewModel) => {
                    variantComposite2.PvVariantData.WizardData = OptionsUtil.initializeOptions(
                      variantComposite2.PvVariantData.WizardData
                    );
                    variantComposite2.PvVariantData.NumberOfModules = payload.moduleCount;
                    return this.variantService
                      .pvVariantPutPvVariantObjectData(
                        variantComposite2.GenericVariantData.Id,
                        variantComposite2.PvVariantData.WizardData
                      )
                      .pipe(
                        map((variantComposite3: VariantCompositeViewModel) => {
                          return new VariantAction.CreatePriceTableVariantSuccessAction({
                            variantComposite: variantComposite3,
                          });
                        })
                      );
                  })
                );
            }),
            catchError((error: any) =>
              of(new VariantAction.CreatePriceTableVariantFailedAction(error))
            )
          );
      })
    )
  );

  createVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.CREATE_VARIANT),
      map((action: VariantAction.CreateVariantAction) => action.payload),
      switchMap((payload: ICreateVariantActionData) => {
        switch (TradeTypeUtils.toTradeTypeEnum(payload.tradeType)) {
          case TradeTypeEnum.PV:
            return this.variantService
              .pvVariantPostPvVariant(payload.leadId, payload.createPvVariantRequest)
              .pipe(
                map((variantComposite: VariantCompositeViewModel) => {
                  this.logger.debug('After photovoltaicsVariantPostPvVariant');
                  return new VariantAction.CreateVariantSuccessAction({
                    variantComposite: variantComposite,
                  });
                }),
                catchError((error: any) => of(new VariantAction.CreateVariantFailedAction(error)))
              );

          case TradeTypeEnum.HT:
            return this.variantService
              .heatingVariantPostHeatingVariant(payload.leadId, payload.createHeatingVariantRequest)
              .pipe(
                map((variantComposite: VariantCompositeViewModel) => {
                  this.logger.debug('After pCApiVariantPostHeatingVariant');
                  return new VariantAction.CreateVariantSuccessAction({
                    variantComposite: variantComposite,
                  });
                }),
                catchError((error: any) => of(new VariantAction.CreateVariantFailedAction(error)))
              );

          case TradeTypeEnum.EM:
            return this.variantService
              .emobilityVariantPostEmobilityVariant(payload.leadId, payload.createEmVariantRequest)
              .pipe(
                map((variantComposite: VariantCompositeViewModel) => {
                  this.logger.debug('After pCApiVariantPostEmobilityVariant');
                  return new VariantAction.CreateVariantSuccessAction({
                    variantComposite: variantComposite,
                  });
                }),
                catchError((error: any) => of(new VariantAction.CreateVariantFailedAction(error)))
              );

          case TradeTypeEnum.NA:
          case TradeTypeEnum.PVX:
          case TradeTypeEnum.SEC:
          case TradeTypeEnum.S:
          case TradeTypeEnum.AC:
          case TradeTypeEnum.DHW:
          case TradeTypeEnum.BAT:
          case TradeTypeEnum.WS:
          case TradeTypeEnum.ENG:
            return this.variantService
              .simpleTradeVariantPostSimpleTradeVariant(
                payload.leadId,
                payload.tradeType,
                payload.createSimpleTradeVariantRequest
              )
              .pipe(
                map((variantComposite: VariantCompositeViewModel) => {
                  this.logger.debug('After simpleTradeVariantPostSimpleTradeVariant');
                  return new VariantAction.CreateVariantSuccessAction({
                    variantComposite: variantComposite,
                  });
                }),
                catchError((error: any) => of(new VariantAction.CreateVariantFailedAction(error)))
              );

          default:
            return of(
              new VariantAction.CreateVariantFailedAction(
                `Invalid trade type="${payload.tradeType}"`
              )
            );
        }
      })
    )
  );

  saveVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.SAVE_VARIANT),
      map((action: VariantAction.SaveVariantAction) => action.payload),
      switchMap((payload: ISaveVariantActionPayload) => {
        switch (TradeTypeUtils.toTradeTypeEnum(payload.tradeTypeCode)) {
          case TradeTypeEnum.PV:
            return this.variantService
              .genericVariantPatchVariant(
                payload.tradeTypeCode,
                payload.variantComposite.GenericVariantData.Id,
                { Title: payload.variantComposite.GenericVariantData.Title }
              )
              .pipe(
                flatMap(() => {
                  return this.variantService
                    .pvVariantPutPvVariantObjectData(
                      payload.variantComposite.GenericVariantData.Id,
                      payload.variantComposite.PvVariantData.WizardData
                    )
                    .pipe(
                      map((variantComposite2: VariantCompositeViewModel) => {
                        return new VariantAction.SaveVariantSuccessAction({
                          variantComposite: variantComposite2,
                        });
                      })
                    );
                }),
                catchError((error: any) => of(new VariantAction.SaveVariantFailedAction(error)))
              );

          case TradeTypeEnum.HT:
            return this.variantService
              .genericVariantPatchVariant(
                payload.tradeTypeCode,
                payload.variantComposite.GenericVariantData.Id,
                {
                  Title: payload.variantComposite.GenericVariantData.Title,
                }
              )
              .pipe(
                flatMap(() => {
                  return this.variantService
                    .heatingVariantPutHeatingVariantObjectData(
                      payload.variantComposite.GenericVariantData.Id,
                      payload.variantComposite.HeatingVariantData.WizardData
                    )
                    .pipe(
                      map((variantComposite2: VariantCompositeViewModel) => {
                        return new VariantAction.SaveVariantSuccessAction({
                          variantComposite: variantComposite2,
                        });
                      })
                    );
                }),
                catchError((error: any) => of(new VariantAction.SaveVariantFailedAction(error)))
              );

          case TradeTypeEnum.EM:
            return this.variantService
              .genericVariantPatchVariant(
                payload.tradeTypeCode,
                payload.variantComposite.GenericVariantData.Id,
                {
                  Title: payload.variantComposite.GenericVariantData.Title,
                }
              )
              .pipe(
                flatMap(() => {
                  return this.variantService
                    .emobilityVariantPutEmobilityVariantObjectData(
                      payload.variantComposite.GenericVariantData.Id,
                      payload.variantComposite.EmobilityVariantData.WizardData
                    )
                    .pipe(
                      map((variantComposite2: VariantCompositeViewModel) => {
                        return new VariantAction.SaveVariantSuccessAction({
                          variantComposite: variantComposite2,
                        });
                      })
                    );
                }),
                catchError((error: any) => of(new VariantAction.SaveVariantFailedAction(error)))
              );

          default:
            return of(
              new VariantAction.SaveVariantFailedAction(
                `Invalid trade type="${payload.tradeTypeCode}"`
              )
            );
        }
      })
    )
  );

  updateObjectData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.UPDATE_OBJECT_DATA),
      map((action: VariantAction.UpdateObjectDataAction) => action.payload),
      switchMap((payload: VariantCompositeViewModel) => {
        return this.variantService
          .pvVariantPutPvVariantObjectData(
            payload.GenericVariantData.Id,
            payload.PvVariantData.WizardData
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              this.logger.debug('After pCApiVariantPutPvVariantObjectData');
              return new VariantAction.UpdateObjectDataSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.UpdateObjectDataFailedAction(error)))
          );
      })
    )
  );

  getVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.GET_VARIANT),
      map((action: VariantAction.GetVariantAction) => action.payload),
      switchMap((data: IGenericVariantActionPayload) => {
        return this.variantService
          .genericVariantGetVariantById(data.tradeType, data.variantId)
          .pipe(
            map((variantComposite: VariantCompositeViewModel) => {
              return new VariantAction.GetVariantSuccessAction(variantComposite);
            }),
            catchError((error: any) => of(new VariantAction.GetVariantFailedAction(error)))
          );
      })
    )
  );

  addRegularPosition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.ADD_REGULAR_POSITIONS),
      map((action: AddRegularPositionsAction) => action.payload),
      switchMap((payload: IAddRegularPositionsPayload) => {
        return this.variantService
          .genericVariantPostRegularPositions(
            payload.tradeTypeCode,
            payload.variantId,
            payload.addPositionRequests
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.AddRegularPositionsSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.AddRegularPositionsFailedAction(error)))
          );
      })
    )
  );

  addSpecialPosition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.ADD_SPECIAL_POSITIONS),
      map((action: AddSpecialPositionsAction) => action.payload),
      switchMap((payload: IAddSpecialPositionsPayload) => {
        return this.variantService
          .genericVariantPostSpecialPositions(
            payload.tradeTypeCode,
            payload.variantId,
            payload.addPositionRequests
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.AddSpecialPositionsSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.AddSpecialPositionsFailedAction(error)))
          );
      })
    )
  );

  addGroupPosition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.ADD_GROUP_POSITIONS),
      map((action: AddGroupPositionsAction) => action.payload),
      switchMap((payload: IAddGroupPayload) => {
        return this.variantService
          .genericVariantPostPositionGroup(
            payload.tradeTypeCode,
            payload.variantId,
            payload.addGroupsRequests
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.AddGroupPositionsSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.AddGroupPositionsFailedAction(error)))
          );
      })
    )
  );

  addGroupsAndPositions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PASTE_GROUPS_AND_POSITIONS),
      map((action: PasteGroupsAndPositionsAction) => action.payload),
      switchMap((payload: IPasteGroupsAndPositionsPayload) => {
        const request: PastePositionsAndGroupsRequest = {
          SourceVariantId: payload.sourceVariantId,
          TargetGroupId: payload.targetGroupId,
          OrderFrom: payload.orderFrom,
          GroupIds: payload.targetGroupId ? [] : payload.groupIds,
          RegularPositionIds: payload.regularPositionIds,
          SpecialPositionIds: payload.specialPositionIds,
          PositionsType: payload.positionsType,
        };

        return this.variantService
          .genericVariantPostPositionsAndGroups(
            payload.tradeTypeCode,
            payload.targetVariantId,
            request
          )
          .pipe(
            map(
              (compositeVariant: VariantCompositeViewModel) =>
                new VariantAction.PasteGroupsAndPositionsSuccessAction(compositeVariant)
            ),
            catchError((error: any) =>
              of(new VariantAction.PasteGroupsAndPositionsFailedAction(error))
            )
          );
      })
    )
  );

  replaceSuccessorPosition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.REPLACE_SUCCESSOR_POSITION),
      map((action: ReplaceSuccessorPositionAction) => action.payload),
      switchMap((data: IReplaceSuccessorPositionPayload) => {
        return this.variantService
          .genericVariantPatchPositionReplaceWithSuccessor(
            data.tradeTypeCode,
            data.variantId,
            data.positionId
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.ReplaceSuccessorPositionSuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.ReplaceSuccessorPositionFailedAction(error))
            )
          );
      })
    )
  );

  replaceAllSuccessorPositions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.REPLACE_ALL_SUCCESSOR_POSITIONS),
      map((action: ReplaceAllSuccessorPositionsAction) => action.payload),
      switchMap((data: IReplaceAllSuccessorPositionsPayload) => {
        return this.variantService
          .genericVariantPatchPositionsReplaceWithSuccessor(data.tradeTypeCode, data.variantId)
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.ReplaceAllSuccessorPositionsSuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.ReplaceAllSuccessorPositionsFailedAction(error))
            )
          );
      })
    )
  );

  deletePositionsOrGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.DELETE_BOM_ITEMS),
      map((action: DeleteBomItemsAction) => action.payload),
      switchMap((data: IDeleteBomItemsPayload) => {
        return this.variantService
          .genericVariantDeleteBomItems(data.tradeTypeCode, data.variantId, data.itemIds.join(','))
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.DeleteBomItemsSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.DeleteBomItemsFailedAction(error)))
          );
      })
    )
  );

  rebuildVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.REBUILD_VARIANT),
      switchMap((action: any) => {
        return this.variantService
          .genericVariantPostForceRebuild(action.payload.tradeTypeCode, action.payload.variantId)
          .pipe(
            flatMap(() => {
              return this.variantService
                .genericVariantPatchVariant(
                  action.payload.tradeTypeCode,
                  action.payload.variantId,
                  { RebuildDisabled: false }
                )
                .pipe(
                  map((variantComposite2: VariantCompositeViewModel) => {
                    return new VariantAction.RebuildVariantSuccessAction(variantComposite2);
                  })
                );
            }),
            catchError((error: any) => of(new VariantAction.RebuildVariantFailedAction(error)))
          );
      })
    )
  );

  rebuildVariantMeierTobler$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.REBUILD_VARIANT_MEIER_TOBLER),
      switchMap((action: any) => {
        return this.meierToblerService
          .meierToblerCrmPostForceRebuild(action.payload.variantId)
          .pipe(
            flatMap(() => {
              return this.variantService
                .genericVariantPatchVariant('HT', action.payload.variantId, {
                  RebuildDisabled: false,
                })
                .pipe(
                  map((variantComposite2: VariantCompositeViewModel) => {
                    return new VariantAction.RebuildVariantSuccessAction(variantComposite2);
                  })
                );
            }),
            catchError((error: any) => of(new VariantAction.RebuildVariantFailedAction(error)))
          );
      })
    )
  );

  addRebates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.ADD_VARIANT_REBATE),
      switchMap((data: any) => {
        return this.variantService
          .genericVariantPostVariantRebate(
            data.payload.tradeTypeCode,
            data.payload.variantId,
            data.payload.addRebateRequest
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              this.logger.debug('After pCApiVariantPutVariantRebate');
              return new VariantAction.AddVariantRebateSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.AddVariantRebateFailedAction(error)))
          );
      })
    )
  );

  updateRebates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.UPDATE_VARIANT_REBATE),
      switchMap((data: any) => {
        return this.variantService
          .genericVariantPutVariantRebate(
            data.payload.tradeTypeCode,
            data.payload.variantId,
            data.payload.rebateId,
            data.payload.addRebateRequest
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.UpdateVariantRebateSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.UpdateVariantRebateFailedAction(error)))
          );
      })
    )
  );

  removeRebate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.REMOVE_VARIANT_REBATE),
      switchMap((data: any) => {
        return this.variantService
          .genericVariantDeleteVariantRebate(
            data.payload.tradeTypeCode,
            data.payload.variantId,
            data.payload.rebateId
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              this.logger.debug('After pCApiVariantDeleteVariantRebate');
              return new VariantAction.RemoveVariantRebateSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.RemoveVariantRebateFailedAction(error)))
          );
      })
    )
  );

  updatePrices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.UPDATE_PRICES),
      switchMap((data: any) => {
        return this.variantService
          .genericVariantPostUpdatePrices(data.payload.tradeTypeCode, data.payload.variantId)
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              this.logger.debug('After pCApiVariantPostUpdatePrices');
              return new VariantAction.UpdatePricesSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.UpdatePricesFailedAction(error)))
          );
      })
    )
  );

  updateVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_VARIANT),
      switchMap((action: PatchVariantAction) => {
        return this.variantService
          .genericVariantPatchVariant(
            action.payload.tradeTypeCode,
            action.payload.variantId,
            action.payload.patch
          )
          .pipe(
            map(
              (variant: VariantCompositeViewModel) =>
                new VariantAction.PatchVariantSuccessAction(variant)
            ),
            catchError((error: any) => of(new PatchVariantFailedAction(error)))
          );
      })
    )
  );

  updatePvNumberOdModulesVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_PV_NUMBER_OF_MODULES_VARIANT),
      switchMap((action: PatchPvNumberOfModulesVariantAction) => {
        return this.variantService
          .pvVariantPutPvNumberOfModulesAndRecalculation(
            action.payload.variantId,
            action.payload.numberOfModules
          )
          .pipe(
            map(
              (variant: VariantCompositeViewModel) =>
                new VariantAction.PatchVariantSuccessAction(variant)
            ),
            catchError((error: any) => of(new PatchVariantFailedAction(error)))
          );
      })
    )
  );

  updatePvModuleKwpVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_PV_MODULE_WATT_PEAK_VARIANT),
      switchMap((action: VariantAction.PatchPvModuleWattPeakVariantAction) => {
        return this.variantService
          .pvVariantPutPvModuleKwpAndRecalculation(
            action.payload.variantId,
            action.payload.moduleWattPeak
          )
          .pipe(
            map(
              (variant: VariantCompositeViewModel) =>
                new VariantAction.PatchVariantSuccessAction(variant)
            ),
            catchError((error: any) => of(new PatchVariantFailedAction(error)))
          );
      })
    )
  );

  patchDynamicProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VariantAction.PATCH_DYNAMIC_PROPERTY,
        VariantAction.PATCH_PV_SPECIFIC_VARIANT,
        VariantAction.PATCH_HT_SPECIFIC_VARIANT
      ),
      concatMap(
        (
          data:
            | VariantAction.PatchDynamicPropertyAction
            | VariantAction.PatchPvSpecificVariantAction
            | VariantAction.PatchHtSpecificVariantAction
        ) => {
          if (isPatchDynamicProperty(data)) {
            return this.variantService
              .genericVariantPatchVariantDynamicProperty(
                data.payload.tradeTypeCode,
                data.payload.variantId,
                data.payload.patchPropertyRequest
              )
              .pipe(
                map((compositeVariant: VariantCompositeViewModel) => {
                  this.logger.debug('After genericVariantPatchVariantDynamicProperty');
                  return new VariantAction.PatchDynamicPropertySuccessAction(compositeVariant);
                }),
                catchError((error: any) =>
                  of(new VariantAction.PatchDynamicPropertyFailedAction(error))
                )
              );
          }

          if (isPvPatchPvVariant(data)) {
            return this.variantService
              .pvVariantPatchPvVariantTradeSpecific(data.payload.variantId, data.payload.patch)
              .pipe(
                map(
                  (variant: VariantCompositeViewModel) =>
                    new VariantAction.PatchPvSpecificVariantSuccessAction(variant)
                ),
                catchError((error: any) => of(new PatchPvSpecificVariantFailedAction(error)))
              );
          }

          return this.variantService
            .heatingVariantPatchHeatingVariantTradeSpecific(
              data.payload.variantId,
              data.payload.patch
            )
            .pipe(
              map(
                (variant: VariantCompositeViewModel) =>
                  new VariantAction.PatchHtSpecificVariantSuccessAction(variant)
              ),
              catchError((error: any) => of(new PatchHtSpecificVariantFailedAction(error)))
            );
        }
      )
    )
  );

  updateEmSpecificVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_EM_SPECIFIC_VARIANT),
      map((action: PatchEmSpecificVariantAction) => action.payload),
      switchMap((payload: IPatchEmSpecificVariantPayload) => {
        return this.variantService
          .emobilityVariantPatchEmobilityVariantTradeSpecific(payload.variantId, payload.patch)
          .pipe(
            map(
              (variant: VariantCompositeViewModel) =>
                new VariantAction.PatchEmSpecificVariantSuccessAction(variant)
            ),
            catchError((error: any) => of(new PatchEmSpecificVariantFailedAction(error)))
          );
      })
    )
  );

  updateStSpecificVariant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_ST_SPECIFIC_VARIANT),
      map((action: PatchStSpecificVariantAction) => action.payload),
      switchMap((payload: IPatchStSpecificVariantPayload) => {
        return this.variantService
          .simpleTradeVariantPatchSimpleTradeVariantTradeSpecific(
            payload.variantId,
            payload.tradeTypeCode,
            payload.patch
          )
          .pipe(
            map(
              (variant: VariantCompositeViewModel) =>
                new VariantAction.PatchHtSpecificVariantSuccessAction(variant)
            ),
            catchError((error: any) => of(new PatchStSpecificVariantFailedAction(error)))
          );
      })
    )
  );

  updateTradeSpecificVariantAndDynamicPropertyActiont$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_TRATE_SPECIFIC_VARIANT_AND_DYNAMIC_PROPERTY),
      map((action: PatchTradeSpecificVariantAndDynamicPropertyAction) => action.payload),
      switchMap((payload: IPatchTradeSpecificVariantAndDynamicPropertyActionPayload) => {
        return this.variantService
          .genericVariantPatchVariantDynamicProperty(
            TradeTypeUtils.toTradeTypeCode(payload.tradeType),
            payload.dynamicProperty.variantId,
            payload.dynamicProperty.patchPropertyRequest
          )
          .pipe(
            map(() => {
              this.logger.debug('After genericVariantPatchVariantDynamicProperty');
              switch (payload.tradeType) {
                case TradeTypeEnum.HT:
                  return new VariantAction.PatchHtSpecificVariantAction(payload.stVariant);
                case TradeTypeEnum.PV:
                  return new VariantAction.PatchPvSpecificVariantAction(payload.stVariant);
                case TradeTypeEnum.EM:
                  return new VariantAction.PatchEmSpecificVariantAction(payload.stVariant);
                default:
                  return new PatchStSpecificVariantAction(payload.stVariant);
              }
            }),
            catchError((error: any) =>
              of(new VariantAction.PatchDynamicPropertyFailedAction(error))
            )
          );
      })
    )
  );

  putPositionOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PUT_POSITION_ORDER_AND_GROUP),
      map((action: PutPositionOrderAndGroupAction) => action.payload),
      switchMap((payload: IPutPositionPayload) => {
        return this.variantService
          .genericVariantUpdatePositionsOrder(
            payload.tradeTypeCode,
            payload.variantId,
            payload.putPositionRequest
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.PutPositionOrderAndGroupSuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.PutPositionOrderAndGroupFailedAction(error))
            )
          );
      })
    )
  );

  patchPositionGroupOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_POSITION_GROUP),
      map((action: PatchPositionGroupAction) => action.payload),
      switchMap((payload: IPatchPositionGroupPayload) => {
        return this.variantService
          .genericVariantPatchPositionGroup(
            payload.tradeTypeCode,
            payload.variantId,
            payload.groupId,
            payload.patchPositionGroupRequest
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.PatchPositionGroupSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.PatchPositionGroupFailedAction(error)))
          );
      })
    )
  );

  deselectPositionGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.DESELECT_POSITION_GROUP),
      map((action: PatchPositionGroupAction) => action.payload),
      switchMap((payload: ISelectPositionGroupPayload) => {
        return this.variantService
          .genericVariantDeselectAllPositionInThisGroup(
            payload.tradeTypeCode,
            payload.variantId,
            payload.groupId
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.DeselectPositionGroupSuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.DeselectPositionGroupFailedAction(error))
            )
          );
      })
    )
  );

  selectPositionGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.SELECT_POSITION_GROUP),
      map((action: PatchPositionGroupAction) => action.payload),
      switchMap((payload: ISelectPositionGroupPayload) => {
        return this.variantService
          .genericVariantSelectAllPositionInThisGroup(
            payload.tradeTypeCode,
            payload.variantId,
            payload.groupId
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.SelectPositionGroupSuccessAction(compositeVariant);
            }),
            catchError((error: any) => of(new VariantAction.SelectPositionGroupFailedAction(error)))
          );
      })
    )
  );

  patchPosition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_REGULAR_OR_SPECIAL_POSITION),
      map((action: PatchRegularOrSpecialPositionAction) => action.payload),
      concatMap((payload: IPatchRegularOrSpecialPositionPayload) => {
        return this.variantService
          .genericVariantPatchPositions(payload.tradeTypeCode, payload.variantId, payload.positions)
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              return new VariantAction.PatchRegularOrSpecialPositionSuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.PatchRegularOrSpecialPositionFailedAction(error))
            )
          );
      })
    )
  );

  patchVariantPositions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.PATCH_REGULAR_OR_SPECIAL_POSITIONS),
      map((action: PatchRegularOrSpecialPositionsAction) => action.payload),
      concatMap((payload: IPatchVariantRegularOrSpecialPositionPayload) => {
        return this.variantService
          .genericVariantPatchVariantPositions(payload.tradeTypeCode, payload.positions)
          .pipe(
            map(() => {
              return new VariantAction.PatchRegularOrSpecialVariantPositionSuccessAction();
            }),
            catchError((error: any) =>
              of(new VariantAction.PatchRegularOrSpecialPositionFailedAction(error))
            )
          );
      })
    )
  );

  deleteDynamicProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.DELETE_DYNAMIC_PROPERTY),
      map((data: VariantAction.DeleteDynamicPropertyAction) => data.payload),
      switchMap((payload: IDeleteDynamicPropertyPayload) => {
        return this.variantService
          .genericVariantDeleteVariantDynamicProperty(
            payload.tradeTypeCode,
            payload.variantId,
            payload.propertyName
          )
          .pipe(
            map((compositeVariant: VariantCompositeViewModel) => {
              this.logger.debug('After genericVariantPatchVariantDynamicProperty');
              return new VariantAction.DeleteDynamicPropertySuccessAction(compositeVariant);
            }),
            catchError((error: any) =>
              of(new VariantAction.DeleteDynamicPropertyFailedAction(error))
            )
          );
      })
    )
  );

  convertSolarPlannerDynamicProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY),
      map((data: VariantAction.ConvertSolarPlannerDynamicPropertyAction) => data.payload),
      switchMap((payload: IConvertSolarPlannerDynamicPropertyActionPayload) => {
        const file: File = new File([payload.projectStoreJsonString], 'solarPlannerData.txt');
        return this.variantService.genericVariantFileUploadFile(payload.variantId, file).pipe(
          flatMap(() => {
            const wizardData: PvWizardViewModel = cloneDeep(payload.wizardData);
            wizardData.SolarPlannerDataFilename = 'solarPlannerData.txt';
            return this.variantService
              .pvVariantPutPvVariantObjectData(payload.variantId, wizardData)
              .pipe(
                flatMap(() => {
                  this.logger.debug('After genericVariantPatchVariantDynamicProperty');
                  return this.variantService
                    .genericVariantDeleteVariantDynamicProperty(
                      'PV',
                      payload.variantId,
                      'solarPlannerData'
                    )
                    .pipe(
                      map((compositeVariant1: VariantCompositeViewModel) => {
                        this.logger.debug('After genericVariantPatchVariantDynamicProperty');
                        return new VariantAction.ConvertSolarPlannerDynamicPropertySuccessAction(
                          compositeVariant1
                        );
                      }),
                      catchError((error: any) =>
                        of(new VariantAction.ConvertSolarPlannerDynamicPropertyFailedAction(error))
                      )
                    );
                }),
                catchError((error: any) =>
                  of(new VariantAction.ConvertSolarPlannerDynamicPropertyFailedAction(error))
                )
              );
          })
        );
      })
    )
  );

  saveSolarPlannerData: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.SAVE_SOLAR_PLANNER_DATA),
      map((data: VariantAction.SaveSolarPlannerAction) => data.payload),
      switchMap((payload: ISaveSolarPlannerAction) => {
        const file: File = new File([payload.projectStoreJsonString], 'solarPlannerData.txt');
        return this.variantService.genericVariantFileUploadFile(payload.variantId, file).pipe(
          flatMap(() => {
            const file1: File = new File([payload.screenshot], 'screenshot.jpg');
            return this.variantService.genericVariantFileUploadFile(payload.variantId, file1).pipe(
              flatMap(() => {
                const wizardData: PvWizardViewModel = cloneDeep(payload.wizardData);
                wizardData.LayoutAugmentedImage = 'screenshot.jpg';
                wizardData.SolarPlannerDataFilename = 'solarPlannerData.txt';
                return this.variantService
                  .pvVariantPutPvVariantObjectData(payload.variantId, wizardData)
                  .pipe(
                    map((compositeVariant2: VariantCompositeViewModel) => {
                      return new VariantAction.SaveSolarPlannerSuccessAction(compositeVariant2);
                    }),
                    catchError((error: any) =>
                      of(new VariantAction.SaveSolarPlannerFailedAction(error))
                    )
                  );
              }),
              catchError((error: any) => {
                console.log(error);
                return of(new VariantAction.SaveSolarPlannerFailedAction(error));
              })
            );
          })
        );
      })
    )
  );

  deleteSolarPlannerScreenshotOnVariant: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.DELETE_SOLAR_PLANNER_SCREENSHOT),
      map((data: VariantAction.DeleteSolarPlannerScreenshotOnVariantFileAction) => data.payload),
      switchMap((payload: ISaveSolarPlannerAction) => {
        return this.variantService
          .genericVariantFileDeleteFile(payload.variantId, payload.wizardData.LayoutAugmentedImage)
          .pipe(
            flatMap(() => {
              const wizardData: PvWizardViewModel = cloneDeep(payload.wizardData);
              wizardData.LayoutAugmentedImage = '';
              return this.variantService
                .pvVariantPutPvVariantObjectData(payload.variantId, wizardData)
                .pipe(
                  map((compositeVariant2: VariantCompositeViewModel) => {
                    this.notificationService.notifySimple(
                      this.translate.instant(
                        'LEAD_VARIANT.SOLAR_PLANNER.DELETE_SCREENSHOT_ON_VARIANT_SUCCESS'
                      ),
                      TypeE.PRIMARY
                    );
                    return new VariantAction.DeleteSolarPlannerScreenshotOnVariantFileSuccessAction(
                      compositeVariant2
                    );
                  }),
                  catchError((error: any) => {
                    this.notificationService.notifySimple(
                      this.translate.instant(
                        'LEAD_VARIANT.SOLAR_PLANNER.DELETE_SCREENSHOT_ON_VARIANT_FAILED'
                      ),
                      TypeE.ALERT
                    );
                    return of(
                      new VariantAction.DeleteSolarPlannerScreenshotOnVariantFileFailedAction(error)
                    );
                  })
                );
            }),
            catchError((error: any) => {
              this.notificationService.notifySimple(
                this.translate.instant(
                  'LEAD_VARIANT.SOLAR_PLANNER.DELETE_SCREENSHOT_ON_VARIANT_FAILED'
                ),
                TypeE.ALERT
              );
              return of(
                new VariantAction.DeleteSolarPlannerScreenshotOnVariantFileFailedAction(error)
              );
            })
          );
      })
    )
  );

  getVariantPrecalucaltion$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.GET_VARIANT_PRECALCULATION),
      map((action: VariantAction.GetVariantAction) => action.payload),
      switchMap((data: IGenericVariantActionPayload) => {
        return this.variantService
          .genericVariantGetVariantPrecalculation(data.tradeType, data.variantId)
          .pipe(
            map((precalculationViewModel: VariantPrecalculationViewModel) => {
              return new VariantAction.GetVariantPrecalculationSuccessAction(
                precalculationViewModel
              );
            }),
            catchError((error: any) =>
              of(new VariantAction.GetVariantPrecalculationFailedAction(error))
            )
          );
      })
    )
  );

  uploadVariantSignature$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.UPLOAD_VARIANT_SIGNATURE),
      switchMap((action: VariantAction.UploadVariantSignatureAction) => {
        return this.variantService
          .genericVariantFileUploadVariantCustomerSignature(
            action.payload.variantId,
            action.payload.file
          )
          .pipe(
            map((signatureLink: string) => {
              return new VariantAction.UploadVariantSignatureSuccessAction(signatureLink);
            }),
            catchError((error: any) =>
              of(new VariantAction.UploadVariantSignatureFailedAction(error))
            )
          );
      })
    )
  );

  getVariantSignature$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.GET_VARIANT_SIGNATURE),
      switchMap((action: VariantAction.GetVariantSignatureAction) => {
        return this.variantService
          .genericVariantFileGetVariantCustomerSignature(action.payload)
          .pipe(
            map((signatureLink: string) => {
              return new VariantAction.GetVariantSignatureSuccessAction(signatureLink);
            }),
            catchError((error: any) => of(new VariantAction.GetVariantSignatureFailedAction(error)))
          );
      })
    )
  );

  getAccountAssignmentList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VariantAction.GET_ACCOUNT_ASSIGNMENT_LIST),
      switchMap((action: VariantAction.GetAccountAssignmentListAction) => {
        return this.spreadsheetService.getAccountAssignmentList(action.payload).pipe(
          map((data: string) => {
            const from: number = data.indexOf('{');
            const to: number = data.lastIndexOf('}') + 1;
            const jsonText: string = data.slice(from, to);
            const table: any = JSON.parse(jsonText).table;
            let accountAssignmentData: IAccountAssignmentData[];
            if (table) {
              const rows: any = JSON.parse(jsonText).table.rows;
              if (rows.length > 0) {
                accountAssignmentData = rows.map((row: any) => {
                  return {
                    account: row.c[0].v,
                    description: row.c[1].v,
                    Type: row.c[2].v,
                    IsActive: row.c[3].v,
                  };
                });
              }
            }
            return new VariantAction.GetAccountAssignmentListSuccessAction(accountAssignmentData);
          }),
          catchError((error: any) =>
            of(new VariantAction.GetAccountAssignmentListFailedAction(error))
          )
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VariantAction.CREATE_VARIANT_FAILED,
        VariantAction.SAVE_VARIANT_FAILED,
        VariantAction.ADD_REGULAR_POSITIONS_FAILED,
        VariantAction.ADD_VARIANT_REBATE_FAILED,
        VariantAction.REMOVE_VARIANT_REBATE_FAILED,
        VariantAction.UPDATE_PRICES_FAILED,
        VariantAction.PATCH_VARIANT_FAILED,
        VariantAction.PATCH_REGULAR_OR_SPECIAL_POSITION_FAILED,
        VariantAction.PATCH_DYNAMIC_PROPERTY_FAILED,
        VariantAction.DELETE_DYNAMIC_PROPERTY_FAILED,
        VariantAction.ADD_SPECIAL_POSITIONS_FAILED,
        VariantAction.PUT_POSITION_ORDER_AND_GROUP_FAILED,
        VariantAction.PATCH_EM_SPECIFIC_VARIANT_FAILED,
        VariantAction.SAVE_SOLAR_PLANNER_DATA_FAILED,
        VariantAction.CONVERT_SOLAR_PLANNER_DYNAMIC_PROPERTY_FAILED,
        VariantAction.UPLOAD_VARIANT_SIGNATURE_FAILED
      ),
      map(
        (
          action:
            | CreateVariantFailedAction
            | SaveVariantFailedAction
            | AddRegularPositionsFailedAction
            | RemoveVariantRebateFailedAction
            | UpdatePricesFailedAction
            | PatchVariantFailedAction
            | PatchPositionGroupFailedAction
            | PutPositionOrderAndGroupFailedAction
            | AddSpecialPositionsFailedAction
            | PatchDynamicPropertyFailedAction
            | DeleteDynamicPropertyFailedAction
            | PatchRegularOrSpecialPositionFailedAction
            | PatchEmSpecificVariantFailedAction
            | SaveSolarPlannerAction
            | UploadVariantSignatureFailedAction
            | ConvertSolarPlannerDynamicPropertyAction
        ) => {
          return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
        }
      )
    )
  );

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VariantAction.GET_VARIANT_FAILED,
        VariantAction.REBUILD_VARIANT_FAILED,
        VariantAction.GET_VARIANT_SIGNATURE_FAILED,
        VariantAction.GET_ACCOUNT_ASSIGNMENT_LIST_FAILED
      ),
      map((action: GetVariantFailedAction | RebuildVariantFailedAction) => {
        return new ErrorAddAction({ type: ErrorTypeE.LOAD, data: action.payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private variantService: VariantService,
    private meierToblerService: MeierToblerService,
    private loggerService: LoggerService,
    private translate: TranslateService,
    private spreadsheetService: GoogleSpreadsheetService,
    private notificationService: NotificationService
  ) {
    this.logger = this.loggerService.create(VariantEffects.name);
  }
}
