import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, catchError, combineLatest, filter, map, of, take, tap } from 'rxjs';
import { LoadingModalService } from '../services/loading-modal.service';
import { AccountService, CurrentUserViewModel } from '../apis/advis';
import { Store } from '@ngrx/store';
import * as RootReducer from '../states/index';
import { PermissionService } from '../services/permission.service';
import { GetUserSuccessAction } from '../states/user/user.action';
import { MasterDataLoadAction } from '../states/global/global.action';

@Injectable({
  providedIn: 'root',
})
class AppFieldServiceInitializerService {
  constructor(
    private loadingModalService: LoadingModalService,
    private accountApi: AccountService,
    private permissionService: PermissionService,
    private store: Store<RootReducer.IState>
  ) {}

  public canActivate(): Observable<boolean> {
    console.info('-- Starting field service ---');

    this.loadingModalService.openModal();

    const userInfo$: Observable<boolean> = this.accountApi.accountGetUser().pipe(
      tap(
        (userInfo: CurrentUserViewModel) =>
          (this.permissionService.userPermissions = userInfo.Permissions)
      ),
      tap(userInfo => this.store.dispatch(new GetUserSuccessAction(userInfo))),
      map((userInfo: CurrentUserViewModel) => userInfo.Permissions.CanSeeServiceOffering),
      catchError(() => of(false))
    );

    this.store.dispatch(new MasterDataLoadAction());
    const masterDataLoaded$: Observable<boolean> = this.store
      .select(RootReducer.getGlobalMasterDataLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    return combineLatest([masterDataLoaded$, userInfo$]).pipe(
      map((a: [boolean, boolean]) => a[1]),
      take(1),
      tap((a: boolean) => {
        if (a) {
          console.info('*** Application initialized ***');
          this.loadingModalService.closeModal();
        } else {
          console.info('*** user has no permissions, reload login ***');
          this.loadingModalService.closeModal();
          window.location.href =
            location.protocol +
            '//' +
            location.host +
            '//auth/login' +
            window.location.pathname +
            window.location.search;
        }
      })
    );
  }
}

export const AppFieldServiceInitializerGuard: CanActivateFn = (): Observable<boolean> =>
  inject(AppFieldServiceInitializerService).canActivate();
