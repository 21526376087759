import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthAction from '../states/auth/auth.action';
import * as Auth from '../states/auth/auth.action';
import * as RootReducer from '../states/index';
import { AuthService } from '../services/auth.service';
import { IPrincipal } from '../interfaces/principal.interface';
import { GetUserAction } from '../states/user/user.action';
import { map, take } from 'rxjs/operators';
import { routesNames } from '../../app.component';

@Injectable()
export class AuthGuard {
  constructor(private store: Store<RootReducer.IState>, private authService: AuthService) {
    // empty
  }

  canActivate(): Observable<boolean> {
    return this.store.select(RootReducer.getAuthLoggedIn).pipe(
      take(1),
      map((loggedIn: boolean) => {
        if (loggedIn) {
          return true;
        }

        // Check if we have a session
        if (this.authService.isLoggedIn()) {
          const principalSession: IPrincipal = this.authService.getPrincipalSession();
          if (principalSession) {
            // Logged in by session - allow activation. In case the API returns a 401 or 403 we redirect to the
            // login page by the interceptor.
            //
            // CAUTION:
            // The principal is read from local storage - this could lead in out-of-date data for role, mandator, mandatorId
            this.store.dispatch(
              new Auth.LoginSuccessSessionAction(this.authService.getPrincipalSession())
            );
            this.store.dispatch(new GetUserAction());
            return true;
          }
        }

        // Not logged in
        if (window.location.pathname.includes(routesNames.MT_CRM)) {
          window.location.href =
            location.protocol +
            '//' +
            location.host +
            '/auth/login' +
            window.location.pathname +
            window.location.search;
        }
        this.store.dispatch(new AuthAction.LoginRedirectAction());
        return false;
      })
    );
  }
}
