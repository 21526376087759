/**
 * Created by Aron Heynen on 20.10.2017.
 */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as RootReducer from '../../shared/states';
import { NavigationBackAction } from '../../shared/states/navigation/navigation.action';
import { Logger, LoggerService } from '../../shared/services/logger.service';
import { EnumService, IEnumData } from '../../shared/services/enum.service';
import {
  AdditionalAddressTypeViewModel,
  AdditionalEmailViewModel,
  AdditionalPhoneViewModel,
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  LeadCompositeViewModel,
  LeadContactViewModel,
  LeadService,
  LeadSiblingsViewModel,
  MasterDataMandatorViewModel,
  MasterDataViewModel,
  PostLeadRequest,
  PutDirectoryContactAddressRequest,
  PutSingleContactAddressRequest,
  LeadRequest,
  LeadViewModel,
  DirectoryPersonViewModel,
  PostSingleContactAddressRequest,
  DirectoryService,
} from '../../shared/apis/advis';
import { combineLatest, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ContactViewModelUtil } from '../../shared/utils/contact-view-model.util';
import { ViewSettingService } from '../../shared/services/view-setting.service';
import { ErrorTypeE } from '../../shared/states/global/global.reducer';
import { ErrorAddAction } from '../../shared/states/global/global.action';
import {
  ISelectedLeadData,
  NewLeadDataDialogComponent,
} from '../../lead/dialog/new-lead-data-dialog/new-lead-data-dialog.component';
import { filter, finalize, take } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { DatePipe } from '@angular/common';
import { LoadingModalService } from '../../shared/services/loading-modal.service';
import { ModalLoaderIdTypesEnum } from '../../shared/models/enum/modal-loader-id-types.enum';
import { isNullOrUndefined } from '../../shared/utils/isNullOrUndefined';
import {
  DETAIL_LOAD_SUCCESS,
  DetailLoadAction,
  IDetailLoad,
} from '../../shared/states/lead/lead.action';
import { Actions, ofType } from '@ngrx/effects';
import { NgForm } from '@angular/forms';
import LanguageEnum = LeadViewModel.LanguageEnum;
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import LeadLanguageEnum = LeadRequest.LeadLanguageEnum;
import { NotificationService, TypeE } from '../../shared/services/notification.service';
import { AddLeadAdditionalAddressBookContactAction } from '../../shared/states/lead-contact/lead-contact.action';
import { AddressBookBackendService } from '../../shared/services/address-book-backend.service';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;
import { IExternManagedAddressTypeConfig } from '../../shared/utils/exter-managed-address-type.util';
import { DialogService, TabsData } from '@sitewerk/theia-ui-lib';
import { PermissionService } from '../../shared/services/permission.service';

enum ActiveTab {
  singleAddress = 'single-address',
  addressBook = 'address-book',
}

@Component({
  selector: 'pc-lead-person',
  templateUrl: './lead-person.component.html',
  styleUrls: ['./lead-person.component.scss'],
})
export class LeadPersonComponent implements OnInit, OnDestroy, AfterViewInit {
  private logger: Logger;
  private subscriptions: Subscription = new Subscription();
  private addToLeadNotification: boolean = false;
  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];
  readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;
  readonly defaultEmailAddress: string = '@theiagenerated.ch';

  public newPersonTitle: string = undefined;

  lead: LeadCompositeViewModel = {};
  singleContact: PostSingleContactAddressRequest;
  isNew: boolean = false;
  isCreatedFromExisting: boolean = false;
  tradeTypeCodeToCreate: string;
  titleItems: IEnumData[] = [];
  langItems: IEnumData[] = [];
  selectedTitleItem: string;
  selectedLangItem: string;
  mandator: MasterDataMandatorViewModel;
  emailTypes: AdditionalAddressTypeViewModel[];
  phoneTypes: AdditionalAddressTypeViewModel[];
  selectedOrigin: string = undefined;
  selectedComplexityType: string = undefined;
  leadSiblings: LeadSiblingsViewModel;
  updateDebitorAddress: boolean;
  updateObjectAddress: boolean;

  selectedTab: ActiveTab = ActiveTab.singleAddress;
  activeTabs = ActiveTab;

  selectedAddressBookContact: DirectoryContactViewModel;
  originAddressBookContact: LeadContactViewModel;
  isAddressBookSelected: boolean = false;
  titleItemsPerson: IEnumData[] = [];

  tabsData: TabsData[] = [
    {
      id: ActiveTab.singleAddress,
      tabLabel: 'ADDRESS.CREATE.AS_SINGLE_ADDRESS',
      tabName: 'ADDRESS.CREATE.AS_SINGLE_ADDRESS',
    },
    {
      id: ActiveTab.addressBook,
      tabLabel: 'ADDRESS.CREATE.IN_ADDRESS_BOOK',
      tabName: 'ADDRESS.CREATE.IN_ADDRESS_BOOK',
    },
  ] as TabsData[];

  @ViewChild('addressForm', { static: false }) addressForm: NgForm;

  constructor(
    private actions$: Actions,
    private store: Store<RootReducer.IState>,
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: LoggerService,
    private enumService: EnumService,
    private leadService: LeadService,
    private translate: TranslateService,
    private vsService: ViewSettingService,
    private loadingModalService: LoadingModalService,
    private dialogService: DialogService,
    private directoryService: DirectoryService,
    private notificationService: NotificationService,
    private datepipe: DatePipe,
    private addressBookBackendService: AddressBookBackendService,
    public permissions: PermissionService
  ) {
    this.logger = this.loggerService.create(LeadPersonComponent.name);
    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
  }

  ngOnInit(): void {
    this.titleItemsPerson = this.enumService.getEnumDataExclude(TitleEnum, [
      TitleEnum.None,
      TitleEnum.Company,
    ]);
    this.selectedTab = ActiveTab.singleAddress;
    this.lead.Lead = {};
    this.langItems = this.enumService.getEnumDataExclude(LanguageEnum, [LanguageEnum.EN]);
    this.titleItems = this.enumService.getEnumDataExclude(TitleEnum, [TitleEnum.None]);
    this.singleContact = ContactViewModelUtil.createEmptySingleContact();

    this.originAddressBookContact = ContactViewModelUtil.createEmptyContactAddressbook();
    this.originAddressBookContact.AddressType = 'Contact';

    this.subscriptions.add(
      this.store.select(RootReducer.getLeadSiblingsEntity).subscribe((s: LeadSiblingsViewModel) => {
        if (isNullOrUndefined(s)) {
          this.subscriptions.add(
            this.dialogService
              .openConfirm({
                acceptButton: this.translate.instant('DIALOG.SIBLINGS_NOT_LOADED.RELOAD'),
                cancelButton: this.translate.instant('COMMON.BTN_CANCEL'),
                message: this.translate.instant('DIALOG.SIBLINGS_NOT_LOADED.MEASSAGE'),
                title: this.translate.instant('DIALOG.SIBLINGS_NOT_LOADED.TITLE'),
              })
              .afterClosed()
              .subscribe((accept: boolean) => {
                if (accept) {
                  window.location.reload();
                }
              })
          );
        }
        this.leadSiblings = s;
      })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getGlobalMasterData)
        .subscribe((masterData: MasterDataViewModel) => {
          this.emailTypes = masterData.AdditionalEmailTypes;
          this.phoneTypes = masterData.AdditionalPhoneTypes;
        })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getLeadContactAddedToLeadFailed)
        .pipe(filter(failed => failed === true))
        .subscribe(() => {
          if (this.addToLeadNotification) {
            this.addToLeadNotification = false;
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_ADD_TO_LEAD_FAILED'),
              TypeE.ALERT
            );
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getLeadContactAddedToLeadSuccess)
        .pipe(filter(success => success === true))
        .subscribe(() => {
          if (this.addToLeadNotification) {
            this.addToLeadNotification = false;
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_ADD_TO_LEAD_SUCCESS'),
              TypeE.PRIMARY
            );
          }
        })
    );

    this.subscriptions.add(
      this.route.params.subscribe((params: Params) => {
        if (!isNullOrUndefined(params.id)) {
          if (params.id === 'add') {
            this.isNew = true;
            this.newPersonTitle = this.translate.instant('LEAD_PERSON.NEW_LEAD');
            this.singleContact = ContactViewModelUtil.createEmptySingleContact();
            this.singleContact.Language = LanguageEnum.DE;
            this.singleContact.Title = TitleEnum.Madam;
            this.selectedTitleItem = this.singleContact.Title.toString();

            this.setPersonLanguageAccordingLoginLanguage();
          } else if (params.id !== null) {
            this.loadingModalService.openModal(this.modalLoaderIdTypesEnum.PERSON);

            this.newPersonTitle = undefined;

            this.loadPerson(params.id);
          }
        } else {
          this.logger.error(`Unknown params ${params}`);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.isNew) {
      this.subscriptions.add(
        this.dialogService
          .open(NewLeadDataDialogComponent, { options: { closeOnBackdropClick: false } })
          .afterClosed()
          .subscribe((data: ISelectedLeadData) => {
            if (data) {
              if (data.mandant.Id) {
                this.mandator = data.mandant;
              }

              this.tradeTypeCodeToCreate = data.tradeType.Code;
              this.selectedOrigin = data.origin;
              this.selectedComplexityType = data.complexityType;
            } else {
              this.onBack();
            }
          })
      );
    } else {
      setTimeout(() => this.addressForm.form.markAllAsTouched());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  switchActiveTab(tabId: string): void {
    this.selectedTab = tabId as ActiveTab;
  }

  onBack(): void {
    this.store.dispatch(new NavigationBackAction());
  }

  public checkDuplicateAndCopyAsExternalManaged(addressType: AddressTypeEnum): void {
    this.loadingModalService.openModal();

    const externalManagedConfig = this.allExternalManagedAddressConfig.find(
      x => x.addressType === addressType
    );

    this.addressBookBackendService
      .externManagedRequest(
        this.mandator.SharedAddressBookMandatorIds.join(','),
        this.loadingModalService,
        externalManagedConfig,
        this.lead.Lead.ContactAddress.ContactId,
        this.lead.Lead.ContactAddress.Person?.LastName,
        this.lead.Lead.ContactAddress.Person?.FirstName,
        this.lead.Lead.ContactAddress.Company?.Name,
        this.lead.Lead.ContactAddress.MainContact.Street,
        this.lead.Lead.ContactAddress.MainContact.City,
        this.lead.Lead.ContactAddress.MainContact.ZipCode,
        this.lead.Lead.ContactAddress.MainContact.PrimaryEmail.Email,
        this.lead.Lead.ContactAddress.MainContact.PrimaryPhone.PhoneNumber
      )
      .subscribe(result => {
        if (result.save && !isNullOrUndefined(result.addContactToLead)) {
          this.addToLeadNotification = true;
          this.store.dispatch(
            new AddLeadAdditionalAddressBookContactAction({
              leadId: this.lead.Lead.Id,
              request: {
                AddressType: externalManagedConfig.addressType,
                NewContactId: result.addContactToLead,
              },
            })
          );
        } else if (
          (result.save || (result.saveExternalManagedContactRequest ?? false)) &&
          !isNullOrUndefined(result.selectNewId)
        ) {
          this.copyAsExternalManagedContact(result.selectNewId, externalManagedConfig);
        } else {
          this.loadingModalService.closeModal();
        }
      });
  }

  saveButtonDisabled(): boolean {
    if (
      this.isNew &&
      (isNullOrUndefined(this.selectedOrigin) ||
        this.selectedOrigin.length === 0 ||
        isNullOrUndefined(this.selectedComplexityType) ||
        this.selectedComplexityType.length === 0)
    ) {
      return true;
    }

    if (this.selectedTab === ActiveTab.singleAddress) {
      return isNullOrUndefined(this.addressForm) || !this.addressForm.valid;
    }

    return (
      !this.isAddressBookSelected &&
      !this.updateObjectAddress &&
      !this.updateDebitorAddress &&
      this.lead.Lead.Language === this.singleContact.Language
    );
  }

  contactSelected(contact: DirectoryContactViewModel): void {
    this.selectedAddressBookContact = contact;
    this.isAddressBookSelected = true;
  }

  addressBookSaveButtonDisable(disabled: boolean): void {
    this.isAddressBookSelected = !disabled;
  }

  onCompanyNameUpdate(name: string): void {
    this.addressForm.form.markAsDirty();
    this.singleContact.CompanyName = name;
  }

  addAdditionalEmail(): void {
    this.singleContact.AdditionalEmails.push({
      EmailType: AdditionalEmailViewModel.EmailTypeEnum.Work,
    } as any);
  }

  addAdditionalPhone(): void {
    this.singleContact.AdditionalPhones.push({
      PhoneType: AdditionalPhoneViewModel.PhoneTypeEnum.Mobile,
    } as any);
  }

  removeEmail(index: number): void {
    this.singleContact.AdditionalEmails.splice(index, 1);
  }

  removePhone(index: number): void {
    this.singleContact.AdditionalPhones.splice(index, 1);
  }

  onSave(): void {
    if (this.selectedTab === ActiveTab.addressBook) {
      if (!this.hasEmailAddress()) {
        this.dialogService.openAlert(
          this.translate.instant('ADDRESS.DIALOGS.MISSING_EMAIL.MESSAGE'),
          this.translate.instant('ADDRESS.DIALOGS.MISSING_EMAIL.TITLE')
        );
        return;
      }
    }

    if (this.isNew) {
      this.onCreateContact();
    } else {
      this.updateContactPerson();
    }
  }

  private copyAsExternalManagedContact(
    contactId: number,
    externManagedAddressTypeConfig: IExternManagedAddressTypeConfig
  ): void {
    this.subscriptions.add(
      this.directoryService
        .directoryCreateExternalManagedFromExistsContact(
          contactId,
          externManagedAddressTypeConfig.addressType,
          this.lead.Lead.Language
        )
        .subscribe(
          newContact => {
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_SUCCESS', {
                addressTypeName: externManagedAddressTypeConfig.translation,
              }),
              TypeE.PRIMARY
            );
            this.store.dispatch(
              new AddLeadAdditionalAddressBookContactAction({
                leadId: this.lead.Lead.Id,
                request: {
                  AddressType: externManagedAddressTypeConfig.addressType,
                  NewContactId: newContact.Id,
                },
              })
            );
          },
          () => {
            this.loadingModalService.closeModal();
            this.notificationService.notifySimple(
              this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_FAILED', {
                addressTypeName: externManagedAddressTypeConfig.translation,
              }),
              TypeE.ALERT
            );
          }
        )
    );
  }

  private hasEmailAddress(): boolean {
    if (!isNullOrUndefined(this.selectedAddressBookContact?.Person)) {
      if (
        this.selectedAddressBookContact.Person.Emails.findIndex(
          (x: DirectoryEmailViewModel) => x.Email.length > 0
        ) > -1
      ) {
        return true;
      }
    }

    if (!isNullOrUndefined(this.selectedAddressBookContact?.Company)) {
      if (
        this.selectedAddressBookContact.Company.Emails.findIndex(
          (x: DirectoryEmailViewModel) => x.Email.length > 0
        ) > -1
      ) {
        return true;
      }
    }

    return false;
  }

  private onCreateContact(): void {
    if (this.selectedTab === ActiveTab.addressBook) {
      this.createContactAndLead();
      return;
    }
    if (!this.singleContact.Email) {
      this.subscriptions.add(
        this.store
          .select((state: RootReducer.IState) => state.auth.principal.getMandant())
          .subscribe(() => {
            this.singleContact.Email =
              this.datepipe.transform(Date.now(), 'yy-MM-dd-H-m-s') + this.defaultEmailAddress;
            this.createContactAndLead();
          })
      );
    } else {
      this.createContactAndLead();
    }
  }

  private createContactAndLead(): void {
    this.loadingModalService.openModal();
    if (
      isNullOrUndefined(this.tradeTypeCodeToCreate) &&
      !isNullOrUndefined(this.lead) &&
      !isNullOrUndefined(this.lead.Lead.TradeTypeCode)
    ) {
      this.tradeTypeCodeToCreate = this.lead.Lead.TradeTypeCode;
    }
    const request: PostLeadRequest = {
      MandatorId: this.mandator.Id,
      Origin: this.selectedOrigin,
      TradeTypeCode: this.tradeTypeCodeToCreate,
      Language: this.singleContact.Language as LeadLanguageEnum,
      ComplexityType: this.selectedComplexityType,
      DirectoryContactId:
        this.selectedTab === ActiveTab.addressBook ? this.selectedAddressBookContact.Id : undefined,
      SingleContact:
        this.selectedTab === ActiveTab.singleAddress
          ? ContactViewModelUtil.deepTrimStrings(this.singleContact)
          : undefined,
    };

    this.subscriptions.add(
      this.leadService.pCApiLeadPostLead(request).subscribe(
        (leadResult: LeadCompositeViewModel) => this.navigateToDetailView(leadResult.Lead.Id),
        (error: Response) => {
          this.logger.error('Failed to create person/lead ' + error);
          this.loadingModalService.closeModalAll();
          this.store.dispatch(new ErrorAddAction({ type: ErrorTypeE.SAVE, data: error }));
        }
      )
    );
  }

  private updateContactPerson(): void {
    this.loadingModalService.openModal();
    if (this.selectedTab === ActiveTab.addressBook) {
      this.saveAddressbookSelection();
    } else {
      if (!this.singleContact.Email) {
        this.subscriptions.add(
          this.store
            .select((state: RootReducer.IState) => state.auth.principal.getMandant())
            .subscribe(() => {
              this.singleContact.Email =
                this.datepipe.transform(Date.now(), 'yy-MM-dd-H-m-s') + this.defaultEmailAddress;
              this.UpdateSingleContact();
            })
        );
      } else {
        this.UpdateSingleContact();
      }
    }
  }

  private saveAddressbookSelection(): void {
    const request: PutDirectoryContactAddressRequest = {
      NewContactId: this.selectedAddressBookContact.Id,
      UpdateDebitorAddress: this.updateDebitorAddress,
      UpdateObjectAddress: this.updateObjectAddress,
      LeadLanguage: this.singleContact.Language,
    };

    this.subscriptions.add(
      this.leadService
        .leadAddressUpdateDirectoryContactAddress(this.lead.Lead.Id, request)
        .subscribe(
          () => {
            this.updateLeadInStore();
          },
          (error: Response) => {
            if (error.status !== 409) {
              this.loadingModalService.closeModalAll();
              this.updateLeadInStore();
              return;
            }
          }
        )
    );
  }

  private UpdateSingleContact(): void {
    const request: PutSingleContactAddressRequest = ContactViewModelUtil.deepTrimStrings(
      this.singleContact
    );
    request.OverrideSiblings = false;
    request.UpdateDebitorAddress = this.updateDebitorAddress;
    request.UpdateObjectAddress = this.updateObjectAddress;

    this.subscriptions.add(
      this.leadService
        .leadAddressUpdateSingleContactAddress(this.lead.Lead.Id, request)
        .pipe(finalize(() => this.loadingModalService.closeModal()))
        .subscribe(
          () => {
            this.updateLeadInStore();
          },
          (error: Response) => {
            if (error.status !== 409) {
              this.updateLeadInStore();
              return;
            }
          }
        )
    );
  }

  private loadPerson(leadId: number): void {
    this.subscriptions.add(
      combineLatest([
        this.store.select((storeState: RootReducer.IState) => storeState.lead.leadsDetail),
        this.store.select(RootReducer.getGlobalMandantsMap),
      ]).subscribe(
        ([leadInStore, mandatorMap]: [
          LeadCompositeViewModel,
          Map<number, MasterDataMandatorViewModel>
        ]) => {
          this.mandator = mandatorMap.get(leadInStore.Lead.MandantId);
          if (!isEmpty(leadInStore)) {
            this.setData(leadInStore);
          } else {
            this.leadService.pCApiLeadGetLead(leadId).subscribe((lead: LeadCompositeViewModel) => {
              if (lead) {
                this.setData(lead);
              } else {
                this.logger.error(`Could not find lead with id: ${this.lead.Lead.Id}`);
              }
            });
          }
        }
      )
    );

    this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.PERSON);
  }

  private setData(lead: LeadCompositeViewModel): void {
    this.lead = cloneDeep(lead);
    this.isCreatedFromExisting = !!lead.Lead.LeadCreatedFromId;
    this.selectedOrigin = lead.Lead.Origin;
    this.selectedComplexityType = lead.Lead.ComplexityType;
    this.selectedLangItem = this.lead.Lead.Language.toString();
    if (lead.Lead.ContactAddress.IsSingleContact) {
      this.lead.Lead.ContactAddress = this.lead.Lead.ContactAddress.Person
        ? this.lead.Lead.ContactAddress
        : ContactViewModelUtil.createEmptyPersonContact('Contact', true);
      this.singleContact = ContactViewModelUtil.mapLeadContactToContactViewModel(
        this.lead.Lead.ContactAddress
      );
      this.selectedTitleItem = this.lead.Lead.ContactAddress.Person.Title.toString();
    } else {
      this.originAddressBookContact = cloneDeep(this.lead.Lead.ContactAddress);
      this.selectedAddressBookContact = cloneDeep(
        ContactViewModelUtil.mapLeadContactToDirectoryContact(this.lead.Lead.ContactAddress)
      );

      this.selectedTab = ActiveTab.addressBook;
    }
  }

  private updateLeadInStore(): void {
    this.subscriptions.add(
      this.actions$.pipe(ofType(DETAIL_LOAD_SUCCESS), take(1)).subscribe(() => {
        this.navigateToDetailView(this.lead.Lead.Id);
      })
    );
    const payload: IDetailLoad = {
      leadId: this.lead.Lead.Id,
      useForceDetailLoad: true,
    };
    this.store.dispatch(new DetailLoadAction(payload));
  }

  private navigateToDetailView(leadId: number): void {
    this.router.navigate(['lead/detail', leadId]);
  }

  private setPersonLanguageAccordingLoginLanguage(): void {
    const lang: string = this.vsService.getLastLoginLang();
    this.singleContact.Language = ContactViewModelUtil.mapLangStr(lang);

    this.selectedLangItem = this.singleContact.Language.toString();
    this.logger.debug(`Set language to "${this.selectedLangItem}"`);
  }
}
