<section class="dialog">
  <h2 class="dialog-title">{{ title }}</h2>

  <div class="dialog-content">
    <pc-contact-selector
      [selectType]="selectType"
      [mandator]="mandator"
      [contactType]="contactType"
      (onSelectedContactId)="selectNewContact($event)">
    </pc-contact-selector>
  </div>
  <div class="dialog-actions">
    <button (click)="onCloseClick()">{{ 'COMMON.BTN_CLOSE' | translate }}</button>
  </div>
</section>
