<section class="dialog">
  <h2 class="dialog-title">{{ dialogTitle }}</h2>

  <div class="dialog-content">
    <p>{{ dialogDescription }}</p>
    <h4>{{ subTitleExist }}</h4>
    <helion-ui-radio-button
      [items]="contacts"
      [radioGroupClass]="'radio-group-column'"
      [(ngModel)]="selectedContact">
    </helion-ui-radio-button>
  </div>

  <div class="dialog-actions">
    <button (click)="cancel()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button [attr.fill-type]="'contained'" (click)="save()">
      {{ 'COMMON.BTN_SAVE' | translate }}
    </button>
  </div>
</section>
