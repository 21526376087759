import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../../shared/states';
import {
  AdditionalAddressTypeViewModel,
  AddressViewModel,
  DirectoryContactAddressViewModel,
  DirectoryEmailViewModel,
  DirectoryPhoneViewModel,
  FileLinkViewModel,
  LeadContactViewModel,
  LeadViewModel,
} from '../../../shared/apis/advis';
import { DomSanitizer } from '@angular/platform-browser';
import { EnumService, IEnumData } from '../../../shared/services/enum.service';
import PhoneTypeEnum = DirectoryPhoneViewModel.PhoneTypeEnum;
import EmailTypeEnum = DirectoryEmailViewModel.EmailTypeEnum;
import { DialogService } from '@sitewerk/theia-ui-lib';
import LanguageEnum = LeadViewModel.LanguageEnum;

export enum SelectContactTypeEnum {
  COMPANY_PERSON = <any>'companyPerson',
  COMPANY_PERSON_ADDRESS = <any>'companyPersonAddress',
  PERSON = <any>'person',
  COMPANY = <any>'company',
  PERSON_EXCLUDING_COMPANYPERSON = <any>'personExcludingCompanyPerson',
  COMPANY_PERSON_EXTERNAL_MANAGED_ID = <any>'companyPersonAndExternalManagedId',
}

@Directive()
export abstract class BaseContactDirective implements OnInit, OnDestroy {
  @Input() public isEditMode: boolean;
  @Input() public leadId: number;
  @Input() public mandatorId: number;
  @Input() public titleItems: IEnumData[] = [];
  @Input() public languageItems: IEnumData[] = [];
  @Input() public emailTypes: AdditionalAddressTypeViewModel[];
  @Input() public phoneTypes: AdditionalAddressTypeViewModel[];
  @Output() public contactChange: EventEmitter<LeadContactViewModel> =
    new EventEmitter<LeadContactViewModel>();
  @Output() public setEditMode: EventEmitter<undefined> = new EventEmitter<undefined>();

  public readonly phoneTypeEnum: typeof PhoneTypeEnum = PhoneTypeEnum;
  public readonly emailTypeEnum: typeof EmailTypeEnum = EmailTypeEnum;
  public readonly addressTypeEnum: typeof AddressViewModel.AddressTypeEnum =
    AddressViewModel.AddressTypeEnum;

  public logoUrl: string;

  public logoFile: FileLinkViewModel;

  protected subscriptions: Subscription = new Subscription();

  protected constructor(
    public dialogService: DialogService,
    public store: Store<RootReducer.IState>,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public enumService: EnumService
  ) {
    this.languageItems = this.enumService.getEnumDataExclude(LanguageEnum, [LanguageEnum.EN]);
  }

  public ngOnInit(): void {
    this.logoUrl = this.logoFile && this.logoFile.AccessUri;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addEmail(address: DirectoryContactAddressViewModel): void {
    if (!address.Emails) {
      address.Emails = [];
    }

    address.Emails.push({ EmailType: this.emailTypeEnum.Work, Email: '' });
  }

  public addPhone(address: DirectoryContactAddressViewModel): void {
    if (!address.Phones) {
      address.Phones = [];
    }

    address.Phones.push({ PhoneType: this.phoneTypeEnum.Mobile, PhoneNumber: '' });
  }

  public removeEmail(address: DirectoryContactAddressViewModel, index: number): void {
    address.Emails.splice(index, 1);
  }

  public removePhone(address: DirectoryContactAddressViewModel, index: number): void {
    address.Phones.splice(index, 1);
  }
}
