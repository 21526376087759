import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pc-address-header',
  templateUrl: './address-header.component.html',
  styleUrls: ['./address-header.component.scss'],
})
export class AddressHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public isReadOnly: boolean;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onEdit: EventEmitter<void> = new EventEmitter<void>();
}
