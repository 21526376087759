import { AfterViewInit, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadCompositeViewModel, MasterDataViewModel } from '../../../shared/apis/advis';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DialogRef } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent implements AfterViewInit {
  public lead$: Observable<LeadCompositeViewModel>;

  public masterData$: Observable<MasterDataViewModel>;

  public isShow: boolean = false;

  constructor(public dialogRef: DialogRef, private store: Store<RootReducer.IState>) {
    this.lead$ = this.store.select(RootReducer.getLeadDetailEntity).pipe(
      map((lead: LeadCompositeViewModel) => {
        return isEmpty(lead) ? undefined : clone(lead);
      })
    );

    this.masterData$ = this.store.select(RootReducer.getGlobalMasterData);
  }

  public ngAfterViewInit(): void {
    this.isShow = true;
  }

  public onCloseClick(): void {
    this.dialogRef.close(undefined);
  }
}
