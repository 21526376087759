import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IDetailResolve } from '../../lead-detail/resolvers/lead-detail.resolver';
import { LeadDetailLoadedResolver } from '../../lead-detail/resolvers/lead-detail-loaded.resolver';

@Injectable()
export class PersonLeadDetailLoadedResolver {
  constructor(private leadDetailLoadedResolver: LeadDetailLoadedResolver) {
    // empty
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IDetailResolve> {
    if (route.params.id && route.params.id === 'add') {
      const resolve: IDetailResolve = {
        isOwner: false,
        isDelegate: false,
        isResponsible: false,
        isReadOnly: true,
        canEditProjectData: false,
      };
      return of(resolve);
    } else {
      return this.leadDetailLoadedResolver.resolve(route);
    }
  }
}
