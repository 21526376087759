import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './common-components/main/main.component';
import { LeadMainComponent } from './lead/common/lead-main/lead-main.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LeadDetailLoadedResolver } from './lead-detail/resolvers/lead-detail-loaded.resolver';
import { AppInitializerGuard } from './shared/guards/app-initializer.guard';
import { DevelopmentComponent } from './components/development/development.component';
import { ErrorComponent } from './components/error/error.component';
import { PersonLeadDetailLoadedResolver } from './shared/resolvers/person-lead-detail-loaded.resolver';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { LeadPersonComponent } from './lead-detail/lead-person/lead-person.component';
import { LeadAddressComponent } from './lead-detail/lead-address/lead-address.component';
import { UserComponent } from './components/user/user.component';
import { AppMtInitializerGuard } from './shared/guards/mt/app-mt-initializer.guard';
import { routesNames } from './app.component';
import { LeadByPhoneGuard } from './shared/guards/lead-by-phone.guard';
import { BrowserUtils } from '@azure/msal-browser';
import { AppFieldServiceInitializerGuard } from './shared/guards/app-field-service-initializer.guard';

/////////////////////////////////////////////////////////////////////////////////////
// CAUTION:
// main.component.ts has a dependency on the URL path to generate the support mail
// according the context of the page. Whenever you change the routes, you have to
// adapt the RegExp in main.component.ts as well.
/////////////////////////////////////////////////////////////////////////////////////
const routes: Routes = [
  {
    path: '',
    redirectTo: '/lead/overview',
    pathMatch: 'full',
  },
  {
    path: '_dev',
    component: DevelopmentComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    title: 'Maintenance',
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: 'Error',
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    title: 'Login',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    title: 'Auth',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, AppInitializerGuard],
    children: [
      {
        path: 'addressbook',
        loadChildren: () =>
          import('./address-book/address-book.module').then(m => m.AddressBookModule),
        title: 'Address book',
      },
      {
        path: routesNames.MT_CRM,
        loadChildren: () => import('./mt-crm/mt-crm.module').then(m => m.MtCrmModule),
        canActivate: [AppMtInitializerGuard],
        title: 'MT CRM',
      },
      {
        path: routesNames.FIELD_SERVICE,
        loadChildren: () =>
          import('./field-service/field-service.module').then(m => m.FieldServiceModule),
        canActivate: [AppFieldServiceInitializerGuard],
        title: 'Field Service',
      },
      {
        path: 'lead',
        component: LeadMainComponent,
        canActivate: [LeadByPhoneGuard],
        children: [
          {
            path: 'project-reporting',
            loadChildren: () =>
              import('./project-reporting/project-reporting.module').then(
                m => m.ProjectReportingModule
              ),
            title: 'Lead Project Reporting',
          },
          {
            path: 'overview',
            loadChildren: () =>
              import('./lead-overview/lead-overview.module').then(m => m.LeadOverviewModule),
            title: 'Leads Overview',
          },
          {
            path: 'detail/:id',
            loadChildren: () =>
              import('./lead-detail/lead-detail.module').then(m => m.LeadDetailModule),
            title: 'Lead Detail',
          },
          {
            path: 'person/:id',
            component: LeadPersonComponent,
            resolve: {
              action: PersonLeadDetailLoadedResolver,
            },
            title: 'Lead Person',
          },
          {
            path: 'leadaddr/:id',
            component: LeadAddressComponent,
            resolve: {
              action: LeadDetailLoadedResolver,
            },
            title: 'Lead Address',
          },
          {
            path: routesNames.LEAD_EDIT_OBJECT + '/:id',
            component: LeadAddressComponent,
            resolve: {
              action: LeadDetailLoadedResolver,
            },
            title: 'Lead Object Address',
          },
          {
            path: routesNames.LEAD_EDIT_DEBITOR + '/:id',
            component: LeadAddressComponent,
            resolve: {
              action: LeadDetailLoadedResolver,
            },
            title: 'Lead Debitor Address',
          },
          {
            path: 'user',
            component: UserComponent,
            title: 'User',
          },
        ],
      },
      {
        path: 'lead-webview',
        canActivate: [LeadByPhoneGuard],
        children: [
          {
            path: 'detail/:id',
            loadChildren: () =>
              import('./lead-detail/lead-detail-webview.module').then(
                m => m.LeadDetailWebviewModule
              ),
            title: 'Lead Detail',
          },
        ],
      },
    ],
  },
  {
    path: 'user',
    redirectTo: '/lead/user',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/lead/overview',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
