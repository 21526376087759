import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ModalLoaderIdTypesEnum } from './shared/models/enum/modal-loader-id-types.enum';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { VersionCheckService } from './shared/services/version-check.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { SignalRService } from './singalR/signalr-notification.service';
import { AuthService } from './shared/services/auth.service';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.elektroFormAuthIssuer,

  redirectUri: window.location.origin + '/',

  clientId: environment.elektroFormAuthClientId,

  responseType: 'code',

  dummyClientSecret: environment.elektroFormAuthClientSecret,

  scope: 'app_api offline_access',

  showDebugInformation: true,

  useSilentRefresh: true,
};

@Component({
  selector: 'pc-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;
  public isIframe = window !== window.parent && !window.opener;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private versionCheckService: VersionCheckService,
    private oauthService: OAuthService,
    private router: Router,
    private signalRService: SignalRService,
    private authService: AuthService
  ) {
    this.oauthService.configure(authCodeFlowConfig);

    this.subscriptions.add(
      this.oauthService.events.subscribe((event: OAuthEvent) => {
        if (event.type === 'token_received' && this.oauthService.state) {
          this.router.navigate(['lead/detail/' + this.oauthService.state]);
        }
      })
    );

    this.oauthService.loadDiscoveryDocument().then(() => {
      if (this.oauthService.getRefreshToken()) {
        this.oauthService.refreshToken();
      }

      this.oauthService.tryLogin().then(() => this.oauthService.setupAutomaticSilentRefresh());
    });

    this.iconRegistry.addSvgIconInNamespace(
      'assets',
      'helion-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/helion-logo.svg')
    );

    this.iconRegistry.addSvgIconInNamespace(
      'assets',
      'bouygues-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Theia_black.svg')
    );

    this.iconRegistry.addSvgIconInNamespace(
      'assets',
      'telesales',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/telesales.svg')
    );

    this.iconRegistry.addSvgIconInNamespace(
      'assets',
      'top_ranking',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/top-ranking.svg')
    );

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('de');

    this.signalRService.subscribeToEvents();
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.authService.subscribeToSsoErrors());
    this.isIframe = window !== window.parent && !window.opener;
    this.versionCheckService.initVersionCheck(
      environment.checkVersionUrl,
      environment.versionCheckIntervalInMs
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export const routesNames: any = {
  MT_CRM: 'mt_crm',
  MT_CRM_BOM: 'bom/ht',
  MT_CRM_OVERVIEW: 'lead/overview',
  LEAD_EDIT_OBJECT: 'objectaddr',
  LEAD_EDIT_DEBITOR: 'debitoraddr',
  FIELD_SERVICE: 'field-service',
};
