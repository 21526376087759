import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  LeadCompositeViewModel,
  MasterDataViewModel,
  NoteViewModel,
  RoleTargetGroupViewModel,
} from '../../../shared/apis/advis';
import { ModalLoaderIdTypesEnum } from '../../../shared/models/enum/modal-loader-id-types.enum';
import { NoteTypeEnum } from '../../../shared/models/enum/note-type.enum';
import { INoteType } from '../../note/note-type-select-dialog/note-type-select-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENT_OVERLAY_REF } from '@sitewerk/theia-ui-lib';
import { OverlayRef } from '@angular/cdk/overlay';
import { LeadNoteCardComponent } from '../../note/lead-note-card/lead-note-card.component';
import { combineLatest, Subscription } from 'rxjs';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';

@Component({
  selector: 'pc-lead-detail-notes-card',
  templateUrl: './lead-detail-notes-card.component.html',
  styleUrls: ['./lead-detail-notes-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadDetailNotesCardComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(LeadNoteCardComponent, { static: false })
  public leadNoteCardComponent: LeadNoteCardComponent;
  readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;

  @Input() selectedNoteType: NoteTypeEnum;
  @Input() note: NoteViewModel = {};
  @Input() lead: LeadCompositeViewModel = {};
  @Input() hasProjectDescription: boolean;
  @Input() isFullScreenMode: boolean;
  @Input() selectedTags: string[] = [];
  @Input() selectedNote: NoteViewModel;
  protected roleTargetGroups: RoleTargetGroupViewModel[] = [];
  private subscriptions: Subscription = new Subscription();

  public noteTypes: INoteType[] = [
    {
      name: this.translate.instant('DIALOG.SELECT_NOTE_TYPE.TEXT_NOTE_ADD'),
      icon: 'align-left',
      type: NoteTypeEnum.TEXT,
      cyAttr: 'addSelectTextNote',
    },
    {
      name: this.translate.instant('DIALOG.SELECT_NOTE_TYPE.DRAWING_NOTE_ADD'),
      icon: 'edit-3',
      type: NoteTypeEnum.DRAWING,
    },
  ];

  constructor(
    private translate: TranslateService,
    protected store: Store<RootReducer.IState>,
    @Optional() @Inject(COMPONENT_OVERLAY_REF) public overlayRef: OverlayRef
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.store.select<MasterDataViewModel>(RootReducer.getGlobalMasterData),
        this.store.select<Set<number>>(RootReducer.getGlobalSelectedMandants),
      ]).subscribe(([masterData, selectedMandants]) => {
        if (masterData && selectedMandants) {
          this.roleTargetGroups = masterData.RoleTargetGroups.filter(
            rtg =>
              rtg.IsEnabledForNotesTargetGroup &&
              rtg.MandatorIds.some(mId => selectedMandants.has(mId))
          );
        }
      })
    );
  }

  public onSetNoteType(type: NoteTypeEnum): void {
    this.note = { Text: '', NoteType: type, LeadId: this.lead.Lead.Id };
    this.selectedNoteType = type;
    this.leadNoteCardComponent?.closeOtherNotes(undefined);
  }

  public onBack(): void {
    this.note = {};
    this.selectedNoteType = undefined;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedNoteType) {
      this.onSetNoteType(this.selectedNoteType);
    }
  }

  public printNotes(): void {
    const printContent = document.getElementById('notes-print-content')?.innerHTML;

    if (printContent) {
      const printWindow = window.open('', '_blank', 'width=800,height=600');

      printWindow.document.write(`
        <html>
            <head></head>
            <body>${printContent}</body>
        </html>
    `);

      const headClone = document.head.cloneNode(true);
      printWindow.document.head.appendChild(headClone);

      printWindow.document.close();
      printWindow.focus();

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    }
  }

  protected get bindGetRoleTargetGroupTranslation() {
    return this.getRoleTargetGroupTranslation.bind(this);
  }

  protected getRoleTargetGroupTranslation(roleId: string): string {
    var currentRtg = this.roleTargetGroups.find(rtg => rtg.RoleId === roleId);
    if (currentRtg) {
      switch (this.translate.currentLang) {
        case 'de':
          return currentRtg.RoleDisplayNameGerman;
        case 'fr':
          return currentRtg.RoleDisplayNameFrench;
        case 'it':
          return currentRtg.RoleDisplayNameItalian;
        default:
          return currentRtg.RoleDisplayNameEnglish;
      }
    }

    return 'failed to find role target group';
  }
}
