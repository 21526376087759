import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ViewSettingModel } from '../models/view-setting/view-setting.model';
import { LeadOverviewOwnVsModel } from '../models/view-setting/lead-overview-own-vs.model';
import { LeadOverviewVsModel } from '../models/view-setting/lead-overview-vs.model';
import { IViewSettingBase } from '../models/view-setting/view-setting-base.model';
import { SelectedMandantsVsModel } from '../models/view-setting/selected-mandants-vs.model';
import { Logger, LoggerService } from './logger.service';
import { IPrincipal } from '../interfaces/principal.interface';
import { CommonUtil } from '../utils/common.util';
import { AccountService } from '../apis/advis';
import merge from 'lodash/merge';
import { VariantVsModel } from '../models/view-setting/variant-vs.model';
import { LeadOverviewTasksVsModel } from '../models/view-setting/lead-overview-tasks-vs.model';
import { LocalStorageKey } from './local-storage-key';
import { LeadOverviewSettingsModel } from '../models/view-setting/lead-overview-settings.model';

export enum ViewSettingKeyE {
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // Do not change the order of the enum
  // Do not delete entries from the enum
  // as it is used as key in the local storage
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  AUTH = 1, // For internal use only
  LAST_LOGIN_LANG, // For internal use only
  LEAD_OVERVIEW_V1,
  LEAD_OVERVIEW_OWN_V1,
  LEAD_OVERVIEW_ONLINEPOOL_V1,
  LEAD_OVERVIEW_ALL_V1,
  DEPRECATED_SELECTED_MANDANTS_V1, // Deprecated - Keep for backwards compatibility
  LEAD_OVERVIEW_TRIAGE_V1,
  DEPRECATED_SELECTED_MANDANTS_ONLINEPOOL_V1, // Deprecated - Keep for backwards compatibility
  _VERSION, // For internal use only
  SELECTED_MANDANTS_V2,
  VARIANT,
  LEAD_OVERVIEW_NEW,
  LEAD_OVERVIEW_FOLLOWUP,
  TASK_OWN_OVERVIEW_LIST,
  LEAD_OVERVIEW_ON_HOLD,
  LEAD_OVERVIEW_WON,
  MT_CRM_LEAD_OVERVIEW_WON,
  LEAD_OVERVIEW_NOT_ASSIGNED,
  ADDRESSBOOK_OVERVIEW,
  LEAD_OVERVIEW_PROJECTS_IN_PROGRESS,
  LEAD_OVERVIEW_PROJECT_OVERVIEW,
  LEAD_OVERVIEW_PROJECT_OVERVIEW_OWN,
  TASK_DELEGATED_OVERVIEW_LIST,
  LEAD_OVERVIEW_ONLINEPOOL_CALLPOOL,
  LEAD_OVERVIEW_ONLINEPOOL_DOWNLOAD,
  IS_PRIVACY_MODE,
  TASKS_FILTERS,
  BOM_SIDENAV_WIDTH,
  LEAD_OVERVIEW_ES_NOT_SET,
  LEAD_OVERVIEW_ES_APPROVED,
  LEAD_OVERVIEW_ES_DISAPPROVED,
  LEAD_OVERVIEW_ORDER_ENTRY,
}

export class ViewSettingItem<T> {
  key: ViewSettingKeyE;
  setting: IViewSettingBase<T>;

  constructor(key: ViewSettingKeyE, setting: IViewSettingBase<T>) {
    this.key = key;
    this.setting = setting;
  }
}

export enum LeadOverviewOwnPageEnum {
  newPage,
  followupPage,
  unreachedPage,
  delegatedPage,
  delegatedToMePage,
  projectAssignedPage,
  projectArchivedPage,
  projectInOperationPage,
  projectInProgressPage,
  projectAccountedPage,
  orderEntryPage,
}

export interface ITasksFilters {
  completed?: boolean;
  dueDate?: boolean;
}

const DEFAULT_SIDENAV_WIDTH = '30%';

@Injectable()
export class ViewSettingService {
  private logger: Logger;
  private storagePrefix: string;
  private version: number = 2; // Version of the settings, can be used for migration in the future

  $bomSideNavWidth: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_SIDENAV_WIDTH);
  $IsPrivacyMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $tasksFilters: BehaviorSubject<ITasksFilters> = new BehaviorSubject<ITasksFilters>({});

  constructor(private accountService: AccountService, loggerService: LoggerService) {
    this.logger = loggerService.create(ViewSettingService.name);
  }

  public load(): Observable<ViewSettingModel> {
    this.checkInitVersion();

    this.setBomSideNavWidth(this.getBomSideNavWidth());
    this.setPrivacyMode(this.getPrivacyMode());
    this.setTasksFilters(this.getTasksFilters());

    const viewSettings: ViewSettingModel = new ViewSettingModel();

    const selectedMandants: SelectedMandantsVsModel = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE.SELECTED_MANDANTS_V2)
    );
    if (selectedMandants) {
      viewSettings.selectedMandants.mandants = selectedMandants.mandants;
    }

    const leadOverview: LeadOverviewVsModel = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_V1)
    );
    if (leadOverview) {
      viewSettings.leadOverview.activeView = leadOverview.activeView;
    }

    const leadOverviewOwn: LeadOverviewOwnVsModel = new LeadOverviewOwnVsModel();
    merge(
      leadOverviewOwn,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_OWN_V1))
    );
    if (leadOverviewOwn) {
      viewSettings.leadOverviewOwn.filter.name = leadOverviewOwn.filter.name;
      viewSettings.leadOverviewOwn.filter.street = leadOverviewOwn.filter.street;
      viewSettings.leadOverviewOwn.filter.zipCity = leadOverviewOwn.filter.zipCity;
      viewSettings.leadOverviewOwn.filter.hideLeadsWithFutureTasks =
        leadOverviewOwn.filter.hideLeadsWithFutureTasks;
      viewSettings.leadOverviewOwn.filter.tradeType = leadOverviewOwn.filter.tradeType;
      viewSettings.leadOverviewOwn.filter.mandant = leadOverviewOwn.filter.mandant;
      viewSettings.leadOverviewOwn.filter.phoneNumber = leadOverviewOwn.filter.phoneNumber;
      viewSettings.leadOverviewOwn.filter.origin = leadOverviewOwn.filter.origin;
      viewSettings.leadOverviewOwn.filter.companyName = leadOverviewOwn.filter.companyName;
      viewSettings.leadOverviewOwn.filter.language = leadOverviewOwn.filter.language;
      viewSettings.leadOverviewOwn.filter.tag = leadOverviewOwn.filter.tag;
      viewSettings.leadOverviewOwn.filter.dateRangeType = leadOverviewOwn.filter.dateRangeType;
      viewSettings.leadOverviewOwn.filter.dateRangeEnd = leadOverviewOwn.filter.dateRangeEnd;
      viewSettings.leadOverviewOwn.filter.dateRangeStart = leadOverviewOwn.filter.dateRangeStart;
      viewSettings.leadOverviewOwn.filter.planner = leadOverviewOwn.filter.planner;
      viewSettings.leadOverviewOwn.filter.plannerAc = leadOverviewOwn.filter.plannerAc;

      viewSettings.leadOverviewOwn.filterVisible = leadOverviewOwn.filterVisible;

      viewSettings.leadOverviewOwn.newSort = leadOverviewOwn.newSort;
      viewSettings.leadOverviewOwn.unreachedSort = leadOverviewOwn.unreachedSort;
      viewSettings.leadOverviewOwn.delegatedSort = leadOverviewOwn.delegatedSort;
      viewSettings.leadOverviewOwn.delegatedToMeSort = leadOverviewOwn.delegatedToMeSort;
      viewSettings.leadOverviewOwn.followupSort = leadOverviewOwn.followupSort;
      viewSettings.leadOverviewOwn.projectAssignedSort = leadOverviewOwn.projectAssignedSort;
      viewSettings.leadOverviewOwn.projectArchivedSort = leadOverviewOwn.projectArchivedSort;
      viewSettings.leadOverviewOwn.projectInOperationSort = leadOverviewOwn.projectInOperationSort;
      viewSettings.leadOverviewOwn.projectInProgressSort = leadOverviewOwn.projectInProgressSort;

      viewSettings.leadOverviewOwn.newPage = leadOverviewOwn.newPage;
      viewSettings.leadOverviewOwn.unreachedPage = leadOverviewOwn.unreachedPage;
      viewSettings.leadOverviewOwn.delegatedPage = leadOverviewOwn.delegatedPage;
      viewSettings.leadOverviewOwn.delegatedToMePage = leadOverviewOwn.delegatedToMePage;
      viewSettings.leadOverviewOwn.followupPage = leadOverviewOwn.followupPage;
      viewSettings.leadOverviewOwn.projectAssignedPage = leadOverviewOwn.projectAssignedPage;
      viewSettings.leadOverviewOwn.projectArchivedPage = leadOverviewOwn.projectArchivedPage;
      viewSettings.leadOverviewOwn.projectInOperationPage = leadOverviewOwn.projectInOperationPage;
      viewSettings.leadOverviewOwn.projectInProgressPage = leadOverviewOwn.projectInProgressPage;

      viewSettings.leadOverviewOwn.panel.panelNewExpanded = leadOverviewOwn.panel.panelNewExpanded;
      viewSettings.leadOverviewOwn.panel.panelFollowUpExpanded =
        leadOverviewOwn.panel.panelFollowUpExpanded;
      viewSettings.leadOverviewOwn.panel.panelUnreachedExpanded =
        leadOverviewOwn.panel.panelUnreachedExpanded;
      viewSettings.leadOverviewOwn.panel.panelDelegatedExpanded =
        leadOverviewOwn.panel.panelDelegatedExpanded;
      viewSettings.leadOverviewOwn.panel.panelDelegatedToMeExpanded =
        leadOverviewOwn.panel.panelDelegatedToMeExpanded;
      viewSettings.leadOverviewOwn.panel.panelProjectAssigned =
        leadOverviewOwn.panel.panelProjectAssigned;
      viewSettings.leadOverviewOwn.panel.panelProjectInOperation =
        leadOverviewOwn.panel.panelProjectInOperation;
      viewSettings.leadOverviewOwn.panel.panelProjectInProgress =
        leadOverviewOwn.panel.panelProjectInProgress;
      viewSettings.leadOverviewOwn.panel.panelProjectArchived =
        leadOverviewOwn.panel.panelProjectArchived;
    }

    const leadOverviewNew: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewNew,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_NEW))
    );
    if (leadOverviewNew) {
      viewSettings.leadOverviewNew = leadOverviewNew;
      viewSettings.leadOverviewNew.itemsPerPage = 100;
    }

    const leadOverviewFollowup: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewFollowup,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_FOLLOWUP))
    );
    if (leadOverviewFollowup) {
      viewSettings.leadOverviewFollowup = leadOverviewFollowup;
      viewSettings.leadOverviewFollowup.itemsPerPage = 100;
    }

    const leadOverviewOrderEntry: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewOrderEntry,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ORDER_ENTRY)
      )
    );
    if (leadOverviewOrderEntry) {
      viewSettings.leadOverviewOrderEntry = leadOverviewOrderEntry;
      viewSettings.leadOverviewOrderEntry.itemsPerPage = 100;
    }

    const leadOverviewOnlinepool: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewOnlinepool,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_V1)
      )
    );
    if (leadOverviewOnlinepool) {
      viewSettings.leadOverviewOnlinepool = leadOverviewOnlinepool;
    }

    const leadOverviewDownloadpool: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewDownloadpool,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_DOWNLOAD)
      )
    );
    if (leadOverviewDownloadpool) {
      viewSettings.leadOverviewDownloadpool = leadOverviewDownloadpool;
    }

    const leadOverviewCallpool: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewCallpool,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ONLINEPOOL_CALLPOOL)
      )
    );
    if (leadOverviewCallpool) {
      viewSettings.leadOverviewCallpool = leadOverviewCallpool;
    }

    const leadOverviewTriage: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewTriage,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_TRIAGE_V1))
    );
    if (leadOverviewTriage) {
      viewSettings.leadOverviewTriage = leadOverviewTriage;
    }

    const leadOverviewOwnTasks: LeadOverviewTasksVsModel = new LeadOverviewTasksVsModel();
    merge(
      leadOverviewOwnTasks,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.TASK_OWN_OVERVIEW_LIST))
    );
    if (leadOverviewOwnTasks) {
      viewSettings.leadOverviewOwnTasks.panel.panelExpanded =
        leadOverviewOwnTasks.panel.panelExpanded;
      viewSettings.leadOverviewOwnTasks.tableSort = leadOverviewOwnTasks.tableSort;
    }

    const leadOverviewDelegatedTasks: LeadOverviewTasksVsModel = new LeadOverviewTasksVsModel();
    merge(
      leadOverviewDelegatedTasks,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.TASK_DELEGATED_OVERVIEW_LIST)
      )
    );
    if (leadOverviewDelegatedTasks) {
      viewSettings.leadOverviewDelegatedTasks.panel.panelExpanded =
        leadOverviewDelegatedTasks.panel.panelExpanded;
      viewSettings.leadOverviewDelegatedTasks.tableSort = leadOverviewDelegatedTasks.tableSort;
    }

    const variant: VariantVsModel = new VariantVsModel();
    merge(variant, JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.VARIANT)));
    if (variant) {
      viewSettings.variant.bomViewMode = variant.bomViewMode;
      viewSettings.variant.isVariantWizardExtended = variant.isVariantWizardExtended;
      viewSettings.variant.isTradeSpecificLeadWizardExtended =
        variant.isTradeSpecificLeadWizardExtended;
      viewSettings.variant.isVariantWizardExpanded = variant.isVariantWizardExpanded;
      viewSettings.variant.isTradeSpecificLeadWizardExpanded =
        variant.isTradeSpecificLeadWizardExpanded;
    }

    const leadOverviewOnHold: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewOnHold,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ON_HOLD)),
      { page: 0 }
    );
    if (leadOverviewOnHold) {
      viewSettings.leadOverviewOnHold = leadOverviewOnHold;
      viewSettings.leadOverviewOnHold.itemsPerPage = 100;
    }

    const leadOverviewWon: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewWon,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_WON)),
      { page: 0 }
    );
    if (leadOverviewWon) {
      viewSettings.leadOverviewWon = leadOverviewWon;
      viewSettings.leadOverviewWon.itemsPerPage = 100;
    }

    const leadOverviewEsApproved: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewEsApproved,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ES_APPROVED)
      ),
      { page: 0 }
    );
    if (leadOverviewEsApproved) {
      viewSettings.leadOverviewEsApproved = leadOverviewEsApproved;
      viewSettings.leadOverviewEsApproved.itemsPerPage = 100;
    }

    const leadOverviewEsDisapproved: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewEsDisapproved,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ES_DISAPPROVED)
      ),
      { page: 0 }
    );
    if (leadOverviewEsDisapproved) {
      viewSettings.leadOverviewEsDisapproved = leadOverviewEsDisapproved;
      viewSettings.leadOverviewEsDisapproved.itemsPerPage = 100;
    }

    const leadOverviewEsNotSet: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewEsNotSet,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ES_NOT_SET)
      ),
      { page: 0 }
    );
    if (leadOverviewEsNotSet) {
      viewSettings.leadOverviewEsNotSet = leadOverviewEsNotSet;
      viewSettings.leadOverviewEsNotSet.itemsPerPage = 100;
    }

    const leadOverviewNotAssigned: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewNotAssigned,
      JSON.parse(
        localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_NOT_ASSIGNED)
      ),
      { page: 0 }
    );
    if (leadOverviewNotAssigned) {
      viewSettings.leadOverviewNotAssigned = leadOverviewNotAssigned;
    }

    const leadOverviewAll: LeadOverviewSettingsModel = new LeadOverviewSettingsModel();
    merge(
      leadOverviewAll,
      JSON.parse(localStorage.getItem(this.storagePrefix + ViewSettingKeyE.LEAD_OVERVIEW_ALL_V1)),
      { page: 0 }
    );
    if (leadOverviewAll) {
      viewSettings.leadOverviewAll = leadOverviewAll;
    }

    return of(viewSettings);
  }

  public save<T>(viewSettingsItem: ViewSettingItem<T>): Observable<ViewSettingItem<T>> {
    localStorage.setItem(
      this.storagePrefix + viewSettingsItem.key,
      JSON.stringify(viewSettingsItem.setting)
    );
    return of(viewSettingsItem);
  }

  public setPrincipal(principal: IPrincipal): void {
    this.storagePrefix = principal.getUserId() + '.' + LocalStorageKey.MAIN_KEY_VIEW_SETTING;

    // We store the principal together with the userId - maybe in the future we have to migrate a user with role X to role Y
    // therefor it's good to remember the settings. Currently unused.
    localStorage.setItem(this.storagePrefix + ViewSettingKeyE.AUTH, JSON.stringify(principal));
  }

  public setLastLoginLang(language: string): void {
    localStorage.setItem(
      LocalStorageKey.MAIN_KEY_VIEW_SETTING + ViewSettingKeyE.LAST_LOGIN_LANG,
      JSON.stringify(language)
    );
  }

  public getLastLoginLang(): string {
    const lastLoginLang: string = JSON.parse(
      localStorage.getItem(LocalStorageKey.MAIN_KEY_VIEW_SETTING + ViewSettingKeyE.LAST_LOGIN_LANG)
    );
    if (lastLoginLang) {
      return lastLoginLang;
    }
    return 'de';
  }

  public setPrivacyMode(isPrivacyMode: boolean): void {
    localStorage.setItem(
      this.storagePrefix + ViewSettingKeyE.IS_PRIVACY_MODE,
      JSON.stringify(isPrivacyMode)
    );
    this.$IsPrivacyMode.next(isPrivacyMode);
  }

  public getPrivacyMode(): boolean {
    const isPrivacyMode: string = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE.IS_PRIVACY_MODE)
    );
    if (isPrivacyMode) {
      return Boolean(isPrivacyMode);
    }
    return false;
  }

  public setBomSideNavWidth(width: string): void {
    if (width) {
      localStorage.setItem(
        this.storagePrefix + ViewSettingKeyE.BOM_SIDENAV_WIDTH,
        JSON.stringify(width)
      );
      this.$bomSideNavWidth.next(width);
    }
  }

  public getBomSideNavWidth(): string {
    return JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE.BOM_SIDENAV_WIDTH) ||
        JSON.stringify(DEFAULT_SIDENAV_WIDTH)
    );
  }

  public setAccountLanguageAndReload(language: string): void {
    this.accountService.accountPutLanguage(language).subscribe(() => {
      this.setLastLoginLang(language);
      CommonUtil.reloadPage();
    });
  }

  public setTasksFilters(tasksFilters: Partial<ITasksFilters>): void {
    const currentTasksFilters = this.getTasksFilters();
    const actualTasksFilters = { ...currentTasksFilters, ...tasksFilters };
    localStorage.setItem(
      this.storagePrefix + ViewSettingKeyE.TASKS_FILTERS,
      JSON.stringify(actualTasksFilters)
    );

    this.$tasksFilters.next(actualTasksFilters);
  }

  public getTasksFilters(): ITasksFilters {
    const tasksFilters: Record<string, boolean> = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE.TASKS_FILTERS)
    );
    return tasksFilters ?? { completed: false, dueDate: false };
  }

  public toggleTaskFilter(property: keyof ITasksFilters): void {
    const currentTasksFilters = this.getTasksFilters();
    this.setTasksFilters({ ...currentTasksFilters, [property]: !currentTasksFilters[property] });
  }

  private checkInitVersion(): void {
    const storageVersion: number = JSON.parse(
      localStorage.getItem(this.storagePrefix + ViewSettingKeyE._VERSION)
    );
    if (storageVersion) {
      this.logger.info(
        `Version "${storageVersion}" found in storage. Current version is "${this.version}"`
      );

      if (storageVersion === this.version) {
        this.logger.info('Versions equal, noting to do');
      } else {
        // Implement the migration path here, e.g. 1->2, 2-3, 3-4, etc.
        this.logger.info('Versions different, starting migration ... ');
      }
    } else {
      this.logger.info(`No version found in storage, write "${this.version}"`);
      localStorage.setItem(
        this.storagePrefix + ViewSettingKeyE._VERSION,
        JSON.stringify(this.version)
      );
    }
  }
}
