<helion-ui-drawer
  drawerClass="{{ debugInfo.debugClass }} lead-main-drawer"
  [drawerContentComponent]="drawerContentComponent">
  <div role="header" style="flex: 1">
    <div class="{{ debugInfo.debugClass }} js-lead-main-toolbar">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="15px"
        fxLayoutGap.lt-sm="0"
        class="width-100">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
          <span fxHide.lt-sm (click)="navigateToHome()" class="cursor-pointer">
            THEIA {{ 'COMMON.PC.LONG' | translate }}
          </span>
          <span fxHide fxShow.lt-sm (click)="navigateToHome()" class="cursor-pointer">
            THEIA {{ 'COMMON.PC.SHORT' | translate }}
          </span>
          <helion-ui-tag-header
            class="debug-chip hide-mobile"
            *ngIf="debugInfo.isDisplayed"
            [tagHeaderText]="debugInfo.debugText">
          </helion-ui-tag-header>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px" fxLayoutGap.lt-sm="10px">
          <div class="header-notifications">
            <button class="overflow-visible" [cdkMenuTriggerFor]="tasksMenu">
              <pc-notification-count
                [notifications]="(tasks$ | async)?.length"
                [color]="notificationColorEnum.Warn">
                <helion-ui-icon data-feather="bell"></helion-ui-icon>
              </pc-notification-count>
            </button>

            <ng-template #tasksMenu>
              <helion-ui-menu class="task-menu">
                <div fxLayout="column">
                  <div class="subhead">{{ 'LEAD_TASK.OPEN_TASKS' | translate }}</div>
                  <helion-ui-divider dividerColor="gray"></helion-ui-divider>
                </div>
                <div *ngIf="!(tasks$ | async) || (tasks$ | async)?.length === 0">
                  {{ 'COMMON.NO_DATA' | translate }}
                </div>
                <ng-template let-item="$implicit" let-last="last" ngFor [ngForOf]="tasks$ | async">
                  <a cdkMenuItem (click)="onTask(item)" class="task-menu-content">
                    <helion-ui-icon
                      data-feather="calendar"
                      class="task-menu-content_icon"></helion-ui-icon>
                    <p class="task-menu-content_date">{{ item.DueDate | date : 'dd.MM.yy' }}</p>
                    <h4 class="task-menu-content_title">
                      <span class="text-wrap">&nbsp;{{ item.Title }}</span>
                    </h4>
                    <div class="task-customer task-menu-content_name">{{ item.CustomerName }}</div>
                  </a>
                  <helion-ui-divider *ngIf="!last" dividerColor="gray"></helion-ui-divider>
                </ng-template>
              </helion-ui-menu>
            </ng-template>
          </div>

          <pc-mandant-selector></pc-mandant-selector>

          <pc-account-switcher></pc-account-switcher>

          <pc-privacy-mode-switcher
            [ngClass]="{
              'disabled-component disabled-component_with-opacity': isReadOnly
            }"></pc-privacy-mode-switcher>
        </div>
      </div>
    </div>
  </div>
</helion-ui-drawer>

<div class="lead-main-content-wrapper">
  <pc-read-only-label *ngIf="isReadOnlyLabelShow" [message]="readOnlyLabel">
    <span
      *ngIf="showAssignToMeButton && !showProjectAssignButton"
      (click)="onLeadAssignToMe()"
      class="cursor-pointer with-font-bold text-blue"
      data-cy="leadAssignToMe"
      >{{ 'COMMON.ACTION.ASSIGN_TO_ME' | translate }}</span
    >
    <span
      *ngIf="isReadOnly && isOwner && isResponsible"
      (click)="onUnlockLead()"
      class="cursor-pointer with-font-bold text-blue"
      >{{ 'COMMON.ACTION.UNLOCK_LEAD' | translate }}</span
    >
    <span
      *ngIf="isReadOnly && isOwner && !isResponsible"
      (click)="onSendBackLead()"
      class="cursor-pointer with-font-bold text-blue"
      >{{ 'LEADS.ACTION.SEND_BACK' | translate }}</span
    >
  </pc-read-only-label>
  <router-outlet></router-outlet>
</div>
