import { Injectable } from '@angular/core';
import * as RootReducer from '../../shared/states/index';
import { Store } from '@ngrx/store';
import { IPrincipal } from '../interfaces/principal.interface';
import { ILeadRights } from '../interfaces/lead-rights.interface';
import { LeadRights } from '../models/rights/lead-rights.model';
import { DummyPrincipal } from '../models/principal-dummy.model';
import { PermissionService } from './permission.service';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';

@Injectable()
export class RightsService {
  private principal: IPrincipal;
  private leadRights: ILeadRights;

  constructor(private store: Store<RootReducer.IState>, permissions: PermissionService) {
    this.store
      .select<IPrincipal>(RootReducer.getAuthPrincipal)
      .subscribe((principal: IPrincipal) => {
        if (isNullOrUndefined(principal)) {
          this.principal = new DummyPrincipal();
        } else {
          this.principal = principal;
        }
        this.leadRights = new LeadRights(this.principal, permissions);
      });
  }

  public getLeadRights(): ILeadRights {
    return this.leadRights;
  }
}
