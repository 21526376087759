/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProjectReportingPositionTypeViewModel } from './projectReportingPositionTypeViewModel';


export interface ProjectReportingSnapshotViewModel { 
    Id?: number;
    Created?: Date;
    ProjectTrafficLight?: ProjectReportingSnapshotViewModel.ProjectTrafficLightEnum;
    Comment?: string;
    Risks?: string;
    Prospects?: string;
    Material?: ProjectReportingPositionTypeViewModel;
    OwnContribution?: ProjectReportingPositionTypeViewModel;
    ThirdPartyContribution?: ProjectReportingPositionTypeViewModel;
    Undefined?: ProjectReportingPositionTypeViewModel;
    Total?: ProjectReportingPositionTypeViewModel;
    MinimalMargin?: number;
    GrossMarginTotal?: number;
    GoalMargin?: number;
    Charged?: number;
    Rabat?: number;
    ProjectManagementTimeHoursActual?: number;
    MontageTimeHoursActual?: number;
    MontageAcTimeHoursActual?: number;
    MontageScaffoldingTimeHoursActual?: number;
    OtherTimeHoursActual?: number;
    ProjectManagementTimeHourlyRate?: number;
    MontageTimeHourlyRate?: number;
    MontageAcTimeHourlyRate?: number;
    MontageScaffoldingTimeHourlyRate?: number;
    OtherTimeHourlyRate?: number;
    ProjectAccountedDate?: Date;
    ProjectAccountedDateReschedulingReason?: ProjectReportingSnapshotViewModel.ProjectAccountedDateReschedulingReasonEnum;
    FinanceTrafficLight?: string;
    ExpectedUpsites?: number;
    ExpectedDownsites?: number;
    LastContactToCustomerDate?: Date;
}
export namespace ProjectReportingSnapshotViewModel {
    export type ProjectTrafficLightEnum = 'None' | 'Light' | 'Strong' | 'Neutral';
    export const ProjectTrafficLightEnum = {
        None: 'None' as ProjectTrafficLightEnum,
        Light: 'Light' as ProjectTrafficLightEnum,
        Strong: 'Strong' as ProjectTrafficLightEnum,
        Neutral: 'Neutral' as ProjectTrafficLightEnum
    };
    export type ProjectAccountedDateReschedulingReasonEnum = 'WEATHER_CONDITIONS' | 'LACK_OF_LABOR' | 'SUPPLY_CHAIN_ISSUES' | 'PERMITS_AND_BUREAUCRACY' | 'SCOPE_CHANGES' | 'TECHNICAL_ISSUES' | 'FINANCIAL_CHALLENGES' | 'UNFORESEEN_OBJECT_CONDITIONS' | 'OTHER' | 'PROJECT_ACCOUNTED_DATE_CHANGE';
    export const ProjectAccountedDateReschedulingReasonEnum = {
        WEATHERCONDITIONS: 'WEATHER_CONDITIONS' as ProjectAccountedDateReschedulingReasonEnum,
        LACKOFLABOR: 'LACK_OF_LABOR' as ProjectAccountedDateReschedulingReasonEnum,
        SUPPLYCHAINISSUES: 'SUPPLY_CHAIN_ISSUES' as ProjectAccountedDateReschedulingReasonEnum,
        PERMITSANDBUREAUCRACY: 'PERMITS_AND_BUREAUCRACY' as ProjectAccountedDateReschedulingReasonEnum,
        SCOPECHANGES: 'SCOPE_CHANGES' as ProjectAccountedDateReschedulingReasonEnum,
        TECHNICALISSUES: 'TECHNICAL_ISSUES' as ProjectAccountedDateReschedulingReasonEnum,
        FINANCIALCHALLENGES: 'FINANCIAL_CHALLENGES' as ProjectAccountedDateReschedulingReasonEnum,
        UNFORESEENOBJECTCONDITIONS: 'UNFORESEEN_OBJECT_CONDITIONS' as ProjectAccountedDateReschedulingReasonEnum,
        OTHER: 'OTHER' as ProjectAccountedDateReschedulingReasonEnum,
        PROJECTACCOUNTEDDATECHANGE: 'PROJECT_ACCOUNTED_DATE_CHANGE' as ProjectAccountedDateReschedulingReasonEnum
    };
}
