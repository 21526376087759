<div fxLayout="row" fxLayoutGap="1rem" class="contact-selector">
  <helion-ui-select
    [overlayCssClass]="'dropdown-content-with-action-buttons'"
    name="companyAddress"
    type="text"
    maxlength="350"
    (onAutocomplete)="onTypeaheadChange($event)"
    [placeholder]="inputPlaceholder"
    [autocomplete]="true"
    [(ngModel)]="searchString">
    <div>
      <ul class="dropdown-content-list">
        <ng-container>
          <div class="contact-selector-rows">
            <li
              *ngFor="let contact of contactsFromBook"
              (click)="selectContact(contact)"
              class="rows menu-item"
              fxLayoutAlign="start center">
              <helion-ui-icon
                *ngIf="contact.IsExternalManaged"
                class="icon-bold"
                [ngClass]="{ 'icon-success': contact.DataState === 1 }"
                [attr.data-feather]="externalManagedAddress.Icon">
              </helion-ui-icon>
              <div style="font-size: 14px">
                {{ contact.Contact }}
              </div>
            </li>
          </div>

          <helion-ui-divider [dividerColor]="'gray'"></helion-ui-divider>

          <div fxLayout="row" fxLayoutAlign="start">
            <div
              class="contact-form-autocomplete-options-footer width-100"
              fxLayout="column"
              fxLayoutGap="10px"
              fxLayoutAlign="center start">
              <button
                *ngIf="createCompanyEnabled"
                class="contact-form-button menu-item"
                (click)="createCompany()">
                <helion-ui-icon data-feather="plus"></helion-ui-icon>
                <span>{{ 'ADDRESS.CREATE.NEW_COMPANY' | translate }}</span>
              </button>
              <button
                *ngIf="createPersonEnabled"
                class="contact-form-button menu-item"
                (click)="createPerson()">
                <helion-ui-icon data-feather="plus"></helion-ui-icon>
                <span>{{ 'ADDRESS.CREATE.NEW_PERSON' | translate }}</span>
              </button>
              <button
                *ngIf="createExternalManagedAddressEnabled"
                class="contact-form-button menu-item"
                (click)="createExternalManaged()">
                <helion-ui-icon data-feather="plus"></helion-ui-icon>
                <span>{{
                  'ADDRESS.CREATE.NEW_EXTERNAL_MANAGED'
                    | translate : { addressTypeName: externalManagedAddress.translation }
                }}</span>
              </button>
              <button
                *ngIf="createCompanyEnabled && detailSearchEnabled"
                class="contact-form-button menu-item"
                (click)="detailSearch()">
                <helion-ui-icon data-feather="search"></helion-ui-icon>
                <span>{{ 'ADDRESS.CREATE.DETAIL_SEARCH' | translate }}</span>
              </button>
            </div>
          </div>
        </ng-container>
      </ul>
    </div>
  </helion-ui-select>
  <button *ngIf="detailSearchEnabled" class="contact-form-button" (click)="detailSearch()">
    <helion-ui-icon data-feather="search" class="add-article-icon"></helion-ui-icon>
    <span>{{ 'ADDRESS.CREATE.DETAIL_SEARCH' | translate }}</span>
  </button>
</div>
