import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignalRService } from './../../singalR/signalr-notification.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class SignalRInterceptor implements HttpInterceptor {
  constructor(private signalRService: SignalRService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const connectionId = this.signalRService.getCurrentConnectionId();
    const requestToBackend = request.url.startsWith(environment.apiBasePath);
    if (connectionId && requestToBackend) {
      const modifiedRequest = request.clone({
        setHeaders: {
          SignalRConnectionId: connectionId,
        },
      });
      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}
