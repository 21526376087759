import { Injectable } from '@angular/core';
import { DragulaOptions } from 'ng2-dragula';
import { DragulaItemDirective } from '../../common-components/directives/dragula-item.directive';

@Injectable({
  providedIn: 'root',
})
export class DragulaNoteTagsConfig {
  private noteItems: DragulaItemDirective[] = [];
  private _allowDragAndDrop: boolean = true;

  public readonly noteTagClassName = 'note-tag';
  public readonly noteTagAttributeName = 'data-note-tag';
  public readonly noteContainerClassName = 'note-container';
  public readonly dragNoteTagClassName = 'drag-note-tag';
  public readonly noteIndexAttributeName = 'note-index';
  public readonly currentDragTargetClassName = 'current-drag-target';

  options: DragulaOptions = {
    copy: (el: Element) => {
      if (el.classList.contains(this.noteTagClassName)) {
        el.classList.add(this.dragNoteTagClassName);
      }
      return el.classList.contains(this.noteTagClassName);
    },
    copyItem: (item: any) => {
      return { ...item };
    },
    accepts: (el: HTMLElement, target: HTMLElement, source: HTMLElement) => {
      if (!this._allowDragAndDrop) {
        return false;
      }

      const targetIndex = target.getAttribute(this.noteIndexAttributeName);
      this.noteItems
        .map(note => note.elementRef.nativeElement)
        .filter(element => element.getAttribute(this.noteIndexAttributeName) !== targetIndex)
        .forEach(element => element.classList.remove(this.currentDragTargetClassName));

      target.classList.add(this.currentDragTargetClassName);

      const elementIsTag: boolean = el.classList.contains(this.noteTagClassName);
      const targetIsNote: boolean = target.classList.contains(this.noteContainerClassName);

      return target === source || (elementIsTag && targetIsNote);
    },
    moves: (el: HTMLElement) => {
      return this._allowDragAndDrop && el.classList.contains(this.noteTagClassName);
    },
    revertOnSpill: true,
  };

  setAllowDnD(allow: boolean): void {
    this._allowDragAndDrop = allow;
  }

  initializeNoteItems(notes: DragulaItemDirective[]): void {
    this.noteItems = notes;
  }
}
