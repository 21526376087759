import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { combineLatest, Observable, race } from 'rxjs';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../shared/states/index';
import { Actions, ofType } from '@ngrx/effects';
import * as ProjectReportingAction from '../states/Project-reporting/Project-reporting.action';
import { Logger, LoggerService } from '../services/logger.service';
import { RightsService } from '../services/rights.service';
import { LeadUtil } from '../utils/lead.util';
import { IPrincipal } from '../interfaces/principal.interface';
import { ProjectReportingViewModel } from '../apis/advis';
import { map, take, tap } from 'rxjs/operators';
import { getIdFromRouteSnapshotRecursive } from '../../lead-detail/resolvers/lead-detail.resolver';
import {
  GetProjectReportingAction,
  GetProjectReportingFailedAction,
  GetProjectReportingSnapshotsAction,
  GetProjectReportingSuccessAction,
} from '../states/Project-reporting/Project-reporting.action';
import { GetAccountAssignmentListAction } from '../states/variant/variant.action';

export interface IDetailResolve {
  isOwner: boolean;
  isResponsible: boolean;
  isDelegate: boolean;
  isReadOnly: boolean;
}

@Injectable()
export class ProjectReportingResolver {
  private logger: Logger;

  constructor(
    private store: Store<RootReducer.IState>,
    private action$: Actions,
    private router: Router,
    private loggerService: LoggerService,
    private rights: RightsService
  ) {
    this.logger = this.loggerService.create(ProjectReportingResolver.name);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IDetailResolve> {
    // Always load detail view because history may have changed.
    const id: number = getIdFromRouteSnapshotRecursive(route);
    this.store.dispatch(new GetProjectReportingAction(id));

    const success: Actions<GetProjectReportingSuccessAction> = this.action$.pipe(
      ofType(ProjectReportingAction.GET_PROJECT_REPORTING_SUCCESS)
    );
    const failure: Actions<GetProjectReportingFailedAction> = this.action$.pipe(
      ofType(ProjectReportingAction.GET_PROJECT_REPORTING_FAILED)
    );

    failure
      .pipe(
        tap(() => {
          this.logger.warn(`Failed to load lead with id "${id}"`);
          this.router.navigate(['error']);
        })
      )
      .subscribe();

    const principal$: Observable<IPrincipal> = this.store.select<IPrincipal>(
      RootReducer.getAuthPrincipal
    );
    const projectReporting$: Observable<ProjectReportingViewModel> = this.store.select(
      (storeState: RootReducer.IState) => storeState.projectReporting.ProjectReporting
    );

    return combineLatest([principal$, projectReporting$, race(success, failure)]).pipe(
      map(
        (
          value: [
            IPrincipal,
            ProjectReportingViewModel,
            (
              | ProjectReportingAction.GetProjectReportingSuccessAction
              | Actions<ProjectReportingAction.GetProjectReportingFailedAction>
            )
          ]
        ) => {
          const answer: IDetailResolve = {
            isOwner: LeadUtil.isOwner(value[1].Lead.Lead, value[0]),
            isResponsible: LeadUtil.isResponsible(value[1].Lead.Lead, value[0]),
            isDelegate: LeadUtil.isDelegate(value[1].Lead.Lead, value[0]),
            isReadOnly: this.rights.getLeadRights().isReadOnly(value[1].Lead.Lead),
          };
          this.store.dispatch(new GetProjectReportingSnapshotsAction(id));

          let accountAssignmentColumn: string = 'B';
          const selectedLang = value[1].Lead.Lead.Language.toString().toUpperCase();
          if (selectedLang === 'EN') {
            accountAssignmentColumn = 'B';
          } else {
            accountAssignmentColumn =
              selectedLang === 'DE' ? 'B' : selectedLang === 'FR' ? 'C' : 'D';
          }

          this.store.dispatch(
            new GetAccountAssignmentListAction(`select A, ${accountAssignmentColumn}, E, F`)
          );

          return answer;
        }
      ),
      take(1)
    );
  }
}
