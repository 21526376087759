/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddPositionGroupRequest } from '../model/addPositionGroupRequest';
import { AddRebateRequest } from '../model/addRebateRequest';
import { AddRegularPositionRequest } from '../model/addRegularPositionRequest';
import { AddSpecialPositionRequest } from '../model/addSpecialPositionRequest';
import { CreateEmobilityVariantRequest } from '../model/createEmobilityVariantRequest';
import { CreateHeatingVariantRequest } from '../model/createHeatingVariantRequest';
import { CreatePvVariantRequest } from '../model/createPvVariantRequest';
import { CreateSimpleTradeVariantRequest } from '../model/createSimpleTradeVariantRequest';
import { EmobilityWizardDataViewModel } from '../model/emobilityWizardDataViewModel';
import { FileLinkViewModel } from '../model/fileLinkViewModel';
import { HeatingWizardDataViewModel } from '../model/heatingWizardDataViewModel';
import { PastePositionsAndGroupsRequest } from '../model/pastePositionsAndGroupsRequest';
import { PatchEmobilityVariantRequest } from '../model/patchEmobilityVariantRequest';
import { PatchHeatingVariantRequest } from '../model/patchHeatingVariantRequest';
import { PatchPositionGroupRequest } from '../model/patchPositionGroupRequest';
import { PatchPositionRequest } from '../model/patchPositionRequest';
import { PatchPvVariantRequest } from '../model/patchPvVariantRequest';
import { PatchSimpleTradeVariantRequest } from '../model/patchSimpleTradeVariantRequest';
import { PatchVariantDynamicPropertyRequest } from '../model/patchVariantDynamicPropertyRequest';
import { PatchVariantPositionRequest } from '../model/patchVariantPositionRequest';
import { PatchVariantRequest } from '../model/patchVariantRequest';
import { PutPositionOrderRequest } from '../model/putPositionOrderRequest';
import { PvReportRequest } from '../model/pvReportRequest';
import { PvWizardViewModel } from '../model/pvWizardViewModel';
import { RebateViewModel } from '../model/rebateViewModel';
// @ts-ignore
import { ValidationErrorViewModel } from '../model/validationErrorViewModel';
import { VariantCompositeViewModel } from '../model/variantCompositeViewModel';
import { VariantPrecalculationViewModel } from '../model/variantPrecalculationViewModel';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VariantService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Patches attributes on the emobility variant.
     * 
     * @param variantId the id of the variant
     * @param body The request to patch the variant, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityVariantPatchEmobilityVariantTradeSpecific(variantId: number, body?: PatchEmobilityVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public emobilityVariantPatchEmobilityVariantTradeSpecific(variantId: number, body?: PatchEmobilityVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public emobilityVariantPatchEmobilityVariantTradeSpecific(variantId: number, body?: PatchEmobilityVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public emobilityVariantPatchEmobilityVariantTradeSpecific(variantId: number, body?: PatchEmobilityVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling emobilityVariantPatchEmobilityVariantTradeSpecific.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/em/${encodeURIComponent(String(variantId))}/trade-specific`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new Emobility variant.
     * 
     * @param leadId the id of the variant&#39;s lead
     * @param body The create request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityVariantPostEmobilityVariant(leadId: number, body?: CreateEmobilityVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public emobilityVariantPostEmobilityVariant(leadId: number, body?: CreateEmobilityVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public emobilityVariantPostEmobilityVariant(leadId: number, body?: CreateEmobilityVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public emobilityVariantPostEmobilityVariant(leadId: number, body?: CreateEmobilityVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling emobilityVariantPostEmobilityVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/em`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates variant object data and rebuilds the list of positions if rebuild not disabled.
     * 
     * @param variantId the id of the variant
     * @param body The modified object data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emobilityVariantPutEmobilityVariantObjectData(variantId: number, body?: EmobilityWizardDataViewModel, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public emobilityVariantPutEmobilityVariantObjectData(variantId: number, body?: EmobilityWizardDataViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public emobilityVariantPutEmobilityVariantObjectData(variantId: number, body?: EmobilityWizardDataViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public emobilityVariantPutEmobilityVariantObjectData(variantId: number, body?: EmobilityWizardDataViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling emobilityVariantPutEmobilityVariantObjectData.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/em/${encodeURIComponent(String(variantId))}/objectdata`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Bom Items (includes groups, regular and special positions)  &lt;param name&#x3D;\&quot;tradeTypeCode\&quot;&gt;the trade type of the variant&#39;s lead&lt;/param&gt;&lt;param name&#x3D;\&quot;variantId\&quot;&gt;the id of the variant&lt;/param&gt;&lt;param name&#x3D;\&quot;bomItemIds\&quot;&gt;A request with the comma separated ids of the groups and positions to delete&lt;/param&gt;&lt;returns&gt;&lt;/returns&gt;  /
     * 
     * @param tradeTypeCode 
     * @param variantId 
     * @param bomItemIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantDeleteBomItems(tradeTypeCode: string, variantId: number, bomItemIds?: string, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantDeleteBomItems(tradeTypeCode: string, variantId: number, bomItemIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantDeleteBomItems(tradeTypeCode: string, variantId: number, bomItemIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantDeleteBomItems(tradeTypeCode: string, variantId: number, bomItemIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantDeleteBomItems.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantDeleteBomItems.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        // @ts-ignore
        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (bomItemIds !== undefined) {
            formParams = formParams.append('bomItemIds', <any>bomItemIds) || formParams;
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/bom-items`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a dynamic property on a variant
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param propertyName the name of the property to remove
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantDeleteVariantDynamicProperty(tradeTypeCode: string, variantId: number, propertyName?: string, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantDeleteVariantDynamicProperty(tradeTypeCode: string, variantId: number, propertyName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantDeleteVariantDynamicProperty(tradeTypeCode: string, variantId: number, propertyName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantDeleteVariantDynamicProperty(tradeTypeCode: string, variantId: number, propertyName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantDeleteVariantDynamicProperty.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantDeleteVariantDynamicProperty.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (propertyName !== undefined && propertyName !== null) {
            queryParameters = queryParameters.set('propertyName', <any>propertyName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/dynamic-properties`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the variant&#39;s rebate by specified id.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param rebateId the id of the rebate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantDeleteVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantDeleteVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantDeleteVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantDeleteVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantDeleteVariantRebate.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantDeleteVariantRebate.');
        }

        if (rebateId === null || rebateId === undefined) {
            throw new Error('Required parameter rebateId was null or undefined when calling genericVariantDeleteVariantRebate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/rebates/${encodeURIComponent(String(rebateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deselect all positions in this group.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param groupId The id of the group to patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantDeselectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantDeselectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantDeselectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantDeselectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantDeselectAllPositionInThisGroup.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantDeselectAllPositionInThisGroup.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling genericVariantDeselectAllPositionInThisGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/groups/${encodeURIComponent(String(groupId))}/deselect`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a file of a variant on Azure
     * 
     * @param variantId the id of the variant
     * @param fileName name of the file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantFileDeleteFile(variantId: number, fileName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public genericVariantFileDeleteFile(variantId: number, fileName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public genericVariantFileDeleteFile(variantId: number, fileName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public genericVariantFileDeleteFile(variantId: number, fileName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantFileDeleteFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the variant customer signature.
     * 
     * @param variantId The variant identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantFileGetVariantCustomerSignature(variantId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public genericVariantFileGetVariantCustomerSignature(variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public genericVariantFileGetVariantCustomerSignature(variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public genericVariantFileGetVariantCustomerSignature(variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantFileGetVariantCustomerSignature.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/signature`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the files for a variant
     * 
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantFileGetVariantFiles(variantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FileLinkViewModel>>;
    public genericVariantFileGetVariantFiles(variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileLinkViewModel>>>;
    public genericVariantFileGetVariantFiles(variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileLinkViewModel>>>;
    public genericVariantFileGetVariantFiles(variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantFileGetVariantFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FileLinkViewModel>>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a file for a variant to azure.
     * 
     * @param variantId the id of the variant
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantFileUploadFile(variantId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public genericVariantFileUploadFile(variantId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public genericVariantFileUploadFile(variantId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public genericVariantFileUploadFile(variantId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantFileUploadFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        // @ts-ignore
        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/files`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uploads the variant customer signature.
     * 
     * @param variantId The variant identifier.
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantFileUploadVariantCustomerSignature(variantId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public genericVariantFileUploadVariantCustomerSignature(variantId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public genericVariantFileUploadVariantCustomerSignature(variantId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public genericVariantFileUploadVariantCustomerSignature(variantId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantFileUploadVariantCustomerSignature.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        // @ts-ignore
        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<string>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(variantId))}/signature`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get variant by id.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantGetVariantById(tradeTypeCode: string, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantGetVariantById(tradeTypeCode: string, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantGetVariantById(tradeTypeCode: string, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantGetVariantById(tradeTypeCode: string, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantGetVariantById.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantGetVariantById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the precalculation of a variant by Id
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantGetVariantPrecalculation(tradeTypeCode: string, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantPrecalculationViewModel>;
    public genericVariantGetVariantPrecalculation(tradeTypeCode: string, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantPrecalculationViewModel>>;
    public genericVariantGetVariantPrecalculation(tradeTypeCode: string, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantPrecalculationViewModel>>;
    public genericVariantGetVariantPrecalculation(tradeTypeCode: string, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantGetVariantPrecalculation.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantGetVariantPrecalculation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<VariantPrecalculationViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/precalculation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches a group, which could also do the reordering.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param groupId The id of the group to patch
     * @param body The requests to patch the group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchPositionGroup(tradeTypeCode: string, variantId: number, groupId: string, body?: PatchPositionGroupRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchPositionGroup(tradeTypeCode: string, variantId: number, groupId: string, body?: PatchPositionGroupRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchPositionGroup(tradeTypeCode: string, variantId: number, groupId: string, body?: PatchPositionGroupRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchPositionGroup(tradeTypeCode: string, variantId: number, groupId: string, body?: PatchPositionGroupRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchPositionGroup.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchPositionGroup.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling genericVariantPatchPositionGroup.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/groups/${encodeURIComponent(String(groupId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace all variant positions with successors if available.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param positionId The id of the positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchPositionReplaceWithSuccessor(tradeTypeCode: string, variantId: number, positionId: string, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchPositionReplaceWithSuccessor(tradeTypeCode: string, variantId: number, positionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchPositionReplaceWithSuccessor(tradeTypeCode: string, variantId: number, positionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchPositionReplaceWithSuccessor(tradeTypeCode: string, variantId: number, positionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchPositionReplaceWithSuccessor.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchPositionReplaceWithSuccessor.');
        }

        if (positionId === null || positionId === undefined) {
            throw new Error('Required parameter positionId was null or undefined when calling genericVariantPatchPositionReplaceWithSuccessor.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/position/${encodeURIComponent(String(positionId))}/replace-with-successor`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches regular and special variant positions.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body The requests to patch the positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchPositions(tradeTypeCode: string, variantId: number, body?: Array<PatchPositionRequest>, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchPositions(tradeTypeCode: string, variantId: number, body?: Array<PatchPositionRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchPositions(tradeTypeCode: string, variantId: number, body?: Array<PatchPositionRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchPositions(tradeTypeCode: string, variantId: number, body?: Array<PatchPositionRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchPositions.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchPositions.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace all variant positions with successors if available.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchPositionsReplaceWithSuccessor(tradeTypeCode: string, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchPositionsReplaceWithSuccessor(tradeTypeCode: string, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchPositionsReplaceWithSuccessor(tradeTypeCode: string, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchPositionsReplaceWithSuccessor(tradeTypeCode: string, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchPositionsReplaceWithSuccessor.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchPositionsReplaceWithSuccessor.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions/replace-with-successor`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body The patch request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchVariant(tradeTypeCode: string, variantId: number, body?: PatchVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchVariant(tradeTypeCode: string, variantId: number, body?: PatchVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchVariant(tradeTypeCode: string, variantId: number, body?: PatchVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchVariant(tradeTypeCode: string, variantId: number, body?: PatchVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchVariant.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates or creates an a dynamic property on a variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body the request with the dynamic property data, can be more than one              existing keys will be overidden with the new values, if any, old keys will stay.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchVariantDynamicProperty(tradeTypeCode: string, variantId: number, body?: Array<PatchVariantDynamicPropertyRequest>, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPatchVariantDynamicProperty(tradeTypeCode: string, variantId: number, body?: Array<PatchVariantDynamicPropertyRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPatchVariantDynamicProperty(tradeTypeCode: string, variantId: number, body?: Array<PatchVariantDynamicPropertyRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPatchVariantDynamicProperty(tradeTypeCode: string, variantId: number, body?: Array<PatchVariantDynamicPropertyRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchVariantDynamicProperty.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPatchVariantDynamicProperty.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/dynamic-properties`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches regular and special variant positions.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param body The requests to patch the positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPatchVariantPositions(tradeTypeCode: string, body?: Array<PatchVariantPositionRequest>, observe?: 'body', reportProgress?: boolean): Observable<Array<VariantCompositeViewModel>>;
    public genericVariantPatchVariantPositions(tradeTypeCode: string, body?: Array<PatchVariantPositionRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VariantCompositeViewModel>>>;
    public genericVariantPatchVariantPositions(tradeTypeCode: string, body?: Array<PatchVariantPositionRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VariantCompositeViewModel>>>;
    public genericVariantPatchVariantPositions(tradeTypeCode: string, body?: Array<PatchVariantPositionRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPatchVariantPositions.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Array<VariantCompositeViewModel>>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/positions`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Forces the variant positions rebuild.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostForceRebuild(tradeTypeCode: string, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostForceRebuild(tradeTypeCode: string, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostForceRebuild(tradeTypeCode: string, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostForceRebuild(tradeTypeCode: string, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostForceRebuild.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostForceRebuild.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/rebuild`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a new group to the variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body The add group request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostPositionGroup(tradeTypeCode: string, variantId: number, body?: AddPositionGroupRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostPositionGroup(tradeTypeCode: string, variantId: number, body?: AddPositionGroupRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostPositionGroup(tradeTypeCode: string, variantId: number, body?: AddPositionGroupRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostPositionGroup(tradeTypeCode: string, variantId: number, body?: AddPositionGroupRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostPositionGroup.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostPositionGroup.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/groups`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a list of new groups, regular and special positions to the variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body An array of requests to add regular positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostPositionsAndGroups(tradeTypeCode: string, variantId: number, body?: PastePositionsAndGroupsRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostPositionsAndGroups(tradeTypeCode: string, variantId: number, body?: PastePositionsAndGroupsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostPositionsAndGroups(tradeTypeCode: string, variantId: number, body?: PastePositionsAndGroupsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostPositionsAndGroups(tradeTypeCode: string, variantId: number, body?: PastePositionsAndGroupsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostPositionsAndGroups.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostPositionsAndGroups.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions-and-groups`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a list of new regular positions to the variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body An array of requests to add regular positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostRegularPositions(tradeTypeCode: string, variantId: number, body?: Array<AddRegularPositionRequest>, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostRegularPositions(tradeTypeCode: string, variantId: number, body?: Array<AddRegularPositionRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostRegularPositions(tradeTypeCode: string, variantId: number, body?: Array<AddRegularPositionRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostRegularPositions(tradeTypeCode: string, variantId: number, body?: Array<AddRegularPositionRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostRegularPositions.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostRegularPositions.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions/regular`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a list of new special positions to the variant.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body An array of requests to add special positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostSpecialPositions(tradeTypeCode: string, variantId: number, body?: Array<AddSpecialPositionRequest>, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostSpecialPositions(tradeTypeCode: string, variantId: number, body?: Array<AddSpecialPositionRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostSpecialPositions(tradeTypeCode: string, variantId: number, body?: Array<AddSpecialPositionRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostSpecialPositions(tradeTypeCode: string, variantId: number, body?: Array<AddSpecialPositionRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostSpecialPositions.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostSpecialPositions.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions/special`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pulls the pricing information for non-custom positions from blue office.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostUpdatePrices(tradeTypeCode: string, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostUpdatePrices(tradeTypeCode: string, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostUpdatePrices(tradeTypeCode: string, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostUpdatePrices(tradeTypeCode: string, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostUpdatePrices.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostUpdatePrices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/updateprices`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing discount. We&#39;ll check if the user is allowed to perform this operation.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body the request with the rebate data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPostVariantRebate(tradeTypeCode: string, variantId: number, body?: AddRebateRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPostVariantRebate(tradeTypeCode: string, variantId: number, body?: AddRebateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPostVariantRebate(tradeTypeCode: string, variantId: number, body?: AddRebateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPostVariantRebate(tradeTypeCode: string, variantId: number, body?: AddRebateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPostVariantRebate.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPostVariantRebate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/rebates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an existing discount. We&#39;ll check if the user is allowed to perform this operation.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param rebateId the id of the rebate
     * @param body the rebate model to save
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantPutVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, body?: RebateViewModel, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantPutVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, body?: RebateViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantPutVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, body?: RebateViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantPutVariantRebate(tradeTypeCode: string, variantId: number, rebateId: number, body?: RebateViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantPutVariantRebate.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantPutVariantRebate.');
        }

        if (rebateId === null || rebateId === undefined) {
            throw new Error('Required parameter rebateId was null or undefined when calling genericVariantPutVariantRebate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/rebates/${encodeURIComponent(String(rebateId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Select all positions in this group.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param groupId The id of the group to patch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantSelectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantSelectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantSelectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantSelectAllPositionInThisGroup(tradeTypeCode: string, variantId: number, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantSelectAllPositionInThisGroup.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantSelectAllPositionInThisGroup.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling genericVariantSelectAllPositionInThisGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/groups/${encodeURIComponent(String(groupId))}/select`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches the order and group of regular and special positions.  Be aware that the order sent will not necessarily be the one received in the response.
     * 
     * @param tradeTypeCode the trade type of the variant&#39;s lead
     * @param variantId the id of the variant
     * @param body The requests to reorder positions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericVariantUpdatePositionsOrder(tradeTypeCode: string, variantId: number, body?: PutPositionOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public genericVariantUpdatePositionsOrder(tradeTypeCode: string, variantId: number, body?: PutPositionOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public genericVariantUpdatePositionsOrder(tradeTypeCode: string, variantId: number, body?: PutPositionOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public genericVariantUpdatePositionsOrder(tradeTypeCode: string, variantId: number, body?: PutPositionOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling genericVariantUpdatePositionsOrder.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling genericVariantUpdatePositionsOrder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/positions/order`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the heating variant.
     * 
     * @param variantId the id of the variant
     * @param body The request to patch the variant, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingVariantPatchHeatingVariantTradeSpecific(variantId: number, body?: PatchHeatingVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public heatingVariantPatchHeatingVariantTradeSpecific(variantId: number, body?: PatchHeatingVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public heatingVariantPatchHeatingVariantTradeSpecific(variantId: number, body?: PatchHeatingVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public heatingVariantPatchHeatingVariantTradeSpecific(variantId: number, body?: PatchHeatingVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling heatingVariantPatchHeatingVariantTradeSpecific.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/ht/${encodeURIComponent(String(variantId))}/trade-specific`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new heating variant.
     * 
     * @param leadId the id of the variant&#39;s lead
     * @param body The create request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingVariantPostHeatingVariant(leadId: number, body?: CreateHeatingVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public heatingVariantPostHeatingVariant(leadId: number, body?: CreateHeatingVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public heatingVariantPostHeatingVariant(leadId: number, body?: CreateHeatingVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public heatingVariantPostHeatingVariant(leadId: number, body?: CreateHeatingVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling heatingVariantPostHeatingVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/ht`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates variant object data and rebuilds the list of positions if rebuild not disabled.
     * 
     * @param variantId the id of the variant
     * @param body The modified object data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public heatingVariantPutHeatingVariantObjectData(variantId: number, body?: HeatingWizardDataViewModel, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public heatingVariantPutHeatingVariantObjectData(variantId: number, body?: HeatingWizardDataViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public heatingVariantPutHeatingVariantObjectData(variantId: number, body?: HeatingWizardDataViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public heatingVariantPutHeatingVariantObjectData(variantId: number, body?: HeatingWizardDataViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling heatingVariantPutHeatingVariantObjectData.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/ht/${encodeURIComponent(String(variantId))}/objectdata`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Calculates the price table report, i.e.  different variant summaries for different values of roof area, given the variant and lead data  Returns a CSV consisting of the variant summaries
     * 
     * @param body Variant and Lead data to calculate the report, as well as roof area information
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantCalculatePriceTableReport(body?: PvReportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pvVariantCalculatePriceTableReport(body?: PvReportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pvVariantCalculatePriceTableReport(body?: PvReportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pvVariantCalculatePriceTableReport(body?: PvReportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/pc/variants/pv/report`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the pv variant as well as the objectdata.  If and only if IsLocked on the object data is set to false, the product builder will be triggered.
     * 
     * @param variantId the id of the variant
     * @param body The request to patch the objectdata, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantPatchPvVariantTradeSpecific(variantId: number, body?: PatchPvVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public pvVariantPatchPvVariantTradeSpecific(variantId: number, body?: PatchPvVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public pvVariantPatchPvVariantTradeSpecific(variantId: number, body?: PatchPvVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public pvVariantPatchPvVariantTradeSpecific(variantId: number, body?: PatchPvVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pvVariantPatchPvVariantTradeSpecific.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/pv/${encodeURIComponent(String(variantId))}/trade-specific`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantPostPvVariant(leadId: number, body?: CreatePvVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public pvVariantPostPvVariant(leadId: number, body?: CreatePvVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public pvVariantPostPvVariant(leadId: number, body?: CreatePvVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public pvVariantPostPvVariant(leadId: number, body?: CreatePvVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pvVariantPostPvVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/pv`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates module kwp and recalculate all dependency properties.
     * 
     * @param variantId the id of the variant
     * @param body the new module kwp
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantPutPvModuleKwpAndRecalculation(variantId: number, body?: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public pvVariantPutPvModuleKwpAndRecalculation(variantId: number, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public pvVariantPutPvModuleKwpAndRecalculation(variantId: number, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public pvVariantPutPvModuleKwpAndRecalculation(variantId: number, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pvVariantPutPvModuleKwpAndRecalculation.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/pv/${encodeURIComponent(String(variantId))}/moduleKwpAndRecalculation`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates number of modules and recalculate all dependency properties.
     * 
     * @param variantId the id of the variant
     * @param body the new number of modules
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantPutPvNumberOfModulesAndRecalculation(variantId: number, body?: number, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public pvVariantPutPvNumberOfModulesAndRecalculation(variantId: number, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public pvVariantPutPvNumberOfModulesAndRecalculation(variantId: number, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public pvVariantPutPvNumberOfModulesAndRecalculation(variantId: number, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pvVariantPutPvNumberOfModulesAndRecalculation.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/pv/${encodeURIComponent(String(variantId))}/numberOfModulesAndRecalculation`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates variant wizard data (object data and some variant data) and rebuilds the list of positions if rebuild not disabled.
     * 
     * @param variantId the id of the variant
     * @param body The modified wizard data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pvVariantPutPvVariantObjectData(variantId: number, body?: PvWizardViewModel, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public pvVariantPutPvVariantObjectData(variantId: number, body?: PvWizardViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public pvVariantPutPvVariantObjectData(variantId: number, body?: PvWizardViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public pvVariantPutPvVariantObjectData(variantId: number, body?: PvWizardViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pvVariantPutPvVariantObjectData.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/pv/${encodeURIComponent(String(variantId))}/wizarddata`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patches attributes on the simple trade variant.
     * 
     * @param variantId the id of the variant
     * @param tradeTypeCode The trade type code for this new variant
     * @param body The request to patch the variant, containing all the parameters to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public simpleTradeVariantPatchSimpleTradeVariantTradeSpecific(variantId: number, tradeTypeCode: string, body?: PatchSimpleTradeVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public simpleTradeVariantPatchSimpleTradeVariantTradeSpecific(variantId: number, tradeTypeCode: string, body?: PatchSimpleTradeVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public simpleTradeVariantPatchSimpleTradeVariantTradeSpecific(variantId: number, tradeTypeCode: string, body?: PatchSimpleTradeVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public simpleTradeVariantPatchSimpleTradeVariantTradeSpecific(variantId: number, tradeTypeCode: string, body?: PatchSimpleTradeVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling simpleTradeVariantPatchSimpleTradeVariantTradeSpecific.');
        }

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling simpleTradeVariantPatchSimpleTradeVariantTradeSpecific.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<VariantCompositeViewModel>(`${this.basePath}/api/pc/variants/${encodeURIComponent(String(tradeTypeCode))}/${encodeURIComponent(String(variantId))}/trade-specific`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new simple trade variant.
     * 
     * @param leadId the id of the variant&#39;s lead
     * @param tradeTypeCode The trade type code for this new variant
     * @param body The create request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public simpleTradeVariantPostSimpleTradeVariant(leadId: number, tradeTypeCode: string, body?: CreateSimpleTradeVariantRequest, observe?: 'body', reportProgress?: boolean): Observable<VariantCompositeViewModel>;
    public simpleTradeVariantPostSimpleTradeVariant(leadId: number, tradeTypeCode: string, body?: CreateSimpleTradeVariantRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VariantCompositeViewModel>>;
    public simpleTradeVariantPostSimpleTradeVariant(leadId: number, tradeTypeCode: string, body?: CreateSimpleTradeVariantRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VariantCompositeViewModel>>;
    public simpleTradeVariantPostSimpleTradeVariant(leadId: number, tradeTypeCode: string, body?: CreateSimpleTradeVariantRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling simpleTradeVariantPostSimpleTradeVariant.');
        }

        if (tradeTypeCode === null || tradeTypeCode === undefined) {
            throw new Error('Required parameter tradeTypeCode was null or undefined when calling simpleTradeVariantPostSimpleTradeVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VariantCompositeViewModel>(`${this.basePath}/api/pc/leads/${encodeURIComponent(String(leadId))}/variants/${encodeURIComponent(String(tradeTypeCode))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
