import * as LeadProjectActions from './lead-project.action';
import * as LeadDocumentActions from '../lead-document/lead-document.action';
import { tassign } from 'tassign';
import {
  ProjectChecklistTemplateViewModel,
  ProjectChecklistViewModel,
} from 'app/shared/apis/advis';
import { ISopChecklistViewModel } from './lead-project.effects';

export interface IState {
  loading: boolean;
  projectSopChecklist: ISopChecklistViewModel;
  projectSopChecklistLoading: boolean;
  projectSopChecklistLoaded: boolean;
  projectChecklist: ProjectChecklistViewModel;
  projectChecklistLoading: boolean;
  projectChecklistLoaded: boolean;
  projectChecklistTemplates: ProjectChecklistTemplateViewModel[];
  projectChecklistTemplatesLoaded: boolean;
  projectChecklistTemplatesLoading: boolean;
}

export const initialState: IState = {
  loading: false,
  projectSopChecklist: {},
  projectSopChecklistLoading: false,
  projectSopChecklistLoaded: false,
  projectChecklist: {},
  projectChecklistTemplates: [],
  projectChecklistLoaded: false,
  projectChecklistLoading: false,
  projectChecklistTemplatesLoaded: false,
  projectChecklistTemplatesLoading: false,
};

export function reducer(state: IState = initialState, action: LeadProjectActions.Actions): IState {
  switch (action.type) {
    case LeadProjectActions.PATCH_PROJECT_DOCUMENTATION:
    case LeadProjectActions.DELETE_PROJECT_DOCUMENTATION:
    case LeadDocumentActions.GET_DOCUMENTS:
      return { ...state, loading: true };
    case LeadProjectActions.DELETE_PROJECT_DOCUMENTATION_SUCCESS:
    case LeadProjectActions.DELETE_PROJECT_DOCUMENTATION_FAILED:
    case LeadProjectActions.PATCH_PROJECT_SUCCESS:
    case LeadProjectActions.PATCH_PROJECT_FAILED:
    case LeadDocumentActions.GET_DOCUMENTS_SUCCESS:
    case LeadDocumentActions.GET_DOCUMENTS_FAILED:
      return { ...state, loading: false };
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST:
      return tassign(state, {
        projectChecklistLoading: true,
      });
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST_SUCCESS:
      return tassign(state, {
        projectChecklistLoading: false,
        projectChecklistLoaded: true,
        projectChecklist: (action as LeadProjectActions.ProjectChecklistLoadSuccessAction).payload,
      });
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST_FAILURE:
      return tassign(state, {
        projectChecklist: {},
        projectChecklistLoading: false,
        projectChecklistLoaded: true,
      });
    case LeadProjectActions.LOAD_PROJECT_SOP_CHECKLIST:
      return tassign(state, {
        projectSopChecklistLoading: true,
      });
    case LeadProjectActions.LOAD_PROJECT_SOP_CHECKLIST_SUCCESS:
      return tassign(state, {
        projectSopChecklistLoading: false,
        projectSopChecklistLoaded: true,
        projectSopChecklist: (action as LeadProjectActions.ProjectSopChecklistLoadSuccessAction)
          .payload,
      });
    case LeadProjectActions.LOAD_PROJECT_SOP_CHECKLIST_FAILURE:
      return tassign(state, {
        projectChecklist: {},
        projectChecklistLoading: false,
        projectChecklistLoaded: true,
      });
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST_TEMPLATES:
      return tassign(state, {
        projectChecklistTemplatesLoading: true,
      });
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST_TEMPLATES_SUCCESS:
      return tassign(state, {
        projectChecklistTemplatesLoading: false,
        projectChecklistTemplatesLoaded: true,
        projectChecklistTemplates: (
          action as LeadProjectActions.ProjectChecklistTemplatesLoadSuccessAction
        ).payload,
      });
    case LeadProjectActions.LOAD_PROJECT_CHECKLIST_TEMPLATES_FAILURE:
      return tassign(state, {
        projectChecklistTemplatesLoading: false,
        projectChecklistTemplatesLoaded: false,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP:
      return tassign(state, {
        projectChecklistLoaded: false,
        projectChecklistLoading: true,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_PHASE:
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_STEP:
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP:
      return tassign(state, {
        projectSopChecklistLoaded: false,
        projectSopChecklistLoading: true,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_SUCCESS:
      return tassign(state, {
        projectChecklistLoaded: true,
        projectChecklistLoading: false,
        projectChecklist: (action as LeadProjectActions.ProjectChecklistClickStepSuccessAction)
          .payload,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_SUCCESS:
      return tassign(state, {
        projectSopChecklistLoaded: true,
        projectSopChecklistLoading: false,
        projectSopChecklist: (
          action as LeadProjectActions.ProjectChecklistClickStepWithSopSuccessAction
        ).payload,
      });

    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_PHASE_SUCCESS:
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_STEP_SUCCESS:
      return tassign(state, {
        projectSopChecklistLoaded: true,
        projectSopChecklistLoading: false,
        projectSopChecklist: (action as LeadProjectActions.ProjectChecklistClickStepSuccessAction)
          .payload,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_FAILURE:
      return tassign(state, {
        projectChecklistLoading: false,
        projectChecklistLoaded: false,
      });
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_PHASE_FAILURE:
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_STEP_FAILURE:
    case LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP_FAILURE:
      return tassign(state, {
        projectSopChecklistLoading: false,
        projectSopChecklistLoaded: false,
      });
    case LeadProjectActions.PATCH_PROJECT_CHECKLIST_PHASE_SUCCESS:
      return tassign(state, {
        projectChecklistLoaded: true,
        projectChecklistLoading: false,
        projectChecklist: (action as LeadProjectActions.ProjectChecklistPatchPhaseSuccessAction)
          .payload,
      });
    case LeadProjectActions.CREATE_PROJECT_CHECKLIST_SUCCESS:
      return tassign(state, {
        projectChecklist: (action as LeadProjectActions.ProjectChecklistCreateSuccessAction)
          .payload,
      });
    default:
      return state;
  }
}

export const getLoading: (state: IState) => boolean = (state: IState) => state.loading;

export const getProjectChecklist: (state: IState) => ProjectChecklistViewModel = (state: IState) =>
  state.projectChecklist;

export const getSopProjectChecklist: (state: IState) => ISopChecklistViewModel = (state: IState) =>
  state.projectSopChecklist;

export const getProjectChecklistLoading: (state: IState) => boolean = (state: IState) =>
  state.projectChecklistLoading;

export const getProjectSopChecklistLoading: (state: IState) => boolean = (state: IState) =>
  state.projectSopChecklistLoading;
export const getProjectChecklistLoaded: (state: IState) => boolean = (state: IState) =>
  state.projectChecklistLoaded;
export const getProjectSopChecklistLoaded: (state: IState) => boolean = (state: IState) =>
  state.projectSopChecklistLoaded;

export const getProjectChecklistTemplates: (
  state: IState
) => ProjectChecklistTemplateViewModel[] = (state: IState) => state.projectChecklistTemplates;

export const getProjectChecklistTemplatesLoading: (state: IState) => boolean = (state: IState) =>
  state.projectChecklistTemplatesLoading;

export const getProjectChecklistTemplatesLoaded: (state: IState) => boolean = (state: IState) =>
  state.projectChecklistTemplatesLoaded;
