<div fxLayout="column" fxLayoutGap="20px">
  <form #form="ngForm">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="flex-col">
        <pc-company-name-typeahead
          class="width-100"
          label="{{ 'ADDRESS.COMPANY' | translate }}"
          [value]="company.Name"
          (updateName)="onCompanyNameUpdate($event)">
        </pc-company-name-typeahead>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.STREET' | translate }}"
          type="text"
          name="street"
          [maxlength]="requiresStrictValidation ? 30 : 50"
          [required]="isObjectContact"
          [(ngModel)]="company.Street">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CARE_OF_NAME' | translate }}"
          type="text"
          name="careOfName"
          maxlength="40"
          [(ngModel)]="company.CareOfName">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.COMPANY_ADDITIONAL_NAME' | translate }}"
          type="text"
          maxlength="100"
          name="additionalName"
          [(ngModel)]="company.AdditionalName">
        </helion-ui-input>

        <div
          *ngFor="let additionalEmail of company.Emails; let index = index"
          class="width-100"
          fxLayout="column"
          fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start end">
            <helion-ui-input
              #email
              fxFlex="50"
              placeholder="{{
                (additionalEmail.EmailType === emailTypeEnum.Primary
                  ? 'ADDRESS.PRIMARY_EMAIL'
                  : 'ADDRESS.ADDITIONAL_EMAIL'
                ) | translate
              }}"
              email="true"
              type="email"
              maxlength="100"
              name="additionalEmail{{ index }}"
              [(ngModel)]="additionalEmail.Email">
            </helion-ui-input>
            <helion-ui-select
              fxFlex="100"
              [name]="'additionalEmailType' + index"
              [ngModel]="selectedEmailItems[index]"
              (ngModelChange)="setAdditionalEmailType(index, $event)">
              <helion-ui-option
                *ngFor="let emailType of emailTypes"
                [value]="emailType"
                [label]="emailType.Translation">
              </helion-ui-option>
            </helion-ui-select>
            <helion-ui-icon
              class="select-icon"
              style="align-items: center"
              data-feather="x-circle"
              (click)="removeEmail(company, index)">
            </helion-ui-icon>
          </div>
          <helion-ui-error
            [hidden]="email.valid || !email.touched"
            [message]="'COMMON.VALIDATION.INVALID_EMAIL_ADDRESS' | translate">
          </helion-ui-error>
        </div>
      </div>

      <div class="flex-col">
        <helion-ui-input
          placeholder="{{ 'ADDRESS.ZIP' | translate }}"
          type="text"
          [maxlength]="requiresStrictValidation ? 10 : 30"
          name="zip"
          [required]="isObjectContact"
          [(ngModel)]="company.ZipCode">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.CITY' | translate }}"
          type="text"
          maxlength="30"
          name="city"
          [required]="isObjectContact"
          [(ngModel)]="company.City">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.POST_OFFICE_BOX' | translate }}"
          type="text"
          name="postOfficeBox"
          maxlength="30"
          [(ngModel)]="company.PostOfficeBox">
        </helion-ui-input>

        <helion-ui-input
          placeholder="{{ 'ADDRESS.THIRD_PARTY_CUSTOMER_NUMBER' | translate }}"
          type="text"
          maxlength="50"
          name="thirdPartyCustomerNumber"
          [(ngModel)]="company.ThirdPartyCustomerNumber"
          data-cy="thirdPartyCustomerNumber">
        </helion-ui-input>

        <div
          *ngFor="let additionalPhone of company.Phones; let index = index"
          class="width-100"
          fxLayout="row"
          fxLayoutAlign="start end"
          fxLayoutGap="10px">
          <helion-ui-input
            fxFlex="50"
            placeholder="{{
              (additionalPhone.PhoneType === phoneTypeEnum.Primary
                ? 'ADDRESS.PRIMARY_PHONE'
                : 'ADDRESS.ADDITIONAL_PHONE'
              ) | translate
            }}"
            name="additionalPhone{{ index }}"
            [(ngModel)]="additionalPhone.PhoneNumber">
          </helion-ui-input>
          <helion-ui-select
            fxFlex="100"
            [name]="'additionalPhoneType' + index"
            [ngModel]="selectedPhoneItems[index]"
            (ngModelChange)="setAdditionalPhoneType(index, $event)">
            <helion-ui-option
              *ngFor="let phoneType of phoneTypes"
              [value]="phoneType"
              [label]="phoneType.Translation">
            </helion-ui-option>
          </helion-ui-select>
          <helion-ui-icon
            class="select-icon"
            style="align-items: center"
            data-feather="x-circle"
            (click)="removePhone(company, index)">
          </helion-ui-icon>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)" class="contact-form__block">
        <div fxLayout="column">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.NOTES' | translate }}</h4>
          <textarea
            class="textarea"
            placeholder="{{ 'ADDRESS.TEXT' | translate }}"
            rows="8"
            cols="40"
            maxlength="2000"
            name="notes"
            [(ngModel)]="company.Notes"></textarea>
        </div>
      </div>

      <div
        *ngIf="logoUrl"
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 16px)"
        class="contact-form__block">
        <div fxLayout="column" class="contact-form__image">
          <h4 class="contact-form__image-title">{{ 'ADDRESS.LOGO' | translate }}</h4>
          <img [alt]="logoUrl" [src]="sanitizer.bypassSecurityTrustUrl(logoUrl)" class="img" />
          <button
            [attr.fill-type]="'icon'"
            [attr.aria-label]="'COMMON.ACTION.DELETE' | translate"
            [tooltip]="'COMMON.ACTION.DELETE' | translate"
            (click)="logoDelete.emit()">
            <helion-ui-icon data-feather="trash"></helion-ui-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
