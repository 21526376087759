import * as ProjectReporting from './Project-reporting.action';
import { tassign } from 'tassign';
import { ProjectReportingViewModel } from '../../apis/advis/model/projectReportingViewModel';
import { ProjectReportingSnapshotViewModel } from '../../apis/advis';

export interface IState {
  ProjectReporting: ProjectReportingViewModel;
  ProjectReportingSnapshots: ProjectReportingSnapshotViewModel[];
  ProjectReportingLoading: boolean;
  ProjectReportingSnapshotsLoading: boolean;
  ProjectReportingSnapshotsAddSuccess: boolean;
  ProjectReportingSnapshotsAddFailed: boolean;
}

export const initialState: IState = {
  ProjectReporting: undefined,
  ProjectReportingSnapshots: [],
  ProjectReportingLoading: false,
  ProjectReportingSnapshotsLoading: false,
  ProjectReportingSnapshotsAddSuccess: false,
  ProjectReportingSnapshotsAddFailed: false,
};

export function reducer(state: IState = initialState, action: ProjectReporting.Actions): IState {
  switch (action.type) {
    case ProjectReporting.GET_PROJECT_REPORTING: {
      return tassign(state, {
        ProjectReportingLoading: true,
      });
    }
    case ProjectReporting.PUT_FINANCES_INCOME_ACCOUNT_SUCCESS:
    case ProjectReporting.PUT_FINANCES_TIME_SUCCESS:
    case ProjectReporting.RESET_FINANCES_TIME_SUCCESS:
    case ProjectReporting.GET_PROJECT_REPORTING_SUCCESS: {
      return tassign(state, {
        ProjectReporting: action.payload,
        ProjectReportingLoading: false,
      });
    }
    case ProjectReporting.GET_PROJECT_REPORTING_FAILED: {
      return tassign(state, {
        ProjectReporting: undefined,
        ProjectReportingLoading: false,
      });
    }

    case ProjectReporting.GET_PROJECT_REPORTING_SNAPSHOTS: {
      return tassign(state, {
        ProjectReportingSnapshotsLoading: true,
      });
    }
    case ProjectReporting.GET_PROJECT_REPORTING_SNAPSHOTS_SUCCESS: {
      return tassign(state, {
        ProjectReportingSnapshots: action.payload,
        ProjectReportingSnapshotsLoading: false,
      });
    }
    case ProjectReporting.GET_PROJECT_REPORTING_SNAPSHOTS_FAILED: {
      return tassign(state, {
        ProjectReportingSnapshots: undefined,
        ProjectReportingSnapshotsLoading: false,
      });
    }

    case ProjectReporting.ADD_PROJECT_REPORTING_SNAPSHOT_SUCCESS: {
      return tassign(state, {
        ProjectReportingSnapshots: action.payload,
        ProjectReportingSnapshotsAddSuccess: true,
      });
    }

    case ProjectReporting.ADD_PROJECT_REPORTING_SNAPSHOT_FAILED: {
      return tassign(state, {
        ProjectReportingSnapshots: undefined,
        ProjectReportingSnapshotsAddFailed: true,
      });
    }

    case ProjectReporting.ADD_PROJECT_REPORTING_SNAPSHOT_RESET_STATE: {
      return tassign(state, {
        ProjectReportingSnapshotsAddSuccess: false,
        ProjectReportingSnapshotsAddFailed: false,
      });
    }

    default: {
      return state;
    }
  }
}

export const getProjectReporting: (state: IState) => ProjectReportingViewModel = (state: IState) =>
  state.ProjectReporting;
export const getProjectReportingSnapshots: (
  state: IState
) => ProjectReportingSnapshotViewModel[] = (state: IState) => state.ProjectReportingSnapshots;
export const getProjectReportingLoading: (state: IState) => boolean = (state: IState) =>
  state.ProjectReportingLoading;
export const getProjectReportingSnapshotsLoading: (state: IState) => boolean = (state: IState) =>
  state.ProjectReportingSnapshotsLoading;
