<section class="dialog">
  <h2 class="dialog-title">
    {{ 'LEAD_PERSON.COMPLEXITY_TYPE_PLACEHOLDER' | translate }}
  </h2>

  <div class="dialog-content">
    <pc-lead-complexity-type-selector
      *ngIf="tradeType"
      [tradeType]="tradeType"
      [selectedComplexityType]="selectedComplexityTypes"
      (selectedComplexityTypeChange)="
        selectedComplexityTypes = $event
      "></pc-lead-complexity-type-selector>
  </div>

  <div class="dialog-actions">
    <button type="button" (click)="onCancel()">{{ 'COMMON.BTN_CANCEL' | translate }}</button>
    <button
      [attr.fill-type]="'contained'"
      type="button"
      [disabled]="!selectedComplexityTypes"
      (click)="onOk()">
      {{ 'COMMON.BTN_OK' | translate }}
    </button>
  </div>
</section>
