import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AdditionalAddressTypeViewModel,
  DirectoryCompanyViewModel,
  LeadContactViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseContactFormDirective } from '../../../lead/common/base/BaseContactFormDirective';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { AddressBookBackendService } from '../../../shared/services/address-book-backend.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { PersonContactFormComponent } from '../../lead-address/address-book-contact/person-contact/person-contact-form/person-contact-form.component';
import {
  ExternManagedAddressTypeUtils,
  IExternManagedAddressTypeConfig,
} from '../../../shared/utils/exter-managed-address-type.util';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';
import { EnumService } from '../../../shared/services/enum.service';

export interface IEditCompanyData {
  dialogTitle?: string;
  isNew?: boolean;
  contactId: number;
  company: DirectoryCompanyViewModel;
  mandatorId: number;
  addressType: LeadContactViewModel.AddressTypeEnum;
}

@Component({
  selector: 'pc-edit-company-contact-dialog',
  templateUrl: './edit-company-contact-dialog.component.html',
  styleUrls: ['./edit-company-contact-dialog.component.scss'],
})
export class EditCompanyContactDialogComponent
  extends BaseContactFormDirective
  implements OnInit, OnDestroy
{
  @ViewChild('companyContactForm', { static: true })
  public companyContactFormComponent: PersonContactFormComponent;

  public company: DirectoryCompanyViewModel;
  public addressType: AddressTypeEnum;
  public emailTypes: AdditionalAddressTypeViewModel[];
  public modalName: string = EditCompanyContactDialogComponent.name;
  public isNew: boolean;
  public sharedAddressBookMandatorIds: number[];

  private allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[];

  constructor(
    private dialogRef: DialogRef,
    private addressBookService: AddressBookBackendService,
    private store: Store<RootReducer.IState>,
    @Inject(DIALOG_DATA) private data: IEditCompanyData,
    dialogService: DialogService,
    loadingModalService: LoadingModalService,
    notification: NotificationService,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(sanitizer, translate, dialogService, notification, loadingModalService, enumService);
    this.company = this.data.company;
    this.addressType = this.data.addressType;
    this.isNew = this.data.isNew;

    this.subscriptions.add(
      this.store.select(RootReducer.getAllExternalManagedAddressConfigState).subscribe(x => {
        this.allExternalManagedAddressConfig = x;
      })
    );
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select<MasterDataViewModel>(RootReducer.getGlobalMasterData)
        .subscribe(masterData => {
          this.phoneTypes = masterData.AdditionalPhoneTypes;
          this.emailTypes = masterData.AdditionalEmailTypes;
          this.sharedAddressBookMandatorIds = masterData.Mandators.find(
            x => x.Id === this.data.mandatorId
          ).SharedAddressBookMandatorIds;
        })
    );
    super.ngOnInit();
  }

  public onCloseClick(): void {
    this.dialogRef.close(undefined);
  }

  public saveDisabled(): boolean {
    return this.company.Name.length === 0 || !this.companyContactFormComponent?.ngForm?.valid;
  }

  protected saveEntry(): void {
    const isExternalManaged = ExternManagedAddressTypeUtils.isAddressTypeExternalManaged(
      this.addressType,
      this.allExternalManagedAddressConfig
    );
    this.loadingModalService.openModal(this.modalName);
    if (this.data.isNew && !isExternalManaged) {
      this.subscriptions.add(
        this.addressBookService
          .addCompany(
            this.data.mandatorId,
            this.sharedAddressBookMandatorIds,
            this.company,
            this.addressType
          )
          .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
      );
    } else if (this.data.isNew && isExternalManaged) {
      this.subscriptions.add(
        this.addressBookService
          .addCompanyAsExternalManaged(
            this.data.mandatorId,
            this.sharedAddressBookMandatorIds,
            this.company,
            this.addressType
          )
          .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
      );
    } else {
      this.subscriptions.add(
        this.addressBookService
          .updateCompany(this.company.Id, this.company, this.data.contactId)
          .subscribe(X => this.saveResult(X, this.modalName, this.dialogRef))
      );
    }
  }
}
