import { Injectable } from '@angular/core';
import { LoadingModalComponent } from '../../common-components/loading-modal/loading-modal.component';
import { ModalLoaderIdTypesEnum } from '../models/enum/modal-loader-id-types.enum';

@Injectable()
export class LoadingModalService {
  private modals: LoadingModalComponent[] = [];

  isModalOpen: boolean = false;

  addModal(modal: LoadingModalComponent): void {
    this.modals.push(modal);
  }

  removeModal(id: any): void {
    this.modals = this.modals.filter((modal: LoadingModalComponent) => modal.id !== id);
  }

  openModal(id: any = ModalLoaderIdTypesEnum.MAIN_LOADING_MODAL, progressText?: string): void {
    const modal: LoadingModalComponent = this.findModalById(id);
    if (modal) {
      this.isModalOpen = true;
      if (progressText) {
        modal.progressText = progressText;
      }
      modal.openModal();
    }
  }

  closeModal(id: any = ModalLoaderIdTypesEnum.MAIN_LOADING_MODAL): void {
    const modal: LoadingModalComponent = this.findModalById(id);
    if (modal) {
      this.isModalOpen = false;
      modal.progressText = undefined;
      modal.closeModal();
    }
  }

  closeModalAll(id?: any): void {
    if (id) {
      this.closeAllWithId(id);
    } else {
      this.closeAll();
    }
  }

  private closeAllWithId(id: any): void {
    this.modals = this.modals.filter((modal: LoadingModalComponent) => {
      if (modal.id === id) {
        this.isModalOpen = false;
        modal.closeModal();
      }
      return modal;
    });
  }

  private closeAll(): void {
    let closeModals: LoadingModalComponent[] = [...this.modals];
    closeModals = closeModals.map((modal: LoadingModalComponent) => {
      this.isModalOpen = false;
      modal.closeModal();
      return modal;
    });
    this.modals = closeModals;
  }

  private findModalById(id: string): LoadingModalComponent {
    return this.modals.find((modal: LoadingModalComponent) => modal.id === id);
  }
}
