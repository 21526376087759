<helion-ui-card *ngIf="note">
  <div content class="d-flex flex-column">
    <ng-container *ngIf="isEditMode || editOnly">
      <div class="pc-note-item-created sub-title">
        {{ note.Created ? (note.Created | UtcToLocalTime) + ' - ' + note.CreatedUser : '' }}
      </div>

      <ng-container *ngIf="note.NoteType === noteTypeEnum.TEXT">
        <div class="margin-bottom-20 d-flex">
          <helion-ui-select
            selectLook="white"
            class="template-dropdown flex-50"
            placeholder="{{ 'VARIANT_OFFER_DOWNLOAD.CHOOSE_TEMPLATE' | translate }}"
            [ngModel]="selectedNoteTemplate?.Title"
            (ngModelChange)="selectNoteTemplate($event)">
            <helion-ui-option
              *ngFor="let noteTemplate of individualNoteTemplates"
              [value]="noteTemplate.Title"
              [label]="noteTemplate.Title">
            </helion-ui-option>

            <helion-ui-option
              *ngFor="let noteTemplate of sharedNoteTemplates"
              [value]="noteTemplate.Title"
              [label]="noteTemplate.Title">
            </helion-ui-option>
          </helion-ui-select>
        </div>

        <!--
        <pc-quill-editor
          [(text)]="note.Text"
          (autoSave)="!isNew && save($event)"
          (textChange)="onTextNoteChange()"
          [placeholder]="'LEAD_NOTE.TEXT' | translate"
          [cyAttr]="'leadEditTextareaNoteText'"
          [needFocus]="true">
        </pc-quill-editor>
        -->

        <!-- CKEDITOR -->
        <pc-ck-editor
          [disabled]="false"
          [initialData]="note.Text"
          (textChange)="onTextNoteChange($event)"
          (autoSave)="!isNew && save($event)">
        </pc-ck-editor>
        <!-- CKEDITOR -->
      </ng-container>

      <div
        *ngIf="note.NoteType === noteTypeEnum.DRAWING"
        id="handwrittenNote"
        style="height: 472px"></div>

      <helion-ui-input-tag
        *ngIf="isTaggedNote"
        [hideClearAllButton]="true"
        [placeholder]="'COMMON.TAGS' | translate"
        [tagSuggestions]="existingLeadNotesTags"
        (removed)="tagsChange()"
        (added)="tagsChange()"
        (ngModelChange)="tagsChange()"
        [(ngModel)]="note.Tags">
      </helion-ui-input-tag>

      <div class="d-flex justify-content-end gap-16 margin-top-8">
        <button (click)="back()" *ngIf="!editOnly">
          {{ 'COMMON.BTN_CLOSE' | translate }}
        </button>
        <button
          [attr.fill-type]="'contained'"
          (click)="save()"
          *ngIf="note.NoteType === noteTypeEnum.DRAWING || isNew">
          {{ 'COMMON.BTN_SAVE' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!isEditMode && !editOnly">
      <div
        class="x pc-note-item"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        style="flex: 1 1 100% !important">
        <span
          *ngIf="note.NoteType === noteTypeEnum.TEXT"
          class="note-item"
          [className]="
            isNotesOnFullScreen
              ? 'honor-line-break cursor-pointer'
              : 'honor-line-break cursor-pointer max-lines'
          "
          (click)="onEdit()">
          <!-- <span [innerHTML]="note.Text | leadNote | safeHtml"></span> -->

          <pc-ck-editor [disabled]="true" [initialData]="note.Text"> </pc-ck-editor>
        </span>
        <img
          *ngIf="note.NoteType === noteTypeEnum.DRAWING"
          (click)="onEdit()"
          [src]="sanitizer.bypassSecurityTrustUrl(note.DrawingNoteImageUrl)"
          class="note-list-drawing note-item"
          alt="drawing note content" />
        <span class="menu-variant-note-list note-actions">
          <a class="table-actions" fxLayoutAlign="center center" [cdkMenuTriggerFor]="menu">
            <helion-ui-icon data-feather="more-vertical"></helion-ui-icon>
          </a>
          <ng-template #menu>
            <helion-ui-menu>
              <button class="menu-item" cdkMenuItem (click)="onEdit()">
                <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
                <span *ngIf="!readOnly">{{ 'COMMON.ACTION.MODIFY' | translate }}</span>
                <span *ngIf="readOnly">{{ 'COMMON.ACTION.VIEW' | translate }}</span>
              </button>
              <button *ngIf="!readOnly" class="menu-item" cdkMenuItem (click)="onDelete(note)">
                <helion-ui-icon data-feather="trash-2"></helion-ui-icon>
                <span>{{ 'COMMON.ACTION.DELETE' | translate }}</span>
              </button>
            </helion-ui-menu>
          </ng-template>
        </span>
      </div>
      <div *ngIf="showTags(note)" class="list-tags">
        <helion-ui-tag-image
          *ngFor="let tag of getTags(note)"
          [tagImageText]="tag"
          [isActive]="true"></helion-ui-tag-image>
      </div>
      <span class="pc-note-item-created">
        {{ note.Created | date : 'dd.MM.yy' }} - {{ note.CreatedUser }}
      </span>
    </ng-container>
  </div>
</helion-ui-card>
