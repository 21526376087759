export interface IProjectDescriptionViewModel {
  isDefault: boolean;
  customerInfo: IProjectDescriptionCustomerInfoViewModel;
  motivation: IProjectDescriptionMotivationViewModel;
  projectDetails: IProjectDescriptionProjectDetailsViewModel;
  objectDetails: IProjectDescriptionObjectDetailsViewModel;
  processingDetails: IProjectDescriptionProcessingDetailsViewModel;
  notes: IProjectDescriptionNotesViewModel;
  checklist: IProjectDescriptionChecklistViewModel;
}

export interface IProjectDescriptionCustomerInfoViewModel {
  isContactOwner: string;
}

export interface IProjectDescriptionMotivationViewModel {
  whySelectionIndependence: boolean;
  whySelectionSavings: boolean;
  whySelectionFeedInPower: boolean;
  whySelectionEnvironment: boolean;
  whySelectionIncreaseInValue: boolean;
  whySelectionOther: boolean;
  whyComment?: string;
  plannedInNext12Months: string;
}

export interface IProjectDescriptionProjectDetailsViewModel {
  noOfPersons: string;
  energyUsage: string;
  energyCosts: string;

  timeOfExecution: string;
  // Kostendach
  costLimit: string;
  // Förderung
  boost: string;

  evg: string;
  // Speicher
  storage: string;
  smartEnergy: string;
  monitoring: string;
  lan: string;
  scaffolding: string;
  ac: string;
  lightningProtection: string;
  snowCatcher: string;
  moduleType: string;
  channelColor: string;
  houseType: string;
  switchCombination: string;
  prefinancing: string;
}

export interface IProjectDescriptionObjectDetailsViewModel {
  ageOfRoof: string;
  // Eindeckung
  supplySelectionTonziegel: boolean;
  supplySelectionTrapez: boolean;
  supplySelectionSchiefer: boolean;
  supplySelectionKies: boolean;
  supplySelectionBeton: boolean;
  supplySelectionWell: boolean;
  supplySelectionNackt: boolean;
  supplySelectionBegruent: boolean;
  // supplyComment: string;
  restorationPlanned: string;
  rooferAvailable: string;
  typeOfHeating: string;
  ageOfHeating: string;
  supplyOptionsGravel: string;
  supplyOptionsOther: string;
  // WW aufbereitung
  wwTreatment: string;
  heaterRestorationPending: string;
  typeOfRoof: string;
  roofAreaSelection: string;
  // roofAreaComment: string;
  roofInclination: string;
  roofOrientation: string;
  cardNumber: string;
  stableHeight: string;
  typeOfMontage: string;
  counterNumber1: string;
  counterNumber2: string;
  counterNumber3: string;
  responsibleEvu: string;
  houseConnectionFuse: string;
}

export interface IProjectDescriptionProcessingDetailsViewModel {
  createOfferUntil: string;
  sendOfferTo: string;
  attachmentOffer: boolean;
  attachmentMaintenance: boolean;
  attachmentRoofplan: boolean;
  attachmentDatasheets: boolean;
  attachmentReferences: boolean;
  attachmentGis: boolean;
  attachmentEconomy: boolean;
  // attachmentsComment: string;
  handoverDate: string;
}

export interface IProjectDescriptionNotesViewModel {
  comment: string;
}

export interface IProjectDescriptionChecklistViewModel {
  pvPhotos?: boolean;
  hak?: boolean;
  electroDistribution?: boolean;
  standortWechselrichter?: boolean;
  steigleitung?: boolean;
  rse?: boolean;
  ewZaehler?: boolean;
  dach?: boolean;
  hausumgebung?: boolean;
  storagePhotos?: boolean;
  boiler?: boolean;
  pump?: boolean;
  placeStorage?: boolean;
  placeSEBox?: boolean;
}

export function initDefaultProjectDescription(): IProjectDescriptionViewModel {
  return {
    isDefault: true,
    customerInfo: initDescriptionCustomerInfo(),
    motivation: initDescriptionMotivation(),
    projectDetails: initDescriptionProjectDetails(),
    objectDetails: initDescriptionObjectDetails(),
    processingDetails: initDescriptionProcessingDetails(),
    notes: initDescriptionNotes(),
    checklist: initDescriptionCheckList(),
  };
}

export function initDescriptionCustomerInfo(): IProjectDescriptionCustomerInfoViewModel {
  return {
    isContactOwner: '',
  };
}

export function initDescriptionMotivation(): IProjectDescriptionMotivationViewModel {
  return {
    plannedInNext12Months: '',
    whySelectionIndependence: false,
    whySelectionSavings: false,
    whySelectionFeedInPower: false,
    whySelectionEnvironment: false,
    whySelectionIncreaseInValue: false,
    whySelectionOther: false,
    whyComment: '',
  };
}

export function initDescriptionProjectDetails(): IProjectDescriptionProjectDetailsViewModel {
  return {
    noOfPersons: '',
    energyUsage: '',
    energyCosts: '',

    timeOfExecution: '',
    costLimit: '',
    boost: '',
    evg: '',
    storage: '',
    smartEnergy: '',
    monitoring: '',
    lan: '',
    scaffolding: '',
    ac: '',
    lightningProtection: '',
    snowCatcher: '',
    moduleType: '',
    channelColor: '',
    houseType: '',
    switchCombination: '',
    prefinancing: '',
  };
}

export function initDescriptionObjectDetails(): IProjectDescriptionObjectDetailsViewModel {
  return {
    ageOfRoof: '',
    supplySelectionBegruent: false,
    supplySelectionBeton: false,
    supplySelectionKies: false,
    supplySelectionNackt: false,
    supplySelectionSchiefer: false,
    supplySelectionTonziegel: false,
    supplySelectionTrapez: false,
    supplySelectionWell: false,
    typeOfHeating: '',
    ageOfHeating: '',
    supplyOptionsGravel: '',
    supplyOptionsOther: '',
    wwTreatment: '',
    heaterRestorationPending: '',
    // supplyComment: '',
    restorationPlanned: '',
    rooferAvailable: '',
    typeOfRoof: '',
    roofAreaSelection: '',
    // roofAreaComment: '',
    roofInclination: '',
    roofOrientation: '',
    cardNumber: '',
    stableHeight: '',
    typeOfMontage: '',
    counterNumber1: '',
    counterNumber2: '',
    counterNumber3: '',
    responsibleEvu: '',
    houseConnectionFuse: '',
  };
}

export function initDescriptionProcessingDetails(): IProjectDescriptionProcessingDetailsViewModel {
  return {
    createOfferUntil: '',
    sendOfferTo: '',
    attachmentDatasheets: false,
    attachmentEconomy: false,
    attachmentGis: false,
    attachmentMaintenance: false,
    attachmentOffer: false,
    attachmentReferences: false,
    attachmentRoofplan: false,
    // attachmentsComment: '',
    handoverDate: '',
  };
}

export function initDescriptionNotes(): IProjectDescriptionNotesViewModel {
  return {
    comment: '',
  };
}

export function initDescriptionCheckList(): IProjectDescriptionChecklistViewModel {
  return {};
}
