<div class="connection-switcher-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <helion-ui-icon data-feather="user"></helion-ui-icon>
    <helion-ui-switch-input
      class="switch"
      [checked]="isPrivacyMode"
      (checkedChange)="onChangeMode($event)"></helion-ui-switch-input>
    <helion-ui-icon data-feather="user-minus"></helion-ui-icon>
  </div>
</div>
