<div>
  <div class="main-container">
    <div class="editor-container editor-container_classic-editor" #editorContainerElement>
      <div class="editor-container__editor">
        <div #editorElement>
          <ckeditor
            *ngIf="isLayoutReady"
            [editor]="Editor"
            [config]="config"
            [disabled]="disabled"
            (change)="this.onTextChange($event)"
            placeholder="placeholder" />
        </div>
        <!--- [data]="this.text" -->
      </div>
    </div>
  </div>
</div>
