import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as GlobalAction from './global.action';
import {
  ErrorAddAction,
  SET_NAVIGATION_PROCESS,
  SetNavigationProcessAction,
} from './global.action';
import { ErrorTypeE } from './global.reducer';
import { ConfigService } from '../../services/config.service';
import { ArticleForestViewModel, MasterDataService, MasterDataViewModel } from '../../apis/advis';
import { catchError, debounceTime, flatMap, map, switchMap } from 'rxjs/operators';
import { LoadingModalService } from '../../services/loading-modal.service';

@Injectable()
export class GlobalEffects {
  masterDataLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.MASTER_DATA_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: GlobalAction.MasterDataLoadAction) => action.payload),
      switchMap(() => {
        return this.masterDataService.pCApiMasterdataGetGenericMasterData().pipe(
          flatMap((viewModel: MasterDataViewModel) => [
            new GlobalAction.MasterDataLoadSuccessAction(viewModel),
            new GlobalAction.ArticlesForestsLoadAction(),
          ]),
          catchError((e: any) => of(new GlobalAction.MasterDataLoadFailureAction(e)))
        );
      })
    )
  );

  loadArticlesForests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.ARTICLES_FORESTS_LOAD),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() => {
        return this.masterDataService.pCApiMasterdataGetArticlesMasterData().pipe(
          map(
            (articlesForests: ArticleForestViewModel[]) =>
              new GlobalAction.ArticlesForestsLoadSuccessAction(articlesForests)
          ),
          catchError((e: any) => of(new GlobalAction.ArticlesForestsLoadFailureAction(e)))
        );
      })
    )
  );

  loadArticlesForestFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.ARTICLES_FORESTS_LOAD_FAILURE),
      map(
        (action: GlobalAction.ArticlesForestsLoadFailureAction) =>
          new ErrorAddAction({ type: ErrorTypeE.LOAD, data: action.payload.message })
      )
    )
  );

  leadsLoadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalAction.MASTER_DATA_FAILURE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: GlobalAction.MasterDataLoadFailureAction) => {
        return new ErrorAddAction({ type: ErrorTypeE.LOAD, data: action.payload });
      })
    )
  );

  isLoading: boolean;
  // dispatch: false will not emit the returned value

  setNavigationProcess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_NAVIGATION_PROCESS),
        map((state: SetNavigationProcessAction) => {
          if (state.payload) {
            if (!this.isLoading) {
              this.loadingModalService.openModal();
              this.isLoading = true;
            }
          } else {
            if (this.isLoading) {
              this.loadingModalService.closeModal();
              this.isLoading = false;
            }
          }
          return { type: 'NOOP' };
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private masterDataService: MasterDataService,
    private config: ConfigService,
    private loadingModalService: LoadingModalService
  ) {}
}
