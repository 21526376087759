<section class="dialog">
  <h2 class="dialog-title">
    {{ title }}
  </h2>

  <div class="dialog-content">
    <form [formGroup]="form">
      <helion-ui-input-tag
        [placeholder]="'COMMON.TAGS' | translate"
        [tagSuggestions]="data.existingTags$ | async"
        [tagFormatter]="tagFormatter"
        [inputWidth]="'100%'"
        [hasLoadingSpinner]="true"
        formControlName="tags">
        <div class="dialog-actions">
          <button type="button" (click)="onCancel()">{{ 'COMMON.BTN_CANCEL' | translate }}</button>
          <button
            [attr.fill-type]="'contained'"
            type="button"
            [disabled]="isOkButtonDisable"
            (click)="onOk()">
            {{ 'COMMON.BTN_OK' | translate }}
          </button>
        </div>
      </helion-ui-input-tag>
    </form>
  </div>
</section>
