/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RoleViewModel } from './roleViewModel';


/**
 * Short summary of the current user.
 */
export interface UserInfo { 
    /**
     * User name
     */
    User?: string;
    /**
     * User id. This is not usually needed. Whenever we ask for a user as an argument, we're asking for 'User'
     */
    UserId?: string;
    /**
     * Name of the partner the user belongs to.
     */
    Mandant?: string;
    /**
     * The id of the partner the user belongs to
     */
    MandantId?: number;
    /**
     * The roles of this user
     */
    Roles?: Array<RoleViewModel>;
    Language?: UserInfo.LanguageEnum;
    /**
     * The project score of this user
     */
    ProjectScore?: number;
}
export namespace UserInfo {
    export type LanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LanguageEnum = {
        DE: 'DE' as LanguageEnum,
        FR: 'FR' as LanguageEnum,
        IT: 'IT' as LanguageEnum,
        EN: 'EN' as LanguageEnum
    };
}
