<div *ngIf="!contactIsSelected" fxLayout="row" fxLayoutAlign="space-between flex-start">
  <pc-contact-selector
    fxFlex="100"
    [selectType]="getTypeAheadType()"
    [mandator]="mandator"
    [contactType]="originContact.AddressType"
    (onSelectedContactId)="selectNewContact($event)">
  </pc-contact-selector>
</div>

<div
  *ngIf="contactIsSelected"
  class="contact-form-content-wrapper"
  [ngClass]="{ 'margin-between': isPersonContactSelect }">
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="70">
    <h4 *ngIf="isExternalManaged && originContact?.ContactId">ID: {{ originContact.ContactId }}</h4>

    <pc-company-contact
      *ngIf="isCompanyContactSelect"
      [company]="directoryContact.Company"
      [isCompanyContactSelect]="isCompanyContactSelect"></pc-company-contact>

    <pc-person-contact
      *ngIf="isPersonContactSelect && !siteAddressContactInfo"
      [person]="directoryContact.Person"
      [isPersonContactSelect]="isPersonContactSelect"></pc-person-contact>

    <pc-contact-info
      *ngIf="isPersonContactSelect && siteAddressContactInfo"
      [displayContactInfo]="siteAddressContactInfo">
    </pc-contact-info>
  </div>

  <div fxLayout="column" fxLayoutAlign="start end" fxFlex="25">
    <div fxFlex="10">
      <span fxFlex="5" class="pull-right">
        <button [attr.fill-type]="'icon'" class="table-actions" [cdkMenuTriggerFor]="menu">
          <helion-ui-icon data-feather="more-vertical"></helion-ui-icon>
        </button>
        <ng-template #menu>
          <helion-ui-menu>
            <div *ngIf="isCompanyContactSelect && isPersonContactSelect && !isExternalManaged">
              <button
                class="menu-item"
                cdkMenuItem
                *ngIf="isCompanyContactSelect"
                (click)="companyEdit()">
                <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
                <span>{{ 'ADDRESS.EDIT.EDIT_COMPANY' | translate }}</span>
              </button>
              <button
                class="menu-item"
                cdkMenuItem
                *ngIf="isPersonContactSelect"
                (click)="personEdit()">
                <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
                <span>{{ 'ADDRESS.EDIT.EDIT_PERSON' | translate }}</span>
              </button>
              <helion-ui-divider></helion-ui-divider>
            </div>

            <div *ngIf="!(isCompanyContactSelect && isPersonContactSelect) && !isExternalManaged">
              <button
                class="menu-item"
                cdkMenuItem
                *ngIf="isCompanyContactSelect"
                (click)="companyEdit()">
                <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
                <span>{{ 'ADDRESS.EDIT.EDIT_COMPANY' | translate }}</span>
              </button>
              <button
                class="menu-item"
                cdkMenuItem
                *ngIf="isPersonContactSelect"
                (click)="personEdit()">
                <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
                <span>{{ 'ADDRESS.EDIT.EDIT_PERSON' | translate }}</span>
              </button>
            </div>

            <button
              class="menu-item"
              cdkMenuItem
              *ngIf="!isExternalManaged"
              (click)="selectNewCompany()">
              <helion-ui-icon data-feather="briefcase"></helion-ui-icon>
              <span>{{ 'ADDRESS.EDIT.MENU.CHANGE_COMPANY' | translate }}</span>
            </button>
            <button
              class="menu-item"
              cdkMenuItem
              *ngIf="isCompanyContactSelect && !isExternalManaged"
              (click)="selectNewPersonFromCompany()">
              <helion-ui-icon data-feather="phone-call"></helion-ui-icon>
              <span>{{ 'ADDRESS.EDIT.MENU.CHANGE_CONTACT_PERSON_OF_COMPANY' | translate }}</span>
            </button>
            <button
              class="menu-item"
              cdkMenuItem
              *ngIf="isNotAdditionalAddress && !isExternalManaged"
              (click)="selectNewPerson()">
              <helion-ui-icon data-feather="user"></helion-ui-icon>
              <span>{{ 'ADDRESS.EDIT.MENU.CHANGE_PERSON' | translate }}</span>
            </button>
            <pc-menu-save-to-external-managed-contact
              *ngIf="originContact.Id > 0"
              [copyToExternalManaged]="!isExternalManaged"
              [addressType]="directoryContact.ContactType"
              (onCopyAsExternalManaged)="
                onCopyAsExternalManaged.emit({ contact: originContact, addressType: $event })
              "
              (onChangeRequest)="ExternalManagedContactChangeRequest($event)">
            </pc-menu-save-to-external-managed-contact>
          </helion-ui-menu>
        </ng-template>
      </span>
    </div>
    <div fxFlex="30" class="text-align-right" style="margin-top: 40px !important">
      <helion-ui-checkbox
        [isChecked]="directoryContact.DataState === 'Qualified'"
        [disabled]="true">
        {{ 'ADDRESS.CREATE.VALIDATED' | translate }}
      </helion-ui-checkbox>
    </div>
  </div>
</div>

<div class="action-buttons-to-right" *ngIf="showSaveButton && contactIsSelected">
  <button
    class="new-person-button"
    [attr.fill-type]="'contained'"
    [disabled]="saveSelectionDisabled()"
    (click)="saveSelection()">
    {{ 'ADDRESS.CREATE_AND_SAVE.SELECTION' | translate }}
  </button>
</div>
