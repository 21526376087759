<helion-ui-card>
  <h2 title>Development</h2>
  <div content style="margin-top: 15px; margin-bottom: 0">
    <helion-ui-input
      #devInput
      *ngIf="!show"
      [max]="30"
      type="text"
      maxlength="30"
      name="title"
      placeholder="password"
      (keyup.enter)="check(devInput.inputValue)"
      required>
    </helion-ui-input>
  </div>
  <div actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
    <button (click)="clearLocalStorage()">Clear Local Storage</button>
  </div>
</helion-ui-card>
