import { Injectable } from '@angular/core';
import {
  ContactDuplicateAndTypeaheadViewModel,
  DirectoryCompanyViewModel,
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  DirectoryService,
  ExternalManagedContactDuplicateCheckRequest,
  ExternalManagedDuplicateAndTypeaheadViewModel,
  LeadContactViewModel,
  PostPutContactRequest,
  PostPutPersonRequest,
  SearchAddressBookListViewModelPageResultViewModel,
} from '../apis/advis';
import { Observable, Observer, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { NotificationService, TypeE } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import {
  DuplicateContactDialogComponent,
  IDuplicateData,
  TypeOfDuplicateEnum,
} from '../../address-book-shared/dialog/duplicate-contact-dialog/duplicate-contact-dialog.component';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { LoadingModalService } from './loading-modal.service';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;
import { IExternManagedAddressTypeConfig } from '../utils/exter-managed-address-type.util';
import { DialogService } from '@sitewerk/theia-ui-lib';

export interface IResultPayload<T> {
  payload: T;
  successful: boolean;
}

interface IDuplicateCheckResult {
  save: boolean;
  cancel: boolean;
  selectNewId: number;
}

interface IExternalManagedDuplicateCheckResult {
  save: boolean;
  cancel: boolean;
  selectNewId?: number;
  addContactToLead?: number;
  saveExternalManagedContactRequest?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AddressBookBackendService {
  constructor(
    private directoryService: DirectoryService,
    private notification: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {}

  public static removeEmptyEmailAndPhonesFromCompany(
    company: DirectoryCompanyViewModel
  ): DirectoryCompanyViewModel {
    if (company?.Emails?.length > 0) {
      company.Emails = AddressBookBackendService.removeEmptyEmail(company.Emails);
    }
    if (company?.Phones?.length > 0) {
      company.Phones = AddressBookBackendService.removeEmptyPhone(company.Phones);
    }
    return company;
  }

  public static removeEmptyEmailAndPhonesFromPerson(
    person: DirectoryPersonViewModel
  ): DirectoryPersonViewModel {
    if (person?.Emails?.length > 0) {
      person.Emails = AddressBookBackendService.removeEmptyEmail(person.Emails);
    }
    if (person?.Phones?.length > 0) {
      person.Phones = AddressBookBackendService.removeEmptyPhone(person.Phones);
    }
    return person;
  }

  public static removeEmptyEmailAndPhonesFromContact(
    contact: DirectoryContactViewModel
  ): DirectoryContactViewModel {
    if (contact.Company) {
      contact.Company = AddressBookBackendService.removeEmptyEmailAndPhonesFromCompany(
        contact.Company
      );
    }

    if (contact.Person) {
      contact.Person = AddressBookBackendService.removeEmptyEmailAndPhonesFromPerson(
        contact.Person
      );
    }

    return contact;
  }

  public static removeEmptyEmail(emails: DirectoryEmailViewModel[]): DirectoryEmailViewModel[] {
    return emails.filter((item: DirectoryEmailViewModel) => item.Email && item.Email.length > 0);
  }

  public static removeEmptyPhone(phones: DirectoryPhoneViewModel[]): DirectoryPhoneViewModel[] {
    return phones.filter(
      (item: DirectoryPhoneViewModel) => item.PhoneNumber && item.PhoneNumber.length > 0
    );
  }

  public GetDirectoryCompanyTypeaheadSuggestions(
    mandatorIds: number[],
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryCompanyTypeaheadSuggestions(
        mandatorIds.join(','),
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public GetDirectoryCompanyPersonAddressSuggestions(
    mandatorIds: number[],
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryCompanyPersonAddressSuggestions(
        mandatorIds.join(','),
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public GetDirectoryCompanyPersonSuggestions(
    mandatorIds: number[],
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryCompanyPersonSuggestions(
        mandatorIds.join(','),
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public GetDirectoryPersonSuggestions(
    mandatorIds: number[],
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryPersonSuggestions(
        mandatorIds.join(','),
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public GetDirectoryPersonExcludingCompanyPersonSuggestions(
    mandatorIds: number[],
    companyId: number,
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryPersonExcludingCompanySuggestions(
        mandatorIds.join(','),
        companyId,
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public GetDirectoryCompanyPersonExternalManagedIdSuggestions(
    mandatorIds: number[],
    addressType: AddressTypeEnum,
    searchString: string
  ): Observable<IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>> {
    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryCompanyPersonExternalManagedContactIdSuggestions(
        mandatorIds.join(','),
        addressType,
        searchString,
        undefined
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.CONTACT_SUGGESTIONS_FAILED'
    );
  }

  public getPersonsContactsOfCompany(
    companyId: number,
    pageIndex?: number,
    pageSize?: number
  ): Observable<IResultPayload<SearchAddressBookListViewModelPageResultViewModel>> {
    return this.handleHttpResponse<SearchAddressBookListViewModelPageResultViewModel>(
      this.directoryService.directoryGetCompanyPersons(companyId, pageIndex, pageSize),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.LOAD_CONTACT_FAILED'
    );
  }

  public getContact(id: number): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryGetContact(id),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.LOAD_CONTACT_FAILED'
    );
  }

  public getCompanyContact(id: number): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryGetCompanyContact(id),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.LOAD_CONTACT_FAILED'
    );
  }

  public getPersonContact(id: number): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryGetPerson(id),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.LOAD_CONTACT_FAILED'
    );
  }

  public getGetPersonCompanies(
    id: number,
    pageIndex: number,
    pageSize: number
  ): Observable<IResultPayload<SearchAddressBookListViewModelPageResultViewModel>> {
    return this.handleHttpResponse<SearchAddressBookListViewModelPageResultViewModel>(
      this.directoryService.directoryGetPersonCompanies(id, pageIndex, pageSize),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.LOAD_CONTACT_FAILED'
    );
  }

  public addCompany(
    mandatorId: number,
    duplicateSearchMandatorIds: number[],
    company: DirectoryCompanyViewModel,
    addressType?: PostPutContactRequest.AddressTypeEnum
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    const request: PostPutContactRequest = {
      ContactId: 0,
      AddressType: addressType,
      Company: AddressBookBackendService.removeEmptyEmailAndPhonesFromCompany(company),
      IsSingleContact: false,
      IsValidated: true,
    };

    return this.onCheckDuplicateForAddCompany(company, duplicateSearchMandatorIds).pipe(
      filter(result => result.cancel || result.save || result.selectNewId > 0),
      switchMap(result => {
        if (result.selectNewId > 0) {
          return this.getContact(result.selectNewId);
        } else if (result.save) {
          return this.handleHttpResponse<DirectoryContactViewModel>(
            this.directoryService.directoryCreateContact(mandatorId, request),
            'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_SUCCESSFUL',
            'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
          );
        }
        return this.cancelCompany();
      })
    );
  }

  public addCompanyAsExternalManaged(
    mandatorId: number,
    duplicateSearchMandatorIds: number[],
    company: DirectoryCompanyViewModel,
    addressType?: PostPutContactRequest.AddressTypeEnum
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    const request: PostPutContactRequest = {
      ContactId: 0,
      AddressType: addressType,
      Company: AddressBookBackendService.removeEmptyEmailAndPhonesFromCompany(company),
      IsSingleContact: false,
      IsValidated: true,
    };

    return this.onCheckDuplicateForAddCompany(company, duplicateSearchMandatorIds).pipe(
      filter(result => result.cancel || result.save || result.selectNewId > 0),
      switchMap(result => {
        if (result.selectNewId > 0) {
          return this.getContact(result.selectNewId);
        } else if (result.save) {
          return this.handleHttpResponse<DirectoryContactViewModel>(
            this.directoryService.directoryCreateExternalManagedContact(mandatorId, request),
            'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_SUCCESSFUL',
            'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
          );
        }
        return this.cancelCompany();
      })
    );
  }

  public cancelCompany(): Observable<IResultPayload<DirectoryContactViewModel>> {
    return new Observable(observer => {
      observer.next({
        successful: false,
        payload: undefined,
      } as IResultPayload<DirectoryContactViewModel>);
    });
  }

  public updateCompany(
    companyId: number,
    company: DirectoryCompanyViewModel,
    contactId: number
  ): Observable<IResultPayload<DirectoryCompanyViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryUpdateCompany(
        companyId,
        contactId,
        AddressBookBackendService.removeEmptyEmailAndPhonesFromCompany(company)
      ),
      'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_SUCCESSFUL',
      'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
    );
  }

  public removeCompany(companyId: number, contactId: number): Observable<IResultPayload<any>> {
    return this.handleHttpResponse<any>(
      this.directoryService.directoryRemoveCompany(companyId, contactId),
      'ADDRESSBOOK.NOTIFICATION.COMPANY_REMOVE_SUCCESSFUL',
      'ADDRESSBOOK.NOTIFICATION.COMPANY_REMOVE_FAILED'
    );
  }

  public addPerson(
    mandatorId: number,
    duplicateSearchMandatorIds: number[],
    person: DirectoryPersonViewModel,
    externalManagedExclude: boolean,
    addressType?: PostPutContactRequest.AddressTypeEnum
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    const request: PostPutContactRequest = {
      ContactId: 0,
      AddressType: addressType,
      Person: AddressBookBackendService.removeEmptyEmailAndPhonesFromPerson(person),
      IsSingleContact: false,
      IsValidated: true,
    };

    return this.onCheckDuplicateForAddPerson(
      person,
      externalManagedExclude,
      duplicateSearchMandatorIds
    ).pipe(
      filter(result => result.cancel || result.save || result.selectNewId > 0),
      switchMap(result => {
        if (result.selectNewId > 0) {
          return this.getContact(result.selectNewId);
        } else if (result.save) {
          return this.handleHttpResponse<DirectoryContactViewModel>(
            this.directoryService.directoryCreateContact(mandatorId, request),
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL',
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'
          );
        }
        return this.cancelCompany();
      })
    );
  }

  public addPersonAsExternalManaged(
    mandatorId: number,
    duplicateSearchMandatorIds: number[],
    person: DirectoryPersonViewModel,
    externalManagedExclude: boolean,
    addressType?: PostPutContactRequest.AddressTypeEnum
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    const request: PostPutContactRequest = {
      ContactId: 0,
      AddressType: addressType,
      Person: AddressBookBackendService.removeEmptyEmailAndPhonesFromPerson(person),
      IsSingleContact: false,
      IsValidated: true,
    };

    return this.onCheckDuplicateForAddPerson(
      person,
      externalManagedExclude,
      duplicateSearchMandatorIds
    ).pipe(
      filter(result => result.cancel || result.save || result.selectNewId > 0),
      switchMap(result => {
        if (result.selectNewId > 0) {
          return this.getContact(result.selectNewId);
        } else if (result.save) {
          return this.handleHttpResponse<DirectoryContactViewModel>(
            this.directoryService.directoryCreateExternalManagedContact(mandatorId, request),
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL',
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'
          );
        }
        return this.cancelCompany();
      })
    );
  }

  public addPersonToCompany(
    person: PostPutPersonRequest,
    companyId: number,
    externalManagedExclude: boolean,
    duplicateSearchMandatorIds: number[]
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.onCheckDuplicateForAddPerson(
      person,
      externalManagedExclude,
      duplicateSearchMandatorIds
    ).pipe(
      filter(result => result.cancel || result.save || result.selectNewId > 0),
      switchMap(result => {
        if (result.selectNewId > 0) {
          return this.getContact(result.selectNewId);
        } else if (result.save) {
          return this.handleHttpResponse<DirectoryContactViewModel>(
            this.directoryService.directoryCreateContactWithExistingCompany(
              companyId,
              AddressBookBackendService.removeEmptyEmailAndPhonesFromPerson(person)
            ),
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL',
            'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'
          );
        }
        return this.cancelCompany();
      })
    );
  }

  public addExistPersonToCompany(
    personId: number,
    companyId: number
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryCreateContactWithExistingCompanyAndPerson(companyId, personId),
      'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL',
      'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'
    );
  }

  public updatePerson(
    personId: number,
    person: DirectoryPersonViewModel,
    contactId: number
  ): Observable<IResultPayload<DirectoryContactViewModel>> {
    return this.handleHttpResponse<DirectoryContactViewModel>(
      this.directoryService.directoryUpdatePerson(
        personId,
        contactId,
        AddressBookBackendService.removeEmptyEmailAndPhonesFromPerson(person)
      ),
      'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_SUCCESSFUL',
      'ADDRESSBOOK.NOTIFICATION.PERSON_SAVE_FAILED'
    );
  }

  public removePerson(personId: number, contactId: number): Observable<IResultPayload<boolean>> {
    return this.handleHttpResponse<any>(
      this.directoryService.directoryRemovePerson(personId, contactId),
      'ADDRESSBOOK.NOTIFICATION.PERSON_REMOVE_SUCCESSFUL',
      'ADDRESSBOOK.NOTIFICATION.PERSON_REMOVE_FAILED'
    );
  }

  public externManagedRequest(
    mandatorIds: string,
    loadingModalService: LoadingModalService,
    externalManagedAddress: IExternManagedAddressTypeConfig,
    contactId?: number,
    lastName?: string,
    firstName?: string,
    companyName?: string,
    street?: string,
    city?: string,
    zipCode?: string,
    email?: string,
    phone?: string
  ): Observable<IExternalManagedDuplicateCheckResult> {
    const label: string = [
      companyName ?? '',
      firstName ?? '',
      lastName ?? '',
      street ?? '',
      zipCode ?? '',
      city ?? '',
      email ?? '',
      phone ?? '',
    ]
      .filter(x => x && x.length > 0)
      .join(', ');

    const request = {
      MandatorIds: mandatorIds,
      AddressType: externalManagedAddress.addressType,
      ContactId: contactId,
      LastName: lastName,
      CompanyName: companyName,
      Street: street,
      City: city,
      ZipCode: zipCode,
      Email: email,
      Phone: phone,
    } as ExternalManagedContactDuplicateCheckRequest;

    return this.handleHttpResponse<ExternalManagedDuplicateAndTypeaheadViewModel>(
      this.directoryService.directoryGetPossibleDuplicatesExternalManagedContactIdSuggestions(
        request
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
    ).pipe(
      filter(x => x.successful),
      switchMap((d: IResultPayload<ExternalManagedDuplicateAndTypeaheadViewModel>) =>
        this.checkExternalManagedDuplicateAndAddOrCancel(
          d.payload,
          label,
          loadingModalService,
          externalManagedAddress,
          contactId
        )
      )
    );
  }

  private checkExternalManagedDuplicateAndAddOrCancel(
    possibleDuplicates: ExternalManagedDuplicateAndTypeaheadViewModel,
    newContactLabel: string,
    loadingModalService: LoadingModalService,
    externalManagedAddress: IExternManagedAddressTypeConfig,
    contactId: number
  ): Observable<IExternalManagedDuplicateCheckResult> {
    if (
      isNullOrUndefined(possibleDuplicates.ExistExternalManagedContact) &&
      (isNullOrUndefined(possibleDuplicates.PossibleDuplicates) ||
        possibleDuplicates.PossibleDuplicates.length === 0)
    ) {
      return of({
        save: true,
        cancel: false,
        selectNewId: contactId,
        saveExternalManagedContactRequest: true,
      });
    } else if (!isNullOrUndefined(possibleDuplicates.ExistExternalManagedContact)) {
      return new Observable((observer: Observer<IExternalManagedDuplicateCheckResult>) => {
        loadingModalService.closeModal();
        this.dialogService
          .openConfirm({
            message: this.translate.instant(
              'ADDRESS.EXTERNAL_MANAGED_INFO.DUPLICATE_CHECK.DIALOG.EXTERNAL_MANAGED_EXIST.MESSAGE',
              { addressTypeName: externalManagedAddress.translation }
            ),
            title: this.translate.instant(
              'ADDRESS.EXTERNAL_MANAGED_INFO.DUPLICATE_CHECK.DIALOG.EXTERNAL_MANAGED_EXIST.TITLE',
              { addressTypeName: externalManagedAddress.translation }
            ),
            acceptButton: this.translate.instant(
              'ADDRESS.EXTERNAL_MANAGED_INFO.DUPLICATE_CHECK.DIALOG.EXTERNAL_MANAGED_EXIST.OK_BTN'
            ),
          })
          .afterClosed()
          .subscribe(ok => {
            observer.next({
              save: ok,
              cancel: !ok,
              selectNewId: 0,
              addContactToLead: possibleDuplicates.ExistExternalManagedContact.Id,
            });
            observer.complete();
          });
        observer.next({
          save: false,
          cancel: false,
          selectNewId: possibleDuplicates.ExistExternalManagedContact.Id,
        });
      });
    } else {
      return new Observable((observer: Observer<IExternalManagedDuplicateCheckResult>) => {
        if (
          !possibleDuplicates.PossibleDuplicates ||
          possibleDuplicates.PossibleDuplicates.length > 0
        ) {
          const dialogData: IDuplicateData = {
            newContactLabel: newContactLabel,
            duplicate: possibleDuplicates.PossibleDuplicates,
            typeOfDuplicate: TypeOfDuplicateEnum.EXTERNAL_MANAGED,
            externalManagedAddress: externalManagedAddress,
          };

          loadingModalService.closeModal();
          this.dialogService
            .open(DuplicateContactDialogComponent, { data: dialogData })
            .afterClosed()
            .subscribe((res: any) => {
              if (!res.confirmed) {
                observer.next({ save: false, cancel: true, selectNewId: 0 });
                observer.complete();
              }
              if (res.selectedContact > 0) {
                observer.next({ save: true, cancel: false, addContactToLead: res.selectedContact });
                observer.complete();
              } else {
                observer.next({ save: true, cancel: false, selectNewId: contactId });
                observer.complete();
              }
            });
        } else {
          observer.next({ save: true, cancel: false, selectNewId: 0 });
        }
      });
    }
  }

  private checkDuplicateAndAddOrCancel(
    duplicate: ContactDuplicateAndTypeaheadViewModel[],
    newContactLabel: string
  ): Observable<IDuplicateCheckResult> {
    return new Observable((observer: Observer<IDuplicateCheckResult>) => {
      if (!duplicate || duplicate.length > 0) {
        const dialogData: IDuplicateData = {
          newContactLabel: newContactLabel,
          duplicate: duplicate,
          typeOfDuplicate: TypeOfDuplicateEnum.ADDRESS,
        };
        this.dialogService
          .open(DuplicateContactDialogComponent, { data: dialogData })
          .afterClosed()
          .subscribe((res: any) => {
            if (!res.confirmed) {
              observer.next({ save: false, cancel: true, selectNewId: 0 });
              observer.complete();
            }
            if (res.selectedContact > 0) {
              observer.next({ save: false, cancel: false, selectNewId: res.selectedContact });
              observer.complete();
            } else {
              observer.next({ save: true, cancel: false, selectNewId: 0 });
              observer.complete();
            }
          });
      } else {
        observer.next({ save: true, cancel: false, selectNewId: 0 });
      }
    });
  }

  private handleHttpResponse<T>(
    response: Observable<T>,
    successKey?: string,
    failedKey?: string
  ): Observable<IResultPayload<T>> {
    return response.pipe(
      map(r => {
        const result: IResultPayload<T> = {
          payload: r,
          successful: true,
        };
        if (isNotNullOrUndefined(successKey)) {
          this.notification.notifySimple(this.translate.instant(successKey), TypeE.PRIMARY);
        }
        return result;
      }),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error(`Error: ${error.error.message}`);
        } else {
          console.error(`Error: ${error.message}`);
        }
        if (isNotNullOrUndefined(failedKey)) {
          this.notification.notifySimple(this.translate.instant(failedKey), TypeE.ALERT);
        }
        return of({ payload: undefined, successful: false });
      })
    );
  }

  private onCheckDuplicateForAddCompany(
    company: DirectoryCompanyViewModel,
    duplicateSearchMandatorIds: number[]
  ): Observable<IDuplicateCheckResult> {
    const companyLabel: string = [
      company.Name,
      company.Street,
      company.ZipCode,
      company.City,
      company.PrimaryEmail?.Email ?? '',
      company.PrimaryPhone?.PhoneNumber ?? '',
    ]
      .filter(x => x && x.length > 0)
      .join(', ');

    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryContactDuplicate(
        duplicateSearchMandatorIds.join(','),
        true,
        undefined,
        undefined,
        company.Name,
        company.Street,
        company.ZipCode,
        company.City,
        company.Emails.map((x: DirectoryEmailViewModel) => x.Email).join(','),
        company.Phones.map((x: DirectoryPhoneViewModel) => x.PhoneNumber).join(',')
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
    ).pipe(
      filter(x => x.successful),
      switchMap((d: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) =>
        this.checkDuplicateAndAddOrCancel(d.payload, companyLabel)
      )
    );
  }

  private onCheckDuplicateForAddPerson(
    person: DirectoryPersonViewModel,
    externalManagedExclude: boolean,
    duplicateSearchMandatorIds: number[]
  ): Observable<IDuplicateCheckResult> {
    const companyLabel: string = [
      person.FirstName + ' ' + person.LastName,
      person.Street,
      person.ZipCode,
      person.City,
      person.PrimaryEmail?.Email ?? '',
      person.PrimaryPhone?.PhoneNumber ?? '',
    ]
      .filter(x => x && x.length > 0)
      .join(', ');

    return this.handleHttpResponse<ContactDuplicateAndTypeaheadViewModel[]>(
      this.directoryService.directoryGetDirectoryContactDuplicate(
        duplicateSearchMandatorIds.join(','),
        false,
        externalManagedExclude,
        person.FirstName,
        person.LastName,
        undefined,
        person.Street,
        person.ZipCode,
        person.City,
        person.Emails?.map((x: DirectoryEmailViewModel) => x.Email).join(','),
        person.Phones?.map((x: DirectoryPhoneViewModel) => x.PhoneNumber).join(',')
      ),
      undefined,
      'ADDRESSBOOK.NOTIFICATION.COMPANY_SAVE_FAILED'
    ).pipe(
      switchMap((d: IResultPayload<ContactDuplicateAndTypeaheadViewModel[]>) => {
        if (d.successful) {
          return this.checkDuplicateAndAddOrCancel(d.payload, companyLabel);
        }
        return of({ save: false, cancel: true, selectNewId: 0 });
      })
    );
  }
}
