/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PvWizardViewModel } from './pvWizardViewModel';


export interface PvTradeSpecificDataViewModel { 
    WizardData?: PvWizardViewModel;
    EnergyConsumptionHt?: number;
    EnergyConsumptionLt?: number;
    ElectricityRateHt?: number;
    ElectricityRateLt?: number;
    OwnConsumptionRatioWithoutStorage?: number;
    OwnConsumptionRatioWithStorage?: number;
    FeedInTarifHt?: number;
    FeedInTarifLt?: number;
    FeedInTariffY?: number;
    FeasibilitySolarTarIfNewCustomers?: string;
    SmartMeterRollOut?: number;
    /**
     * This property is calculated and cannot be set
     */
    EnergyProductionCosts?: number;
    /**
     * This property is calculated and cannot be set
     */
    TaxSaving?: number;
    NumberOfModules?: number;
    TotalNumberOfBlindModules?: number;
    /**
     * This property is calculated and cannot be set
     */
    TotalKwp?: number;
    /**
     * This property is calculated and cannot be set
     */
    CoveredRoofArea?: number;
    /**
     * This property is calculated and cannot be set
     */
    KwhProducedPerYear?: number;
    /**
     * This property is calculated and cannot be set
     */
    EivOneOffPayment?: number;
    /**
     * The Municipal subsidies, default is 0
     */
    MunicipalSubsidies?: number;
    /**
     * The Cantonal subsidies, default is 0
     */
    CantonalSubsidies?: number;
    /**
     * The Federal subsidies, default is 0
     */
    FederalSubsidies?: number;
    /**
     * The Other subsidies, default is 0
     */
    OtherSubsidies?: number;
    BruttoPrice?: number;
    Investment?: number;
    FinancingType?: PvTradeSpecificDataViewModel.FinancingTypeEnum;
    SalesType?: PvTradeSpecificDataViewModel.SalesTypeEnum;
    MinimalMargin?: number;
    GoalMargin?: number;
    ValidationDate?: Date;
    GsmInternetGateway?: string;
    MonitoringContract?: string;
    MaintenanceContract?: string;
    TotalSubsidies?: number;
    TotalPrice?: number;
    Template?: string;
    UseSolarPlannerPicture?: boolean;
}
export namespace PvTradeSpecificDataViewModel {
    export type FinancingTypeEnum = 'None' | 'SubsidyPrefinancing' | 'Installments';
    export const FinancingTypeEnum = {
        None: 'None' as FinancingTypeEnum,
        SubsidyPrefinancing: 'SubsidyPrefinancing' as FinancingTypeEnum,
        Installments: 'Installments' as FinancingTypeEnum
    };
    export type SalesTypeEnum = 'Classic' | 'Digital';
    export const SalesTypeEnum = {
        Classic: 'Classic' as SalesTypeEnum,
        Digital: 'Digital' as SalesTypeEnum
    };
}
