import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { RegionalInformationDialogComponent } from 'app/lead/dialog/regional-information-dialog/regional-information-dialog.component';
import { IRegionalInformation } from '../models/regional-information';

@Component({
  selector: 'pc-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @Input() public showEditIcon: boolean;
  @Input() public regionalInformation: IRegionalInformation;
  @Input() public searchLink: string;
  @Output() public editAddress: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dialogService: DialogService) {}

  public get hasRegionalInformation(): boolean {
    return (
      this.regionalInformation &&
      this.regionalInformation.regionalInformation &&
      (this.regionalInformation.regionalInformation.RegionalInformation !== null ||
        (this.regionalInformation.regionalInformation.TradeTypeRegionalResponsibilities !== null &&
          this.regionalInformation.regionalInformation.TradeTypeRegionalResponsibilities.length >
            0))
    );
  }

  public onEditAddress(event: any): void {
    event.stopPropagation();
    this.editAddress.emit();
  }

  public openRegionalInfo(): void {
    this.dialogService.open(RegionalInformationDialogComponent, {
      data: {
        leadRegionalAddressViewModel: this.regionalInformation.regionalInformation,
        tradeTypeCode: this.regionalInformation.tradeTypeCode,
      },
    });
  }
}
