import { Action } from '@ngrx/store';

export const GET_MASTER_DATA: string = '[HT] Get masterdata';
export const GET_MASTER_DATA_SUCCESS: string = '[HT] Get masterdata success';
export const GET_MASTER_DATA_FAILED: string = '[HT] Get masterdata failed';

export class GetMasterDataAction implements Action {
  readonly type: string = GET_MASTER_DATA;

  constructor(public payload?: undefined) {}
}

export class GetMasterDataSuccessAction implements Action {
  readonly type: string = GET_MASTER_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetMasterDataFailedAction implements Action {
  readonly type: string = GET_MASTER_DATA_FAILED;

  constructor(public payload: any) {}
}

export type Actions = GetMasterDataAction | GetMasterDataSuccessAction | GetMasterDataFailedAction;
