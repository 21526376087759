import * as LeadAction from './lead.action';
import * as ActionsAction from '../actions/actions.action';
import { IActionMultipleSuccessPayload, IActionTransferPayload } from '../actions/actions.action';
import { tassign } from 'tassign';
import cloneDeep from 'lodash/cloneDeep';

import { IPaging } from './paging.interface';
import { LeadUtil } from '../../utils/lead.util';
import {
  EmobilityLeadViewModel,
  HeatingLeadViewModel,
  LeadCompositeViewModel,
  LeadListViewModel,
  LeadSiblingsViewModel,
  LeadViewModel,
  MtLeadListViewModel,
  PageResultViewModelLeadListViewModel,
  PatchLeadVariantRequest,
  PvLeadViewModel,
  TaskListViewModel,
  VariantListCompositionViewModel,
  VariantListViewModel,
} from '../../apis/advis';
import { IProjectDescriptionViewModel } from '../../models/project-description-view-model';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export interface IMultipleSuccessInfo {
  success: number;
  failed: number;
}

export interface IState {
  mtCrmLeadsOwn: MtLeadListViewModel[];
  mtCrmLeadsOwnLoading: boolean;
  mtCrmLeadsOwnPaging: IPaging;
  mtCrmLeadsSearch: MtLeadListViewModel[];
  mtCrmLeadsSearchLoading: boolean;
  leadsNotAssigned: LeadListViewModel[];
  leadsNotAssignedLoading: boolean;
  leadsNotAssignedPaging: IPaging;
  projectsInProgress: LeadListViewModel[];
  projectsInProgressLoading: boolean;
  projectsInProgressPaging: IPaging;
  projectOverview: LeadListViewModel[];
  projectOverviewLoading: boolean;
  projectOverviewPaging: IPaging;
  projectOverviewOwn: LeadListViewModel[];
  projectOverviewOwnLoading: boolean;
  projectOverviewOwnPaging: IPaging;
  leadsOwn: LeadListViewModel[];
  leadsOwnLoaded: boolean;
  leadsOwnLoading: boolean;
  leadsWonPaging: IPaging;
  leadsWon: LeadListViewModel[];
  leadsWonLoaded: boolean;
  leadsWonLoading: boolean;
  leadsNewLoaded: boolean;
  leadsNewLoading: boolean;
  leadsNew: LeadListViewModel[];
  leadsNewPaging: IPaging;
  leadsFollowup: LeadListViewModel[];
  leadsFollowupLoaded: boolean;
  leadsFollowupLoading: boolean;
  leadsFollowupPaging: IPaging;
  leadsOrderEntry: LeadListViewModel[];
  leadsOrderEntryLoaded: boolean;
  leadsOrderEntryLoading: boolean;
  leadsOrderEntryPaging: IPaging;
  leadsOnHold: LeadListViewModel[];
  leadsOnHoldLoaded: boolean;
  leadsOnHoldLoading: boolean;
  leadsOnHoldPaging: IPaging;
  leadsOnlinepool: LeadListViewModel[];
  leadsOnlinepoolLoaded: boolean;
  leadsOnlinepoolLoading: boolean;
  leadsOnlinepoolPaging: IPaging;
  leadsCallpool: LeadListViewModel[];
  leadsCallpoolLoaded: boolean;
  leadsCallpoolLoading: boolean;
  leadsCallpoolPaging: IPaging;
  leadsDownloadpool: LeadListViewModel[];
  leadsDownloadpoolLoaded: boolean;
  leadsDownloadpoolLoading: boolean;
  leadsDownloadpoolPaging: IPaging;
  leadsAll: LeadListViewModel[];
  leadsAllPaging: IPaging;
  leadsAllLoaded: boolean;
  leadsAllLoading: boolean;
  leadsEnergySolutions: LeadListViewModel[];
  leadsEnergySolutionPaging: IPaging;
  leadsEnergySolutionLoading: boolean;
  leadsEnergySolutionLoaded: boolean;
  leadsTriage: LeadListViewModel[];
  leadsTriagePaging: IPaging;
  leadsTriageLoaded: boolean;
  leadsTriageLoading: boolean;
  leadsDetail: LeadCompositeViewModel;
  leadsDetailLoaded: boolean;
  leadsDetailLoading: boolean;
  leadSiblings: LeadSiblingsViewModel;
  leadSiblingsLoaded: boolean;
  leadSiblingsLoading: boolean;
  ownTaskList: TaskListViewModel[];
  ownTaskListLoading: boolean;
  ownTaskListLoaded: boolean;
  delegatedTaskList: TaskListViewModel[];
  delegatedTaskListLoading: boolean;
  delegatedTaskListLoaded: boolean;
  taskAddedSuccess: boolean;
  taskActionRunning: boolean;
  variantActionRunning: boolean;
  transferRunning: boolean;
  abortRunning: boolean;
  triageSuccess: boolean;
  transferMultipleRunning: boolean;
  transferMultipleSuccessInfo: IMultipleSuccessInfo;
  abortMultipleRunning: boolean;
  abortMultipleSuccessInfo: IMultipleSuccessInfo;
  releaseMultipleRunning: boolean;
  releaseMultipleSuccessInfo: IMultipleSuccessInfo;
  holdMultipleRunning: boolean;
  holdMultipleSuccessInfo: IMultipleSuccessInfo;
  projectProperties?: IProjectDescriptionViewModel;
  projectPropertiesLoaded: boolean;
  projectPropertiesLoading: boolean;
  searchedVariants: VariantListViewModel[];
  nextLeads: number[];
  emLeadData: EmobilityLeadViewModel;
  emLeadLoaded: boolean;
  emLeadLoading: boolean;
  htLeadData: HeatingLeadViewModel;
  htLeadLoaded: boolean;
  htLeadLoading: boolean;
  pvLeadData: PvLeadViewModel;
  pvLeadLoaded: boolean;
  pvLeadLoading: boolean;
  LeadSynchronizeSuccess: boolean;
  LeadSynchronizeFailed: boolean;
  LeadSynchronizeLoading: boolean;
}

export const initialState: IState = {
  mtCrmLeadsOwn: [],
  mtCrmLeadsOwnLoading: false,
  mtCrmLeadsOwnPaging: { ItemCount: 0, PageCount: 0 },
  mtCrmLeadsSearch: [],
  mtCrmLeadsSearchLoading: false,
  projectsInProgress: [],
  projectsInProgressLoading: false,
  projectsInProgressPaging: { ItemCount: 0, PageCount: 0 },
  projectOverview: [],
  projectOverviewLoading: false,
  projectOverviewPaging: { ItemCount: 0, PageCount: 0 },
  projectOverviewOwn: [],
  projectOverviewOwnLoading: false,
  projectOverviewOwnPaging: { ItemCount: 0, PageCount: 0 },
  leadsNotAssigned: [],
  leadsNotAssignedLoading: false,
  leadsNotAssignedPaging: { ItemCount: 0, PageCount: 0 },
  leadsOwn: [],
  leadsOwnLoaded: false,
  leadsOwnLoading: false,
  leadsWonLoaded: false,
  leadsWonLoading: false,
  leadsWon: [],
  leadsWonPaging: { ItemCount: 0, PageCount: 0 },
  leadsNewLoaded: false,
  leadsNewLoading: false,
  leadsNew: [],
  leadsNewPaging: { ItemCount: 0, PageCount: 0 },
  leadsFollowup: [],
  leadsFollowupLoaded: false,
  leadsFollowupLoading: false,
  leadsFollowupPaging: { ItemCount: 0, PageCount: 0 },
  leadsOrderEntry: [],
  leadsOrderEntryLoaded: false,
  leadsOrderEntryLoading: false,
  leadsOrderEntryPaging: { ItemCount: 0, PageCount: 0 },
  leadsOnHoldLoaded: false,
  leadsOnHoldLoading: false,
  leadsOnHold: [],
  leadsOnHoldPaging: { ItemCount: 0, PageCount: 0 },
  leadsOnlinepool: [],
  leadsOnlinepoolLoaded: false,
  leadsOnlinepoolLoading: false,
  leadsOnlinepoolPaging: { ItemCount: 0, PageCount: 0 },
  leadsCallpool: [],
  leadsCallpoolLoaded: false,
  leadsCallpoolLoading: false,
  leadsCallpoolPaging: { ItemCount: 0, PageCount: 0 },
  leadsDownloadpool: [],
  leadsDownloadpoolLoaded: false,
  leadsDownloadpoolLoading: false,
  leadsDownloadpoolPaging: { ItemCount: 0, PageCount: 0 },
  leadsAll: [],
  leadsAllPaging: { ItemCount: 0, PageCount: 0 },
  leadsAllLoaded: false,
  leadsAllLoading: false,
  leadsEnergySolutions: [],
  leadsEnergySolutionLoaded: false,
  leadsEnergySolutionLoading: false,
  leadsEnergySolutionPaging: { ItemCount: 0, PageCount: 0 },
  leadsTriage: [],
  leadsTriagePaging: { ItemCount: 0, PageCount: 0 },
  leadsTriageLoaded: false,
  leadsTriageLoading: false,
  leadsDetail: {},
  leadsDetailLoaded: false,
  leadsDetailLoading: false,
  leadSiblings: {},
  leadSiblingsLoaded: false,
  leadSiblingsLoading: false,
  ownTaskList: [],
  ownTaskListLoading: false,
  ownTaskListLoaded: false,
  delegatedTaskList: [],
  delegatedTaskListLoading: false,
  delegatedTaskListLoaded: false,
  taskAddedSuccess: false,
  taskActionRunning: false,
  variantActionRunning: false,
  transferRunning: false,
  abortRunning: false,
  triageSuccess: false,
  transferMultipleRunning: false,
  transferMultipleSuccessInfo: undefined,
  abortMultipleRunning: false,
  abortMultipleSuccessInfo: undefined,
  releaseMultipleRunning: false,
  releaseMultipleSuccessInfo: undefined,
  holdMultipleRunning: false,
  holdMultipleSuccessInfo: undefined,
  projectProperties: undefined,
  projectPropertiesLoaded: false,
  projectPropertiesLoading: false,
  searchedVariants: [],
  nextLeads: [],
  emLeadData: {},
  emLeadLoaded: false,
  emLeadLoading: false,
  htLeadData: {},
  htLeadLoaded: false,
  htLeadLoading: false,
  pvLeadData: {},
  pvLeadLoaded: false,
  pvLeadLoading: false,
  LeadSynchronizeSuccess: false,
  LeadSynchronizeFailed: false,
  LeadSynchronizeLoading: false,
};

export function reducer(
  state: IState = initialState,
  action: LeadAction.Actions | ActionsAction.Actions
): IState {
  switch (action.type) {
    case LeadAction.SYNCHRONIZE_LEAD: {
      return tassign(state, {
        LeadSynchronizeSuccess: false,
        LeadSynchronizeFailed: false,
        LeadSynchronizeLoading: true,
      });
    }
    case LeadAction.SYNCHRONIZE_LEAD_SUCCESS: {
      return tassign(state, {
        LeadSynchronizeSuccess: true,
        LeadSynchronizeFailed: false,
        LeadSynchronizeLoading: false,
      });
    }
    case LeadAction.SYNCHRONIZE_LEAD_FAILURE: {
      return tassign(state, {
        LeadSynchronizeSuccess: false,
        LeadSynchronizeFailed: true,
        LeadSynchronizeLoading: false,
      });
    }

    case LeadAction.OWN_MT_CRM_LOAD: {
      return tassign(state, {
        mtCrmLeadsOwnLoading: true,
      });
    }

    case LeadAction.OWN_MT_CRM_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        mtCrmLeadsOwn: action.payload,
        mtCrmLeadsOwnLoading: false,
        mtCrmLeadsOwnPaging: paging,
      });
    }

    case LeadAction.OWN_MT_CRM_LOAD_FAILURE: {
      return tassign(state, {
        mtCrmLeadsOwn: [],
        mtCrmLeadsOwnLoading: false,
        mtCrmLeadsOwnPaging: { ItemCount: 0, PageCount: 0 },
      });
    }

    case LeadAction.SEARCH_MT_CRM_LOAD: {
      return tassign(state, {
        mtCrmLeadsSearchLoading: true,
      });
    }

    case LeadAction.SEARCH_MT_CRM_LOAD_SUCCESS: {
      return tassign(state, {
        mtCrmLeadsSearch: action.payload,
        mtCrmLeadsSearchLoading: false,
      });
    }

    case LeadAction.SEARCH_MT_CRM_LOAD_FAILURE: {
      return tassign(state, {
        mtCrmLeadsSearch: [],
        mtCrmLeadsSearchLoading: false,
      });
    }

    case LeadAction.NOT_ASSIGNED_LOAD: {
      return tassign(state, {
        leadsNotAssignedLoading: true,
      });
    }

    case LeadAction.NOT_ASSIGNED_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsNotAssigned: action.payload.Entries,
        leadsNotAssignedPaging: paging,
        leadsNotAssignedLoading: false,
      });
    }

    case LeadAction.NOT_ASSIGNED_LOAD_FAILURE: {
      return tassign(state, {
        leadsNotAssigned: [],
        leadsNotAssignedPaging: { ItemCount: 0, PageCount: 0 },
        leadsNotAssignedLoading: false,
      });
    }

    case LeadAction.PROJECTS_IN_PROGRESS_LOAD: {
      return tassign(state, {
        projectsInProgressLoading: true,
      });
    }

    case LeadAction.PROJECTS_IN_PROGRESS_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        projectsInProgress: action.payload.Entries,
        projectsInProgressPaging: paging,
        projectsInProgressLoading: false,
      });
    }

    case LeadAction.PROJECTS_IN_PROGRESS_LOAD_FAILURE: {
      return tassign(state, {
        projectsInProgress: [],
        projectsInProgressPaging: { ItemCount: 0, PageCount: 0 },
        projectsInProgressLoading: false,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_LOAD: {
      return tassign(state, {
        projectOverviewLoading: true,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        projectOverview: action.payload.Entries,
        projectOverviewPaging: paging,
        projectOverviewLoading: false,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_LOAD_FAILURE: {
      return tassign(state, {
        projectOverview: [],
        projectOverviewPaging: { ItemCount: 0, PageCount: 0 },
        projectOverviewLoading: false,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_OWN_LOAD: {
      return tassign(state, {
        projectOverviewOwnLoading: true,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_OWN_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        projectOverviewOwn: action.payload.Entries,
        projectOverviewOwnPaging: paging,
        projectOverviewOwnLoading: false,
      });
    }

    case LeadAction.PROJECT_OVERVIEW_OWN_LOAD_FAILURE: {
      return tassign(state, {
        projectOverviewOwn: [],
        projectOverviewOwnPaging: { ItemCount: 0, PageCount: 0 },
        projectOverviewOwnLoading: false,
      });
    }

    case LeadAction.OWN_LOAD: {
      return tassign(state, {
        leadsOwnLoading: true,
      });
    }

    case LeadAction.OWN_LOAD_SUCCESS: {
      return tassign(state, {
        leadsOwn: action.payload,
        leadsOwnLoading: false,
        leadsOwnLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.OWN_LOAD_FAILURE: {
      return tassign(state, {
        leadsOwn: [],
        leadsOwnLoaded: false,
        leadsOwnLoading: false,
      });
    }

    case LeadAction.FOLLOWUP_LOAD: {
      return tassign(state, {
        leadsFollowupLoading: true,
      });
    }

    case LeadAction.FOLLOWUP_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsFollowup: action.payload.Entries,
        leadsFollowupPaging: paging,
        leadsFollowupLoading: false,
        leadsFollowupLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.FOLLOWUP_LOAD_FAILURE: {
      return tassign(state, {
        leadsFollowup: [],
        leadsFollowupPaging: { ItemCount: 0, PageCount: 0 },
        leadsFollowupLoading: false,
        leadsFollowupLoaded: false,
      });
    }

    case LeadAction.ORDER_ENTRY_LOAD: {
      return tassign(state, {
        leadsOrderEntryLoading: true,
      });
    }

    case LeadAction.ORDER_ENTRY_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsOrderEntry: action.payload.Entries,
        leadsOrderEntryPaging: paging,
        leadsOrderEntryLoading: false,
        leadsOrderEntryLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.ORDER_ENTRY_LOAD_FAILURE: {
      return tassign(state, {
        leadsOrderEntry: [],
        leadsOrderEntryPaging: { ItemCount: 0, PageCount: 0 },
        leadsOrderEntryLoading: false,
        leadsOrderEntryLoaded: false,
      });
    }

    case LeadAction.ONHOLD_LOAD: {
      return tassign(state, {
        leadsOnHoldLoading: true,
      });
    }

    case LeadAction.ONHOLD_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsOnHold: action.payload.Entries,
        leadsOnHoldPaging: paging,
        leadsOnHoldLoading: false,
        leadsOnHoldLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.WON_LOAD: {
      return tassign(state, {
        leadsWonLoading: true,
      });
    }

    case LeadAction.WON_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsWon: action.payload.Entries,
        leadsWonPaging: paging,
        leadsWonLoading: false,
        leadsWonLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.WON_LOAD_FAILURE: {
      return tassign(state, {
        leadsWon: [],
        leadsWonPaging: { ItemCount: 0, PageCount: 0 },
        leadsWonLoading: false,
        leadsWonLoaded: false,
      });
    }

    case LeadAction.NEW_LOAD: {
      return tassign(state, {
        leadsNewLoading: true,
      });
    }

    case LeadAction.NEW_LOAD_SUCCESS: {
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsNew: action.payload.Entries,
        leadsNewPaging: paging,
        leadsNewLoading: false,
        leadsNewLoaded: true,
        nextLeads: [],
      });
    }

    case LeadAction.NEW_LOAD_FAILURE: {
      return tassign(state, {
        leadsNew: [],
        leadsNewPaging: { ItemCount: 0, PageCount: 0 },
        leadsNewLoading: false,
        leadsNewLoaded: false,
      });
    }

    case LeadAction.TAKEOVER_SUCCESS: {
      let leadsOwn: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      leadsOwn = leadsOwn.filter((lead: LeadListViewModel) => {
        return lead.Id !== +action.payload.leadId;
      });

      let leadsAll: LeadListViewModel[] = cloneDeep(state.leadsAll);
      leadsAll = leadsAll.map((lead: LeadListViewModel) => {
        if (lead.Id === action.payload.leadId) {
          lead.OwnerId = action.payload.userId;
        }
        return lead;
      });

      return tassign(state, {
        leadsOwn: leadsOwn,
        leadsAll: leadsAll,
      });
    }

    case LeadAction.ONLINEPOOL_LOAD: {
      return tassign(state, {
        leadsOnlinepoolLoading: true,
      });
    }

    case LeadAction.ONLINEPOOL_LOAD_SUCCESS: {
      const leads: LeadListViewModel[] = action.payload.Entries;
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsOnlinepool: leads,
        leadsOnlinepoolLoaded: true,
        leadsOnlinepoolLoading: false,
        leadsOnlinepoolPaging: paging,
        nextLeads: [],
      });
    }

    case LeadAction.ONLINEPOOL_LOAD_FAILURE: {
      return tassign(state, {
        leadsOnlinepool: [],
        leadsOnlinepoolLoaded: false,
        leadsOnlinepoolLoading: false,
        leadsOnlinepoolPaging: { ItemCount: 0, PageCount: 0 },
      });
    }

    // call
    case LeadAction.CALLPOOL_LOAD: {
      return tassign(state, {
        leadsCallpoolLoading: true,
      });
    }

    case LeadAction.CALLPOOL_LOAD_SUCCESS: {
      const leads: LeadListViewModel[] = action.payload.Entries;
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsCallpool: leads,
        leadsCallpoolLoaded: true,
        leadsCallpoolLoading: false,
        leadsCallpoolPaging: paging,
        nextLeads: [],
      });
    }

    case LeadAction.CALLPOOL_LOAD_FAILURE: {
      return tassign(state, {
        leadsCallpool: [],
        leadsCallpoolLoaded: false,
        leadsCallpoolLoading: false,
        leadsCallpoolPaging: { ItemCount: 0, PageCount: 0 },
      });
    }

    // download
    case LeadAction.DOWNLOADPOOL_LOAD: {
      return tassign(state, {
        leadsDownloadpoolLoading: true,
      });
    }

    case LeadAction.DOWNLOADPOOL_LOAD_SUCCESS: {
      const leads: LeadListViewModel[] = action.payload.Entries;
      const paging: IPaging = {
        ItemCount: action.payload.ItemCount,
        PageCount: action.payload.PageCount,
      };
      return tassign(state, {
        leadsDownloadpool: leads,
        leadsDownloadpoolLoaded: true,
        leadsDownloadpoolLoading: false,
        leadsDownloadpoolPaging: paging,
        nextLeads: [],
      });
    }

    case LeadAction.DOWNLOADPOOL_LOAD_FAILURE: {
      return tassign(state, {
        leadsDownloadpool: [],
        leadsDownloadpoolLoaded: false,
        leadsDownloadpoolLoading: false,
        leadsDownloadpoolPaging: { ItemCount: 0, PageCount: 0 },
      });
    }

    case LeadAction.ALL_CLEAR: {
      return tassign(state, {
        leadsAll: [],
        leadsAllPaging: { ItemCount: 0, PageCount: 0 },
        leadsAllLoaded: false,
        leadsAllLoading: false,
      });
    }

    case LeadAction.ALL_LOAD: {
      return tassign(state, {
        leadsAllLoading: true,
      });
    }

    case LeadAction.ALL_LOAD_SUCCESS: {
      const pageResult: PageResultViewModelLeadListViewModel = action.payload;
      const paging: IPaging = {
        ItemCount: pageResult.ItemCount,
        PageCount: pageResult.PageCount,
      };

      return tassign(state, {
        leadsAll: pageResult.Entries,
        leadsAllPaging: paging,
        leadsAllLoaded: true,
        leadsAllLoading: false,
        nextLeads: [],
      });
    }

    case LeadAction.ALL_LOAD_FAILURE: {
      return tassign(state, {
        leadsAll: [],
        leadsAllPaging: { ItemCount: 0, PageCount: 0 },
        leadsAllLoaded: false,
        leadsAllLoading: false,
      });
    }

    case LeadAction.ENERGY_SOLUTIONS_LOAD: {
      return tassign(state, {
        leadsEnergySolutionLoading: true,
      });
    }

    case LeadAction.ENERGY_SOLUTIONS_LOAD_SUCCESS: {
      const pageResult: PageResultViewModelLeadListViewModel = action.payload;
      const paging: IPaging = {
        ItemCount: pageResult.ItemCount,
        PageCount: pageResult.PageCount,
      };

      return tassign(state, {
        leadsEnergySolutions: pageResult.Entries,
        leadsEnergySolutionPaging: paging,
        leadsEnergySolutionLoaded: true,
        leadsEnergySolutionLoading: false,
        nextLeads: [],
      });
    }

    case LeadAction.ENERGY_SOLUTIONS_LOAD_FAILURE: {
      return tassign(state, {
        leadsEnergySolutions: [],
        leadsEnergySolutionPaging: { ItemCount: 0, PageCount: 0 },
        leadsEnergySolutionLoaded: false,
        leadsEnergySolutionLoading: false,
      });
    }

    case LeadAction.TRIAGE_CLEAR: {
      return tassign(state, {
        leadsTriage: [],
        leadsTriagePaging: { ItemCount: 0, PageCount: 0 },
        leadsTriageLoaded: false,
        leadsTriageLoading: false,
      });
    }

    case LeadAction.TRIAGE_LOAD: {
      return tassign(state, {
        leadsTriageLoading: true,
      });
    }

    case LeadAction.TRIAGE_LOAD_SUCCESS: {
      const pageResult: PageResultViewModelLeadListViewModel = action.payload;
      const paging: IPaging = {
        ItemCount: pageResult.ItemCount,
        PageCount: pageResult.PageCount,
      };

      return tassign(state, {
        leadsTriage: pageResult.Entries,
        leadsTriagePaging: paging,
        leadsTriageLoaded: true,
        leadsTriageLoading: false,
      });
    }

    case LeadAction.TRIAGE_LOAD_FAILURE: {
      return tassign(state, {
        leadsTriage: [],
        leadsTriagePaging: { ItemCount: 0, PageCount: 0 },
        leadsTriageLoaded: false,
        leadsTriageLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD: {
      return tassign(state, {
        leadsDetailLoaded: false,
        leadsDetailLoading: true,
      });
    }

    case LeadAction.DETAIL_LOAD_SUCCESS:
    case LeadAction.APPROVE_SOLAR_TARIF_SUCCESS:
    case LeadAction.UNLOCK_LEAD_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        leadsDetailLoaded: true,
        leadsDetailLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD_FAILURE: {
      return tassign(state, {
        leadsDetail: {},
        leadsDetailLoaded: false,
        leadsDetailLoading: false,
      });
    }

    case LeadAction.SIBLINGS_LOAD: {
      return tassign(state, {
        leadSiblings: {},
        leadSiblingsLoaded: false,
        leadSiblingsLoading: true,
      });
    }

    case LeadAction.SIBLINGS_LOAD_SUCCESS: {
      return tassign(state, {
        leadSiblings: action.payload,
        leadSiblingsLoaded: true,
        leadSiblingsLoading: false,
      });
    }

    case LeadAction.SIBLINGS_LOAD_FAILURE: {
      return tassign(state, {
        leadSiblings: {},
        leadSiblingsLoaded: false,
        leadSiblingsLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD_EM_LEAD: {
      return tassign(state, {
        emLeadLoading: true,
      });
    }

    case LeadAction.DETAIL_LOAD_EM_LEAD_SUCCESS: {
      return tassign(state, {
        emLeadData: action.payload,
        emLeadLoaded: true,
        emLeadLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD_EM_LEAD_FAILURE: {
      return tassign(state, {
        emLeadData: {},
        emLeadLoaded: false,
        emLeadLoading: false,
      });
    }

    case LeadAction.PATCH_EM_LEAD: {
      return tassign(state, {
        emLeadLoading: true,
      });
    }

    case LeadAction.PATCH_EM_LEAD_SUCCESS: {
      return tassign(state, {
        emLeadData: action.payload,
        emLeadLoaded: true,
        emLeadLoading: false,
      });
    }

    case LeadAction.PATCH_EM_LEAD_FAILURE: {
      return tassign(state, {
        emLeadData: {},
        emLeadLoaded: false,
        emLeadLoading: false,
      });
    }
    // HT
    case LeadAction.DETAIL_LOAD_HT_LEAD: {
      return tassign(state, {
        htLeadLoading: true,
      });
    }

    case LeadAction.DETAIL_LOAD_HT_LEAD_SUCCESS: {
      return tassign(state, {
        htLeadData: action.payload,
        htLeadLoaded: true,
        htLeadLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD_HT_LEAD_FAILURE: {
      return tassign(state, {
        htLeadData: {},
        htLeadLoaded: false,
        htLeadLoading: false,
      });
    }

    case LeadAction.PATCH_HT_LEAD: {
      return tassign(state, {
        htLeadLoading: true,
      });
    }

    case LeadAction.PATCH_HT_LEAD_SUCCESS: {
      return tassign(state, {
        htLeadData: action.payload,
        htLeadLoaded: true,
        htLeadLoading: false,
      });
    }

    case LeadAction.PATCH_HT_LEAD_FAILURE: {
      return tassign(state, {
        htLeadData: {},
        htLeadLoaded: false,
        htLeadLoading: false,
      });
    }

    // PV
    case LeadAction.DETAIL_LOAD_PV_LEAD: {
      return tassign(state, {
        pvLeadLoading: true,
      });
    }

    case LeadAction.DETAIL_LOAD_PV_LEAD_SUCCESS: {
      return tassign(state, {
        pvLeadData: action.payload,
        pvLeadLoaded: true,
        pvLeadLoading: false,
      });
    }

    case LeadAction.DETAIL_LOAD_PV_LEAD_FAILURE: {
      return tassign(state, {
        pvLeadData: {},
        pvLeadLoaded: false,
        pvLeadLoading: false,
      });
    }

    case LeadAction.PATCH_PV_LEAD: {
      return tassign(state, {
        pvLeadLoading: true,
      });
    }

    case LeadAction.PATCH_PV_LEAD_SUCCESS: {
      return tassign(state, {
        pvLeadData: action.payload,
        pvLeadLoaded: true,
        pvLeadLoading: false,
      });
    }

    case LeadAction.PATCH_PV_LEAD_FAILURE: {
      return tassign(state, {
        pvLeadLoaded: false,
        pvLeadLoading: false,
      });
    }

    // TASK
    case LeadAction.TASKS_LOAD_OWN: {
      return tassign(state, {
        ownTaskListLoading: true,
      });
    }

    case LeadAction.TASKS_LOAD_OWN_SUCCESS: {
      return tassign(state, {
        ownTaskList: action.payload,
        ownTaskListLoaded: true,
        ownTaskListLoading: false,
      });
    }

    case LeadAction.TASKS_LOAD_OWN_FAILURE: {
      return tassign(state, {
        ownTaskList: {},
        ownTaskListLoading: false,
        ownTaskListLoaded: false,
      });
    }

    case LeadAction.TASKS_LOAD_DELEGATED: {
      return tassign(state, {
        delegatedTaskListLoading: true,
      });
    }

    case LeadAction.TASKS_LOAD_DELEGATED_SUCCESS: {
      return tassign(state, {
        delegatedTaskList: action.payload,
        delegatedTaskListLoaded: true,
        delegatedTaskListLoading: false,
      });
    }

    case LeadAction.TASKS_LOAD_DELEGATED_FAILURE: {
      return tassign(state, {
        delegatedTaskList: {},
        delegatedTaskListLoading: false,
        delegatedTaskListLoaded: false,
      });
    }

    case LeadAction.DETAIL_ADD_TASK: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_ADD_TASK_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.DIALOG_ADD_TASK_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskAddedSuccess: true,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_ADD_TASK_FAILURE: {
      console.error(`Add Task failed="${action.payload}"`);
      return tassign(state, {
        taskAddedSuccess: false,
        taskActionRunning: false,
      });
    }

    case LeadAction.DIALOG_ADD_TASK_SUCCESS_RESET: {
      return tassign(state, {
        taskAddedSuccess: false,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_UPDATE_APPOINTMENT: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_UPDATE_APPOINTMENT_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_UPDATE_APPOINTMENT_FAILURE: {
      console.error(`Update Task failed="${action.payload}"`);

      return tassign(state, {
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_ADD_APPOINTMENT: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_ADD_APPOINTMENT_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_ADD_APPOINTMENT_FAILURE: {
      console.error(`Update Task failed="${action.payload}"`);

      return tassign(state, {
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_UPDATE_TASK: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_UPDATE_TASK_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_UPDATE_TASK_FAILURE: {
      console.error(`Update Task failed="${action.payload}"`);

      return tassign(state, {
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_REMOVE_TASK: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_REMOVE_TASK_SUCCESS: {
      const leadComposite: LeadCompositeViewModel = action.payload;
      return tassign(state, {
        leadsDetail: leadComposite,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_REMOVE_TASK_FAILURE: {
      console.error(`Update appointment failed="${action.payload}"`);

      return tassign(state, {
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_REMOVE_APPOINTMENT: {
      return tassign(state, {
        taskActionRunning: true,
      });
    }

    case LeadAction.DETAIL_REMOVE_APPOINTMENT_SUCCESS: {
      const leadComposite: LeadCompositeViewModel = action.payload;
      return tassign(state, {
        leadsDetail: leadComposite,
        taskActionRunning: false,
      });
    }

    case LeadAction.DETAIL_REMOVE_APPOINTMENT_FAILURE: {
      console.error(`Remove Task failed="${action.payload}"`);
      return tassign(state, {
        taskActionRunning: false,
      });
    }

    // Invoices
    case LeadAction.UPDATE_INVOICES_SUCCESS:
    case LeadAction.UPDATE_INVOICE_STATUS_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
      });
    }

    case LeadAction.ADD_FINAL_INVOICES_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
      });
    }

    case LeadAction.UPDATE_INVOICES_FAILURE: {
      console.error(`Final Invoice failed="${action.payload}"`);
      return state;
    }

    case LeadAction.REQUEST_DUNNING_RUN_BLOCKER_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
      });
    }

    case LeadAction.REQUEST_DUNNING_RUN_BLOCKER_FAILURE: {
      console.error(`Request Dunning Run Blocker failed"`, action.payload);
      return state;
    }

    case LeadAction.DETAIL_CANCEL_VARIANT: {
      return tassign(state, {
        variantActionRunning: true,
      });
    }

    case LeadAction.DETAIL_CANCEL_VARIANT_SUCCESS: {
      const leadComposite: LeadCompositeViewModel = action.payload;
      return tassign(state, {
        leadsDetail: leadComposite,
        variantActionRunning: false,
      });
    }

    case LeadAction.DETAIL_CANCEL_VARIANT_FAILURE: {
      console.error(`Remove Variant failed="${action.payload}"`);
      return tassign(state, {
        variantActionRunning: false,
      });
    }

    case LeadAction.DETAIL_CLONE_VARIANT: {
      return tassign(state, {
        variantActionRunning: true,
      });
    }

    case LeadAction.DETAIL_CLONE_VARIANT_SUCCESS: {
      const leadComposite: LeadCompositeViewModel = action.payload;
      return tassign(state, {
        leadsDetail: leadComposite,
        variantActionRunning: false,
      });
    }

    case LeadAction.DETAIL_CLONE_VARIANT_FAILURE: {
      console.error(`Clone variante failed="${action.payload}"`);
      return tassign(state, {
        variantActionRunning: false,
      });
    }

    case LeadAction.COPY_VARIANT_SUCCESS: {
      const leadComposite: LeadCompositeViewModel = action.payload;
      return tassign(state, {
        leadsDetail: leadComposite,
      });
    }

    case LeadAction.COPY_VARIANT_FAILURE: {
      console.error(`Copy variante failed="${action.payload}"`);
      return state;
    }

    case LeadAction.SEARCH_VARIANT_SUCCESS: {
      const variants: VariantListViewModel[] = action.payload;
      return tassign(state, {
        searchedVariants: variants,
      });
    }

    case LeadAction.SEARCH_VARIANT_FAILURE: {
      console.error(`Search variants failed="${action.payload}"`);
      return state;
    }

    case LeadAction.SET_NEXT_LEAD_LIST: {
      return tassign(state, {
        nextLeads: action.payload,
      });
    }

    case LeadAction.UPDATE_LEAD_SUCCESS: {
      const leadId: number = action.payload.leadId;
      const lead: LeadViewModel = action.payload.lead;

      const leadsAllCloned: LeadListViewModel[] = cloneDeep(state.leadsAll);
      if (updateLeadListTracersArr(leadsAllCloned, leadId, lead.Tracers)) {
        state = tassign(state, {
          leadsAll: leadsAllCloned,
        });
      }

      if (updateLeadListRatingArr(leadsAllCloned, leadId, lead.Rating)) {
        state = tassign(state, {
          leadsAll: leadsAllCloned,
        });
      }

      const leadsFollowUpCloned = cloneDeep(state.leadsFollowup);
      if (updateLeadListRatingArr(leadsFollowUpCloned, leadId, lead.Rating)) {
        state = tassign(state, {
          leadsFollowup: leadsFollowUpCloned,
        });
      }

      const leadsOnHoldCloned = cloneDeep(state.leadsOnHold);
      if (updateLeadListRatingArr(leadsOnHoldCloned, leadId, lead.Rating)) {
        state = tassign(state, {
          leadsFollowup: leadsOnHoldCloned,
        });
      }

      const leadsNewCloned = cloneDeep(state.leadsNew);
      if (updateLeadListRatingArr(leadsNewCloned, leadId, lead.Rating)) {
        state = tassign(state, {
          leadsFollowup: leadsNewCloned,
        });
      }

      const leadsDetailCloned: LeadCompositeViewModel = cloneDeep(state.leadsDetail);
      if (updateLeadTracers(leadsDetailCloned.Lead, lead.Tracers)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
        });
      }
      if (updateLeadRating(leadsDetailCloned.Lead, lead.Rating)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
        });
      }

      if (updateLeadProviderLeadId(leadsDetailCloned.Lead, lead.ProviderLeadId)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
        });
      }

      return state;
    }

    case LeadAction.UPDATE_LEAD_TAG_SUCCESS: {
      const leadId: number = action.payload.leadId;
      const tag: string = action.payload.tag;

      const leadsOwnCloned: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      if (updateLeadListTagArr(leadsOwnCloned, leadId, tag)) {
        state = tassign(state, {
          leadsOwn: leadsOwnCloned,
        });
      }

      const leadsOnHoldCloned = cloneDeep(state.leadsOnHold);
      if (updateLeadListTagArr(leadsOnHoldCloned, leadId, tag)) {
        state = tassign(state, {
          leadsOnHold: leadsOnHoldCloned,
        });
      }

      const leadsNewCloned = cloneDeep(state.leadsNew);
      if (updateLeadListTagArr(leadsNewCloned, leadId, tag)) {
        state = tassign(state, {
          leadsNew: leadsNewCloned,
        });
      }

      const leadsFollowUpCloned = cloneDeep(state.leadsFollowup);
      if (updateLeadListTagArr(leadsFollowUpCloned, leadId, tag)) {
        state = tassign(state, {
          leadsFollowup: leadsFollowUpCloned,
        });
      }

      const leadsAllCloned: LeadListViewModel[] = cloneDeep(state.leadsAll);
      if (updateLeadListTagArr(leadsAllCloned, leadId, tag)) {
        state = tassign(state, {
          leadsAll: leadsAllCloned,
        });
      }

      const leadsOnlinepoolCloned: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      if (updateLeadListTagArr(leadsOnlinepoolCloned, leadId, tag)) {
        state = tassign(state, {
          leadsOnlinepool: leadsOnlinepoolCloned,
        });
      }

      const leadsDownloadPoolCloned: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      if (updateLeadListTagArr(leadsDownloadPoolCloned, leadId, tag)) {
        state = tassign(state, {
          leadsDownloadpool: leadsDownloadPoolCloned,
        });
      }

      const leadsCallpoolCloned: LeadListViewModel[] = cloneDeep(state.leadsCallpool);
      if (updateLeadListTagArr(leadsCallpoolCloned, leadId, tag)) {
        state = tassign(state, {
          leadsCallpool: leadsCallpoolCloned,
        });
      }

      const leadsDetailCloned: LeadCompositeViewModel = cloneDeep(state.leadsDetail);
      if (updateLeadTag(leadsDetailCloned.Lead, tag)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
        });
      }

      return state;
    }

    case LeadAction.UPDATE_LEAD_COMPLEXITY_TYPE_SUCCESS: {
      const complexityType: string = action.payload.complexityType;

      const leadsDetailCloned: LeadCompositeViewModel = cloneDeep(state.leadsDetail);
      if (updateLeadComplexityType(leadsDetailCloned.Lead, complexityType)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
        });
      }

      return state;
    }

    case LeadAction.UPDATE_VARIANT_SALES_PROBABILITY: {
      return tassign(state, {
        variantActionRunning: true,
      });
    }

    case LeadAction.UPDATE_VARIANT_SALES_PROBABILITY_SUCCESS: {
      const variantId: number = action.payload.variantId;
      const salesProbability: number = action.payload.salesProbability;

      const leadsDetailCloned: LeadCompositeViewModel = cloneDeep(state.leadsDetail);
      if (updateVariantSalesProbability(leadsDetailCloned, variantId, salesProbability)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
          variantActionRunning: false,
        });
      }

      return state;
    }

    case LeadAction.UPDATE_VARIANT: {
      const variantId: number = action.payload.variantId;

      const leadsDetailCloned: LeadCompositeViewModel = cloneDeep(state.leadsDetail);
      if (updateVariant(leadsDetailCloned, variantId, action.payload.body)) {
        state = tassign(state, {
          leadsDetail: leadsDetailCloned,
          variantActionRunning: false,
        });
      }

      return state;
    }

    case LeadAction.UPDATE_VARIANT_SALES_PROBABILITY_FAILURE: {
      return tassign(state, {
        variantActionRunning: false,
      });
    }

    case ActionsAction.ACTION_ABORT: {
      return tassign(state, {
        abortRunning: true,
      });
    }

    case ActionsAction.ACTION_RELEASE_SUCCESS: {
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);
      return tassign(state, {
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_TRANSFER_BACK_SUCCESS: {
      const leadsTriage: LeadListViewModel[] = cloneDeep(state.leadsTriage);
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);
      return tassign(state, {
        leadsDetail: action.payload,
        leadsTriage: LeadUtil.filterOutEqualId(leadsTriage, lead),
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_NBO_SENT_SUCCESS:
    case ActionsAction.ACTION_BO_SENT_SUCCESS:
    case ActionsAction.ACTION_ABORT_SUCCESS: {
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);
      let leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      let leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      let leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);

      leadsOnlinepool = leadsOnlinepool.filter((leadVm: LeadListViewModel) => {
        return leadVm.Id !== lead.Id;
      });

      leadsDownloadPool = leadsDownloadPool.filter((leadVm: LeadListViewModel) => {
        return leadVm.Id !== lead.Id;
      });

      leadsCallPool = leadsCallPool.filter((leadVm: LeadListViewModel) => {
        return leadVm.Id !== lead.Id;
      });

      return tassign(state, {
        abortRunning: false,
        leadsDetail: action.payload,
        leadsOwn: updateOwnLeads(lead),
        leadsAll: updateAllLeads(lead),
        leadsDownloadpool: leadsDownloadPool,
        leadsCallpool: leadsCallPool,
        leadsOnlinepool: leadsOnlinepool,
      });
    }

    case ActionsAction.ACTION_ON_HOLD_SUCCESS:
    case ActionsAction.ACTION_CONTACT_SUCCESS:
    case ActionsAction.ACTION_UNREACHED_SUCCESS:
    case ActionsAction.ACTION_CONTACT_AND_TS_WIN_SUCCESS:
    case ActionsAction.ACTION_WIN_SUCCESS:
    case ActionsAction.ACTION_ORDER_BACK_TO_FOLLOWUP_SUCCESS:
    case ActionsAction.ACTION_START_ORDER_SUCCESS:
    case ActionsAction.ACTION_DELEGATE_SUCCESS:
    case ActionsAction.ACTION_SEND_BACK_SUCCESS:
    case ActionsAction.ACTION_PROJECT_START_SUCCESS:
    case ActionsAction.ACTION_PROJECT_FINISH_SUCCESS:
    case ActionsAction.ACTION_PROJECT_ACCOUNT_SUCCESS:
    case ActionsAction.ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_SUCCESS:
    case ActionsAction.ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_SUCCESS:
    case ActionsAction.ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_SUCCESS:
    case ActionsAction.ACTION_PROJECT_ARCHIVED_SUCCESS: {
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);
      return tassign(state, {
        leadsDetail:
          action.payload.Lead.Id === state.leadsDetail.Lead.Id ? action.payload : state.leadsDetail,
        leadsOwn: updateOwnLeads(lead),
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_TRANSFER: {
      return tassign(state, {
        transferRunning: true,
      });
    }

    case ActionsAction.ACTION_TRANSFER_SUCCESS: {
      const leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      const leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      const leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);

      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);

      return tassign(state, {
        transferRunning: false,
        leadsOnlinepool: LeadUtil.filterOutEqualId(leadsOnlinepool, lead),
        leadsDownloadpool: LeadUtil.filterOutEqualId(leadsDownloadPool, lead),
        leadsCallpool: LeadUtil.filterOutEqualId(leadsCallPool, lead),
        leadsDetail: action.payload,
        leadsOwn: updateOwnLeads(lead),
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_TRANSFER_FAILURE: {
      return tassign(state, {
        transferRunning: false,
      });
    }

    case ActionsAction.ACTION_TRANSFER_MULTIPLE: {
      return tassign(state, {
        transferMultipleRunning: true,
        transferMultipleSuccessInfo: undefined,
      });
    }

    case ActionsAction.ACTION_TRANSFER_MULTIPLE_SUCCESS: {
      const transferMultipleSuccessPayload: IActionMultipleSuccessPayload = action.payload;
      const leads: LeadListViewModel[] = LeadUtil.mapLeadViewModelToLeadListViewModelArr(
        transferMultipleSuccessPayload.leads
      );
      const failed: IActionTransferPayload[] = transferMultipleSuccessPayload.failed;

      let leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      let leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      let leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);
      let leadsOwn: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      let leadsAll: LeadListViewModel[] = cloneDeep(state.leadsAll);

      leads.forEach((leadListVm: LeadListViewModel) => {
        leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, leadListVm);
        leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, leadListVm);
        leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, leadListVm);
        leadsOwn = updateLead(leadsOwn, leadListVm);
        leadsAll = updateLead(leadsAll, leadListVm);
      });

      failed.forEach((actionTransferPayload: IActionTransferPayload) => {
        const lead: LeadListViewModel = LeadUtil.findLeadById(
          actionTransferPayload.leadId,
          leadsOnlinepool
        );
        if (lead) {
          leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, lead);
          leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, lead);
          leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, lead);
        }
      });

      return tassign(state, {
        transferMultipleRunning: false,
        transferMultipleSuccessInfo: {
          success: transferMultipleSuccessPayload.leads.length,
          failed: transferMultipleSuccessPayload.failed.length,
        },
        leadsOnlinepool: leadsOnlinepool,
        leadsDownloadpool: leadsDownloadPool,
        leadsCallpool: leadsCallPool,
        leadsOwn: leadsOwn,
        leadsAll: leadsAll,
      });
    }

    case ActionsAction.ACTION_ABORT_MULTIPLE: {
      return tassign(state, {
        abortMultipleRunning: true,
        abortMultipleSuccessInfo: undefined,
      });
    }

    case ActionsAction.ACTION_ABORT_MULTIPLE_SUCCESS: {
      const abortMultipleSuccessPayload: IActionMultipleSuccessPayload = action.payload;
      const leads: LeadListViewModel[] = LeadUtil.mapLeadViewModelToLeadListViewModelArr(
        abortMultipleSuccessPayload.leads
      );
      const failed: IActionTransferPayload[] = abortMultipleSuccessPayload.failed;

      let leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      let leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      let leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);

      let leadsOwn: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      let leadsAll: LeadListViewModel[] = cloneDeep(state.leadsAll);

      leads.forEach((leadVm: LeadListViewModel) => {
        leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, leadVm);
        leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, leadVm);
        leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, leadVm);
        leadsOwn = updateLead(leadsOwn, leadVm);
        leadsAll = updateLead(leadsAll, leadVm);
      });

      failed.forEach((actionTransferPayload: IActionTransferPayload) => {
        const lead: LeadListViewModel = LeadUtil.findLeadById(
          actionTransferPayload.leadId,
          leadsOnlinepool
        );
        if (lead) {
          leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, lead);
          leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, lead);
          leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, lead);
        }
      });

      return tassign(state, {
        abortMultipleRunning: false,
        abortMultipleSuccessInfo: {
          success: abortMultipleSuccessPayload.leads.length,
          failed: abortMultipleSuccessPayload.failed.length,
        },
        leadsOnlinepool: leadsOnlinepool,
        leadsDownloadpool: leadsDownloadPool,
        leadsCallpool: leadsCallPool,
        leadsOwn: leadsOwn,
        leadsAll: leadsAll,
      });
    }

    case ActionsAction.ACTION_RELEASE_MULTIPLE: {
      return tassign(state, {
        releaseMultipleRunning: true,
        releaseMultipleSuccessInfo: undefined,
      });
    }

    case ActionsAction.ACTION_RELEASE_MULTIPLE_SUCCESS: {
      const releaseMultipleSuccessPayload: IActionMultipleSuccessPayload = action.payload;
      const leads: LeadListViewModel[] = LeadUtil.mapLeadViewModelToLeadListViewModelArr(
        releaseMultipleSuccessPayload.leads
      );
      const failed: IActionTransferPayload[] = releaseMultipleSuccessPayload.failed;

      let leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      let leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      let leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);

      let leadsOwn: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      let leadsAll: LeadListViewModel[] = cloneDeep(state.leadsAll);

      leads.forEach((leadVm: LeadListViewModel) => {
        leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, leadVm);
        leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, leadVm);
        leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, leadVm);
        leadsOwn = updateLead(leadsOwn, leadVm);
        leadsAll = updateLead(leadsAll, leadVm);
      });

      failed.forEach((actionTransferPayload: IActionTransferPayload) => {
        const lead: LeadListViewModel = LeadUtil.findLeadById(
          actionTransferPayload.leadId,
          leadsOnlinepool
        );
        if (lead) {
          leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, lead);
          leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, lead);
          leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, lead);
        }
      });

      return tassign(state, {
        releaseMultipleRunning: false,
        releaseMultipleSuccessInfo: {
          success: releaseMultipleSuccessPayload.leads.length,
          failed: releaseMultipleSuccessPayload.failed.length,
        },
        leadsOnlinepool: leadsOnlinepool,
        leadsDownloadpool: leadsDownloadPool,
        leadsCallpool: leadsCallPool,
        leadsOwn: leadsOwn,
        leadsAll: leadsAll,
      });
    }

    case ActionsAction.ACTION_HOLD_MULTIPLE: {
      return tassign(state, {
        holdMultipleRunning: true,
        holdMultipleSuccessInfo: undefined,
      });
    }

    case ActionsAction.ACTION_HOLD_MULTIPLE_SUCCESS: {
      const holdMultipleSuccessPayload: IActionMultipleSuccessPayload = action.payload;
      const leads: LeadListViewModel[] = LeadUtil.mapLeadViewModelToLeadListViewModelArr(
        holdMultipleSuccessPayload.leads
      );
      const failed: IActionTransferPayload[] = holdMultipleSuccessPayload.failed;

      let leadsOnlinepool: LeadListViewModel[] = cloneDeep(state.leadsOnlinepool);
      let leadsDownloadPool: LeadListViewModel[] = cloneDeep(state.leadsDownloadpool);
      let leadsCallPool: LeadListViewModel[] = cloneDeep(state.leadsCallpool);

      let leadsOwn: LeadListViewModel[] = cloneDeep(state.leadsOwn);
      let leadsAll: LeadListViewModel[] = cloneDeep(state.leadsAll);

      leads.forEach((leadVm: LeadListViewModel) => {
        leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, leadVm);
        leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, leadVm);
        leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, leadVm);
        leadsOwn = updateLead(leadsOwn, leadVm);
        leadsAll = updateLead(leadsAll, leadVm);
      });

      failed.forEach((actionTransferPayload: IActionTransferPayload) => {
        const lead: LeadListViewModel = LeadUtil.findLeadById(
          actionTransferPayload.leadId,
          leadsOnlinepool
        );
        if (lead) {
          leadsOnlinepool = LeadUtil.filterOutEqualId(leadsOnlinepool, lead);
          leadsDownloadPool = LeadUtil.filterOutEqualId(leadsDownloadPool, lead);
          leadsCallPool = LeadUtil.filterOutEqualId(leadsCallPool, lead);
        }
      });

      return tassign(state, {
        holdMultipleRunning: false,
        holdMultipleSuccessInfo: {
          success: holdMultipleSuccessPayload.leads.length,
          failed: holdMultipleSuccessPayload.failed.length,
        },
        leadsOnlinepool: leadsOnlinepool,
        leadsDownloadpool: leadsDownloadPool,
        leadsCallpool: leadsCallPool,
        leadsOwn: leadsOwn,
        leadsAll: leadsAll,
      });
    }

    case ActionsAction.ACTION_TRIAGE: {
      return tassign(state, {
        triageSuccess: false,
      });
    }

    case ActionsAction.ACTION_TRIAGE_SUCCESS: {
      const leadsTriage: LeadListViewModel[] = cloneDeep(state.leadsTriage);
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);

      return tassign(state, {
        triageSuccess: true,
        leadsDetail: action.payload,
        leadsTriage: LeadUtil.filterOutEqualId(leadsTriage, lead),
        leadsOwn: updateOwnLeads(lead),
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_TRIAGE_FAILURE: {
      console.error(`Triage lead failed="${action.payload}"`);
      return tassign(state, {
        triageSuccess: false,
      });
    }

    case ActionsAction.ACTION_CONTACT_AND_TRIAGE_SUCCESS: {
      const leadsTriage: LeadListViewModel[] = cloneDeep(state.leadsTriage);
      const lead: LeadListViewModel = LeadUtil.mapLeadViewModelToLeadListViewModel(action.payload);

      return tassign(state, {
        triageSuccess: true,
        leadsDetail: action.payload,
        leadsTriage: LeadUtil.filterOutEqualId(leadsTriage, lead),
        leadsOwn: updateOwnLeads(lead),
        leadsAll: updateAllLeads(lead),
      });
    }

    case ActionsAction.ACTION_CONTACT_AND_TRIAGE_FAILURE: {
      console.error(`Contact and triage lead failed="${action.payload}"`);
      return tassign(state, {
        triageSuccess: false,
      });
    }

    case ActionsAction.ACTION_TRANSFER_BACK_FAILURE: {
      console.error(`Transfer lead failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_CONTACT_FAILURE: {
      console.error(`Move lead to FOLLOW_UP failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_UNREACHED_FAILURE: {
      console.error(`Move lead to UNREACHED failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_CONTACT_AND_TS_WIN_FAILURE: {
      console.error(`Contact and move lead to FOLLOW_UP failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_WIN_FAILURE: {
      console.error(`Move lead to FOLLOW_UP failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_ABORT_FAILURE: {
      console.error(`Abort lead failed="${action.payload}"`);
      return tassign(state, {
        abortRunning: false,
      });
    }

    case ActionsAction.ACTION_ON_HOLD_FAILURE: {
      console.error(`On hold lead failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_NBO_SENT_FAILURE: {
      console.error(`NBO sent failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_BO_SENT_FAILURE: {
      console.error(`BO sent failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_PROJECT_ASSIGN_FAILURE: {
      console.error(`BO sent failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_PROJECT_START_FAILURE: {
      console.error(`BO sent failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_PROJECT_FINISH_FAILURE: {
      console.error(`BO sent failed="${action.payload}"`);
      return state;
    }

    case ActionsAction.ACTION_PROJECT_ARCHIVED_FAILURE: {
      console.error(`BO sent failed="${action.payload}"`);
      return state;
    }

    case LeadAction.LOAD_PROJECT_PROPERTIES: {
      return {
        ...state,
        projectProperties: undefined,
        projectPropertiesLoaded: false,
        projectPropertiesLoading: true,
      };
    }

    case LeadAction.LOAD_PROJECT_PROPERTIES_SUCCESS: {
      return {
        ...state,
        projectProperties: (<LeadAction.LoadProjectPropertiesSuccessAction>action).payload,
        projectPropertiesLoaded: true,
        projectPropertiesLoading: false,
      };
    }
    case LeadAction.LOAD_PROJECT_PROPERTIES_FAILURE: {
      return {
        ...state,
        projectProperties: undefined,
        projectPropertiesLoaded: false,
        projectPropertiesLoading: false,
      };
    }

    case LeadAction.STORE_PROJECT_PROPERTIES_SUCCESS: {
      return {
        ...state,
        projectProperties: (<LeadAction.StoreProjectPropertiesSuccessAction>action).payload,
      };
    }

    // APPOINTMENT
    case LeadAction.ADD_APPOINTMENT: {
      return tassign(state, {
        // leadsDetail: action.payload,
        taskActionRunning: true,
      });
    }

    case LeadAction.ADD_APPOINTMENT_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.ADD_APPOINTMENT_FAILURE: {
      return tassign(state, {
        taskActionRunning: false,
      });
    }

    // TASK
    case LeadAction.ADD_TASK: {
      return tassign(state, {
        // leadsDetail: action.payload,
        taskActionRunning: true,
      });
    }

    case LeadAction.ADD_TASK_SUCCESS: {
      return tassign(state, {
        leadsDetail: action.payload,
        taskActionRunning: false,
      });
    }

    case LeadAction.ADD_TASK_FAILURE: {
      return tassign(state, {
        taskActionRunning: false,
      });
    }

    case LeadAction.LOCK_LEAD_FAILURE: {
      console.error(`lead locked error ${action.payload}`);
      return state;
    }

    case LeadAction.UNLOCK_LEAD_FAILURE: {
      console.error(`lead unlocked error ${action.payload}`);
      return state;
    }

    default: {
      return state;
    }
  }

  function updateOwnLeads(lead: LeadListViewModel): LeadListViewModel[] {
    const ownLeads: LeadListViewModel[] = cloneDeep(state.leadsOwn);
    return ownLeads.map((ownLead: LeadListViewModel) => {
      if (ownLead.Id !== lead.Id) {
        return ownLead;
      }
      return lead;
    });
  }

  function updateAllLeads(lead: LeadListViewModel): LeadListViewModel[] {
    const allLeads: LeadListViewModel[] = cloneDeep(state.leadsAll);
    return allLeads.map((allLead: LeadListViewModel) => {
      if (allLead.Id !== lead.Id) {
        return allLead;
      }
      return lead;
    });
  }

  function updateLead(
    leads: LeadListViewModel[],
    leadToUpdate: LeadListViewModel
  ): LeadListViewModel[] {
    return leads.map((lead: LeadListViewModel) => {
      if (lead.Id !== leadToUpdate.Id) {
        return lead;
      }
      return leadToUpdate;
    });
  }
}

function updateLeadListRatingArr(
  leads: LeadListViewModel[],
  leadId: number,
  rating: number
): boolean {
  if (leads && leads.length > 0) {
    const lead: LeadListViewModel = leads.find((leadVm: LeadListViewModel) => leadVm.Id === leadId);
    return updateLeadRating(lead, rating);
  }
  return false;
}

function updateLeadListTagArr(leads: LeadListViewModel[], leadId: number, tag: string): boolean {
  if (leads && leads.length > 0) {
    const lead: LeadListViewModel = leads.find((leadVm: LeadListViewModel) => leadVm.Id === leadId);
    return updateLeadTag(lead, tag);
  }
  return false;
}

function updateLeadListTracersArr(
  leads: LeadListViewModel[],
  leadId: number,
  tracers: string
): boolean {
  if (leads && leads.length > 0) {
    const lead: LeadListViewModel = leads.find((leadVm: LeadListViewModel) => leadVm.Id === leadId);
    return updateLeadTracers(lead, tracers);
  }
  return false;
}

function updateLeadProviderLeadId(lead: LeadViewModel, providerLeadId: string): boolean {
  if (!isNullOrUndefined(lead)) {
    lead.ProviderLeadId = providerLeadId;
    return true;
  }
  return false;
}

function updateLeadRating(lead: LeadViewModel | LeadListViewModel, rating: number): boolean {
  if (!isNullOrUndefined(lead)) {
    lead.Rating = rating;
    return true;
  }
  return false;
}

function updateLeadComplexityType(lead: LeadViewModel, complexityType: string): boolean {
  if (!isNullOrUndefined(lead)) {
    lead.ComplexityType = complexityType;
    return true;
  }
  return false;
}

function updateLeadTag(lead: LeadListViewModel | LeadViewModel, tag: string): boolean {
  if (!isNullOrUndefined(lead)) {
    lead.Tag = tag;
    return true;
  }
  return false;
}

function updateLeadTracers(lead: LeadListViewModel | LeadViewModel, tracers: string): boolean {
  if (!isNullOrUndefined(lead)) {
    lead.Tracers = tracers;
    return true;
  }
  return false;
}

function updateVariantSalesProbability(
  lead: LeadCompositeViewModel,
  variantId: number,
  salesProbability: number
): boolean {
  let updated: boolean = false;
  if (lead && lead.Variants) {
    const variant: VariantListCompositionViewModel = lead.Variants.find(
      (variantVm: VariantListCompositionViewModel) => variantVm.LeadVariant.Id === variantId
    );
    if (!isNullOrUndefined(variant)) {
      variant.LeadVariant.SalesProbability = salesProbability;
      updated = true;
    }
  }
  return updated;
}

function updateVariant(
  lead: LeadCompositeViewModel,
  variantId: number,
  update: PatchLeadVariantRequest
): boolean {
  let updated: boolean = false;
  if (lead && lead.Variants) {
    const variant: VariantListCompositionViewModel = lead.Variants.find(
      (variantVm: VariantListCompositionViewModel) => variantVm.LeadVariant.Id === variantId
    );
    if (!isNullOrUndefined(variant)) {
      for (const key in update) {
        if (update.hasOwnProperty(key)) {
          variant.LeadVariant[key] = update[key];
        }
      }
      updated = true;
    }
  }
  return updated;
}

export const getOwnMtCrmLeadsState: (state: IState) => MtLeadListViewModel[] = (state: IState) =>
  state.mtCrmLeadsOwn;

export const getOwnMtCrmLoadingState: (state: IState) => boolean = (state: IState) =>
  state.mtCrmLeadsOwnLoading;

export const getSearchMtCrmLeadsState: (state: IState) => MtLeadListViewModel[] = (state: IState) =>
  state.mtCrmLeadsSearch;

export const getSearchMtCrmLoadingState: (state: IState) => boolean = (state: IState) =>
  state.mtCrmLeadsSearchLoading;

export const getNotAssignedLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsNotAssigned;

export const getNotAssignedLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsNotAssignedLoading;

export const getNotAssignedLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsNotAssignedPaging;

export const getProjectsInProgressState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.projectsInProgress;

export const getProjectsInProgressLoadingState: (state: IState) => boolean = (state: IState) =>
  state.projectsInProgressLoading;

export const getProjectsInProgressPagingState: (state: IState) => IPaging = (state: IState) =>
  state.projectsInProgressPaging;

export const getProjectOverviewState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.projectOverview;

export const getProjectOverviewLoadingState: (state: IState) => boolean = (state: IState) =>
  state.projectOverviewLoading;

export const getProjectOverviewPagingState: (state: IState) => IPaging = (state: IState) =>
  state.projectOverviewPaging;

export const getProjectOverviewOwnState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.projectOverviewOwn;

export const getProjectOverviewOwnLoadingState: (state: IState) => boolean = (state: IState) =>
  state.projectOverviewOwnLoading;

export const getProjectOverviewOwnPagingState: (state: IState) => IPaging = (state: IState) =>
  state.projectOverviewOwnPaging;

export const getOwnLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsOwn;

export const getOwnLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsOwnLoading;

export const getNewLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsNewLoading;

export const getOnHoldLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsOnHold;

export const getOnHoldLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsOnHoldPaging;

export const getOnHoldLeadsLoading: (state: IState) => boolean = (state: IState) =>
  state.leadsOnHoldLoading;

export const getWonLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsWonPaging;

export const getWonLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsWon;

export const getWonLeadsLoading: (state: IState) => boolean = (state: IState) =>
  state.leadsWonLoading;

export const getNewLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsNew;

export const getNewLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsNewPaging;

export const getNewLeadsLoading: (state: IState) => boolean = (state: IState) =>
  state.leadsNewLoading;

export const getFollowupLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsFollowup;

export const getFollowupLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsFollowupLoading;

export const getFollowupLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsFollowupPaging;

export const getOrderEntryLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsOrderEntry;

export const getOrderEntryLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsOrderEntryLoading;

export const getOrderEntryLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsOrderEntryPaging;

export const getOnlinepoolLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsOnlinepool;

export const getOnlinepoolLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsOnlinepoolLoading;

export const getOnlinepoolLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsOnlinepoolPaging;

export const getCallpoolLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsCallpool;

export const getCallpoolLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsCallpoolLoading;

export const getCallpoolLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsCallpoolPaging;

export const getDownloadpoolLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsDownloadpool;

export const getDownloadpoolLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsDownloadpoolLoading;

export const getDownloadpoolLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsDownloadpoolPaging;

export const getAllLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsAll;

export const getAllLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsAllPaging;

export const getAllLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsAllLoading;

export const getEnergySolutionState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsEnergySolutions;

export const getEnergySolutionPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsEnergySolutionPaging;

export const getEnergySolutionLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsEnergySolutionLoading;

export const getDetailLeadState: (state: IState) => LeadCompositeViewModel = (state: IState) =>
  state.leadsDetail;

export const getLeadSiblingsState: (state: IState) => LeadSiblingsViewModel = (state: IState) =>
  state.leadSiblings;

export const getDetailLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsDetailLoading;

export const getEmLeadState: (state: IState) => EmobilityLeadViewModel = (state: IState) =>
  state.emLeadData;

export const getEmLoadingState: (state: IState) => boolean = (state: IState) => state.emLeadLoading;

export const getHtLeadState: (state: IState) => HeatingLeadViewModel = (state: IState) =>
  state.htLeadData;

export const getHtLoadingState: (state: IState) => boolean = (state: IState) => state.htLeadLoading;

export const getPvLeadState: (state: IState) => PvLeadViewModel = (state: IState) =>
  state.pvLeadData;

export const getPvLoadingState: (state: IState) => boolean = (state: IState) => state.pvLeadLoading;

export const getOwnTaskListState: (state: IState) => TaskListViewModel[] = (state: IState) =>
  state.ownTaskList;

export const getOwnTaskListLoadingState: (state: IState) => boolean = (state: IState) =>
  state.ownTaskListLoading;

export const getDelegatedTaskListState: (state: IState) => TaskListViewModel[] = (state: IState) =>
  state.delegatedTaskList;

export const getDelegatedTaskListLoadingState: (state: IState) => boolean = (state: IState) =>
  state.delegatedTaskListLoading;

export const getTriageLeadsState: (state: IState) => LeadListViewModel[] = (state: IState) =>
  state.leadsTriage;

export const getTriageLeadsPagingState: (state: IState) => IPaging = (state: IState) =>
  state.leadsTriagePaging;

export const getTriageLoadingState: (state: IState) => boolean = (state: IState) =>
  state.leadsTriageLoading;

export const getTransferRunningState: (state: IState) => boolean = (state: IState) =>
  state.transferRunning;

export const getTaskActionState: (state: IState) => boolean = (state: IState) =>
  state.taskActionRunning;

export const getVariantActionState: (state: IState) => boolean = (state: IState) =>
  state.variantActionRunning;

export const getAbortRunningState: (state: IState) => boolean = (state: IState) =>
  state.abortRunning;

export const getTriageSuccessState: (state: IState) => boolean = (state: IState) =>
  state.triageSuccess;

export const getTransferMultipleRunningState: (state: IState) => boolean = (state: IState) =>
  state.transferMultipleRunning;

export const getTransferMultipleSuccessInfoState: (state: IState) => IMultipleSuccessInfo = (
  state: IState
) => state.transferMultipleSuccessInfo;

export const getAbortMultipleRunningState: (state: IState) => boolean = (state: IState) =>
  state.abortMultipleRunning;

export const getAbortMultipleSuccessInfoState: (state: IState) => IMultipleSuccessInfo = (
  state: IState
) => state.abortMultipleSuccessInfo;

export const getReleaseMultipleRunningState: (state: IState) => boolean = (state: IState) =>
  state.releaseMultipleRunning;

export const getReleaseMultipleSuccessInfoState: (state: IState) => IMultipleSuccessInfo = (
  state: IState
) => state.releaseMultipleSuccessInfo;

export const getHoldMultipleRunningState: (state: IState) => boolean = (state: IState) =>
  state.holdMultipleRunning;

export const getHoldMultipleSuccessInfoState: (state: IState) => IMultipleSuccessInfo = (
  state: IState
) => state.holdMultipleSuccessInfo;

export const getProjectProperties: (state: IState) => IProjectDescriptionViewModel = (
  state: IState
) => state.projectProperties;

export const getSearchedVariants: (state: IState) => VariantListViewModel[] = (state: IState) =>
  state.searchedVariants;

export const getNextLeadList: (state: IState) => number[] = (state: IState) => state.nextLeads;

export const getLeadSynchronizeLoading: (state: IState) => boolean = (state: IState) =>
  state.LeadSynchronizeLoading;

export const getLeadSynchronizeFailed: (state: IState) => boolean = (state: IState) =>
  state.LeadSynchronizeFailed;

export const getLeadSynchronizeSuccess: (state: IState) => boolean = (state: IState) =>
  state.LeadSynchronizeSuccess;
