import { ActionReducer, ActionReducerMap, createSelector, MemoizedSelector } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import * as GlobalReducer from './global/global.reducer';
import * as LeadReducer from './lead/lead.reducer';
import * as LeadNotesReducer from './lead-notes/lead-notes.reducer';
import { IMultipleSuccessInfo } from './lead/lead.reducer';
import * as ViewSettingReducer from './view-setting/view-setting.reducer';
import * as AuthReducer from './auth/auth.reducer';
import * as TaskReducer from './task/task.reducer';
import * as LeadDocumentReducer from './lead-document/lead-document.reducer';
import * as LeadProjectReducer from './lead-project/lead-project.reducer';
import * as LeadContactReducer from './lead-contact/lead-contact.reducer';
import * as VariantReducer from './variant/variant.reducer';
import * as HeatingReducer from './heating/heating.reducer';
import * as EMobilityReducer from './electro-mobility/electro-mobility.reducer';
import * as PhotovoltaikReducer from './photovoltaik/photovoltaik.reducer';
import * as UserReducer from './user/user.reducer';
import * as ProjectReportingReducer from './Project-reporting/Project-reporting.reducer';
import { LeadOverviewVsModel } from '../models/view-setting/lead-overview-vs.model';
import { LeadOverviewOwnVsModel } from '../models/view-setting/lead-overview-own-vs.model';
import * as AuthAction from './auth/auth.action';
import { SelectedMandantsVsModel } from '../models/view-setting/selected-mandants-vs.model';
import { IPaging } from './lead/paging.interface';
import {
  ArticleForestViewModel,
  FileLinkViewModel,
  LeadDocumentListViewModel,
  LeadSiblingsViewModel,
  MtLeadListViewModel,
  NoteViewModel,
  PictureListViewModel,
  TaskViewModel,
  ProjectChecklistViewModel,
  ProjectChecklistTemplateViewModel,
  ProjectReportingSnapshotViewModel,
  ProjectReportingViewModel,
  CurrentUserViewModel,
  EmobilityLeadViewModel,
  EmobilityMasterDataViewModel,
  HeatingLeadViewModel,
  HeatingMasterDataViewModel,
  LeadCompositeViewModel,
  LeadListViewModel,
  MasterDataMandatorViewModel,
  MasterDataUserViewModel,
  MasterDataViewModel,
  PvLeadViewModel,
  PvMasterDataViewModel,
  TaskListViewModel,
  UserInfo,
  ValidationErrorViewModel,
  VariantCompositeViewModel,
  VariantListViewModel,
  VariantPrecalculationViewModel,
  MasterDataTradeTypeViewModel,
  DocumentTemplatesViewModel,
  NoteTemplateViewModel,
} from '../apis/advis';
import { IPrincipal } from '../interfaces/principal.interface';
import { IProjectDescriptionViewModel } from '../models/project-description-view-model';
import { VariantVsModel } from '../models/view-setting/variant-vs.model';
import { LeadOverviewTasksVsModel } from '../models/view-setting/lead-overview-tasks-vs.model';
import { LeadOverviewSettingsModel } from '../models/view-setting/lead-overview-settings.model';
import { AddressbookLeadOverviewSettingsModel } from '../models/view-setting/address-book-overview-settings.model';
import { IAccountAssignmentData } from '../../common-components/account-assignment-list/account-assignment-list.component';
import * as MtCrmReducer from '../states/mt-crm/mt-crm.reducer';
import { getLeadData, getVariantData } from './mt-crm/mt-crm.selectors';
import { IExternManagedAddressTypeConfig } from '../utils/exter-managed-address-type.util';
import { ISopChecklistViewModel } from './lead-project/lead-project.effects';

export interface IState {
  global: GlobalReducer.IState;
  lead: LeadReducer.IState;
  leadNotes: LeadNotesReducer.IState;
  viewSetting: ViewSettingReducer.IState;
  auth: AuthReducer.IState;
  task: TaskReducer.IState;
  leadDocument: LeadDocumentReducer.IState;
  leadProject: LeadProjectReducer.IState;
  leadContact: LeadContactReducer.IState;
  variant: VariantReducer.IState;
  heating: HeatingReducer.IState;
  eMobility: EMobilityReducer.IState;
  photovoltaik: PhotovoltaikReducer.IState;
  user: UserReducer.IState;
  projectReporting: ProjectReportingReducer.IState;
  mtCrm: MtCrmReducer.IState;
}

export const reducers: ActionReducerMap<IState> = {
  global: GlobalReducer.reducer,
  lead: LeadReducer.reducer,
  leadNotes: LeadNotesReducer.reducer,
  viewSetting: ViewSettingReducer.reducer,
  auth: AuthReducer.reducer,
  task: TaskReducer.reducer,
  leadDocument: LeadDocumentReducer.reducer,
  leadProject: LeadProjectReducer.reducer,
  leadContact: LeadContactReducer.reducer,
  variant: VariantReducer.reducer,
  heating: HeatingReducer.reducer,
  eMobility: EMobilityReducer.reducer,
  photovoltaik: PhotovoltaikReducer.reducer,
  user: UserReducer.reducer,
  projectReporting: ProjectReportingReducer.reducer,
  mtCrm: MtCrmReducer.reducer,
};

export const getMtCrmState: (state: IState) => MtCrmReducer.IState = (state: IState) => state.mtCrm;

export const getMtCrmVariantData: MemoizedSelector<IState, VariantCompositeViewModel> =
  createSelector(getMtCrmState, getVariantData);

export const getMtCrmLeadData: MemoizedSelector<IState, HeatingLeadViewModel> = createSelector(
  getMtCrmState,
  getLeadData
);

///////////
// Lead //
///////////
export const getLeadState: (state: IState) => LeadReducer.IState = (state: IState) => state.lead;

export const getLeadSynchronizeLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getLeadSynchronizeLoading
);

export const getLeadSynchronizeSuccess: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getLeadSynchronizeSuccess
);

export const getLeadSynchronizeFailed: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getLeadSynchronizeFailed
);

export const getOwnMtCrmLeadEntities: MemoizedSelector<IState, MtLeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getOwnMtCrmLeadsState);

export const getOwnMtCrmLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOwnMtCrmLoadingState
);

export const getSearchMtCrmLeadEntities: MemoizedSelector<IState, MtLeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getSearchMtCrmLeadsState);

export const getSearchMtCrmLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getSearchMtCrmLoadingState
);

export const getNotAssignedLeadEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getNotAssignedLeadsState);

export const getNotAssignedLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getNotAssignedLoadingState
);

export const getLeadNotAssignedPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getNotAssignedLeadsPagingState
);

export const getProjectsInProgressEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getProjectsInProgressState);

export const getProjectsInProgressLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getProjectsInProgressLoadingState
);

export const getProjectsInProgressPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getProjectsInProgressPagingState
);

export const getProjectOverviewEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getProjectOverviewState);

export const getProjectOverviewLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getProjectOverviewLoadingState
);

export const getProjectOverviewPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getProjectOverviewPagingState
);

export const getProjectOverviewOwnEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getProjectOverviewOwnState);

export const getProjectOverviewOwnLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getProjectOverviewOwnLoadingState
);

export const getProjectOverviewOwnPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getProjectOverviewOwnPagingState
);

export const getOwnLeadEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getOwnLeadsState
);

export const getOwnLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOwnLoadingState
);

// WON
export const getWonLeadsPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getWonLeadsPagingState
);

export const getWonLeadsEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getWonLeadsState
);

export const getWonLeadsLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getWonLeadsLoading
);

// On Hold
export const getOnHoldLeadsPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getOnHoldLeadsPagingState
);

export const getOnHoldLeadsEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getOnHoldLeadsState
);

export const getOnHoldLeadsLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOnHoldLeadsLoading
);

// New
export const getNewLeadsLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getNewLoadingState
);

export const getNewLeadsPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getNewLeadsPagingState
);

export const getNewLeadsEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getNewLeadsState
);

// Fup
export const getFollowupLeadEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getFollowupLeadsState);

export const getFollowupLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getFollowupLoadingState
);

export const getFollowupLeadsPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getFollowupLeadsPagingState
);

// Order entry
export const getOrderEntryLeadEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getOrderEntryLeadsState);

export const getOrderEntryLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOrderEntryLoadingState
);

export const getOrderEntryLeadsPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getOrderEntryLeadsPagingState
);

// Onlinepool
export const getLeadOnlinepoolEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getOnlinepoolLeadsState);

export const getLeadOnlinepoolLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOnlinepoolLoadingState
);

export const getLeadOnlinepoolPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getOnlinepoolLeadsPagingState
);

// Callpool
export const getLeadCallpoolEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getCallpoolLeadsState);

export const getLeadCallpoolLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getCallpoolLoadingState
);

export const getLeadCallpoolPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getCallpoolLeadsPagingState
);

// Downloadpool
export const getLeadDownloadpoolEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getDownloadpoolLeadsState);

export const getLeadDownloadpoolLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getDownloadpoolLoadingState
);

export const getLeadDownloadpoolPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getDownloadpoolLeadsPagingState
);

// All
export const getLeadAllEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getAllLeadsState
);

export const getLeadAllPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getAllLeadsPagingState
);

export const getLeadAllLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getAllLoadingState
);

// Triage
export const getLeadTriageEntities: MemoizedSelector<IState, LeadListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getTriageLeadsState
);

export const getLeadTriagePaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getTriageLeadsPagingState
);

export const getLeadTriageLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getTriageLoadingState
);

export const getTriageSuccess: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getTriageSuccessState
);

// Detail
export const getLeadDetailEntity: MemoizedSelector<IState, LeadCompositeViewModel> = createSelector(
  getLeadState,
  LeadReducer.getDetailLeadState
);

export const getLeadDetailLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getDetailLoadingState
);

export const getLeadSiblingsEntity: MemoizedSelector<IState, LeadSiblingsViewModel> =
  createSelector(getLeadState, LeadReducer.getLeadSiblingsState);

export const getEmLeadEntity: MemoizedSelector<IState, EmobilityLeadViewModel> = createSelector(
  getLeadState,
  LeadReducer.getEmLeadState
);

export const getEmLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getEmLoadingState
);

export const getHtLeadEntity: MemoizedSelector<IState, HeatingLeadViewModel> = createSelector(
  getLeadState,
  LeadReducer.getHtLeadState
);

export const getHtLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getHtLoadingState
);

export const getPvLeadEntity: MemoizedSelector<IState, PvLeadViewModel> = createSelector(
  getLeadState,
  LeadReducer.getPvLeadState
);

export const getPvLeadLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getPvLoadingState
);

// TaskList
export const getOwnTaskListEntity: MemoizedSelector<IState, TaskListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getOwnTaskListState
);

export const getOwnTaskListLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getOwnTaskListLoadingState
);

export const getDelegatedTaskListEntity: MemoizedSelector<IState, TaskListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getDelegatedTaskListState);

export const getDelegatedTaskListLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getDelegatedTaskListLoadingState
);

export const getTransferRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getTransferRunningState
);

export const getTaskActionRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getTaskActionState
);

export const getVariantActionRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getVariantActionState
);

export const getAbortRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getAbortRunningState
);

export const getTransferMultipleRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getTransferMultipleRunningState
);

export const getTransferMultipleSuccessInfo: MemoizedSelector<IState, IMultipleSuccessInfo> =
  createSelector(getLeadState, LeadReducer.getTransferMultipleSuccessInfoState);

export const getAbortMultipleRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getAbortMultipleRunningState
);

export const getAbortMultipleSuccessInfo: MemoizedSelector<IState, IMultipleSuccessInfo> =
  createSelector(getLeadState, LeadReducer.getAbortMultipleSuccessInfoState);

export const getHoldtMultipleRunning: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getHoldtMultipleRunningState
);

export const getHoldMultipleSuccessInfo: MemoizedSelector<IState, IMultipleSuccessInfo> =
  createSelector(getLeadState, LeadReducer.getHoldMultipleSuccessInfoState);

export const getProjectProperties: MemoizedSelector<IState, IProjectDescriptionViewModel> =
  createSelector(getLeadState, LeadReducer.getProjectProperties);

export const getSearchedVariants: MemoizedSelector<IState, VariantListViewModel[]> = createSelector(
  getLeadState,
  LeadReducer.getSearchedVariants
);

export const getNextLeadList: MemoizedSelector<IState, number[]> = createSelector(
  getLeadState,
  LeadReducer.getNextLeadList
);

export const getLeadEnergySolutionEntities: MemoizedSelector<IState, LeadListViewModel[]> =
  createSelector(getLeadState, LeadReducer.getEnergySolutionState);

export const getEnergySolutionPaging: MemoizedSelector<IState, IPaging> = createSelector(
  getLeadState,
  LeadReducer.getEnergySolutionPagingState
);

export const getEnergySolutionLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadState,
  LeadReducer.getEnergySolutionLoadingState
);

///////////
// Notes //
///////////
export const getLeadNotesState: (state: IState) => LeadNotesReducer.IState = (state: IState) =>
  state.leadNotes;

// All Notes
export const getAllNotesEntity: MemoizedSelector<IState, NoteViewModel[]> = createSelector(
  getLeadNotesState,
  LeadNotesReducer.getAllNotesState
);

export const getPmInfoNoteEntity: MemoizedSelector<IState, NoteViewModel> = createSelector(
  getLeadNotesState,
  LeadNotesReducer.getPmNoteState
);

export const getNoteTemplates: MemoizedSelector<IState, NoteTemplateViewModel[]> = createSelector(
  getLeadNotesState,
  LeadNotesReducer.getNoteTemplatesState
);

export const getNoteTemplatesLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadNotesState,
  LeadNotesReducer.getNoteTemplatesLoadingState
);

//////////////////
// ViewSettings //
//////////////////
export const getViewSettingState: (state: IState) => ViewSettingReducer.IState = (state: IState) =>
  state.viewSetting;

// View Settings Global
export const getViewSettingLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLoadedState
);

export const getViewSettingLoading: MemoizedSelector<IState, boolean> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLoadingState
);

export const getViewSettingSelectedMandants: MemoizedSelector<IState, SelectedMandantsVsModel> =
  createSelector(getViewSettingState, ViewSettingReducer.getSelectedMandantsState);

// View Settings Lead Overview
export const getViewSettingLeadOverview: MemoizedSelector<IState, LeadOverviewVsModel> =
  createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewState);

// View Settings Lead Overview Own Filter
export const getViewSettingLeadOverviewOwnFilter: MemoizedSelector<IState, LeadOverviewOwnVsModel> =
  createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewOwnFilterState);

// View Settings Lead Overview New Filter
export const getViewSettingLeadOverviewNewFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewNewFilterState);

// View Settings Lead Overview Followup Filter
export const getViewSettingsMtCrmLeadOverviewOwnFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getMtCrmLeadOverviewOwnFilterState);

export const getViewSettingLeadOverviewFollowupFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewFollowupFilterState);

export const getViewSettingLeadOverviewOrderEntryFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewOrderEntryFilterState);

export const getViewSettingAddressbookOverviewFilter: MemoizedSelector<
  IState,
  AddressbookLeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getAddressbookOverviewFilterState);

export const getViewSettingsLeadOverviewNewFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewNewFilterState);

export const getViewSettingsLeadOverviewOnHoldFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewOnHoldFilterState);

export const getViewSettingsLeadOverviewNotAssignedFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewNotAssignedFilterState);

export const getViewSettingsLeadOverviewProjectsInProgressFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLeadOverviewProjectsInProgressFilterState
);

export const getViewSettingsLeadOverviewProjectsOverviewFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLeadOverviewProjectOverviewFilterState
);

export const getViewSettingsLeadOverviewProjectsOverviewOwnFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLeadOverviewProjectOverviewOwnFilterState
);

export const getViewSettingsLeadOverviewWonFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewWonFilterState);

// View Settings Lead Overview Onlinepool Filter
export const getViewSettingLeadOverviewOnlinepoolFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewOnlinepoolFilterState);

export const getViewSettingLeadOverviewCallpoolFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewCallpoolFilterState);

export const getViewSettingLeadOverviewDownloadpoolFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewDownloadpoolFilterState);

export const getViewSettingLeadOverviewOwnTasksFilter: MemoizedSelector<
  IState,
  LeadOverviewTasksVsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewOwnTasksFilterState);

export const getViewSettingLeadOverviewDelegatedTasksFilter: MemoizedSelector<
  IState,
  LeadOverviewTasksVsModel
> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getLeadOverviewDelegatedTasksFilterState
);

// View Settings Lead Overview All Filter
export const getViewSettingLeadOverviewAllFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewAllFilterState);

export const getViewSettingLeadOverviewEnergySolutionsApprovedFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewEsApprovedFilterState);

export const getViewSettingLeadOverviewEnergySolutionsDisapprovedFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewEsDisapprovedFilterState);

export const getViewSettingLeadOverviewEnergySolutionsNotSetFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewEsNotSetFilterState);

// View Settings Lead Overview Triage Filter
export const getViewSettingLeadOverviewTriageFilter: MemoizedSelector<
  IState,
  LeadOverviewSettingsModel
> = createSelector(getViewSettingState, ViewSettingReducer.getLeadOverviewTriageFilterState);

// View Settings Variant
export const getViewSettingVariant: MemoizedSelector<IState, VariantVsModel> = createSelector(
  getViewSettingState,
  ViewSettingReducer.getVariantState
);

//////////
// Auth //
//////////
export const getAuthState: (state: IState) => AuthReducer.IState = (state: IState) => state.auth;

// Auth Logged In
export const getAuthLoggedIn: MemoizedSelector<IState, boolean> = createSelector(
  getAuthState,
  AuthReducer.getLoggedIn
);

// Auth Principal
export const getAuthPrincipal: MemoizedSelector<IState, IPrincipal | undefined> = createSelector(
  getAuthState,
  AuthReducer.getPrincipal
);

// Auth Error
export const getAuthError: MemoizedSelector<IState, Object | undefined> = createSelector(
  getAuthState,
  AuthReducer.getError
);

// Auth Pending
export const getAuthPending: MemoizedSelector<IState, boolean> = createSelector(
  getAuthState,
  AuthReducer.getPending
);

/////////////
// Global  //
/////////////
export const getGlobalState: (state: IState) => GlobalReducer.IState = (state: IState) =>
  state.global;

// Master Data
export const getGlobalMasterData: MemoizedSelector<IState, MasterDataViewModel> = createSelector(
  getGlobalState,
  GlobalReducer.getMasterDataState
);

export const getAllExternalManagedAddressConfigState: MemoizedSelector<
  IState,
  IExternManagedAddressTypeConfig[]
> = createSelector(getGlobalState, GlobalReducer.getAllExternalManagedAddressConfigState);

export const getAllowedCanSeeExternalManagedAddressConfigState: MemoizedSelector<
  IState,
  IExternManagedAddressTypeConfig[]
> = createSelector(getGlobalState, GlobalReducer.getAllowedCanSeeExternalManagedAddressConfigState);

export const getAllowedCanManagedExternalManagedAddressConfigState: MemoizedSelector<
  IState,
  IExternManagedAddressTypeConfig[]
> = createSelector(
  getGlobalState,
  GlobalReducer.getAllowedCanManagedExternalManagedAddressConfigState
);

export const getArticlesForests: MemoizedSelector<IState, ArticleForestViewModel[]> =
  createSelector(getGlobalState, GlobalReducer.getArticlesForestsState);

export const getGlobalMasterDataLoading: MemoizedSelector<IState, boolean> = createSelector(
  getGlobalState,
  GlobalReducer.getMasterDataLoadingState
);

export const getGlobalMasterDataLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getGlobalState,
  GlobalReducer.getMasterDataLoadedState
);

export const getGlobalMasterDataProductLineLoading: MemoizedSelector<IState, boolean> =
  createSelector(getGlobalState, GlobalReducer.getMasterDataLoadingState);

export const getGlobalMasterDataProductLineLoaded: MemoizedSelector<IState, boolean> =
  createSelector(getGlobalState, GlobalReducer.getMasterDataLoadedState);

// Errors
export const getGlobalErrors: MemoizedSelector<IState, any[]> = createSelector(
  getGlobalState,
  GlobalReducer.getErrorsState
);

export const getGlobalSelectedMandants: MemoizedSelector<IState, Set<number>> = createSelector(
  getGlobalState,
  GlobalReducer.getSelectedMandantsState
);

export const getGlobalMandantsMap: MemoizedSelector<
  IState,
  Map<number, MasterDataMandatorViewModel>
> = createSelector(getGlobalState, GlobalReducer.getMandantsMapState);

// Users
export const getGlobalUserMap: MemoizedSelector<
  IState,
  Map<string, MasterDataUserViewModel>
> = createSelector(getGlobalState, GlobalReducer.getUserMapState);

export const getGlobalUsers: MemoizedSelector<IState, MasterDataUserViewModel[]> = createSelector(
  getGlobalState,
  GlobalReducer.getUsersState
);

export const getGlobalUsersForSelectedMandants: MemoizedSelector<
  IState,
  MasterDataUserViewModel[]
> = createSelector(getGlobalState, GlobalReducer.getUsersForSelectedMandantsState);

export const getGlobalTradeTypesForSelectedMandants: MemoizedSelector<
  IState,
  MasterDataTradeTypeViewModel[]
> = createSelector(getGlobalState, GlobalReducer.getTradeTypesForSelectedMandantsState);

/////////////
// Tasks  //
/////////////
export const getTaskState: (state: IState) => TaskReducer.IState = (state: IState) => state.task;

// Master Data
export const getTasks: MemoizedSelector<IState, TaskViewModel[]> = createSelector(
  getTaskState,
  TaskReducer.getTasksState
);

export const getTasksLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getTaskState,
  TaskReducer.getTasksLoadedState
);

export const getTasksLoading: MemoizedSelector<IState, boolean> = createSelector(
  getTaskState,
  TaskReducer.getTasksLoadingState
);

///////////////////
// LeadDocument  //
///////////////////
export const getLeadDocumentState: (state: IState) => LeadDocumentReducer.IState = (
  state: IState
) => state.leadDocument;
export const getLeadDocumentPicturesLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadDocumentState,
  LeadDocumentReducer.getPicturesLoading
);

export const getLeadDocumentPicturesSync: MemoizedSelector<IState, boolean> = createSelector(
  getLeadDocumentState,
  LeadDocumentReducer.getDocumentAndPictureSync
);

export const getLeadDocumentPictures: MemoizedSelector<IState, PictureListViewModel> =
  createSelector(getLeadDocumentState, LeadDocumentReducer.getPictures);

export const getLeadDocumentDocumentsLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadDocumentState,
  LeadDocumentReducer.getDocumentsLoading
);

export const getLeadFileFilesLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadDocumentState,
  LeadDocumentReducer.getFilesLoading
);

export const getLeadDocumentDocuments: MemoizedSelector<IState, LeadDocumentListViewModel> =
  createSelector(getLeadDocumentState, LeadDocumentReducer.getDocuments);

///////////////////
// LeadProject  //
//////////////////
const getLeadProjectState: (state: IState) => LeadProjectReducer.IState = (state: IState) =>
  state.leadProject;
export const getProjectLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getLoading
);

export const getProjectChecklist: MemoizedSelector<IState, ProjectChecklistViewModel> =
  createSelector(getLeadProjectState, LeadProjectReducer.getProjectChecklist);

export const getProjectSopChecklist: MemoizedSelector<IState, ISopChecklistViewModel> =
  createSelector(getLeadProjectState, LeadProjectReducer.getSopProjectChecklist);

export const getProjectChecklistLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getProjectChecklistLoading
);

export const getProjectSopChecklistLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getProjectSopChecklistLoading
);

export const getProjectChecklistLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getProjectChecklistLoaded
);

export const getProjectSopChecklistLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getProjectSopChecklistLoaded
);

export const getProjectChecklistTemplates: MemoizedSelector<
  IState,
  ProjectChecklistTemplateViewModel[]
> = createSelector(getLeadProjectState, LeadProjectReducer.getProjectChecklistTemplates);

export const getProjectChecklistTemplatesLoading: MemoizedSelector<IState, boolean> =
  createSelector(getLeadProjectState, LeadProjectReducer.getProjectChecklistTemplatesLoading);

export const getProjectChecklistTemplatesLoaded: MemoizedSelector<IState, boolean> = createSelector(
  getLeadProjectState,
  LeadProjectReducer.getProjectChecklistTemplatesLoaded
);

///////////////////
// LeadContact  //
//////////////////
const getLeadContactState: (state: IState) => LeadContactReducer.IState = (state: IState) =>
  state.leadContact;
export const getLeadContactLoading: MemoizedSelector<IState, boolean> = createSelector(
  getLeadContactState,
  LeadContactReducer.getLoading
);

export const getLeadContactAddedToLeadFailed: MemoizedSelector<IState, boolean> = createSelector(
  getLeadContactState,
  LeadContactReducer.getIsAddedToLeadFailed
);

export const getLeadContactAddedToLeadSuccess: MemoizedSelector<IState, boolean> = createSelector(
  getLeadContactState,
  LeadContactReducer.getIsAddedToLeadSuccess
);

export const getLeadContactLoadingState: MemoizedSelector<IState, LeadContactReducer.IState> =
  createSelector(getLeadContactState, LeadContactReducer.getState);

///////////////////
// Variant  //
///////////////////
export const getVariantState: (state: IState) => VariantReducer.IState = (state: IState) =>
  state.variant;
export const getVariant: (state: IState) => VariantCompositeViewModel = (state: IState) =>
  state.variant.variant;

export const getVariantSel: MemoizedSelector<IState, VariantCompositeViewModel> = createSelector(
  getVariantState,
  VariantReducer.getVariantState
);

export const getVariantValidationResult: MemoizedSelector<IState, ValidationErrorViewModel[]> =
  createSelector(getVariantState, VariantReducer.getValidationResultState);

export const getVariantCreateVariantRunning: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getCreateVariantRunningState
);

export const getVariantCreateVariantSuccess: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getCreateVariantSuccessState
);

export const getVariantSaveVariantRunning: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getSaveVariantRunningState
);

export const getVariantSaveVariantSuccess: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getSaveVariantSuccessState
);

export const getVariantRunning: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getVariantRunningState
);

export const userActionRunning: MemoizedSelector<IState, boolean> = createSelector(
  getVariantState,
  VariantReducer.getUserActionRunningState
);

export const getVariantPrecalculation: MemoizedSelector<IState, VariantPrecalculationViewModel> =
  createSelector(getVariantState, VariantReducer.getVariantPrecalculationState);

export const getAccountAssignment: MemoizedSelector<IState, IAccountAssignmentData[]> =
  createSelector(getVariantState, VariantReducer.getAccountAssignmentListState);

//////////////
// Heating //
//////////////
export const getHeatingState: (state: IState) => HeatingReducer.IState = (state: IState) =>
  state.heating;

export const getHeatingMasterDataRunning: MemoizedSelector<IState, boolean> = createSelector(
  getHeatingState,
  HeatingReducer.getMasterDataRunningState
);

export const getHeatingMasterDataState: MemoizedSelector<IState, HeatingMasterDataViewModel> =
  createSelector(getHeatingState, HeatingReducer.getHeatingMasterDataState);

//////////////
// E mobility //
//////////////
export const getEMobilityState: (state: IState) => EMobilityReducer.IState = (state: IState) =>
  state.eMobility;

export const getEMobilityMasterDataRunning: MemoizedSelector<IState, boolean> = createSelector(
  getEMobilityState,
  EMobilityReducer.getMasterDataRunningState
);

export const getEMobilityMasterDataState: MemoizedSelector<IState, EmobilityMasterDataViewModel> =
  createSelector(getEMobilityState, EMobilityReducer.getEMobilityMasterDataState);

//////////////
// Photovoltaik //
//////////////
export const getPhotovoltaikState: (state: IState) => PhotovoltaikReducer.IState = (
  state: IState
) => state.photovoltaik;

export const getPhotovoltaikMasterDataRunning: MemoizedSelector<IState, boolean> = createSelector(
  getPhotovoltaikState,
  PhotovoltaikReducer.getPvMasterDataRunningState
);

export const getPhotovoltaikMasterDataState: MemoizedSelector<IState, PvMasterDataViewModel> =
  createSelector(getPhotovoltaikState, PhotovoltaikReducer.getPvMasterDataState);

//////////////
// Project-reporting //
//////////////
export const getProjectReportingState: (state: IState) => ProjectReportingReducer.IState = (
  state: IState
) => state.projectReporting;

export const getProjectReporting: MemoizedSelector<IState, ProjectReportingViewModel> =
  createSelector(getProjectReportingState, ProjectReportingReducer.getProjectReporting);

export const getProjectReportingSnapshots: MemoizedSelector<
  IState,
  ProjectReportingSnapshotViewModel[]
> = createSelector(getProjectReportingState, ProjectReportingReducer.getProjectReportingSnapshots);

export const getProjectReportingLoading: MemoizedSelector<IState, boolean> = createSelector(
  getProjectReportingState,
  ProjectReportingReducer.getProjectReportingLoading
);

export const getProjectReportingSnapshotsLoading: MemoizedSelector<IState, boolean> =
  createSelector(
    getProjectReportingState,
    ProjectReportingReducer.getProjectReportingSnapshotsLoading
  );

//////////////
// User //
//////////////
export const getUserState: (state: IState) => UserReducer.IState = (state: IState) => state.user;

export const getUserInfo: MemoizedSelector<IState, UserInfo> = createSelector(
  getUserState,
  UserReducer.getUserInfo
);

export const getCurrentUser: MemoizedSelector<IState, CurrentUserViewModel> = createSelector(
  getUserState,
  UserReducer.getCurrentUser
);

export const getIndividualNoteTemplates: MemoizedSelector<IState, NoteTemplateViewModel[]> =
  createSelector(getUserState, UserReducer.getNoteTemplates);

export const getIndividualNoteTemplatesLoading: MemoizedSelector<IState, boolean> = createSelector(
  getUserState,
  UserReducer.getNoteTemplatesLoading
);

export const getCurrentUserFiles: MemoizedSelector<IState, FileLinkViewModel[]> = createSelector(
  getUserState,
  UserReducer.getCurrentUserFiles
);

export const getUserLoading: MemoizedSelector<IState, boolean> = createSelector(
  getUserState,
  UserReducer.getUserLoading
);

// Document
export const getDocumentsState: (state: IState) => LeadDocumentReducer.IState = (state: IState) =>
  state.leadDocument;

export const getDocumentTemplates: MemoizedSelector<IState, DocumentTemplatesViewModel> =
  createSelector(getDocumentsState, LeadDocumentReducer.getDocumentTemplates);

//////////////////////////////////////////////////////////////////////////////////////////////
//                                         META REDUCERS                                    //
//////////////////////////////////////////////////////////////////////////////////////////////

// storeFreeze cannot be use yet together with StoreRouterConnectingModule, see: https://github.com/ngrx/platform/pull/188

// FIXME No idea why this is not working anymore - fix
// export const metaReducers: ActionReducer<any, any>[] = !environment.production ? [logger, storeFreeze] : [];
export const metaReducers: any = !environment.production
  ? [logger, initMetaReducer]
  : [initMetaReducer];

// Log actions to console
export function logger(reducer: ActionReducer<IState>): ActionReducer<any, any> {
  return function (state: IState, action: any): IState {
    return reducer(state, action);
  };
}

export function initMetaReducer(reducer: ActionReducer<IState>): ActionReducer<any, any> {
  return function (state: IState, action: any): IState {
    if (action.type === AuthAction.INIT) {
      console.info(`Init action="${action.type}", clearing ngrx store state`);
      state = undefined;
    }

    return reducer(state, action);
  };
}
