import { ExternalManagedAddressTypeViewModel, LeadContactViewModel } from '../apis/advis';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;

export interface IExternManagedAddressTypeConfig extends IInternalExternManagedAddressTypeConfig {
  key: string;
  canSeePermission: string;
  canManagePermission: string;
  translation?: string;
  receiverRequestEmail: string;
}

interface IInternalExternManagedAddressTypeConfig {
  addressType: AddressTypeEnum;
  Icon: string;
}

export class ExternManagedAddressTypeUtils {
  private static readonly sapDebtor: IInternalExternManagedAddressTypeConfig = {
    addressType: 'SapDebtor',
    Icon: 'dollar-sign',
  };
  private static readonly siteAddress: IInternalExternManagedAddressTypeConfig = {
    addressType: 'SiteAddress',
    Icon: 'home',
  };

  public static getConfigForAddressType(
    addressType: AddressTypeEnum | string,
    externalManagedAddressTypeViewModel: ExternalManagedAddressTypeViewModel
  ): IExternManagedAddressTypeConfig {
    addressType = addressType.toString().toLowerCase();
    if (addressType && addressType.length > 0) {
      switch (addressType) {
        case AddressTypeEnum.SapDebtor.toLowerCase():
          return ExternManagedAddressTypeUtils.mapToExternManagedAddressTypeConfig(
            this.sapDebtor,
            externalManagedAddressTypeViewModel
          );
        case AddressTypeEnum.SiteAddress.toLowerCase():
          return ExternManagedAddressTypeUtils.mapToExternManagedAddressTypeConfig(
            this.siteAddress,
            externalManagedAddressTypeViewModel
          );
        default:
          return undefined;
      }
    }

    return undefined;
  }

  public static isAddressTypeExternalManaged(
    addressType: LeadContactViewModel.AddressTypeEnum,
    allExternalManagedAddressConfig: IExternManagedAddressTypeConfig[]
  ): boolean {
    const findIndex = allExternalManagedAddressConfig.findIndex(x => x.addressType === addressType);
    return findIndex >= 0 ? true : false;
  }

  private static mapToExternManagedAddressTypeConfig(
    internal: IInternalExternManagedAddressTypeConfig,
    externalManagedAddressTypeViewModel: ExternalManagedAddressTypeViewModel
  ): IExternManagedAddressTypeConfig {
    return {
      Icon: internal.Icon,
      addressType: internal.addressType,
      key: internal.addressType.toString(),
      translation: externalManagedAddressTypeViewModel.Translation,
      canManagePermission: externalManagedAddressTypeViewModel.CanManagePermission,
      canSeePermission: externalManagedAddressTypeViewModel.CanSeePermission,
      receiverRequestEmail: externalManagedAddressTypeViewModel.ReceiverRequestEmail,
    };
  }
}
