/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseContactDirective } from '../../../lead/common/base/BaseContactDirective';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../../shared/states';
import { TranslateService } from '@ngx-translate/core';
import {
  AdditionalAddressTypeViewModel,
  DirectoryContactAddressViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  FileLinkViewModel,
  LeadContactViewModel,
} from '../../../shared/apis/advis';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import TitleEnum = DirectoryPersonViewModel.TitleEnum;
import { CommonUtil } from '../../../shared/utils/common.util';
import { NgForm } from '@angular/forms';
import { IExternalManagedAddress } from '../../../shared/interfaces/ExternalManagedAddress.interface';
import { EnumService, IEnumData } from 'app/shared/services/enum.service';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-single-contact',
  templateUrl: './single-contact.component.html',
  styleUrls: ['./single-contact.component.scss'],
})
export class SingleContactComponent
  extends BaseContactDirective
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() public contact: LeadContactViewModel;
  @Input() public logoFileSingle: FileLinkViewModel;
  @Input() public addressTypeChanged: boolean = false;
  @Output() public onLogoFileSelect: EventEmitter<File> = new EventEmitter<File>();
  @Output() public onLogoDelete: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() public onContactSave: EventEmitter<LeadContactViewModel> =
    new EventEmitter<LeadContactViewModel>();
  @Output() public onCopyAsExternalManaged: EventEmitter<IExternalManagedAddress> =
    new EventEmitter<IExternalManagedAddress>();

  @ViewChild('singleContactForm', { static: true }) public ngForm: NgForm;

  public _selectedTitleItem: string;
  public _selectedLanguageItem: string;
  public isDataStateQualified: boolean;
  public isLogoChanged: boolean = false;
  public requiresStrictValidation: boolean = false;
  public isObjectContact: boolean = false;

  constructor(
    dialogService: DialogService,
    store: Store<RootReducer.IState>,
    translate: TranslateService,
    sanitizer: DomSanitizer,
    enumService: EnumService
  ) {
    super(dialogService, store, translate, sanitizer, enumService);
  }

  public static PrepareCompanyData(contact: LeadContactViewModel): LeadContactViewModel {
    if (!contact.Company) {
      contact.Company = { Name: '', AdditionalName: '' };
    }
    contact.Company.Name = isNotNullOrUndefined(contact.Company?.Name) ? contact.Company?.Name : '';
    contact.Company.AdditionalName = isNotNullOrUndefined(contact.Company?.AdditionalName)
      ? contact.Company?.AdditionalName
      : '';
    return contact;
  }

  public setSelectedTitleItem(data: IEnumData): void {
    if (typeof data === 'string') {
      return;
    }
    this._selectedTitleItem = data?.display;
    this.contact.Person.Title = data?.key;
  }

  public setPersonLanguage(language: IEnumData): void {
    if (typeof language === 'string') {
      return;
    }
    this.contact.Person.Language = language.key;
    this._selectedLanguageItem = language.display;
  }

  public ngOnInit(): void {
    this.logoFile = this.logoFileSingle;
    this.contact = SingleContactComponent.PrepareCompanyData(this.contact);
    this.requiresStrictValidation =
      this.contact.AddressType === this.addressTypeEnum.Object ||
      this.contact.AddressType === this.addressTypeEnum.Delivery;
    this.isObjectContact = this.contact.AddressType === this.addressTypeEnum.Object;
    this.isDataStateQualified =
      this.contact.DataState === LeadContactViewModel.DataStateEnum.Qualified;

    this._selectedTitleItem = this.titleItems.find(
      item => item.key === this.contact.Person.Title
    )?.display;

    this._selectedLanguageItem = this.contact.Person.Language;

    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.logoFileSingle) {
      this.logoFile = this.logoFileSingle;
      super.ngOnInit();
    }
  }

  public ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.ngForm)) {
      this.subscriptions.add(
        this.ngForm.form.valueChanges.subscribe(() => {
          if (this.ngForm.invalid) {
            Object.values(this.ngForm.controls)
              .filter(c => c.invalid)
              .forEach(c => {
                c.markAllAsTouched();
              });
          }
        })
      );
    }
  }

  public onLogoSelect(logo: File | FileList): void {
    this.isLogoChanged = true;
    const newLogo: File = logo instanceof File ? logo : logo[0];
    this.logoUrl = URL.createObjectURL(newLogo);
    this.onLogoFileSelect.emit(logo instanceof File ? logo : logo[0]);
  }

  public logoDelete(): void {
    this.subscriptions.add(
      this.dialogService
        .openConfirm({ message: this.translate.instant('ADDRESS.CONFIRM_IMAGE_DELETION') })
        .afterClosed()
        .pipe(filter((res: boolean) => !!res))
        .subscribe(() => {
          this.logoUrl = undefined;
          this.isLogoChanged = true;
          this.onLogoDelete.emit(undefined);
        })
    );
  }

  public setEmailType(index: number, emailType: AdditionalAddressTypeViewModel): void {
    this.contact.Person.Emails[index].EmailType =
      emailType.Key as DirectoryEmailViewModel.EmailTypeEnum;
  }

  public setPhoneType(index: number, phoneType: AdditionalAddressTypeViewModel): void {
    this.contact.Person.Phones[index].PhoneType =
      phoneType.Key as DirectoryPhoneViewModel.PhoneTypeEnum;
  }

  public hasUnsavedData(): boolean {
    return this.ngForm.form.touched || this.isLogoChanged;
  }

  public save(): void {
    this.contact.Person = CommonUtil.trimAllStrings(this.contact.Person);
    if (this.contact.Company) {
      this.contact.Company = CommonUtil.trimAllStrings(this.contact.Company);
    }

    const contact: LeadContactViewModel = cloneDeep(this.contact);
    contact.Person.Id = contact.Person.Id ? contact.Person.Id : 0;
    contact.Company.Id = contact.Company.Id ? contact.Company.Id : 0;

    if (
      this.contact.Person.Title === TitleEnum.Company.toString() &&
      (isNullOrUndefined(this.contact.Company?.Name) || this.contact.Company?.Name.length === 0)
    ) {
      this.dialogService.openAlert(
        this.translate.instant('DIALOG.WARNING.SAVE_SINGLECONTACT_COMPANY_WITHOUT_COMPANYNAME'),
        this.translate.instant('LEAD.EM_WIZARD_SAVE'),
        this.translate.instant('COMMON.BTN_OK')
      );
      return;
    }

    if (
      contact.Company.Id === 0 &&
      (contact.Company?.Name || contact.Company?.AdditionalName) &&
      (contact.Company?.Name.length > 0 || contact.Company?.AdditionalName.length > 0)
    ) {
      contact.Company.Street = contact.Person.Street;
      contact.Company.ZipCode = contact.Person.ZipCode;
      contact.Company.City = contact.Person.City;
      contact.Company.Emails = [];
      contact.Company.Phones = [];
    } else if (contact.Company.Id === 0) {
      contact.Company = undefined;
    }

    contact.IsSingleContact = true;

    this.ngForm.form.markAsUntouched();
    this.isLogoChanged = false;

    this.onContactSave.emit(contact);
  }

  public dataStateClick(): void {
    this.isDataStateQualified = !this.isDataStateQualified;
    this.isDataStateQualified
      ? (this.contact.DataState = LeadContactViewModel.DataStateEnum.Qualified)
      : (this.contact.DataState = LeadContactViewModel.DataStateEnum.Unqualified);
  }

  public removeEmail(address: DirectoryContactAddressViewModel, index: number): void {
    this.ngForm.form.markAsTouched();
    super.removeEmail(address, index);
  }

  public removePhone(address: DirectoryContactAddressViewModel, index: number): void {
    this.ngForm.form.markAsTouched();
    super.removePhone(address, index);
  }
}
