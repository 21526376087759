/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LeadActionViewModel } from './leadActionViewModel';
import { LeadStatusViewModel } from './leadStatusViewModel';


export interface LeadStageViewModel { 
    Key?: string;
    Phase?: LeadStageViewModel.PhaseEnum;
    LeadStatus?: Array<LeadStatusViewModel>;
    AllowedLeadActions?: Array<LeadActionViewModel>;
}
export namespace LeadStageViewModel {
    export type PhaseEnum = 'Nbo' | 'Bo' | 'Project' | 'Break';
    export const PhaseEnum = {
        Nbo: 'Nbo' as PhaseEnum,
        Bo: 'Bo' as PhaseEnum,
        Project: 'Project' as PhaseEnum,
        Break: 'Break' as PhaseEnum
    };
}
