/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LeadCompositeViewModel } from '../model/leadCompositeViewModel';
import { MultipleLeadsViewModel } from '../model/multipleLeadsViewModel';
import { OrderEntryLeadViewModel } from '../model/orderEntryLeadViewModel';
import { TriageViewModel } from '../model/triageViewModel';
import { WinLeadRequest } from '../model/winLeadRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LeadActionsService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * The leads will be aborted with the given status. There will be a check whether the status has been defined.
     * 
     * @param leadIds 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsAbortMultipleLeads(leadIds?: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<MultipleLeadsViewModel>;
    public pCApiLeadActionsAbortMultipleLeads(leadIds?: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MultipleLeadsViewModel>>;
    public pCApiLeadActionsAbortMultipleLeads(leadIds?: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MultipleLeadsViewModel>>;
    public pCApiLeadActionsAbortMultipleLeads(leadIds?: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadIds !== undefined && leadIds !== null) {
            queryParameters = queryParameters.set('leadIds', <any>leadIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MultipleLeadsViewModel>(`${this.basePath}/api/pc/leads/actions/abort`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from Project_In_Operation to PROJECT_ACCOUNTED  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsAccountProject(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsAccountProject(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsAccountProject(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsAccountProject(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsAccountProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/account-project`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsApproveSolarTarif(leadId: number, body?: boolean, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsApproveSolarTarif(leadId: number, body?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsApproveSolarTarif(leadId: number, body?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsApproveSolarTarif(leadId: number, body?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsApproveSolarTarif.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/approve-solar-tariff`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from PROJECT_ACCOUNTED to PROJECT_ARCHIVED  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsArchiveProject(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsArchiveProject(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsArchiveProject(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsArchiveProject(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsArchiveProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/archive-project`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Each lead will be sent from a sales user to another sales user.  This switches the Stage from WON to PROJECT_ASSIGNED  &lt;param name&#x3D;\&quot;leadIds\&quot;&gt;Comma-separated ids of the leads to be transferred&lt;/param&gt;&lt;param name&#x3D;\&quot;newUserId\&quot;&gt;The user id of the new owner&lt;/param&gt;
     * 
     * @param leadIds 
     * @param newUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsAssignMultipleProjects(leadIds?: string, newUserId?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsAssignMultipleProjects(leadIds?: string, newUserId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsAssignMultipleProjects(leadIds?: string, newUserId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsAssignMultipleProjects(leadIds?: string, newUserId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadIds !== undefined && leadIds !== null) {
            queryParameters = queryParameters.set('leadIds', <any>leadIds);
        }
        if (newUserId !== undefined && newUserId !== null) {
            queryParameters = queryParameters.set('newUserId', <any>newUserId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/assign-projects`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * In case the stage will remain HANDOVER, the status will be changed to DATA_COLLECTION. The variant will be set to &#39;DEPOSITED&#39;, all other to &#39;COMPLETED&#39;.
     * 
     * @param leadId 
     * @param variantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsConfirmPayment(leadId: number, variantId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsConfirmPayment(leadId: number, variantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsConfirmPayment(leadId: number, variantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsConfirmPayment(leadId: number, variantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsConfirmPayment.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadActionsConfirmPayment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/pay/${encodeURIComponent(String(variantId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notifies the system that a customer could not be contacted.  In case the failed contact attempts exceed the allowed threshold defined in Stages.StageSettings an error will be produced.  The stage will be UNREACHED_BO or UNREACHED_NBO.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsCustomerContactFailed(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsCustomerContactFailed(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsCustomerContactFailed(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsCustomerContactFailed(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsCustomerContactFailed.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/contact/fail`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notifies the system that a customer has been successfully contacted.  The new stage will be FOLLOWUP_NBO or FOLLOWUP_BO.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsCustomerContactSucceeded(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsCustomerContactSucceeded(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsCustomerContactSucceeded(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsCustomerContactSucceeded(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsCustomerContactSucceeded.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/contact/success`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends the lead to a delegate. The delegate can&#39;t be the owner or the previous delegate  The stage will stay the same, but an entry will be made into the delegate history table.
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsDelegateLead(leadId: number, body?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsDelegateLead(leadId: number, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsDelegateLead(leadId: number, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsDelegateLead(leadId: number, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsDelegateLead.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/delegate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This will create a history item for the lead to reflect that the sales person has sent the customer an offer.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsDocumentBoSent(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsDocumentBoSent(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsDocumentBoSent(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsDocumentBoSent(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsDocumentBoSent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/bosent`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This will create a history item for the lead to reflect that the telesales person has sent the customer an offer.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsDocumentNboSent(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsDocumentNboSent(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsDocumentNboSent(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsDocumentNboSent(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsDocumentNboSent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/nbosent`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from PROJECT_IN_PROGRESS to PROJECT_IN_OPERATION  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsFinishProject(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsFinishProject(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsFinishProject(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsFinishProject(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsFinishProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/project-in-operation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the number of remaining attempts of customer contact for the lead.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsGetNumberOfPossibleContactAttempts(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public pCApiLeadActionsGetNumberOfPossibleContactAttempts(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public pCApiLeadActionsGetNumberOfPossibleContactAttempts(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public pCApiLeadActionsGetNumberOfPossibleContactAttempts(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsGetNumberOfPossibleContactAttempts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/contact/fail/possible`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsGetOrderEntryLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<OrderEntryLeadViewModel>;
    public pCApiLeadActionsGetOrderEntryLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderEntryLeadViewModel>>;
    public pCApiLeadActionsGetOrderEntryLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderEntryLeadViewModel>>;
    public pCApiLeadActionsGetOrderEntryLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsGetOrderEntryLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderEntryLeadViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/order-entry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be set to &#39;HANDOVER&#39;. This is to be used by telesales for marking leads in the stage &#39;FOLLOWUP_NBO&#39; as being ready for triage.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsHandover(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsHandover(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsHandover(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsHandover(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsHandover.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/handover`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The leads will be put on hold with the given status. There will be a check whether the status has been defined.
     * 
     * @param leadIds 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsHoldOnMultipleLeads(leadIds?: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<MultipleLeadsViewModel>;
    public pCApiLeadActionsHoldOnMultipleLeads(leadIds?: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MultipleLeadsViewModel>>;
    public pCApiLeadActionsHoldOnMultipleLeads(leadIds?: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MultipleLeadsViewModel>>;
    public pCApiLeadActionsHoldOnMultipleLeads(leadIds?: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadIds !== undefined && leadIds !== null) {
            queryParameters = queryParameters.set('leadIds', <any>leadIds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MultipleLeadsViewModel>(`${this.basePath}/api/pc/leads/actions/hold`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsOrderBackToFollowUp(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsOrderBackToFollowUp(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsOrderBackToFollowUp(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsOrderBackToFollowUp(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsOrderBackToFollowUp.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/order-back-to-followup`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from PROJECT_ACCOUNTED to PROJECT_IN_OPERATION  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsPutAccountedProjectBackToFinishedAsync(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsPutAccountedProjectBackToFinishedAsync(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutAccountedProjectBackToFinishedAsync(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutAccountedProjectBackToFinishedAsync(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsPutAccountedProjectBackToFinishedAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/put-accounted-back-to-project-in-operation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsPutArchivedProjectBackToAccountedAsync(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsPutArchivedProjectBackToAccountedAsync(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutArchivedProjectBackToAccountedAsync(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutArchivedProjectBackToAccountedAsync(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsPutArchivedProjectBackToAccountedAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/put-archived-project-back-to-accounted`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsPutFinishedProjectBackToInProgressAsync(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsPutFinishedProjectBackToInProgressAsync(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutFinishedProjectBackToInProgressAsync(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsPutFinishedProjectBackToInProgressAsync(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsPutFinishedProjectBackToInProgressAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/put-finished-project-back-to-in-progress`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be put back on the stage it was before being put on hold.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsReleaseLead(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsReleaseLead(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsReleaseLead(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsReleaseLead(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsReleaseLead.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/release`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from PROJECT_ASSIGNED back to WON  The owner also gets set to the previous owner  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsReleaseProject(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsReleaseProject(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsReleaseProject(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsReleaseProject(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsReleaseProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/release-project`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends the lead back to the owner and does not change stages
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsSendLeadBackToOwner(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsSendLeadBackToOwner(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwner(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwner(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsSendLeadBackToOwner.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/send-back`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends the lead back to the owner and set it to ABORT
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsSendLeadBackToOwnerAndAbort(leadId: number, body?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsSendLeadBackToOwnerAndAbort(leadId: number, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwnerAndAbort(leadId: number, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwnerAndAbort(leadId: number, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsSendLeadBackToOwnerAndAbort.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/send-back-abort`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends the lead back to the owner and set it to ON_HOLD
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsSendLeadBackToOwnerAndHoldOn(leadId: number, body?: string, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsSendLeadBackToOwnerAndHoldOn(leadId: number, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwnerAndHoldOn(leadId: number, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsSendLeadBackToOwnerAndHoldOn(leadId: number, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsSendLeadBackToOwnerAndHoldOn.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/send-back-hold-on`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param variantId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsStartOrder(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsStartOrder(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsStartOrder(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsStartOrder(leadId: number, variantId: number, body?: WinLeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsStartOrder.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadActionsStartOrder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/start-order/${encodeURIComponent(String(variantId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This switches the Stage from PROJECT_ASSIGNED to PROJECT_IN_PROGRESS  &lt;param name&#x3D;\&quot;leadId\&quot;&gt;The id of the lead&lt;/param&gt;
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsStartProject(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsStartProject(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsStartProject(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsStartProject(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsStartProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/start-project`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be transferred back to the previous owner and take up the previous stage. Ideal for transferring the lead back to telesales.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsTransferBack(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsTransferBack(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsTransferBack(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsTransferBack(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsTransferBack.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/transferback`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers multiple leads to a new owner. In case the new owner is NULL, the lead will be transferred to the current user.  This method is ideal for taking over multiple leads from the online pool, from colleagues on holiday oder transferring leads to colleagues.  Stages and status will not be touched unless the lead has been transferred from the online pool.  In the case a TC takes the lead from onlinepool, they will set it to ASSIGNED_NBO  In the case a Sales takes it from online pool, they will set it to ASSIGNED_BO
     * 
     * @param leadIds 
     * @param newUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsTransferMultipleLeads(leadIds?: string, newUserId?: string, observe?: 'body', reportProgress?: boolean): Observable<MultipleLeadsViewModel>;
    public pCApiLeadActionsTransferMultipleLeads(leadIds?: string, newUserId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MultipleLeadsViewModel>>;
    public pCApiLeadActionsTransferMultipleLeads(leadIds?: string, newUserId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MultipleLeadsViewModel>>;
    public pCApiLeadActionsTransferMultipleLeads(leadIds?: string, newUserId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (leadIds !== undefined && leadIds !== null) {
            queryParameters = queryParameters.set('leadIds', <any>leadIds);
        }
        if (newUserId !== undefined && newUserId !== null) {
            queryParameters = queryParameters.set('newUserId', <any>newUserId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<MultipleLeadsViewModel>(`${this.basePath}/api/pc/leads/actions/transfer`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be set to &#39;ASSIGNED_BO&#39; and the variant for which the id is being sent to &#39;DEPOSITED&#39;. If the lead contains other variants, they will be set to &#39;COMPLETE&#39;. The address will be exported to the MIS system at this point.
     * 
     * @param leadId 
     * @param variantId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsTriageLeadWithVariants(leadId: number, variantId: number, body?: TriageViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsTriageLeadWithVariants(leadId: number, variantId: number, body?: TriageViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsTriageLeadWithVariants(leadId: number, variantId: number, body?: TriageViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsTriageLeadWithVariants(leadId: number, variantId: number, body?: TriageViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsTriageLeadWithVariants.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadActionsTriageLeadWithVariants.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/triage/${encodeURIComponent(String(variantId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be set to &#39;ASSIGNED_BO&#39;. The address will be exported to the MIS system at this point.
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsTriageLeadWithoutVariants(leadId: number, body?: TriageViewModel, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsTriageLeadWithoutVariants(leadId: number, body?: TriageViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsTriageLeadWithoutVariants(leadId: number, body?: TriageViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsTriageLeadWithoutVariants(leadId: number, body?: TriageViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsTriageLeadWithoutVariants.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/triage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be set to &#39;WON&#39; as well as the variant for which the id is being sent. If the lead contains other variants, they will be set to &#39;ABORT&#39;. The offer will be exported to the MIS system at this point.
     * 
     * @param leadId 
     * @param variantId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsWinLeadWithVariant(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsWinLeadWithVariant(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsWinLeadWithVariant(leadId: number, variantId: number, body?: WinLeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsWinLeadWithVariant(leadId: number, variantId: number, body?: WinLeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsWinLeadWithVariant.');
        }

        if (variantId === null || variantId === undefined) {
            throw new Error('Required parameter variantId was null or undefined when calling pCApiLeadActionsWinLeadWithVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/win/${encodeURIComponent(String(variantId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsWinLeadWithoutVariant(leadId: number, body?: WinLeadRequest, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsWinLeadWithoutVariant(leadId: number, body?: WinLeadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsWinLeadWithoutVariant(leadId: number, body?: WinLeadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsWinLeadWithoutVariant(leadId: number, body?: WinLeadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsWinLeadWithoutVariant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/win`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * The lead will be put back on the stage it was before being put on hold.
     * 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pCApiLeadActionsWonBackToFollowupBo(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<LeadCompositeViewModel>;
    public pCApiLeadActionsWonBackToFollowupBo(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LeadCompositeViewModel>>;
    public pCApiLeadActionsWonBackToFollowupBo(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LeadCompositeViewModel>>;
    public pCApiLeadActionsWonBackToFollowupBo(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling pCApiLeadActionsWonBackToFollowupBo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<LeadCompositeViewModel>(`${this.basePath}/api/pc/leads/actions/${encodeURIComponent(String(leadId))}/wonBackToFollowupBo`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
