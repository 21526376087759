<div
  draggable="false"
  fxLayout="row"
  fxLayoutAlign="start center"
  class="custom-file-upload"
  (click)="onBrowseFiles()">
  <input
    #customFileUploadInput
    type="file"
    class="custom-file-upload-input"
    [accept]="accept"
    (change)="onFileSelect($event)"
    [multiple]="isMultiple" />
  <helion-ui-icon
    [attr.data-feather]="iconName"
    *ngIf="!isOnlyIconButton; else icon"
    class="custom-file-upload-button"
    [size]="iconSize"
    [disabled]="disabled"
    [attr.aria-label]="'COMMON.UPLOAD' | translate"
    tooltip="{{ 'COMMON.UPLOAD' | translate }}">
  </helion-ui-icon>

  <ng-template #icon>
    <helion-ui-icon
      [attr.data-feather]="iconName"
      [size]="iconSize"
      class="custom-file-upload-button">
    </helion-ui-icon>
  </ng-template>
  <ng-content></ng-content>
</div>
