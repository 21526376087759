/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AdditionalEmailViewModel { 
    EmailType: AdditionalEmailViewModel.EmailTypeEnum;
    Email: string;
}
export namespace AdditionalEmailViewModel {
    export type EmailTypeEnum = 'Mail' | 'Work' | 'Private' | 'Other' | 'Primary' | 'Reminder' | 'Billing';
    export const EmailTypeEnum = {
        Mail: 'Mail' as EmailTypeEnum,
        Work: 'Work' as EmailTypeEnum,
        Private: 'Private' as EmailTypeEnum,
        Other: 'Other' as EmailTypeEnum,
        Primary: 'Primary' as EmailTypeEnum,
        Reminder: 'Reminder' as EmailTypeEnum,
        Billing: 'Billing' as EmailTypeEnum
    };
}
