import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { LoadingModalService } from './loading-modal.service';
import { Configuration } from '../apis/advis';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

/*
  Since we introduced JWT auth, the previous swagger workaround to download files
  doesn't work anymore (https://github.com/swagger-api/swagger-codegen/issues/8409)
  This service is used to download files from the server, irrespective of JWT or
  cookie auth or swagger limitations.
  Drawback is that we have to construct the URL ourselves.
  For more context see HP-10141, this service will be obsolete as soon as we
  correctly configure swagger to generate the correct code.
*/

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(
    private configuration: Configuration,
    private http: HttpClient,
    private loadingModalService: LoadingModalService,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {}

  public downloadFile(url: string): void {
    this.loadingModalService.openModal();

    url = url.startsWith('/') ? url : `/${url}`;
    url = `${environment.apiBasePath}${url}`;

    this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
      })
      .subscribe(response => {
        this.loadingModalService.closeModal();

        if (response.status == 200) {
          saveAs(response.body, this.getFilenameFromHeader(response));
        } else if (response.status == 403) {
          this.dialogService.openAlert(
            this.translate.instant('VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.MESSAGE_NO_ACCESS'),
            this.translate.instant('VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.TITLE')
          );
        } else if (response.status == 404) {
          this.showErrorMessage(
            this.translate.instant(
              'VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.MESSAGE_TEMPLATE_NOT_FOUND'
            ),
            this.translate.instant('VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.TITLE')
          );
        } else {
          this.showErrorMessage(
            this.translate.instant('VARIANT_OFFER_DOWNLOAD.WARNING.DIALOG.TITLE'),
            ''
          );
        }
      });
  }

  private getFilenameFromHeader(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('content-disposition');
    return contentDisposition
      ? contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '')
      : 'untitled';
  }

  private showErrorMessage(title: string, message: string): void {
    this.dialogService.openAlert(message, title);
  }
}
