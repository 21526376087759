import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'pc-mandant-indicator',
  templateUrl: './mandant-indicator.component.html',
  styleUrls: ['./mandant-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MandantIndicatorComponent implements OnInit, OnChanges {
  @Input('MandantId') mandantId: number;

  logoSrc: string;

  ngOnInit(): void {
    this.setLogoSrc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mandantId) {
      this.setLogoSrc();
    }
  }

  private setLogoSrc(): void {
    this.logoSrc = '../../assets/images/mandant' + this.mandantId + '.png';
  }
}
