import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as ActionsAction from './actions.action';
import {
  ActionAssignFailureAction,
  ActionBoSentFailureAction,
  ActionContactFailureAction,
  ActionDepositFailureAction,
  ActionHoldMultipleSuccessAction,
  ActionNboSentFailureAction,
  ActionOnHoldFailureAction,
  ActionProjectArchivedFailureAction,
  ActionProjectAssignedFailureAction,
  ActionProjectFinishFailureAction,
  ActionProjectReleaseFailureAction,
  ActionProjectStartFailureAction,
  ActionReleaseFailureAction,
  ActionWinFailureAction,
  ActionTransferBackFailureAction,
  ActionTransferFailureAction,
  ActionTriageFailureAction,
  ActionUnreachedFailureAction,
  IActionAbortPayload,
  IActionOnHoldActionPayload,
  IActionProjectAssignedPayload,
  IActionReleasePayload,
  IActionSentActionPayload,
  IActionWinActionPayload,
  IActionDelegatePayload,
  IActionTransferPayload,
  IActionTriageActionPayload,
  IActionWonBackToFollowupBoPayload,
  ActionDelegateFailureAction,
  ActionSendBackFailureAction,
  IActionSendBackActionPayload,
  IActionContactAndTriageActionPayload,
  IActionMultiplePayload,
} from './actions.action';
import { ConfigService } from '../../services/config.service';
import { ErrorAddAction } from '../global/global.action';
import { ErrorTypeE } from '../global/global.reducer';
import {
  ExternalSystem,
  FailedLeadViewModel,
  LeadActionsService,
  LeadCompositeViewModel,
  LeadService,
  MultipleLeadsViewModel,
  WinLeadRequest,
} from '../../apis/advis';
import { catchError, debounceTime, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { LoadingModalService } from '../../services/loading-modal.service';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { LeadPermissionsService } from '../../services/lead-permissions.service';
import { SynchronizeLeadAction } from '../lead/lead.action';
import * as RootReducer from '../index';

@Injectable()
export class ActionsEffects {
  actionContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_CONTACT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionContactAction) => action.payload),
      switchMap((payload: { leadId: number }) => {
        return this.leadActionsService
          .pCApiLeadActionsCustomerContactSucceeded(payload.leadId)
          .pipe(
            map(
              (lead: LeadCompositeViewModel) => new ActionsAction.ActionContactSuccessAction(lead)
            ),
            catchError((e: any) => of(new ActionsAction.ActionContactFailureAction(e)))
          );
      })
    )
  );

  actionUnreached$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_UNREACHED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionUnreachedAction) => action.payload),
      switchMap((payload: { leadId: number }) => {
        return this.leadActionsService.pCApiLeadActionsCustomerContactFailed(payload.leadId).pipe(
          map(
            (lead: LeadCompositeViewModel) => new ActionsAction.ActionUnreachedSuccessAction(lead)
          ),
          catchError((e: any) => of(new ActionsAction.ActionUnreachedFailureAction(e)))
        );
      })
    )
  );

  actionWin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_WIN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionWinAction) => action.payload),
      switchMap((payload: IActionWinActionPayload) => {
        if (isNullOrUndefined(payload.variantId)) {
          return this.leadActionsService
            .pCApiLeadActionsWinLeadWithoutVariant(payload.leadId, payload.winLeadRequest)
            .pipe(
              map((lead: LeadCompositeViewModel) => {
                if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                  this.store.dispatch(
                    new SynchronizeLeadAction({
                      leadId: lead.Lead.Id,
                      leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                    })
                  );
                }
                return new ActionsAction.ActionWinSuccessAction(lead);
              }),
              catchError((e: any) => of(new ActionsAction.ActionWinFailureAction(e)))
            );
        } else {
          return this.leadActionsService
            .pCApiLeadActionsWinLeadWithVariant(
              payload.leadId,
              payload.variantId,
              payload.winLeadRequest
            )
            .pipe(
              map((lead: LeadCompositeViewModel) => {
                if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                  this.store.dispatch(
                    new SynchronizeLeadAction({
                      leadId: lead.Lead.Id,
                      leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                    })
                  );
                }
                return new ActionsAction.ActionWinSuccessAction(lead);
              }),
              catchError((e: any) => of(new ActionsAction.ActionWinFailureAction(e)))
            );
        }
      })
    )
  );

  actionStartOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_START_ORDER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionStartOrderAction) => action.payload),
      switchMap((payload: { leadId: number; variantId: number; winRequest: WinLeadRequest }) => {
        return this.leadActionsService
          .pCApiLeadActionsStartOrder(payload.leadId, payload.variantId, payload.winRequest)
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              return new ActionsAction.ActionStartOrderSuccessAction(lead);
            }),
            catchError((e: any) => of(new ActionsAction.ActionStartOrderFailureAction(e)))
          );
      })
    )
  );

  actionORderBackToFollowUp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_ORDER_BACK_TO_FOLLOWUP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionOrderBackToFollowUpAction) => action.payload),
      switchMap((payload: { leadId: number }) => {
        return this.leadActionsService.pCApiLeadActionsOrderBackToFollowUp(payload.leadId).pipe(
          map((lead: LeadCompositeViewModel) => {
            return new ActionsAction.ActionOrderBackToFollowUpSuccessAction(lead);
          }),
          catchError((e: any) => of(new ActionsAction.ActionOrderBackToFollowUpFailureAction(e)))
        );
      })
    )
  );

  actionAbort$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_ABORT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionAbortAction) => action.payload),
      switchMap((payload: IActionAbortPayload) => {
        return this.leadActionsService
          .pCApiLeadActionsAbortMultipleLeads(payload.leadId.toString(), payload.status)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionsAction.ActionAbortSuccessAction(lead.SuccessfulLeads[0])
            ),
            catchError((e: any) => of(new ActionsAction.ActionAbortFailureAction(e)))
          );
      })
    )
  );

  actionOnHold$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_ON_HOLD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionOnHoldAction) => action.payload),
      switchMap((payload: IActionOnHoldActionPayload) => {
        return this.leadActionsService
          .pCApiLeadActionsHoldOnMultipleLeads(payload.leadId.toString(), payload.status)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionsAction.ActionOnHoldSuccessAction(lead.SuccessfulLeads[0])
            ),
            catchError((e: any) => of(new ActionsAction.ActionOnHoldFailureAction(e)))
          );
      })
    )
  );

  actionTransfer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_TRANSFER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionTransferAction) => action.payload),
      switchMap((payload: IActionTransferPayload) => {
        return this.leadActionsService
          .pCApiLeadActionsTransferMultipleLeads(payload.leadId.toString(), payload.newOwnerId)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionsAction.ActionTransferSuccessAction(lead.SuccessfulLeads[0])
            ),
            catchError((e: any) => of(new ActionsAction.ActionTransferFailureAction(e)))
          );
      })
    )
  );

  actionDelegate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_DELEGATE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionDelegateAction) => action.payload),
      switchMap((payload: IActionDelegatePayload) => {
        return this.leadActionsService
          .pCApiLeadActionsDelegateLead(payload.leadId, payload.delegateId)
          .pipe(
            flatMap((leadAnswer: LeadCompositeViewModel) => {
              return this.leadService.leadAddTask(payload.leadId, { TaskToAdd: payload.task }).pipe(
                map(() => new ActionsAction.ActionDelegateSuccessAction(leadAnswer)),
                catchError((e: any) => of(new ActionsAction.ActionDelegateFailureAction(e)))
              );
            }),
            catchError((e: any) => of(new ActionsAction.ActionDelegateFailureAction(e)))
          );
      })
    )
  );

  actionSendBack$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_SEND_BACK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionSendBackAction) => action.payload),
      switchMap((payload: IActionSendBackActionPayload) => {
        return this.sendLeadBack(payload).pipe(
          map(
            (lead: LeadCompositeViewModel) => new ActionsAction.ActionSendBackSuccessAction(lead)
          ),
          catchError((e: any) => of(new ActionsAction.ActionSendBackFailureAction(e)))
        );
      })
    )
  );

  actionTransferMultiple$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_TRANSFER_MULTIPLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionTransferMultipleAction) => action.payload),
      switchMap((payload: IActionMultiplePayload) => {
        return this.leadActionsService
          .pCApiLeadActionsTransferMultipleLeads(payload.leadIds.join(), payload.status)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionsAction.ActionTransferMultipleSuccessAction({
                  leads: lead.SuccessfulLeads || [],
                  failed: this.getFailed(lead.FailedLeads),
                })
            ),
            catchError((e: any) => of(new ActionsAction.ActionTransferFailureAction(e)))
          );
      })
    )
  );

  actionAbortMultiple$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_ABORT_MULTIPLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionAbortMultipleAction) => action.payload),
      switchMap((payload: IActionMultiplePayload) => {
        return this.leadActionsService
          .pCApiLeadActionsAbortMultipleLeads(payload.leadIds.join(), payload.status)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionsAction.ActionAbortMultipleSuccessAction({
                  leads: lead.SuccessfulLeads || [],
                  failed: this.getFailed(lead.FailedLeads),
                })
            ),
            catchError((e: any) => of(new ActionsAction.ActionAbortFailureAction(e)))
          );
      })
    )
  );

  actionHoldMultiple$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_HOLD_MULTIPLE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionHoldMultipleAction) => action.payload),
      switchMap((payload: IActionMultiplePayload) => {
        return this.leadActionsService
          .pCApiLeadActionsHoldOnMultipleLeads(payload.leadIds.join(), payload.status)
          .pipe(
            map(
              (lead: MultipleLeadsViewModel) =>
                new ActionHoldMultipleSuccessAction({
                  leads: lead.SuccessfulLeads || [],
                  failed: this.getFailed(lead.FailedLeads),
                })
            ),
            catchError((e: any) => of(new ActionsAction.ActionAbortFailureAction(e)))
          );
      })
    )
  );

  actionTransferAborted$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_RELEASE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionReleaseAction) => action.payload),
      switchMap((payload: IActionReleasePayload) => {
        return this.leadActionsService.pCApiLeadActionsReleaseLead(payload.leadId).pipe(
          flatMap(() => {
            return this.leadActionsService
              .pCApiLeadActionsTransferMultipleLeads(payload.leadId.toString(), payload.newOwner)
              .pipe(
                map(
                  (lead: MultipleLeadsViewModel) =>
                    new ActionsAction.ActionHoldMultipleSuccessAction({
                      leads: lead.SuccessfulLeads || [],
                      failed: this.getFailed(lead.FailedLeads),
                    })
                ),
                catchError((e: any) => of(new ActionsAction.ActionReleaseFailureAction(e)))
              );
          }),
          catchError((e: any) => of(new ActionsAction.ActionReleaseFailureAction(e)))
        );
      })
    )
  );

  actionTransferWonBackToFollowupBo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_WON_BACK_TO_FOLLOWUP_BO),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionWonBackToFollowupBoAction) => action.payload),
      switchMap((payload: IActionWonBackToFollowupBoPayload) => {
        return this.leadActionsService.pCApiLeadActionsWonBackToFollowupBo(payload.leadId).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new ActionsAction.ActionHoldMultipleSuccessAction({ leads: [lead] || [], failed: [] })
          ),
          catchError((e: any) => of(new ActionsAction.ActionReleaseFailureAction(e)))
        );
      })
    )
  );

  actionTransferBack: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_TRANSFER_BACK),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionTransferBackAction) => action.payload),
      switchMap((payload: { leadId: number }) => {
        return this.leadActionsService.pCApiLeadActionsTransferBack(payload.leadId).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new ActionsAction.ActionTransferBackSuccessAction(lead)
          ),
          catchError((e: any) => of(new ActionsAction.ActionTransferBackFailureAction(e)))
        );
      })
    )
  );

  actionTriage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_TRIAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionTriageAction) => action.payload),
      switchMap((payload: IActionTriageActionPayload) => {
        if (isNullOrUndefined(payload.variantId)) {
          return this.leadActionsService
            .pCApiLeadActionsTriageLeadWithoutVariants(payload.leadId, {
              LeadId: payload.leadId,
              NewUserId: payload.newOwnerId,
            })
            .pipe(
              map(
                (leadComposite: LeadCompositeViewModel) =>
                  new ActionsAction.ActionTriageSuccessAction(leadComposite)
              ),
              catchError((e: any) => of(new ActionsAction.ActionTriageFailureAction(e)))
            );
        } else {
          return this.leadActionsService
            .pCApiLeadActionsTriageLeadWithVariants(payload.leadId, payload.variantId, {
              LeadId: payload.leadId,
              NewUserId: payload.newOwnerId,
            })
            .pipe(
              map(
                (leadComposite: LeadCompositeViewModel) =>
                  new ActionsAction.ActionTriageSuccessAction(leadComposite)
              ),
              catchError((e: any) => of(new ActionsAction.ActionTriageFailureAction(e)))
            );
        }
      })
    )
  );

  actionContactAndTriage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_CONTACT_AND_TRIAGE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionContactAndTriageAction) => action.payload),
      switchMap((actionContactAndTriageActionPayload: IActionContactAndTriageActionPayload) => {
        return this.leadActionsService
          .pCApiLeadActionsCustomerContactSucceeded(actionContactAndTriageActionPayload.leadId)
          .pipe(
            flatMap(() => {
              if (isNullOrUndefined(actionContactAndTriageActionPayload.variantId)) {
                return this.leadActionsService
                  .pCApiLeadActionsTriageLeadWithoutVariants(
                    actionContactAndTriageActionPayload.leadId,
                    {
                      LeadId: actionContactAndTriageActionPayload.leadId,
                      NewUserId: actionContactAndTriageActionPayload.newOwnerId,
                    }
                  )
                  .pipe(
                    map(
                      (leadComposite: LeadCompositeViewModel) =>
                        new ActionsAction.ActionContactAndTriageSuccessAction(leadComposite)
                    ),
                    catchError((e: any) =>
                      of(new ActionsAction.ActionContactAndTriageFailureAction(e))
                    )
                  );
              } else {
                return this.leadActionsService
                  .pCApiLeadActionsTriageLeadWithVariants(
                    actionContactAndTriageActionPayload.leadId,
                    actionContactAndTriageActionPayload.variantId,
                    {
                      LeadId: actionContactAndTriageActionPayload.leadId,
                      NewUserId: actionContactAndTriageActionPayload.newOwnerId,
                    }
                  )
                  .pipe(
                    map(
                      (leadComposite: LeadCompositeViewModel) =>
                        new ActionsAction.ActionContactAndTriageSuccessAction(leadComposite)
                    ),
                    catchError((e: any) =>
                      of(new ActionsAction.ActionContactAndTriageFailureAction(e))
                    )
                  );
              }
            })
          );
      })
    )
  );

  actionContactAndTsWin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_CONTACT_AND_TS_WIN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionContactAndTsWinAction) => action.payload),
      switchMap((actionContactAndTsWinActionPayload: { leadId: number }) => {
        return this.leadActionsService
          .pCApiLeadActionsCustomerContactSucceeded(actionContactAndTsWinActionPayload.leadId)
          .pipe(
            flatMap(() => {
              return this.leadActionsService
                .pCApiLeadActionsHandover(actionContactAndTsWinActionPayload.leadId)
                .pipe(
                  map(
                    (lead: LeadCompositeViewModel) =>
                      new ActionsAction.ActionContactAndTsWinSuccessAction(lead)
                  ),
                  catchError((e: any) =>
                    of(new ActionsAction.ActionContactAndTsWinFailureAction(e))
                  )
                );
            })
          );
      })
    )
  );

  nboSent: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_NBO_SENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionNboSentAction) => action.payload),
      switchMap((payload: IActionSentActionPayload) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsDocumentNboSent(payload.leadId).pipe(
          map((lead: LeadCompositeViewModel) => new ActionsAction.ActionNboSentSuccessAction(lead)),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionNboSentFailureAction(e)))
        );
      })
    )
  );

  boSent: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_BO_SENT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionBoSentAction) => action.payload),
      switchMap((payload: IActionSentActionPayload) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsDocumentBoSent(payload.leadId).pipe(
          map((lead: LeadCompositeViewModel) => new ActionsAction.ActionBoSentSuccessAction(lead)),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionBoSentFailureAction(e)))
        );
      })
    )
  );

  projectAssign$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_ASSIGN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectAssignedAction) => action.payload),
      switchMap((payload: IActionProjectAssignedPayload) => {
        this.loadingModalService.openModal();
        return this.leadActionsService
          .pCApiLeadActionsAssignMultipleProjects(payload.leadIds.toString(), payload.newUserId)
          .pipe(
            map(() => {
              if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                payload.leadIds.split("'").forEach(x =>
                  this.store.dispatch(
                    new SynchronizeLeadAction({
                      leadId: +x,
                      leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                    })
                  )
                );
              }
              return new ActionsAction.ActionProjectAssignedSuccessAction(payload);
            }),
            tap(() => this.loadingModalService.closeModal()),
            catchError((e: any) => of(new ActionsAction.ActionProjectAssignedFailureAction(e)))
          );
      })
    )
  );

  projectStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_START),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectStartAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsStartProject(leadId).pipe(
          map(
            (lead: LeadCompositeViewModel) =>
              new ActionsAction.ActionProjectStartSuccessAction(lead)
          ),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionProjectStartFailureAction(e)))
        );
      })
    )
  );

  projectFinish$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_FINISH),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectFinishAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsFinishProject(leadId).pipe(
          map((lead: LeadCompositeViewModel) => {
            if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
              this.store.dispatch(
                new SynchronizeLeadAction({
                  leadId: lead.Lead.Id,
                  leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                })
              );
            }
            return new ActionsAction.ActionProjectFinishSuccessAction(lead);
          }),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionProjectFinishFailureAction(e)))
        );
      })
    )
  );

  projectAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_ACCOUNT),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectAccountAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsAccountProject(leadId).pipe(
          map((lead: LeadCompositeViewModel) => {
            if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
              this.store.dispatch(
                new SynchronizeLeadAction({
                  leadId: lead.Lead.Id,
                  leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                })
              );
            }
            return new ActionsAction.ActionProjectAccountSuccessAction(lead);
          }),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionProjectAccountFailureAction(e)))
        );
      })
    )
  );

  projectPutAccountedBackToFinished$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionPutAccountedProjectBackToFinishedAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService
          .pCApiLeadActionsPutAccountedProjectBackToFinishedAsync(leadId)
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                this.store.dispatch(
                  new SynchronizeLeadAction({
                    leadId: lead.Lead.Id,
                    leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                  })
                );
              }
              return new ActionsAction.ActionPutAccountedProjectBackToFinishedSuccessAction(lead);
            }),
            tap(() => this.loadingModalService.closeModal()),
            catchError((e: any) =>
              of(new ActionsAction.ActionPutAccountedProjectBackToFinishedFailureAction(e))
            )
          );
      })
    )
  );

  projectPutArchivedBackToAccounted$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionPutArchivedProjectBackToAccountedAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService
          .pCApiLeadActionsPutArchivedProjectBackToAccountedAsync(leadId)
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                this.store.dispatch(
                  new SynchronizeLeadAction({
                    leadId: lead.Lead.Id,
                    leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                  })
                );
              }
              return new ActionsAction.ActionPutArchivedProjectBackToAccountedSuccessAction(lead);
            }),
            tap(() => this.loadingModalService.closeModal()),
            catchError((e: any) =>
              of(new ActionsAction.ActionPutAccountedProjectBackToFinishedFailureAction(e))
            )
          );
      })
    )
  );

  projectPutFinishedBackToInProgress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionPutFinishedProjectBackToInProgressAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService
          .pCApiLeadActionsPutFinishedProjectBackToInProgressAsync(leadId)
          .pipe(
            map((lead: LeadCompositeViewModel) => {
              if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
                this.store.dispatch(
                  new SynchronizeLeadAction({
                    leadId: lead.Lead.Id,
                    leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                  })
                );
              }
              return new ActionsAction.ActionPutFinishedProjectBackToInProgressSuccessAction(lead);
            }),
            tap(() => this.loadingModalService.closeModal()),
            catchError((e: any) =>
              of(new ActionsAction.ActionPutFinishedProjectBackToInProgressFailureAction(e))
            )
          );
      })
    )
  );

  projectArchived$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_ARCHIVED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectArchivedAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsArchiveProject(leadId).pipe(
          map((lead: LeadCompositeViewModel) => {
            if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
              this.store.dispatch(
                new SynchronizeLeadAction({
                  leadId: lead.Lead.Id,
                  leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                })
              );
            }
            return new ActionsAction.ActionProjectArchivedSuccessAction(lead);
          }),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionProjectArchivedFailureAction(e)))
        );
      })
    )
  );

  projectRelease$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionsAction.ACTION_PROJECT_RELEASE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ActionsAction.ActionProjectReleaseAction) => action.payload),
      switchMap((leadId: number) => {
        this.loadingModalService.openModal();
        return this.leadActionsService.pCApiLeadActionsReleaseProject(leadId).pipe(
          map((lead: LeadCompositeViewModel) => {
            if (this.leadPermissionsService.getCanSeeHelionImportPermission()) {
              this.store.dispatch(
                new SynchronizeLeadAction({
                  leadId: lead.Lead.Id,
                  leadSynchronizationToExternalSystem: ExternalSystem.BlueOffice,
                })
              );
            }
            return new ActionsAction.ActionProjectReleaseSuccessAction(lead);
          }),
          tap(() => this.loadingModalService.closeModal()),
          catchError((e: any) => of(new ActionsAction.ActionProjectReleaseFailureAction(e)))
        );
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActionsAction.ACTION_CONTACT_FAILURE,
        ActionsAction.ACTION_UNREACHED_FAILURE,
        ActionsAction.ACTION_WIN_FAILURE,
        ActionsAction.ACTION_START_ORDER_FAILURE,
        ActionsAction.ACTION_ORDER_BACK_TO_FOLLOWUP_FAILURE,
        ActionsAction.ACTION_ASSIGN_FAILURE,
        ActionsAction.ACTION_DELEGATE_FAILURE,
        ActionsAction.ACTION_SEND_BACK_FAILURE,
        ActionsAction.ACTION_TRIAGE_FAILURE,
        ActionsAction.ACTION_DEPOSIT_FAILURE,
        ActionsAction.ACTION_TRANSFER_FAILURE,
        ActionsAction.ACTION_RELEASE_FAILURE,
        ActionsAction.ACTION_TRANSFER_BACK_FAILURE,
        ActionsAction.ACTION_ON_HOLD_FAILURE,
        ActionsAction.ACTION_ABORT_FAILURE,
        ActionsAction.ACTION_NBO_SENT_FAILURE,
        ActionsAction.ACTION_BO_SENT_FAILURE,
        ActionsAction.ACTION_PROJECT_ASSIGN_FAILURE,
        ActionsAction.ACTION_PROJECT_START_FAILURE,
        ActionsAction.ACTION_PROJECT_FINISH_FAILURE,
        ActionsAction.ACTION_PROJECT_ARCHIVED_FAILURE,
        ActionsAction.ACTION_PROJECT_RELEASE_FAILURE,
        ActionsAction.ACTION_PUT_ACCOUNTED_PROJECT_BACK_TO_FINISHED_FAILURE,
        ActionsAction.ACTION_PROJECT_ACCOUNT_FAILURE,
        ActionsAction.ACTION_PUT_FINISHED_PROJECT_BACK_TO_IN_PROGRESS_FAILURE,
        ActionsAction.ACTION_PUT_ARCHIVED_PROJECT_BACK_TO_ACCOUNTED_FAILURE
      ),
      debounceTime(this.config.apiDebounceTimeMs),
      map(
        (
          action:
            | ActionContactFailureAction
            | ActionUnreachedFailureAction
            | ActionWinFailureAction
            | ActionAssignFailureAction
            | ActionTriageFailureAction
            | ActionDepositFailureAction
            | ActionTransferFailureAction
            | ActionReleaseFailureAction
            | ActionDelegateFailureAction
            | ActionSendBackFailureAction
            | ActionTransferBackFailureAction
            | ActionOnHoldFailureAction
            | ActionNboSentFailureAction
            | ActionBoSentFailureAction
            | ActionProjectAssignedFailureAction
            | ActionProjectStartFailureAction
            | ActionProjectFinishFailureAction
            | ActionProjectArchivedFailureAction
            | ActionProjectReleaseFailureAction
            | ActionsAction.ActionStartOrderFailureAction
            | ActionsAction.ActionOrderBackToFollowUpFailureAction
            | ActionsAction.ActionPutFinishedProjectBackToInProgressFailureAction
            | ActionsAction.ActionPutArchivedProjectBackToAccountedFailureAction
        ) => {
          this.loadingModalService.closeModal();
          return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private leadService: LeadService,
    private leadActionsService: LeadActionsService,
    private config: ConfigService,
    private leadPermissionsService: LeadPermissionsService,
    private store: Store<RootReducer.IState>,
    private loadingModalService: LoadingModalService
  ) {}

  private getFailed(failedLeads: FailedLeadViewModel[]): IActionAbortPayload[] {
    return failedLeads.map((f: FailedLeadViewModel) => {
      return { leadId: f.LeadId, status: f.ErrorMessage };
    });
  }

  private sendLeadBack(payload: IActionSendBackActionPayload): Observable<LeadCompositeViewModel> {
    if (payload.abortStatus) {
      return this.leadActionsService.pCApiLeadActionsSendLeadBackToOwnerAndAbort(
        payload.leadId,
        payload.abortStatus.Name
      );
    }

    if (payload.onHoldStatus) {
      return this.leadActionsService.pCApiLeadActionsSendLeadBackToOwnerAndHoldOn(
        payload.leadId,
        payload.onHoldStatus.Name
      );
    }

    return this.leadActionsService.pCApiLeadActionsSendLeadBackToOwner(payload.leadId);
  }
}
