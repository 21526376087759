import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss'],
})
export class CustomFileUploadComponent {
  @Input() protected accept: string = '';
  @Input() protected capture: string;
  @Input() protected isMultiple: boolean = false;
  @Input() protected iconName: string = 'upload-cloud';
  @Input() protected buttonColor: string = 'accent';
  @Input() protected disabled: boolean = false;
  @Input() protected isOnlyIconButton: boolean = false;

  @Output() protected selectFile: EventEmitter<File | FileList> = new EventEmitter<
    File | FileList
  >();

  @ViewChild('customFileUploadInput', { static: true }) protected customFileUploadInput: ElementRef;

  constructor(private dialogService: DialogService, private translate: TranslateService) {}

  // Clear FileList on upload cancel
  public cancelUpload(): void {
    this.customFileUploadInput.nativeElement.value = '';
  }

  public onBrowseFiles(): void {
    this.customFileUploadInput.nativeElement.click();
  }

  public onFileSelect(evt: any): void {
    const fileList: FileList = evt.target.files;
    if (fileList.length > 0) {
      for (let i: number = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        if (!this.verifyAccept(file.type, this.accept)) {
          this.showWrongFileTypeWarningDialog();
          return;
        }
      }

      this.selectFile.emit(fileList.length === 1 ? fileList[0] : fileList);
      this.customFileUploadInput.nativeElement.value = '';
    }
  }

  protected verifyAccept(fileType: string, accept: string): boolean {
    const typeRegExp: RegExp = new RegExp(accept.replace(/\*/g, '.*').replace(/\,/g, '|'));
    return typeRegExp.test(fileType);
  }

  protected showWrongFileTypeWarningDialog(): void {
    this.dialogService.openAlert(
      `${this.translate.instant('DIALOG.FILE_UPLOAD.WRONG_FILE_TYPE')} ${this.accept}`
    );
  }
}
