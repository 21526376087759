import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import * as Auth from './auth.action';
import { Action } from '@ngrx/store';
import { ViewSettingService } from '../../services/view-setting.service';
import { ConfigService } from '../../services/config.service';
import { IPrincipal } from '../../interfaces/principal.interface';
import { AccountService } from '../../apis/advis';
import { ILoginActionPayload, ILoginSuccessActionPayload, IResetPasswordCode } from './auth.action';
import { catchError, debounceTime, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(Auth.LOGIN),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: Auth.LoginAction) => action.payload),
      exhaustMap((auth: ILoginActionPayload) => {
        return this.authService.login(auth.authenticate).pipe(
          switchMap(() => {
            return this.authService.afterSuccessLogin(auth.authenticate).pipe(
              map(
                (principal: IPrincipal) =>
                  new Auth.LoginSuccessAction({
                    principal: principal,
                    redirect: auth.redirect,
                  } as ILoginSuccessActionPayload)
              )
            );
          }),
          catchError((error: any) => of(new Auth.LoginFailureAction(error)))
        );
      })
    )
  );

  loginSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_SUCCESS),
        debounceTime(this.config.apiDebounceTimeMs),
        tap((action: Auth.LoginSuccessAction) => {
          this.viewSettingService.setPrincipal(action.payload.principal); // That's a little bit weired
        }),
        tap((action: Auth.LoginSuccessAction) => {
          if (
            !action.payload.redirect ||
            !action.payload.redirect.urlSegments ||
            action.payload.redirect.urlSegments.length === 0
          ) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate([action.payload.redirect.urlSegments.join('//')], {
              queryParams: action.payload.redirect.queryParams,
            });
          }
        })
      ),
    { dispatch: false }
  );

  loginSuccessSession$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_SUCCESS_SESSION),
        debounceTime(this.config.apiDebounceTimeMs),
        tap((action: Auth.LoginSuccessSessionAction) => {
          this.viewSettingService.setPrincipal(action.payload); // That's a little bit weired
        })
      ),
    { dispatch: false }
  );

  loginRedirect$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGIN_REDIRECT),
        debounceTime(this.config.apiDebounceTimeMs),
        map((action: Auth.LoginRedirectAction) => action.payload),
        tap((redirect: string) => {
          if (redirect.length === 0) {
            window.location.href = location.protocol + '//' + location.host + '/auth/login';
          } else {
            window.location.href = redirect;
          }
        })
      ),
    { dispatch: false }
  );

  logout$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Auth.LOGOUT, Auth.LOGOUT_SESSION),
        debounceTime(this.config.apiDebounceTimeMs),
        exhaustMap(() => {
          return this.authService.logout();
        }),
        tap((_: any) => {
          this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  sendResetCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(Auth.SEND_RESET_CODE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: Auth.SendResetCodeAction) => action.payload),
      switchMap((userName: string) => {
        return this.accountApi.accountSendResetPasswordMail(userName).pipe(
          map(() => {
            return new Auth.SendResetCodeSuccessAction();
          }),
          catchError((e: any) => of(new Auth.SendResetCodeFailedAction(e)))
        );
      })
    )
  );

  resetPasswordCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(Auth.RESET_PASSWORD_CODE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: Auth.ResetPasswordCodeAction) => action.payload),
      switchMap((payload: IResetPasswordCode) => {
        return this.accountApi
          .accountResetPasswordFromCode(payload.userName, payload.code, payload.newPassword)
          .pipe(
            map(() => {
              return new Auth.ResetPasswordCodeSuccessAction();
            }),
            catchError((e: any) => of(new Auth.ResetPasswordCodeFailedAction(e)))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private viewSettingService: ViewSettingService,
    private router: Router,
    private config: ConfigService,
    private accountApi: AccountService
  ) {}
}
