/**
 * Created by Aron Heynen on 11.10.2017.
 */
export class Option {
  /**
   * The id to be used for further calls
   */
  id?: number;

  /**
   * A translated name of the master data entity
   */
  de?: string;

  /**
   * A translated name of the master data entity
   */
  it?: string;

  /**
   * A translated name of the master data entity
   */
  fr?: string;

  /**
   * A translated name of the master data entity
   */
  en?: string;

  /**
   * Name of the image file
   */
  fileName?: string;
}
