import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ViewSettingService } from '../../shared/services/view-setting.service';

interface ILanguage {
  value: string;
  viewValue: string;
  isSelected: boolean;
}

export enum LangSelectorTypeEnum {
  LIST = 'list',
  FLAT_ROW = 'flat row',
}

@Component({
  selector: 'pc-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  readonly langSelectorTypeEnum: typeof LangSelectorTypeEnum = LangSelectorTypeEnum;

  public langs: ILanguage[] = [];
  public selectedLang: string;

  @Input() dispatch: boolean = true;
  @Input() langSelectorType: LangSelectorTypeEnum = LangSelectorTypeEnum.LIST;
  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private translate: TranslateService,
    private vsService: ViewSettingService,
    private cdr: ChangeDetectorRef
  ) {
    // empty
  }

  ngOnInit(): void {
    this.langs = [
      { value: 'de', viewValue: this.translate.instant('COMMON.LANG_GERMAN'), isSelected: true },
      { value: 'fr', viewValue: this.translate.instant('COMMON.LANG_FRENCH'), isSelected: false },
      { value: 'it', viewValue: this.translate.instant('COMMON.LANG_ITALIAN'), isSelected: false },
      { value: 'en', viewValue: this.translate.instant('COMMON.LANG_ENGLISH'), isSelected: false },
    ];

    this.selectedLang = this.getViewLanguage(this.vsService.getLastLoginLang());
    this.setDatePickerLocale();
  }

  ngOnDestroy(): void {
    // empty
  }

  onLanguageChange(language: string): void {
    this.selectedLang = this.getViewLanguage(language);
    this.setDatePickerLocale();
    this.languageChanged.emit(language);
  }

  private setDatePickerLocale(): void {
    this.cdr.markForCheck();
  }

  private getViewLanguage(language: string): string {
    for (const key in this.langs) {
      this.langs[key].isSelected = false;
      if (this.langs[key].value === language) {
        this.langs[key].isSelected = true;
        return this.langs[key].viewValue;
      }
    }
    return this.translate.instant('COMMON.LANG_GERMAN');
  }
}
