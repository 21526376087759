import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor() {
    // empty
  }

  ngOnInit(): void {
    // empty
  }
}
