import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DirectoryContactViewModel,
  LeadContactViewModel,
  MasterDataMandatorViewModel,
  SearchAddressBookListViewModel,
} from '../../../shared/apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { SelectContactTypeEnum } from '../../../lead/common/base/BaseContactDirective';
import { AddressBookBackendService } from '../../../shared/services/address-book-backend.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';

export interface IDialogData {
  companyId: number;
  companyName: string;
  selectablePersonsDisabled?: boolean;
  selectablePersons: ISelectablePersonsDialogData[];
  selectedPersonId?: number;
  mandator: MasterDataMandatorViewModel;
  contactType: LeadContactViewModel.AddressTypeEnum;
}

interface ISelectablePersonsDialogData extends SearchAddressBookListViewModel {
  originContactId?: number;
}

interface ISelectablePersons {
  isSelected: boolean;
  id: number;
  personId: number;
  displayText: string;
  originContactId?: number;
}

@Component({
  selector: 'pc-select-person-dialog',
  templateUrl: './select-person-dialog.component.html',
  styleUrls: ['./select-person-dialog.component.scss'],
})
export class SelectPersonDialogComponent implements OnInit, OnDestroy {
  public selectablePersons: ISelectablePersons[] = [];
  public withoutPersonItem: ISelectablePersons;
  public title: string;
  public excludeIds: number[] = [];
  public selectablePersonsDisabled?: boolean;
  public readonly selectType: SelectContactTypeEnum =
    SelectContactTypeEnum.PERSON_EXCLUDING_COMPANYPERSON;
  public readonly mandator: MasterDataMandatorViewModel;
  public readonly contactType: LeadContactViewModel.AddressTypeEnum;
  public readonly companyId: number;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public dialogRef: DialogRef,
    private dialogService: DialogService,
    private addressBookService: AddressBookBackendService,
    private translation: TranslateService,
    @Inject(DIALOG_DATA) private data: IDialogData
  ) {
    this.withoutPersonItem = {
      id: 0,
      personId: 0,
      isSelected: true,
      displayText: this.translation.instant('ADDRESS.DIALOGS.SELECT_PERSON.WITHOUT_PERSON'),
    };
    this.title = this.translation.instant('ADDRESS.DIALOGS.SELECT_PERSON.TITLE', {
      companyName: this.data.companyName,
    });
    this.mandator = this.data.mandator;
    this.contactType = this.data.contactType;
    this.companyId = this.data.companyId;
    this.selectablePersonsDisabled = this.data.selectablePersonsDisabled ?? false;
  }

  public ngOnInit(): void {
    this.initDataSource(this.data.selectablePersons);

    if (
      isNotNullOrUndefined(this.data.selectedPersonId) &&
      this.selectablePersons.findIndex(x => x.id === this.data.selectedPersonId) > -1
    ) {
      this.selectablePersons.find(x => x.id === this.data.selectedPersonId).isSelected = true;
      this.selectablePersons.find(x => x.id === this.withoutPersonItem.id).isSelected = false;
    }
  }

  public initDataSource(selectablePersons: ISelectablePersonsDialogData[]): void {
    this.selectablePersons = cloneDeep([
      this.withoutPersonItem,
      ...selectablePersons
        .map<ISelectablePersons>(p => ({
          isSelected: false,
          id: p.ContactId,
          personId: p.PersonAddressId,
          originContactId: p.originContactId,
          displayText: [
            p.LastName + ' ' + p.FirstName,
            p.PersonStreet,
            p.PersonZipCode,
            p.PersonCity,
          ]
            .filter(x => x && x.length > 0)
            .join(', '),
        }))
        .sort((p1: ISelectablePersons, p2: ISelectablePersons) =>
          p1.displayText.localeCompare(p2.displayText)
        ),
    ]);

    this.excludeIds = this.selectablePersons.filter(x => x.id < 0).map(y => y.originContactId);
  }

  public onChangeSelection(id: number): void {
    this.selectablePersons.forEach(p => (p.isSelected = p.id === id));
  }

  public selectExistPerson(id: number): void {
    this.subscriptions.add(
      this.addressBookService.getContact(id).subscribe(contact => {
        if (this.data.selectablePersonsDisabled) {
          this.dialogRef.close(contact.payload.Id);
        }
        if (contact.successful) {
          const newContact: ISelectablePersonsDialogData = this.mapToSelectablePersons(
            contact.payload
          );
          this.data.selectablePersons.push(newContact);
          this.initDataSource(this.data.selectablePersons);
          this.selectablePersons.forEach(x => (x.isSelected = x.id === newContact.ContactId));
        }
      })
    );
  }

  public onOkClick(): void {
    const selectedContact: ISelectablePersons = this.selectablePersons.find(p => p.isSelected);
    if (selectedContact.id < 0) {
      this.subscriptions.add(
        this.dialogService
          .openConfirm({
            message: 'Wollen sie diese Person wirklilch zu dieser Firma hinzufügen?',
            title: 'Person zu Firma hinzufügen',
          })
          .afterClosed()
          .pipe(filter((proceed: boolean) => proceed))
          .subscribe(() => {
            this.subscriptions.add(
              this.addressBookService
                .addExistPersonToCompany(selectedContact.personId, this.data.companyId)
                .subscribe(contact => {
                  if (contact.successful) {
                    this.dialogRef.close(contact.payload.Id);
                  }
                })
            );
          })
      );
    } else {
      this.dialogRef.close(this.selectablePersons.find(p => p.isSelected).id);
    }
  }

  public onCloseClick(): void {
    this.dialogRef.close(undefined);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private mapToSelectablePersons(model: DirectoryContactViewModel): ISelectablePersonsDialogData {
    const index: number = -1 - this.selectablePersons.filter(x => x.id < 0).length;
    return {
      ContactId: index,
      PersonAddressId: model.Person.Id,
      FirstName: model.Person.FirstName,
      LastName: model.Person.LastName,
      PersonStreet: model.Person.Street,
      PersonZipCode: model.Person.ZipCode,
      PersonCity: model.Person.City,
      originContactId: model.Id,
    };
  }
}
