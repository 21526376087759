import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import * as RootReducer from '../states/index';
import { MasterDataLoadAction } from '../states/global/global.action';
import { ViewSettingLoadAction } from '../states/view-setting/view-setting.action';
import { GetMasterDataAction } from '../states/heating/heating.action';
import { GetEmMasterDataAction } from '../states/electro-mobility/electro-mobility.action';
import { PermissionService } from '../services/permission.service';
import { AccountService, CurrentUserViewModel } from '../apis/advis';
import { LocalStorageKey } from '../services/local-storage-key';
import { GetPvMasterDataAction } from '../states/photovoltaik/photovoltaik.action';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { LoadingModalService } from '../services/loading-modal.service';
import { GetUserInfoAction, GetUserSuccessAction } from '../states/user/user.action';

@Injectable()
export class AppInitializerGuard {
  constructor(
    private store: Store<RootReducer.IState>,
    private loadingModalService: LoadingModalService,
    private permissionService: PermissionService,
    private accountApi: AccountService
  ) {
    // empty
  }

  canActivate(): Observable<boolean> {
    console.info('-- Starting ---');

    this.store.dispatch(new GetUserInfoAction());

    this.loadingModalService.openModal();

    const userInfo$: Observable<boolean> = this.accountApi.accountGetUser().pipe(
      tap(
        (userInfo: CurrentUserViewModel) =>
          (this.permissionService.userPermissions = userInfo.Permissions)
      ),
      tap(userInfo => this.store.dispatch(new GetUserSuccessAction(userInfo))),
      map(() => true),
      catchError(() => of(false))
    );

    this.store.dispatch(new ViewSettingLoadAction());
    const viewSettingsLoaded$: Observable<boolean> = this.store
      .select(RootReducer.getViewSettingLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new MasterDataLoadAction());
    const masterDataLoaded$: Observable<boolean> = this.store
      .select(RootReducer.getGlobalMasterDataLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new GetMasterDataAction());
    const htMasterData$: Observable<boolean> = this.store
      .select(RootReducer.getHeatingMasterDataRunning)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new GetEmMasterDataAction());
    const emMasterData$: Observable<boolean> = this.store
      .select(RootReducer.getEMobilityMasterDataRunning)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new GetPvMasterDataAction());
    const pvMasterData$: Observable<boolean> = this.store
      .select(RootReducer.getPhotovoltaikMasterDataRunning)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    return combineLatest([
      viewSettingsLoaded$,
      masterDataLoaded$,
      htMasterData$,
      emMasterData$,
      pvMasterData$,
      userInfo$,
    ]).pipe(
      map(() => true),
      take(1),
      tap(() => {
        console.info('*** Application initialized ***');
        this.registerStorageEventListener();
        this.loadingModalService.closeModal();
      })
    );
  }

  private registerStorageEventListener(): void {
    window.addEventListener('storage', (storageEvent: StorageEvent) => {
      if (storageEvent.key === LocalStorageKey.MAIN_KEY_AUTH) {
        console.info('LocalStorage property "auth" has changed - reload root page now');
        this.reloadRootPage();
      }
    });
  }

  private reloadRootPage(): void {
    window.location.href = location.protocol + '//' + location.host;
  }
}
