import {
  EmobilityMasterDataViewModel,
  EmobilityProductlineViewModel,
  HeatingMasterDataViewModel,
  HeatingProductlineViewModel,
  MasterDataMandatorViewModel,
  PvProductlineViewModel,
  PvMasterDataViewModel,
} from '../apis/advis';
import { MasterDataViewModel } from '../apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import { TradeTypeEnum } from './trade-type.util';
import { CommonUtil } from './common.util';

export class MasterDataUtil {
  private masterData: MasterDataViewModel;
  private mandant: MasterDataMandatorViewModel;

  constructor(masterData: MasterDataViewModel, selectMandantId: number) {
    this.masterData = cloneDeep(masterData); // FIXME: TODO:184 Too heavy
    this.selectMandant(selectMandantId);
  }

  public static getProductLineName(
    productlineId: number,
    tradeType: TradeTypeEnum,
    pvMasterData: PvMasterDataViewModel,
    heatingMasterData: HeatingMasterDataViewModel,
    emobilityMasterData: EmobilityMasterDataViewModel
  ): string {
    let productLineName: string = undefined;

    if (tradeType === TradeTypeEnum.PV) {
      const productLine: PvProductlineViewModel = pvMasterData.Productlines.find(
        (productLineVm: PvProductlineViewModel) => productLineVm.Id === productlineId
      );
      if (productLine) {
        productLineName = productLine.Name;
      }
    } else if (tradeType === TradeTypeEnum.HT) {
      const productLine: HeatingProductlineViewModel = heatingMasterData.Productlines.find(
        (productLineVm: HeatingProductlineViewModel) => productLineVm.Id === productlineId
      );

      productLineName = productLine ? productLine.Name : undefined;
    } else if (tradeType === TradeTypeEnum.EM) {
      const productLine: EmobilityProductlineViewModel = emobilityMasterData.Productlines.find(
        (productLineVm: EmobilityProductlineViewModel) => {
          return productLineVm.Id === productlineId;
        }
      );

      productLineName = productLine ? productLine.Name : undefined;
    }

    if (productLineName) {
      return productLineName;
    }

    return CommonUtil.getNotAvailable();
  }

  public selectMandant(mandantId: number): MasterDataUtil {
    const mandants: MasterDataMandatorViewModel[] = this.masterData.Mandators;

    this.mandant = mandants.find((mandant: MasterDataMandatorViewModel) => {
      return mandant.Id === mandantId;
    });

    if (!this.mandant) {
      throw new Error('No mandant found for id={mandantId}');
    }

    console.info(`Switched mandant to id=${mandantId} name=${this.mandant.Name}`);

    return this;
  }

  public getMandant(): MasterDataMandatorViewModel {
    return this.mandant;
  }
}
