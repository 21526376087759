import { Component, Inject, OnInit } from '@angular/core';
import { SelectContactTypeEnum } from '../../../lead/common/base/BaseContactDirective';
import { LeadContactViewModel, MasterDataMandatorViewModel } from '../../../shared/apis/advis';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DIALOG_DATA } from '@sitewerk/theia-ui-lib';

export interface IContactSelectorData {
  selectType: SelectContactTypeEnum;
  contactType: LeadContactViewModel.AddressTypeEnum;
  mandator: MasterDataMandatorViewModel;
  isCompany: boolean;
}

@Component({
  selector: 'pc-contact-selector-dialog',
  templateUrl: './contact-selector-dialog.component.html',
  styleUrls: ['./contact-selector-dialog.component.scss'],
})
export class ContactSelectorDialogComponent implements OnInit {
  public mandator: MasterDataMandatorViewModel;
  public selectType: SelectContactTypeEnum;
  public contactType: LeadContactViewModel.AddressTypeEnum;
  public title: string;

  constructor(
    public dialogRef: DialogRef,
    private translate: TranslateService,
    @Inject(DIALOG_DATA) private data: IContactSelectorData
  ) {}

  public ngOnInit(): void {
    this.mandator = this.data.mandator;
    this.selectType = this.data.selectType;
    this.contactType = this.data.contactType;
    this.title = this.data.isCompany
      ? this.translate.instant('ADDRESS.EDIT.SELECT_COMPANY')
      : this.translate.instant('ADDRESS.EDIT.SELECT_PERSON');
  }

  public selectNewContact(id: number): void {
    this.dialogRef.close(id);
  }

  public onCloseClick(): void {
    this.dialogRef.close(undefined);
  }
}
