// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { EnvironmentTypeEnum, IEnvironmentInterface } from './environment.interface';

/* tslint:disable:no-require-imports */
export const environment: IEnvironmentInterface = {
  production: false,
  apiBasePath: 'https://pc-api-dev.offerten-rechner.ch',
  protectDevelopmentPage: false,
  versionInfo: 'Azure-Dev:',
  versionCheckIntervalInMs: 60000,
  version: require('../../package.json').version,
  googleMapsApiKey: 'AIzaSyBpZYbrftI-ohPhVbwAD6eySG8LcXvOlO4',
  googleMapsApiKeyNEW: 'AIzaSyC7mk_EyPLGAumjCrLu32r4P08e-0C1v3U',
  veseApiKey: '102x9gqm80cyff0cf9xdrc888dsf7gu1ywndc6qa',
  instrumentStoreDevtools: true,
  environmentType: EnvironmentTypeEnum.DEV,
  checkVersionUrl: 'assets/version.json',
  screenshotServiceBasePath: 'https://solar-planner-screenshot-dev.offerten-rechner.ch',
  screenshotServiceApiToken: 'c8e5fc16-6676-4643-bbf6-244dc5d5db93',
  appInsightsInstrumentationKey: '509d61cb-57fc-44a3-9bc4-e3341e31a7e6',
  elektroFormAuthIssuer: 'https://int-identity.elektroform.ch',
  elektroFormAuthClientId: 'app_helion',
  elektroFormAuthClientSecret: 'VwjgyUftDG9RN2bgutPuUzteaaQGwWikfbeS0vukvXC8omKN',
  electroFormProjectdef:
    'https://int-solar.elektroform.ch/api/v1/efo/projectdef/823DD4E1-8BD7-41C0-B8FD-28D4C32ABAEA',
  ssoClientId: 'd623926f-426b-4160-ab38-85b63c30d8ae',
  ssoApiApplicationId: 'api://theia-dev',
  trackAndTraceUrl: 'https://tt-dev.offerten-rechner.ch/',
};
