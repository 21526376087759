/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import {
  DocumentTemplatesViewModel,
  FileLinkViewModel,
  LeadDocumentListViewModel,
  LeadDocumentViewModel,
  LeadViewModel,
  PictureListViewModel,
  PictureViewModel,
} from 'app/shared/apis/advis';

export const GET_PICTURES: string = '[AzureFiles] Get pictures';
export const GET_PICTURES_SUCCESS: string = '[AzureFiles] Get pictures success';
export const GET_PICTURES_FAILED: string = '[AzureFiles] Get pictures failed';

export const UPLOAD_PICTURE: string = '[AzureFiles] Upload picture';
export const UPLOAD_PICTURE_SUCCESS: string = '[AzureFiles] Upload picture success';
export const UPLOAD_PICTURE_FAILED: string = '[AzureFiles] Upload picture failed';

export const DELETE_PICTURE: string = '[AzureFiles] Delete picture';
export const DELETE_PICTURE_SUCCESS: string = '[AzureFiles] Delete picture success';
export const DELETE_PICTURE_FAILED: string = '[AzureFiles] Delete picture failed';

export const DOWNLOAD_PICTURE: string = '[AzureFiles] Download picture';
export const DOWNLOAD_PICTURE_SUCCESS: string = '[AzureFiles] Download picture success';
export const DOWNLOAD_PICTURE_FAILED: string = '[AzureFiles] Download picture failed';

export const GET_DOCUMENTS: string = '[AzureFiles] Get documents';
export const GET_DOCUMENTS_SUCCESS: string = '[AzureFiles] Get documents success';
export const GET_DOCUMENTS_FAILED: string = '[AzureFiles] Get documents failed';

export const UPLOAD_DOCUMENT: string = '[AzureFiles] Upload document';
export const UPLOAD_DOCUMENT_SUCCESS: string = '[AzureFiles] Upload document success';
export const UPLOAD_DOCUMENT_FAILED: string = '[AzureFiles] Upload document failed';

export const DELETE_DOCUMENT: string = '[AzureFiles] Delete document';
export const DELETE_DOCUMENT_SUCCESS: string = '[AzureFiles] Delete document success';
export const DELETE_DOCUMENT_FAILED: string = '[AzureFiles] Delete document failed';

export const DOWNLOAD_DOCUMENT: string = '[AzureFiles] Download document';
export const DOWNLOAD_DOCUMENT_SUCCESS: string = '[AzureFiles] Download document success';
export const DOWNLOAD_DOCUMENT_FAILED: string = '[AzureFiles] Download document failed';

export const GET_FILES: string = '[AzureFiles] Get files';
export const GET_FILES_SUCCESS: string = '[AzureFiles] Get files success';
export const GET_FILES_FAILED: string = '[AzureFiles] Get files failed';

export const UPLOAD_FILE: string = '[AzureFiles] Upload file';
export const UPLOAD_FILE_SUCCESS: string = '[AzureFiles] Upload file success';
export const UPLOAD_FILE_FAILED: string = '[AzureFiles] Upload file failed';

export const DELETE_FILE: string = '[AzureFiles] Delete file';
export const DELETE_FILE_SUCCESS: string = '[AzureFiles] Delete file success';
export const DELETE_FILE_FAILED: string = '[AzureFiles] Delete file failed';

export const DOWNLOAD_FILE: string = '[AzureFiles] Download file';
export const DOWNLOAD_FILE_SUCCESS: string = '[AzureFiles] Download file success';
export const DOWNLOAD_FILE_FAILED: string = '[AzureFiles] Download file failed';

export const SYNC_DOCUMENT_AND_PICTURE: string = '[AzureFiles] Sync Download and picture';
export const SYNC_DOCUMENT_AND_PICTURE_SUCCESS: string =
  '[AzureFiles] Sync Download and picture success';
export const SYNC_DOCUMENT_AND_PICTURE_FAILED: string =
  '[AzureFiles] Sync Download and picture failed';

export const TEST_FOLDER_CONNECTION: string = '[AzureFiles] Test folder connection';
export const TEST_FOLDER_CONNECTION_SUCCESS: string = '[AzureFiles] Test folder connection success';
export const TEST_FOLDER_CONNECTION_FAILED: string = '[AzureFiles] Test folder connection failed';

export const USE_FOLDER_CONNECTION: string = '[AzureFiles] Use folder connection';
export const USE_FOLDER_CONNECTION_SUCCESS: string = '[AzureFiles] Use folder connection success';
export const USE_FOLDER_CONNECTION_FAILED: string = '[AzureFiles] Use folder connection failed';

export const PATCH_PICTURE_TAG_SUCCESS: string = '[AzureFiles] Patch picture tag success';

export const GET_LEAD_STENCILS: string = '[AzureFiles] Get lead stencils';
export const GET_LEAD_STENCILS_SUCCESS: string = '[AzureFiles] Get lead stencils success';
export const GET_LEAD_STENCILS_FAILED: string = '[AzureFiles] Get lead stencils failed';

export const CREATE_LEAD_FOLDER: string = '[AzureFiles] Create lead folder';
export const CREATE_LEAD_FOLDER_SUCCESS: string = '[AzureFiles] Create lead folder success';
export const CREATE_LEAD_FOLDER_FAILED: string = '[AzureFiles] Create lead folder failed';

export const LOAD_LEAD_DOCUMENT_TEMPLATES: string = '[AzureFiles] Load lead document templates';
export const LOAD_LEAD_DOCUMENT_TEMPLATES_SUCCESS: string =
  '[AzureFiles] Load lead document templates success';
export const LOAD_LEAD_DOCUMENT_TEMPLATES_FAILED: string =
  '[AzureFiles] Load lead document templates failed';

export class SyncDocumentAndPicturesAction implements Action {
  public readonly type: string = SYNC_DOCUMENT_AND_PICTURE;

  constructor(public payload: { leadId: number }) {}
}

export class SyncDocumentAndPicturesFailedAction implements Action {
  public readonly type: string = SYNC_DOCUMENT_AND_PICTURE_FAILED;

  constructor(public payload: any) {}
}

export class SyncDocumentAndPicturesSuccessAction implements Action {
  public readonly type: string = SYNC_DOCUMENT_AND_PICTURE_SUCCESS;

  constructor(public payload: any) {}
}

export interface ITestFolderConnectionPayload {
  leadId: number;
  folderName: string;
  relativeServerUrl: string;
  uniqueId: string;
}

export class TestFolderConnectionAction implements Action {
  public readonly type: string = TEST_FOLDER_CONNECTION;

  constructor(public payload: ITestFolderConnectionPayload) {}
}

export class TestFolderConnectionFailedAction implements Action {
  public readonly type: string = TEST_FOLDER_CONNECTION_FAILED;

  constructor(public payload: any) {}
}

export class TestFolderConnectionSuccessAction implements Action {
  public readonly type: string = TEST_FOLDER_CONNECTION_SUCCESS;

  constructor(public payload: ITestFolderConnectionPayload) {}
}

export interface IUseFolderConnectionPayload {
  leadId: number;
  serverUrl: string;
  uniqueId: string;
}

export class UseFolderConnectionAction implements Action {
  public readonly type: string = USE_FOLDER_CONNECTION;

  constructor(public payload: IUseFolderConnectionPayload) {}
}

export class UseFolderConnectionFailedAction implements Action {
  public readonly type: string = USE_FOLDER_CONNECTION_FAILED;

  constructor(public payload: any) {}
}

export class UseFolderConnectionSuccessAction implements Action {
  public readonly type: string = USE_FOLDER_CONNECTION_SUCCESS;

  constructor(public payload: IUseFolderConnectionPayload) {}
}

export interface IGetPictures {
  leadId: number;
  useForcePicturesLoad?: boolean;
}

export class GetPicturesAction implements Action {
  public readonly type: string = GET_PICTURES;

  constructor(public payload: IGetPictures) {}
}

export class GetPicturesFailedAction implements Action {
  public readonly type: string = GET_PICTURES_FAILED;

  constructor(public payload: any) {}
}

export interface IGetPicturesSuccess {
  pictures: PictureListViewModel;
  leadId: number;
}

export class GetPicturesSuccessAction implements Action {
  public readonly type: string = GET_PICTURES_SUCCESS;

  constructor(public payload: IGetPicturesSuccess) {}
}

export interface IUploadPictureActionPayload {
  leadId: number;
  folder?: string;
  tags: string[];
  files: Blob[];
}

export class UploadPictureAction implements Action {
  public readonly type: string = UPLOAD_PICTURE;

  constructor(public payload: IUploadPictureActionPayload) {}
}

export class UploadPictureFailedAction implements Action {
  public readonly type: string = UPLOAD_PICTURE_FAILED;

  constructor(public payload: any) {}
}

export class UploadPictureSuccessAction implements Action {
  public readonly type: string = UPLOAD_PICTURE_SUCCESS;

  constructor(public payload: any) {}
}

export interface IDeletePictureActionPayload {
  leadId: number;
  pictureName: string;
  folder: string;
}

export class DeletePictureAction implements Action {
  public readonly type: string = DELETE_PICTURE;

  constructor(public payload: IDeletePictureActionPayload) {}
}

export class DeletePictureFailedAction implements Action {
  public readonly type: string = DELETE_PICTURE_FAILED;

  constructor(public payload: any) {}
}

export class DeletePictureSuccessAction implements Action {
  public readonly type: string = DELETE_PICTURE_SUCCESS;

  constructor(public payload: { fileName: string; folder: string }) {}
}

export class DownloadPictureAction implements Action {
  public readonly type: string = DOWNLOAD_PICTURE;

  constructor(public payload: PictureViewModel) {}
}

export class DownloadPictureSuccessAction implements Action {
  public readonly type: string = DOWNLOAD_PICTURE_SUCCESS;

  constructor(public payload: any) {}
}

export class DownloadPictureFailedAction implements Action {
  public readonly type: string = DOWNLOAD_PICTURE_FAILED;

  constructor(public payload: any) {}
}

export class PatchPictureTagSuccessAction implements Action {
  public readonly type: string = PATCH_PICTURE_TAG_SUCCESS;

  constructor(public payload: PictureViewModel) {}
}

// Documents

export interface IGetDocuments {
  leadId: number;
  useForceDocumentsLoad?: boolean;
}

export class GetDocumentsAction implements Action {
  public readonly type: string = GET_DOCUMENTS;

  constructor(public payload: IGetDocuments) {}
}

export class GetDocumentsFailedAction implements Action {
  public readonly type: string = GET_DOCUMENTS_FAILED;

  constructor(public payload: any) {}
}

export interface IGetDocumentsSuccess {
  documents: LeadDocumentListViewModel;
  leadId: number;
}

export class GetDocumentsSuccessAction implements Action {
  public readonly type: string = GET_DOCUMENTS_SUCCESS;

  constructor(public payload: IGetDocumentsSuccess) {}
}

export interface IUploadDocumentActionPayload {
  leadId: number;
  fileFolder: string;
  tags: string[];
  files: Blob[];
}

export class UploadDocumentAction implements Action {
  public readonly type: string = UPLOAD_DOCUMENT;

  constructor(public payload: IUploadDocumentActionPayload) {}
}

export class UploadDocumentFailedAction implements Action {
  public readonly type: string = UPLOAD_DOCUMENT_FAILED;

  constructor(public payload: any) {}
}

export class UploadDocumentSuccessAction implements Action {
  public readonly type: string = UPLOAD_DOCUMENT_SUCCESS;

  constructor(public payload: any) {}
}

export interface IDeleteDocumentActionPayload {
  leadId: number;
  fileFolder: string;
  documentName: string;
}

export class DeleteDocumentAction implements Action {
  public readonly type: string = DELETE_DOCUMENT;

  constructor(public payload: IDeleteDocumentActionPayload) {}
}

export class DeleteDocumentFailedAction implements Action {
  public readonly type: string = DELETE_DOCUMENT_FAILED;

  constructor(public payload: any) {}
}

export class DeleteDocumentSuccessAction implements Action {
  public readonly type: string = DELETE_DOCUMENT_SUCCESS;

  constructor(public payload: { fileName: string; fileFolder: string }) {}
}

export class DownloadDocumentAction implements Action {
  public readonly type: string = DOWNLOAD_DOCUMENT;

  constructor(public payload: LeadDocumentViewModel) {}
}

export class DownloadDocumentSuccessAction implements Action {
  public readonly type: string = DOWNLOAD_DOCUMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class DownloadDocumentFailedAction implements Action {
  public readonly type: string = DOWNLOAD_DOCUMENT_FAILED;

  constructor(public payload: any) {}
}

// Files

export class GetFilesAction implements Action {
  public readonly type: string = GET_FILES;

  constructor(public payload?: { leadId: number }) {}
}

export class GetFilesFailedAction implements Action {
  public readonly type: string = GET_FILES_FAILED;

  constructor(public payload: any) {}
}

export class GetFilesSuccessAction implements Action {
  public readonly type: string = GET_FILES_SUCCESS;

  constructor(public payload: FileLinkViewModel[]) {}
}

export interface IUploadFileActionPayload {
  leadId: number;
  files: Blob[];
}

export class UploadFileAction implements Action {
  public readonly type: string = UPLOAD_FILE;

  constructor(public payload: IUploadFileActionPayload) {}
}

export class UploadFileFailedAction implements Action {
  public readonly type: string = UPLOAD_FILE_FAILED;

  constructor(public payload: any) {}
}

export class UploadFileSuccessAction implements Action {
  public readonly type: string = UPLOAD_FILE_SUCCESS;

  constructor(public payload: any) {}
}

export interface IDeleteFileActionPayload {
  leadId: number;
  documentName: string;
}

export class DeleteFileAction implements Action {
  public readonly type: string = DELETE_FILE;

  constructor(public payload: IDeleteFileActionPayload) {}
}

export class DeleteFileFailedAction implements Action {
  public readonly type: string = DELETE_FILE_FAILED;

  constructor(public payload: any) {}
}

export class DeleteFileSuccessAction implements Action {
  public readonly type: string = DELETE_FILE_SUCCESS;

  constructor(public payload: { fileName: string }) {}
}

export class DownloadFileAction implements Action {
  public readonly type: string = DOWNLOAD_FILE;

  constructor(public payload: FileLinkViewModel) {}
}

export class DownloadFileSuccessAction implements Action {
  public readonly type: string = DOWNLOAD_FILE_SUCCESS;

  constructor(public payload: any) {}
}

export class DownloadFileFailedAction implements Action {
  public readonly type: string = DOWNLOAD_FILE_FAILED;

  constructor(public payload: any) {}
}

export class GetLeadStencilsAction implements Action {
  public readonly type: string = GET_LEAD_STENCILS;

  constructor(public payload: number) {}
}

export class GetLeadStencilsSuccessAction implements Action {
  public readonly type: string = GET_LEAD_STENCILS_SUCCESS;

  constructor(public payload: string[]) {}
}

export class GetLeadStencilsFailedAction implements Action {
  public readonly type: string = GET_LEAD_STENCILS_FAILED;

  constructor(public payload: any) {}
}

export class CreateLeadFolderAction implements Action {
  public readonly type: string = CREATE_LEAD_FOLDER;

  constructor(public payload: { leadId: number; folderName: string }) {}
}

export class CreateLeadFolderSuccessAction implements Action {
  public readonly type: string = CREATE_LEAD_FOLDER_SUCCESS;

  constructor(public payload: LeadDocumentListViewModel) {}
}

export class CreateLeadFolderFailedAction implements Action {
  public readonly type: string = CREATE_LEAD_FOLDER_FAILED;

  constructor(public payload: any) {}
}

export class LoadLeadDocumentTemplatesAction implements Action {
  public readonly type: string = LOAD_LEAD_DOCUMENT_TEMPLATES;

  constructor(public payload: { leadId: number; language: LeadViewModel.LanguageEnum }) {}
}

export class LoadLeadDocumentTemplatesSuccessAction implements Action {
  public readonly type: string = LOAD_LEAD_DOCUMENT_TEMPLATES_SUCCESS;

  constructor(public payload: DocumentTemplatesViewModel) {}
}

export class LoadLeadDocumentTemplatesFailedAction implements Action {
  public readonly type: string = LOAD_LEAD_DOCUMENT_TEMPLATES_FAILED;

  constructor(public payload: any) {}
}

export type Actions =
  | GetPicturesAction
  | GetPicturesFailedAction
  | GetPicturesSuccessAction
  | UploadPictureAction
  | UploadPictureFailedAction
  | UploadPictureSuccessAction
  | DeletePictureAction
  | DeletePictureFailedAction
  | DeletePictureSuccessAction
  | DownloadPictureAction
  | DownloadPictureSuccessAction
  | DownloadPictureFailedAction
  | GetDocumentsAction
  | GetDocumentsFailedAction
  | GetDocumentsSuccessAction
  | UploadDocumentAction
  | UploadDocumentFailedAction
  | UploadDocumentSuccessAction
  | DeleteDocumentAction
  | DeleteDocumentFailedAction
  | DeleteDocumentSuccessAction
  | DownloadDocumentAction
  | DownloadDocumentSuccessAction
  | DownloadDocumentFailedAction
  | GetFilesAction
  | GetFilesFailedAction
  | GetFilesSuccessAction
  | UploadFileAction
  | UploadFileFailedAction
  | UploadFileSuccessAction
  | DeleteFileAction
  | DeleteFileFailedAction
  | DeleteFileSuccessAction
  | DownloadFileAction
  | DownloadFileSuccessAction
  | DownloadFileFailedAction
  | SyncDocumentAndPicturesAction
  | SyncDocumentAndPicturesSuccessAction
  | SyncDocumentAndPicturesFailedAction
  | GetLeadStencilsAction
  | GetLeadStencilsSuccessAction
  | GetLeadStencilsFailedAction
  | CreateLeadFolderAction
  | CreateLeadFolderSuccessAction
  | CreateLeadFolderFailedAction
  | LoadLeadDocumentTemplatesAction
  | LoadLeadDocumentTemplatesSuccessAction
  | LoadLeadDocumentTemplatesFailedAction;
