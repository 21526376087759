<div>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div fxFlex="100" class="contact-info-main">
      <p *ngIf="displayContactInfo.contactLine1">{{ displayContactInfo.contactLine1 }}</p>
      <p *ngIf="displayContactInfo.contactLine2">{{ displayContactInfo.contactLine2 }}</p>
      <p *ngIf="displayContactInfo.email.length > 0">{{ displayContactInfo.email }}</p>
      <p *ngIf="displayContactInfo.phone.length > 0">{{ displayContactInfo.phone }}</p>
      <p *ngIf="displayContactInfo.language.length > 0">{{ displayContactInfo.language }}</p>
    </div>
  </div>
</div>
