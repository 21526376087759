import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export enum NotificationCountPositionY {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
}

export enum NotificationCountPositionX {
  Before = 'before',
  After = 'after',
  Center = 'center',
  BeforeWithText = 'before with text',
  AfterWithText = 'after with text',
}

export enum NotificationCountColor {
  Primary = 'primary',
  Warn = 'warn',
  Accent = 'accent',
  Gray = 'gray',
}

@Component({
  selector: 'pc-notification-count',
  templateUrl: './notification-count.component.html',
  styleUrls: ['./notification-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCountComponent implements OnInit {
  @Input()
  color: string = NotificationCountColor.Primary;

  @Input()
  positionX: string = NotificationCountPositionX.After;

  @Input()
  positionY: string = NotificationCountPositionY.Top;

  @Input()
  notifications: number = 0;

  @Input()
  noCount: boolean;

  classes: string[];
  typeIsNumber: boolean;

  /*
  Limit for the notification count.
  When the notification count exceeds this limit, it will be displayed as limit+. Defaults to 99.
   */
  @Input()
  limit: number = 99;

  constructor() {}

  ngOnInit(): void {
    this.classes = ['pc-notification-count'];
    if (this.noCount === true) {
      this.classes.push('pc-notification-no-count');
    }

    switch (this.positionX) {
      case NotificationCountPositionX.Before:
        this.classes.push('pc-notification-before');
        break;
      case NotificationCountPositionX.Center:
        this.classes.push('pc-notification-center-x');
        break;
      case NotificationCountPositionX.BeforeWithText:
        this.classes.push('pc-notification-before-with-text');
        break;
      case NotificationCountPositionX.AfterWithText:
        this.classes.push('pc-notification-after-with-text');
        break;
      default:
        this.classes.push('pc-notification-after');
    }

    switch (this.positionY) {
      case NotificationCountPositionY.Bottom:
        this.classes.push('pc-notification-bottom');
        break;
      case NotificationCountPositionY.Center:
        this.classes.push('pc-notification-center-y');
        break;
      default:
        this.classes.push('pc-notification-top');
    }

    switch (this.color) {
      case NotificationCountColor.Primary:
        this.classes.push('primary');
        break;
      case NotificationCountColor.Accent:
        this.classes.push('accent');
        break;
      case NotificationCountColor.Gray:
        this.classes.push('pc-notification-gray');
        break;
      default:
        this.classes.push('warn-color');
    }

    this.typeIsNumber = typeof this.notifications === 'number';
  }
}
