import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { LeadService } from 'app/shared/apis/advis';
import { Observable } from 'rxjs';
import { ILeadTagDialogData } from './lead-tag-dialog.data';
import { DIALOG_DATA, DialogRef } from '@sitewerk/theia-ui-lib';
import { FormControl, FormGroup } from '@angular/forms';

export const SYSTEM_TAG_MARKER = ':';

@Component({
  selector: 'pc-lead-tag-dialog',
  templateUrl: './lead-tag-dialog.component.html',
  styleUrls: ['./lead-tag-dialog.component.scss'],
})
export class LeadTagDialogComponent {
  @ViewChild('input') public input: ElementRef;

  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  protected existingTags$: Observable<string[]>;
  protected title: string;
  protected questionTagMarker: string = SYSTEM_TAG_MARKER;
  protected tags: string[] = [];
  protected isOkButtonDisable: boolean = true;
  protected form = new FormGroup({
    tags: new FormControl([]),
  });

  constructor(
    public dialogRef: DialogRef,
    private translate: TranslateService,
    @Inject(DIALOG_DATA) public data: ILeadTagDialogData,
    private leadService: LeadService
  ) {
    const tags =
      !isNullOrUndefined(data.leadTag) && data.leadTag.length > 0 ? data.leadTag.split(',') : [];
    this.existingTags$ = this.leadService.leadGetExistingTagsList();
    this.form.controls['tags'].setValue(tags);
    if (!isNullOrUndefined(data.title)) {
      this.title = this.translate.instant(data.title);
    } else {
      this.title = this.translate.instant('LEAD.TAG.TITLE');
    }
    this.form.valueChanges.subscribe(() => (this.isOkButtonDisable = false));
  }

  public onOk(): void {
    this.close(this.form.controls.tags.value.toString());
  }

  public onCancel(): void {
    this.dialogRef.close(undefined);
  }

  protected tagFormatter: (tag: string) => string = tag => tag;

  private close(leadTag: string): void {
    this.dialogRef.close(leadTag.trim());
  }
}
