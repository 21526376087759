<div class="select">
  <helion-ui-select
    placeholder="{{ 'VARIANT_OFFER_DOWNLOAD.CHOOSE_TEMPLATE' | translate }}"
    [(ngModel)]="selectedTemplateId"
    [disabled]="preselection">
    <helion-ui-option
      *ngFor="let template of documentTemplates"
      [value]="template.Id"
      [label]="template.Name"></helion-ui-option>
  </helion-ui-select>
</div>
