import { AfterViewInit, Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { DebugClass, DebugInfo } from '../../lead/common/lead-main/lead-main.component';
import { EnvironmentService } from '../../shared/services/environment.service';
import { UserInfo } from '../../shared/apis/advis';
import * as RootReducer from '../../shared/states';
import { Store } from '@ngrx/store';
import { ViewSettingService } from '../../shared/services/view-setting.service';
import { GetUserInfoAction } from '../../shared/states/user/user.action';
import { MainSidenavService } from '../../shared/services/main-sidenav.service';
import { MatToolbar } from '@angular/material/toolbar';
import { MainDrawerContentComponent } from 'app/common-components/main-drawer-content/main-drawer-content.component';
export const MEIER_TOBLER_MANDANT_ID: number = 27;

@Component({
  selector: 'pc-mt-overview',
  templateUrl: './mt-overview.component.html',
  styleUrls: ['./mt-overview.component.scss'],
})
export class MtOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  public drawerContentComponent: Type<MainDrawerContentComponent> = MainDrawerContentComponent;
  protected readonly MainDrawerContentComponent = MainDrawerContentComponent;

  private subscriptions: Subscription = new Subscription();

  @ViewChild(MatToolbar) toolbar: MatToolbar;

  debugInfo: DebugInfo;
  email: string = undefined;

  constructor(
    public environment: EnvironmentService,
    private store: Store<RootReducer.IState>,
    private vsService: ViewSettingService,
    private mainSidenavService: MainSidenavService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetUserInfoAction());
    this.createDebugInfo();

    const loggedInState$: Observable<boolean> = this.store.select(RootReducer.getAuthLoggedIn);
    const userInfo$: Observable<UserInfo> = this.store.select(RootReducer.getUserInfo);
    this.subscriptions.add(
      combineLatest([loggedInState$, userInfo$]).subscribe(
        ([isLoggedIn, userInfo]: [boolean, UserInfo]) => {
          if (!isLoggedIn) {
            return;
          }

          if (!userInfo) {
            return;
          }

          this.email = userInfo.User;

          if (!userInfo.Roles) {
            return;
          }
        }
      )
    );
  }

  ngAfterViewInit(): void {
    // this.mainSidenavService.setSidenav(this.toolbar);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createDebugInfo(): void {
    switch (this.environment.getEnvironmentType()) {
      case this.environment.getLocal():
        this.debugInfo = new DebugInfo(
          this.environment.getLocal().toString(),
          DebugClass.LOCAL,
          true
        );
        break;
      case this.environment.getDev():
        this.debugInfo = new DebugInfo(
          this.environment.getDev().toString(),
          DebugClass.DEVELOPMENT,
          true
        );
        break;
      case this.environment.getTest():
        this.debugInfo = new DebugInfo(
          this.environment.getTest().toString(),
          DebugClass.TESTING,
          true
        );
        break;
      case this.environment.getProd():
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
      default:
        this.debugInfo = new DebugInfo('', DebugClass.PRODUCTION, false);
        break;
    }
  }

  openMainSideNav(): void {
    this.mainSidenavService.toggle();
  }

  onLanguageChanged(language: string): void {
    if (
      this.vsService.getLastLoginLang().toLowerCase().localeCompare(language.toLowerCase()) !== 0
    ) {
      this.vsService.setAccountLanguageAndReload(language);
    }
  }
}
