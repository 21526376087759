<div fxLayout="column" fxLayoutGap="25px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h4>{{ titleKey | translate }}</h4>
    <div fxFlex="30" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center">
      <button
        *ngIf="pictureUrl"
        mat-icon-button
        attr.aria-label="{{ 'COMMON.BTN_CANCEL' | translate }}"
        matTooltip="{{ 'COMMON.BTN_CANCEL' | translate }}"
        (click)="onPictureDelete()">
        <helion-ui-icon style="display: block" data-feather="x-circle"></helion-ui-icon>
      </button>
      <pc-custom-file-upload
        accept=".png,.jpeg,.jpg,.gif"
        class="logo-upload-button"
        iconName="upload-cloud"
        (selectFile)="onPictureSelect($event)">
      </pc-custom-file-upload>
    </div>
  </div>
  <img
    [src]="sanitizer.bypassSecurityTrustUrl(pictureUrl)"
    *ngIf="pictureUrl"
    mat-card-image
    class="user-picture-image" />
  <span *ngIf="!pictureUrl">{{ 'USER.EDIT.NO_LOGO' | translate }}</span>
</div>
