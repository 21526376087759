import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable()
export class UrlService implements OnDestroy {
  private subscription: Subscription = new Subscription();

  private url: string;

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  public currentUrl$: Observable<string> = this.currentUrl.asObservable();

  constructor(private router: Router) {
    this.subscription.add(
      this.router.events
        .pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.setPreviousUrl(this.url);
          this.setCurrentUrl(event.url);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setCurrentUrl(currentUrl: string): void {
    this.currentUrl.next(currentUrl);
    this.url = currentUrl;
  }

  private setPreviousUrl(previousUrl: string): void {
    this.previousUrl.next(previousUrl);
  }
}
