/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RebateViewModel { 
    Rebate?: number;
    Id?: number;
    Title?: string;
    Order?: number;
    RebateType?: RebateViewModel.RebateTypeEnum;
    RebateCalculated?: number;
}
export namespace RebateViewModel {
    export type RebateTypeEnum = 'Percent' | 'Netto' | 'Brutto';
    export const RebateTypeEnum = {
        Percent: 'Percent' as RebateTypeEnum,
        Netto: 'Netto' as RebateTypeEnum,
        Brutto: 'Brutto' as RebateTypeEnum
    };
}
