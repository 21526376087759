import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as User from './user.action';
import {
  GetUserFailedAction,
  GetUserInfoFailedAction,
  IUpdateUser,
  PatchUserFailedAction,
  PutUserPasswordFailedAction,
} from './user.action';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import {
  AccountService,
  CurrentUserViewModel,
  FileLinkViewModel,
  LeadNoteService,
  ResetPasswordRequest,
  SaveNoteTemplateRequest,
  UserInfo,
} from '../../apis/advis';
import { ErrorAddAction } from '../global/global.action';
import { ErrorTypeE } from '../global/global.reducer';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Injectable()
export class UserEffects {
  getUserInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.GET_USER_INFO),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: User.GetUserInfoAction) => action.payload),
      switchMap(() => {
        return this.accountApi.accountGetUserInfo().pipe(
          map((res: UserInfo) => {
            return new User.GetUserInfoSuccessAction(res);
          }),
          catchError((error: any) => of(new User.GetUserInfoFailedAction(error)))
        );
      })
    )
  );

  getUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.GET_USER),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() => {
        return this.accountApi.accountGetUser().pipe(
          map((res: CurrentUserViewModel) => {
            return new User.GetUserSuccessAction(res);
          }),
          catchError((error: any) => of(new User.GetUserFailedAction(error)))
        );
      })
    )
  );

  getUserFiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.GET_USER_SUCCESS),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() => {
        return this.accountApi.accountGetUserFiles().pipe(
          map((res: FileLinkViewModel[]) => {
            return new User.GetUserFilesSuccessAction(res);
          }),
          catchError((error: any) => of(new User.GetUserFilesFailedAction(error)))
        );
      })
    )
  );

  patchUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.PATCH_USER),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: User.PatchUserAction) => action.payload),
      switchMap((userUpdateData: IUpdateUser) => {
        return this.accountApi
          .accountPutUser(userUpdateData.userData)
          .pipe(
            switchMap((userViewModel: CurrentUserViewModel) => {
              if (userUpdateData.picture.deleted) {
                return this.accountApi.accountDeleteUserFile('Picture');
              }
              if (isNullOrUndefined(userUpdateData.picture.file)) {
                return of(userViewModel);
              }
              return this.accountApi.accountPostUserFile('Picture', userUpdateData.picture.file);
            })
          )
          .pipe(
            switchMap((resultSignature: CurrentUserViewModel) => {
              if (userUpdateData.signature.deleted) {
                return this.accountApi.accountDeleteUserFile('Signature');
              }
              if (isNullOrUndefined(userUpdateData.signature.file)) {
                return of(resultSignature);
              }
              return this.accountApi.accountPostUserFile(
                'Signature',
                userUpdateData.signature.file
              );
            })
          )
          .pipe(map((result: CurrentUserViewModel) => new User.PatchUserSuccessAction(result)));
      }),
      catchError((error: any) => of(new User.PatchUserFailedAction(error)))
    )
  );

  putUserPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.PUT_USER_PASSWORD),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: User.PutUserPasswordAction) => action.payload),
      switchMap((passwordRequest: ResetPasswordRequest) => {
        return this.accountApi.accountResetPassword(passwordRequest).pipe(
          map(() => {
            return new User.PutUserPasswordSuccessAction();
          }),
          catchError((error: any) => of(new User.PutUserPasswordFailedAction(error)))
        );
      })
    )
  );

  saveIndividualNoteTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.SAVE_NOTE_TEMPLATES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: User.SaveNoteTemplateAction) => action.payload),
      switchMap((request: SaveNoteTemplateRequest) => {
        return this.leadNoteApi.leadNoteSaveNoteTemplate(request).pipe(
          map(response => {
            return new User.SaveNoteTemplateSuccessAction(response);
          }),
          catchError((error: any) => of(new User.SaveNoteTemplateFailedAction(error)))
        );
      })
    )
  );

  getIndividualNoteTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.GET_NOTE_TEMPLATES),
      debounceTime(this.config.apiDebounceTimeMs),
      switchMap(() => {
        return this.leadNoteApi.leadNoteGetIndividualNoteTemplates().pipe(
          map(response => {
            return new User.GetNoteTemplatesSuccessAction(response);
          }),
          catchError((error: any) => of(new User.GetNoteTemplatesFailedAction(error)))
        );
      })
    )
  );

  // Failure handling

  loadFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.GET_USER_INFO_FAILED, User.GET_USER_FAILED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: GetUserFailedAction | GetUserInfoFailedAction) => {
        return new ErrorAddAction({ type: ErrorTypeE.LOAD, data: action.payload });
      })
    )
  );

  saveFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(User.PATCH_USER_FAILED, User.PUT_USER_PASSWORD_FAILED, User.GET_USER_FILES_FAILED),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: PatchUserFailedAction | PutUserPasswordFailedAction) => {
        return new ErrorAddAction({ type: ErrorTypeE.SAVE, data: action.payload });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private config: ConfigService,
    private accountApi: AccountService,
    private leadNoteApi: LeadNoteService
  ) {}
}
