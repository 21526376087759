/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NoteTemplateViewModel { 
    Title?: string;
    FileName?: string;
    Content?: string;
    Type?: NoteTemplateViewModel.TypeEnum;
}
export namespace NoteTemplateViewModel {
    export type TypeEnum = 'Shared' | 'Individual';
    export const TypeEnum = {
        Shared: 'Shared' as TypeEnum,
        Individual: 'Individual' as TypeEnum
    };
}
