<div fxLayout="column" fxLayoutGap="20px" style="margin-top: 16px">
  <form>
    <div fxLayout="row wrap" fxLayoutGap="20px">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 10px)" class="contact-form__block">
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="with-font-bold">{{
              'DIALOG.REGIONAL_INFORMATION.FREE_FOR_SITE_VISIT_LABEL' | translate
            }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span
              *ngIf="
                tradeTypeRegionalResponsibilities?.FeeForSiteVisit === undefined ||
                tradeTypeRegionalResponsibilities?.FeeForSiteVisit === 0
              ">
              {{ 'DIALOG.REGIONAL_INFORMATION.FREE_FOR_SITE_VISIT' | translate }}
            </span>
            <span
              *ngIf="
                tradeTypeRegionalResponsibilities?.FeeForSiteVisit !== undefined &&
                tradeTypeRegionalResponsibilities?.FeeForSiteVisit > 0
              ">
              {{ tradeTypeRegionalResponsibilities?.FeeForSiteVisit }}
            </span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="with-font-bold" style="margin-top: 25px !important"
              >{{ 'DIALOG.REGIONAL_INFORMATION.SALE' | translate }} ({{
                tradeTypeRegionalResponsibilities?.SalesOrganizationUnit?.Name
              }})</span
            >
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.SALE' | translate }} 1:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.PrimarySalesDisplayName }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.SALE' | translate }} 2:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.SecondarySalesDisplayName }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.SALE' | translate }} 3:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.TertiarySalesDisplayName }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.KAM' | translate }}:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.KeyAccountManagerSalesDisplayName }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span style="margin-top: 25px !important" class="with-font-bold">{{
              'DIALOG.REGIONAL_INFORMATION.EXECUTING_ORGANIZATION_UNIT_LABEL' | translate
            }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.EXECUTING_ORGANIZATION_UNIT' | translate }}:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.ExecutingOrganizationUnit?.Name }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label-min-width"
              >{{ 'DIALOG.REGIONAL_INFORMATION.PERIMETER' | translate }}:</span
            >
            <span>{{ tradeTypeRegionalResponsibilities?.Perimeter }}</span>
          </div>
        </div>
      </div>

      <div
        fxFlex.lt-md="100"
        fxFlex.gt-sm="0 0 calc(50% - 10px)"
        class="contact-form__block"
        fxLayoutAlign="{{ isMobile ? 'start center' : 'end center' }}">
        <div fxLayout="column" fxLayoutGap="16px">
          <div
            *ngIf="isMobile"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="contact-form__field"
            style="min-height: 16px"></div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
              <label>
                <textarea
                  class="textarea"
                  placeholder="{{ 'DIALOG.REGIONAL_INFORMATION.SALES_NOTES' | translate }}"
                  [disabled]="true"
                  name="notes"
                  [value]="
                    tradeTypeRegionalResponsibilities?.SalesNotes === undefined
                      ? ''
                      : tradeTypeRegionalResponsibilities?.SalesNotes
                  ">
                </textarea>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
