/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LeadContactViewModel } from '../../../../shared/apis/advis';
import * as RootReducer from '../../../../shared/states';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GetNoteSuccessAction } from '../../../../shared/states/lead-notes/lead-notes.action';
import AddressTypeEnum = LeadContactViewModel.AddressTypeEnum;
import { IExternManagedAddressTypeConfig } from '../../../../shared/utils/exter-managed-address-type.util';
import { LeadPermissionsService } from '../../../../shared/services/lead-permissions.service';
@Component({
  selector: 'pc-menu-save-to-external-managed-contact [copyToExternalManaged]',
  templateUrl: './menu-save-to-external-managed-contact.component.html',
  styleUrls: ['./menu-save-to-external-managed-contact.component.scss'],
})
export class MenuSaveToExternalManagedContactComponent implements OnInit, OnDestroy {
  @Input() public copyToExternalManaged: boolean;

  @Input() public addressType: AddressTypeEnum;

  @Output() public onCopyAsExternalManaged: EventEmitter<AddressTypeEnum> =
    new EventEmitter<AddressTypeEnum>();

  @Output() public onChangeRequest: EventEmitter<IExternManagedAddressTypeConfig> =
    new EventEmitter<IExternManagedAddressTypeConfig>();

  public externalManagedAddressConfig: IExternManagedAddressTypeConfig[];
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<RootReducer.IState>,
    private permissionsService: LeadPermissionsService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(RootReducer.getAllowedCanSeeExternalManagedAddressConfigState)
        .subscribe(x => {
          this.externalManagedAddressConfig = x.filter(
            y =>
              this.permissionsService.getCanSeeExternManagedAddressPermission(y.addressType) ===
              true
          );
          if (!this.copyToExternalManaged) {
            this.externalManagedAddressConfig = this.externalManagedAddressConfig.filter(
              y => y.addressType === this.addressType
            );
          }
        })
    );
  }

  public copyAsExternalManaged(addressTypeString: string): void {
    this.onCopyAsExternalManaged.emit(AddressTypeEnum[addressTypeString]);
  }

  public changeRequest(addressTypeConfig: IExternManagedAddressTypeConfig): void {
    this.onChangeRequest.emit(addressTypeConfig);
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new GetNoteSuccessAction(undefined));
  }
}
