import * as HeatingAction from './heating.action';
import { GET_MASTER_DATA, GET_MASTER_DATA_FAILED, GET_MASTER_DATA_SUCCESS } from './heating.action';
import { tassign } from 'tassign';
import { HeatingMasterDataViewModel } from '../../apis/advis';

export interface IState {
  getMasterDataRunning: boolean;
  masterData: HeatingMasterDataViewModel;
}

export const initialState: IState = {
  getMasterDataRunning: undefined,
  masterData: undefined,
};

export function reducer(state: IState = initialState, action: HeatingAction.Actions): IState {
  switch (action.type) {
    case GET_MASTER_DATA: {
      return tassign(state, {
        getMasterDataRunning: true,
      });
    }

    case GET_MASTER_DATA_SUCCESS: {
      return tassign(state, {
        getMasterDataRunning: false,
        masterData: action.payload,
      });
    }

    case GET_MASTER_DATA_FAILED: {
      const err: any = action.payload;
      console.error('ERROR is: ', err);

      return tassign(state, {
        getMasterDataRunning: false,
        masterData: action.payload,
      });
    }

    default: {
      return state;
    }
  }
}

export const getHeatingMasterDataState: (state: IState) => HeatingMasterDataViewModel = (
  state: IState
) => state.masterData;
export const getMasterDataRunningState: (state: IState) => boolean = (state: IState) =>
  state.getMasterDataRunning;
