export const DEFAULT_ROLE_ICON: string = 'person';

export class IconUtils {
  public static getRoleIcon(iconName: string): string {
    switch (iconName) {
      case 'construction':
        return 'tool';
      case 'shopping_cart':
        return 'shopping-cart';
      case 'call':
        return 'phone';
      case 'people':
        return 'users';
      case 'energy':
        return 'zap';
      default:
        return DEFAULT_ROLE_ICON;
    }
  }
}
