<section class="dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="dialog-title">
      {{
        (company.Id && company.Id > 0 ? 'ADDRESS.EDIT.EDIT_COMPANY' : 'ADDRESS.EDIT.ADD_COMPANY')
          | translate
      }}
    </h2>
    <span>
      <button [attr.fill-type]="'icon'" [cdkMenuTriggerFor]="companyAddressMenu">
        <helion-ui-icon data-feather="more-vertical"></helion-ui-icon>
      </button>
      <ng-template #companyAddressMenu>
        <helion-ui-menu>
          <button class="menu-item" cdkMenuItem (click)="addEmail(company)">
            <helion-ui-icon data-feather="mail"></helion-ui-icon>
            <span>{{ 'ADDRESS.ADD_EMAIL' | translate }}</span>
          </button>
          <button class="menu-item" cdkMenuItem (click)="addPhone(company)">
            <helion-ui-icon data-feather="phone"></helion-ui-icon>
            <span>{{ 'ADDRESS.ADD_PHONE' | translate }}</span>
          </button>
        </helion-ui-menu>
      </ng-template>
    </span>
  </div>

  <div class="dialog-content">
    <form class="company-contact-form">
      <pc-company-contact-form
        #companyContactForm
        [addressType]="addressType"
        [company]="company"
        [emailTypes]="emailTypes"
        [logoUrl]="logoUrl"
        [phoneTypes]="phoneTypes"></pc-company-contact-form>
    </form>
  </div>

  <div class="dialog-actions">
    <button (click)="onCloseClick()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button [attr.fill-type]="'contained'" [disabled]="saveDisabled()" (click)="save(isNew)">
      {{
        (company.Id && company.Id > 0
          ? 'ADDRESS.UPDATE.COMPANY'
          : 'ADDRESS.CREATE_AND_SAVE.COMPANY'
        ) | translate
      }}
    </button>
  </div>
</section>
