/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProjectChecklistPhaseViewModel } from './projectChecklistPhaseViewModel';


export interface ProjectChecklistViewModel { 
    Id?: number;
    Title?: string;
    TemplateDate?: Date;
    IsCompleted?: boolean;
    ActivePhase?: ProjectChecklistViewModel.ActivePhaseEnum;
    Phases?: Array<ProjectChecklistPhaseViewModel>;
    SopListId?: number;
}
export namespace ProjectChecklistViewModel {
    export type ActivePhaseEnum = 'Definition' | 'Planning' | 'Sourcing' | 'Preparation' | 'Installation' | 'Closure';
    export const ActivePhaseEnum = {
        Definition: 'Definition' as ActivePhaseEnum,
        Planning: 'Planning' as ActivePhaseEnum,
        Sourcing: 'Sourcing' as ActivePhaseEnum,
        Preparation: 'Preparation' as ActivePhaseEnum,
        Installation: 'Installation' as ActivePhaseEnum,
        Closure: 'Closure' as ActivePhaseEnum
    };
}
