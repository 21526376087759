import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import * as RootReducer from '../../states/index';
import { MasterDataLoadAction } from '../../states/global/global.action';
import { ViewSettingLoadAction } from '../../states/view-setting/view-setting.action';
import { GetMasterDataAction } from '../../states/heating/heating.action';
import { PermissionService } from '../../services/permission.service';
import { AccountService, CurrentUserViewModel } from '../../apis/advis';
import { LocalStorageKey } from '../../services/local-storage-key';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { LoadingModalService } from '../../services/loading-modal.service';
import { GetUserSuccessAction } from 'app/shared/states/user/user.action';

@Injectable()
export class AppMtInitializerGuard {
  constructor(
    private store: Store<RootReducer.IState>,
    private loadingModalService: LoadingModalService,
    private permissionService: PermissionService,
    private accountApi: AccountService
  ) {
    // empty
  }

  canActivate(): Observable<boolean> {
    console.info('-- Starting mt crm ---');

    this.loadingModalService.openModal();

    const userInfo$: Observable<boolean> = this.accountApi.accountGetUser().pipe(
      tap(
        (userInfo: CurrentUserViewModel) =>
          (this.permissionService.userPermissions = userInfo.Permissions)
      ),
      tap(userInfo => this.store.dispatch(new GetUserSuccessAction(userInfo))),
      map((userInfo: CurrentUserViewModel) => userInfo.Permissions.CanCreateMtCrmLead),
      catchError(() => of(false))
    );

    this.store.dispatch(new ViewSettingLoadAction());
    const viewSettingsLoaded$: Observable<boolean> = this.store
      .select(RootReducer.getViewSettingLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new MasterDataLoadAction());
    const masterDataLoaded$: Observable<boolean> = this.store
      .select(RootReducer.getGlobalMasterDataLoaded)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    this.store.dispatch(new GetMasterDataAction());
    const htMasterData$: Observable<boolean> = this.store
      .select(RootReducer.getHeatingMasterDataRunning)
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1)
      );

    return combineLatest([viewSettingsLoaded$, masterDataLoaded$, htMasterData$, userInfo$]).pipe(
      map((a: [boolean, boolean, boolean, boolean]) => a[3]),
      take(1),
      tap((a: boolean) => {
        if (a) {
          console.info('*** Application initialized ***');
          this.registerStorageEventListener();
          this.loadingModalService.closeModal();
        } else {
          console.info('*** user has no permissions, reload login ***');
          this.loadingModalService.closeModal();
          window.location.href =
            location.protocol +
            '//' +
            location.host +
            '//auth/login' +
            window.location.pathname +
            window.location.search;
        }
      })
    );
  }

  private registerStorageEventListener(): void {
    window.addEventListener('storage', (storageEvent: StorageEvent) => {
      if (storageEvent.key === LocalStorageKey.MAIN_KEY_AUTH) {
        console.info('LocalStorage property "auth" has changed - reload root page now');
        this.reloadRootPage();
      }
    });
  }

  private reloadRootPage(): void {
    window.location.href =
      location.protocol + '//' + location.host + window.location.pathname + window.location.search;
  }
}
