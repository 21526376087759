<helion-ui-drawer
  #notesSideNav
  position="right"
  [noDrawerIcon]="true"
  [drawerContentComponent]="notesDrawerContentComponent"
  drawerClass="lead-main-drawer">
</helion-ui-drawer>

<helion-ui-drawer
  #sopChecklistSideNav
  position="right"
  [noDrawerIcon]="true"
  [drawerContentComponent]="sopChecklistDrawerContentComponent"
  drawerClass="lead-main-drawer">
</helion-ui-drawer>

<helion-ui-drawer class="lead-action-bar" [noDrawerIcon]="true">
  <div role="header" class="action-bar no-print" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="title">{{ title }}</div>
    <div
      *ngIf="!title && lead !== undefined"
      fxLayout="row"
      fxLayoutAlign=" center"
      class="hide-mobile action-bar-info-pane">
      <helion-ui-tag-trade-type
        fxHide.lt-sm
        [tradeTypeCode]="tradeTypeCode"
        data-cy="leadTradeTypeIndicator"></helion-ui-tag-trade-type>
      <div fxHide.lt-sm class="lead-address-compund cursor-pointer" (click)="onAddressClick()">
        <span *ngIf="!isMobile" [tooltip]="lead | leadAddressCompoundForHeader : false">{{
          lead | leadAddressCompoundForHeader : true
        }}</span>
        <helion-ui-icon *ngIf="isMobile" data-feather="home"></helion-ui-icon>
      </div>

      <pc-mandant-indicator fxHide.lt-sm [MandantId]="lead.Lead.MandantId"></pc-mandant-indicator>

      <span>
        <!-- same contact -->
        <pc-lead-siblings-notification
          fxHide.lt-sm
          *ngIf="
            leadSiblings?.SameMandatorSiblingLeads?.length > 0 ||
            leadSiblings?.OtherMandatorSiblingLeads?.length > 0
          "
          [sameMandatorSibling]="leadSiblings?.SameMandatorSiblingLeads"
          [otherMandatorSiblings]="leadSiblings?.OtherMandatorSiblingLeads"
          [masterData]="masterData"
          [showMandantName]="false"
          [notificationType]="leadSiblingsNotificationIconEnum.SAME_CONTACT"
          [userMap]="userMap$ | async"
          [leadId]="lead.Lead.Id"
          intTooltip="{{ 'LEAD_DETAIL.SIBLINGS.TOOLTIP.SAME_CONTACT' | translate }}">
        </pc-lead-siblings-notification>

        <!-- same company -->
        <pc-lead-siblings-notification
          fxHide.lt-sm
          *ngIf="
            leadSiblings?.SameMandatorSameCompanyLeads?.length > 0 ||
            leadSiblings?.OtherMandatorSameCompanyLeads?.length > 0
          "
          [sameMandatorSibling]="leadSiblings?.SameMandatorSameCompanyLeads"
          [otherMandatorSiblings]="leadSiblings?.OtherMandatorSameCompanyLeads"
          [masterData]="masterData"
          [showMandantName]="false"
          [notificationType]="leadSiblingsNotificationIconEnum.SAME_COMPANY"
          [userMap]="userMap$ | async"
          [leadId]="lead.Lead.Id"
          intTooltip="{{ 'LEAD_DETAIL.SIBLINGS.TOOLTIP.SAME_COMPANY' | translate }}">
        </pc-lead-siblings-notification>

        <!-- same object -->
        <pc-lead-siblings-notification
          fxHide.lt-sm
          *ngIf="
            leadSiblings?.SameMandatorSameObjectLeads?.length > 0 ||
            leadSiblings?.OtherMandatorSameObjectLeads?.length > 0
          "
          [sameMandatorSibling]="leadSiblings?.SameMandatorSameObjectLeads"
          [otherMandatorSiblings]="leadSiblings?.OtherMandatorSameObjectLeads"
          [masterData]="masterData"
          [showMandantName]="false"
          [notificationType]="leadSiblingsNotificationIconEnum.SAME_OBJECT"
          [userMap]="userMap$ | async"
          [leadId]="lead.Lead.Id"
          intTooltip="{{ 'LEAD_DETAIL.SIBLINGS.TOOLTIP.SAME_OBJECT' | translate }}">
        </pc-lead-siblings-notification>

        <!-- same group -->
        <pc-lead-siblings-notification
          fxHide.lt-sm
          *ngIf="leadSiblings?.SameGroupLeads?.length > 0"
          [sameMandatorSibling]="leadSiblings?.SameGroupLeads"
          [masterData]="masterData"
          [showMandantName]="false"
          [notificationType]="leadSiblingsNotificationIconEnum.SAME_GROUP"
          [userMap]="userMap$ | async"
          [leadId]="lead.Lead.Id"
          intTooltip="{{ 'LEAD_DETAIL.SIBLINGS.TOOLTIP.SAME_GROUP' | translate }}">
        </pc-lead-siblings-notification>
      </span>

      <helion-ui-tag-header
        class="lead-origin"
        *ngIf="lead.Lead.Origin"
        [tagHeaderText]="lead.Lead.Origin"
        tooltip="{{ 'LEAD_PERSON.ORIGIN' | translate }}"></helion-ui-tag-header>

      <helion-ui-tag-header
        *ngIf="lead.Lead.ComplexityType || permissions.userPermissions.CanSetComplexityType"
        class="lead-complexity-type"
        [tagHeaderText]="
          lead.Lead.ComplexityType ?? 'LEAD_PERSON.COMPLEXITY_TYPE_PLACEHOLDER' | translate
        "
        tooltip="{{ 'LEAD_PERSON.COMPLEXITY_TYPE' | translate }}"
        (click)="onEditComplexityTypeClicked()"></helion-ui-tag-header>
    </div>
    <span style="flex: 1"></span>

    <button
      #notesButton
      *ngIf="!hideNotes"
      [attr.fill-type]="'icon'"
      class="action-primary button-header-round-mobile"
      [tooltip]="'LEAD_DETAIL.NOTES' | translate"
      (click)="onNotesSideNavToggle()"
      data-cy="notesButton">
      <helion-ui-icon data-feather="edit-3"></helion-ui-icon>
    </button>

    <button
      #sopChecklistButton
      *ngIf="!sopChecklistHidden && lead?.Lead?.Project?.SopListId"
      [attr.fill-type]="'icon'"
      class="action-primary button-header-round-mobile"
      [tooltip]="'LEAD_DETAIL.PROJECT_STATUS.PROJECT_STATUS' | translate"
      (click)="onSopChecklistSideNavToggle()"
      data-cy="sopChecklistButton">
      <img
        class="offer-button button-header-round-image-mobile"
        style="width: 16px"
        src="../../../assets/icons/list-check-light.svg"
        alt="list-check-light" />
    </button>

    <ng-content></ng-content>
  </div>
</helion-ui-drawer>
