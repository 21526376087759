import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customStepIndexPreview',
})
export class CustomStepIndexPreviewPipe implements PipeTransform {
  transform(value: string, bottom?: boolean): unknown {
    return bottom
      ? value
          .split('.')
          .reduce((res, i, index, arr) => `${res}.${index === arr.length - 1 ? +i + 1 : i}`)
      : value;
  }
}
