<helion-ui-select
  selectLook="white"
  class="mobile-mandator-selector"
  (onDropdownClose)="onClose()"
  [(ngModel)]="selectedItemIds"
  data-cy="mandatSelector">
  <div class="mandator-selector-options">
    <helion-ui-checkbox
      (click)="$event.stopImmediatePropagation()"
      data-cy="mandatSelectAllOption"
      [isChecked]="selectItems.length === selectedItemIds.length"
      (onCheckboxChange)="selectAllChange($event)">
      {{ 'COMMON.SELECT_ALL' | translate }}
    </helion-ui-checkbox>
  </div>
  <helion-ui-checkbox-option
    *ngFor="let item of selectItems"
    [displayLabel]="item.text"
    [label]="item.text"
    [value]="item.mandantId"
    [isChecked]="selectedItemIds.indexOf(item.mandantId) > -1"
    data-cy="mandatSelection"></helion-ui-checkbox-option>
  <div class="mandator-selector-wrapper">
    <helion-ui-divider></helion-ui-divider>
    <div class="mandator-selector-reset-all">
      <button (click)="onResetClick()">{{ 'COMMON.RESET' | translate }}</button>
    </div>
  </div>
</helion-ui-select>
