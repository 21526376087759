import { Injectable } from '@angular/core';
import {
  ICantonLink,
  IGemeinde,
  IGemeindeEvu,
  IGemeindeEvuRequest,
  IGemeindeEW,
} from 'app/shared/interfaces/gemeinde.interface';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { flatMap, map } from 'rxjs/operators';

const mapEvu: (data: any) => IGemeindeEvu = (data: any) => ({
  nrElcom: data.nrElcom,
  name: data.Name,
  idofs: data.idofs,
  plz: data.PLZ,
  gemeinde: data.Gemeinde,
});

const mapEvuRequest: (data: any) => IGemeindeEvuRequest = (data: any) => ({
  valid: data.valid,
  evus: data.evus ? data.evus.map(mapEvu) : [],
});

@Injectable()
export class GemeindeService {
  constructor(private http: HttpClient) {}

  getCantonLinks(): Observable<ICantonLink[]> {
    return this.http.get('/assets/data/HT_Links.json') as Observable<ICantonLink[]>;
  }

  getGemeinden(): Observable<IGemeinde[]> {
    return this.http.get('/assets/data/Gemeindestand.json') as Observable<IGemeinde[]>;
  }

  findGemeinde(gemeinde: string): Observable<IGemeinde> {
    return this.getGemeinden().pipe(
      map((gemeinden: IGemeinde[]) =>
        gemeinden.find(
          (g: IGemeinde) => (g.longName || '').toLowerCase() === (gemeinde || '').toLowerCase()
        )
      )
    );
  }

  findGemeindeEW(gemeindeName: string): Observable<IGemeindeEW[]> {
    return this.findGemeinde(gemeindeName).pipe(
      flatMap((gemeinde: IGemeinde) => {
        if (!gemeinde) {
          throw new Error('Could not find gemeinde with name ' + gemeindeName);
        }
        const params: HttpParams = new HttpParams()
          .set('idofs', gemeinde.Id)
          .set('licenseKey', environment.veseApiKey);
        return this.http
          .get('https://opendata.vese.ch/pvtarif/api/getData/muni', { params })
          .pipe(map(mapEvuRequest));
      }),
      flatMap((evuRequest: IGemeindeEvuRequest) => {
        const requests: Observable<IGemeindeEW>[] = evuRequest.evus
          .map((evu: IGemeindeEvu) => evu.nrElcom)
          .map((nrElcom: string) => {
            console.log('EVU NR ELCOM', nrElcom);
            const params: HttpParams = new HttpParams()
              .set('evuId', nrElcom)
              .set('year', '17')
              .set('licenseKey', environment.veseApiKey);
            return this.http.get('https://opendata.vese.ch/pvtarif/api/getData/evu', {
              params,
            }) as Observable<IGemeindeEW>;
          });
        return forkJoin(...requests);
      })
    );
  }

  getCantonForMunicipality(municipalityName: string): Observable<string> {
    return this.getGemeinden().pipe(
      map((municipalities: IGemeinde[]) => {
        let gemeinde: IGemeinde = municipalities.filter(
          (municipality: IGemeinde) =>
            municipality.longName.toLowerCase() === municipalityName.toLowerCase()
        )[0];
        if (!gemeinde) {
          throw new Error('Could not find gemeinde with name ' + municipalityName);
        }
        return gemeinde.canton;
      })
    );
  }

  getLinkForCanton(canton: string): Observable<string> {
    return this.getCantonLinks().pipe(
      map((cantons: ICantonLink[]) => {
        return cantons.filter((x: ICantonLink) => x.canton === canton)[0].url;
      })
    );
  }
}
