/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NoteViewModel { 
    Id?: number;
    NoteType?: NoteViewModel.NoteTypeEnum;
    Text?: string;
    LeadId?: number;
    Created?: Date;
    CreatedUser?: string;
    Tags?: Array<string>;
    DrawingNoteImageUrl?: string;
    DrawingNoteImageData?: string;
    TargetGroupRoleIds?: Array<string>;
}
export namespace NoteViewModel {
    export type NoteTypeEnum = 'Text' | 'Drawing' | 'PmInfo';
    export const NoteTypeEnum = {
        Text: 'Text' as NoteTypeEnum,
        Drawing: 'Drawing' as NoteTypeEnum,
        PmInfo: 'PmInfo' as NoteTypeEnum
    };
}
