import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectManagerRankingDialogComponent } from 'app/lead/dialog/project-manager-ranking-dialog/project-manager-ranking-dialog.component';
import {
  AccountService,
  CurrentUserViewModel,
  RoleViewModel,
  UserInfo,
} from 'app/shared/apis/advis';
import { PermissionService } from 'app/shared/services/permission.service';
import { Subscription } from 'rxjs';
import * as RootReducer from '../../../../shared/states/index';
import * as AuthAction from '../../../../shared/states/auth/auth.action';
import { AuthService } from 'app/shared/services/auth.service';
import { Authenticate, Principal } from 'app/shared/models/principal.model';
import { LocalStorageKey } from 'app/shared/services/local-storage-key';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { IconUtils } from '../../../../shared/utils/icon.utils';

interface ISelectableAccount {
  userName: string;
  icon: string;
}

@Component({
  selector: 'pc-account-switcher',
  templateUrl: './account-switcher.component.html',
  styleUrls: ['./account-switcher.component.scss'],
})
export class AccountSwitcherComponent implements OnInit {
  public availableAccounts: ISelectableAccount[] = [];
  public email: string;
  public isScoreClickable: boolean = false;
  public roleIcon: string = undefined;
  public scoreIcon: string = undefined;
  public isScoreIconAsset: boolean = false;
  public showTelesalesIcon: boolean = false;
  private jwtEnabled: boolean;
  private language: UserInfo.LanguageEnum;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<RootReducer.IState>,
    private accountService: AccountService,
    private dialogService: DialogService,
    private permissions: PermissionService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select<CurrentUserViewModel>(RootReducer.getCurrentUser).subscribe(currentUser => {
        this.availableAccounts = currentUser.AvailableLogins?.map(account => ({
          icon: this.getRoleIcon(account.Roles),
          userName: account.UserName,
        }));
      })
    );

    this.subscriptions.add(
      this.store
        .select(RootReducer.getGlobalMasterData)
        .subscribe(masterdata => (this.jwtEnabled = masterdata.EnableJwtTokenAuth))
    );

    this.subscriptions.add(
      this.store.select(RootReducer.getUserInfo).subscribe(userInfo => {
        if (userInfo) {
          this.email = userInfo.User;
          this.language = userInfo.Language;
          this.isScoreClickable = this.permissions.userPermissions.CanSeeProjectScore;
          this.scoreIcon = this.getScoreIcon(userInfo.ProjectScore);
          this.roleIcon = this.getRoleIcon(userInfo.Roles);
          this.showTelesalesIcon =
            userInfo.Roles.some(x => x.IconName === 'shopping-cart') &&
            this.roleIcon !== 'shopping-cart';
        }
      })
    );
  }

  public openProjectRankingDialog(): void {
    this.dialogService.open(ProjectManagerRankingDialogComponent);
  }

  public switchAccount(userName: string): void {
    if (this.jwtEnabled) {
      this.accountService.accountSwitchUserAccountJwt(userName).subscribe(tokenData => {
        localStorage.setItem(LocalStorageKey.MAIN_KEY_AUTH_JWT, JSON.stringify(tokenData));
        this.authService
          .afterSuccessLogin({
            language: this.language,
          } as Authenticate)
          .subscribe(
            () => window.location.reload(),
            () => this.store.dispatch(new AuthAction.LoginRedirectAction())
          );
      });
    } else {
      this.accountService.accountSwitchUserAccount(userName).subscribe(() => {
        this.authService.getPrincipal().subscribe(
          (principal: Principal) => {
            localStorage.setItem(LocalStorageKey.MAIN_KEY_AUTH, JSON.stringify(principal));
            window.location.reload();
          },
          () => {
            this.store.dispatch(new AuthAction.LoginRedirectAction());
          }
        );
      });
    }
  }

  private getScoreIcon(projectScore: number): string {
    if (projectScore >= 30000) {
      this.isScoreIconAsset = true;
      return 'assets:top_ranking';
    } else if (projectScore >= 20000) {
      return 'star';
    } else if (projectScore >= 10000) {
      return 'award';
    } else if (projectScore >= 1000) {
      return 'zap';
    }

    return undefined;
  }

  private getRoleIcon(roles: RoleViewModel[]): string {
    const roleWithIcon = roles.find(r => !!r.IconName);
    return IconUtils.getRoleIcon(roleWithIcon?.IconName);
  }
}
