/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CurrentUserViewModel } from '../model/currentUserViewModel';
import { FileLinkViewModel } from '../model/fileLinkViewModel';
import { LoginViewModel } from '../model/loginViewModel';
import { PutUserRequest } from '../model/putUserRequest';
import { ResetPasswordRequest } from '../model/resetPasswordRequest';
import { UserInfo } from '../model/userInfo';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccountService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Remove a user file
     * 
     * @param fileType the type of the file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountDeleteUserFile(fileType: 'Signature' | 'Picture', observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountDeleteUserFile(fileType: 'Signature' | 'Picture', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountDeleteUserFile(fileType: 'Signature' | 'Picture', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountDeleteUserFile(fileType: 'Signature' | 'Picture', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling accountDeleteUserFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CurrentUserViewModel>(`${this.basePath}/api/pc/account/users/current/files/${encodeURIComponent(String(fileType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the currently logged in user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountGetUser(observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountGetUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountGetUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountGetUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<CurrentUserViewModel>(`${this.basePath}/api/pc/account/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the user files
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountGetUserFiles(observe?: 'body', reportProgress?: boolean): Observable<Array<FileLinkViewModel>>;
    public accountGetUserFiles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileLinkViewModel>>>;
    public accountGetUserFiles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileLinkViewModel>>>;
    public accountGetUserFiles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FileLinkViewModel>>(`${this.basePath}/api/pc/account/users/current/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns some user infos
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountGetUserInfo(observe?: 'body', reportProgress?: boolean): Observable<UserInfo>;
    public accountGetUserInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfo>>;
    public accountGetUserInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfo>>;
    public accountGetUserInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserInfo>(`${this.basePath}/api/pc/account/user-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns possibility to authenticate via JWT token
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountIsJwtAuthEnabled(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public accountIsJwtAuthEnabled(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public accountIsJwtAuthEnabled(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public accountIsJwtAuthEnabled(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/pc/account/jwt-auth-enabled`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Performs an user login
     * 
     * @param body username, password and options
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountLogin(body?: LoginViewModel, observe?: 'body', reportProgress?: boolean): Observable<UserInfo>;
    public accountLogin(body?: LoginViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInfo>>;
    public accountLogin(body?: LoginViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInfo>>;
    public accountLogin(body?: LoginViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserInfo>(`${this.basePath}/api/pc/account/login`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revokes the auth token that has been issued during the login
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountLogout(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public accountLogout(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public accountLogout(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public accountLogout(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/account/logout`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload new user file
     * 
     * @param fileType the type of the file
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountPostUserFile(fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountPostUserFile(fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountPostUserFile(fileType: 'Signature' | 'Picture', file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountPostUserFile(fileType: 'Signature' | 'Picture', file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling accountPostUserFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<CurrentUserViewModel>(`${this.basePath}/api/pc/account/users/current/files/${encodeURIComponent(String(fileType))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the language of the session (by using a cookie)
     * 
     * @param language the language to apply (valid values: &#39;de&#39;,&#39;fr&#39;,&#39;it&#39;)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountPutLanguage(language?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public accountPutLanguage(language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public accountPutLanguage(language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public accountPutLanguage(language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/pc/account/language`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the currently logged in user
     * 
     * @param body the user data to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountPutUser(body?: PutUserRequest, observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountPutUser(body?: PutUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountPutUser(body?: PutUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountPutUser(body?: PutUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CurrentUserViewModel>(`${this.basePath}/api/pc/account/user`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resets the password of the logged in user to the given value  &lt;param name&#x3D;\&quot;resetPasswordRequest\&quot;&gt;the current and new password of the user&lt;/param&gt;
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountResetPassword(body?: ResetPasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public accountResetPassword(body?: ResetPasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public accountResetPassword(body?: ResetPasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public accountResetPassword(body?: ResetPasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/pc/account/password/reset`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resets the password of the user to the given value  /// &lt;param name&#x3D;\&quot;userName\&quot;&gt;the name of the user to reset the password for&lt;/param&gt;  /// &lt;param name&#x3D;\&quot;code\&quot;&gt;the code the user received to reset the password&lt;/param&gt;  /// &lt;param name&#x3D;\&quot;newPassword\&quot;&gt;the new password for the user&lt;/param&gt;
     * 
     * @param userName 
     * @param code 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountResetPasswordFromCode(userName?: string, code?: string, body?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public accountResetPasswordFromCode(userName?: string, code?: string, body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public accountResetPasswordFromCode(userName?: string, code?: string, body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public accountResetPasswordFromCode(userName?: string, code?: string, body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/pc/account/password/reset/code`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends an email with a reset password link  if the user with the given username exists.  Be aware that it will fail silently if the user does not exist.  &lt;param name&#x3D;\&quot;userName\&quot;&gt;the username of the user to reset the password for&lt;/param&gt;
     * 
     * @param userName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountSendResetPasswordMail(userName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public accountSendResetPasswordMail(userName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public accountSendResetPasswordMail(userName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public accountSendResetPasswordMail(userName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/pc/account/password/reset/code`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs out and logs in user with new account connected via CloudId
     * 
     * @param newAccountUserName the new user name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountSwitchUserAccount(newAccountUserName?: string, observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountSwitchUserAccount(newAccountUserName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountSwitchUserAccount(newAccountUserName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountSwitchUserAccount(newAccountUserName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newAccountUserName !== undefined && newAccountUserName !== null) {
            queryParameters = queryParameters.set('newAccountUserName', <any>newAccountUserName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<CurrentUserViewModel>(`${this.basePath}/api/pc/account/switch-account`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs out and logs in user with new account connected via CloudId
     * 
     * @param newAccountUserName the new user name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountSwitchUserAccountJwt(newAccountUserName?: string, observe?: 'body', reportProgress?: boolean): Observable<CurrentUserViewModel>;
    public accountSwitchUserAccountJwt(newAccountUserName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserViewModel>>;
    public accountSwitchUserAccountJwt(newAccountUserName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserViewModel>>;
    public accountSwitchUserAccountJwt(newAccountUserName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newAccountUserName !== undefined && newAccountUserName !== null) {
            queryParameters = queryParameters.set('newAccountUserName', <any>newAccountUserName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.post<CurrentUserViewModel>(`${this.basePath}/api/pc/account/switch-account-jwt`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
