import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as EMobilityAction from './electro-mobility.action';
import { EmobilityMasterDataViewModel, MasterDataService } from '../../apis/advis';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class EMobilityEffets {
  getMasterData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EMobilityAction.GET_MASTER_DATA),
      switchMap(() => {
        return this.masterData.pCApiMasterdataGetEmobilityMasterData().pipe(
          map((res: EmobilityMasterDataViewModel) => {
            return new EMobilityAction.GetEmMasterDataSuccessAction(res);
          }),
          catchError((error: any) => of(new EMobilityAction.GetEmMasterDataFailedAction(error)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private masterData: MasterDataService) {}
}
