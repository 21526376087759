/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type ExternalSystem = 'BlueOffice' | 'EsErp' | 'Sap';

export const ExternalSystem = {
    BlueOffice: 'BlueOffice' as ExternalSystem,
    EsErp: 'EsErp' as ExternalSystem,
    Sap: 'Sap' as ExternalSystem
};
