<div
  class="checklist-container sop-checklist"
  [class.side]="!isFullScreen"
  [class.full-screen]="isFullScreen">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    *ngIf="!isFullScreen; else stepTemplateChecklist">
    <span>{{ 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_STATUS' | translate }}</span>
    <div
      *ngIf="lead"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      style="background-color: #ffffff; padding: 8px; border-radius: 8px">
      <helion-ui-tag-trade-type
        fxHide.lt-sm
        [tradeTypeCode]="lead.TradeTypeCode"
        data-cy="leadTradeTypeIndicator"></helion-ui-tag-trade-type>
      <div fxHide.lt-sm class="lead-address-compund">
        <span [tooltip]="lead | leadAddressCompoundForHeader : false">{{
          lead | leadAddressCompoundForHeader : true
        }}</span>
      </div>
    </div>
    <div class="pull-right" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <a
        class="d-flex no-underline"
        [tooltip]="'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.OPEN_CUSTOMER_LINK' | translate"
        [href]="environment.trackAndTraceUrl + '?leadId=' + (lead?.['PublicLeadId'] ?? lead?.['PublicId']) + '&lang=' + ((lead['LeadLanguage'] || lead['Language']) | lowercase)"
        target="_blank">
        <img
          class="button-header-round-image-mobile"
          style="width: 26px"
          src="../../../../assets/icons/track.svg" />
      </a>
      <helion-ui-icon
        role="button"
        data-feather="maximize"
        (click)="goToFullChecklist()"></helion-ui-icon>
      <helion-ui-icon
        role="button"
        data-feather="x"
        (click)="overlayRef.dispose()"></helion-ui-icon>
    </div>
  </div>
  <div class="d-flex" *ngIf="!isFullScreen">
    <span class="font-small pull-right"
      >{{
        'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.EMAIL_NOTIFICATION_ENABLED' | translate
      }}&nbsp;{{
      (lead?.['ChecklistStepCompletedNotificationEnabled']
          ? 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.EMAIL_NOTIFICATION_ON'
          : 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.EMAIL_NOTIFICATION_OFF'
      ) | translate
      }}</span
    >
  </div>
  <helion-ui-accordion multi class="width-100">
    <helion-ui-accordion-item
      [expanded]="togglePhaseIndex === phase.SopPhase.Key"
      *ngFor="let phase of projectCheckList.SopChecklistPhases; trackBy: trackByPhaseKey"
      class="phase-item">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        accordionItemHeader>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <span [ngSwitch]="phase.CheckedState" (click)="clickPhase(phase, $event)">
            <helion-ui-progress-box
              *ngSwitchCase="checkedStates.Checked"
              [boxStyle]="progressBoxStyle.DONE"></helion-ui-progress-box>
            <helion-ui-progress-box
              [ngClass]="phase.IsTimeWarning ? 'text-warning' : 'text-orange'"
              *ngSwitchCase="checkedStates.PartiallyChecked"
              [boxStyle]="progressBoxStyle.STARTED"></helion-ui-progress-box>
            <helion-ui-progress-box
              *ngSwitchCase="checkedStates.Unchecked"
              [boxStyle]="progressBoxStyle.NOT_STARTED"></helion-ui-progress-box>
          </span>
          <div>
            {{ phase.Title }}
          </div>
          <span *ngIf="phase.IsTimeWarning">
            <helion-ui-icon data-feather="clock" class="icon-danger"></helion-ui-icon>
          </span>
        </div>

        <div fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="end center" fxLayoutGap="8px">
          <div fxFlex="10rem" fxHide.lt-sm *ngIf="isFullScreen">
            {{ 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.START_DATE' | translate }}:
            {{ (phase.StartDate | date : 'dd.MM.yy') || '-' }}
          </div>
          <div fxFlex="10rem" fxHide.lt-sm *ngIf="isFullScreen">
            {{ 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.LAST_UPDATE_DATE' | translate }}:
            {{ (phase.LastUpdateDate | date : 'dd.MM.yy') || '-' }}
          </div>
          <div fxFlex="2rem" fxLayoutAlign="end center" *ngIf="isFullScreen">
            <helion-ui-icon *ngIf="phase.Notes" data-feather="message-square"></helion-ui-icon>
          </div>
          <div fxFlex="2rem" fxLayoutAlign="end center">
            <a
              *ngIf="phase.Link || phase"
              class="help-link"
              (click)="$event.stopPropagation()"
              [href]="phase.Link"
              data-rel="external"
              target="_blank">
              <helion-ui-icon data-feather="folder"></helion-ui-icon>
            </a>
          </div>
          <helion-ui-icon
            *ngIf="!isFullScreen"
            data-feather="chevron-down"
            class="pull-right"></helion-ui-icon>
        </div>
      </div>
      <div accordionItemBody fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="64px">
        <helion-ui-accordion
          multi
          class="width-100 parent-step-item"
          fxFlex.xs="100"
          [fxFlex.gt-xs]="isFullScreen ? '50' : '100'">
          <helion-ui-accordion-item
            class="margin-left-20 sop-step-item"
            *ngFor="let sopStep of phase.SopSteps; trackBy: trackBySopChecklistStepId">
            <div accordionItemHeader class="position-relative">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <span [ngSwitch]="sopStep.CheckedState" (click)="clickSopStep(sopStep, $event)">
                    <helion-ui-progress-box
                      *ngSwitchCase="checkedStates.Checked"
                      [boxStyle]="progressBoxStyle.DONE"></helion-ui-progress-box>
                    <helion-ui-progress-box
                      class="text-orange"
                      *ngSwitchCase="checkedStates.PartiallyChecked"
                      [boxStyle]="progressBoxStyle.STARTED"></helion-ui-progress-box>
                    <helion-ui-progress-box
                      *ngSwitchCase="checkedStates.Unchecked"
                      [boxStyle]="progressBoxStyle.NOT_STARTED"></helion-ui-progress-box>
                  </span>
                  <div>
                    {{ sopStep.Title }}
                  </div>
                  <img
                    class="button-header-round-image-mobile text-orange sop-step-tack-and-trace-icon"
                    [class.visible]="sopStep.IsVisibleToCustomer"
                    style="width: 20px"
                    src="../../../../assets/icons/track-yellow.svg" />
                </div>
                <helion-ui-icon
                  [size]="20"
                  data-feather="chevron-down"
                  class="pull-right"></helion-ui-icon>
              </div>

              <ng-container *ngIf="!sopStep.ChecklistSteps?.length">
                <ng-container
                  *ngTemplateOutlet="
                    customStepTemplate;
                    context: { index: sopStep.Index, sopStepId: sopStep.Id }
                  "></ng-container>
              </ng-container>
            </div>
            <div accordionItemBody>
              <div
                class="sub-step"
                *ngFor="
                  let step of sopStep.ChecklistSteps;
                  trackBy: trackByProjectChecklistStepId;
                  let last = last
                ">
                <ng-container
                  *ngTemplateOutlet="
                    customStepTemplate;
                    context: { index: step.Index, sopStepId: step.SopStepId }
                  "></ng-container>
                <ng-container *ngIf="step.SubSteps?.length">
                  <div class="width-100 parent-step-item">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <ng-container
                        *ngTemplateOutlet="stepTemplate; context: { step: step }"></ng-container>
                    </div>
                    <ng-container
                      *ngFor="
                        let subStep of step.SubSteps;
                        trackBy: trackByProjectChecklistStepId;
                        let last = last
                      ">
                      <div class="new-sub-step">
                        <ng-container
                          *ngTemplateOutlet="
                            customStepTemplate;
                            context: { index: subStep.Index, sopStepId: subStep.SopStepId }
                          "></ng-container>
                      </div>

                      <ng-container
                        *ngTemplateOutlet="stepTemplate; context: { step: subStep }"></ng-container>

                      <ng-container *ngIf="last">
                        <div class="new-sub-step">
                          <ng-container
                            *ngTemplateOutlet="
                              customStepTemplate;
                              context: {
                                index: subStep.Index,
                                sopStepId: subStep.SopStepId,
                                bottom: true
                              }
                            "></ng-container>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="!step.SubSteps?.length">
                  <ng-container
                    *ngTemplateOutlet="stepTemplate; context: { step: step }"></ng-container>

                  <div class="new-sub-step">
                    <ng-container
                      *ngTemplateOutlet="
                        customStepTemplate;
                        context: { index: step.Index + '.1', sopStepId: step.SopStepId }
                      "></ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="last">
                  <ng-container
                    *ngTemplateOutlet="
                      customStepTemplate;
                      context: { index: step.Index, sopStepId: step.SopStepId, bottom: true }
                    "></ng-container>
                </ng-container>
              </div>
            </div>
          </helion-ui-accordion-item>
        </helion-ui-accordion>

        <div fxFlex.xs="100" fxFlex.gt-xs="50" fxLayout="column" *ngIf="isFullScreen">
          <div class="notes-editor">
            <div>
              <pc-text-editor
                [placeholder]="'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.NOTES' | translate"
                [initialData]="phase.Notes || ''"
                (textChange)="phase.Notes = $event"
                (editorCreated)="setInitialPhaseNotesValue($event, phase)"
                (autoSave)="saveNotes(phase, $event)">
              </pc-text-editor>
            </div>
          </div>
        </div>
      </div>
    </helion-ui-accordion-item>
  </helion-ui-accordion>
</div>

<canvas #confettiCanvas></canvas>
<pc-loading-modal [id]="modalName" type="overlay"></pc-loading-modal>

<ng-template #stepTemplate let-step="step">
  <div
    #stepRef
    class="step-template d-flex align-items-center gap-8 position-relative"
    [class.sub-step]="step.IsSubStep"
    [style.margin-left.px]="step.IsSubStep ? 20 : 0">
    <ng-template #menu>
      <helion-ui-menu>
        <button
          data-cy="customStepEditButton"
          class="menu-item"
          cdkMenuItem
          (click)="editCustomStep(step, stepRef)">
          <helion-ui-icon data-feather="edit-2"></helion-ui-icon>
          <span>{{ 'COMMON.ACTION.MODIFY' | translate }}</span>
        </button>
        <button
          class="menu-item"
          cdkMenuItem
          data-cy="customStepDeleteButton"
          (click)="deleteCustomStep(step)">
          <helion-ui-icon data-feather="trash-2"></helion-ui-icon>
          <span>{{ 'COMMON.ACTION.DELETE' | translate }}</span>
        </button>
      </helion-ui-menu>
    </ng-template>

    <helion-ui-icon
      [size]="16"
      class="custom-step-actions"
      *ngIf="step.IsCustom"
      [cdkMenuTriggerFor]="menu"
      data-feather="more-vertical"></helion-ui-icon>
    <helion-ui-checkbox
      (click)="clickChecklistStep(step, $event)"
      [tooltip]="getTooltip(step)"
      [disabled]="step.IsLockedByRequiredSteps"
      [isChecked]="
        step.CheckedState === stepCheckedStates.NotRequired ||
        step.CheckedState === stepCheckedStates.Checked
      "
      [indeterminate]="step.CheckedState === stepCheckedStates.NotRequired"
      class="with-font-bold margin-left-20">
    </helion-ui-checkbox>

    <div
      [ngClass]="{ 'd-flex align-items-center gap-4': editingCustomStep?.Index === step.Index }"
      [class.font-italic]="step.IsOptional"
      [class.text-strikethrough]="
        step.IsOptional && step.CheckedState === stepCheckedStates.NotRequired
      ">
      <span>{{ step.Index }}&nbsp;</span>
      @if(editingCustomStep?.Index !== step.Index) {
      <span *ngIf="!step.Link && !step.DocumentTemplateId">{{ step.Title }}</span>
      <a
        *ngIf="step.Link || step.DocumentTemplateId"
        class="link with-font-bold"
        [href]="step.Link"
        (click)="onStepLinkClick($event, step)"
        data-rel="external"
        target="_blank">
        {{ step.Title }}
      </a>
      } @else {
      <helion-ui-input
        [(ngModel)]="editingCustomStep.Title"
        [inputWidth]="'100%'"
        type="text"
        name="customStepName">
      </helion-ui-input>
      }
      <span *ngIf="!step.IsSubStep && step.SubSteps?.length">
        ({{ step.FinishedSubStepsNumber ?? 0 }}/{{ step.SubSteps?.length }})
      </span>
    </div>

    <span fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
      <helion-ui-icon
        class="icon-danger"
        data-feather="clock"
        *ngIf="step.Deadline && step.CheckedState === stepCheckedStates.Unchecked"
        [size]="20"
        [ngClass]="getTimeWarningClass(step)"
        [tooltip]="getTimeWarningTooltip(step)">
      </helion-ui-icon>
      <helion-ui-icon
        data-feather="edit-2"
        class="cursor-pointer"
        *ngIf="step.LinkedTaskId && isFullScreen"
        [size]="20"
        (click)="onEditTask(step.LinkedTaskId)"
        tooltip="{{ 'LEAD_TASK.EDIT_TASK' | translate }}"></helion-ui-icon>
      <img
        *ngIf="step.IsVisibleToCustomer"
        class="button-header-round-image-mobile text-orange"
        style="width: 20px"
        src="../../../../assets/icons/track-yellow.svg" />
    </span>

    <span
      class="material-icons cursor-pointer pull-right text-20"
      *ngIf="!step.LinkedTaskId && step.CheckedState === 'Unchecked' && isFullScreen"
      (click)="onAddTask(step)"
      tooltip="{{ 'LEAD_TASK.NEW_TASK' | translate }}">
      add_task
    </span>
  </div>
</ng-template>

<ng-template #customStepTemplate let-index="index" let-sopStepId="sopStepId" let-bottom="bottom">
  <div class="add-step-block" #customStepBlock>
    <helion-ui-input
      [(ngModel)]="customStepName"
      *ngIf="activeCustomStepIndex === index && customStepBottom === bottom"
      [inputWidth]="'100%'"
      type="text"
      name="customStepName">
    </helion-ui-input>
    <div
      class="add-step-hover-block"
      *ngIf="activeCustomStepIndex !== index || customStepBottom !== bottom">
      <hr class="flex-1" />
      <div class="add-step-button">
        <span>{{ index | customStepIndexPreview : bottom }}</span>
        <helion-ui-icon
          [size]="20"
          color="#2571ab"
          role="button"
          data-feather="plus-circle"
          (click)="onCustomStepClick(index, sopStepId, customStepBlock, bottom)"></helion-ui-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #stepTemplateChecklist>
  <div
    fxLayout.xs="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center"
    class="margin-bottom-36">
    <helion-ui-select
      selectLook="white"
      class="tempate-selector"
      [ngModel]="projectCheckList.ChecklistTemplateDate | ticks"
      [disabled]="!isResponsible"
      placeholder="{{ 'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.TEMPLATE' | translate }}">
      <helion-ui-option
        *ngFor="let template of checklistTemplates"
        [value]="template.TemplateDate | ticks"
        [disabled]="
          (template.TemplateDate | ticks) === (projectCheckList.ChecklistTemplateDate | ticks)
        "
        (click)="createChecklist(template)"
        [label]="template.Title + ' ' + (template.TemplateDate | date : 'dd.MM.yyyy')">
      </helion-ui-option>
    </helion-ui-select>

    <helion-ui-select
      selectLook="white"
      placeholder="{{ 'LEAD_DETAIL.PROJECT_STATUS.PHASE_PLACEHOLDER' | translate }}"
      [ngModel]="selectedPhase"
      (ngModelChange)="selectedPhaseChange.emit($event)">
      <helion-ui-option
        *ngFor="let phase of phases"
        [value]="phase.Key"
        [label]="phase.Translation">
      </helion-ui-option>
    </helion-ui-select>

    <div *ngIf="projectCheckList?.SopListId" fxLayout="row" fxLayoutAlign="start center">
      <span style="margin-right: 8px">{{
        'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.COLLAPSE_ALL' | translate
      }}</span>
      <helion-ui-switch-input
        (checkedChange)="toggleAccordionCollapsedState($event)"></helion-ui-switch-input>
      <span style="margin-left: 8px">{{
        'LEAD_DETAIL.PROJECT_STATUS.PROJECT_CHECKLIST.EXPAND_ALL' | translate
      }}</span>
    </div>
  </div>
</ng-template>
