// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';
import { Authenticate } from '../../models/principal.model';
import { IPrincipal } from '../../interfaces/principal.interface';
import { HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http';
import { Params, UrlSegment } from '@angular/router';

export const INIT: string = '[Auth] Init';
export const LOGIN: string = '[Auth] Login';
export const LOGOUT: string = '[Auth] Logout';
export const LOGOUT_SESSION: string = '[Auth] Logout Session';
export const LOGIN_SUCCESS: string = '[Auth] Login Success';
export const LOGIN_SUCCESS_SESSION: string = '[Auth] Login Success Session';
export const LOGIN_FAILURE: string = '[Auth] Login Failure';
export const LOGIN_REDIRECT: string = '[Auth] Login Redirect';
export const SEND_RESET_CODE: string = '[Auth] Send reset code';
export const SEND_RESET_CODE_SUCCESS: string = '[Auth] Send reset code success';
export const SEND_RESET_CODE_FAILED: string = '[Auth] Send reset code failed';
export const RESET_PASSWORD_CODE: string = '[Auth] Reset password code';
export const RESET_PASSWORD_CODE_SUCCESS: string = '[Auth] Reset password code success';
export const RESET_PASSWORD_CODE_FAILED: string = '[Auth] Reset password code failed';

export class InitAction implements Action {
  public readonly type: string = INIT;

  constructor(public payload: any = undefined) {}
}

export interface ILoginActionPayload {
  authenticate: Authenticate;
  redirect?: ILoginRedirect;
}

export interface ILoginRedirect {
  queryParams: Params;
  urlSegments: UrlSegment[];
}

export class LoginAction implements Action {
  public readonly type: string = LOGIN;

  constructor(public payload: ILoginActionPayload) {}
}

export interface ILoginSuccessActionPayload {
  principal: IPrincipal;
  redirect?: ILoginRedirect;
}

export class LoginSuccessAction implements Action {
  public readonly type: string = LOGIN_SUCCESS;

  constructor(public payload: ILoginSuccessActionPayload) {}
}

export class LoginSuccessSessionAction implements Action {
  public readonly type: string = LOGIN_SUCCESS_SESSION;

  constructor(public payload: IPrincipal) {}
}

export class LoginFailureAction implements Action {
  public readonly type: string = LOGIN_FAILURE;

  constructor(public payload: any) {}
}

export class LoginRedirectAction implements Action {
  public readonly type: string = LOGIN_REDIRECT;

  constructor(public payload: string = '') {}
}

export class LogoutAction implements Action {
  public readonly type: string = LOGOUT;

  constructor(public payload: any = undefined) {}
}

export class LogoutSessionAction implements Action {
  public readonly type: string = LOGOUT_SESSION;

  constructor(public payload: HttpErrorResponse | HttpHeaderResponse) {}
}

export class SendResetCodeAction implements Action {
  public readonly type: string = SEND_RESET_CODE;

  constructor(public payload: string) {}
}

export class SendResetCodeSuccessAction implements Action {
  public readonly type: string = SEND_RESET_CODE_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class SendResetCodeFailedAction implements Action {
  public readonly type: string = SEND_RESET_CODE_FAILED;

  constructor(public payload: string) {}
}

export interface IResetPasswordCode {
  userName: string;
  code: string;
  newPassword: string;
  againNewPassword: string;
}

export class ResetPasswordCodeAction implements Action {
  public readonly type: string = RESET_PASSWORD_CODE;

  constructor(public payload: IResetPasswordCode) {}
}

export class ResetPasswordCodeSuccessAction implements Action {
  public readonly type: string = RESET_PASSWORD_CODE_SUCCESS;

  constructor(public payload: any = undefined) {}
}

export class ResetPasswordCodeFailedAction implements Action {
  public readonly type: string = RESET_PASSWORD_CODE_FAILED;

  constructor(public payload: string) {}
}

export type Actions =
  | InitAction
  | LoginAction
  | LoginSuccessAction
  | LoginSuccessSessionAction
  | LoginFailureAction
  | LoginRedirectAction
  | LogoutAction
  | LogoutSessionAction
  | SendResetCodeAction
  | SendResetCodeSuccessAction
  | SendResetCodeFailedAction
  | ResetPasswordCodeAction
  | ResetPasswordCodeSuccessAction
  | ResetPasswordCodeFailedAction;
