import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'pc-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss'],
})
export class DevelopmentComponent implements OnInit {
  // This is by no means a serious protection - we just want to protect the user from accidentally fiddling around on this page
  private pwd: string = 'helion';

  show: boolean = !environment.protectDevelopmentPage;

  constructor() {
    // empty
  }

  ngOnInit(): void {
    // empty
  }

  check(value: string): void {
    if (value.localeCompare(this.pwd) === 0) {
      this.show = true;
    }
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }
}
