import { LeadActionViewModel } from '../apis/advis';

import ActionTypeEnum = LeadActionViewModel.ActionTypeEnum;

export class ActionsUtil {
  // https://github.com/slavik57/enum-values

  public static isAllowedArr(
    actions: ActionTypeEnum[],
    actionUrls: LeadActionViewModel[]
  ): boolean {
    for (let action of actions) {
      if (ActionsUtil.isAllowed(action, actionUrls)) {
        return true;
      }
    }
    return false;
  }

  public static isAllowed(action: ActionTypeEnum, actionUrls: LeadActionViewModel[]): boolean {
    for (let url of actionUrls) {
      if (action === url.ActionType.toString()) {
        return true;
      }
    }
    return false;
  }

  // public static getAllowed(actionUrls: string[]): ActionE[] {
  //   const actions: ActionE[] = [];
  //   for (let url of actionUrls) {
  //     for (let item in ActionE) {
  //       const value: any = ActionE[item];
  //       if (isNaN(Number(item)) && value.localeCompare(url) === 0) {
  //         actions.push(value);
  //       }
  //     }
  //   }
  //   return actions;
  // }
}

// export enum ActionE {
//   TRANSFER = 'api/actions/lead/{leadId}/transfer',
//   CONTACT_FAIL = 'api/actions/lead/{leadId}/contact/fail',
//   CONTACT_SUCCESS = 'api/actions/lead/{leadId}/contact/success',
//   WIN = 'api/actions/lead/{leadId}/win',
//   WIN_VARIANT = 'api/actions/lead/{leadId}/win/{varianteId}',
//   HANDOVER = 'api/actions/lead/{leadId}/handover',
//   TRIAGE = 'api/actions/lead/{leadId}/triage',
//   TRANSFER_BO_TS = 'api/actions/lead/{leadId}/transfer-bo-ts',
//   TRANSFER_BO_SS = 'api/actions/lead/{leadId}/transfer-bo-ss',
//   TRIAGE_VARIANT = 'api/actions/lead/{leadId}/triage/{varianteId}',
//   PAY_VARIANT = 'api/actions/lead/{leadId}/pay/{varianteId',
//   TRANSFERBACK = 'api/actions/lead/{leadId}/transferback',
//   TRANSFERBACK_BO = 'api/actions/lead/{leadId}/transferback-bo',
//   ABORT = 'api/actions/lead/{leadId}/abort',
//   HOLD = 'api/actions/lead/{leadId}/hold',
//   RELEASE = 'api/actions/lead/{leadId}/release',
//   BO_SENT = 'api/actions/lead/{leadId}/bosent',
//   NBO_SENT = 'api/actions/lead/{leadId}/nbosent'
// }
//
// export enum ActionE {
//   TRANSFER = 'TRANSFER',
//   CONTACT_FAIL = 'CONTACT_FAIL',
//   CONTACT_SUCCESS = 'CONTACT_SUCCESS',
//   WIN = 'WIN',
//   WIN_VARIANT = 'WIN_VARIANT',
//   HANDOVER = 'HANDOVER',
//   TRIAGE = 'TRIAGE',
//   TRANSFER_BO_TS = 'TRANSFER_BO_TS',
//   TRANSFER_BO_SS = 'TRANSFER_BO_SS',
//   TRIAGE_VARIANT = 'TRIAGE_VARIANT',
//   PAY_VARIANT = 'PAY_VARIANT',
//   TRANSFERBACK = 'TRANSFERBACK',
//   TRANSFERBACK_BO = 'TRANSFERBACK_BO',
//   ABORT = 'ABORT',
//   HOLD = 'HOLD',
//   RELEASE = 'RELEASE',
//   BO_SENT = 'BO_SENT',
//   NBO_SENT = 'NBO_SENT'
// }
