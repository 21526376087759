/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PvVariantReportRequest { 
    NumberOfModules?: number;
    KwhYieldPerKwp?: number;
    ProductlineType?: PvVariantReportRequest.ProductlineTypeEnum;
    /**
     * AdditionalSmartEnergyDevices (multiselect)
     */
    AdditionalSmartEnergyDevices?: Array<string>;
    /**
     * ControllableSmartEnergyDevices (multiselect)
     */
    ControllableSmartEnergyDevices?: Array<string>;
    IsNewDistributionBoard?: string;
}
export namespace PvVariantReportRequest {
    export type ProductlineTypeEnum = 'Basic' | 'Advanced' | 'HighEnd' | 'SingleArticle' | 'LargeScale';
    export const ProductlineTypeEnum = {
        Basic: 'Basic' as ProductlineTypeEnum,
        Advanced: 'Advanced' as ProductlineTypeEnum,
        HighEnd: 'HighEnd' as ProductlineTypeEnum,
        SingleArticle: 'SingleArticle' as ProductlineTypeEnum,
        LargeScale: 'LargeScale' as ProductlineTypeEnum
    };
}
