import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { NoteViewModel, RoleTargetGroupViewModel } from '../../../shared/apis/advis';
import { SYSTEM_TAG_MARKER } from '../../../lead/dialog/lead-tag/lead-tag-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import {
  GetAllNotesAction,
  GetAllNotesSuccessAction,
  IDrawingNote,
  UpdateDrawingNoteAction,
  UpdateNoteTags,
  UpdateTextNoteAction,
} from '../../../shared/states/lead-notes/lead-notes.action';
import cloneDeep from 'lodash/cloneDeep';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../../shared/states';
import { IState } from '../../../shared/states';
import { filter, mapTo } from 'rxjs/operators';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { ModalLoaderIdTypesEnum } from '../../../shared/models/enum/modal-loader-id-types.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { DragulaNoteTagsConfig } from 'app/shared/services/dragula-note-tags-config.service';
import { DragulaService } from 'ng2-dragula';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DragScrollerService } from 'app/shared/services/drag-scroller.service';
import { ButtonToggleGroupComponent, DialogService } from '@sitewerk/theia-ui-lib';
import { DragulaItemDirective } from '../../../common-components/directives/dragula-item.directive';
import { NoteComponent } from '../note.component';
import NoteTypeEnum = NoteViewModel.NoteTypeEnum;

@Component({
  selector: 'pc-lead-note-card',
  templateUrl: './lead-note-card.component.html',
  styleUrls: ['./lead-note-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LeadNoteCardComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() protected leadId: number;
  @Input() protected readOnly: boolean;
  @Input() protected tagFilter: string;
  @Input() protected isNotesOnFullScreen: boolean;
  @Input() protected editNavigateCommand: string;
  @Input() protected hiddenSystemTag: boolean = false;
  @Input() protected selectedTags: string[] = [];
  @Input() protected selectedNote: NoteViewModel;
  @Input() protected roleTargetGroups: RoleTargetGroupViewModel[];
  @Input() protected translateRoleTargetGroup: Function;

  @Output() protected notesWithTagFilter: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren(NoteComponent) protected noteComponents: QueryList<NoteComponent>;
  @ViewChildren(DragulaItemDirective) protected listItems: QueryList<DragulaItemDirective>;
  @ViewChild('tagsToggle', { static: false }) protected tagsToggle: ButtonToggleGroupComponent;

  public dragulaNoteTagsGroupName: string = 'dragula-note-tags-group';
  public isDragTag: boolean = false;

  protected readonly noteTypeEnum: typeof NoteTypeEnum = NoteTypeEnum;

  protected tags: string[];
  protected selectedNotes: NoteViewModel[];
  protected notes: NoteViewModel[];
  protected isDesktop: boolean = this.deviceService.isDesktop();

  protected selectedTargetGroupFilter: string[] = [];
  protected showWarningFilterApplied: boolean = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private store: Store<IState>,
    private translate: TranslateService,
    private loadingModalService: LoadingModalService,
    private deviceService: DeviceDetectorService,
    private dragulaService: DragulaService,
    private dragScrollerService: DragScrollerService,
    public dragulaNoteTagsConfig: DragulaNoteTagsConfig,
    public sanitizer: DomSanitizer
  ) {
    dragulaService.destroy(this.dragulaNoteTagsGroupName);
    dragulaService.createGroup(this.dragulaNoteTagsGroupName, dragulaNoteTagsConfig.options);
  }

  public ngOnInit(): void {
    this.selectedTargetGroupFilter = [];

    this.subscriptions.add(
      this.store
        .select(RootReducer.getAllNotesEntity)
        .pipe(filter((notes: NoteViewModel[]) => isNotNullOrUndefined(notes)))
        .subscribe((notes: NoteViewModel[]) => {
          this.notes = notes.filter(
            note =>
              note.TargetGroupRoleIds.length == 0 ||
              this.roleTargetGroups?.some(rtg => note.TargetGroupRoleIds.includes(rtg.RoleId))
          );

          this.notes = cloneDeep(
            this.notes.filter(
              note =>
                isNullOrUndefined(this.tagFilter) ||
                note.Tags.findIndex(y => y === this.tagFilter) >= 0
            )
          );

          const newTags: string[] = [];
          const newNotes: NoteViewModel[] = cloneDeep(this.notes);

          newNotes.forEach((note: NoteViewModel) => {
            if (isNotNullOrUndefined(note.Tags)) {
              note.Tags.forEach((noteTag: string) => {
                if (newTags.findIndex((tag: string) => tag === noteTag) === -1) {
                  newTags.push(noteTag);
                }
              });
            }
          });

          this.tags = newTags
            .filter(
              x =>
                !this.hiddenSystemTag || (this.hiddenSystemTag && !x.startsWith(SYSTEM_TAG_MARKER))
            )
            .sort((a: string, b: string) => (a > b ? 1 : -1));

          this.setData();
        })
    );
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(
      this.listItems.changes.subscribe(() => {
        this.dragulaNoteTagsConfig.initializeNoteItems(this.listItems.toArray());
      })
    );

    this.subscriptions.add(
      this.store
        .select((storeState: IState) => storeState.leadNotes.notesLoading)
        .subscribe((loading: boolean) => {
          if (loading) {
            this.loadingModalService.openModal(ModalLoaderIdTypesEnum.NOTES_LIST);
          } else {
            this.loadingModalService.closeModal(ModalLoaderIdTypesEnum.NOTES_LIST);
          }
        })
    );

    this.subscriptions.add(
      this.store
        .select((storeState: IState) => storeState.leadNotes.noteLoading)
        .subscribe((loading: boolean) => {
          if (loading) {
            this.loadingModalService.openModal(ModalLoaderIdTypesEnum.NOTES_LIST);
          } else {
            this.loadingModalService.closeModal(ModalLoaderIdTypesEnum.NOTES_LIST);
          }
        })
    );

    this.subscriptions.add(
      this.dragulaService.drop(this.dragulaNoteTagsGroupName).subscribe(({ el, target }: any) => {
        if (target.classList.contains(this.dragulaNoteTagsConfig.noteContainerClassName)) {
          const noteIndex: number = +target.getAttribute(
            this.dragulaNoteTagsConfig.noteIndexAttributeName
          );
          const note: NoteViewModel = this.notes[noteIndex];
          const tags: string[] = note.Tags;
          const newTag: string = el.getAttribute(this.dragulaNoteTagsConfig.noteTagAttributeName);
          const isTagExist: boolean = tags.includes(newTag);
          if (!isTagExist) {
            tags.push(newTag);
            note.Tags = tags;
            this.saveNoteTags(note);
          } else {
            this.dialogService.openAlert(
              this.translate.instant('LEAD_DETAIL.NOTE_SKETCH.TAG_ALREADY_APPLY', {
                imageTag: newTag,
              })
            );
          }
        }
      })
    );

    const drag$: Observable<boolean> = this.dragulaService.drag().pipe(mapTo(true));
    const dragEnd$: Observable<boolean> = this.dragulaService.dragend().pipe(mapTo(false));

    this.subscriptions.add(
      this.dragScrollerService.subscribeToScrollableContainerDrag('.lead-main-content-wrapper')
    );
    this.subscriptions.add(
      drag$.subscribe(() => {
        this.isDragTag = true;
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      dragEnd$.subscribe(() => {
        this.isDragTag = false;
        this.listItems
          .map(note => note.elementRef.nativeElement)
          .forEach(element =>
            element.classList.remove(this.dragulaNoteTagsConfig.currentDragTargetClassName)
          );
        this.cdr.detectChanges();
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isDesktop) {
      this.dragulaNoteTagsConfig.setAllowDnD(true);
    } else {
      this.dragulaNoteTagsConfig.setAllowDnD(false);
    }

    if (changes.leadId && this.leadId) {
      this.store.dispatch(new GetAllNotesSuccessAction({ notes: [], leadId: this.leadId }));
      this.store.dispatch(
        new GetAllNotesAction({
          leadId: this.leadId,
        })
      );
    }

    if (changes.selectedNote) {
      const noteComponent = this.noteComponents?.find(
        noteComponent => noteComponent.note?.Id === this.selectedNote.Id
      );
      if (noteComponent) {
        noteComponent.isEditMode = true;
        noteComponent.scrollToNote();
      }
    }

    if (changes.selectedTargetGroup) {
      this.filterTargetGroup(this.selectedTargetGroupFilter);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public closeOtherNotes(noteId: number): void {
    this.noteComponents?.forEach(noteComponent => {
      if (noteComponent.note?.Id && noteComponent.note.Id !== noteId && noteComponent.isEditMode) {
        noteComponent.isEditMode = false;
      }
    });
  }

  protected filterTag(value: any): void {
    this.selectedTags = value;
    this.setData();
  }

  protected filterTargetGroup(value: string[]): void {
    this.selectedTargetGroupFilter = value;
    this.setData();
  }

  protected trackNoteListItem(_: number, note: NoteViewModel): number {
    return note.Id;
  }

  private saveNoteTags(newNote: NoteViewModel): void {
    this.store.dispatch(new UpdateNoteTags(true));
    if (newNote.NoteType === this.noteTypeEnum.Text) {
      this.store.dispatch(new UpdateTextNoteAction({ note: newNote }));
    } else {
      const noteData: NoteViewModel = { ...newNote };
      const data: IDrawingNote = {
        noteData,
      };
      this.store.dispatch(new UpdateDrawingNoteAction(data));
    }
  }

  private setData(): void {
    requestAnimationFrame(() => {
      // filter tags
      this.selectedNotes = this.notes.filter(
        (note: NoteViewModel) =>
          this.selectedTags.length === 0 ||
          note.Tags.some((noteTag: string) => this.selectedTags.includes(noteTag))
      );

      if (this.selectedTargetGroupFilter?.length > 0) {
        // filter target group
        this.selectedNotes = this.selectedNotes.filter(
          (note: NoteViewModel) =>
            this.selectedTargetGroupFilter.length === 0 ||
            note.TargetGroupRoleIds.some((noteTargetGroup: string) =>
              this.selectedTargetGroupFilter.includes(noteTargetGroup)
            )
        );
      }

      if (this.notes.length > 0 && this.selectedNotes.length == 0) {
        this.showWarningFilterApplied = true;
      } else {
        this.showWarningFilterApplied = false;
      }

      this.notesWithTagFilter.emit(this.selectedNotes.length);
      this.cdr.markForCheck();
    });

    setTimeout(() => {
      this.tagsToggle?.updateButtonsSubscriptions();
    });
  }
}
