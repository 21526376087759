import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

import * as RootReducer from '../../shared/states/index';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { SetSelectedMandantsAction } from '../../shared/states/global/global.action';
import { ViewSettingSaveAction } from '../../shared/states/view-setting/view-setting.action';
import { ViewSettingItem, ViewSettingKeyE } from '../../shared/services/view-setting.service';
import { SelectedMandantsVsModel } from '../../shared/models/view-setting/selected-mandants-vs.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, TypeE } from '../../shared/services/notification.service';
import {
  ActiveViewE,
  LeadOverviewVsModel,
} from '../../shared/models/view-setting/lead-overview-vs.model';
import { MasterDataMandatorViewModel, MasterDataViewModel } from '../../shared/apis/advis';
import { map, take } from 'rxjs/operators';
import { CheckboxOptionComponent } from '@sitewerk/theia-ui-lib';

export interface ISelectItem {
  text: string;
  mandantId: string;
}

@Component({
  selector: 'pc-mandant-selector',
  templateUrl: './mandant-selector.component.html',
  styleUrls: ['./mandant-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MandantSelectorComponent implements OnInit, OnDestroy {
  private oldSelectedItemIds: string[] = [];
  private subscriptions: Subscription = new Subscription();
  private alertSnackbar: MatSnackBarRef<SimpleSnackBar> = undefined;
  private vsLeadOverview: LeadOverviewVsModel = new LeadOverviewVsModel();

  public selectItems: ISelectItem[] = [];
  public selectedItemIds: string[] = [];
  @ViewChildren(CheckboxOptionComponent) private options: QueryList<CheckboxOptionComponent>;

  constructor(
    private store: Store<RootReducer.IState>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  private static createSelectItems(
    mandants: MasterDataMandatorViewModel[],
    selectedMandants: string[]
  ): [ISelectItem[], string[]] {
    const selectItems: ISelectItem[] = [];
    const selectedItems: string[] = [];

    mandants.sort((m1: MasterDataMandatorViewModel, m2: MasterDataMandatorViewModel) => {
      return m1.Name.localeCompare(m2.Name);
    });

    mandants.forEach((mandant: MasterDataMandatorViewModel) => {
      const mandantId: string = '' + mandant.Id;

      const mandantItem: ISelectItem = {
        text: mandant.Name,
        mandantId: '' + mandantId,
      };

      selectItems.push(mandantItem);

      const selectedMandantId: string = selectedMandants.find((id: string) => {
        return id.localeCompare(mandantId) === 0;
      });

      if (selectedMandantId !== undefined) {
        selectedItems.push(mandantItem.mandantId);
      }
    });

    return [selectItems, selectedItems];
  }

  ngOnInit(): void {
    const mandants$: Observable<MasterDataMandatorViewModel[]> = this.store
      .select(RootReducer.getGlobalMasterData)
      .pipe(
        take(1),
        map((masterDataVm: MasterDataViewModel) => {
          return masterDataVm.Mandators;
        })
      );

    const selectedMandants$: Observable<SelectedMandantsVsModel> =
      this.store.select<SelectedMandantsVsModel>(RootReducer.getViewSettingSelectedMandants);
    const vsLeadOverview$: Observable<LeadOverviewVsModel> = this.store.select<LeadOverviewVsModel>(
      RootReducer.getViewSettingLeadOverview
    );

    this.subscriptions.add(
      combineLatest([mandants$, selectedMandants$, vsLeadOverview$]).subscribe(
        ([mandantsVm, selectedMandantsVm, vsLeadOverviewVm]: [
          MasterDataMandatorViewModel[],
          SelectedMandantsVsModel,
          LeadOverviewVsModel
        ]) => {
          const mandants: MasterDataMandatorViewModel[] = cloneDeep(mandantsVm);
          console.log('-----', mandants);

          if (this.vsLeadOverview.activeView !== vsLeadOverviewVm.activeView) {
            this.closeSnackBar();
          }

          this.vsLeadOverview = cloneDeep(vsLeadOverviewVm);

          const selectedMandants: SelectedMandantsVsModel = cloneDeep(selectedMandantsVm);

          const selectItemsArr: [ISelectItem[], string[]] =
            MandantSelectorComponent.createSelectItems(mandants, selectedMandants.mandants);

          this.selectItems = selectItemsArr[0];
          this.selectedItemIds = selectItemsArr[1];
          this.oldSelectedItemIds = cloneDeep(this.selectedItemIds);

          if (this.selectedItemIds.length === 0) {
            this.openSnackBar();
          }

          this.store.dispatch(new SetSelectedMandantsAction(this.createSetFromSelectedItems()));
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public createSetFromSelectedItems(): Set<number> {
    const selectedSet: Set<number> = new Set<number>();

    this.selectedItemIds.forEach((item: string) => {
      selectedSet.add(Number.parseInt(item, 10));
    });

    return selectedSet;
  }

  selectAllChange(value: boolean): void {
    this.selectedItemIds = value ? cloneDeep(this.selectItems.map(x => x.mandantId)) : [];
    this.options.forEach((option: CheckboxOptionComponent) => {
      option.isChecked = value;
    });
    this.cdr.markForCheck();
  }

  onClose(): void {
    if (!isEqual(this.oldSelectedItemIds, this.selectedItemIds)) {
      // Items changed
      if (this.selectedItemIds.length === 0) {
        this.openSnackBar();
      } else {
        this.closeSnackBar();
        this.notificationService.notifySimple(
          this.translate.instant('COMMON.LOADING_ACCORDING_SELECTED_MANDANTS'),
          TypeE.PRIMARY
        );
      }

      this.dispatchViewSettings();
    }
  }

  onResetClick(): void {
    this.selectedItemIds = [];
  }

  private openSnackBar(): void {
    if (this.alertSnackbar === undefined) {
      let message: string;
      if (this.vsLeadOverview.activeView === ActiveViewE.Onlinepool) {
        message = this.translate.instant('COMMON.SELECT_ONLINEPOOL');
      } else {
        message = this.translate.instant('COMMON.SELECT_MANDANTS');
      }

      this.alertSnackbar = this.notificationService.notifyWithAction(
        message,
        this.translate.instant('COMMON.BTN_DISMISS'),
        TypeE.ALERT
      );
      this.subscriptions.add(
        this.alertSnackbar.afterDismissed().subscribe(() => {
          this.alertSnackbar = undefined;
        })
      );
    }
  }

  private closeSnackBar(): void {
    if (this.alertSnackbar !== undefined) {
      this.alertSnackbar.dismiss();
      this.alertSnackbar = undefined;
    }
  }

  private dispatchViewSettings(): void {
    const selectedMandantIds: string[] = [];
    this.selectedItemIds.forEach((item: string) => {
      selectedMandantIds.push(item);
    });

    this.store.dispatch(
      new ViewSettingSaveAction(
        new ViewSettingItem(
          ViewSettingKeyE.SELECTED_MANDANTS_V2,
          new SelectedMandantsVsModel(selectedMandantIds)
        )
      )
    );
  }
}
