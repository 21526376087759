/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DirectoryContactViewModel,
  DirectoryEmailViewModel,
  DirectoryPersonViewModel,
  DirectoryPhoneViewModel,
  LeadContactViewModel,
  MasterDataMandatorViewModel,
} from '../../../shared/apis/advis';
import { SelectContactTypeEnum } from '../../../lead/common/base/BaseContactDirective';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { filter, map } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { IEnumData } from '../../../shared/services/enum.service';
import {
  EditCompanyContactDialogComponent,
  IEditCompanyData,
} from '../../dialog/edit-company-contact-dialog/edit-company-contact-dialog.component';
import {
  EditPersonContactDialogComponent,
  IEditPersonData,
} from '../../dialog/edit-person-contact-dialog/edit-person-contact-dialog.component';
import { AddressBookBackendService } from '../../../shared/services/address-book-backend.service';
import {
  IDialogData,
  SelectPersonDialogComponent,
} from '../../dialog/select-person-dialog/select-person-dialog.component';
import { ContactSelectorDialogComponent } from '../../dialog/contact-selector-dialog/contact-selector-dialog.component';
import { IEditResult } from '../../../lead/common/base/BaseContactFormDirective';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtil } from '../../../shared/utils/common.util';
import { IExternalManagedAddress } from '../../../shared/interfaces/ExternalManagedAddress.interface';
import { IExternManagedAddressTypeConfig } from '../../../shared/utils/exter-managed-address-type.util';
import { DialogService } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-address-book-contact',
  templateUrl: './address-book-contact.component.html',
  styleUrls: ['./address-book-contact.component.scss'],
})
export class AddressBookContactComponent implements OnInit, OnDestroy {
  @Input() public leadId: number;
  @Input() public mandator: MasterDataMandatorViewModel;
  @Input() public originContact: LeadContactViewModel;
  @Input() public titleItems: IEnumData[] = [];
  @Input() public showSaveButton: boolean = true;
  @Input() public isExternalManaged: boolean = false;
  @Input() public addressTypeChanged: boolean = false;
  @Input() public language: string;
  @Output() public onContactSave: EventEmitter<DirectoryContactViewModel> =
    new EventEmitter<DirectoryContactViewModel>();
  @Output() public onContactSelected: EventEmitter<DirectoryContactViewModel> =
    new EventEmitter<DirectoryContactViewModel>();
  @Output() public onSaveButtonDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onCopyAsExternalManaged: EventEmitter<IExternalManagedAddress> =
    new EventEmitter<IExternalManagedAddress>();

  public isCompanyContactSelect: boolean = false;
  public isPersonContactSelect: boolean = false;
  public isNotAdditionalAddress: boolean = false;
  public contactIsSelected: boolean = false;
  public isSelectionChangeNotSaved: boolean = false;
  public directoryContact: DirectoryContactViewModel;

  private subscriptions: Subscription = new Subscription();
  private prevSaveButtonDisabled: boolean;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private addressBookService: AddressBookBackendService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (
      this.originContact.Id > 0 &&
      !isNullOrUndefined(this.originContact.ContactId) &&
      this.originContact.ContactId > 0
    ) {
      this.subscriptions.add(
        this.addressBookService.getContact(this.originContact.ContactId).subscribe(newContact => {
          if (newContact.successful) {
            this.mapLoadDirectoryContact(newContact.payload);
          }
        })
      );
    }
  }

  public selectNewCompany(): void {
    this.subscriptions.add(
      this.dialogService
        .open(ContactSelectorDialogComponent, {
          data: {
            selectType: SelectContactTypeEnum.COMPANY,
            isCompany: true,
            mandator: this.mandator,
            contactType: this.originContact.AddressType,
          },
        })
        .afterClosed()
        .subscribe((contactId: number) => {
          if (contactId === undefined) {
            return;
          }
          this.selectNewContact(contactId);
        })
    );
  }

  public selectNewPersonFromCompany(): void {
    this.subscriptions.add(
      this.addressBookService
        .getPersonsContactsOfCompany(this.directoryContact.Company.Id)
        .pipe(
          filter(x => x.successful),
          map(x => x.payload)
        )
        .subscribe(contact => {
          const dialogData: IDialogData = {
            companyId: this.directoryContact.Company.Id,
            companyName: this.directoryContact.Company.Name,
            selectablePersons: contact.Entries,
            mandator: this.mandator,
            contactType: this.originContact.AddressType,
            selectedPersonId: this.directoryContact.Id ? this.directoryContact.Id : undefined,
          };
          this.subscriptions.add(
            this.dialogService
              .open(SelectPersonDialogComponent, { data: dialogData })
              .afterClosed()
              .subscribe((contactId: number) => {
                if (contactId === undefined) {
                  return;
                }
                if (contactId === 0) {
                  this.subscriptions.add(
                    this.addressBookService
                      .getCompanyContact(this.directoryContact.Company.Id)
                      .subscribe(contactResult => {
                        if (contactResult.successful) {
                          this.selectNewContact(contactResult.payload.Id);
                        }
                      })
                  );
                } else {
                  this.selectNewContact(contactId);
                }
              })
          );
        })
    );
  }

  public selectNewPerson(): void {
    this.subscriptions.add(
      this.dialogService
        .open(ContactSelectorDialogComponent, {
          data: {
            selectType: SelectContactTypeEnum.PERSON,
            isCompany: false,
            mandator: this.mandator,
          },
        })
        .afterClosed()
        .subscribe((contactId: number) => {
          if (contactId === undefined) {
            return;
          }
          this.selectNewContact(contactId);
        })
    );
  }

  public getTypeAheadType(): SelectContactTypeEnum {
    if (
      this.originContact.AddressType === 'Debitor' ||
      this.originContact.AddressType === 'Contact'
    ) {
      return SelectContactTypeEnum.COMPANY_PERSON;
    } else if (this.originContact.AddressType === 'Object') {
      return SelectContactTypeEnum.COMPANY_PERSON_ADDRESS;
    } else if (this.isExternalManaged) {
      return SelectContactTypeEnum.COMPANY_PERSON_EXTERNAL_MANAGED_ID;
    } else {
      return SelectContactTypeEnum.COMPANY;
    }
  }

  public selectNewContact(id: number): void {
    this.subscriptions.add(
      this.addressBookService.getContact(id).subscribe(newContact => {
        if (newContact.successful) {
          this.mapLoadDirectoryContact(newContact.payload);
          this.isSelectionChangeNotSaved = true;
          this.onContactSelected.emit(newContact.payload);
          this.cdr.detectChanges();
        }
      })
    );
  }

  public saveSelectionDisabled(): boolean {
    const buttonDisabled: boolean =
      !(this.isSelectionChangeNotSaved || this.addressTypeChanged) &&
      (isNullOrUndefined(this.directoryContact) ||
        !isNullOrUndefined(this.directoryContact.Person) ||
        !isNullOrUndefined(this.directoryContact.Company));
    if (this.prevSaveButtonDisabled !== buttonDisabled) {
      this.prevSaveButtonDisabled = buttonDisabled;
      this.onSaveButtonDisabled.emit(buttonDisabled);
    }
    return buttonDisabled;
  }

  public personEdit(): void {
    const dialogData: IEditPersonData = {
      person: cloneDeep(this.directoryContact.Person),
      contactId: this.directoryContact.Id,
      mandatorId: this.mandator.Id,
      addressType: this.originContact.AddressType,
      titleItems: this.titleItems,
    };
    this.subscriptions.add(
      this.dialogService
        .open(EditPersonContactDialogComponent, { data: dialogData })
        .afterClosed()
        .pipe(filter((data: IEditResult) => !isNullOrUndefined(data)))
        .subscribe((data: IEditResult) => {
          if (!isNullOrUndefined(data.contact)) {
            this.directoryContact.Person = data.contact as DirectoryPersonViewModel;
          }
        })
    );
  }

  public ExternalManagedContactChangeRequest(
    addressTypeConfig: IExternManagedAddressTypeConfig
  ): void {
    window.open(
      `mailto:${addressTypeConfig.receiverRequestEmail}${this.getMailArguments(addressTypeConfig)}`
    );
  }

  public getMailArguments(addressTypeConfig: IExternManagedAddressTypeConfig): string {
    const supportTitle: string = this.translate.instant(
      'COMMON.EXTERNAL_MANAGED_REQUEST_EMAIL_TITLE',
      { addressTypeName: addressTypeConfig.translation }
    );
    return `?subject=${supportTitle}&body=` + this.createSupportBody(addressTypeConfig);
  }

  public createSupportBody(addressTypeConfig: IExternManagedAddressTypeConfig): string {
    return (
      '%0D%0A%0D%0A' +
      '*************************************************************************************%0D%0A\n' +
      '%0D%0A' +
      'Contact:%0D%0A' +
      `  ContactId: ${this.getTextOrNotAvailable(this.directoryContact.Id)}%0D%0A` +
      `  ${addressTypeConfig.translation} Id: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.ThirdPartyCustomerNumber
      )}%0D%0A` +
      `  Sprache: ${this.getTextOrNotAvailable(this.directoryContact.Person?.Language)}%0D%0A` +
      `  Kommunikationsart: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.PreferredCommunicationType
      )}%0D%0A` +
      `  Anrede: ${this.getTextOrNotAvailable(this.directoryContact.Person?.Title)}%0D%0A` +
      `  Vorname: ${this.getTextOrNotAvailable(this.directoryContact.Person?.FirstName)}%0D%0A` +
      `  Nachname: ${this.getTextOrNotAvailable(this.directoryContact.Person?.LastName)}%0D%0A` +
      `  Firmenname: ${this.getTextOrNotAvailable(this.directoryContact.Company?.Name)}%0D%0A` +
      `  C/O: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.CareOfName ?? this.directoryContact.Company?.CareOfName
      )}%0D%0A` +
      `  Strasse: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.Street ?? this.directoryContact.Company?.Street
      )}%0D%0A` +
      `  Ort: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.ZipCode ?? this.directoryContact.Company?.ZipCode
      )} ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.City ?? this.directoryContact.Company?.City
      )}%0D%0A` +
      `  Postfach: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.PostOfficeBox ?? this.directoryContact.Company?.PostOfficeBox
      )}%0D%0A` +
      `  Email: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.PrimaryEmail?.Email ??
          this.directoryContact.Company?.PrimaryEmail?.Email
      )}%0D%0A` +
      `${this.getAdditionalEmails(
        this.directoryContact.Person?.PrimaryEmail?.Email
          ? this.directoryContact.Person?.AdditionalEmails
          : this.directoryContact.Company?.AdditionalEmails
      )}` +
      `  Telefon: ${this.getTextOrNotAvailable(
        this.directoryContact.Person?.PrimaryPhone?.PhoneNumber ??
          this.directoryContact.Company?.PrimaryPhone?.PhoneNumber
      )}%0D%0A` +
      `${this.getAdditionalPhones(
        this.directoryContact.Person?.PrimaryPhone?.PhoneNumber
          ? this.directoryContact.Person?.AdditionalPhones
          : this.directoryContact.Company?.AdditionalPhones
      )}` +
      '%0D%0A' +
      `URL: ${this.getTextOrNotAvailable(
        encodeURIComponent(window.location.origin) +
          '/addressbook/sap-debtor-detail/' +
          this.directoryContact.Id
      )}`
    );
  }

  public companyEdit(): void {
    const dialogData: IEditCompanyData = {
      company: cloneDeep(this.directoryContact.Company),
      contactId: this.directoryContact.Id,
      mandatorId: this.mandator.Id,
      addressType: this.originContact.AddressType,
    };
    this.subscriptions.add(
      this.dialogService
        .open(EditCompanyContactDialogComponent, { data: dialogData })
        .afterClosed()
        .pipe(filter((data: IEditResult) => !isNullOrUndefined(data)))
        .subscribe((data: IEditResult) => {
          if (!isNullOrUndefined(data.contact)) {
            this.directoryContact.Company = data.contact;
          }
        })
    );
  }

  public saveSelection(): void {
    this.onContactSave.emit(this.directoryContact);
  }

  public isSaved(): void {
    this.isSelectionChangeNotSaved = false;
    this.addressTypeChanged = false;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTextOrNotAvailable(txt: string | number): string {
    if (isNullOrUndefined(txt)) {
      return CommonUtil.getNotAvailable();
    }
    return '' + txt;
  }

  private getAdditionalEmails(additionalEmails: DirectoryEmailViewModel[]): string {
    if (isNullOrUndefined(additionalEmails) || additionalEmails.length === 0) {
      return '';
    }

    let additionalEmailsString: string = '';
    additionalEmails.forEach(x => {
      additionalEmailsString = additionalEmailsString + `         ${x.Email}%0D%0A`;
    });
    return '' + additionalEmailsString;
  }

  private getAdditionalPhones(additionalPhones: DirectoryPhoneViewModel[]): string {
    if (isNullOrUndefined(additionalPhones) || additionalPhones.length === 0) {
      return '';
    }

    let additionalPhonesString: string = '';
    additionalPhones.forEach(x => {
      additionalPhonesString = additionalPhonesString + `         ${x.PhoneNumber}%0D%0A`;
    });
    return '' + additionalPhonesString;
  }

  private mapLoadDirectoryContact(newContact: DirectoryContactViewModel): void {
    this.contactIsSelected = false;
    this.isPersonContactSelect = !isNullOrUndefined(newContact.Person);
    this.isCompanyContactSelect = !isNullOrUndefined(newContact.Company);
    this.isNotAdditionalAddress =
      this.originContact.AddressType === 'Debitor' ||
      this.originContact.AddressType === 'Contact' ||
      this.originContact.AddressType === 'Object';
    this.directoryContact = newContact;
    this.contactIsSelected = true;
  }
}
