import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LeadCompositeViewModel, NoteViewModel } from '../../../shared/apis/advis';
import { ModalLoaderIdTypesEnum } from '../../../shared/models/enum/modal-loader-id-types.enum';
import { NoteTypeEnum } from '../../../shared/models/enum/note-type.enum';
import { INoteType } from '../../note/note-type-select-dialog/note-type-select-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENT_OVERLAY_REF } from '@sitewerk/theia-ui-lib';
import { OverlayRef } from '@angular/cdk/overlay';
import { LeadNoteCardComponent } from '../../note/lead-note-card/lead-note-card.component';

@Component({
  selector: 'pc-lead-detail-notes-card',
  templateUrl: './lead-detail-notes-card.component.html',
  styleUrls: ['./lead-detail-notes-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadDetailNotesCardComponent implements OnChanges {
  @ViewChild(LeadNoteCardComponent, { static: false })
  public leadNoteCardComponent: LeadNoteCardComponent;
  readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;

  @Input() selectedNoteType: NoteTypeEnum;
  @Input() note: NoteViewModel = {};
  @Input() lead: LeadCompositeViewModel = {};
  @Input() hasProjectDescription: boolean;
  @Input() isFullScreenMode: boolean;
  @Input() selectedTag: string[] = [];
  @Input() selectedNote: NoteViewModel;

  public noteTypes: INoteType[] = [
    {
      name: this.translate.instant('DIALOG.SELECT_NOTE_TYPE.TEXT_NOTE_ADD'),
      icon: 'align-left',
      type: NoteTypeEnum.TEXT,
      cyAttr: 'addSelectTextNote',
    },
    {
      name: this.translate.instant('DIALOG.SELECT_NOTE_TYPE.DRAWING_NOTE_ADD'),
      icon: 'edit-3',
      type: NoteTypeEnum.DRAWING,
    },
  ];

  constructor(
    private translate: TranslateService,
    @Optional() @Inject(COMPONENT_OVERLAY_REF) public overlayRef: OverlayRef
  ) {}

  public onSetNoteType(type: NoteTypeEnum): void {
    this.note = { Text: '', NoteType: type, LeadId: this.lead.Lead.Id };
    this.selectedNoteType = type;
    this.leadNoteCardComponent?.closeOtherNotes(undefined);
  }

  public onBack(): void {
    this.note = {};
    this.selectedNoteType = undefined;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedNoteType) {
      this.onSetNoteType(this.selectedNoteType);
    }
  }
}
