<div *ngIf="tags && tags.length > 0 && tagFilter === undefined" class="d-flex">
  <helion-ui-select
    placeholder="{{ 'COMMON.TAGS' | translate }}"
    selectLook="white"
    [ngModel]="selectedTag"
    (ngModelChange)="filterTag($event)"
    class="button-toggle-group flex-50">
    <helion-ui-checkbox-option
      *ngFor="let tag of tags"
      [value]="tag"
      [label]="tag"
      [attr.data-note-tag]="tag">
    </helion-ui-checkbox-option>
  </helion-ui-select>
</div>
<div [ngClass]="isDragTag ? 'drag-mode' : ''" class="notes-container">
  <div
    pcDragulaItem
    class="{{ dragulaNoteTagsConfig.noteContainerClassName }}"
    *ngFor="let note of selectedNotes; trackBy: trackNoteListItem; let last = last; let i = index"
    data-cy="leadEditItemNote"
    [dragula]="dragulaNoteTagsGroupName"
    [attr.note-index]="i">
    <pc-note
      (onEditModeChange)="closeOtherNotes($event)"
      [isNotesOnFullScreen]="isNotesOnFullScreen"
      [readOnly]="readOnly"
      [tags]="tags"
      [hiddenSystemTag]="hiddenSystemTag"
      [isNew]="!note.Id"
      [isTaggedNote]="true"
      [note]="note"></pc-note>
  </div>
</div>
