import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class MainSidenavService {
  private mainSidenav: MatSidenav;

  setSidenav(sidenav: MatSidenav): void {
    this.mainSidenav = sidenav;
  }

  toggle(): void {
    this.mainSidenav.toggle();
  }
}
