import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';

import * as RootReducer from '../../../shared/states/index';
import { Store } from '@ngrx/store';
import {
  LeadCompositeViewModel,
  LeadSiblingsViewModel,
  MasterDataUserViewModel,
  MasterDataViewModel,
} from '../../../shared/apis/advis';
import { Subscription, Observable } from 'rxjs';
import { LeadSiblingsNotificationTypeEnum } from '../../../shared/components/lead-siblings-notification/lead-siblings-notification.component';
import { take } from 'rxjs/operators';
import { AddressDialogComponent } from '../../../address-book-shared/dialog/address-dialog/address-dialog.component';
import { MediaObserver } from '@angular/flex-layout';
import { LoadLeadDocumentTemplatesAction } from 'app/shared/states/lead-document/lead-document.action';
import { DialogService, DrawerComponent } from '@sitewerk/theia-ui-lib';
import { LeadDetailNotesCardComponent } from '../../../lead-detail/lead-detail/lead-detail-notes-card/lead-detail-notes-card.component';
import { IProjectDescriptionViewModel } from '../../../shared/models/project-description-view-model';
import {
  IUpdateLeadComplexityTypeActionPayload,
  UpdateLeadComplexityTypeAction,
} from '../../../shared/states/lead/lead.action';
import {
  ILeadComplexityTypeDialogData,
  LeadComplexityTypeDialogComponent,
} from '../../dialog/lead-complexity-type/lead-complexity-type-dialog.component';
import { PermissionService } from '../../../shared/services/permission.service';

@Component({
  selector: 'pc-lead-action-bar',
  templateUrl: './lead-action-bar.component.html',
  styleUrls: ['./lead-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadActionBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DrawerComponent, { static: false }) public notesSideNav: DrawerComponent;
  @ViewChild('notesButton', { static: false }) public notesButton: ElementRef;
  private subscription: Subscription = new Subscription();
  private hasProjectDescription: boolean = false;

  userMap$: Observable<Map<string, MasterDataUserViewModel>> = this.store.select<
    Map<string, MasterDataUserViewModel>
  >(RootReducer.getGlobalUserMap);
  tradeTypeCode: string;
  lead: LeadCompositeViewModel = undefined;
  leadSiblingsNotificationIconEnum: typeof LeadSiblingsNotificationTypeEnum =
    LeadSiblingsNotificationTypeEnum;
  masterData: MasterDataViewModel;
  isMobile: boolean = false;
  leadSiblings: LeadSiblingsViewModel;

  @Input() title: string = undefined;
  @Input() hideNotes: boolean = false;
  @Output() public onEditComplexityType: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dialogService: DialogService,
    private media: MediaObserver,
    private store: Store<RootReducer.IState>,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    public permissions: PermissionService
  ) {
    // empty
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(RootReducer.getLeadDetailEntity)
        .subscribe((lead: LeadCompositeViewModel) => {
          if (isEmpty(lead) && this.isCustom()) {
            return;
          }
          this.lead = clone(lead);
          this.tradeTypeCode = this.lead.Lead.TradeTypeCode;
          this.cdr.detectChanges();

          this.store.dispatch(
            new LoadLeadDocumentTemplatesAction({
              leadId: lead.Lead.Id,
              language: lead.Lead.Language,
            })
          );
        })
    );

    this.subscription.add(
      this.store.select(RootReducer.getLeadSiblingsEntity).subscribe((s: LeadSiblingsViewModel) => {
        this.leadSiblings = s;
        this.cdr.detectChanges();
      })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getGlobalMasterData)
        .pipe(take(1))
        .subscribe((masterData: MasterDataViewModel) => {
          this.masterData = masterData;
          this.cdr.detectChanges();
        })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getProjectProperties)
        .subscribe(
          (properties: IProjectDescriptionViewModel) =>
            (this.hasProjectDescription = properties && !properties.isDefault)
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.media.asObservable().subscribe(() => {
        this.isMobile = this.media.isActive('lt-lg');
      })
    );

    (
      Array.from(
        this.elementRef.nativeElement.querySelectorAll(`button[fill-type="icon"]`) ?? []
      ).pop() as HTMLButtonElement
    )?.after(this.notesButton.nativeElement);
  }

  onAddressClick(): void {
    this.dialogService.open(AddressDialogComponent);
  }

  public onEditComplexityTypeClicked(): void {
    if (this.permissions.userPermissions.CanSetComplexityType)
      this.subscription.add(
        this.dialogService
          .open(LeadComplexityTypeDialogComponent, {
            data: {
              leadComplexityType: this.lead.Lead.ComplexityType,
              mandantId: this.lead.Lead.MandantId,
              tradeTypeCode: this.lead.Lead.TradeTypeCode,
            } as ILeadComplexityTypeDialogData,
          })
          .afterClosed()
          .subscribe((complexityType: string = undefined) => {
            if (complexityType === undefined || complexityType === this.lead.Lead.ComplexityType) {
              return;
            }

            const payload: IUpdateLeadComplexityTypeActionPayload = {
              leadId: this.lead.Lead.Id,
              complexityType: complexityType,
            };
            this.store.dispatch(new UpdateLeadComplexityTypeAction(payload));
          })
      );
  }

  public onNotesSideNavToggle(): void {
    this.notesSideNav.onToggleDrawer();
    this.notesSideNav.compRef.setInput('lead', this.lead);
    this.notesSideNav.compRef.setInput('hasProjectDescription', this.hasProjectDescription);
  }

  private isCustom(): boolean {
    return !!this.title;
  }

  protected readonly drawerContentComponent = LeadDetailNotesCardComponent;
}
