/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LeadStatusViewModel } from './leadStatusViewModel';


export interface LeadActionViewModel { 
    ActionType?: LeadActionViewModel.ActionTypeEnum;
    NextStageKey?: string;
    AllowedNextStatuses?: Array<LeadStatusViewModel>;
}
export namespace LeadActionViewModel {
    export type ActionTypeEnum = 'Abort' | 'Hold' | 'Win' | 'WinWithVariant' | 'Release' | 'ContactSuccess' | 'ContactFail' | 'BoSent' | 'NboSent' | 'PayVariant' | 'Handover' | 'Triage' | 'Transfer' | 'TransferBack' | 'Delegate' | 'SendBackToOwner' | 'AssignProject' | 'StartProject' | 'FinishProject' | 'PutFinishedProjectBackToInProgress' | 'ArchiveProject' | 'PutArchivedProjectBackToAccounted' | 'ReleaseProject' | 'PutWonBackToFollowupBo' | 'AccountProject' | 'PutAccountedBackToFinished' | 'StartOrder' | 'OrderBackToFollowUp';
    export const ActionTypeEnum = {
        Abort: 'Abort' as ActionTypeEnum,
        Hold: 'Hold' as ActionTypeEnum,
        Win: 'Win' as ActionTypeEnum,
        WinWithVariant: 'WinWithVariant' as ActionTypeEnum,
        Release: 'Release' as ActionTypeEnum,
        ContactSuccess: 'ContactSuccess' as ActionTypeEnum,
        ContactFail: 'ContactFail' as ActionTypeEnum,
        BoSent: 'BoSent' as ActionTypeEnum,
        NboSent: 'NboSent' as ActionTypeEnum,
        PayVariant: 'PayVariant' as ActionTypeEnum,
        Handover: 'Handover' as ActionTypeEnum,
        Triage: 'Triage' as ActionTypeEnum,
        Transfer: 'Transfer' as ActionTypeEnum,
        TransferBack: 'TransferBack' as ActionTypeEnum,
        Delegate: 'Delegate' as ActionTypeEnum,
        SendBackToOwner: 'SendBackToOwner' as ActionTypeEnum,
        AssignProject: 'AssignProject' as ActionTypeEnum,
        StartProject: 'StartProject' as ActionTypeEnum,
        FinishProject: 'FinishProject' as ActionTypeEnum,
        PutFinishedProjectBackToInProgress: 'PutFinishedProjectBackToInProgress' as ActionTypeEnum,
        ArchiveProject: 'ArchiveProject' as ActionTypeEnum,
        PutArchivedProjectBackToAccounted: 'PutArchivedProjectBackToAccounted' as ActionTypeEnum,
        ReleaseProject: 'ReleaseProject' as ActionTypeEnum,
        PutWonBackToFollowupBo: 'PutWonBackToFollowupBo' as ActionTypeEnum,
        AccountProject: 'AccountProject' as ActionTypeEnum,
        PutAccountedBackToFinished: 'PutAccountedBackToFinished' as ActionTypeEnum,
        StartOrder: 'StartOrder' as ActionTypeEnum,
        OrderBackToFollowUp: 'OrderBackToFollowUp' as ActionTypeEnum
    };
}
