export enum StageE {
  NEW = <any>'NEW',
  ASSIGNED_NBO = <any>'ASSIGNED_NBO',
  FOLLOWUP_NBO = <any>'FOLLOWUP_NBO',
  UNREACHED_NBO = <any>'UNREACHED_NBO',
  NBO_SENT = <any>'NBO_SENT',
  HANDOVER = <any>'HANDOVER',
  ASSIGNED_BO = <any>'ASSIGNED_BO',
  FOLLOWUP_BO = <any>'FOLLOWUP_BO',
  UNREACHED_BO = <any>'UNREACHED_BO',
  BO_SENT = <any>'BO_SENT',
  ORDER_ENTRY = <any>'ORDER_ENTRY',
  WON = <any>'WON',
  ABORT = <any>'ABORT',
  ON_HOLD = <any>'ON_HOLD',
  PROJECT_ASSIGNED = <any>'PROJECT_ASSIGNED',
  PROJECT_IN_PROGRESS = <any>'PROJECT_IN_PROGRESS',
  PROJECT_ARCHIVED = <any>'PROJECT_ARCHIVED',
  WON_ARCHIVED = <any>'WON_ARCHIVED',
  PROJECT_IN_OPERATION = <any>'PROJECT_IN_OPERATION',
  PROJECT_ACCOUNTED = <any>'PROJECT_ACCOUNTED',
  MIGRATION = <any>'MIGRATION',
}
