import { Injectable } from '@angular/core';

export class Logger {
  constructor(private name: string) {
    this.name = name + ': ';
  }

  public error(message?: any, ...optionalParams: any[]): void {
    // this.logger.error(this.name + message, ...optionalParams);
    console.error(this.name + message, ...optionalParams);
  }

  public warn(message?: any, ...optionalParams: any[]): void {
    // this.logger.warn(this.name + message, ...optionalParams);
    console.warn(this.name + message, ...optionalParams);
  }

  public info(message?: any, ...optionalParams: any[]): void {
    // this.logger.info(this.name + message, ...optionalParams);
    console.info(this.name + message, ...optionalParams);
  }

  public debug(message?: any, ...optionalParams: any[]): void {
    // this.logger.debug(this.name + message, ...optionalParams);
    console.debug(this.name + message, ...optionalParams);
  }

  public log(message?: any, ...optionalParams: any[]): void {
    // this.logger.log(this.name + message, ...optionalParams);
    console.log(this.name + message, ...optionalParams);
  }
}

@Injectable()
export class LoggerService {
  constructor() {
    // empty
  }

  public create(className: string): Logger {
    return new Logger(className);
  }
}
