/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MtSapRequest { 
    ContactId?: string;
    CustomerId?: string;
    OpportunityId?: string;
    VariantId?: number;
    ContactName?: string;
    ContactEmail?: string;
    HydraulicSchemeId?: string;
    ReferencedDocumentsText?: string;
    OfferText?: string;
    SapMailBehavior: MtSapRequest.SapMailBehaviorEnum;
}
export namespace MtSapRequest {
    export type SapMailBehaviorEnum = 'Automatic' | 'Manually';
    export const SapMailBehaviorEnum = {
        Automatic: 'Automatic' as SapMailBehaviorEnum,
        Manually: 'Manually' as SapMailBehaviorEnum
    };
}
