import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import {
  ContactDuplicateAndTypeaheadViewModel,
  DirectoryService,
} from '../../../shared/apis/advis';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { ModalLoaderIdTypesEnum } from '../../../shared/models/enum/modal-loader-id-types.enum';
import cloneDeep from 'lodash/cloneDeep';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationService, TypeE } from '../../../shared/services/notification.service';
import { IExternManagedAddressTypeConfig } from '../../../shared/utils/exter-managed-address-type.util';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';

export interface IDetailSearchDialogData {
  mandatorIds: string;
  language: 'DE' | 'FR' | 'IT' | 'EN';
  searchExternalManagedAddress: boolean;
  externalManagedAddress?: IExternManagedAddressTypeConfig;
}

interface IHeaderConfig {
  prop: string;
  name: string;
}

interface IRowData {
  ContactId: number;
  DisplayContactName: string;
  DisplayContactDetail: string;
  IsExternalManagedContact: boolean;
  DataState: number;
}

@Component({
  selector: 'pc-address-detail-search-dialog',
  templateUrl: './address-detail-search-dialog.component.html',
  styleUrls: ['./address-detail-search-dialog.component.scss'],
})
export class AddressDetailSearchDialogComponent implements OnDestroy {
  @ViewChild('tableWrapper', { static: true }) public tableWrapper: any;
  @ViewChild('leadTable', { static: true }) public table: DatatableComponent;

  public readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;

  public filter: {
    FirstName: string;
    LastName: string;
    CompanyName: string;
    AdditionalCompanyName: string;
    Street: string;
    ZipCode: string;
    City: string;
    ThirdPartyCustomerNumber: string;
  } = {
    FirstName: '',
    LastName: '',
    CompanyName: '',
    AdditionalCompanyName: '',
    Street: '',
    ZipCode: '',
    City: '',
    ThirdPartyCustomerNumber: '',
  };

  public activeFilter: {
    FirstName: string;
    LastName: string;
    CompanyName: string;
    AdditionalCompanyName: string;
    Street: string;
    ZipCode: string;
    City: string;
    ThirdPartyCustomerNumber: string;
  } = {
    FirstName: '',
    LastName: '',
    CompanyName: '',
    AdditionalCompanyName: '',
    Street: '',
    ZipCode: '',
    City: '',
    ThirdPartyCustomerNumber: '',
  };

  public rowsPerPage: number = 5;
  public searchBox: string;
  public rows: IRowData[] = [];
  public columns: IHeaderConfig[] = [
    { prop: 'DisplayContactName', name: this.translate.instant('LEADS.TABLE_HEADER.NAME') },
    { prop: 'DisplayContactDetail', name: this.translate.instant('LEADS.TABLE_HEADER.DETAIL') },
  ];

  public tableMessage: Object = {
    emptyMessage: this.translate.instant('COMMON.NO_DATA'),
    totalMessage: this.translate.instant('COMMON.TABLE_TOTAL_MESSAGE'),
  };

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: IDetailSearchDialogData,
    private translate: TranslateService,
    private loadingModalService: LoadingModalService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private directory: DirectoryService
  ) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public hasFilterValue(): boolean {
    return (
      this.filter.FirstName.length !== this.activeFilter.FirstName.length ||
      this.filter.LastName.length !== this.activeFilter.LastName.length ||
      this.filter.CompanyName.length !== this.activeFilter.CompanyName.length ||
      this.filter.AdditionalCompanyName.length !== this.activeFilter.AdditionalCompanyName.length ||
      this.filter.Street.length !== this.activeFilter.Street.length ||
      this.filter.ZipCode.length !== this.activeFilter.ZipCode.length ||
      this.filter.City.length !== this.activeFilter.City.length ||
      this.filter.ThirdPartyCustomerNumber.length !==
        this.activeFilter.ThirdPartyCustomerNumber.length
    );
  }

  public search(): void {
    this.loadingModalService.openModal(this.modalLoaderIdTypesEnum.LEAD_ADDRESS_DETAIL_SEARCH);
    this.subscription.add(
      this.directory
        .directoryDirectoryContactDetailSearch(
          this.data.mandatorIds,
          !this.data.searchExternalManagedAddress ?? true,
          this.filter.FirstName,
          this.filter.LastName,
          this.filter.CompanyName,
          this.filter.AdditionalCompanyName,
          this.filter.Street,
          this.filter.ZipCode,
          this.filter.City,
          this.filter.ThirdPartyCustomerNumber,
          undefined,
          true
        )
        .subscribe(
          (c: ContactDuplicateAndTypeaheadViewModel[]) => {
            this.activeFilter = cloneDeep(this.filter);
            this.loadingModalService.closeModal(
              this.modalLoaderIdTypesEnum.LEAD_ADDRESS_DETAIL_SEARCH
            );
            this.rows = c.map((x: ContactDuplicateAndTypeaheadViewModel) => {
              return {
                ContactId: x.ContactId,
                DisplayContactName: x.ContactDisplayName,
                DisplayContactDetail: x.ContactDetailDisplayName,
                IsExternalManagedContact: x.IsExternalManaged,
                DataState: x.DataState,
              };
            });
          },
          () => {
            this.subscription.add(
              this.dialogService
                .openAlert(this.translate.instant('ERROR.LOAD_FAILED'))
                .afterClosed()
                .subscribe()
            );
            this.loadingModalService.closeModal(
              this.modalLoaderIdTypesEnum.LEAD_ADDRESS_DETAIL_SEARCH
            );
          }
        )
    );
  }

  public onActionModify(row: IRowData): void {
    if (this.data.searchExternalManagedAddress && !row.IsExternalManagedContact) {
      this.subscription.add(
        this.dialogService
          .openConfirm({
            message: this.translate.instant(
              'ADDRESS.DIALOGS.SEARCH.ADD_EXTERNAL_MANAGED_ADDRESS.MESSAGE',
              {
                addressTypeName: this.data.externalManagedAddress.translation,
              }
            ),
            title: this.translate.instant(
              'ADDRESS.DIALOGS.SEARCH.ADD_EXTERNAL_MANAGED_ADDRESS.TITLE',
              {
                addressTypeName: this.data.externalManagedAddress.translation,
              }
            ),
          })
          .afterClosed()
          .pipe(filter(ok => ok))
          .subscribe(() => {
            this.subscription.add(
              this.directory
                .directoryCreateExternalManagedFromExistsContact(
                  row.ContactId,
                  this.data.externalManagedAddress.addressType,
                  this.data.language
                )
                .subscribe(
                  c => {
                    this.loadingModalService.closeModal();
                    this.notificationService.notifySimple(
                      this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_SUCCESS', {
                        addressTypeName: this.data.externalManagedAddress.translation,
                      }),
                      TypeE.PRIMARY
                    );
                    this.dialogRef.close(c.Id);
                  },
                  () => {
                    this.loadingModalService.closeModal();
                    this.notificationService.notifySimple(
                      this.translate.instant('ADDRESS.CONTACT_COPY_TO_EXTERNAL_MANAGED_FAILED', {
                        addressTypeName: this.data.externalManagedAddress.translation,
                      }),
                      TypeE.ALERT
                    );
                  }
                )
            );
          })
      );
    } else {
      this.dialogRef.close(row.ContactId);
    }
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }
}
