/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MtCrmParameters { 
    Crm_CustomerNr?: string;
    Crm_VkNr?: string;
    Crm_ApNr?: string;
    Crm_CustomerFirstName?: string;
    Crm_CustomerLastName?: string;
    Crm_CustomerEmail?: string;
    Crm_CustomerLanguage?: MtCrmParameters.CrmCustomerLanguageEnum;
    Crm_GroupId?: string;
}
export namespace MtCrmParameters {
    export type CrmCustomerLanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const CrmCustomerLanguageEnum = {
        DE: 'DE' as CrmCustomerLanguageEnum,
        FR: 'FR' as CrmCustomerLanguageEnum,
        IT: 'IT' as CrmCustomerLanguageEnum,
        EN: 'EN' as CrmCustomerLanguageEnum
    };
}
