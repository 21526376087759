/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PutPvBuildingData } from './putPvBuildingData';
import { PvWizardViewModel } from './pvWizardViewModel';


export interface PvAutoOfferRequest { 
    BuildingData?: PutPvBuildingData;
    ObjectData?: PvWizardViewModel;
    ProductLineType?: PvAutoOfferRequest.ProductLineTypeEnum;
    OfferTemplatePath?: string;
}
export namespace PvAutoOfferRequest {
    export type ProductLineTypeEnum = 'Basic' | 'Advanced' | 'HighEnd' | 'SingleArticle' | 'LargeScale';
    export const ProductLineTypeEnum = {
        Basic: 'Basic' as ProductLineTypeEnum,
        Advanced: 'Advanced' as ProductLineTypeEnum,
        HighEnd: 'HighEnd' as ProductLineTypeEnum,
        SingleArticle: 'SingleArticle' as ProductLineTypeEnum,
        LargeScale: 'LargeScale' as ProductLineTypeEnum
    };
}
