<div *ngIf="langSelectorType === langSelectorTypeEnum.LIST">
  <button class="button language-selector" [cdkMenuTriggerFor]="menu">
    {{ selectedLang }}
  </button>
  <ng-template #menu>
    <helion-ui-menu>
      <button
        class="menu-item"
        cdkMenuItem
        *ngFor="let lang of langs"
        (click)="onLanguageChange(lang.value)">
        {{ lang.viewValue }}
      </button>
    </helion-ui-menu>
  </ng-template>
</div>
<div
  *ngIf="langSelectorType === langSelectorTypeEnum.FLAT_ROW"
  class="flat-row-language-selector"
  fxLayout="row"
  fxLayoutGap="10px">
  <button
    class="button"
    [ngClass]="{ 'language-select': lang.isSelected, 'language-unselect': !lang.isSelected }"
    *ngFor="let lang of langs"
    (click)="onLanguageChange(lang.value)">
    {{ lang.viewValue }}
  </button>
</div>
