<div class="d-flex justify-content-between">
  <div *ngIf="tags && tags.length > 0 && tagFilter === undefined" style="width: 49%">
    <helion-ui-select
      placeholder="{{ 'COMMON.TAGS' | translate }}"
      selectLook="white"
      [ngModel]="selectedTags"
      (ngModelChange)="filterTag($event)"
      class="button-toggle-group flex-50">
      <helion-ui-checkbox-option
        *ngFor="let tag of tags"
        [value]="tag"
        [label]="tag"
        [attr.data-note-tag]="tag">
      </helion-ui-checkbox-option>
    </helion-ui-select>
  </div>
  <div class="filter-container-notes" style="width: 49%">
    <helion-ui-select
      placeholder="{{ 'NOTES.TARGETGROUP_FILTER' | translate }}"
      [(ngModel)]="selectedTargetGroupFilter"
      (ngModelChange)="filterTargetGroup($event)"
      selectLook="white">
      <helion-ui-checkbox-option
        *ngFor="let targetRole of this.roleTargetGroups"
        [value]="targetRole.RoleId"
        [isChecked]="selectedTargetGroupFilter.indexOf(targetRole.RoleId) > -1"
        [label]="this.translateRoleTargetGroup(targetRole.RoleId)">
      </helion-ui-checkbox-option>
    </helion-ui-select>
  </div>
</div>

<div id="notes-print-content">
  <div [ngClass]="isDragTag ? 'drag-mode' : ''" class="notes-container">
    <div
      pcDragulaItem
      class="{{ dragulaNoteTagsConfig.noteContainerClassName }}"
      *ngFor="let note of selectedNotes; trackBy: trackNoteListItem; let last = last; let i = index"
      data-cy="leadEditItemNote"
      [dragula]="dragulaNoteTagsGroupName"
      [attr.note-index]="i">
      <pc-note
        (onEditModeChange)="closeOtherNotes($event)"
        [isNotesOnFullScreen]="isNotesOnFullScreen"
        [readOnly]="readOnly"
        [tags]="tags"
        [hiddenSystemTag]="hiddenSystemTag"
        [isNew]="!note.Id"
        [isTaggedNote]="true"
        [note]="note"
        [translateRoleTargetGroup]="this.translateRoleTargetGroup"></pc-note>
    </div>
    <div *ngIf="showWarningFilterApplied">
      <br />
      {{ 'NOTES.TARGETGROUP_FILTER_WARNING' | translate }}
    </div>
  </div>
</div>
