import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, Observable, of, toArray } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as LeadProjectActions from './lead-project.action';
import {
  DeleteProjectDocumentation,
  IDeleteProjectDocumentationPayload,
  IPatchDocumentationPayload,
  PatchProjectDocumentation,
  ProjectSopChecklistAddStepAction,
} from './lead-project.action';
import {
  LeadChecklistService,
  LeadCompositeViewModel,
  LeadFileService,
  LeadService,
  PatchProjectDocumentationRequest,
  SopChecklistPhaseViewModel,
  SopChecklistStepViewModel,
  SopChecklistViewModel,
  SopPhaseViewModel,
} from '../../apis/advis';
import * as LeadDocument from '../lead-document/lead-document.action';
import * as LeadAction from '../lead/lead.action';
import { ConfigService } from 'app/shared/services/config.service';
import { NotificationService, TypeE } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectChecklistStepViewModel } from '../../apis/advis/model/projectChecklistStepViewModel';

@Injectable()
export class LeadProjectEffects {
  protected patchProjectDocumentation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.PATCH_PROJECT_DOCUMENTATION),
      switchMap((action: PatchProjectDocumentation) => {
        return this.patchProjectDocument(action.payload);
      })
    )
  );

  protected deleteProjectDocumentation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.DELETE_PROJECT_DOCUMENTATION),
      switchMap((action: DeleteProjectDocumentation) => {
        return this.deleteProjectDocument(action.payload);
      })
    )
  );

  protected createProjectChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.CREATE_PROJECT_CHECKLIST),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistCreateAction) => action.payload),
      switchMap(payload =>
        this.leadChecklistService
          .projectChecklistCreateChecklistFromTemplate(payload.leadId, payload.templateId)
          .pipe(
            map(checklist => new LeadProjectActions.ProjectChecklistCreateSuccessAction(checklist)),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistCreateFailureAction(error))
            )
          )
      )
    )
  );

  protected loadProjectChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.LOAD_PROJECT_CHECKLIST),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistLoadAction) => action.payload),
      switchMap(leadId =>
        this.leadChecklistService.projectChecklistGetChecklistForLead(leadId).pipe(
          map(checklist => new LeadProjectActions.ProjectChecklistLoadSuccessAction(checklist)),
          catchError(error => of(new LeadProjectActions.ProjectChecklistLoadFailureAction(error)))
        )
      )
    )
  );

  protected loadProjectChecklistTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.LOAD_PROJECT_CHECKLIST_TEMPLATES),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistTemplatesLoadAction) => action.payload),
      switchMap(leadId =>
        this.leadChecklistService
          .projectChecklistGetAvailableChecklistTemplatesForLead(leadId)
          .pipe(
            map(
              templates =>
                new LeadProjectActions.ProjectChecklistTemplatesLoadSuccessAction(templates)
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistTemplatesLoadFailureAction(error))
            )
          )
      )
    )
  );

  protected clickProjectChecklistStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistClickStepAction) => action.payload),
      switchMap(payload =>
        this.leadChecklistService
          .projectChecklistClickChecklistStep(payload.leadId, payload.stepId)
          .pipe(
            map(
              checklist => new LeadProjectActions.ProjectChecklistClickStepSuccessAction(checklist)
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistClickStepFailureAction(error))
            )
          )
      )
    )
  );

  protected clickProjectChecklistStepAndUpdateRelatedSopSteps$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LeadProjectActions.CLICK_PROJECT_CHECKLIST_STEP_AND_UPDATE_SOP_STEP),
        debounceTime(this.config.apiDebounceTimeMs),
        map((action: LeadProjectActions.ProjectChecklistClickStepWithSopAction) => action.payload),
        switchMap(payload =>
          this.leadChecklistService
            .projectChecklistClickChecklistStepWithSop(payload.leadId, payload.stepId)
            .pipe(
              map(
                checklist =>
                  new LeadProjectActions.ProjectChecklistClickStepWithSopSuccessAction(
                    this.prepareSopChecklist(checklist)
                  )
              ),
              catchError(error =>
                of(new LeadProjectActions.ProjectChecklistClickStepWithSopFailureAction(error))
              )
            )
        )
      )
  );

  protected clickProjectChecklistSopStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_STEP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistClickSopStepAction) => action.payload),
      switchMap(payload =>
        this.leadChecklistService
          .projectChecklistClickSopStep(payload.leadId, payload.stepId, payload.desiredCheckedState)
          .pipe(
            map(
              checklist =>
                new LeadProjectActions.ProjectChecklistClickSopStepSuccessAction(
                  this.prepareSopChecklist(checklist)
                )
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistClickSopStepFailureAction(error))
            )
          )
      )
    )
  );

  protected clickProjectChecklistSopPhase$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.CLICK_PROJECT_CHECKLIST_SOP_PHASE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistClickSopPhaseAction) => action.payload),
      switchMap(payload =>
        this.leadChecklistService
          .projectChecklistClickSopPhase(
            payload.leadId,
            payload.phaseKey,
            payload.desiredCheckedState
          )
          .pipe(
            map(
              checklist =>
                new LeadProjectActions.ProjectChecklistClickSopPhaseSuccessAction(
                  this.prepareSopChecklist(checklist)
                )
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistClickSopPhaseFailureAction(error))
            )
          )
      )
    )
  );

  protected patchProjectChecklistPhase$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.PATCH_PROJECT_CHECKLIST_PHASE),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectChecklistPatchPhaseAction) => action.payload),
      switchMap(payload =>
        this.leadChecklistService
          .projectChecklistPatchChecklistPhase(payload.leadId, payload.request)
          .pipe(
            map(
              checklist => new LeadProjectActions.ProjectChecklistPatchPhaseSuccessAction(checklist)
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectChecklistPatchPhaseFailureAction(error))
            )
          )
      )
    )
  );

  protected loadProjectSopChecklist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.LOAD_PROJECT_SOP_CHECKLIST),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectSopChecklistLoadAction) => action.payload),
      switchMap(leadId =>
        this.leadChecklistService.projectChecklistGetSopChecklistForLead(leadId).pipe(
          map(
            (checklist: SopChecklistViewModel) =>
              new LeadProjectActions.ProjectSopChecklistLoadSuccessAction(
                this.prepareSopChecklist(checklist)
              )
          ),
          catchError(error =>
            of(new LeadProjectActions.ProjectSopChecklistLoadFailureAction(error))
          )
        )
      )
    )
  );

  protected addCustomSopChecklistStep: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.ADD_CUSTOM_SOP_CHECKLIST_STEP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: ProjectSopChecklistAddStepAction) => action.payload),
      switchMap(payload => {
        const { leadId, ...body } = payload;
        return this.leadChecklistService.projectChecklistStepPostCustomStep(leadId, body).pipe(
          map(
            sopChecklist =>
              new LeadProjectActions.ProjectSopChecklistAddStepSuccessAction(sopChecklist)
          ),
          catchError(error =>
            of(new LeadProjectActions.ProjectSopChecklistAddStepFailureAction(error))
          )
        );
      })
    )
  );

  protected editCustomSopChecklistStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.EDIT_CUSTOM_SOP_CHECKLIST_STEP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectSopChecklistEditStepAction) => action.payload),
      switchMap((payload: any) => {
        return this.leadChecklistService
          .projectChecklistStepPatchCustomStep(payload.leadId, payload.stepId, payload.body)
          .pipe(
            map(
              sopChecklist =>
                new LeadProjectActions.ProjectSopChecklistEditStepSuccessAction(sopChecklist)
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectSopChecklistEditStepFailureAction(error))
            )
          );
      })
    )
  );

  protected deleteCustomSopChecklistStep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadProjectActions.DELETE_CUSTOM_SOP_CHECKLIST_STEP),
      debounceTime(this.config.apiDebounceTimeMs),
      map((action: LeadProjectActions.ProjectSopChecklistDeleteStepAction) => action.payload),
      switchMap((payload: any) => {
        return this.leadChecklistService
          .projectChecklistStepDeleteCustomStep(payload.leadId, payload.stepId)
          .pipe(
            map(
              sopChecklist =>
                new LeadProjectActions.ProjectSopChecklistDeleteStepSuccessAction(sopChecklist)
            ),
            catchError(error =>
              of(new LeadProjectActions.ProjectSopChecklistDeleteStepFailureAction(error))
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private leadService: LeadService,
    private leadFileService: LeadFileService,
    private config: ConfigService,
    private leadChecklistService: LeadChecklistService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  private patchProjectDocument(payload: IPatchDocumentationPayload): Observable<Action> {
    const uploadObservables = payload.documents.map(document => {
      return document.file
        ? this.leadFileService.leadFileUploadFile(payload.leadId, document.file)
        : this.leadFileService.leadFileUploadExistingFile(payload.leadId, document.fileToken);
    });
    const uploadResults$ = concat(...uploadObservables).pipe(toArray());

    return uploadResults$.pipe(
      switchMap(() => {
        const body: PatchProjectDocumentationRequest = {
          Documents: payload.documents.map(document => {
            return {
              ProjectDocumentId: document.documentId,
              DocumentType: document.documentType,
              FileName: document.fileName,
            };
          }),
        };

        return this.leadService.leadPatchLeadProjectDocumentation(payload.leadId, body);
      }),
      mergeMap((updatedLead: LeadCompositeViewModel) => [
        new LeadProjectActions.PatchProjectSuccess(),
        new LeadDocument.GetFilesAction({ leadId: payload.leadId }),
        new LeadAction.DetailLoadSuccessAction(updatedLead),
      ]),
      catchError(() => of(new LeadProjectActions.PatchProjectFailed()))
    );
  }

  private deleteProjectDocument(payload: IDeleteProjectDocumentationPayload): Observable<Action> {
    const deleteObservables = payload.fileNames.map(fileName =>
      this.leadService.leadDeleteLeadProjectDocumentation(payload.leadId, fileName)
    );

    const deleteProjectDocument$ = concat(...deleteObservables).pipe(toArray());

    return deleteProjectDocument$.pipe(
      mergeMap(updatedLead => [
        new LeadProjectActions.DeleteProjectDocumentationSuccess(),
        new LeadDocument.GetFilesAction({ leadId: payload.leadId }),
        new LeadAction.DetailLoadSuccessAction(updatedLead[updatedLead.length - 1]),
      ]),
      catchError(() => {
        this.notificationService.notifySimple(
          this.translate.instant('LEAD_DETAIL.PROJECT_DOCUMENTATION.DELETE_FAILED'),
          TypeE.ALERT
        );
        return of(new LeadProjectActions.DeleteProjectDocumentationFailed());
      })
    );
  }

  private prepareSopChecklist(checklist: SopChecklistViewModel): ISopChecklistViewModel {
    return {
      ...checklist,
      SopChecklistPhases: checklist.SopChecklistPhases.map((phase: SopChecklistPhaseViewModel) => ({
        ...phase,
        Notes: phase.Notes ?? '',
        SopSteps: phase.SopSteps.map((sopStep: SopChecklistStepViewModel) => ({
          ...sopStep,
          ChecklistSteps: sopStep.ChecklistSteps.filter(
            (step: ProjectChecklistStepViewModel) => !step.ParentId
          ).map((step: ProjectChecklistStepViewModel) => ({
            ...step,
            SopStepId: sopStep.Id,
            SopPhase: sopStep.Phase,
            SubSteps: sopStep.ChecklistSteps.filter(
              (step: ProjectChecklistStepViewModel) => !!step.ParentId
            )
              .filter((child: ProjectChecklistStepViewModel) => child.ParentId === step.Id)
              .map(step => ({
                ...step,
                SopStepId: sopStep.Id,
                SopPhase: sopStep.Phase,
              })),
            FinishedSubStepsNumber: sopStep.ChecklistSteps.filter(
              (child: ProjectChecklistStepViewModel) =>
                child.ParentId === step.Id &&
                child.CheckedState === ProjectChecklistStepViewModel.CheckedStateEnum.Checked
            ).length,
          })),
          IsVisibleToCustomer: sopStep.ChecklistSteps.some(
            (child: ProjectChecklistStepViewModel) => child.IsVisibleToCustomer
          ),
        })),
      })),
    };
  }
}

export interface IProjectChecklistStepViewModel extends ProjectChecklistStepViewModel {
  SopPhase: SopPhaseViewModel;
  SopStepId?: number;
  SubSteps?: Array<IProjectChecklistStepViewModel>;
  FinishedSubStepsNumber?: number;
}

export interface ISopChecklistStepViewModel extends SopChecklistStepViewModel {
  ChecklistSteps?: Array<IProjectChecklistStepViewModel>;
  IsVisibleToCustomer?: boolean;
}

export interface ISopChecklistPhaseViewModel extends SopChecklistPhaseViewModel {
  SopSteps?: Array<ISopChecklistStepViewModel>;
}

export interface ISopChecklistViewModel extends SopChecklistViewModel {
  SopChecklistPhases?: Array<ISopChecklistPhaseViewModel>;
}
