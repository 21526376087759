import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { combineLatest, Observable, race } from 'rxjs';
import * as LeadAction from '../../shared/states/lead/lead.action';
import { DetailLoadAction, IDetailLoad } from '../../shared/states/lead/lead.action';
import { Store } from '@ngrx/store';
import * as RootReducer from '../../shared/states/index';
import { Actions, ofType } from '@ngrx/effects';
import { Logger, LoggerService } from '../../shared/services/logger.service';
import { RightsService } from '../../shared/services/rights.service';
import { LeadUtil } from '../../shared/utils/lead.util';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { LeadCompositeViewModel, MasterDataMandatorViewModel } from '../../shared/apis/advis';
import { map, take, tap } from 'rxjs/operators';
import { PermissionService } from '../../shared/services/permission.service';

export interface IDetailResolve {
  isOwner: boolean;
  isResponsible: boolean;
  isDelegate: boolean;
  isReadOnly: boolean;
  canEditProjectData: boolean;
}

/**
 * Retrieves an id from an activated route snapshot. If the current route has no
 * id column, we go up the parents chain and try out those.
 * @param {ActivatedRouteSnapshot} route
 * @returns {number}
 */
export function getIdFromRouteSnapshotRecursive(route: ActivatedRouteSnapshot): number {
  let current: ActivatedRouteSnapshot = route;
  while (current) {
    if (current.params.id) {
      return parseInt(current.params.id, 10);
    }
    current = current.parent;
  }

  return undefined;
}

@Injectable({
  providedIn: 'root',
})
export class LeadDetailResolver {
  private logger: Logger;

  constructor(
    private store: Store<RootReducer.IState>,
    private action$: Actions,
    private router: Router,
    private loggerService: LoggerService,
    private permissionsService: PermissionService,
    private rights: RightsService
  ) {
    this.logger = this.loggerService.create(LeadDetailResolver.name);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IDetailResolve> {
    // Always load detail view because history may have changed.
    const id: number = getIdFromRouteSnapshotRecursive(route);
    const payload: IDetailLoad = {
      leadId: id,
    };
    this.store.dispatch(new DetailLoadAction(payload));

    const success: Actions<LeadAction.DetailLoadSuccessAction> = this.action$.pipe(
      ofType(LeadAction.DETAIL_LOAD_SUCCESS)
    );
    const failure: Actions<LeadAction.DetailLoadFailureAction> = this.action$.pipe(
      ofType(LeadAction.DETAIL_LOAD_FAILURE)
    );

    failure
      .pipe(
        tap(() => {
          this.logger.warn(`Failed to load lead with id "${id}"`);
          this.router.navigate(['error']);
        })
      )
      .subscribe();

    const principal$: Observable<IPrincipal> = this.store.select<IPrincipal>(
      RootReducer.getAuthPrincipal
    );
    const lead$: Observable<LeadCompositeViewModel> = this.store.select(
      (storeState: RootReducer.IState) => storeState.lead.leadsDetail
    );
    const mandatorMap: Observable<Map<number, MasterDataMandatorViewModel>> = this.store.select<
      Map<number, MasterDataMandatorViewModel>
    >(RootReducer.getGlobalMandantsMap);

    var mandants: string[] = [];
    mandatorMap.pipe(take(1)).subscribe(map => {
      map.forEach(x => mandants.push(x.Id.toString()));
    });

    return combineLatest([principal$, lead$, race(success, failure), mandatorMap]).pipe(
      map(
        (
          value: [
            IPrincipal,
            LeadCompositeViewModel,
            LeadAction.DetailLoadSuccessAction | Actions<LeadAction.DetailLoadFailureAction>,
            Map<number, MasterDataMandatorViewModel>
          ]
        ) => {
          const answer: IDetailResolve = {
            isOwner: LeadUtil.isOwner(value[1].Lead, value[0]),
            isResponsible: LeadUtil.isResponsible(value[1].Lead, value[0]),
            isDelegate: LeadUtil.isDelegate(value[1].Lead, value[0]),
            isReadOnly: this.rights.getLeadRights().isReadOnly(value[1].Lead),
            canEditProjectData: LeadUtil.canEditProjectData(
              value[1].Lead,
              value[0],
              this.permissionsService.userPermissions,
              mandants
            ),
          };
          return answer;
        }
      ),
      take(1)
    );
  }
}
