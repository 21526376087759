<div role="drawer" class="main-sidenav">
  <div class="main-sidenav-header">
    <img class="main-sidenav-logo" src="/assets/icons/Theia_black.svg" alt="THEIA Logo" />

    <span class="main-sidenav-header__title-small">{{ name }}</span>
    <span class="main-sidenav-header__title-small">{{ email }}</span>
  </div>

  <pc-language-selector
    [langSelectorType]="langSelectorTypeEnum.FLAT_ROW"
    (languageChanged)="onLanguageChanged($event)"></pc-language-selector>

  <helion-ui-divider></helion-ui-divider>
  <a (click)="onLogout()" data-cy="mainNavButtonLogout" class="logout-button">
    <helion-ui-icon data-feather="log-out"></helion-ui-icon>
    {{ 'AUTH.SIGN_OUT' | translate }}</a
  >

  <helion-ui-divider></helion-ui-divider>

  <h3 class="subheader">Menu</h3>

  <a
    [routerLink]="[item.route]"
    class="navigate-link"
    *ngFor="let item of routes; let last = last"
    [class.last]="last">
    <helion-ui-icon [attr.data-feather]="item.icon"></helion-ui-icon>
    {{ item.title }}
  </a>

  <helion-ui-divider></helion-ui-divider>

  <h3 class="subheader">{{ 'COMMON.SEARCH' | translate }}</h3>
  <div class="search">
    <helion-ui-input
      type="number"
      placeholder="{{ 'LEADS.FILTER.LEAD_ID' | translate }}"
      [(ngModel)]="searchLeadId">
    </helion-ui-input>

    <helion-ui-input placeholder="{{ 'LEADS.FILTER.NAME' | translate }}" [(ngModel)]="searchName">
    </helion-ui-input>

    <helion-ui-input
      placeholder="{{ 'LEADS.FILTER.STREET' | translate }}"
      [(ngModel)]="searchStreet"></helion-ui-input>

    <helion-ui-input
      placeholder="{{ 'LEADS.FILTER.ZIP_CITY' | translate }}"
      [(ngModel)]="searchZipCodeCity">
    </helion-ui-input>

    <helion-ui-input
      placeholder="{{ 'LEADS.FILTER.PHONE_NUMBER' | translate }}"
      [(ngModel)]="searchPhone">
    </helion-ui-input>

    <helion-ui-input placeholder="{{ 'LEADS.FILTER.EMAIL' | translate }}" [(ngModel)]="searchEmail">
    </helion-ui-input>

    <div fxLayout="row" fxLayoutAlign="end center" class="filter-actions">
      <button [attr.fill-type]="'contained'" (click)="clickSearch()">
        {{ 'COMMON.SEARCH' | translate }}
      </button>
    </div>
  </div>

  <helion-ui-divider class="main-sidenav-footer-divider"></helion-ui-divider>

  <h3 class="subheader">{{ 'COMMON.SUPPORT' | translate }}</h3>
  <a (click)="onSupportClick($event)" data-rel="external" class="navigate-link">
    <helion-ui-icon data-feather="message-square"></helion-ui-icon>
    <span class="cursor-pointer">
      {{ 'COMMON.SUPPORT_EMAIL_LABEL' | translate }}
    </span>
  </a>

  <div class="main-sidenav-header" [tooltip]="versionCompleteInfo">
    <div style="max-width: 280px" class="main-sidenav-header__title-small">
      Version {{ versionEnvironment }}
    </div>

    <label class="main-sidenav-header__title-small versionLabel">{{ version }}</label>
  </div>
</div>
