/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PvDependencyViewModel { 
    Key?: string;
    DependencyType?: PvDependencyViewModel.DependencyTypeEnum;
}
export namespace PvDependencyViewModel {
    export type DependencyTypeEnum = 'RoofType';
    export const DependencyTypeEnum = {
        RoofType: 'RoofType' as DependencyTypeEnum
    };
}
