import * as LeadNotesAction from './lead-notes.action';
import * as ActionsAction from '../actions/actions.action';
import cloneDeep from 'lodash/cloneDeep';
import { tassign } from 'tassign';

import { NoteTemplateViewModel, NoteViewModel } from '../../apis/advis';

export interface IState {
  notes: NoteViewModel[];
  notesLoading: boolean;
  notesLeadId: number;
  noteTemplates: NoteTemplateViewModel[];
  noteTemplatesLoading: boolean;
  note: NoteViewModel;
  noteLoading: boolean;
  updatingTags: boolean;
  pmInfoNote: NoteViewModel;
  pmInfoNoteLoading: boolean;
}

export const initialState: IState = {
  notes: undefined,
  notesLoading: false,
  notesLeadId: undefined,
  noteTemplates: undefined,
  noteTemplatesLoading: false,
  note: undefined,
  noteLoading: false,
  updatingTags: false,
  pmInfoNote: undefined,
  pmInfoNoteLoading: false,
};

export function reducer(
  state: IState = initialState,
  action: LeadNotesAction.Actions | ActionsAction.Actions
): IState {
  switch (action.type) {
    // NOTE
    case LeadNotesAction.GET_NOTE_TEMPLATES: {
      return tassign(state, {
        noteTemplatesLoading: true,
      });
    }

    case LeadNotesAction.GET_NOTE_TEMPLATES_SUCCESS: {
      return tassign(state, {
        noteTemplatesLoading: false,
        noteTemplates: cloneDeep(action.payload),
      });
    }

    case LeadNotesAction.GET_NOTE_TEMPLATES_FAILED: {
      console.error(`Get All Note Templates failed="${action.payload}"`);
      return tassign(state, {
        noteTemplatesLoading: false,
      });
    }

    case LeadNotesAction.GET_ALL_NOTES: {
      return tassign(state, {
        notesLoading: true,
      });
    }

    case LeadNotesAction.GET_ALL_NOTES_SUCCESS: {
      return tassign(state, {
        notes: sortNotesByDate(cloneDeep(action.payload.notes)),
        notesLoading: false,
        notesLeadId: action.payload.leadId,
      });
    }

    case LeadNotesAction.GET_ALL_NOTES_FAILED: {
      console.error(`Get All Note failed="${action.payload}"`);
      return tassign(state, {
        notesLoading: false,
      });
    }

    case LeadNotesAction.GET_NOTE: {
      return tassign(state, {
        noteLoading: true,
      });
    }

    case LeadNotesAction.GET_NOTE_SUCCESS: {
      return tassign(state, {
        note: action.payload,
        noteLoading: false,
      });
    }

    case LeadNotesAction.GET_NOTE_FAILED: {
      console.error(`Get All Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    case LeadNotesAction.GET_PM_INFO_NOTE: {
      return tassign(state, {
        pmInfoNoteLoading: true,
      });
    }

    case LeadNotesAction.GET_PM_INFO_NOTE_SUCCESS: {
      return tassign(state, {
        pmInfoNote: action.payload,
        pmInfoNoteLoading: false,
      });
    }

    case LeadNotesAction.GET_PM_INFO_NOTE_FAILED: {
      console.error(`Get PM Info Note failed="${action.payload}"`);
      return tassign(state, {
        pmInfoNoteLoading: false,
        pmInfoNote: undefined,
      });
    }

    case LeadNotesAction.ADD_DRAWING_NOTE:
    case LeadNotesAction.UPDATE_DRAWING_NOTE:
    case LeadNotesAction.REMOVE_NOTE: {
      return tassign(state, {
        noteLoading: true,
      });
    }

    case LeadNotesAction.ADD_PM_INFO_TEXT_NOTE: {
      return tassign(state, {
        pmInfoNoteLoading: true,
      });
    }

    case LeadNotesAction.ADD_TEXT_NOTE_SUCCESS:
    case LeadNotesAction.ADD_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION:
    case LeadNotesAction.ADD_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION:
    case LeadNotesAction.ADD_DRAWING_NOTE_SUCCESS: {
      const notesState: NoteViewModel[] = cloneDeep(state.notes);
      notesState.push(action.payload);
      return tassign(state, {
        notes: sortNotesByDate(notesState),
        noteLoading: false,
      });
    }

    case LeadNotesAction.ADD_PM_INFO_TEXT_NOTE_SUCCESS: {
      return tassign(state, {
        pmInfoNote: action.payload,
        pmInfoNoteLoading: false,
      });
    }

    case LeadNotesAction.ADD_TEXT_NOTE_FAILURE: {
      console.error(`Add Text Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    case LeadNotesAction.ADD_PM_INFO_TEXT_NOTE_FAILURE: {
      return tassign(state, {
        pmInfoNoteLoading: false,
      });
    }

    case LeadNotesAction.ADD_DRAWING_NOTE_FAILURE: {
      console.error(`Add Drawing Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    case LeadNotesAction.UPDATE_TEXT_NOTE_SUCCESS:
    case LeadNotesAction.UPDATE_TEXT_NOTE_SUCCESS_WITHOUT_NAVIGATION:
    case LeadNotesAction.UPDATE_DRAWING_NOTE_SUCCESS_WITHOUT_NAVIGATION:
    case LeadNotesAction.UPDATE_DRAWING_NOTE_SUCCESS: {
      const notesState: NoteViewModel[] = cloneDeep(state.notes);
      const noteIndex: number = notesState.findIndex(
        (note: NoteViewModel) => note.Id === action.payload.Id
      );
      if (noteIndex > -1) {
        notesState.splice(noteIndex, 1, action.payload);
      }
      return tassign(state, {
        notes: notesState,
        noteLoading: false,
      });
    }

    case LeadNotesAction.UPDATE_TEXT_NOTE_FAILURE: {
      console.error(`Update Text Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    case LeadNotesAction.UPDATE_DRAWING_NOTE_FAILURE: {
      console.error(`Update Drawing Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    case LeadNotesAction.UPDATE_NOTE_TAGS: {
      return tassign(state, {
        updatingTags: action.payload,
      });
    }

    case LeadNotesAction.REMOVE_NOTE_SUCCESS: {
      const notesState: NoteViewModel[] = cloneDeep(state.notes);
      const noteIndex: number = notesState.findIndex(
        (note: NoteViewModel) => note.Id === action.payload.Id
      );
      if (noteIndex > -1) {
        notesState.splice(noteIndex, 1);
      }
      return tassign(state, {
        notes: notesState,
        noteLoading: false,
      });
    }

    case LeadNotesAction.REMOVE_NOTE_FAILURE: {
      console.error(`Remove Note failed="${action.payload}"`);
      return tassign(state, {
        noteLoading: false,
      });
    }

    default: {
      return state;
    }
  }

  function sortNotesByDate(notes: NoteViewModel[]): NoteViewModel[] {
    return notes.sort((n1: NoteViewModel, n2: NoteViewModel) => {
      return new Date(n2.Created).getTime() - new Date(n1.Created).getTime();
    });
  }
}

export const getAllNotesState: (state: IState) => NoteViewModel[] = (state: IState) => state.notes;

export const getNoteTemplatesState: (state: IState) => NoteTemplateViewModel[] = (state: IState) =>
  state.noteTemplates;

export const getNoteTemplatesLoadingState: (state: IState) => boolean = (state: IState) =>
  state.noteTemplatesLoading;

export const getPmNoteState: (state: IState) => NoteViewModel = (state: IState) => state.pmInfoNote;
