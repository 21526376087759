/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DependencyViewModel { 
    Key?: string;
    DependencyType?: DependencyViewModel.DependencyTypeEnum;
}
export namespace DependencyViewModel {
    export type DependencyTypeEnum = 'ProjectType' | 'HeatingType' | 'CurrentHeatGeneratorType' | 'WaterTankType' | 'LocationExhaustSystem' | 'CurrentHeatDissipation' | 'DomesticWaterHeatingInstallation';
    export const DependencyTypeEnum = {
        ProjectType: 'ProjectType' as DependencyTypeEnum,
        HeatingType: 'HeatingType' as DependencyTypeEnum,
        CurrentHeatGeneratorType: 'CurrentHeatGeneratorType' as DependencyTypeEnum,
        WaterTankType: 'WaterTankType' as DependencyTypeEnum,
        LocationExhaustSystem: 'LocationExhaustSystem' as DependencyTypeEnum,
        CurrentHeatDissipation: 'CurrentHeatDissipation' as DependencyTypeEnum,
        DomesticWaterHeatingInstallation: 'DomesticWaterHeatingInstallation' as DependencyTypeEnum
    };
}
