import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { LangSelectorTypeEnum } from '../language-selector/language-selector.component';
import { Subscription } from 'rxjs';
import { Logger, LoggerService } from '../../shared/services/logger.service';
import {
  LeadCompositeViewModel,
  MasterDataMandatorViewModel,
  MasterDataOrganisationUnitViewModel,
  MasterDataUserViewModel,
  MasterDataViewModel,
  RoleViewModel,
  UserInfo,
  VariantCompositeViewModel,
} from '../../shared/apis/advis';
import { IPrincipal } from '../../shared/interfaces/principal.interface';
import { environment } from '../../../environments/environment';
import { versions } from '../../../environments/versions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { ViewSettingService } from '../../shared/services/view-setting.service';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from '../../shared/utils/isNullOrUndefined';
import * as RootReducer from '../../shared/states';
import * as AuthReducer from '../../shared/states/auth/auth.reducer';
import { routesNames } from '../../app.component';
import { QuickSearchDialogComponent } from '../dialog/quick-search-dialog/quick-search-dialog.component';
import { LogoutAction } from '../../shared/states/auth/auth.action';
import {
  ISupportDialogData,
  SupportDialogComponent,
} from '../dialog/support-dialog/support-dialog.component';
import cloneDeep from 'lodash/cloneDeep';
import { SetNavigationProcessAction } from '../../shared/states/global/global.action';
import { DialogService } from '@sitewerk/theia-ui-lib';
import { fillTemplate } from '../../shared/utils/support-dialog.utils';

enum PageViewE {
  UNDEF,
  LEAD,
  VARIANT,
  MT_CRM,
  PKF,
}

export interface IMainRouteData {
  title: string;
  route: string;
  icon: string;
}

@Component({
  selector: 'pc-main-drawer-content',
  templateUrl: './main-drawer-content.component.html',
  styleUrls: ['./main-drawer-content.component.scss'],
})
export class MainDrawerContentComponent implements OnInit, OnDestroy {
  private static TEMPLATE_PATH: string = '/assets/template/support_de.txt'; // Currently on DE support

  @HostBinding('class')
  public cssClass: string = 'pc-main';

  public readonly langSelectorTypeEnum: typeof LangSelectorTypeEnum = LangSelectorTypeEnum;

  // Routes are pushed after lang-txt translation, see ngOnInt()
  public routes: IMainRouteData[] = [];
  public name: string = undefined;
  public email: string = undefined;
  public phoneNumber: string = undefined;
  public versionEnvironment: string = environment.versionInfo;
  public version: string = versions.displayVersion;
  public versionCompleteInfo: string =
    'Version : ' +
    versions.version +
    ' \n  Branch : ' +
    versions.branch +
    ' \n  Revision : ' +
    versions.revision;

  public searchLeadId: number;
  public searchName: string;
  public searchStreet: string;
  public searchZipCodeCity: string;
  public searchEmail: string;
  public searchPhone: string;

  public userRoles: string = '';

  private subscription: Subscription = new Subscription();
  private logger: Logger;
  private lead: LeadCompositeViewModel = undefined;
  private variant: VariantCompositeViewModel = undefined;
  private pageView: PageViewE = PageViewE.UNDEF;
  private template: string = '';
  private principal: IPrincipal;
  private mandantMap: Map<number, MasterDataMandatorViewModel>;
  private deviceInfo: string = window.navigator.userAgent;
  private currentURL: string = '';

  // Routes for Lead and Variant - this is weak, keep it in sync with the routes.
  private variantRouteRegExp: RegExp = /.*lead\/detail\/\d+\/variant.*/;
  private pfkRouteRegExp: RegExp = /.*lead\/pfk\/\d+.*/;
  private leadRouteRegExp: RegExp = /.*lead\/detail\/\d+.*/;
  private mtCrmRouteRegExp: RegExp = /mt_crm\/bom\//;

  private userMandator: MasterDataMandatorViewModel;

  private supportEmail: string;
  private technicalSupportEmail: string;
  private productSupportEmail: string;

  constructor(
    private store: Store<RootReducer.IState>,
    private translate: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private vsService: ViewSettingService,
    private loggerService: LoggerService,
    private http: HttpClient
  ) {
    this.logger = this.loggerService.create(MainDrawerContentComponent.name);

    this.subscription.add(
      this.router.events.subscribe((routerEvent: Event) => {
        this.dispatchNavigationAction(routerEvent);
        this.setPageView(routerEvent);
      })
    );
  }

  public ngOnInit(): void {
    this.loadTemplate();

    this.subscription.add(
      this.store.select(RootReducer.getAuthState).subscribe((auth: AuthReducer.IState) => {
        if (auth.loggedIn === true) {
          this.principal = auth.principal;
        }
      })
    );

    this.subscription.add(
      this.store.select(RootReducer.getUserInfo).subscribe((userInfo: UserInfo) => {
        if (!userInfo) {
          return;
        }
        this.email = userInfo.User;
        this.name =
          userInfo.Mandant +
          ' (' +
          userInfo.Roles.map((x: RoleViewModel) => x.Name).join(' ,') +
          ')';
        this.userRoles = userInfo.Roles.map((x: RoleViewModel) => x.Name).join(', ');
      })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getGlobalMandantsMap)
        .subscribe((mandantMap: Map<number, MasterDataMandatorViewModel>) => {
          this.mandantMap = mandantMap;
        })
    );

    const isMtCrm: boolean = window.location.pathname.includes(routesNames.MT_CRM);

    this.routes.push({
      title: this.translate.instant('LEADS.LEADS'),
      route: isMtCrm ? '/' + routesNames.MT_CRM + '/' + routesNames.MT_CRM_OVERVIEW : '/',
      icon: 'table',
    } as IMainRouteData);

    this.routes.push({
      title: this.translate.instant('ADDRESSBOOK.OVERVIEW.MENU'),
      route: '/addressbook/overview',
      icon: 'book',
    } as IMainRouteData);

    this.subscription.add(
      this.store
        .select(RootReducer.getLeadDetailEntity)
        .subscribe((lead: LeadCompositeViewModel) => {
          if (!isNullOrUndefined(lead)) {
            this.lead = lead;
          } else {
            this.lead = undefined;
          }
        })
    );

    this.subscription.add(
      this.store.select(RootReducer.getVariant).subscribe((variant: VariantCompositeViewModel) => {
        if (!isNullOrUndefined(variant)) {
          this.variant = variant;
        } else {
          this.variant = undefined;
        }
      })
    );

    this.subscription.add(
      this.store
        .select(RootReducer.getGlobalMasterData)
        .subscribe((masterData: MasterDataViewModel) => {
          const userMandator: MasterDataMandatorViewModel = masterData.Mandators.find(
            (m: MasterDataMandatorViewModel) => {
              return m.Id === this.principal.getMandantId();
            }
          );

          this.userMandator = userMandator;

          userMandator.OrganisationUnits.forEach((oe: MasterDataOrganisationUnitViewModel) => {
            oe.Users.find((u: MasterDataUserViewModel) => {
              if (u.Id === this.principal.getUserId()) {
                this.phoneNumber = u.PhoneNumber;
              }

              return u.Id === this.principal.getUserId();
            });
          });

          this.supportEmail =
            userMandator.TechnicalSupportEmail || this.translate.instant('COMMON.SUPPORT_EMAIL');
          this.technicalSupportEmail =
            userMandator.TechnicalSupportEmail ||
            this.translate.instant('COMMON.TECHNICAL_SUPPORT_EMAIL');
          this.productSupportEmail =
            userMandator.ProductSupportEmail ||
            this.translate.instant('COMMON.PRODUCT_SUPPORT_EMAIL');
        })
    );

    if (isNullOrUndefined(this.translate.currentLang)) {
      this.translate.use(this.vsService.getLastLoginLang());
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public clickSearch(): void {
    this.subscription.add(
      this.dialogService
        .open(QuickSearchDialogComponent, {
          data: {
            name: this.searchName,
            street: this.searchStreet,
            zipCodeCity: this.searchZipCodeCity,
            email: this.searchEmail,
            phone: this.searchPhone,
            leadId: this.searchLeadId,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.searchLeadId = undefined;
          this.searchName = '';
          this.searchStreet = '';
          this.searchZipCodeCity = '';
          this.searchEmail = '';
          this.searchPhone = '';
        })
    );
  }

  public onLogoClick(): void {
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 300);
  }

  public onLogout(): void {
    this.store.dispatch(new LogoutAction());
  }

  public onLanguageChanged(language: string): void {
    if (
      this.vsService.getLastLoginLang().toLowerCase().localeCompare(language.toLowerCase()) !== 0
    ) {
      this.vsService.setAccountLanguageAndReload(language);
    }
  }

  public onSupportClick(event: MouseEvent): void {
    event.preventDefault();
    const mailArguments = this.getMailArguments();
    if (this.userMandator.UseExtendedSupportDialog) {
      const supportDialogData: ISupportDialogData = {
        productSupportEmail: this.productSupportEmail,
        technicalSupportEmail: this.technicalSupportEmail,
        mailArguments: mailArguments,
      };
      this.dialogService.open(SupportDialogComponent, {
        data: supportDialogData,
      });
    } else {
      window.open(`mailto:${this.supportEmail}${mailArguments}`);
    }
  }

  private getMailArguments(): string {
    const supportTitle: string = this.translate.instant('COMMON.SUPPORT_TITLE');
    return `?subject=${supportTitle}&body=` + this.createSupportBody();
  }

  private loadTemplate(): void {
    this.subscription.add(
      this.http
        .get(MainDrawerContentComponent.TEMPLATE_PATH, { responseType: 'text' })
        .subscribe((data: any) => {
          this.template = data;
        })
    );
  }

  private createSupportBody(): string {
    let body: string = cloneDeep(this.template);
    this.currentURL = encodeURIComponent(window.location.href);
    switch (this.pageView) {
      case PageViewE.UNDEF:
        body = fillTemplate(
          body,
          this.version,
          this.deviceInfo,
          this.principal,
          this.phoneNumber,
          undefined,
          undefined,
          this.mandantMap,
          window.location.href,
          this.userRoles
        );
        break;

      case PageViewE.LEAD:
        if (!isNullOrUndefined(this.lead)) {
          body = fillTemplate(
            body,
            this.version,
            this.deviceInfo,
            this.principal,
            this.phoneNumber,
            this.lead.Lead,
            undefined,
            this.mandantMap,
            this.currentURL,
            this.userRoles
          );
        }
        break;

      case PageViewE.MT_CRM:
        body = fillTemplate(
          body,
          this.version,
          this.deviceInfo,
          this.principal,
          this.phoneNumber,
          undefined,
          undefined,
          this.mandantMap,
          this.currentURL,
          this.userRoles
        );
        break;

      case PageViewE.VARIANT:
        if (!isNullOrUndefined(this.lead) && !isNullOrUndefined(this.variant)) {
          body = fillTemplate(
            body,
            this.version,
            this.deviceInfo,
            this.principal,
            this.phoneNumber,
            this.lead.Lead,
            this.variant,
            this.mandantMap,
            this.currentURL,
            this.userRoles
          );
        }
        break;

      default:
        this.logger.warn(`Unknown page view=${this.pageView}`);
        break;
    }
    return body;
  }

  private dispatchNavigationAction(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.store.dispatch(new SetNavigationProcessAction(true));
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.store.dispatch(new SetNavigationProcessAction(false));
    }
  }

  private setPageView(routerEvent: Event): void {
    if (routerEvent instanceof NavigationEnd) {
      const url: string = (<NavigationEnd>routerEvent).url;
      if (this.variantRouteRegExp.exec(url) !== null) {
        this.pageView = PageViewE.VARIANT;
      } else if (this.pfkRouteRegExp.exec(url) !== null) {
        this.pageView = PageViewE.PKF;
      } else if (this.leadRouteRegExp.exec(url) !== null) {
        this.pageView = PageViewE.LEAD;
      } else if (this.mtCrmRouteRegExp.exec(url) !== null) {
        this.pageView = PageViewE.MT_CRM;
      } else {
        this.pageView = PageViewE.UNDEF;
      }
    }
  }
}
