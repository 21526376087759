import { ProjectScoreViewModel } from './../../../shared/apis/advis/model/projectScoreViewModel';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeadService } from '../../../shared/apis/advis';
import { Subscription } from 'rxjs';
import { DialogRef } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-project-manager-ranking-dialog',
  templateUrl: './project-manager-ranking-dialog.component.html',
  styleUrls: ['./project-manager-ranking-dialog.component.scss'],
})
export class ProjectManagerRankingDialogComponent implements OnInit, OnDestroy {
  protected topScores: ProjectScoreViewModel[] = [];
  protected otherScores: ProjectScoreViewModel[] = [];
  protected isLoaded: boolean = false;
  protected hasSpaceInBetween: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(public dialogRef: DialogRef, private leadService: LeadService) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.leadService.leadGetProjectScoreRanking().subscribe(
        (scores: ProjectScoreViewModel[]) => {
          if (scores) {
            this.setupScores(scores);
            this.isLoaded = true;
          }
        },
        (error: any) => {
          this.isLoaded = true;
          console.error(`Loading project scores failed "${error}"`);
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

  private setupScores(scores: ProjectScoreViewModel[]): void {
    const topScores = 5;
    const scoreOffset = 2;

    for (let i = 0; i < scores.length; i++) {
      if (i < topScores) {
        this.topScores.push(scores[i]);
      }

      if (scores[i].IsCurrentUser) {
        if (i > topScores + scoreOffset) {
          this.hasSpaceInBetween = true;
        }

        for (
          let j = Math.max(topScores, i - scoreOffset);
          j < Math.min(scores.length, i + scoreOffset + 1);
          j++
        ) {
          this.otherScores.push(scores[j]);
        }
      }
    }
  }
}
