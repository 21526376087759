/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
// @ts-ignore
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
// @ts-ignore
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PostProjectReportingSnapshotRequest } from '../model/postProjectReportingSnapshotRequest';
import { ProjectReportingSnapshotViewModel } from '../model/projectReportingSnapshotViewModel';
import { ProjectReportingTimeRequest } from '../model/projectReportingTimeRequest';
import { ProjectReportingViewModel } from '../model/projectReportingViewModel';
import { PutProjectReportingFinancesIncomeAccountRequest } from '../model/putProjectReportingFinancesIncomeAccountRequest';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectReportingService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    // @ts-ignore
        private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates project reporting snapshots for a lead.
     * 
     * @param leadId the id of the lead
     * @param body the project reporting request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingAddProjectReportingSnapshot(leadId: number, body?: PostProjectReportingSnapshotRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectReportingSnapshotViewModel>>;
    public projectReportingAddProjectReportingSnapshot(leadId: number, body?: PostProjectReportingSnapshotRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectReportingSnapshotViewModel>>>;
    public projectReportingAddProjectReportingSnapshot(leadId: number, body?: PostProjectReportingSnapshotRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectReportingSnapshotViewModel>>>;
    public projectReportingAddProjectReportingSnapshot(leadId: number, body?: PostProjectReportingSnapshotRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingAddProjectReportingSnapshot.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ProjectReportingSnapshotViewModel>>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/snapshots`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get project reporting for a lead.
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingGetProjectReporting(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectReportingViewModel>;
    public projectReportingGetProjectReporting(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectReportingViewModel>>;
    public projectReportingGetProjectReporting(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectReportingViewModel>>;
    public projectReportingGetProjectReporting(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingGetProjectReporting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProjectReportingViewModel>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the project reporting snapshots for a lead.
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingGetProjectReportingSnapshot(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectReportingSnapshotViewModel>>;
    public projectReportingGetProjectReportingSnapshot(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectReportingSnapshotViewModel>>>;
    public projectReportingGetProjectReportingSnapshot(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectReportingSnapshotViewModel>>>;
    public projectReportingGetProjectReportingSnapshot(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingGetProjectReportingSnapshot.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProjectReportingSnapshotViewModel>>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/snapshots`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the income account to a finances position.
     * 
     * @param leadId the id of the lead
     * @param body a list with position for income account change
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingPutFinancesIncomeAccount(leadId: number, body?: Array<PutProjectReportingFinancesIncomeAccountRequest>, observe?: 'body', reportProgress?: boolean): Observable<ProjectReportingViewModel>;
    public projectReportingPutFinancesIncomeAccount(leadId: number, body?: Array<PutProjectReportingFinancesIncomeAccountRequest>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectReportingViewModel>>;
    public projectReportingPutFinancesIncomeAccount(leadId: number, body?: Array<PutProjectReportingFinancesIncomeAccountRequest>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectReportingViewModel>>;
    public projectReportingPutFinancesIncomeAccount(leadId: number, body?: Array<PutProjectReportingFinancesIncomeAccountRequest>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingPutFinancesIncomeAccount.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProjectReportingViewModel>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/finances`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the time of the project reporting.
     * 
     * @param leadId the id of the lead
     * @param body the request with the project reporting time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingPutProjectReportingTime(leadId: number, body?: ProjectReportingTimeRequest, observe?: 'body', reportProgress?: boolean): Observable<ProjectReportingViewModel>;
    public projectReportingPutProjectReportingTime(leadId: number, body?: ProjectReportingTimeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectReportingViewModel>>;
    public projectReportingPutProjectReportingTime(leadId: number, body?: ProjectReportingTimeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectReportingViewModel>>;
    public projectReportingPutProjectReportingTime(leadId: number, body?: ProjectReportingTimeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingPutProjectReportingTime.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
            'text/plain',
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProjectReportingViewModel>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/Time`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset the time to the last manual project reporting snapshot.
     * 
     * @param leadId the id of the lead
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectReportingResetProjectReportingTime(leadId: number, observe?: 'body', reportProgress?: boolean): Observable<ProjectReportingViewModel>;
    public projectReportingResetProjectReportingTime(leadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectReportingViewModel>>;
    public projectReportingResetProjectReportingTime(leadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectReportingViewModel>>;
    public projectReportingResetProjectReportingTime(leadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling projectReportingResetProjectReportingTime.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        // @ts-ignore
        const consumes: string[] = [
        ];

        return this.httpClient.put<ProjectReportingViewModel>(`${this.basePath}/api/pc/project-reporting/${encodeURIComponent(String(leadId))}/Time/reset`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
