import { Action } from '@ngrx/store';
import { ViewSettingModel } from '../../models/view-setting/view-setting.model';
import { ViewSettingItem } from '../../services/view-setting.service';

export const LOAD: string = '[View Setting] Load';
export const LOAD_SUCCESS: string = '[View Setting] Load Success';
export const LOAD_FAILURE: string = '[View Setting] Load Failure';
export const SAVE: string = '[View Setting] Save';
export const SAVE_SUCCESS: string = '[View Setting] Save Success';
export const SAVE_FAILURE: string = '[View Setting] Save Failure';

export class ViewSettingLoadAction implements Action {
  readonly type: string = LOAD;

  constructor(public payload: any = undefined) {}
}

export class ViewSettingLoadSuccessAction implements Action {
  readonly type: string = LOAD_SUCCESS;

  constructor(public payload: ViewSettingModel) {}
}

export class ViewSettingLoadFailureAction implements Action {
  readonly type: string = LOAD_FAILURE;

  constructor(public payload: any) {}
}

export class ViewSettingSaveAction<T> implements Action {
  readonly type: string = SAVE;

  constructor(public payload: ViewSettingItem<T>) {}
}

export class ViewSettingSaveSuccessAction<T> implements Action {
  readonly type: string = SAVE_SUCCESS;

  constructor(public payload: ViewSettingItem<T>) {}
}

export class ViewSettingSaveFailureAction implements Action {
  readonly type: string = SAVE_FAILURE;

  constructor(public payload: any) {}
}

export type Actions =
  | ViewSettingLoadAction
  | ViewSettingLoadSuccessAction
  | ViewSettingLoadFailureAction
  | ViewSettingSaveAction<any>
  | ViewSettingSaveSuccessAction<any>
  | ViewSettingSaveFailureAction;
