import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as RootReducer from '../../shared/states/index';
import { ErrorAddAction } from '../states/global/global.action';
import { ErrorTypeE } from '../states/global/global.reducer';
import { tap, timeout } from 'rxjs/operators';
import { AppMonitoringService } from '../services/app-monitoring.service';
import { environment } from '../../../environments/environment';
import { EnvironmentTypeEnum } from '../../../environments/environment.interface';

export const DEFAULT_TIMEOUT: InjectionToken<number> = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<RootReducer.IState>,
    private appMonitoringService: AppMonitoringService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let timeoutValue: number = this.defaultTimeout;

    // Check if we send FormData with File(s) and increase timeoutValue in this case
    if (req.body instanceof FormData) {
      const reqBody: FormData = req.body;
      reqBody.forEach((value: FormDataEntryValue) => {
        if (value instanceof File) {
          timeoutValue = 900000;
        }
      });
    }

    return next.handle(req).pipe(
      timeout(timeoutValue),
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (
              error.status.toString().indexOf('400') > -1 &&
              environment.environmentType !== EnvironmentTypeEnum.LOCAL
            ) {
              // if you need monitoring start from the app start - put `private appMonitoringService: AppMonitoringService` into app.component constructor
              this.appMonitoringService.logException(error, 3);
            }

            switch (error.status) {
              case 403:
                console.error(`onResponseError status "${error.status}" error "${error}"`);
                this.store.dispatch(
                  new ErrorAddAction({
                    type: ErrorTypeE.PERMISSION,
                    data: error,
                  })
                );
                break;
              case 503:
                console.error(`onResponseError status "${error.status}" error "${error}"`);
                if (error.url.includes('pc-api')) {
                  window.location.href = location.protocol + '//' + location.host + '/maintenance';
                }
                break;
              default:
                // empty
                break;
            }
          }
        }
      )
    );
  }
}
