/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DirectoryCompanyViewModel } from './directoryCompanyViewModel';
import { DirectoryPersonViewModel } from './directoryPersonViewModel';


export interface DirectoryContactViewModel { 
    Id?: number;
    ContactType?: DirectoryContactViewModel.ContactTypeEnum;
    Person?: DirectoryPersonViewModel;
    Company?: DirectoryCompanyViewModel;
    DataState?: DirectoryContactViewModel.DataStateEnum;
    MandatorId?: number;
    IsSingleContact?: boolean;
}
export namespace DirectoryContactViewModel {
    export type ContactTypeEnum = 'Contact' | 'Object' | 'Debitor' | 'Administration' | 'Architect' | 'AuthorizedPerson' | 'Authorizer' | 'BrineWaterDrilling' | 'BuildingPhysicist' | 'ContactForAccess' | 'Delivery' | 'Dismantling' | 'Electrician' | 'ElectroPlanner' | 'ExternalAssemblyCompany' | 'HeatingEngineer' | 'Isolation' | 'Janitor' | 'LandOwner' | 'MasterBuilder' | 'Painter' | 'Plumber' | 'PowerCompany' | 'Roofer' | 'RoofTenant' | 'Scaffolder' | 'SiteEngineer' | 'SiteOwner' | 'SiteSupervisor' | 'SubsidyReceiver' | 'Tinsmith' | 'Supplier' | 'Dealer' | 'ServiceTechnician' | 'CarDealer' | 'PlanningOffice' | 'VentilationSystemInstaller' | 'SapDebtor' | 'SiteAddress' | 'FormerHeatingEngineer';
    export const ContactTypeEnum = {
        Contact: 'Contact' as ContactTypeEnum,
        Object: 'Object' as ContactTypeEnum,
        Debitor: 'Debitor' as ContactTypeEnum,
        Administration: 'Administration' as ContactTypeEnum,
        Architect: 'Architect' as ContactTypeEnum,
        AuthorizedPerson: 'AuthorizedPerson' as ContactTypeEnum,
        Authorizer: 'Authorizer' as ContactTypeEnum,
        BrineWaterDrilling: 'BrineWaterDrilling' as ContactTypeEnum,
        BuildingPhysicist: 'BuildingPhysicist' as ContactTypeEnum,
        ContactForAccess: 'ContactForAccess' as ContactTypeEnum,
        Delivery: 'Delivery' as ContactTypeEnum,
        Dismantling: 'Dismantling' as ContactTypeEnum,
        Electrician: 'Electrician' as ContactTypeEnum,
        ElectroPlanner: 'ElectroPlanner' as ContactTypeEnum,
        ExternalAssemblyCompany: 'ExternalAssemblyCompany' as ContactTypeEnum,
        HeatingEngineer: 'HeatingEngineer' as ContactTypeEnum,
        Isolation: 'Isolation' as ContactTypeEnum,
        Janitor: 'Janitor' as ContactTypeEnum,
        LandOwner: 'LandOwner' as ContactTypeEnum,
        MasterBuilder: 'MasterBuilder' as ContactTypeEnum,
        Painter: 'Painter' as ContactTypeEnum,
        Plumber: 'Plumber' as ContactTypeEnum,
        PowerCompany: 'PowerCompany' as ContactTypeEnum,
        Roofer: 'Roofer' as ContactTypeEnum,
        RoofTenant: 'RoofTenant' as ContactTypeEnum,
        Scaffolder: 'Scaffolder' as ContactTypeEnum,
        SiteEngineer: 'SiteEngineer' as ContactTypeEnum,
        SiteOwner: 'SiteOwner' as ContactTypeEnum,
        SiteSupervisor: 'SiteSupervisor' as ContactTypeEnum,
        SubsidyReceiver: 'SubsidyReceiver' as ContactTypeEnum,
        Tinsmith: 'Tinsmith' as ContactTypeEnum,
        Supplier: 'Supplier' as ContactTypeEnum,
        Dealer: 'Dealer' as ContactTypeEnum,
        ServiceTechnician: 'ServiceTechnician' as ContactTypeEnum,
        CarDealer: 'CarDealer' as ContactTypeEnum,
        PlanningOffice: 'PlanningOffice' as ContactTypeEnum,
        VentilationSystemInstaller: 'VentilationSystemInstaller' as ContactTypeEnum,
        SapDebtor: 'SapDebtor' as ContactTypeEnum,
        SiteAddress: 'SiteAddress' as ContactTypeEnum,
        FormerHeatingEngineer: 'FormerHeatingEngineer' as ContactTypeEnum
    };
    export type DataStateEnum = 'Unqualified' | 'Qualified';
    export const DataStateEnum = {
        Unqualified: 'Unqualified' as DataStateEnum,
        Qualified: 'Qualified' as DataStateEnum
    };
}
