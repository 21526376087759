<div *ngIf="copyToExternalManaged">
  <button
    class="menu-item"
    cdkMenuItem
    *ngFor="let addressConfig of externalManagedAddressConfig"
    (cdkMenuItemTriggered)="copyAsExternalManaged(addressConfig.addressType)">
    <helion-ui-icon [attr.data-feather]="addressConfig.Icon"></helion-ui-icon>
    <span>{{
      'ADDRESS.EXTERNAL_MANAGED.CONFIG.CONTACT_TO.EXTERNAL_MANAGED'
        | translate : { addressTypeName: addressConfig.translation }
    }}</span>
  </button>
</div>

<div *ngIf="!copyToExternalManaged">
  <button
    class="menu-item"
    cdkMenuItem
    *ngFor="let addressConfig of externalManagedAddressConfig"
    (cdkMenuItemTriggered)="changeRequest(addressConfig)">
    <helion-ui-icon [attr.data-feather]="addressConfig.Icon"></helion-ui-icon>
    <span>{{
      'ADDRESS.EXTERNAL_MANAGED_REQUEST_MENU'
        | translate : { addressTypeName: addressConfig.translation }
    }}</span>
  </button>
</div>
