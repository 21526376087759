import { Action } from '@ngrx/store';
import { ProjectReportingViewModel } from '../../apis/advis/model/projectReportingViewModel';
import {
  PostProjectReportingSnapshotRequest,
  ProjectFinancesOwnContributionViewModel,
  ProjectFinancesPositionViewModel,
  ProjectReportingSnapshotViewModel,
} from '../../apis/advis';

export const GET_PROJECT_REPORTING: string = '[Project-reporting] Get Project-reporting info';
export const GET_PROJECT_REPORTING_SUCCESS: string =
  '[Project-reporting] Get Project-reporting info success';
export const GET_PROJECT_REPORTING_FAILED: string =
  '[Project-reporting] Get Project-reporting info failed';

export const GET_PROJECT_REPORTING_SNAPSHOTS: string =
  '[Project-reporting] Get Project-reporting snapshot';
export const GET_PROJECT_REPORTING_SNAPSHOTS_SUCCESS: string =
  '[Project-reporting] Get Project-reporting snapshot success';
export const GET_PROJECT_REPORTING_SNAPSHOTS_FAILED: string =
  '[Project-reporting] Get Project-reporting snapshot failed';

export const ADD_PROJECT_REPORTING_SNAPSHOT: string =
  '[Project-reporting] Add Project-reporting snapshot';
export const ADD_PROJECT_REPORTING_SNAPSHOT_SUCCESS: string =
  '[Project-reporting] Add Project-reporting snapshot success';
export const ADD_PROJECT_REPORTING_SNAPSHOT_FAILED: string =
  '[Project-reporting] Add Project-reporting snapshot failed';

export const PUT_FINANCES_INCOME_ACCOUNT: string = '[Project-reporting] Put FinancesIncomeAccount';
export const PUT_FINANCES_INCOME_ACCOUNT_SUCCESS: string =
  '[Project-reporting] Put FinancesIncomeAccount success';
export const PUT_FINANCES_INCOME_ACCOUNT_FAILED: string =
  '[Project-reporting] Put FinancesIncomeAccount failed';

export const PUT_FINANCES_TIME: string = '[Project-reporting] Put Finances Time';
export const PUT_FINANCES_TIME_SUCCESS: string = '[Project-reporting] Put Finances Time success';
export const PUT_FINANCES_TIME_FAILED: string = '[Project-reporting] Put Finances Time failed';

export const RESET_FINANCES_TIME: string = '[Project-reporting] Reset Finances Time';
export const RESET_FINANCES_TIME_SUCCESS: string =
  '[Project-reporting] Reset Finances Time success';
export const RESET_FINANCES_TIME_FAILED: string = '[Project-reporting] Reset Finances Time failed';

export const ADD_PROJECT_REPORTING_SNAPSHOT_RESET_STATE: string =
  '[Project-reporting] Add Project-reporting snapshot reset state';

export const DOWNLOAD_PFK_INVOICE_PDF: string = '[Project-reporting] Download Pfk Invoice Pdf';
export const DOWNLOAD_PFK_INVOICE_PDF_SUCCESS: string =
  '[Project-reporting] Download Pfk Invoice Pdf success';
export const DOWNLOAD_PFK_INVOICE_PDF_FAILED: string =
  '[Project-reporting] Download Pfk Invoice Pdf failed';

export class ResetFinancesTime implements Action {
  readonly type: string = RESET_FINANCES_TIME;

  constructor(public leadId: number) {}
}

export class ResetFinancesTimeSuccessAction implements Action {
  readonly type: string = RESET_FINANCES_TIME_SUCCESS;

  constructor(public payload: ProjectReportingViewModel) {}
}

export class ResetFinancesTimeFailedAction implements Action {
  readonly type: string = RESET_FINANCES_TIME_FAILED;

  constructor(public payload: any) {}
}

export interface IPutFinancesTimePayload {
  leadId: number;
  ownContributionFinances: ProjectFinancesOwnContributionViewModel;
}

export class PutFinancesTime implements Action {
  readonly type: string = PUT_FINANCES_TIME;

  constructor(public payload: IPutFinancesTimePayload) {}
}

export class PutFinancesTimeSuccessAction implements Action {
  readonly type: string = PUT_FINANCES_TIME_SUCCESS;

  constructor(public payload: ProjectReportingViewModel) {}
}

export class PutFinancesTimeFailedAction implements Action {
  readonly type: string = PUT_FINANCES_TIME_FAILED;

  constructor(public payload: any) {}
}

export interface IPutFinancesIncomeAccountPayload {
  leadId: number;
  finances: ProjectFinancesPositionViewModel[];
}

export class PutFinancesIncomeAccount implements Action {
  readonly type: string = PUT_FINANCES_INCOME_ACCOUNT;

  constructor(public payload: IPutFinancesIncomeAccountPayload) {}
}

export class PutFinancesIncomeAccountSuccessAction implements Action {
  readonly type: string = PUT_FINANCES_INCOME_ACCOUNT_SUCCESS;

  constructor(public payload: ProjectReportingViewModel) {}
}

export class PutFinancesIncomeAccountFailedAction implements Action {
  readonly type: string = PUT_FINANCES_INCOME_ACCOUNT_FAILED;

  constructor(public payload: any) {}
}

export class GetProjectReportingAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING;

  constructor(public payload: any = undefined) {}
}

export class GetProjectReportingSuccessAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING_SUCCESS;

  constructor(public payload: ProjectReportingViewModel) {}
}

export class GetProjectReportingFailedAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING_FAILED;

  constructor(public payload: any) {}
}

export class GetProjectReportingSnapshotsAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING_SNAPSHOTS;

  constructor(public payload: number) {}
}

export class GetProjectReportingSnapshotsSuccessAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING_SNAPSHOTS_SUCCESS;

  constructor(public payload: ProjectReportingSnapshotViewModel[]) {}
}

export class GetProjectReportingSnapshotsFailedAction implements Action {
  readonly type: string = GET_PROJECT_REPORTING_SNAPSHOTS_FAILED;

  constructor(public payload: any) {}
}

export interface IAddProjectReportingSnapshotPayload {
  leadId: number;
  body: PostProjectReportingSnapshotRequest;
}

export class AddProjectReportingSnapshotAction implements Action {
  readonly type: string = ADD_PROJECT_REPORTING_SNAPSHOT;

  constructor(public payload: IAddProjectReportingSnapshotPayload) {}
}

export class AddProjectReportingSnapshotSuccessAction implements Action {
  readonly type: string = ADD_PROJECT_REPORTING_SNAPSHOT_SUCCESS;

  constructor(public payload: ProjectReportingSnapshotViewModel[]) {}
}

export class AddProjectReportingSnapshotFailedAction implements Action {
  readonly type: string = ADD_PROJECT_REPORTING_SNAPSHOT_FAILED;

  constructor(public payload: any) {}
}

export class AddProjectReportingSnapshotResetStateAction implements Action {
  readonly type: string = ADD_PROJECT_REPORTING_SNAPSHOT_RESET_STATE;

  constructor(public payload: any) {}
}

export interface ICreditorInvoicePdfDownloadActionPayload {
  leadId: number;
  invoiceNumber: number;
  fiscalYear: number;
}

export class CreditorInvoicePdfDownloadAction implements Action {
  readonly type: string = DOWNLOAD_PFK_INVOICE_PDF;

  constructor(public payload: ICreditorInvoicePdfDownloadActionPayload) {}
}

export class CreditorInvoicePdfDownloadSuccessAction implements Action {
  readonly type: string = DOWNLOAD_PFK_INVOICE_PDF_SUCCESS;
}

export class CreditorInvoicePdfDownloadFailedAction implements Action {
  readonly type: string = DOWNLOAD_PFK_INVOICE_PDF_FAILED;
}

export type Actions =
  | PutFinancesIncomeAccount
  | PutFinancesIncomeAccountFailedAction
  | PutFinancesIncomeAccountSuccessAction
  | GetProjectReportingAction
  | GetProjectReportingFailedAction
  | GetProjectReportingSuccessAction
  | GetProjectReportingSnapshotsAction
  | GetProjectReportingSnapshotsFailedAction
  | GetProjectReportingSnapshotsSuccessAction
  | AddProjectReportingSnapshotAction
  | AddProjectReportingSnapshotFailedAction
  | AddProjectReportingSnapshotSuccessAction
  | AddProjectReportingSnapshotResetStateAction;
