import { IViewSettingBase } from './view-setting-base.model';
import { BomViewModeE } from '../bom-view-config';

export class VariantVsModel implements IViewSettingBase<VariantVsModel> {
  public bomViewMode: BomViewModeE = BomViewModeE.Short;
  public isVariantWizardExtended: boolean = false;
  public isTradeSpecificLeadWizardExtended: boolean = false;
  public isVariantWizardExpanded: boolean = true;
  public isTradeSpecificLeadWizardExpanded: boolean = true;

  clone(): VariantVsModel {
    const model: VariantVsModel = new VariantVsModel();
    model.bomViewMode = this.bomViewMode;
    model.isVariantWizardExtended = this.isVariantWizardExtended;
    model.isTradeSpecificLeadWizardExtended = this.isTradeSpecificLeadWizardExtended;
    model.isVariantWizardExpanded = this.isVariantWizardExpanded;
    model.isTradeSpecificLeadWizardExpanded = this.isTradeSpecificLeadWizardExpanded;

    return model;
  }
}
