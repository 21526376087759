import { IPrincipal } from '../interfaces/principal.interface';

export class DummyPrincipal implements IPrincipal {
  public getUser(): string {
    return 'Dummy';
  }

  public getUserId(): string {
    return '';
  }

  public getMandant(): string {
    return '';
  }

  public getMandantId(): number {
    return 0;
  }
}
