<pc-select-download-document-type
  class="toggleFileTypeButton"
  [ngClass]="{ 'toggle-file-type-button-hidden': hideToggle }"
  (fileTypeChange)="changeFileType($event)">
</pc-select-download-document-type>

<h4 *ngIf="attachments?.length">{{ 'VARIANT_OFFER_DOWNLOAD.ATTACHMENT_TITLE' | translate }}</h4>

<div *ngIf="attachments?.length" class="attachment-list">
  <helion-ui-tree [treeData]="nodes">
    <ng-template let-item>
      <li fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngIf="item?.children?.length">{{ item.name }}</ng-container>
        <ng-container *ngIf="!item?.children?.length">
          <helion-ui-checkbox
            class="margin-right-40"
            (onCheckboxChange)="onCheckboxChange($event, item.id)"
            [disabled]="isNativeDocument"
            >{{ item.name }}</helion-ui-checkbox
          >
          <helion-ui-icon
            role="button"
            [attr.fill-type]="'icon'"
            [attr.aria-label]="'VARIANT_OFFER_DOWNLOAD.PREVIEW_DOCUMENT' | translate"
            tooltip="{{ 'VARIANT_OFFER_DOWNLOAD.PREVIEW_DOCUMENT' | translate }}"
            (click)="previewAttachment(item.path)"
            data-feather="eye"></helion-ui-icon
        ></ng-container>
      </li>
    </ng-template>
  </helion-ui-tree>
</div>
