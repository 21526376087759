import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

// Takes dates as UTC and formats them to locale time. locale and format can be provided as parameters.
// This should be equal to using ngx-moment pipes, but provides easier and more readable access
@Pipe({
  name: 'UtcToLocalTime',
})
export class UtcToLocalTimePipe implements PipeTransform {
  public transform(
    value: Date,
    format: string = 'DD.MM.YY', // add ' HH:mm Z' to include time and timezone
    locale: string = 'de-CH'
  ): string {
    moment.updateLocale('de-CH', {
      invalidDate: '',
    });

    const momentValue = moment(value).parseZone();

    return momentValue.locale(locale).local().format(format);
  }
}
