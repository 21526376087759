<section class="dialog">
  <h2 class="dialog-title">
    {{
      'ADDRESS.DIALOGS.ADD_EXTERNAL_MANAGED_CONTACT.TITLE'
        | translate : { addressTypeName: externalManagedAddressName }
    }}
  </h2>

  <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
    <form #form="ngForm">
      <div fxLayout="row wrap">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)" class="contact-form__block">
          <helion-ui-select
            placeholder="{{ 'ADDRESS.TITLE' | translate }}"
            name="title"
            [ngModel]="selectedTitleItem"
            (ngModelChange)="selectedTitleItem = $event">
            <helion-ui-option
              *ngFor="let item of titleItems"
              [value]="item.key"
              [label]="item.display">
            </helion-ui-option>
          </helion-ui-select>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.FIRST_NAME' | translate }}"
            type="text"
            maxlength="30"
            name="firstName"
            [(ngModel)]="firstName">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.LAST_NAME' | translate }}"
            type="text"
            maxlength="30"
            name="lastName"
            [(ngModel)]="lastName">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.STREET' | translate }}"
            type="text"
            maxlength="30"
            name="street"
            [required]="true"
            [(ngModel)]="street">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.CARE_OF_NAME' | translate }}"
            type="text"
            name="careOfName"
            maxlength="40"
            [(ngModel)]="careOfName">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.PRIMARY_EMAIL' | translate }}"
            type="text"
            maxlength="50"
            name="street"
            [(ngModel)]="primaryEmail">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.REMINDER_EMAIL' | translate }}"
            type="text"
            maxlength="50"
            name="street"
            [(ngModel)]="reminderEmail">
          </helion-ui-input>

          <div class="margin-top-1">
            <pc-select-communication-type
              name="preferredCommunicationType"
              [(ngModel)]="selectedCommunicationType">
            </pc-select-communication-type>
          </div>
        </div>

        <div fxFlex.lt-md="100" fxFlex.gt-sm="0 0 calc(50% - 16px)">
          <helion-ui-select
            placeholder="{{ 'COMMON.LANGUAGE' | translate }}"
            name="personSprache"
            [ngModel]="selectedLanguageItem"
            (ngModelChange)="selectedLanguageItem = $event">
            <helion-ui-option
              *ngFor="let item of languageItems"
              [value]="item.key"
              [label]="item.display">
            </helion-ui-option>
          </helion-ui-select>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.COMPANY' | translate }}"
            type="text"
            maxlength="50"
            name="company"
            [(ngModel)]="companyName">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.ZIP' | translate }}"
            type="text"
            maxlength="10"
            name="zip"
            [required]="true"
            [(ngModel)]="zipCode">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.CITY' | translate }}"
            type="text"
            maxlength="30"
            name="city"
            [required]="true"
            [(ngModel)]="city">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.POST_OFFICE_BOX' | translate }}"
            type="text"
            name="postOfficeBox"
            maxlength="30"
            [(ngModel)]="postOfficeBox">
          </helion-ui-input>

          <helion-ui-input
            placeholder="{{ 'ADDRESS.PRIMARY_PHONE' | translate }}"
            type="text"
            maxlength="50"
            name="street"
            [(ngModel)]="primaryPhone">
          </helion-ui-input>

          <helion-ui-input
            placeholder="Mobile"
            type="text"
            maxlength="50"
            name="street"
            [(ngModel)]="mobilePhone">
          </helion-ui-input>
        </div>
      </div>
    </form>
  </div>

  <div class="dialog-actions">
    <button (click)="onCloseClick()">
      {{ 'COMMON.BTN_CANCEL' | translate }}
    </button>
    <button
      [attr.fill-type]="'contained'"
      [disabled]="isValid()"
      (click)="saveExternalManagedContact()">
      {{ 'COMMON.BTN_SAVE' | translate }}
    </button>
  </div>
</section>
