import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as PhotovoltaikAction from './photovoltaik.action';
import { MasterDataService, PvMasterDataViewModel } from '../../apis/advis';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PhotovoltaikEffects {
  getMasterData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhotovoltaikAction.GET_MASTER_DATA),
      switchMap(() => {
        return this.masterData.pCApiMasterdataGetPvMasterData().pipe(
          map((res: PvMasterDataViewModel) => {
            return new PhotovoltaikAction.GetPvMasterDataSuccessAction(res);
          }),
          catchError((error: any) => of(new PhotovoltaikAction.GetPvMasterDataFailedAction(error)))
        );
      })
    )
  );

  constructor(private actions$: Actions, private masterData: MasterDataService) {}
}
