import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MediaObserver } from '@angular/flex-layout';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DialogRef, DIALOG_DATA, DialogService } from '@sitewerk/theia-ui-lib';
import {
  AddressSearchLeadListViewModel,
  AddressSearchLeadListViewModelPageResultViewModel,
  AddressSearchListLeadRequest,
  LeadService,
  MasterDataMandatorViewModel,
} from '../../../shared/apis/advis';
import cloneDeep from 'lodash/cloneDeep';
import { isNullOrUndefined } from '../../../shared/utils/isNullOrUndefined';
import { CommonUtil } from '../../../shared/utils/common.util';
import { Logger, LoggerService } from '../../../shared/services/logger.service';
import * as RootReducer from '../../../shared/states';
import { Store } from '@ngrx/store';
import { LoadingModalService } from '../../../shared/services/loading-modal.service';
import { ModalLoaderIdTypesEnum } from '../../../shared/models/enum/modal-loader-id-types.enum';

interface IHeaderConfig {
  prop: string;
  name: string;
}

interface IRowData {
  id: number;
  name: string;
  street: string;
  zipCity: string;
  objectAddress: string;
  mandantName: string;
}

export interface IQuickSearchData {
  mandatorIds?: string[];
  leadId: number;
  name: string;
  street: string;
  zipCodeCity: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'pc-quick-search-dialog',
  templateUrl: './quick-search-dialog.component.html',
  styleUrls: ['./quick-search-dialog.component.scss'],
})
export class QuickSearchDialogComponent
  implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy
{
  private currentComponentWidth: number;
  private readonly logger: Logger;
  private mandantsMap: Map<number, MasterDataMandatorViewModel>;
  private subscription: Subscription = new Subscription();

  public rowsPerPage: number = 10;
  public searchBox: string;
  public rows: IRowData[] = [];
  public columns: IHeaderConfig[] = [
    { prop: 'name', name: this.translate.instant('LEADS.TABLE_HEADER.NAME') },
    { prop: 'street', name: this.translate.instant('LEADS.TABLE_HEADER.STREET') },
    { prop: 'zipCity', name: this.translate.instant('LEADS.TABLE_HEADER.ZIP_CITY') },
    { prop: 'objectAddress', name: this.translate.instant('LEADS.TABLE_HEADER.OBJECT_ADDRESS') },
    { prop: 'mandantName', name: this.translate.instant('LEADS.TABLE_HEADER.MANDANT') },
  ];

  public tableMessage: Object = {
    emptyMessage: this.translate.instant('COMMON.NO_DATA'),
    totalMessage: this.translate.instant('COMMON.TABLE_TOTAL_MESSAGE'),
  };

  readonly modalLoaderIdTypesEnum: typeof ModalLoaderIdTypesEnum = ModalLoaderIdTypesEnum;

  @ViewChild('tableWrapper', { static: true }) tableWrapper: any;
  @ViewChild('leadTable', { static: true }) table: DatatableComponent;
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: IQuickSearchData,
    private translate: TranslateService,
    private loadingModalService: LoadingModalService,
    private leadService: LeadService,
    private store: Store<RootReducer.IState>,
    private dialogService: DialogService,
    loggerService: LoggerService,
    private media: MediaObserver
  ) {
    this.logger = loggerService.create(QuickSearchDialogComponent.name);
  }

  ngOnInit(): void {
    const request: AddressSearchListLeadRequest = {
      Name: this.data.name,
      Street: this.data.street,
      CityOrZipCode: this.data.zipCodeCity,
      Email: this.data.email,
      PhoneNumber: this.data.phone,
      LeadId: this.data.leadId,
      MandantIds: this.data.mandatorIds ? this.data.mandatorIds.join(',') : '',
    };

    this.subscription.add(
      this.leadService.leadGetAddressSearchLeadList(request).subscribe(
        (result: AddressSearchLeadListViewModelPageResultViewModel) => {
          this.rows = result.Entries ? this.createRows(cloneDeep(result.Entries)) : [];
          this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.QUICK_SEARCH);
        },
        () => {
          this.dialogService
            .openAlert(this.translate.instant('ERROR.LOAD_FAILED'))
            .afterClosed()
            .subscribe();
          this.loadingModalService.closeModal(this.modalLoaderIdTypesEnum.QUICK_SEARCH);
        }
      )
    );

    this.subscription.add(
      this.store
        .select<Map<number, MasterDataMandatorViewModel>>(RootReducer.getGlobalMandantsMap)
        .subscribe((x: Map<number, MasterDataMandatorViewModel>) => (this.mandantsMap = x))
    );
  }

  public ngAfterViewInit(): void {
    this.loadingModalService.openModal(this.modalLoaderIdTypesEnum.QUICK_SEARCH);
    this.subscription.add(
      this.media.asObservable().subscribe(() => {
        const isMobile: boolean = this.media.isActive('lt-sm');
        const limit: number = isMobile ? undefined : this.rowsPerPage;
        if (this.rowsPerPage !== limit) {
          this.rowsPerPage = limit;
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    if (
      this.table &&
      this.table.recalculate &&
      this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth
    ) {
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;

      setTimeout(() => {
        this.table.recalculate();
        window.dispatchEvent(new Event('resize'));
      }, 200);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onActionModify(leadId: number): void {
    window.open(`${window.location.origin}/lead/detail/${leadId}`, '_blank');
  }

  private createRows(leads: AddressSearchLeadListViewModel[]): IRowData[] {
    const dataSource: IRowData[] = [];

    leads.forEach((lead: AddressSearchLeadListViewModel) => {
      const compName: string =
        isNullOrUndefined(lead.ContactCompanyName) || lead.ContactCompanyName.length === 0
          ? ''
          : lead.ContactCompanyName + ', ';
      dataSource.push({
        id: lead.LeadId,
        name:
          this.getStringWithNullCheck(lead.PersonFirstName) +
          ' ' +
          this.getStringWithNullCheck(lead.ContactLastName),
        street: this.getStringWithNullCheck(lead.PersonStreet),
        zipCity:
          this.getStringWithNullCheck(lead.PersonZipCode) +
          ' ' +
          this.getStringWithNullCheck(lead.PersonCity),
        mandantName: CommonUtil.getMandantShortcut(lead.MandatorId, this.mandantsMap, this.logger),
        objectAddress:
          compName +
          this.getStringWithNullCheck(lead.ContactFirstName) +
          ' ' +
          this.getStringWithNullCheck(lead.ContactLastName) +
          ', ' +
          this.getStringWithNullCheck(lead.ContactStreet) +
          ', ' +
          this.getStringWithNullCheck(lead.ContactZipCode) +
          ' ' +
          this.getStringWithNullCheck(lead.ContactCity),
      });
    });
    return dataSource;
  }

  private getStringWithNullCheck(str: string): string {
    return isNullOrUndefined(str) ? '' : str;
  }
}
