/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ElektroFormProjectDocumentViewModel { 
    DeepLink?: string;
    Status?: ElektroFormProjectDocumentViewModel.StatusEnum;
    DocumentTitle?: string;
}
export namespace ElektroFormProjectDocumentViewModel {
    export type StatusEnum = 'None' | 'Imported' | 'InProcess' | 'PartiallySigned' | 'Signed' | 'Sent' | 'ReceiptConfirmed' | 'Approved' | 'ApprovedWithMeasures' | 'ResponseArrived' | 'Published' | 'Finished' | 'VerifiedExternally' | 'Rejected' | 'CompletedExternally';
    export const StatusEnum = {
        None: 'None' as StatusEnum,
        Imported: 'Imported' as StatusEnum,
        InProcess: 'InProcess' as StatusEnum,
        PartiallySigned: 'PartiallySigned' as StatusEnum,
        Signed: 'Signed' as StatusEnum,
        Sent: 'Sent' as StatusEnum,
        ReceiptConfirmed: 'ReceiptConfirmed' as StatusEnum,
        Approved: 'Approved' as StatusEnum,
        ApprovedWithMeasures: 'ApprovedWithMeasures' as StatusEnum,
        ResponseArrived: 'ResponseArrived' as StatusEnum,
        Published: 'Published' as StatusEnum,
        Finished: 'Finished' as StatusEnum,
        VerifiedExternally: 'VerifiedExternally' as StatusEnum,
        Rejected: 'Rejected' as StatusEnum,
        CompletedExternally: 'CompletedExternally' as StatusEnum
    };
}
