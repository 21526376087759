import { Action } from '@ngrx/store';
import { IErrorInfo } from './global.reducer';
import { ArticleForestViewModel, MasterDataViewModel } from '../../apis/advis';
import { HttpErrorResponse } from '@angular/common/http';

export const SET_NAVIGATION_PROCESS: string = '[Global] Set navigation process';
export const ERROR_ADD: string = '[Global] Error - Add';
export const ERROR_CLEAR: string = '[Global] Error - Clear';

export const MASTER_DATA_LOAD: string = '[Global] MasterData - Load';
export const MASTER_DATA_SUCCESS: string = '[Global] MasterData - Load Success';
export const MASTER_DATA_FAILURE: string = '[Global] MasterData - Load Failure';

export const ARTICLES_FORESTS_LOAD: string = '[Global] ArticlesForests - Load';
export const ARTICLES_FORESTS_LOAD_SUCCESS: string = '[Global] ArticlesForests - Load Success';
export const ARTICLES_FORESTS_LOAD_FAILURE: string = '[Global] ArticlesForests - Load Failure';

export const SET_SELECTED_MANDANTS: string = '[Global] Set selected mandants';

export class SetNavigationProcessAction implements Action {
  readonly type: string = SET_NAVIGATION_PROCESS;

  constructor(public payload: boolean) {}
}

export class MasterDataLoadAction implements Action {
  readonly type: string = MASTER_DATA_LOAD;

  constructor(public payload: any = undefined) {}
}

export class MasterDataLoadSuccessAction implements Action {
  readonly type: string = MASTER_DATA_SUCCESS;

  constructor(public payload: MasterDataViewModel) {}
}

export class MasterDataLoadFailureAction implements Action {
  readonly type: string = MASTER_DATA_FAILURE;

  constructor(public payload: any) {}
}

export class ArticlesForestsLoadAction implements Action {
  readonly type: string = ARTICLES_FORESTS_LOAD;
}

export class ArticlesForestsLoadSuccessAction {
  readonly type: string = ARTICLES_FORESTS_LOAD_SUCCESS;

  constructor(public payload: ArticleForestViewModel[]) {}
}

export class ArticlesForestsLoadFailureAction {
  readonly type: string = ARTICLES_FORESTS_LOAD_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ErrorAddAction implements Action {
  readonly type: string = ERROR_ADD;

  constructor(public payload: IErrorInfo) {}
}

export class ErrorClearAction implements Action {
  readonly type: string = ERROR_CLEAR;

  constructor(public payload: any = undefined) {}
}

export class SetSelectedMandantsAction implements Action {
  readonly type: string = SET_SELECTED_MANDANTS;

  constructor(public payload: Set<number>) {}
}

export type Actions =
  | SetNavigationProcessAction
  | MasterDataLoadAction
  | MasterDataLoadSuccessAction
  | MasterDataLoadFailureAction
  | SetSelectedMandantsAction
  | ErrorAddAction
  | ErrorClearAction;
