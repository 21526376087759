import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutAction } from '../states/auth/auth.action';
import { Store } from '@ngrx/store';
import * as RootReducer from '../states';
import { environment } from '../../../environments/environment';

export const SKIP_PATHS = ['jwt-auth-enabled', 'exchange-openid-token', 'login'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(public auth: AuthService, private store: Store<RootReducer.IState>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lastPart = request.url.split('/').pop();
    return request.url.includes(environment.apiBasePath) &&
      this.auth.getToken() &&
      !SKIP_PATHS.some(path => path === lastPart)
      ? next.handle(this.addToken(request)).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(request, next);
            }

            return throwError(() => error);
          })
        )
      : next.handle(request);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.auth.getTokenData()) {
        return this.auth.refreshToken().pipe(
          switchMap(() => {
            this.isRefreshing = false;

            return next.handle(this.addToken(request));
          }),
          catchError(error => {
            this.isRefreshing = false;

            if (error.status == '403') {
              console.log('Logout');
              this.store.dispatch(new LogoutAction());
            }

            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(this.addToken(request));
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
    });
  }
}
