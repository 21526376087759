<div class="user-email-row hide-mobile" fxLayout="row" fxLayoutAlign="left center">
  <helion-ui-icon
    *ngIf="isScoreClickable && !!scoreIcon && !isScoreIconAsset"
    (click)="openProjectRankingDialog()"
    [attr.data-feather]="scoreIcon">
  </helion-ui-icon>
  <img
    *ngIf="isScoreClickable && !!scoreIcon && isScoreIconAsset"
    (click)="openProjectRankingDialog()"
    class="button-header-round-image-mobile"
    style="width: 20px"
    src="../../../../assets/icons/top-ranking.svg"
    alt="word" />
  <helion-ui-icon *ngIf="roleIcon" [attr.data-feather]="roleIcon"></helion-ui-icon>
  <img
    *ngIf="showTelesalesIcon"
    class="button-header-round-image-mobile"
    style="width: 20px"
    src="../../../../assets/icons/telesales.svg"
    alt="word" />
  <p class="user-email" [cdkMenuTriggerFor]="menu">
    {{ email }}
  </p>
  <ng-template #menu>
    <helion-ui-menu>
      <a class="menu-item account-item" cdkMenuItem [routerLink]="'/user'">
        <helion-ui-icon class="account-item-icon" data-feather="user"></helion-ui-icon>
        <span class="account-item-name">{{ 'USER.EDIT.USER_EDIT' | translate }}</span></a
      >
      <helion-ui-divider
        [dividerColor]="'gray'"
        *ngIf="availableAccounts?.length"></helion-ui-divider>
      <button
        class="menu-item account-item"
        cdkMenuItem
        *ngFor="let account of availableAccounts"
        (click)="switchAccount(account.userName)">
        <helion-ui-icon
          class="account-item-icon"
          [attr.data-feather]="account.icon"></helion-ui-icon>
        <span class="account-item-name">{{ account.userName }}</span>
      </button>
    </helion-ui-menu>
  </ng-template>
</div>
