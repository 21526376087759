/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EmobilityDependencyViewModel { 
    Key?: string;
    DependencyType?: EmobilityDependencyViewModel.DependencyTypeEnum;
}
export namespace EmobilityDependencyViewModel {
    export type DependencyTypeEnum = 'CarManufacturer' | 'MountingType' | 'WhereToInstallCharger' | 'ReserveStrongFuse' | 'ElectricityMeterReserve' | 'NetworkBox' | 'InternetConnectivity' | 'BuildingType' | 'ParkingType';
    export const DependencyTypeEnum = {
        CarManufacturer: 'CarManufacturer' as DependencyTypeEnum,
        MountingType: 'MountingType' as DependencyTypeEnum,
        WhereToInstallCharger: 'WhereToInstallCharger' as DependencyTypeEnum,
        ReserveStrongFuse: 'ReserveStrongFuse' as DependencyTypeEnum,
        ElectricityMeterReserve: 'ElectricityMeterReserve' as DependencyTypeEnum,
        NetworkBox: 'NetworkBox' as DependencyTypeEnum,
        InternetConnectivity: 'InternetConnectivity' as DependencyTypeEnum,
        BuildingType: 'BuildingType' as DependencyTypeEnum,
        ParkingType: 'ParkingType' as DependencyTypeEnum
    };
}
