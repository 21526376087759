import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RadioButtonItem } from '@sitewerk/theia-ui-lib';

@Component({
  selector: 'pc-select-download-document-type',
  templateUrl: './select-download-document-type.component.html',
  styleUrls: ['./select-download-document-type.component.scss'],
})
export class SelectDownloadDocumentTypeComponent {
  @Output()
  public fileTypeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public options: RadioButtonItem[] = [
    {
      value: false,
      name: 'radio-group',
      label: this.translate.instant('VARIANT_OFFER_DOWNLOAD.AS_PDF'),
    },
    {
      value: true,
      name: 'radio-group',
      label: this.translate.instant('VARIANT_OFFER_DOWNLOAD.AS_NATIVE_DOCUMENT'),
    },
  ];
  public selectedOption: boolean = false;

  constructor(private translate: TranslateService) {}
}
