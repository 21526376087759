import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { versions } from '../../../environments/versions';
import { DialogRef, DialogService } from '@sitewerk/theia-ui-lib';

@Injectable()
export class VersionCheckService implements OnDestroy {
  private subscription: Subscription = new Subscription();

  private confirmDialog: DialogRef;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {}

  public initVersionCheck(url: any, frequency: any = 1000 * 60 * 10): void {
    this.checkVersion(url);
    console.log('======> Initial version check done!');
    setInterval(() => {
      this.checkVersion(url);
      console.log('======> Interval version check done!');
    }, frequency);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkVersion(url: any): void {
    if (!this.confirmDialog) {
      this.http
        .get(url + `?decache=${new Date().toISOString()}`)
        .pipe(first())
        .subscribe(
          (response: any) => {
            const revision: any = response.revision;
            const hashChanged: boolean =
              isNullOrUndefined(revision) || versions.revision !== revision;
            if (hashChanged) {
              this.showDialog();
              console.log('======> new Version!');
            }
          },
          (err: any) => {
            console.error(err, 'Could not get version');
            this.showDialog();
          }
        );
    }
  }

  private showDialog(): void {
    this.confirmDialog = this.dialogService.openConfirm({
      message: this.translate.instant('DIALOG.NEW_APP_VERSION.MESSAGE'),
    });

    this.subscription.add(
      this.confirmDialog.afterClosed().subscribe((reload: boolean) => {
        if (reload) {
          window.location.reload();
        }
      })
    );
  }
}
